import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import StorageFile from "../../utils/models/StorageFile";
import User from "../../utils/models/User";
import Drawer from "@material-ui/core/Drawer";
import {Typography} from "@material-ui/core";
import CloseIcon from "../../assets/icons/close.svg";
import {Colors} from "../../components/helper/ColorHelper";
import React, {Fragment} from "react";
import {humanFileSize, timestampToDate} from "../../utils/helper/TextHelper";
import {StorageFileType} from "../../utils/enums/StorageFileType";
import FireStorageImage from "../../components/base/FirestoreImage";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {getTextForFileType} from "../../components/helper/TextHelper";
import FireStorageVideo from "../../components/base/FirestoreVideo";
import Dialog from "@material-ui/core/Dialog";


const useStyles = makeStyles((theme) => ({
    fileInfoDrawer: {
        '& .MuiDrawer-paper': {
            width: "300px",
            marginTop: "65px",
            zIndex: "999",
            borderColor: Colors.BLUELIGHT,
            backgroundColor: Colors.WHITE
        }
    },
    drawerHeader: {
        textAlign: 'center',
        padding: '16px 48px 16px 16px',
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
        '& h2': {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            lineHeight: '24px',
            margin: '0 auto'
        },
        '& svg': {
            position: 'absolute',
            right: '14px',
            top: '14px',
            height: '24px',
            width: '24px',
            cursor: 'pointer'
        }
    },
    imagePreview: {
        width: "32px",
        height: "32px",
        objectFit: "cover",
        borderRadius: '4px'
    },
    drawerInfo: {
        marginBottom: '24px',
        padding: "0 16px"
    },
    drawerInfoType: {
        margin: '24px 0'
    },
    drawerCreatedModified: {
        '& div': {
            width: '132px',
            display: 'inline-block',
            verticalAlign: 'top'
        }
    },
    drawerActivity: {
        margin: '10px 0 34px'
    },
    infoImagePreview: {
        textAlign: 'center',
        '& img': {
            background: 'transparent',
        }
    },
    imageInfoPreview: {
        maxWidth: '100%',
        maxHeight: '300px',
        width: 'auto',
        height: 'auto',
        position: 'static'
    }
}));

/**
 *
 * @param {StorageFile} file
 * @param {User} createdBy
 * @param {User} updatedBy
 * @param {boolean} open
 * @param {Function} onClose
 * @constructor
 */
const StorageInfo = ({ file, createdBy, updatedBy, open = true, onClose ,}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();

    function renderSize(size) {
        if (size === undefined) {
            return <span className={classes.valueSizeNone}>-</span>
        } else {
            return humanFileSize(size);
        }
    }

    return  <Drawer className={classes.fileInfoDrawer}
                variant="persistent"
                anchor="right"
                open={open}
            >
            <div className={classes.drawerHeader}>
                <Typography variant={"h2"}>
                    {file.name }
                </Typography>
                <CloseIcon onClick={(e) => onClose(e)} fill={Colors.BLACK}/>
            </div>
            {
                ( file.type === StorageFileType.IMAGE || file.type === StorageFileType.VIDEO) &&
                <div className={classes.infoImagePreview}>
                    {
                        file.type === StorageFileType.VIDEO
                            ? <FireStorageVideo src={file.fullPath} className={classes.imageInfoPreview}/>
                            : <FireStorageImage src={file.fullPath} className={classes.imageInfoPreview}/>
                    }
                </div>
            }
            <div className={classes.drawerInfo} style={{marginTop: "24px"}}>
                <Typography variant={"h6"}>{t('storage.fileType')}</Typography>
                <Typography variant={"body2"}>{getTextForFileType(file.type, t)}</Typography>
            </div>
            <div className={classes.drawerInfo}>
                <Typography variant={"h6"}>{t('storage.size')}</Typography>
                <Typography variant={"body2"}>{renderSize(file.size)}
                </Typography>
            </div>
            <div className={classes.drawerCreatedModified}>
                <div className={classes.drawerInfo}>
                    <Typography variant={"h6"}> {t('storage.created')} </Typography>
                    <Typography variant={"body2"}>{timestampToDate(file.cAt)}</Typography>
                </div>
                <div className={classes.drawerInfo}>
                    <Typography variant={"h6"}>{t('storage.createdBy')}</Typography>
                    <Typography variant={"body2"}>{createdBy?.fullName}</Typography>
                </div>

                {
                    file.uAt && updatedBy?.fullName &&
                    <Fragment>
                        <div className={classes.drawerInfo}>
                            <Typography variant={"h6"}>{t('storage.modified')}</Typography>
                            <Typography variant={"body2"}>{timestampToDate(file.uAt)}</Typography>
                        </div>
                        <div className={classes.drawerInfo}>
                            <Typography variant={"h6"}> {t('storage.modifiedBy')} </Typography>
                            <Typography variant={"body2"}>{updatedBy?.fullName}</Typography>
                        </div>
                    </Fragment>
                }

            </div>

        </Drawer>;
}


const mapState = (state, ownProps) => ({
    createdBy: getModelInstance(state, User, ownProps.file?.createdBy),
    updatedBy: getModelInstance(state, User, ownProps.file?.updatedBy)
});

export default connect(mapState) (StorageInfo);