import React, {useEffect, useState} from "react";
import 'react-image-lightbox/style.css';

import {connect} from "react-redux";
import {FireStorage} from "../../api/FirebaseService";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import Avatar from "@material-ui/core/Avatar";
import {getModelInstance} from "../../utils/store/selectors";
import {addModelInstanceDispatch} from "../../utils/store/actions";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import {getInitials} from "../helper/TextHelper";
import LightboxWrapper from "./LightboxImage";
import STORAGECACHEMANAGER from "./FirestoreCacheManager";


/**
 *
 * @param {string} alt
 * @param {string} src
 * @param {FireStorageUrl} firestoreUrl
 * @param {Function} addModelInstanceDispatch
 * @param {int} startIndex
 * @param {boolean} lightboxEnabled
 * @param {string} lightboxSrc
 * @param {FireStorageUrl} lightboxFirestoreUrl
 * @param {string} className
 * @param {boolean} isAvatar
 * @param {number} radius
 * @param {number} fontSize
 * @param children
 * @param {Object} rest
 * @param {Object} style
 * @param {JSX.Element} fallbackWidget
 * @returns {JSX.Element}
 * @constructor
 */
const FireStorageImage = ({isAvatar, alt, src, radius=24, style, fontSize, backgroundColor, createRef ,  firestoreUrl, addModelInstanceDispatch, lightboxEnabled = false, lightboxSrc,lightboxFirestoreUrl, fallbackWidget, className,children, onClick, ...rest}) => {

    const [url, setUrl] = useState(firestoreUrl?.url);
    const [lightboxUrl, setLightboxUrl] = useState(lightboxFirestoreUrl?.url ?? firestoreUrl?.url);
    fontSize = fontSize || ( radius > 12 ? (radius * 3/4) : radius) ;

    useEffect( () => {
        if (!!src) {
            if ( typeof src === "string" &&  (src.startsWith('client') || src.startsWith('/client'))) {
                if (!firestoreUrl) {
                    loadImageUrl(src, (downloadUrl) =>initUrl(downloadUrl));
                } else {
                    initUrl(firestoreUrl.url);
                }
            } else {
                initUrl(src);
            }
        }

        if (!!lightboxSrc) {
            if (lightboxEnabled && typeof lightboxSrc === "string" &&  (lightboxSrc.startsWith('client') || lightboxSrc.startsWith('/client'))) {
                if (!lightboxFirestoreUrl) {
                    loadImageUrl(lightboxSrc, (downloadUrl) =>initLightboxUrl(downloadUrl));
                } else {
                    initLightboxUrl(lightboxFirestoreUrl.url);
                }
            } else {
                initLightboxUrl(lightboxSrc);
            }
        }
    }, [src,firestoreUrl]);

    const loadImageUrl = async (src, callback) => {
        let newSrc = src.replace(/%2F/g, '/');
        if (!STORAGECACHEMANAGER.checkIfUrlRequested(newSrc)) {
            STORAGECACHEMANAGER.markUrlAsRequested(newSrc);
            try {
                const downloadUrl = await FireStorage.ref(newSrc).getDownloadURL();
                let fireStorageUrl = new FireStorageUrl();
                fireStorageUrl.id = src;
                fireStorageUrl.src = newSrc;
                fireStorageUrl.url = downloadUrl;
                addModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
                if (callback) {
                    callback(downloadUrl);
                }
                return true;
            } catch (e) {
                let fireStorageUrl = new FireStorageUrl();
                fireStorageUrl.id = src;
                fireStorageUrl.src = newSrc;
                fireStorageUrl.url = undefined;
                addModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
                return false;
            }
        } 
    }

    const initUrl = (url) => {
        setUrl(url);
    }

    const initLightboxUrl = (url) => {
        setLightboxUrl(url);
    }

    let altText= alt;
    if (!!altText && altText !== "..." && altText.length > 1)  {
        altText = getInitials(altText);
    }

    const component = ({setLightBoxOpen}) => {
        return (
            isAvatar
                ? (
                    <Avatar ref={createRef} variant={"circular"} style={ {"height": radius * 2 + "px", "width": radius * 2 + "px",  fontSize: fontSize + "px", cursor: lightboxEnabled && 'pointer', backgroundColor: backgroundColor ? backgroundColor : '' , ...style}} className={ `firestore-image  ${className}`} alt={alt} src={url} {...rest} onClick={setLightBoxOpen} >
                        {altText ? altText : " "}
                    </Avatar>
                )
                : (
                    url
                        ? <img ref={createRef} style={ {fontSize: fontSize + "px", cursor: lightboxEnabled && 'pointer',  backgroundColor: backgroundColor ? backgroundColor : '' }} className={ `firestore-image ${className}`} onClick={setLightBoxOpen} alt={altText} src={url} {...rest}/>
                        : fallbackWidget
                            ? fallbackWidget
                            : <div ref={createRef}  style={ {fontSize: fontSize + "px",  backgroundColor: backgroundColor ? backgroundColor : '' }} className={ `firestore-image-placeholder  ${className}`}>  {altText} </div>
                )
        );
    }

    if (lightboxEnabled) {
        return (
          <LightboxWrapper images={[lightboxUrl ? lightboxUrl : url]}>
              {
                  ({setLightBoxOpen}) => component({setLightBoxOpen})
              }
          </LightboxWrapper>
        );
    }

    return  (
        component(!!onClick ? onClick : () => {} )
    )
};


const mapState = (state, ownProps) => ({
    firestoreUrl: getModelInstance(state, FireStorageUrl,ownProps.src),
    lightboxFirestoreUrl: ownProps.lightboxEnabled && ownProps.lightboxUrl ? getModelInstance(state, FireStorageUrl,ownProps.lightboxUrl) : undefined,
});

const mapAction = {
    addModelInstanceDispatch
};

export default connect(mapState,mapAction)(FireStorageImage);
