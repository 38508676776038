export const MessagingType = {
    // ARTICLES
    ARTICLEPUBLISHED: "ARTICLEPUBLISHED",
    HEARTATARTICLE: "HEARTATARTICLE",

    // COMMENTS
    COMMENTATARTICLE: "COMMENTATARTICLE",
    COMMENTATCOMMENTEDARTICLE: "COMMENTATCOMMENTEDARTICLE" ,
    MENTIONEDINCOMMENT: "MENTIONEDINCOMMENT",
    ANSWERATCOMMENT: "ANSWERATCOMMENT",

    // CHATS
    MESSAGENEW: "MESSAGENEW",
    CHATNAMECHANGED: "CHATNAMECHANGED",

    // DISTRIBUTORS
    ADDEDTODISTRIBUTOR: "ADDEDTODISTRIBUTOR",
    REMOVEDFROMDISTRIBUTOR: "REMOVEDFROMDISTRIBUTOR",
    DISTRIBUTORREMOVED: "DISTRIBUTORREMOVED",
    LEFTDISTRIBUTOR: "LEFTDISTRIBUTOR",
    REQUESTDISTRIBUTOR: "REQUESTDISTRIBUTOR",
    REMOVEDREQUESTDISTRIBUTOR: "REMOVEDREQUESTDISTRIBUTOR",
    REQUESTREFUSEDDISTRIBUTOR: "REQUESTREFUSEDDISTRIBUTOR",

    //TASKS
    TASKASSIGNED: "TASKASSIGNED",
    TASKASSIGNEDTOOTHER: "TASKASSIGNEDTOOTHER",
    TASKFINISHED: "TASKFINISHED",

    DATAEXPORTDONE: "DATAEXPORTDONE",

    NEWEVENT: "NEWEVENT",
    EVENTADDEDASCONTACT: "EVENTADDEDASCONTACT",
    EVENTINFORMATIONCHANGED: "EVENTINFORMATIONCHANGED",
    EVENTPLACECHANGED: "EVENTPLACECHANGED",
    EVENTDATECHANGED: "EVENTDATECHANGED"
};
