import React, {useRef} from "react";
import ModelManager from "../../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../../utils/auth/AuthStorage";
import {getModelInstance} from "../../../utils/store/selectors";
import User from "../../../utils/models/User";
import {connect} from "react-redux";
import FirebaseStorageManager from "../../../utils/manager/manager/FirebaseStorageManager";
import CommentAnswer from "../../../utils/models/CommentAnswer";
import CreateCommentAnswer from "./CreateCommentAnswer";
import {FireIncrement} from "../../../api/FirebaseService";
import {sendAnswerAtCommentNotification} from "../../helper/NotificationHelper";

/**
 * @param {Comment} comment
 * @param {User} authUser
 * @returns {JSX.Element}
 * @constructor
 */
const CreateCommentAnswerContainer = ({comment, authUser}) => {
    const saving = useRef(false);

    const saveNewCommentAnswer = async (text, media) => {
        if(!saving.current) {
            let futures = [];
            saving.current = true;

            const commentAnswer = new CommentAnswer();
            commentAnswer.aId = comment.aId;
            commentAnswer.cId = comment.id;
            commentAnswer.cName = authUser.fullName;
            commentAnswer.cUid = authUser.id;
            commentAnswer.text = (text || "").trim();

            try {

                if(!!media?.name) {
                    let uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/comment_answer/${commentAnswer.id}`;
                    /**
                     * This can't be awaited in the futures array as the model manager update is faster than the image upload,
                     * causing the comments image url to give us a 404, since the image is still being uploaded
                     */
                    await new FirebaseStorageManager().uploadImage(media, uploadPath, 1200 , 1);
                    commentAnswer.media = [uploadPath.substring(1).replace(/\//g, '%2F')];
                }

                comment.aCount++;

                let commentUpdateData = {
                    aCount: FireIncrement(1)
                };

                let commentCommentedData = {
                    [AUTHSTORAGE.getUserId()]: ""
                };

                futures.push(ModelManager.add({modelInstance: commentAnswer}));
                futures.push(ModelManager.update({modelInstance: comment, customData: commentUpdateData}));
                futures.push(ModelManager.update({customData: commentCommentedData, resource: `/client/${AUTHSTORAGE.getClientId()}/comment/${comment.id}/commented`, id: '_all', useSet: true}));
                sendAnswerAtCommentNotification(comment.aId, comment, commentAnswer.id, authUser.fullName);

                await Promise.all(futures);
                saving.current = false;
                return true;
            } catch (ex) {
                saving.current = false;
            }

        }
        return false;
    };

    return <CreateCommentAnswer saveNewCommentAnswer={saveNewCommentAnswer}/>
};


const mapState = (state) => ({
    authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
});

export default connect(mapState)(CreateCommentAnswerContainer);
