import {id, model, prop} from "../manager/decorators";
import MinimalUser from "./MinimalUser";
import {CURRENT_USER, SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import * as forge from "node-forge";
import AUTHSTORAGE from "../auth/AuthStorage";
import {getChatAvatarPath, getUserAvatarPath} from "../helper/ImageHelper";

@model('client/:client/chat')
class Chat {

    @id()
    id;

    @prop()
    name;

    @prop()
    count;

    @prop({readOnly: true})
    deleted;

    @prop({readOnly: true})
    uMC;

    @prop({readOnly: true})
    symKey;

    @prop({readOnly: true})
    iv;

    @prop()
    imageOwner;

    @prop({sentinel: CURRENT_USER, createOnly: true})
    cUid;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP, readOnly: true, createOnly: true})
    cAt;

    @prop({
        nullable: false,
        type: "array",
        typeOptions: {
            entry: "model",
            entryOptions: {
                target: MinimalUser
            }
        }
    })
    sample = [];

    isActive;
    isTemp = false;
    hidden = false;

    @prop({readOnly: true})
    archived = false;
    lastMessage;
    member = [];

    get avatar() {
        if (this.isSingleChat) {
            let otherUser = this.getOtherUser();
            if (!!otherUser) {
                return getUserAvatarPath(otherUser?.uid);
            }
            return getUserAvatarPath(AUTHSTORAGE.getUserId());
        }

        if(!this.imageOwner)
            return undefined;
        return getChatAvatarPath(this.imageOwner, this.id);
    };

    getOtherUser() {
        if (!!this.member && this.member.length > 0) {
            this.sample.find( (e) => e.uid !==  AUTHSTORAGE.getUserId());
        }
        return this.sample.find( (e) => e.uid !==  AUTHSTORAGE.getUserId());
    }

    getOwnUser() {
        if (!!this.member && this.member.length > 0) {
            this.sample.find( (e) => e.uid ===  AUTHSTORAGE.getUserId());
        }
        return this.sample.find( (e) => e.uid ===  AUTHSTORAGE.getUserId());
    }

    get isSingleChat() {
        return this.count === 1;
    }

    get title() {
        if(this.isSingleChat) {
            let otherUser = this.getOtherUser();
            if(!!otherUser) {
                return otherUser.fullName;
            }
            let ownUser = this.getOwnUser();
            if(!!ownUser) {
                return ownUser.fullName;
            }
        }

        return this.name;
    }

    /**
     * @param {Message} message
     * @param {String} [iv]
     * @returns {Message}
     */
    encryptMessage(message, iv) {
        if (AUTHSTORAGE.getClient().isDemoClient()) {
            return message;
        }

        let decodedIv;

        let decodedKey = forge.util.encodeUtf8(this.symKey);

        if(!!iv) {
            decodedIv = forge.util.decode64(iv);
        } else {
            decodedIv = forge.random.getBytesSync(16);
        }

        let cipher = forge.cipher.createCipher('AES-CBC', decodedKey);

        if(!!message.text) {
            cipher.start({iv: decodedIv});
            cipher.update(forge.util.createBuffer(unescape(encodeURIComponent(message.text)), 'raw'));
            cipher.finish();

            message.text = forge.util.encode64(cipher.output.getBytes());
        }

        if(!!message.images) {
            let encryptedImagePaths = [];
            message.images.forEach(imagePath => {
                cipher.start({iv: decodedIv});
                cipher.update(forge.util.createBuffer(imagePath, 'utf-8'));
                cipher.finish();

                encryptedImagePaths.push(forge.util.encode64(cipher.output.getBytes()));
            });

            message.images = encryptedImagePaths;
        }

        if(!!message.docs) {
            let encryptedDocuments = [];
            message.docs.forEach(document => {
                cipher.start({iv: decodedIv});
                cipher.update(forge.util.createBuffer(document.name, 'utf-8'));
                cipher.finish();

                let encryptedName = forge.util.encode64(cipher.output.getBytes());

                cipher.start({iv: decodedIv});
                cipher.update(forge.util.createBuffer(document.path, 'utf-8'));
                cipher.finish();

                let encryptedPath = forge.util.encode64(cipher.output.getBytes());
                cipher.start({iv: decodedIv});
                cipher.update(forge.util.createBuffer(document.size.toString(), 'utf-8'));
                cipher.finish();

                let encryptedSize = forge.util.encode64(cipher.output.getBytes());

                encryptedDocuments.push({
                    name: encryptedName,
                    path: encryptedPath,
                    size: encryptedSize
                });
            });

            message.docs = encryptedDocuments;
        }

        if(!!message.videos) {
            let encryptedVideos = [];
            message.videos.forEach(video => {
                cipher.start({iv: decodedIv});
                cipher.update(forge.util.createBuffer(video.url, 'utf-8'));
                cipher.finish();

                let encryptedUrl = forge.util.encode64(cipher.output.getBytes());

                cipher.start({iv: decodedIv});
                cipher.update(forge.util.createBuffer(video.thumbnail, 'utf-8'));
                cipher.finish();

                let encryptedThumbnail = forge.util.encode64(cipher.output.getBytes());

                let encryptedData = {
                    url: encryptedUrl,
                    thumbnail: encryptedThumbnail
                };

                if(!!video.uploadId) {
                    encryptedData.uploadId = video.uploadId;
                }

                encryptedVideos.push(encryptedData);
            });

            message.videos = encryptedVideos;
        }

        message.iv = forge.util.encode64(decodedIv);
        return message;
    }

    /**
     * @param {Message} message
     * @returns {Message}
     */
    decryptMessage(message) {
        if (AUTHSTORAGE.getClient().isDemoClient()) {
            return message;
        }

        let decodedIv = forge.util.decode64(message.iv);
        let decodedKey = forge.util.encodeUtf8(this.symKey);

        let cipher
        try {
            cipher = forge.cipher.createDecipher('AES-CBC', decodedKey);
        } catch (ex) {
            console.log("Failed to create cipher for " + this.id);
        }

        if (cipher) {
            if(!!message.text) {
                cipher.start({iv: decodedIv});
                cipher.update(forge.util.createBuffer(forge.util.decode64(message.text)));
                let success = cipher.finish();

                if(success) {
                    message.text = forge.util.decodeUtf8(cipher.output.getBytes());
                }
            }

            if(!!message.images) {
                let decryptedImagePaths = [];
                message.images.forEach(imagePath => {
                    cipher.start({iv: decodedIv});
                    cipher.update(forge.util.createBuffer(forge.util.decode64(imagePath)));
                    let success = cipher.finish();

                    if(success) {
                        decryptedImagePaths.push(forge.util.decodeUtf8(cipher.output.getBytes()));
                    }
                });

                message.images = decryptedImagePaths;
            }

            if(!!message.videos) {
                message.videos.forEach(video => {
                    cipher.start({iv: decodedIv});
                    cipher.update(forge.util.createBuffer(forge.util.decode64(video.url)));
                    let urlSuccess = cipher.finish();

                    if(urlSuccess) {
                        video.url = forge.util.decodeUtf8(cipher.output.getBytes());
                    }

                    cipher.start({iv: decodedIv});
                    cipher.update(forge.util.createBuffer(forge.util.decode64(video.thumbnail)));
                    let success = cipher.finish();

                    if(success) {
                        video.thumbnail = forge.util.decodeUtf8(cipher.output.getBytes());
                    }
                });
            }

            if(!!message.audio && message.audio.url && message.audio.length) {
                cipher.start({iv: decodedIv});
                cipher.update(forge.util.createBuffer(forge.util.decode64(message.audio.url)));
                let urlSuccess = cipher.finish();

                if(urlSuccess) {
                    message.audio.url = forge.util.decodeUtf8(cipher.output.getBytes());
                }

                cipher.start({iv: decodedIv});
                cipher.update(forge.util.createBuffer(forge.util.decode64(message.audio.length)));
                let successLength = cipher.finish();

                if(successLength) {
                    message.audio.length = forge.util.decodeUtf8(cipher.output.getBytes());
                }
            }

            if(!!message.docs) {
                message.docs.forEach(doc => {
                    cipher.start({iv: decodedIv});
                    cipher.update(forge.util.createBuffer(forge.util.decode64(doc.path)));
                    let pathSuccess = cipher.finish();

                    if(pathSuccess) {
                        doc.path = forge.util.decodeUtf8(cipher.output.getBytes());
                    }

                    cipher.start({iv: decodedIv});
                    cipher.update(forge.util.createBuffer(forge.util.decode64(doc.name)));
                    let success = cipher.finish();

                    if(success) {
                        doc.name = forge.util.decodeUtf8(cipher.output.getBytes());
                    }

                    if(!!doc.size) {
                        cipher.start({iv: decodedIv});
                        cipher.update(forge.util.createBuffer(forge.util.decode64(doc.size)));
                        let sizeSuccess = cipher.finish();

                        if(sizeSuccess) {
                            doc.size = forge.util.decodeUtf8(cipher.output.getBytes());
                        }
                    }
                });
            }
        }
        return message;
    }

    isCorrectlyDecrypted() {
        let decodedKey = forge.util.encodeUtf8(this.symKey);

        try {
            forge.cipher.createDecipher('AES-CBC', decodedKey);
            return true;
        } catch (ex) {
            return false;
        }
    }
}

export default Chat;

