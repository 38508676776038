import React, {useState} from 'react';
import ChatExternalUser from "./ChatExternalUser";
import BackendController from "../../utils/manager/BackendController";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {ExternalVisibility} from "../../utils/enums/ExternalVisibility";
import {getModelInstance} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import {connect} from "react-redux";
import ModelManager from "../../utils/manager/ModelManager";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import SUCCESSNOTIFICATIONMANAGER from "../success/SuccessNotificationManager";
import SuccessMessage from "../../utils/models/SuccessMessage";
import {SuccessType} from "../../utils/enums/SuccessType";

const ChatExternalUserContainer = ({authUser, referrer}) => {

    const [saving, setSaving] =  useState(false);
    const [redirectTarget, setRedirectTarget] = useState(undefined);

    const onSave = async ({ firstName, lastName, phoneNumber, phoneNumberPrefix, language }) => {

        if (!saving) setSaving(true);

        let department;
        if (AUTHSTORAGE.getClient().externalVisibility !== ExternalVisibility.DEPARTMENTS) {
            department = authUser.department;
        }

        let externalId = await new BackendController().createExternal(AUTHSTORAGE.getClient().workspace,firstName, lastName, phoneNumberPrefix,phoneNumber, department, language);
        if (externalId && typeof externalId === "string") {
            await ModelManager.get({model: User, id: externalId});
            setRedirectTarget(decodeURIComponent(referrer) === ROUTES.messagesCreate ? ROUTES.messagesCreate : ROUTES.messagesSingleCreate);
        } else {
            setSaving(false);
            if (externalId === 409) {
                SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.EXTERNALEXISTS, false));
            } else {
                SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.INVALIDDATA, false));

            }
        }
    };

    if (!!redirectTarget) {
        return <Redirect push to={redirectTarget}/>;
    }

    return (
        !saving ? 
        <ChatExternalUser 
            saving={saving}
            onSave={onSave}
        /> 
        : <div className="moin-circular-progress-container">
            <CircularProgress />
        </div>
    )
};


const mapState = (state, ownProps) => {
    return {
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId()),
    }
};

export default connect(mapState)(ChatExternalUserContainer);
