import React, { useState} from 'react';

import {useTranslation} from "react-i18next";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import MoinTextField from '../base/MoinTextField';
import MoinUnsavedChangesDialog from "../../components/base/MoinUnsavedChangesDialog";
import { Divider } from '@material-ui/core';
import MoinButton from '../base/MoinButton';
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {ExternalVisibility} from "../../utils/enums/ExternalVisibility";
import {Colors} from "../helper/ColorHelper";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import BackendController from "../../utils/manager/BackendController";
import {SmsLanguage} from "../../utils/enums/SmsLanguage";
import MoinSelect from "../base/MoinSelect";
import {Feature} from "../../utils/enums/Feature";
import MenuItem from "@material-ui/core/MenuItem";
import * as TranslationHelper from "../helper/TranslationHelper";
import {getTranslationForSMSLanguage} from "../helper/TranslationHelper";


const useStyles = makeStyles(() => ({
    content: {
        padding: '16px',
        width: '100%',
        marginBottom: '60px'
    },
    addUserDescription: {
       paddingBottom: "24px",
    },
    hintText: {
       marginTop: "8px",
    },
    optionalRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '8px',
        marginRight: "16px"
    },
}));

const PageState =  {
    verify : "verify",
    update : "update"
}

/**
 *
 * @param {boolean} saving
 * @param {Function} onSave
 * @returns {JSX.Element}
 * @constructor
 */
const ChatExternalUser = ({saving, onSave}) => {
    const classes = useStyles();
    const addClasses = useStyles();

    const [pageState, setPageState] = useState(PageState.update);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberPrefix, setPhoneNumberPrefix] = useState("+49");
    const [language, setLanguage] = useState(SmsLanguage.GERMAN);

    const [t] = useTranslation();
    const [hasChanges, setHasChanges] = useState(false);
    const [errors, setErrors] = useState({});

    const validate = async () => {
        let errors = {};

        if (!firstName ) {
            errors['firstName'] = t('form.errors.cantBeEmpty', {field: t('user.firstName')});
        } else if(firstName.trim().length < 2) {
            errors['firstName'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('user.firstName'), chars: 2});
        }
        if (!lastName) {
            errors['lastName'] = t('form.errors.cantBeEmpty', {field: t('user.lastName')});
        } else if(lastName.trim().length < 2) {
            errors['lastName'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('user.lastName'), chars: 2});
        }
        if (!phoneNumberPrefix ) {
            errors['phoneNumberPrefix'] = t('form.errors.cantBeEmpty', {field: t('user.mobilePrefix')});
        } else if(phoneNumberPrefix.trim().length < 2) {
            errors['phoneNumberPrefix'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('user.mobilePrefix'), chars: 2});
        }

        if (!phoneNumber) {
            errors['phoneNumber'] = t('form.errors.cantBeEmpty', {field: t('user.mobile')});
        } else if (phoneNumber.trim().length < 7) {
            errors['phoneNumber'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('user.mobile'), chars: 7});
        } else if (!/^[\d\s]+$/.test(phoneNumber.trim())) {
            errors['phoneNumber'] = t('form.errors.mustBeANumber', {field: t('user.mobile')});
        }

        if (Object.keys(errors).length === 0) {
            let checkIfNumberExists = await new BackendController().checkIfExternalExists(AUTHSTORAGE.getClient().workspace,phoneNumberPrefix,phoneNumber);
            if (!checkIfNumberExists) {
                setPageState(PageState.verify);
            } else {
                errors['phoneNumber'] = t('form.errors.externalExists', {field: phoneNumber});
            }
        }
        setErrors(errors);
    }

    const onCreate = () => {
        onSave({
            firstName: firstName,
            lastName: lastName,
            phoneNumber : phoneNumber,
            phoneNumberPrefix : phoneNumberPrefix,
            language: language
        });
    }

    let invalid = phoneNumberPrefix.trim().length < 3 ||
        firstName.trim().length < 2 ||
        lastName.trim().length < 2 ||
        phoneNumber.trim().length < 7 ||
        !/^[\d\s]+$/.test(phoneNumber.trim())
    ;

    if (pageState === PageState.verify) {
        return (
            <>
                <MoinUnsavedChangesDialog hasChanges={hasChanges} />
                <MoinCard>
                    <MoinCardHeader>
                        <Typography variant="h3">
                            {t('external.inviteMember')}
                        </Typography>
                    </MoinCardHeader>
                    <MoinCardContent>
                        <div className={classes.content}>
                            <div className={addClasses.addUserDescription}>
                                <Typography variant="body2" >
                                    {t('external.inviteDescriptionConfirmation')}
                                </Typography>
                            </div>

                            <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <Typography variant="h6" display="block">
                                    { t('user.firstName')}
                                </Typography>
                                <Typography variant="body2" display="block">
                                    { firstName }
                                </Typography>
                                <Divider style={{margin: '12px 0'}}/>
                                <Typography variant="h6" display="block">
                                    { t('user.lastName')}
                                </Typography>
                                <Typography variant="body2" display="block">
                                    { lastName }
                                </Typography>
                                <Divider style={{margin: '12px 0'}}/>
                                <Typography variant="h6" display="block">
                                    { t('user.mobile')}
                                </Typography>
                                <Typography variant="body2" display="block">
                                    {phoneNumberPrefix} {phoneNumber}
                                </Typography>

                                <Divider style={{margin: '12px 0'}}/>

                                <Typography variant={'h6'}>
                                    { t('language.smsLanguage') }
                                </Typography>

                                <Typography variant="body2" display="block">
                                    {getTranslationForSMSLanguage(t,language)}
                                </Typography>
                            </div>
                        </div>
                    </MoinCardContent>
                    <Divider />
                    <MoinCardHeader style={{flexDirection: 'row', justifyContent: "space-between" }}>
                        <MoinButton onClick={ () => setPageState(PageState.update)}><ArrowLeftIcon style={{"margin-right": "8px"}} fill={Colors.WHITE} width={16} height={16}/>{t('dialogs.back')}</MoinButton>

                        <MoinButton onClick={onCreate}>{t('dialogs.sendInvite')}<ArrowRightIcon style={{"margin-left": "8px"}} fill={Colors.WHITE} width={16} height={16}/></MoinButton>
                    </MoinCardHeader>
                </MoinCard>
            </>
        );
    }


    return (
        <>
            <MoinUnsavedChangesDialog hasChanges={hasChanges} />
            <MoinCard>
                <MoinCardHeader>
                    <Typography variant="h3">
                        Mitglied einladen
                    </Typography>
                </MoinCardHeader>
                <MoinCardContent>
                    <div className={classes.content}>
                        <div className={addClasses.addUserDescription}>
                            <Typography variant="body2" >
                                {t('external.inviteDescription')}
                            </Typography>
                            {
                                AUTHSTORAGE.getClient().externalVisibility !== ExternalVisibility.DEPARTMENTS
                                && <Typography variant="body2" className={addClasses.hintText}  >
                                    <b>{ t('external.hint')}: </b>
                                    { t('external.inviteVisibilityDepartment')}
                                </Typography>
                            }
                        </div>


                        <MoinTextField
                            maxCharacters={200}
                            showCharacterCount={false}
                            title={t('user.firstName')}
                            placeholder={t('user.firstName')}
                            value={firstName}
                            onChange={e => {
                                setHasChanges(true);
                                setFirstName(e.target.value)
                            }}
                            error={ errors && errors['firstName']}
                        />
                        <MoinTextField
                            maxCharacters={200}
                            showCharacterCount={false}
                            title={t('user.lastName')}
                            placeholder={t('user.lastName')}
                            value={lastName}
                            onChange={e => {
                                setHasChanges(true);
                                setLastName(e.target.value)
                            }}
                            error={ errors && errors['lastName']}
                        />
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column" }}>

                            <div className={classes.optionalRow}>
                                <Typography variant={'h2'}>{ t('user.mobile').toUpperCase()}</Typography>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", width: "100%" }}>
                                <div style={{width: "5rem", marginRight: "1rem" }}>
                                    <MoinTextField
                                        maxCharacters={5}
                                        showCharacterCount={false}
                                        placeholder={"+49"}
                                        value={phoneNumberPrefix}
                                        onChange={e => {
                                            setHasChanges(true);
                                            setPhoneNumberPrefix(e.target.value)
                                        }}
                                        error={ errors && errors['phoneNumberPrefix']}
                                    />
                                </div>
                                <div style={{width: "100%"}}>
                                    <MoinTextField
                                        maxCharacters={200}
                                        showCharacterCount={false}
                                        value={phoneNumber}
                                        placeholder={t('user.mobile')}
                                        onChange={e => {
                                            setHasChanges(true);
                                            setPhoneNumber(e.target.value)
                                        }}
                                        error={ errors && errors['phoneNumber']}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <Typography variant={'h2'}>
                                { t('language.smsLanguage').toUpperCase() }
                            </Typography>

                            <MoinSelect
                                value={language}
                                onChange={e => setLanguage(e.target.value)}
                            >
                                {
                                    Object.keys(SmsLanguage)
                                        .map(key =>
                                            <MenuItem
                                                key={SmsLanguage[key]}
                                                value={SmsLanguage[key]}
                                            >
                                                {getTranslationForSMSLanguage(t,SmsLanguage[key])}
                                            </MenuItem>)
                                }
                            </MoinSelect>
                        </div>
                    </div>
                </MoinCardContent>
                <Divider />
                <MoinCardHeader style={{alignItems: "flex-end" }}>
                    <MoinButton disabled={saving || invalid} onClick={validate}>{t('dialogs.continue')}<ArrowRightIcon style={{"margin-left": "8px"}} fill={Colors.WHITE} width={16} height={16}/></MoinButton>
                </MoinCardHeader>
            </MoinCard>
        </>
    );
};

export default ChatExternalUser;