import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import GroupMemberContainer from "./GroupMemberContainer";
import GroupCreateEdit from "./GroupCreateEdit";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import ModelManager from "../../utils/manager/ModelManager";
import ApiClient from "../../api/ApiClient";
import Distributor from "../../utils/models/Distributor";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {Redirect} from "react-router-dom";
import User from "../../utils/models/User";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import {getGroupAvatarPath} from "../../utils/helper/ImageHelper";
import * as ROUTES from "../../Routes";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import {deleteModelInstanceDispatch, updateModelInstance} from "../../utils/store/actions";
import AUTHDISTRIBUTORMEMBERSHIP from "../../utils/auth/AuthDistributorMembership";
import Skeleton from "@material-ui/lab/Skeleton";
import SUCCESSNOTIFICATIONMANAGER from "../../components/success/SuccessNotificationManager";
import SuccessMessage from "../../utils/models/SuccessMessage";
import {SuccessType} from "../../utils/enums/SuccessType";
import {FireDelete, FireTimestamp} from "../../api/FirebaseService";
import parameters from "../../config/parameters.json";
import AuthStorage from "../../utils/auth/AuthStorage";
import {Permission} from "../../utils/enums/Permission";

/**
 *
 * @param {Distributor} distributor
 * @param {Array<String>} userIds
 * @param {Array<User>} users
 * @returns {JSX.Element}
 * @constructor
 */
const GroupCreateEditContainer = ({distributorId, distributor,userIds= [], users=[], deleteModelInstanceDispatch }) => {
    const [saving, setSaving] =  useState(false);
    const [redirect, setRedirect] =  useState(false);

    const onSave = (name, privacy, description, selectedUserIds, newAvatar, deleteAvatar=false) => {

        if (!saving) {
            setSaving(true);
            let selectedUsers = selectedUserIds.map( (id) => users.find( (user) => user.id === id) ).filter( (user) => !!user );

            if (!distributor) {
                let distributor = Distributor.from(
                    {
                        "name": name,
                        "description": description,
                        'privacy' : privacy,
                        "cUid": AUTHSTORAGE.getUserId(),
                        "count": selectedUserIds.length,
                        'sample': selectedUsers.slice(0, 5 <= selectedUsers.length ? 5 : selectedUsers.length).map( (user) => user.getAsMinimalUser())
                    }
                );

                if (  newAvatar) {
                    distributor.imageOwner = AUTHSTORAGE.getUserId() ;
                }

                ModelManager.add({modelInstance: distributor}).then(async (result) => {
                    AUTHDISTRIBUTORMEMBERSHIP.addMembership(result.id);

                    let avatarUpload = getGroupAvatarPath(AUTHSTORAGE.getUserId(), result.id);
                    if (!!newAvatar) {
                        await new FirebaseStorageManager().uploadImage(newAvatar, avatarUpload , 2048 , 1);
                        let fireStorageUrl = new FireStorageUrl();
                        fireStorageUrl.id = avatarUpload;
                        deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
                    }
                    setRedirect(true);

                    linkUnlinkUsers(result.id, name, selectedUserIds);
                    SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.GROUPCREATED, true));
                });
            } else if (distributor && distributor.id === parameters.globalDistributorId){

                if ( newAvatar) {
                    distributor.imageOwner = AUTHSTORAGE.getUserId() ;
                }
                if (deleteAvatar) {
                    distributor.imageOwner = FireDelete();
                }
                ModelManager.update({
                    modelInstance: distributor,
                    id: parameters.globalDistributorId,
                    customData: {
                        'imageOwner': distributor.imageOwner,
                        'uAt': FireTimestamp()
                    }
                }).then(async (result) => {
                    let avatarUpload = getGroupAvatarPath(AUTHSTORAGE.getUserId(), result.id);
                    if (!!newAvatar) {
                        await new FirebaseStorageManager().uploadImage(newAvatar, avatarUpload , 2048 , 1);
                        let fireStorageUrl = new FireStorageUrl();
                        fireStorageUrl.id = avatarUpload;
                        deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
                    }
                    setRedirect(true);
                    SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.GROUPCREATED, true));
                });
            } else {
                let oldMembers =  Array.from(userIds);
                if (!distributor.isAuthUserMember) {
                    oldMembers = oldMembers.filter( (id) => id !== AUTHSTORAGE.getUserId());
                }

                distributor.name = name;
                distributor.description = description;
                distributor.privacy = privacy;
                distributor.isAuthUserMember = true;
                distributor.count = selectedUserIds.length;
                distributor.uAt = new Date();
                distributor.sample = selectedUsers.slice(0, 5 <= selectedUsers.length ? 5 : selectedUsers.length).map( (user) => user.getAsMinimalUser());
                if (deleteAvatar) {
                    distributor.imageOwner = FireDelete();
                }
                else if ( distributor.imageOwner || newAvatar) {
                    distributor.imageOwner = newAvatar ? AUTHSTORAGE.getUserId() : distributor.imageOwner;
                }

                updateModelInstance(Distributor,distributor);
                ModelManager.update({modelInstance: distributor}).then( async () => {
                    let avatarUpload = getGroupAvatarPath(AUTHSTORAGE.getUserId(), distributor.id);

                    if (!!newAvatar) {
                        await new FirebaseStorageManager().uploadImage(newAvatar, avatarUpload , 2048 , 1);
                    } else if (deleteAvatar){
                        await new FirebaseStorageManager().deleteFile(avatarUpload);
                    }
                    let fireStorageUrl = new FireStorageUrl();
                    fireStorageUrl.id = avatarUpload;
                    deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);

                    setRedirect(true);
                    let newMembers = Array.from(selectedUserIds);
                    let newMembersFiltered = newMembers.filter( (id) => !oldMembers.includes(id));
                    let oldMembersFiltered = oldMembers.filter( (id) => !newMembers.includes(id));

                    if (newMembersFiltered.length > 0) {
                        linkUnlinkUsers(distributor.id, name, newMembersFiltered);
                    }

                    if (oldMembersFiltered.length >0) {
                        linkUnlinkUsers(distributor.id, name, oldMembersFiltered, true);
                    }
                    SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.GROUPUPDATED, true));

                });
            }
        }

    };

    const linkUnlinkUsers = async (id, name, userIds, unlink = false) => {
        let body = {};
        body['distributorId'] = id;
        body['distributorName'] = name;
        body['userIds'] = JSON.stringify(userIds);

        const request = await ApiClient.createRequest(unlink ? '/unlinkUsersFromDistributor' : '/linkUsersToDistributor', body);
        await request.send();
    }


    if (userIds.length > 0 && userIds[0] !== AUTHSTORAGE.getUserId()) {
        userIds = userIds.filter( (user) => user !== AUTHSTORAGE.getUserId())
        userIds.splice(0, 0, AUTHSTORAGE.getUserId());
    } else if (userIds.length === 0){
        userIds = [AUTHSTORAGE.getUserId()];
    }

    if(redirect || (distributor && distributor.id === parameters.globalDistributorId && !AuthStorage.hasPermission(Permission.GLOBALGROUPTITLEIMAGEEDIT)))
        return <Redirect push to={ROUTES.groups} />;

    if (distributorId && !distributor)
        return <Skeleton variant="rect" width="100%" height="100%" />

    return <GroupCreateEdit key={distributor ? distributor.id : 'newDistributor'} distributor={distributor} userIds={userIds} saving={saving} onSave={onSave}/>
};

const mapState= (state, ownProps) => (
     {
        distributorId: ownProps.distributorId,
        users: getModelInstances(state, User),
        distributor: !!ownProps.distributorId
            ? getModelInstance(state, Distributor, ownProps.distributorId)
            : undefined,
    }
);


const mapAction = {
    deleteModelInstanceDispatch
};

const GroupCreateEditContainerState = connect(mapState,mapAction)(GroupCreateEditContainer)

/**
 *
 * @param {String }distributorId
 * @returns {JSX.Element}
 * @constructor
 */
const GroupCreateEditContainerWrapper = ({distributorId}) => {

    if (!!distributorId) {
        return (
            <GroupMemberContainer  distributorId={distributorId}>
                {
                    ({userIds}) => <GroupCreateEditContainerState distributorId={distributorId} userIds={userIds}/>
                }
            </GroupMemberContainer>
        )
    }
    return (
        <GroupCreateEditContainerState distributor={undefined} userIds={[]}/>
    )
}
export default GroupCreateEditContainerWrapper;