import React from "react";
import MoinTextField from "./MoinTextField";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "../../assets/icons/search.svg";
import CloseIcon from "../../assets/icons/close.svg";
import {Colors} from "../helper/ColorHelper";

export default ({onClear, ...props}) => (
  <MoinTextField
    type="search"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon fill={Colors.BLACKLIGHTEST} />
        </InputAdornment>
      ),
        endAdornment: (
        <InputAdornment position="end" style={{'cursor': 'pointer'}} onClick={() => onClear()}>
            <CloseIcon fill={Colors.BLACKLIGHTEST} />
        </InputAdornment>
        ),
    }}
    {...props}
  />
)
