import AbstractType from "./AbstractType";
import TypeRegistry from "../manager/TypeRegistry";

export default class ArrayType extends AbstractType
{

    innerType;

    constructor({entry, entryOptions} = {}) {

        super();

        this.innerType = TypeRegistry.configure(entry, entryOptions);
        this.toFirestore = this.toFirestore.bind(this);
        this.fromFirestore = this.fromFirestore.bind(this);
    }

    toFirestore = (values = []) => {
        return values.map(value => this.innerType.toFirestore(value));
    };

    fromFirestore = (values = []) => {
        return values.map(value => this.innerType.fromFirestore(value));
    };


    static type = "array";

    test = (value) => Array.isArray(value);

}