import React, {Fragment, useState} from 'react';
import {connect} from "react-redux";
import {updateModelInstance, updateModelInstanceDispatch} from "../../utils/store/actions";
import {getModelInstance} from "../../utils/store/selectors";
import Distributor from "../../utils/models/Distributor";
import ApiClient from "../../api/ApiClient";
import ModelManager from "../../utils/manager/ModelManager";
import DistributorRequest from "../../utils/models/DistributorRequest";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import MoinButton from "../../components/base/MoinButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../components/helper/ColorHelper";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import User from "../../utils/models/User";
import HIGHLIGHTEDARTICLES from "../../utils/articles/HighlightedArticles";
import {FireDelete, FireTimestamp} from "../../api/FirebaseService";
import AuthStorage from "../../utils/auth/AuthStorage";
import Article from "../../utils/models/Article";
import HIGHLIGHTEDGROUPS from "../../utils/groups/HighlightedGroups";


const useStyles = makeStyles((theme) => ({

    deleteDialogActions: {
        justifyContent: 'space-between'
    },
}));

/**
 *
 * @param authUser
 * @param {Distributor} distributor
 * @param {Distributor} oldestHighlightedGroup
 * @param {Function} children
 * @param {Function} updateModelInstanceDispatch
 * @returns {JSX.Element}
 * @constructor
 */
const GroupMembershipContainer = ({authUser, distributor,oldestHighlightedGroup,children, updateModelInstanceDispatch}) => {

    const [rebuild,setRebuild] = useState(false);
    const [showDelete,setShowDelete] = useState(false);
    const [showLeave,setShowLeave] = useState(false);
    const [showHighlightDialog,setShowHighlightDialog] = useState(false);
    const [redirectResponsible,setRedirectResponsible] = useState(false);
    const [redirectToOverview,setRedirectToOverview] = useState(false);
    const [t, i18n] = useTranslation();

    const classes = useStyles();

    const _makeDistributorMembershipRequest = async  (path, data) => {
        setRebuild(!rebuild);
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        let body = {};
        body['distributorId'] = !!data && data.id ? data.id : distributor.id;
        body['distributorName'] = !!data && data.name ? data.name : distributor.name;
        const request = await ApiClient.createRequest(path,body);
        await request.send();
        setRebuild(!rebuild);
    }

    const onRequest = () => {
        if (distributor.isPublic) {
            distributor.isAuthUserMember = true;
            if (distributor.sample && distributor.sample.length <= 5) {
                distributor.sample.push(authUser.getAsMinimalUser());
            }
            distributor.count++;
        } else {
            distributor.hasAuthUserRequest = true;
            distributor.requests++;
        }
        _makeDistributorMembershipRequest('/requestDistributorMembership')
        updateModelInstance(Distributor, distributor);
    }

    const onLeave = () => {
        setShowLeave(true);
    }

    const onLeaveConfirm = () => {
        distributor.isAuthUserMember = false;
        distributor.count--;
        distributor.sample.filter( (user) =>  user.id !== AUTHSTORAGE.getUserId());
        _makeDistributorMembershipRequest('/unlinkSelfFromDistributor')

        updateModelInstance(Distributor, distributor);
    }

    const onRequestRemove= () => {
        distributor.hasAuthUserRequest = false;
        _makeDistributorMembershipRequest('/removeDistributorMembershipRequest')
        updateModelInstance(Distributor, distributor);
    }

    const onDelete =() => {
        setShowDelete(true);
    }

    const onDeleteConfirm = async () => {
        distributor.isAuthUserMember = false;

        try {
            let _requests = await ModelManager.list( {model: DistributorRequest, resource: "/client/" + AUTHSTORAGE.getClientId() + "/distributor/" + distributor.id + "/request"});
            _requests.forEach( model => ModelManager.delete({modelInstance: model, resource: "/client/" + AUTHSTORAGE.getClientId() + "/distributor/" + distributor.id + "/request"} ))
        } catch (ex){ console.log("Could not delete open requests")}
         let data={ id: distributor.id, name: distributor.name}
        setRedirectToOverview(true);
        await ModelManager.delete({modelInstance: distributor, resource: "/client/" + AUTHSTORAGE.getClientId() + "/distributor/"});

        _makeDistributorMembershipRequest('/unlinkUsersForDeletedDistributor',data )
    }

    const toggleGroupHighlight = () => {
        let oldHighlighted = HIGHLIGHTEDGROUPS.isHighlighted(distributor.id);
        if (!!oldHighlighted || HIGHLIGHTEDGROUPS.canHighlightMoreGroups()) {
            executeToggleGroupHighlight();
        } else {
            setShowHighlightDialog(true);
        }
    };

    const executeToggleGroupHighlight = async () => {
        let oldHighlighted = HIGHLIGHTEDGROUPS.isHighlighted(distributor.id);

        let highlightData = {};

        if (!HIGHLIGHTEDGROUPS.canHighlightMoreGroups() && !oldHighlighted) {
            let oldest = HIGHLIGHTEDGROUPS.getOldestHighlightedGroup();
            HIGHLIGHTEDGROUPS.toggleGroupHighlighted(oldest);
            highlightData[oldest] = FireDelete();
        }

        HIGHLIGHTEDGROUPS.toggleGroupHighlighted(distributor.id);

        if(!oldHighlighted) {
            highlightData[distributor.id] = FireTimestamp();
        } else {
            highlightData[distributor.id] = FireDelete();
        }

        ModelManager.update({useSet: true, stopEvents: true, resource: "/client/" + AuthStorage.getClientId() + "/highlightedGroups/", id: '_all', customData: highlightData});
        updateModelInstanceDispatch(Distributor, distributor);
    };

    const getHighlightDialog = () => {
        return (
            <Dialog
                open={showHighlightDialog}
                onClose={() => setShowHighlightDialog(false)}
                onClick={(event) => {event.stopPropagation(); event.preventDefault();return false}}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('distributor.highlight.fixGroup') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        { t('distributor.highlight.maximumAmountOfGroupsForHighlight') }
                    </DialogContentText>
                    <DialogContentText id="delete-dialog-description">
                        {!!oldestHighlightedGroup && oldestHighlightedGroup.name}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={() => {
                        setShowHighlightDialog(false);
                    }} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    <MoinButton onClick={() => {
                        executeToggleGroupHighlight();
                        setShowHighlightDialog(false);
                    }}>{ t('distributor.highlight.fixGroup') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    }

    const getDeleteDialog = () => {
        return (
            <Dialog
                open={showDelete}
                onClose={() => setShowDelete(false)}
                onClick={(event) => {event.stopPropagation(); event.preventDefault();return false}}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('distributor.delete') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">{ t('distributor.deleteDescription', {name: distributor.name, count: distributor.count}) }</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={(event) => {setShowDelete(false);event.preventDefault(); return false;}} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    <MoinButton onClick={(event) => {setShowDelete(false);event.preventDefault(); onDeleteConfirm(); return false;}}>{ t('buttons.delete') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    };


    const getLeaveDialog = () => {
        let title;
        let description;
        let action;
        let onAction;
        if (distributor.cUid === AUTHSTORAGE.getUserId() && distributor.count === 1) {
            title =  t('distributor.lastUserDeleteGroup');
            description =   t('distributor.lastUserDeleteGroupDescription');
            action =   t('buttons.delete');
            onAction = onDeleteConfirm;
        } else if (distributor.cUid === AUTHSTORAGE.getUserId()) {
            title =  t('distributor.leave');
            description =   t('distributor.mustChangeResponsibleDescription', {name: distributor.name});
            action =   t('distributor.changeResponsible');
            onAction = () => setRedirectResponsible(true);
        } else {
            title =  t('distributor.leave');
            description =   t('distributor.leaveDescription', {name: distributor.name});
            action =   t('distributor.leave');
            onAction =   onLeaveConfirm;
        }

        return <div onClick={ (event) => {event.preventDefault(); event.stopPropagation(); return false;}}>
            <Dialog
                open={showLeave}
                onClose={() => setShowLeave(false)}
                aria-labelledby="leave-dialog-title"
                aria-describedby="leave-dialog-description"
            >
                <DialogTitle id="leave-dialog-title">{ title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="leave-dialog-description">{description }</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={(event) => {setShowLeave(false); event.preventDefault(); return false; }} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    <MoinButton onClick={(event) => {setShowLeave(false); onAction();  event.preventDefault(); return false; }}>{action}</MoinButton>
                </DialogActions>
            </Dialog>
        </div>;
    };

    if(redirectResponsible) {
        return (
            <Redirect push to={ROUTES.groupResponsible.replace(":id", distributor.id)}/>
        )
    }


    if(redirectToOverview) {
        return <Redirect push to={ROUTES.groups}/>
    }

    return(
        <Fragment>
            {
                showDelete && getDeleteDialog()
            }
            {
                showLeave && getLeaveDialog()
            }
            {
                showHighlightDialog && getHighlightDialog()
            }
            {
                children({onRequest,onRequestRemove,onLeave, onDelete, toggleGroupHighlight})
            }
        </Fragment>
    );
};

const mapState = (state, ownProps) => {
    const oldestHighlightedGroup = getModelInstance(state, Distributor, HIGHLIGHTEDGROUPS.getOldestHighlightedGroup());

    return {
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId()),
        distributor: getModelInstance(state, Distributor,ownProps.distributorId),
        oldestHighlightedGroup: oldestHighlightedGroup
    };
};

const mapAction = {
    updateModelInstanceDispatch
};

export default connect(mapState, mapAction)(GroupMembershipContainer);
