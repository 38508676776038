import React from 'react';
import {connect} from "react-redux";
import {Skeleton} from "@material-ui/lab";
import User from "../../utils/models/User";
import EventParticipantsShow from "./EventParticipantsShow";
import Event from "../../utils/models/Event";
import {updateModelInstanceDispatch} from "../../utils/store/actions";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import EventMembership from "../../utils/models/EventMembership";

/**
 * Container that loads the the participants of an event for the event participants show page
 *
 * @param {String} eventId
 * @param {Event} event
 * @param {Array<User>} users
 * @param {Array<User>} memberships
 * @returns {JSX.Element}
 * @constructor
 */
const EventParticipantsShowContainer = ({eventId, event, users, memberships}) => {

    if (!event) {
        return <Skeleton variant={'rect'} width={'100%'} height={'100%'}/>
    }

    const eventMemberships = (memberships || [] ).filter( (participation) => !!participation && !!participation.events && participation.events.hasOwnProperty(eventId) ).map(participation => participation.id);
    const members = users.filter( (user) => eventMemberships.includes(user.uid))

    return(
            <EventParticipantsShow users={members} eventTitle={event.title}/>
    );
};

const mapState = (state, ownProps) => {

    return {
        memberships: getModelInstances(state, EventMembership),
        event: getModelInstance(state, Event, ownProps.eventId),
        users: getModelInstances(state, User)
    }
};

const mapAction = {
    updateModelInstanceDispatch,
};


export default connect(mapState,mapAction)(EventParticipantsShowContainer);