import {id, model, prop} from "../manager/decorators";
import MinimalUser from "./MinimalUser";
import {SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import {getLargeUserAvatarPath, getUserAvatarPath} from "../helper/ImageHelper";
import * as forge from "node-forge";
import {BirthdayPrivacy} from "../enums/BirthdayPrivacy";
import {DateTime} from "luxon";

@model("/client/:client/user")
class User
{

    /**
     * id without generator
     * the uid is handled bay application and must be identical to the firebase user id
     */
    @id(null)
    id;


    get uid() {
       return id;
    };

    get avatar() {
        if(!this.hA)
            return undefined;
        return getUserAvatarPath(this.id);
    };

    get largeAvatar() {
        if(!this.hA)
            return undefined;
        return getLargeUserAvatarPath(this.id);
    };

    get getAvatarUrl() {
        return getUserAvatarPath(this.id);
    };

    get getLargeAvatarUrl() {
        return getLargeUserAvatarPath(this.id);
    };

    @prop()
    username;

    @prop()
    email;

    @prop()
    emailPrivacy;

    @prop()
    salutation;

    @prop()
    firstName;

    @prop()
    lastName;

    @prop({type: "date", readOnly: true, sentinel: SERVER_TIMESTAMP})
    createdAt;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP})
    updatedAt;

    @prop()
    position;

    @prop()
    department;

    @prop()
    addDep;

    @prop()
    office;

    @prop()
    personalInfo;

    @prop()
    mobilePhone;

    @prop()
    competencies;

    @prop()
    workPhone;

    @prop({type: "bool", readOnly: true})
    external = false;

    @prop({type: "bool", readOnly: true})
    deleted = false;

    @prop()
    publicKey;

    @prop({type: "bool"})
    hA = false;

    @prop({type: "bool"})
    enabled = false;

    @prop({type: "bool"})
    hasLoggedIn = false;

    @prop({})
    uid;

    @prop({
        type: "array",
        typeOptions: {
            entry: "string"
        }
    })
    appRoles;

    @prop({type: "date"})
    birthday;

    @prop()
    birthdayPrivacy;

    get hasBirthday() {
        if (this.birthdayPrivacy === BirthdayPrivacy.PUBLIC || this.birthdayPrivacy === BirthdayPrivacy.NOYEAR) {
            const birthday = new DateTime.fromJSDate(this.birthday);
            const today = new DateTime.local();
            if (birthday.month === today.month && birthday.day === today.day) {
                return true;
            }
        }
        return false;
    }

    get age() {
        return DateTime.local().diff(DateTime.fromJSDate(this.birthday), "years")
            .years
            .toFixed(0)
            .toString();
    }

    get fullName() {
        return this.firstName + " " + this.lastName
    }

    matchesSearch(search) {
        search = search.toLowerCase();

        return this.firstName.toLowerCase().includes(search)
        || this.lastName.toLowerCase().includes(search)
        || (!!this.position && this.position.toLowerCase().includes(search) )
        || (!!this.department && this.department.toLowerCase().includes(search)) ;
    }

    getAsMinimalUser() {
        let minimalUser =  new MinimalUser();
        minimalUser.uid = this.id;
        minimalUser.name = this.fullName;
        return minimalUser;
    }

}

export default User;