class HighlightedArticles {
    #_highlightedArticles = {};

    /**
     *
     * @param {Object} highlightedArticles
     */
    setHighlightedArticles = (highlightedArticles) => {
        this.#_highlightedArticles = {};
        Object.keys(highlightedArticles).forEach(articleId => {
            let highlightDate = highlightedArticles[articleId];

            // When an article is highlighted, the listener fires with a null timestamp,
            // and shortly after that fires again with the correct timestamp
            if(highlightDate) {
                highlightDate = highlightDate.toDate();
            } else {
                highlightDate = new Date();
            }

            this.#_highlightedArticles[articleId] = highlightDate;
        });
    };

    toggleArticleHighlighted = (articleId) => {
        if(this.isHighlighted(articleId)) {
            delete this.#_highlightedArticles[articleId];
        } else {
            this.#_highlightedArticles[articleId] = new Date();
        }
    }

    isHighlighted(articleId) {
        return Object.keys(this.#_highlightedArticles).includes(articleId);
    }

    getArticleHighlightDate(articleId) {
        return this.#_highlightedArticles[articleId];
    }

    canHighlightMoreArticles() {
        return Object.keys(this.#_highlightedArticles).length < 3;
    }

    getOldestHighlightedArticle() {
        if (Object.keys(this.#_highlightedArticles).length === 0) {
            return undefined;
        }
        return Object.keys(this.#_highlightedArticles).reduce((a, b) => this.#_highlightedArticles[a].getTime() < this.#_highlightedArticles[b].getTime() ? a : b);
    }

    reset() {
        this.#_highlightedArticles = [];
    }
}

const HIGHLIGHTEDARTICLES = new HighlightedArticles();

export default HIGHLIGHTEDARTICLES;
