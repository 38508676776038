import React, {Fragment, useRef, useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import {Link, useHistory, withRouter} from "react-router-dom";
import * as ROUTES from "../Routes";
import NotificationsActiveIcon from "../assets/icons/notification-active.svg";
import NotificationsNoneIcon from "../assets/icons/notification-inactive.svg";
import {getModelInstances, getModelInstance} from "../utils/store/selectors";
import MessagingCount from "../utils/models/MessagingCount";
import {connect} from "react-redux";
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import { Colors } from "../components/helper/ColorHelper";
import MoinSearchField from "../components/base/MoinSearchField";
import User from "../utils/models/User";
import Event from "../utils/models/Event";
import Distributor from "../utils/models/Distributor";
import {
    sortEventsWithMatchQuality,
    sortGroupsWithMatchQuality,
    sortUsersWithMatchQuality,
} from "../utils/helper/SortHelper";
import Divider from "@material-ui/core/Divider";
import Department from "../utils/models/Department";
import {getStartToEnd, timestampToDate} from "../utils/helper/TextHelper";
import {STORAGECLIENT} from "../utils/storage/StorageClient";
import {StorageFileType} from "../utils/enums/StorageFileType";
import {setFileTypeIcon} from "../utils/helper/FileIconHelper";
import {TASKCLIENT} from "../utils/tasks/TaskClient";
import CheckIcon from "../assets/icons/check.svg";
import CheckOutlineIcon from "../assets/icons/check-outline.svg";
import FirestoreImage from "../components/base/FirestoreImage";
import TaskDate from "../components/tasks/TaskDate";
import MASTERTHEME from "./MasterTheme";
import AUTHSTORAGE from "../utils/auth/AuthStorage";

const MasterAppBar = ({ location, messagingCount, users, groups, events,departments}) => {
    const classes = MASTERTHEME.getStyles();
    const [t] = useTranslation();
    const [search, setSearch] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [searchedFiles, setSearchedFiles] = useState([]);
    const [searchedTasks, setSearchedTasks] = useState({});
    let lastRequest = useRef(0);
    const history = useHistory();
    const isStorage = location && location.pathname &&  location.pathname.startsWith("/storage")
    const isTask = location && location.pathname &&  location.pathname.startsWith("/tasks")


    const searchTasks = async (search) => {
        let requestTime = Date.now()
        lastRequest.current = requestTime;

        let taskData = await TASKCLIENT.searchTasks(search, 5, undefined, undefined, undefined, true);
        if (requestTime === lastRequest.current) {
            setSearchedTasks(taskData);
        }
    }

    const searchFiles = async (search) => {

        let requestTime = Date.now()
        lastRequest.current = requestTime;

        let fileData = await STORAGECLIENT.searchFiles(search, 5);

        if (requestTime === lastRequest.current) {
            setSearchedFiles(fileData);
        }
    }

    let unreadMessagingCount = !!messagingCount && !!messagingCount ? messagingCount.count : 0;
    if (unreadMessagingCount > 0) {
        window.document.title = 'moin! Web (' + unreadMessagingCount + ')';
    } else {
        window.document.title = 'moin! Web';
    }

    let searchResults = {};
    let resultSize = 5;
    if (!!search) {
        let searchString = search.toLowerCase();

        let userResults = users.filter ( (user) => user.fullName.toLowerCase().includes(searchString));
        if (userResults && userResults.length > 0) {
            userResults.sort((a,b) => sortUsersWithMatchQuality(a,b,search))
            userResults = userResults.slice(0, resultSize);
            searchResults['users'] = userResults;
        }

        let groupResults = groups.filter ( (group) => group.name.toLowerCase().includes(searchString));
        if (groupResults && groupResults.length > 0) {
            groupResults.sort((a,b) => sortGroupsWithMatchQuality(a,b,search))
            groupResults = groupResults.slice(0, resultSize);
            searchResults['groups'] = groupResults;
        }

        let eventResults = events.filter ( (event) => event.title.toLowerCase().includes(searchString));
        if (eventResults && eventResults.length > 0) {
            eventResults.sort((a,b) => sortEventsWithMatchQuality(a,b,search))
            eventResults = eventResults.slice(0, resultSize);
            searchResults['events'] = eventResults;
        }
    }

    const getMatchedTextElement = (text, search) => {
        let startOfMatch =text.toLowerCase().indexOf(search.toLowerCase());
        if (startOfMatch >=0 ) {
            let endOfMatch = startOfMatch + search.length;
            let firstPart = text.substr(0, startOfMatch);
            let matchPart = text.substr(startOfMatch, search.length);
            let lastPart =  text.substr(endOfMatch, text.length);
            text=  [firstPart, <b>{matchPart}</b>, lastPart]
        }
        return text;
    }

    const renderSearchResultsStorage = (searchedFiles) => {
        let content = [];

        searchedFiles.result.forEach( (file) => {
            content.push(
                <div className={classes.storageSearchSuggestion} onClick={
                    () => {
                        if (file.type === StorageFileType.FOLDER) {
                            let folderPath;
                            if (file.path !== '') {
                                folderPath = file.path + '!!' + file.id;
                            } else {
                                folderPath = file.id;
                            }

                            if ( folderPath.length > 1 && folderPath[0] === "/") {
                                folderPath = folderPath.substring(1);
                            }
                            history.push(ROUTES.storageWithPath.replace(":query", folderPath));
                        } else {
                            history.push(ROUTES.storageSearch.replace(":query", file.name.replaceAll('.','!!')));
                            setSearch(file.name);
                        }

                        setShowSearch(false);
                    }
                }>
                    <div className={classes.storageSearchFileIcon}>
                        {
                            setFileTypeIcon(file)
                        }
                    </div>
                    <Typography variant="h2" className={classes.storageResultName}>
                        { file.name }
                    </Typography>
                    <Typography variant={"caption"} className={classes.storageSearchUpdated}>{timestampToDate(file.cAt)}</Typography>
                </div>
            )
        });

        return  <div  className={classes.headerSearchResultWrapper} onClick={() => setShowSearch(false)}>
            <div className={classes.headerSearchResultContainer}>
                <div className={classes.headerSearchResultContent}>
                    {content}
                </div>
            </div>
        </div>;
    }

    const renderSearchResultsTasks = (searchedTasks) => {
        if (!searchedTasks || !searchedTasks.result) {
            return;
        }

        let content = [
            <>
                <Typography variant="h4" display="block" style={{marginTop: "16px", marginBottom: "8px"}}>
                    { t('search.headlines.tasks', {count: searchResults.total}) }
                </Typography>
                <Divider style={{marginBottom: '8px'}}/>
            </>
        ]

        searchedTasks.result.forEach( (task) => {
            let assignee;

            if (task.aUid) {
                assignee = users.find( (user) => user.id === task.aUid)
            }

            content.push(
                <div className={classes.tasksSearchSuggestion}
                     onClick={ () => {
                         history.push(ROUTES.tasksSearch.replace(":query", search.replaceAll('.','!!')));
                         setSearch('');
                     }}>

                    <div className={classes.taskIcon}>
                        {
                            task.getCompletedStatusForDate(new Date())
                                ? <div className={classes.taskIconContainer} style={{backgroundColor: Colors.ACTIVEGREEN}}>
                                    <CheckIcon fill={Colors.WHITE}/>
                                </div>
                                : <CheckOutlineIcon fill={Colors.BLACKLIGHTEST}/>
                        }
                    </div>
                    <div className={classes.taskInfo}>
                        {
                            task.getCompletedStatusForDate(new Date())
                                ? <Typography className={classes.taskNameCompleted} variant={"body2"}>{task.title}</Typography>
                                : <Typography variant={"body2"}>{task.title}</Typography>
                        }
                        {
                            task.dueDate &&
                            <TaskDate
                                task={task}
                                completed={task.getCompletedStatusForDate(new Date())}/>
                        }
                    </div>
                    <span className={classes.taskAssignee}>
                        {
                            task.aUid &&
                            assignee &&
                            <FirestoreImage
                                key={'avatar-flash'}
                                alt={assignee.fullName}
                                src={assignee.avatar}
                                radius={16}
                                style={{}}
                                isAvatar={true}
                                backgroundColor={Colors.identifierAsColor(assignee.id)}
                            >
                                {`${assignee.firstName[0]}${assignee.lastName[0]}`}
                            </FirestoreImage>
                        }
                    </span>
                </div>
            )
        });

        return content;
    }

    const renderSearchResults = (searchResults) => {
        let content = [];

        if (searchResults['users']) {
            content.push(
                <>
                    <Typography variant="h4" display="block" style={{marginTop: "16px", marginBottom: "8px"}}>
                        { t('search.headlines.contacts', {count: searchResults['users'].length}) }
                    </Typography>
                    <Divider style={{marginBottom: '8px'}}/>
                </>
            )

            searchResults.users.forEach( (user) => {
                    let department = departments.find(department => user.department === department.id);
                    let text = getMatchedTextElement(user.fullName, search);

                    content.push(
                        <div className={classes.searchSuggestion} onClick={
                            () => {
                                history.push(ROUTES.userShow.replace(":id", user.id));
                                setSearch('');
                            }
                        }>
                            <Typography variant="body2">
                                { text }
                            </Typography>
                            <Typography variant="caption" style={{marginLeft: "4px"}}>
                                {
                                    !!user.external
                                        ? `• ${t('user.externalMember')}`
                                        : `${department?.name ? '•' + department.name : ''} ${ (user.position ? ' •' : '') + (user.position || '')}`
                                }

                            </Typography>
                        </div>
                    )
                }
            )
        }


        if (searchResults['groups']) {
            content.push(
                <>
                    <Typography variant="h4" display="block" style={{marginTop: searchResults['users'] ? "32px" : "16px", marginBottom: "8px"}}>
                        { t('search.headlines.groups', {count: searchResults['groups'].length}) }
                    </Typography>
                    <Divider style={{marginBottom: '8px'}}/>
                </>
            )

            searchResults.groups.forEach( (group) => {
                    let text = getMatchedTextElement(group.name, search);
                    let memberCount;
                    switch (group?.count) {
                        case undefined:
                            break;
                        case 0:
                            memberCount = t('distributor.noMembersCount');
                            break;
                        case 1:
                            memberCount = t('distributor.singleMemberCount');
                            break;
                        default:
                            memberCount = t('distributor.xMembersCount', {count: group?.count})
                            break;
                    }

                    content.push(
                        <div className={classes.searchSuggestion} onClick={
                            () => {
                                history.push(ROUTES.groupShow.replace(":id", group.id));
                                setSearch('');
                            }
                        }>
                            <Typography variant="body2">
                                { text }
                            </Typography>
                            <Typography variant="caption" style={{marginLeft: "4px"}}>
                                {`• ${group.isPublic ? t('distributor.public') : t('distributor.private')} ${memberCount ? '• ' + memberCount : ''}`}
                            </Typography>
                        </div>
                    )
                }
            )
        }

        if (searchResults['events']) {
            content.push(
                <>
                    <Typography variant="h4" display="block" style={{marginTop: (searchResults['users'] || searchResults['groups'] ) ? "32px" : "16px", marginBottom: "8px"}}>
                        { t('search.headlines.events', {count: searchResults['events'].length}) }
                    </Typography>
                    <Divider style={{marginBottom: '8px'}}/>
                </>
            )
            searchResults.events.forEach( (event) => {
                    let text = getMatchedTextElement(event.title, search);

                    content.push(
                        <div className={classes.searchSuggestion} onClick={
                            () => {
                                history.push(ROUTES.eventShow.replace(":id", event.id));
                                setShowSearch(false);
                            }
                        }>
                            <Typography variant="body2">
                                { text }
                            </Typography>
                            <Typography variant="caption" style={{marginLeft: "4px"}}>
                                {`• ${getStartToEnd(event.start, event.end)} ${event.location ? '• ' + event.location : ''}`}
                            </Typography>
                        </div>
                    )
                }
            )
        }

        return  <div  className={classes.headerSearchResultWrapper} onClick={() => setShowSearch(false)}>
            <div className={classes.headerSearchResultContainer}>
                <div className={classes.headerSearchResultContent}>
                    {content}
                    <div style={{margin: "1rem"}}/>
                </div>
            </div>
        </div>    ;
    }

    const onKeyDown = (e) => {
        if(e.keyCode === 13 && !!search){
            if (isStorage) {
                history.push(ROUTES.storageSearch.replace(":query", search.replaceAll('.','!!')));
            } else if (isTask) {
                history.push(ROUTES.tasksSearch.replace(":query", search.replaceAll('.','!!')));
            } else {
                history.push(ROUTES.search.replace(":query", search));
            }
            setShowSearch(false);
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    };

    const onSearch = (search) => {
        setSearch(search);
        if (isStorage) {
            searchFiles(search);
        } else if (isTask || !isStorage) {
            searchTasks(search);
        }
    }

    return <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, classes.appBarShift)}  // The current version of material ui seems to have a bug, the classname is overwritten by defaults
        style={{width: `calc(100% - 240px)`, marginLeft: "240px"}}
        >
        <Toolbar variant="dense">
            <div className={classes.headerSearchBarContainer}>
                <div className={classes.appBarAction} style={{visibility: "hidden"}}>
                    {
                        unreadMessagingCount > 0
                            ? <Fragment>
                                <NotificationsActiveIcon fill={Colors.BRANDPRIMARY} />
                                <div className={classes.iconCounter}> <Typography variant={"h4"}>{unreadMessagingCount}</Typography></div>
                            </Fragment>
                            :  <NotificationsNoneIcon fill={Colors.BRANDPRIMARY} />
                    }
                </div>
                {
                    AUTHSTORAGE.isExternal()
                    ? <></>
                    : <>
                        <MoinSearchField
                            onChange={e => {
                                if (!showSearch) {
                                    setShowSearch(true);
                                }
                                onSearch(e.target.value);
                            }}
                            onClear={ () => onSearch('')}
                            placeholder={isStorage ?  t('search.placeholderStorage') : t('search.placeholder')}
                            className={classes.headerSearchBar}
                            value={search}
                            style={{margin:0, padding: "0 1rem"}} // The current version of material ui seems to have a bug, the classname is overwritten by defaults
                            onKeyDown={onKeyDown}
                            onClick={() => setShowSearch(true)}
                        />
                        <div className={classes.appBarAction}>
                            <Link to={ROUTES.notifications} className={classes.iconWithCounter}>
                                {
                                    unreadMessagingCount > 0
                                        ? <Fragment>
                                            <NotificationsActiveIcon fill={Colors.BRANDPRIMARY} />
                                            <div className={classes.iconCounter}> <Typography className={ unreadMessagingCount > 99 ? classes.iconCounterTextSmall: ""} variant={"h4"}>{unreadMessagingCount > 99 ? "99+" : unreadMessagingCount}</Typography></div>
                                        </Fragment>
                                        :  <NotificationsNoneIcon fill={Colors.BRANDPRIMARY} />
                                }
                            </Link>
                        </div>
                    </>
                }

            </div>
        </Toolbar>

        {
            showSearch && !isStorage &&  !isTask && !!searchResults && (searchResults.users || searchResults.groups || searchResults.events ) && renderSearchResults(searchResults)
        }
        {
            showSearch && isStorage && !!searchedFiles  && !!searchedFiles.result && searchedFiles.result.length > 0 && renderSearchResultsStorage(searchedFiles)
        }
        {
            showSearch && isTask && !!searchedTasks  && !!searchedTasks.result  && searchedTasks.result.length > 0 &&
            <div  className={classes.headerSearchResultWrapper} onClick={() => setShowSearch(false)}>
                <div className={classes.headerSearchResultContainer}>
                    <div className={classes.headerSearchResultContent}>
                        { renderSearchResultsTasks(searchedTasks) }
                    </div>
                </div>
            </div>
        }

    </AppBar>
}


const mapState = (state) => ({
    messagingCount: getModelInstance(state,MessagingCount,"notcount"),
    users: getModelInstances(state,User),
    events: getModelInstances(state,Event),
    groups: getModelInstances(state,Distributor),
    departments: getModelInstances(state, Department)
});

export default withRouter(connect(mapState)(MasterAppBar));