import {model} from "../manager/decorators";

@model("/client/:client/user/:user/article_heart")
class ArticleReaction {
    id;
    type;
}

export default ArticleReaction;

