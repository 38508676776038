import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FireStorageImage from "./FirestoreImage";
import {connect} from "react-redux";
import { getModelInstances} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import {Colors} from "../helper/ColorHelper";
import FirestoreImage from "./FirestoreImage";

/**
 *
 * @param {Array.<String>} ids
 * @param {Array.<User>} users
 * @param {number} limit
 * @param {boolean} showMore
 * @param {number} radius
 * @constructor
 */
const MoinSampleUsersList =  ({ids, users,showMore, limit=5, radius=12}) => {
    const filteredUsers = users.filter( (user) => ((ids ||[] ).includes(user.uid)))

    let [userWidgets, setUserWidgets] = useState([]);

    if (showMore === undefined) {
        showMore = filteredUsers.length > limit
    }
    let usersPart = filteredUsers.slice(0, limit);

    const buildUsers = (renderUsers) => {
        let userWidgets= [];
        renderUsers.map((user) => {
            if (!!user) {
                userWidgets.push(
                    <FireStorageImage
                        radius={radius}
                        backgroundColor={Colors.identifierAsColor(user.id)}
                        style={{ marginLeft: Math.ceil(- ( radius / 2)) + "px"}}
                        key={"avatar-user-" + user.uid}
                        isAvatar={true}
                        src={ user.avatar}
                        alt={ user.fullName}
                    />)
            }
        });
        setUserWidgets(userWidgets);
    }

    if (userWidgets.length !== usersPart.length) {
        buildUsers(usersPart);
    }

    return (
        <Grid container style={{marginLeft: Math.ceil( ( radius / 2)) + "px", width: "auto"}}>
            {
                userWidgets
            }
            {showMore &&
            <FireStorageImage
                radius={radius}
                style={{ marginLeft: Math.ceil(- ( radius / 2)) + "px"}}
                backgroundColor={Colors.BLUELIGHT}
                isAvatar={true}
                alt={ "..."}
            />}
        </Grid>
    )
};

const mapState = (state, ownProps) =>  ({
    users: getModelInstances(state,User),
});

export default connect(mapState)(MoinSampleUsersList);
