import React from "react";
import clsx from "clsx";
import CheckIcon from "../../assets/icons/check.svg";
import Checkbox from "@material-ui/core/Checkbox";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";

const useStyles = makeStyles(() => ({
    checkBox: {
        width: '1em',
        height: '1em',
        borderRadius: '5px'
    },
    unchecked: {
        border: '1px solid ' + Colors.BLUELIGHT,
        backgroundColor: Colors.WHITE,
    },
    checked: {
        backgroundColor: Colors.BRANDPRIMARY,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    checkIcon: {
        fill: Colors.WHITE,
        width: '80%',
        height: '80%'
    }
}));

/**
 * Moin! themed checkbox. Use exactly like normal Mui checkbox component.
 *
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const MoinCheckBox = ({...rest}) => {
    const classes = useStyles();

    return <Checkbox
        icon={<div className={clsx(classes.checkBox, classes.unchecked)} />}
        checkedIcon={<div className={clsx(classes.checkBox, classes.checked)}><CheckIcon className={classes.checkIcon} /></div>}
        {...rest}
    />
}

export default MoinCheckBox;