import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Divider, Typography} from "@material-ui/core";
import FirestoreImage from "../components/base/FirestoreImage";
import {makeStyles} from "@material-ui/core/styles";
import {Redirect} from "react-router-dom";
import {Colors} from "../components/helper/ColorHelper";
import {toDateString} from "../utils/helper/TextHelper";
import MoinListItem from "../components/base/MoinListItem";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(1)
    },
    readLinkWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    avatarWrapper: {
        position: 'relative'
    },
    unreadBobble: {
        position: 'absolute',
        backgroundColor: Colors.BRANDSECONDARY,
        height: '12px',
        width: '12px',
        top: '0px',
        right: '8px',
        borderRadius: '12px'
    },
    link: {
        color: Colors.BRANDSECONDARY,
        cursor: "pointer",
        textTransform: "none",
        textDecoration: "none",

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    },
    notificationOverviewContainer: {
        marginTop: theme.spacing(2)
    }
}));

/**
 *
 * @param {Messaging[]} messagings
 * @param {Function} loadMessagings
 * @param {Function} onMessagingRead
 * @param {Function} onAllMessagingsRead
 * @param {Date} lastNotificationOpen
 * @param {Object} users
 * @param {MessagingCount} unreadMessagesCount
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationOverview = ({messagings,loadMessagings, onMessagingRead, onAllMessagingsRead, lastNotificationOpen, users, unreadMessagesCount}) => {
    const [t, i18n] = useTranslation();
    const classes = useStyles();
    const messagingWrapper = useRef();
    const [redirectTarget, setRedirectTarget] = useState(undefined);

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle, true);
        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [messagings]);

    const checkForUnreadMessages = () => {
        let hasUnreadNotifications = false;
        for (let i = 0; i < messagings.length; i++) {
            if (!messagings[i].read) {
                hasUnreadNotifications = true;
                break;
            }
        }
        return hasUnreadNotifications;
    }

    const onScrollHandle = () => {
        if(!!messagingWrapper.current && messagingWrapper.current.getBoundingClientRect().bottom <= window.innerHeight + 250 && !!messagings[messagings.length - 1]) {
            loadMessagings(messagings[messagings.length - 1].cAt);
        }
    };

    const onClickNotification = (notification) => {
        onMessagingRead(notification);
        setRedirectTarget(notification.getLinkPath());
    };

    /**
     * @param {Messaging} messaging
     * @param {number} index
     * @returns {JSX.Element}
     */
    const renderMessaging = (messaging, index) => {
        if(!messaging) {
            return <Fragment key={'messaging-missing-index-' + index}></Fragment>;
        }

        let user = users.find(user => user.id === (messaging.cUid ?? messaging.uId));

        // If the creator of a messaging does not exist the messaging will most likely redirect to a deleted article
        if (!user) {
            return <Fragment key={'messaging-missing-user-' + messaging.id}></Fragment>
        }

        const avatarImage = <div className={classes.avatarWrapper}>
            <FirestoreImage
                key={'notification-' + messaging.id + '-avatar-' + (messaging.cUid ?? messaging.uId)}
                alt={messaging?.getFallback()}
                src={messaging?.getAvatar()}
                backgroundColor={Colors.identifierAsColor(user?.id)}
                isAvatar={true}
            />
            {
                !messaging.read &&
                <div className={classes.unreadBobble}/>
            }
        </div>;

        return (
            <div key={messaging.id}>
                {
                    index > 0 &&
                    <Divider/>
                }
                <MoinListItem
                    withDivider={false}
                    onClick={() => onClickNotification(messaging)}
                    image={avatarImage}
                    title={messaging.getDescription(t)}
                    subtitle={toDateString(messaging.cAt)}
                />
            </div>
        );
    };

    const newThreshold = new Date();
    newThreshold.setDate(newThreshold.getDate() - 1);

    if(!!redirectTarget) {
        return <Redirect push to={redirectTarget}/>;
    }

    if(messagings.length === 0) {
        return (
            <div>
                <Typography variant="h3">{ t('messaging.emptyStateNotification') }</Typography>
                <Typography variant="body2">{ t('messaging.emptyStateNotificationBody') }</Typography>
            </div>
        );
    }

    let hasUnreadMessages = (unreadMessagesCount?.count ?? 0) > 0;

    if(!hasUnreadMessages) {
        hasUnreadMessages = checkForUnreadMessages();
    }

    return (
        <div ref={(node) => messagingWrapper.current = node} className={classes.notificationOverviewContainer}>
            {
                hasUnreadMessages &&
                    <div className={classes.readLinkWrapper}>
                        <Typography
                            variant="body2"
                            className={classes.link}
                            onClick={() => onAllMessagingsRead()}
                        >
                            { t('messaging.markAllAsRead') }
                        </Typography>
                    </div>
            }
            {
                messagings.length > 0 &&
                    <>
                        <Divider/>
                        { messagings.map((messaging, index) => renderMessaging(messaging, index)) }
                    </>
            }
        </div>
    );
};

export default NotificationOverview;