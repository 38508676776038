import React, {useEffect, useRef, useState} from 'react';
import MasterLayout from "./MasterLayout";
import ModelManager from "../utils/manager/ModelManager";
import User from "../utils/models/User";
import Distributor from "../utils/models/Distributor";
import Chat from "../utils/models/Chat";
import {connect} from "react-redux";
import {
    addModelInstance,
    addModelInstanceDispatch,
    addModelInstances,
    deleteModelInstance, deleteModelInstanceDispatch,
    updateModelInstance, updateModelInstanceDispatch,
} from "../utils/store/actions";
import Department from "../utils/models/Department";
import Messaging from "../utils/models/Messaging";
import MessagingCount from "../utils/models/MessagingCount";
import Article from "../utils/models/Article";
import Event from "../utils/models/Event";
import MinimalUser from "../utils/models/MinimalUser";
import {ContentType} from "../utils/enums/ContentType";
import Message from "../utils/models/Message";
import {MessagingType} from "../utils/enums/MessagingType";
import {sortUsers} from "../utils/helper/SortHelper";
import AuthStorage from "../utils/auth/AuthStorage";
import AUTHSTORAGE from "../utils/auth/AuthStorage";
import EventMembership from "../utils/models/EventMembership";
import Role from "../utils/models/Role";
import AUTHDISTRIBUTORMEMBERSHIP from "../utils/auth/AuthDistributorMembership";
import * as forge from "node-forge";
import * as CacheHelper from "../utils/helper/CacheHelper";
import CUSTOMNOTIFICATIONMANAGER from "../components/notifications/CustomNotificationManager";
import {useTranslation} from "react-i18next";
import * as ChatHelper from "../utils/helper/ChatHelper";
import ONLINESTATUSES from "../utils/chat/OnlineStatuses";
import store from "../utils/store/store";
import {ReactionType} from "../utils/enums/ReactionType";
import ArticleReaction from "../utils/models/ArticleReaction";
import CommentReaction from "../utils/models/CommentReaction";
import CommentAnswerReaction from "../utils/models/CommentAnswerReaction";
import {getModelInstance} from "../utils/store/selectors";
import Task from "../utils/models/Task";
import {FireAuth, FireTimestamp} from "../api/FirebaseService";
import {useHistory} from "react-router-dom";
import {
    getLatestAppStart,
    getPenultimateAppStart,
    setLatestAppStart,
    setPenultimateAppStart,
    getHideEvents
} from "../utils/helper/StartHelper";
import {isAtSameDay} from "../utils/helper/DateHelper";
import STORAGECACHEMANAGER from "../components/base/FirestoreCacheManager";
import FireStorageUrl from "../utils/models/FireStorageUrl";
import HIGHLIGHTEDARTICLES from "../utils/articles/HighlightedArticles";
import HIGHLIGHTEDGROUPS from "../utils/groups/HighlightedGroups";

const MasterLayoutContainer = ({client, logout, addModelInstanceDispatch, updateModelInstanceDispatch, deleteModelInstanceDispatch}) => {
    const history = useHistory();
    const initialOpening = useRef(new Date());
    const [rebuild, setRebuild] = useState(true);
    const [t] = useTranslation();

    useEffect(() => {
        setOpeningDates();
        window.addEventListener("beforeunload", (ev) => {
            ChatHelper.clearOnlineState();
            return undefined;
        });

        // connect manager to redux store
        ModelManager
            .addListener('list', ({data: {model, data, extra}}) => handleListedModelInstances(model, data, extra))
            .addListener('add', ({data: {model, modelInstance, extra}}) => handleAddedModelInstance(model, modelInstance, extra))
            .addListener('delete', ({data: {model, modelInstance, extra}}) => handleRemovedModelInstance(model, modelInstance, extra))
            .addListener('update', ({data: {model, modelInstance, extra}}) => handleUpdatedModelInstances(model, modelInstance, extra));

        ModelManager.subscribeDoc({documentPath: "/client/" + AuthStorage.getClientId() + "/dis_member/" + AuthStorage.getUserId(), extraProps: 'ownDistributorMembership'});
        ModelManager.subscribeDoc({documentPath: "/client/" + AuthStorage.getClientId() + "/online_status/_all", extraProps: 'onlineStatuses'});

        ModelManager.subscribe({model: Role});
        ModelManager.subscribe({model: Department, startAfter: initialOpening.current, orderBy: "uAt", orderDirection: "asc", ignoreDeletes: true });
        ModelManager.subscribe({model: EventMembership, id: AuthStorage.getUserId()});

        ModelManager.subscribe({model: User,  startAfter: initialOpening.current, orderBy: "updatedAt", orderDirection: "asc",ignoreDeletes: true });
        ModelManager.subscribe({model: Task, filter: [['relevant', "array-contains", AuthStorage.getUserId()]], startAfter: initialOpening.current, orderBy: "uAt", orderDirection: "asc", ignoreDeletes: true });
        ModelManager.subscribe({model: Distributor, startAfter: initialOpening.current, orderBy: "uAt", orderDirection: "asc", ignoreDeletes: true });
        ModelManager.subscribe({model: Event, startAfter: initialOpening.current, orderBy: "uAt", orderDirection: "asc", ignoreDeletes: true});

        // TODO: As soon as 1.3.12 is used completely we can cache chats and load them via uAt
        ModelManager.subscribe({model: Chat, resource: "/client/" + AuthStorage.getClientId()  + "/user/" + AuthStorage.getUserId()  + "/chat"});
        ModelManager.subscribe({model: Messaging, orderBy: "cAt", orderDirection: "asc", limit: 1, extraProps: 'messaging_listener' });
        ModelManager.subscribeDoc({model: MessagingCount, id: "notcount", withCallback: initNotificationCount, onlyInitialCallback: true});
        ModelManager.subscribeDoc({stopEvents: true, onlyInitialCallback: false, id: '_all',  documentPath: "/client/" + AuthStorage.getClientId() + "/highlightedArticles/_all", withCallback: updateHighlightedArticles});
        ModelManager.subscribeDoc({stopEvents: true, onlyInitialCallback: false, id: '_all',  documentPath: "/client/" + AuthStorage.getClientId() + "/highlightedGroups/_all", withCallback: updateHighlightedGroups});

        loadModelReactions(ArticleReaction);
        loadModelReactions(CommentReaction);
        loadModelReactions(CommentAnswerReaction);
        ChatHelper.updateOnlineState();

        const refreshUserToken = setInterval(() => {
            refreshPermissions();
        }, 300000);

        const updateOnlineState = setInterval(() => {
            ChatHelper.updateOnlineState();
        }, 240000);

        return () => {
            clearInterval(refreshUserToken);
            clearInterval(updateOnlineState);
            ModelManager
                .unsubscribe(User)
                .unsubscribe(Distributor)
                .unsubscribe(Chat)
                .unsubscribe(Task)
                .unsubscribe(Event)
                .unsubscribe(Messaging)
                .unsubscribe(Role)
                .unsubscribe(Department)

                .removeListener('list')
                .removeListener('add')
                .removeListener('delete')
                .removeListener('update')
                .removeListener('error')
        }
    }, []);

    const setOpeningDates = async () => {
        let latestOpening = await getLatestAppStart();
        AUTHSTORAGE.setLatestOpening(latestOpening);
        AUTHSTORAGE.setPenultimateOpeningDay(await getPenultimateAppStart());
        AUTHSTORAGE.setCurrentOpening(new Date());
        AUTHSTORAGE.setHideEvents(await getHideEvents());

        if(!latestOpening || !isAtSameDay(latestOpening, new Date())) {
            setLatestAppStart(new Date());
            setPenultimateAppStart(latestOpening);

            AUTHSTORAGE.setPenultimateOpeningDay(latestOpening);
            AUTHSTORAGE.setLatestOpening(new Date());
        }
    };

    const updateHighlightedArticles = (highlightedArticlesResult) => {
        if (highlightedArticlesResult) {
            HIGHLIGHTEDARTICLES.setHighlightedArticles(highlightedArticlesResult);

            Object.keys(highlightedArticlesResult).forEach(articleId => {
                ModelManager.get({model: Article, id: articleId});
            });
        } else {
            HIGHLIGHTEDARTICLES.setHighlightedArticles({});
        }
    }

    const updateHighlightedGroups = (highlightedGroupsResult) => {
        if (highlightedGroupsResult) {
            HIGHLIGHTEDGROUPS.setHighlightedGroups(highlightedGroupsResult);
        } else {
            HIGHLIGHTEDGROUPS.setHighlightedGroups({});
        }
    }

    const refreshPermissions = async () => {
        let tokenResult  = await FireAuth.currentUser.getIdTokenResult(true).catch((e) => {});
        const {claims} = tokenResult;
        if (claims) {
            const {appRoles, roles} = claims;
            if (appRoles) {
                let permissions = {};
                if (!!appRoles) {
                    permissions=Object.keys(appRoles);
                }
                AUTHSTORAGE.setPermissions(permissions);
                AUTHSTORAGE.setRoles(roles);
                setRebuild((old) => !old);
            }
        }
    }

    const initNotificationCount = (notCount) =>  {
        if (!!notCount && !!notCount.lastOpen &&  ( !notCount.lastCalculated || notCount.lastCalculated < notCount.lastOpen )) {
            let loadDate = new Date();
            loadDate.setDate(loadDate.getDate() - 28);
            ModelManager.list( {model: Messaging, filter: [['read','==',false], ['cAt', ">=",loadDate ]],cache: false}).then(async function (result) {
                ModelManager.update({
                    resource: "/client/" +AUTHSTORAGE.getClientId() + "/user/" + AUTHSTORAGE.getUserId() + '/private/',
                    id: "notcount",
                    customData: {"count": result ? result.length : 0, "lastCalculated": new Date()}
                });
            });
        }
    }

    const handleAddedModelInstance = (model, modelInstance, extra) => {
        // If the Model is Chat, init it (Check if still member, set Listeners). "!modelInstance.cUid" is important, so it doesn't end in an endless loop
        if (!model && !!extra && extra === 'ownDistributorMembership' ) {
           AUTHDISTRIBUTORMEMBERSHIP.setMemberships( Object.keys(modelInstance?.dis));
        } else if(!model && !!extra && extra === 'onlineStatuses') {
            if (modelInstance) {
                ONLINESTATUSES.setOnlineUsers(
                    Object.keys(modelInstance).filter(
                        (key) => modelInstance[key] && (modelInstance[key].toDate().getTime() + 5 * 60 * 1000) > Date.now()
                    )
                );
            }
        } else if(model.key === "messaging" && !!extra && extra === 'messaging_listener'  ) {
            onMessaging(modelInstance);
        } else if(model.key === "chat" && !modelInstance.cUid) {
            if(!(modelInstance.deleted ?? false)) {
                initChat(model, modelInstance);
            } else {
                deleteModelInstanceDispatch(model, modelInstance);
            }
        } else if(model.key === "message") {
            onNewMessage(model, modelInstance, extra);
        } else if(model.key === "distributor") {
            onAddDistributor(modelInstance);
        } else if (model.key === "user") {
            if ((modelInstance.deleted  || !modelInstance.enabled) ) {
                deleteModelInstanceDispatch(model, modelInstance);
            } else {
                STORAGECACHEMANAGER.removeUrlFromRequested(modelInstance.getAvatarUrl);
                let avatarUrl = new FireStorageUrl();
                avatarUrl.id = modelInstance.getAvatarUrl;
                deleteModelInstanceDispatch(FireStorageUrl,avatarUrl);
                STORAGECACHEMANAGER.removeUrlFromRequested(modelInstance.getLargeAvatarUrl);
                let avatarUrlLarge = new FireStorageUrl();
                avatarUrlLarge.id = modelInstance.getLargeAvatarUrl;
                deleteModelInstanceDispatch(FireStorageUrl,avatarUrlLarge);
            }
        } else {
            if(model.key === "article" && extra && extra.stream) {
                modelInstance.stream = true;
            }

            if(model.key === "distributormembership" && extra) {
                modelInstance.distributorId = extra.distributorId;
            }
            if(model.key === "distributorrequest" && extra) {
                modelInstance.distributorId = extra.distributorId;
            }

            if (!!model && model.key === "task") {
                CacheHelper.setCachedTask(modelInstance);
            }

            if(model.key === "event") {
                CacheHelper.setCachedEvent(modelInstance);
            }
            addModelInstanceDispatch(model, modelInstance);
        }
    };

    const handleListedModelInstances = (model, data, extra) => {
        if(model.key === "message") {
            data.forEach(message => onNewMessage(model, message, extra));
        } else if((model.key === "surveyanswer" || model.key === "surveyvote") && !!extra) {
            data.forEach(answer => answer.surveyId = extra.surveyId);
            addModelInstances(model, data);
        } else {
            addModelInstances(model, data);
        }
    };

    const handleUpdatedModelInstances = (model, modelInstance, extra) => {

        if (!!model && model.key === "article" && !!extra && !!extra.completionCheck) {
            let videos = modelInstance.content.filter((content) => content.type === ContentType.MEDIA && !!content.video);

            if ( videos.length > 0 && videos.filter((video) => !!video.url).length === 0) {
               // FLASHMANAGER.dispatch('flash', {'message': trans('article.videoUploadCompleted'), 'type': "success"});
                ModelManager.unsubscribe({ model: Article, resource: '/client/' + AuthStorage.getClientId() + '/article/' + modelInstance.id});
            }
        }

        if (!!model && model.key === "task") {
            CacheHelper.setCachedTask(modelInstance);
        }

        if(!!model && model.key === "article" && extra && extra.stream) {
            modelInstance.stream = true;
        }

        if (!!model && model.key === "event") {
            CacheHelper.setCachedEvent(modelInstance);
        }

        if (!!model && model.key === "message" && !!extra && !!extra.completionCheck && !!extra.chat) {
            modelInstance = extra.chat.decryptMessage(modelInstance);
            modelInstance.chatId = extra.chat.id;
            if (modelInstance.videos.length > 0 && modelInstance.videos.filter((video) => !video.uploadId).length === 0) {
                // FLASHMANAGER.dispatch('flash', {'message': trans('article.videoUploadCompleted'), 'type': "success"});
                ModelManager.unsubscribe({ model: Message, resource: `/client/${AUTHSTORAGE.getClientId()}/chat/${extra.chat.id}/message` + modelInstance.id});
            }
        }

        if (!model && !!extra && extra === 'ownDistributorMembership' ) {
            if (!!modelInstance?.dis) {
                AUTHDISTRIBUTORMEMBERSHIP.setMemberships( Object.keys(modelInstance?.dis));
            } else {
                AUTHDISTRIBUTORMEMBERSHIP.setMemberships([]);
            }
        } else if(!model && !!extra && extra === 'onlineStatuses') {
            if (modelInstance) {
                ONLINESTATUSES.setOnlineUsers(
                    Object.keys(modelInstance).filter(
                        (key) => modelInstance[key] && (modelInstance[key].toDate().getTime() + 5 * 60 * 1000) > Date.now()
                    )
                );
            }
        } else if(!model && !!extra && !!extra.chat) {
            // Update Model Instance only, if it isn't a message without a cUid
            onUpdatedChatMember(modelInstance, extra);
        } else if (model.key === "user") {
            if ((modelInstance.deleted  || !modelInstance.enabled) ) {
                deleteModelInstanceDispatch(model, modelInstance);
            } else {
                STORAGECACHEMANAGER.removeUrlFromRequested(modelInstance.getAvatarUrl);
                let avatarUrl = new FireStorageUrl();
                avatarUrl.id = modelInstance.getAvatarUrl;
                deleteModelInstanceDispatch(FireStorageUrl,avatarUrl);
                STORAGECACHEMANAGER.removeUrlFromRequested(modelInstance.getLargeAvatarUrl);
                let avatarUrlLarge = new FireStorageUrl();
                avatarUrlLarge.id = modelInstance.getLargeAvatarUrl;
                deleteModelInstanceDispatch(FireStorageUrl,avatarUrlLarge);
            }
        } else if(model.key === "chat") {
            let cachedChats = store.getState()[Chat.key];
            let cachedChat;
            if (cachedChats) {
                cachedChat = store.getState()[Chat.key].find(modelInstance => modelInstance[model.metadata.idProp] === modelInstance.id);
            }

            if(!!cachedChat && cachedChat.archived) {
                initChat(model, modelInstance);
            }
        } else if (!!modelInstance && ((!model || model.key !== "message") || (!!modelInstance.cUid)) ) {

            if (model.key !== "distributor" || !!modelInstance.name) {
                updateModelInstanceDispatch(model, modelInstance);
            }
        }
    };

    const handleRemovedModelInstance = (model, modelInstance, extra) => {
        // Delete Model Instance only, if it isn't the last message of a chat
        if(!extra || !extra.chat || extra.chat.lastMessage?.id !== modelInstance.id) {
            deleteModelInstanceDispatch(model, modelInstance);
        }
    };

    const initChat = (model, chat) => {
        ModelManager.get({model: Chat, id: chat.id, stopEvents: true}).then(async (loadedChat) => {
            if(!!loadedChat) {
                loadedChat.isActive = true;
                loadedChat.uMC = chat.uMC ?? 0;
                loadedChat.archived = chat.archived ?? false;

                if (loadedChat.uMC === 0) {
                    loadedChat.hidden = (await CacheHelper.checkIfChatIsHidden(loadedChat.id) ) || false;
                }

                if(!!chat.symKey) {
                    let cachedKey = await CacheHelper.getEncryptionKeyForChatId(loadedChat.id);
                    if(!!cachedKey) {
                        loadedChat.symKey = cachedKey;
                    } else {
                        try {
                            loadedChat.symKey = AUTHSTORAGE.getPrivateKey().decrypt(forge.util.decode64(chat.symKey));
                            CacheHelper.setEncryptionKeyForChat(loadedChat.id, loadedChat.symKey);
                        } catch (ex) {
                            console.log("Could not decrypt chat with id " + chat.id);
                            return;
                        }
                    }
                }

                let chats = store.getState()[Chat.key];
                if (chats) {
                    let storeChat = chats.find(modelInstance => modelInstance[model.metadata.idProp] === loadedChat.id);
                    if (!!storeChat) {
                        loadedChat.lastMessage = storeChat.lastMessage || loadedChat.lastMessage ;
                    }
                }

                CacheHelper.setCachedChat(loadedChat);
                updateModelInstance(model, loadedChat);
                ModelManager.subscribe({model: Message, resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/chat/${loadedChat.id}/message` , orderBy: 'cAt', orderDirection: 'desc', limit: 1, ignoreDeletes: true, extraProps: {chat: loadedChat, isNew: true}});
                ModelManager.subscribeDoc({documentPath: Chat.collection() + '/' + loadedChat.id + '/user/_all', extraProps: {chat: loadedChat}});
            }
        }, async (error) => {
            chat.isActive = false;
            chat.archived = true;
            chat.hidden = (await CacheHelper.checkIfChatIsHidden(chat.id)) || false;
            updateModelInstance(model, chat);
        });
    };

    /**
     * @param model
     * @param {Message} message
     * @param {Object} extra
     * @param {Chat} extra.chat
     * @param {boolean} extra.isNew
     * @param {Message} extra.unencryptedMessage
     */
    const onNewMessage = (model, message, extra) => {
        if(!!message.cUid) {
            if(!!extra?.unencryptedMessage) {
                let chats = store.getState()[Chat.key];
                let chat;
                if (chats) {
                    chat = store.getState()[Chat.key].find(modelInstance => modelInstance[model.metadata.idProp] === extra.chat.id);
                }
                if (!chat) {
                    chat = extra.chat;
                }

                chat.lastMessage = extra.unencryptedMessage;

                updateModelInstance(Chat, chat);
                updateModelInstance(model, extra.unencryptedMessage);
            }
        } else {
            ModelManager.get({model: Message, id: message.id, resource: Message.collection().replace(':chat', extra.chat.id), stopEvents: true}).then(loadedMessage => {
                if(!!loadedMessage) {
                    loadedMessage.chatId = extra.chat.id;

                    let chats = store.getState()[Chat.key];
                    let chat;
                    if (chats) {
                        chat = store.getState()[Chat.key].find(modelInstance => modelInstance[model.metadata.idProp] === loadedMessage.chatId);
                    }
                    if (!chat) {
                        chat = extra.chat;
                    }

                    // if Message is Encrypted -> decrypt it
                    if(!!loadedMessage.iv) {
                        loadedMessage = chat.decryptMessage(loadedMessage);
                    }

                    if(!!extra.isNew) {
                        if(history.location.pathname.toLowerCase().startsWith('/chat/')) {
                            let pathParts = history.location.pathname.split('/');
                            let openChatId = pathParts[2];
                            if (chat.id === openChatId) {
                                ModelManager.update({customData: {[AUTHSTORAGE.getUserId()]: FireTimestamp()}, useSet: true, resource: `/client/${AUTHSTORAGE.getClientId()}/chat/${openChatId}/user`, id: 'lastReadDate'});
                            }
                        }

                        // Set last Message on Chat
                        if (chat.lastMessage && chat.lastMessage.id !== loadedMessage.id) {
                            chat.hidden = false;
                        }
                        chat.lastMessage = loadedMessage;
                        CacheHelper.setCachedChat(chat);
                        updateModelInstance(Chat, chat);

                        if(loadedMessage.cUid !== AUTHSTORAGE.getUserId() && loadedMessage.cAt.getTime() > initialOpening.current) {
                            let newMessageMessaging = new Messaging();
                            newMessageMessaging.cUid = loadedMessage.cUid;
                            newMessageMessaging.cName = loadedMessage.cName;
                            newMessageMessaging.messageText = loadedMessage.getTeaserText(t);
                            newMessageMessaging.chatName = extra.chat.name;
                            newMessageMessaging.cId = extra.chat.id;
                            newMessageMessaging.type = MessagingType.MESSAGENEW;

                            CUSTOMNOTIFICATIONMANAGER.dispatch('customNotification',  newMessageMessaging);
                        }
                    }
                    addModelInstanceDispatch(model, loadedMessage);
                }
            });
        }
    };

    const onMessaging = (messaging) => {
        if (messaging && !!messaging.cAt && messaging.cAt.getTime() > initialOpening.current) {
          //  CUSTOMMESSAGINGMANAGER.dispatch('messaging',{'messaging': messaging});
        }
    }

    const loadModelReactions = async (model) => {
        let heartsResult =  await ModelManager.list({model: model, convert: false});
        // Loop document ids

        Object.keys(heartsResult).forEach(function(documentId) {
            let heartDocData = heartsResult[documentId];
            // Loop article ids per document

            Object.keys(heartDocData).forEach(function(key) {
                let reactionType = ReactionType.LIKE;
                if((typeof heartDocData[key]) === 'string') {
                    reactionType = heartDocData[key];
                }

                let reaction = new model();
                reaction.id = key;
                reaction.type = reactionType;
                addModelInstanceDispatch(model, reaction);
            });
        });
    };

    const onUpdatedChatMember = (data, extra) => {
        let chatMember = [];

        Object.keys(data).forEach(key => {
            let user = new MinimalUser();
            user.uid = key;
            user.name = data[key];
            chatMember.push(user);
        });
        chatMember.sort(sortUsers);

        extra.chat.member = chatMember;
        updateModelInstance(Chat, extra.chat);
    };

    const onAddDistributor = async (distributor) => {

        if (!!distributor && !!distributor.name) {
            CacheHelper.setCachedDistributor(distributor);
            addModelInstanceDispatch(Distributor, distributor);

            let model = await ModelManager.get({id: AuthStorage.getUserId(), resource: Distributor.collection() + '/' + distributor.id + '/request/', stopEvents: true})
            if (!!model) {
                AUTHDISTRIBUTORMEMBERSHIP.addRequest(distributor.id)
            }

        }
    }
    return (
        <MasterLayout client={client} logout={logout}/>
    );
};


const mapState = (state, ownProps) => ({
    client: AUTHSTORAGE.getClient(),
    authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
});

const mapAction = {
    addModelInstances,
    addModelInstanceDispatch,
    updateModelInstanceDispatch,
    deleteModelInstanceDispatch,
    deleteModelInstance,
    updateModelInstance,
    addModelInstance
};

export default connect(mapState,mapAction) (MasterLayoutContainer);
