// THESE 2 IMPORTS SEEM UNUSED, BUT ARE NEEDED, IF YOU WANT THE NAVBAR TO USE THE CORRECT STYLES!
// Also, these 2 imports must be the first imports!
// If "MoinUserListItem" and "MoinListItem" are not the first imports, please add them

import {NavLink, useHistory, withRouter} from "react-router-dom";
import * as ROUTES from "../Routes";
import React, {useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import {ActiveNavBar} from "../utils/enums/ActiveNavBar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import HomeActiveIcon from "../assets/icons/home-active.svg";
import HomeInactiveIcon from "../assets/icons/home-inactive.svg";
import ContactsActiveIcon from "../assets/icons/contacts-active.svg";
import ContactsInactiveIcon from "../assets/icons/contacts-inactive.svg";
import ChatsActiveIcon from "../assets/icons/chats-active.svg";
import ChatsInactiveIcon from "../assets/icons/chats-inactive.svg";
import GroupsActiveIcon from "../assets/icons/groups-active.svg";
import GroupsInactiveIcon from "../assets/icons/groups-inactive.svg";
import EventsActiveIcon from "../assets/icons/events-active.svg";
import EventsInactiveIcon from "../assets/icons/events-inactive.svg";
import BookmarkFilledIcon from "../assets/icons/bookmark-filled.svg";
import BookmarkInactiveIcon from "../assets/icons/bookmark-inactive.svg";
import ChevronDownIcon from "../assets/icons/chevron-down.svg";
import ChevronUpIcon from "../assets/icons/chevron-up.svg";
import StethoscopeIcon from "../assets/icons/stethoscope.svg";
import HelpOutlineIcon from "../assets/icons/help-outline.svg";
import UserIcon from "../assets/icons/user.svg";
import LogoutIcon from "../assets/icons/logout.svg";
import InfoIcon from "../assets/icons/info.svg";
import SettingsIcon from "../assets/icons/settings.svg";
import FolderFilled from "../assets/icons/folder-filled.svg";
import FolderOutlined from "../assets/icons/folder-outline.svg";
import CheckclistIcon from "../assets/icons/checklist.svg";
import List from "@material-ui/core/List";
import {useTranslation} from "react-i18next";
import AUTHSTORAGE from "../utils/auth/AuthStorage";
import FireStorageImage from "../components/base/FirestoreImage";
import Divider from "@material-ui/core/Divider";
import {connect} from "react-redux";
import {getModelInstance, getModelInstances} from "../utils/store/selectors";
import User from "../utils/models/User";
import Department from "../utils/models/Department";
import Language from "../utils/i18n/LanguageDialog";
import ChangePassword from "../components/user/ChangePasswordDialog";
import {Colors} from "../components/helper/ColorHelper";
import {Feature} from "../utils/enums/Feature";
import HeartActiveIcon from "../assets/icons/heart-active.svg";
import HeartInactiveIcon from "../assets/icons/heart-inactive.svg";
import MASTERTHEME from "./MasterTheme";
import Chat from "../utils/models/Chat";
import parameters from "../config/parameters.json";
import {Role} from "../utils/enums/Role";
import ShareForwardIcon from "../assets/icons/share-forward-outline.svg";
import ExternalIcon from "../assets/icons/external.svg";

const MasterNav = ({authUser, department, chats, logout}) =>  {
    const history = useHistory();
    const classes = MASTERTHEME.getStyles();

    const [t, i18n] = useTranslation();
    const [showAbout, setShowAbout] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showExternalLinks, setShowExternalLinks] = useState(false);
    const currentRoute = history.location.pathname.toLowerCase();
    let activeNavBar;
    let unreadMessages = !!chats && chats.length > 0 && chats.some( (chat) => !!chat.uMC && chat.uMC > 0)

    switch(true) {
        case currentRoute === "/":
        case currentRoute.startsWith(ROUTES.dashboard):
        case currentRoute.startsWith(ROUTES.articleFeed):
            activeNavBar = ActiveNavBar.DASHBOARD;
            break;
        case currentRoute.startsWith(ROUTES.groups):
            activeNavBar = ActiveNavBar.GROUPS;
            break;
        case currentRoute.startsWith(ROUTES.contacts):
            activeNavBar = ActiveNavBar.CONTACTS;
            break;
        case currentRoute.startsWith(ROUTES.events):
            activeNavBar = ActiveNavBar.EVENTS;
            break;
        case currentRoute.startsWith(ROUTES.favoritedArticles):
            activeNavBar = ActiveNavBar.FAVORITEDARTICLES;
            break;
        case currentRoute.startsWith(ROUTES.sendHug):
            activeNavBar = ActiveNavBar.HUGS;
            break;
        case currentRoute.startsWith(ROUTES.chat):
            activeNavBar = ActiveNavBar.CHAT;
            break;
        case currentRoute.startsWith(ROUTES.impressNav):
            activeNavBar = ActiveNavBar.IMPRESS;
            break;
        case currentRoute.startsWith(ROUTES.dpdNav):
            activeNavBar = ActiveNavBar.DPD;
            break;
        case currentRoute.startsWith(ROUTES.termsNav):
            activeNavBar = ActiveNavBar.TERMS;
            break;
        case currentRoute.startsWith(ROUTES.illness):
            activeNavBar = ActiveNavBar.ILLNESS;
            break;
        case currentRoute.startsWith(ROUTES.storage):
            activeNavBar = ActiveNavBar.STORAGE;
            break;
        case currentRoute.startsWith(ROUTES.tasks):
            activeNavBar = ActiveNavBar.TASKS;
            break;
        case currentRoute.startsWith(ROUTES.userShow.replace(":id", AUTHSTORAGE.getUserId()).toLowerCase()):
            activeNavBar = ActiveNavBar.OWNPROFILE;
            break;
        default:
            break;
    }

    const getExternalNav = () => {
        return (
            <List>
                <NavLink className={classes.navLink} to={ROUTES.chat}>
                    <ListItem className={activeNavBar === ActiveNavBar.CHAT ? classes.drawerItemActive : classes.drawerItem} button key='nav.messages'>
                        <ListItemIcon className={classes.navIconSmall}>
                            {
                                activeNavBar === ActiveNavBar.CHAT
                                    ? <ChatsActiveIcon className={classes.navIconActive}/>
                                    : <ChatsInactiveIcon className={classes.navIcon}/>
                            }
                            {
                                unreadMessages &&
                                <div className={classes.iconAttentionMarker}/>
                            }
                        </ListItemIcon>
                        <Typography variant="caption">
                            {t('nav.messages')}
                        </Typography>
                    </ListItem>
                </NavLink>
            </List>
        );
    }

    const getUserNav = () => {
        return (
            <List>
                <NavLink className={classes.navLink} to={ROUTES.articleFeed}>
                    <ListItem className={activeNavBar === ActiveNavBar.DASHBOARD ? classes.drawerItemActive : classes.drawerItem} button key={'nav.home'}>
                        <ListItemIcon className={classes.navIconSmall}>
                            {
                                activeNavBar === ActiveNavBar.DASHBOARD
                                    ? <HomeActiveIcon className={classes.navIconActive}/>
                                    : <HomeInactiveIcon className={classes.navIcon}/>
                            }
                        </ListItemIcon>
                        <Typography variant="caption">
                            {t('nav.news')}
                        </Typography>
                    </ListItem>
                </NavLink>
                <NavLink className={classes.navLink} to={ROUTES.contacts}>
                    <ListItem className={activeNavBar === ActiveNavBar.CONTACTS ? classes.drawerItemActive : classes.drawerItem} button key='nav.contacts'>
                        <ListItemIcon className={classes.navIconSmall}>
                            {
                                activeNavBar === ActiveNavBar.CONTACTS
                                    ? <ContactsActiveIcon className={classes.navIconActive}/>
                                    : <ContactsInactiveIcon className={classes.navIcon}/>
                            }
                        </ListItemIcon>
                        <Typography variant="caption">
                            {t('nav.contacts')}
                        </Typography>
                    </ListItem>
                </NavLink>
                <NavLink className={classes.navLink} to={ROUTES.chat}>
                    <ListItem className={activeNavBar === ActiveNavBar.CHAT ? classes.drawerItemActive : classes.drawerItem} button key='nav.messages'>
                        <ListItemIcon className={classes.navIconSmall}>
                            {
                                activeNavBar === ActiveNavBar.CHAT
                                    ? <ChatsActiveIcon className={classes.navIconActive}/>
                                    : <ChatsInactiveIcon className={classes.navIcon}/>
                            }
                            {
                                unreadMessages &&
                                <div className={classes.iconAttentionMarker}/>
                            }
                        </ListItemIcon>
                        <Typography variant="caption">
                            {t('nav.messages')}
                        </Typography>
                    </ListItem>
                </NavLink>
                <NavLink className={classes.navLink} to={ROUTES.groups}>
                    <ListItem className={activeNavBar === ActiveNavBar.GROUPS ? classes.drawerItemActive : classes.drawerItem} button key='nav.groups'>
                        <ListItemIcon className={classes.navIconSmall}>
                            {
                                activeNavBar === ActiveNavBar.GROUPS
                                    ? <GroupsActiveIcon className={classes.navIconActive}/>
                                    : <GroupsInactiveIcon className={classes.navIcon}/>
                            }
                        </ListItemIcon>
                        <Typography variant="caption">
                            {t('nav.groups')}
                        </Typography>
                    </ListItem>
                </NavLink>
                <NavLink  className={classes.navLink} to={ROUTES.events}>
                    <ListItem className={activeNavBar === ActiveNavBar.EVENTS ? classes.drawerItemActive : classes.drawerItem} button key='nav.events'>
                        <ListItemIcon className={classes.navIconSmall}>
                            {
                                activeNavBar === ActiveNavBar.EVENTS
                                    ? <EventsActiveIcon className={classes.navIconActive}/>
                                    : <EventsInactiveIcon className={classes.navIcon}/>
                            }
                        </ListItemIcon>
                        <Typography variant="caption">
                            {t('nav.events')}
                        </Typography>
                    </ListItem>
                </NavLink>
                <NavLink  className={classes.navLink} to={ROUTES.favoritedArticles}>
                    <ListItem className={activeNavBar === ActiveNavBar.FAVORITEDARTICLES ? classes.drawerItemActive : classes.drawerItem} button key='nav.favoriteArticles'>
                        <ListItemIcon className={classes.navIconSmall}>
                            {
                                activeNavBar === ActiveNavBar.FAVORITEDARTICLES
                                    ? <BookmarkFilledIcon className={classes.navIconActive}/>
                                    : <BookmarkInactiveIcon className={classes.navIcon} />
                            }
                        </ListItemIcon>
                        <Typography variant="caption">
                            {t('nav.favorites')}
                        </Typography>
                    </ListItem>
                </NavLink>
                {
                    AUTHSTORAGE.getClient().features.includes(Feature.HUGS) &&
                    <NavLink className={classes.navLink} to={ROUTES.sendHug}>
                        <ListItem
                            className={activeNavBar === ActiveNavBar.HUGS ? classes.drawerItemActive : classes.drawerItem}
                            button key='nav.hugs'>
                            <ListItemIcon className={classes.navIconSmall}>
                                {
                                    activeNavBar === ActiveNavBar.HUGS
                                        ? <HeartActiveIcon className={classes.navIconActive}/>
                                        : <HeartInactiveIcon className={classes.navIcon}/>
                                }
                            </ListItemIcon>
                            <Typography variant="caption">
                                {t('nav.hugs')}
                            </Typography>
                        </ListItem>
                    </NavLink>
                }
                {
                    AUTHSTORAGE.getClient().features.includes(Feature.ILLNESS) && !!AUTHSTORAGE.getClient().hrMail &&
                    <NavLink  className={classes.navLink} to={ROUTES.illness}>
                        <ListItem className={activeNavBar === ActiveNavBar.ILLNESS ? classes.drawerItemActive : classes.drawerItem} button key='nav.illness'>
                            <ListItemIcon className={classes.navIconSmall}>
                                {
                                    activeNavBar === ActiveNavBar.ILLNESS
                                        ? <StethoscopeIcon className={classes.navIconActive}/>
                                        : <StethoscopeIcon className={classes.navIcon}/>
                                }
                            </ListItemIcon>
                            <Typography variant="caption">
                                {t('nav.illness')}
                            </Typography>
                        </ListItem>
                    </NavLink>
                }

                {
                    AUTHSTORAGE.getClient().paid_features.includes(Feature.STORAGE)  &&
                    <NavLink  className={classes.navLink} to={ROUTES.storage}>
                        <ListItem className={activeNavBar === ActiveNavBar.STORAGE ? classes.drawerItemActive : classes.drawerItem} button key='nav.storage'>
                            <ListItemIcon className={classes.navIconSmall}>
                                {
                                    activeNavBar === ActiveNavBar.STORAGE
                                        ? <FolderFilled className={classes.navIconActive}/>
                                        : <FolderOutlined className={classes.navIcon}/>
                                }
                            </ListItemIcon>
                            <Typography variant="caption">
                                {t('nav.storage')}
                            </Typography>
                        </ListItem>
                    </NavLink>
                }

                {
                    AUTHSTORAGE.getClient().paid_features.includes(Feature.TASKS)  &&
                    <NavLink className={classes.navLink} to={ROUTES.tasks}>
                        <ListItem
                            className={activeNavBar === ActiveNavBar.TASKS ? classes.drawerItemActive : classes.drawerItem}
                            button key='nav.tasks'>
                            <ListItemIcon className={classes.navIconSmall}>
                                {
                                    activeNavBar === ActiveNavBar.TASKS
                                        ? <CheckclistIcon className={classes.navIconActive}/>
                                        : <CheckclistIcon className={classes.navIcon}/>
                                }
                            </ListItemIcon>
                            <Typography variant="caption">
                                {t('nav.tasks')}
                            </Typography>
                        </ListItem>
                    </NavLink>
                }

                {
                    (AUTHSTORAGE.getClient().externalLinks &&  AUTHSTORAGE.getClient().externalLinks.length > 0) &&
                    <>
                        <ListItem className={classes.drawerItem} button key={'nav.settings'} onClick={() => setShowExternalLinks(!showExternalLinks)}>
                            <ListItemIcon className={classes.navIconSmall}>
                                <ExternalIcon className={classes.navIcon} />
                            </ListItemIcon>
                            <Typography variant="caption" className={classes.navText}>
                                {t('nav.externalLinks')}
                            </Typography>
                            <ListItemIcon className={classes.navIconSmall}>
                                {
                                    showExternalLinks
                                        ? <ChevronUpIcon className={classes.navIconRight}/>
                                        : <ChevronDownIcon className={classes.navIconRight}/>
                                }
                            </ListItemIcon>
                        </ListItem>

                        <List className={classes.navSubMenu} style={showExternalLinks ? {} : {height: 0}}>
                            { AUTHSTORAGE.getClient().externalLinks.map(
                                    (link) => <a key={'nav.' + link.name} className={classes.navLink} href={link.url} target="_blank" rel="noopener">
                                        <ListItem className={ classes.drawerItemSub} button>
                                            <Typography variant={'caption'} >
                                                {link.name}
                                            </Typography>
                                        </ListItem>
                                    </a>
                                )
                            }
                        </List>
                    </>
                }

                {
                    (AUTHSTORAGE.hasRole(Role.ADMIN) || AUTHSTORAGE.hasRole(Role.CLIENTMANAGER)) &&
                    <a className={classes.navLink} href={parameters.mcpUrl} target="_blank" rel="noopener">
                        <ListItem className={classes.drawerItem} button key='nav.mcp'>
                            <ListItemIcon className={classes.navIconSmall}>
                                <ShareForwardIcon className={classes.navIcon}/>
                            </ListItemIcon>
                            <Typography variant="caption">
                                {t('nav.mcp')}
                            </Typography>
                        </ListItem>
                    </a>
                }
            </List>
        );
    }

    return (
        <div className={classes.navDrawer}>
            {
                !!authUser &&
                <ListItem className={classes.userItem} onClick={ () => history.push(ROUTES.userShow.replace(":id", AUTHSTORAGE.getUserId()))}>
                    <FireStorageImage
                        className={classes.userAvatar}
                        isAvatar={true}
                        src={ authUser.avatar}
                        alt={ authUser.fullName}
                        backgroundColor={Colors.identifierAsColor(authUser.id)}
                    />
                    <Typography variant="h3" className={classes.userName} >{authUser.fullName}</Typography>
                    <Typography variant="caption" className={classes.userDescription}>{`${department?.name ? department.name + ' •' : ''} ${!!authUser.position ? authUser.position : ""}`}</Typography>
                </ListItem>
            }

            <Divider className={classes.navDivider}/>

            <div className={classes.navContent}>
                {
                    AUTHSTORAGE.isExternal() ? getExternalNav() : getUserNav()
                }
                <List>
                    <Divider className={classes.navDivider}/>

                    <NavLink className={classes.navLink} to={ROUTES.userShow.replace(":id", AUTHSTORAGE.getUserId())}>
                        <ListItem className={activeNavBar === ActiveNavBar.OWNPROFILE ? classes.drawerItemActive : classes.drawerItem} button key={'nav.myProfile'}>
                             <ListItemIcon className={classes.navIconSmall}>
                                <UserIcon className={activeNavBar === ActiveNavBar.OWNPROFILE ? classes.navIconActive : classes.navIcon} />
                            </ListItemIcon>
                            <Typography variant="caption">
                                {t('nav.myProfile')}
                            </Typography>
                        </ListItem>
                    </NavLink>

                    <NavLink className={classes.navLink} to={ROUTES.helpAndSupport}>
                        <ListItem className={activeNavBar === ActiveNavBar.HELP ? classes.drawerItemActive : classes.drawerItem} button key={'nav.helpAndSupport'}>
                            <ListItemIcon className={classes.navIconSmall}>
                                <HelpOutlineIcon className={activeNavBar === ActiveNavBar.HELP ? classes.navIconActive : classes.navIcon} />
                            </ListItemIcon>
                            <Typography variant="caption">
                                {t('nav.helpAndSupport')}
                            </Typography>
                        </ListItem>
                    </NavLink>

                    <ListItem className={classes.drawerItem} button key={'nav.settings'} onClick={() => setShowSettings(!showSettings)}>
                        <ListItemIcon className={classes.navIconSmall}>
                            <SettingsIcon className={classes.navIcon} />
                        </ListItemIcon>
                        <Typography variant="caption" className={classes.navText}>
                            {t('nav.settings')}
                        </Typography>
                        <ListItemIcon className={classes.navIconSmall}>
                            {
                                showSettings
                                    ? <ChevronUpIcon className={classes.navIconRight}/>
                                    : <ChevronDownIcon className={classes.navIconRight}/>
                            }
                        </ListItemIcon>
                    </ListItem>

                    {
                        <List className={classes.navSubMenu} style={showSettings ? {} : {height: 0}}>
                            <Language />
                            <ChangePassword />
                        </List>

                    }

                    <ListItem className={classes.drawerItem} button key={'nav.aboutMoin'} onClick={() => setShowAbout(!showAbout)}>
                        <ListItemIcon className={classes.navIconSmall}>
                            <InfoIcon className={classes.navIcon} />
                        </ListItemIcon>
                        <Typography variant="caption" className={classes.navText}>
                            {t('nav.aboutMoin')}
                        </Typography>
                         <ListItemIcon className={classes.navIconSmall}>
                            {
                                showAbout
                                    ? <ChevronUpIcon className={classes.navIconRight}/>
                                    : <ChevronDownIcon className={classes.navIconRight}/>
                            }
                        </ListItemIcon>
                    </ListItem>

                    {
                        <List className={classes.navSubMenu} style={showAbout ? {} : {height: 0}}>
                            <ListItem className={ classes.drawerItemSub} button key='nav.cookies' onClick={ () => window.mmcm.launchDialogue()}>
                                <Typography variant="caption">{t('legal.cookieManagement')}</Typography>
                            </ListItem>

                            <NavLink className={classes.navLink} to={ROUTES.dpdNav}>
                                <ListItem className={activeNavBar === ActiveNavBar.DPD ? classes.drawerItemSubActive : classes.drawerItemSub} button key='nav.dpd'>
                                    <Typography variant="caption">{t('legal.dpd')}</Typography>
                                </ListItem>
                            </NavLink>

                            <NavLink className={classes.navLink} to={ROUTES.termsNav}>
                                <ListItem className={activeNavBar === ActiveNavBar.TERMS ? classes.drawerItemSubActive : classes.drawerItemSub} button key='nav.terms'>
                                    <Typography variant="caption">{t('legal.terms')}</Typography>
                                </ListItem>
                            </NavLink>

                            <NavLink className={classes.navLink} to={ROUTES.impressNav}>
                                <ListItem className={activeNavBar === ActiveNavBar.IMPRESS ? classes.drawerItemSubActive : classes.drawerItemSub} button key='nav.impress'>
                                    <Typography variant="caption"> {t('legal.impress')}</Typography>
                                </ListItem>
                            </NavLink>

                            <div className={classes.navVersion}>
                                <Typography variant={"caption"} style={{fontSize: "0.75rem"}}>
                                    { 'v' + parameters.version}
                                </Typography>
                            </div>

                        </List>

                    }
                    <Divider className={classes.navDivider}/>


                    <ListItem className={classes.drawerItem} button key={'nav.logout'} onClick={logout}>
                         <ListItemIcon className={classes.navIconSmall}>
                            <LogoutIcon className={classes.navIcon} />
                        </ListItemIcon>
                        <Typography variant="caption">
                            {t('nav.logout')}
                        </Typography>
                    </ListItem>
                </List>
            </div>
        </div>
    )
}


const mapState = (state, ownProps) => {
    let user = getModelInstance(state, User,AUTHSTORAGE.getUserId());
    return {
        authUser: user,
        chats: getModelInstances(state, Chat),
        department: getModelInstance(state, Department, user && user.department),
    }
};


export default connect(mapState)(withRouter(MasterNav));
