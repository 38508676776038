import model from "../manager/decorators/model";
import {prop} from "../manager/decorators";

@model(null)
class AllReactions {
    @prop()
    LIKE;

    @prop()
    HEART;

    @prop()
    IDEA;

    @prop()
    CLAP;

    @prop()
    FUNNY;

    @prop()
    SAD;
}

export default AllReactions;