import { v4 as uuid } from 'uuid';

/**
 * id decorator
 *
 * designed as property decorator
 *
 * with the id decorator you can mark a property as model id
 * to map the firestore id. If you want to handle id's by your application you
 * can define the generator (default uuid/v4)
 * @param generator id generator (default uuid)
 * @return {function(*, *=, *): *}
 */
const id = (generator=uuid) => (target, name, descriptor) => {

    const model = target.constructor;

    model.metadata = {
        idProp: name,
        idGenerator: generator
    };

    if(generator) {
        descriptor.initializer = () => generator();
    }

    return descriptor;
};

export default id;
