export const TaskActivityType = {
    CREATED: "TaskActivityType.CREATED",
    FINISHED: "TaskActivityType.FINISHED",
    ASSIGNED: "TaskActivityType.ASSIGNED",

    SETDUEDATE: "TaskActivityType.SETDUEDATE",
    EDITDESCRIPTION: "TaskActivityType.EDITDESCRIPTION",
    EDITTITLE: "TaskActivityType.EDITTITLE",

    REMOVEDASSIGNEE: "TaskActivityType.REMOVEDASSIGNEE",
    REMOVEDDUEDATE: "TaskActivityType.REMOVEDDUEDATE"
};

