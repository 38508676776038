import {model, prop} from "../manager/decorators";

@model("/client/:client/security")
class ElasticAccess {

    @prop()
    name;

    @prop()
    pass
}

export default ElasticAccess;