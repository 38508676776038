import {useTranslation} from "react-i18next";
import React from "react";
import GroupMembershipContainer from "../../pages/groups/GroupMembershipContainer";
import MoinButton from "../base/MoinButton";
import parameters from "../../config/parameters.json";
import {getModelInstance} from "../../utils/store/selectors";
import Distributor from "../../utils/models/Distributor";
import {connect} from "react-redux";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import AUTHDISTRIBUTORMEMBERSHIP from "../../utils/auth/AuthDistributorMembership";

const GroupJoinButton = ({distributor, className, onRequestRemove, onRequest}) => {
    const [t] = useTranslation();

    if (!distributor.isAuthUserMember && distributor.hasAuthUserRequest) {
        return (
            <MoinButton size="small" className={className} onClick={ (e) => {onRequestRemove();  e.stopPropagation(); e.preventDefault(); return false;}}>
                {t('distributor.requested')}
            </MoinButton>
        )
    } else if (!distributor.isAuthUserMember ) {
        return (
            <MoinButton size="small"  className={className} onClick={(e) => {onRequest(); e.stopPropagation(); e.preventDefault(); return false;}}>
                {t('distributor.join')}
            </MoinButton>
        )
    }

    return <></>;
}

const mapState = (state, ownProps) => ({
    distributor: getModelInstance(state, Distributor,ownProps.distributorId),
})

const GroupJoinButtonState = connect(mapState)(GroupJoinButton);

/**
 *
 * @param {Distributor} distributor
 * @returns {JSX.Element}
 * @constructor
 */
const GroupJoinButtonWrapper = ({distributor, className}) => {

    if (distributor.id === parameters.globalDistributorId) {
        return <></>;
    }

    return (
        <GroupMembershipContainer distributorId={distributor.id}  key={"distributor-membership-container-" + distributor.id}>
            {
                ({onRequest, onRequestRemove, onLeave}) => <GroupJoinButtonState className={className} distributorId={distributor.id} onRequest={onRequest} onRequestRemove={onRequestRemove}/>
            }
        </GroupMembershipContainer>
    )
}

export  default GroupJoinButtonWrapper;