import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import {getFormattedTimeSpan, getddMMMMyyyyFormattedDate, getStartToEnd} from "../../utils/helper/TextHelper";
import {useTranslation} from "react-i18next";
import FireStorageImage from "../base/FirestoreImage";
import * as ROUTES from "../../Routes";
import {Link} from "react-router-dom";
import MoinButton from "../base/MoinButton";
import Typography from "@material-ui/core/Typography";
import {MoinCard, MoinCardHeader, MoinCardHeaderImage} from "../base/MoinCard";
import LocationIcon from '../../assets/icons/location.svg';
import ClockIcon from '../../assets/icons/clock.svg';
import AUTHSTORAGE from "../../utils/auth/AuthStorage";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        width: '100%'
    },
    cardContentRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center",
        color: Colors.BLACKLIGHT,
        '& svg': {
            marginRight: '6px'
        },
        marginBottom: '2px'
    },
   headerImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: "cover"
    },
    link: {
        cursor: "pointer",
        textDecoration: "none"
    },
    date: {
        color: Colors.BRANDPRIMARY,
        marginBottom: "12px"
    },
    moreButton: {
        marginTop: "24px"
    },
    tag: {
        padding: "0px 8px",
        borderRadius: "4px",
        margin: "0px 4px",
        backgroundColor: Colors.BLUELIGHT,
        color: Colors.BLACK,
        font: theme.typography.copySmall,
        zIndex: 100,
        height: "1.5rem",
        position: "absolute",
        bottom: "16px"
    }
}));

/**
 * Moin! event card
 *
 * @param {Object<Event>} event
 * @param {Object<Distributor>} distributor
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <MoinEventCard event={event} />
 */
const MoinEventCard = ({event, distributor, onParticipationChange}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    return (
        <Link to={ROUTES.eventShow.replace(":id", event.id)} className={classes.link} underline="none">
            <MoinCard hasRoundedBorders={true}>
                <MoinCardHeaderImage showBookmark={event.isAuthUserParticipating}>
                    {
                        distributor &&
                        <div className={classes.tag}>
                            { distributor.name }
                        </div>
                    }
                    <FireStorageImage
                        lightboxEnabled={true}
                        fontSize={64}
                        backgroundColor={Colors.identifierAsColor(event.id)}
                        className={classes.headerImage}
                        src={event.avatar}
                        alt={event.title}
                    />
                </MoinCardHeaderImage>
                <MoinCardHeader>
                    <Typography variant="h4" className={classes.date}>{getStartToEnd(event.start, event.end).toUpperCase()} </Typography>
                    <Typography variant="h3">{event.title}</Typography>
                    {
                       event.location &&
                           <div className={classes.cardContentRow}>
                               <LocationIcon fill={Colors.BLACKLIGHT}  style={{height:"1rem"}} />
                               <Typography variant="caption">{event.location}</Typography>
                           </div>
                    }
                    <div className={classes.cardContentRow}>
                        <ClockIcon fill={Colors.BLACKLIGHT}  style={{height:"1rem"}} />
                        <Typography variant="caption">{getFormattedTimeSpan(event.start, event.end) + " " + t('common.oclock')}</Typography>
                    </div>
                        {
                            ((event.cUid !== AUTHSTORAGE.getUserId()) && event.isFuture) &&
                            <MoinButton
                                className={classes.moreButton}
                                onClick={(event) => {
                                    onParticipationChange();
                                    event.preventDefault();
                                }}
                                color={event.isAuthUserParticipating ? 'secondary' : 'default'}
                                size="small"
                            >
                                {event.isAuthUserParticipating ? t('event.cancel') : t('event.participate')}
                            </MoinButton>
                        }
                </MoinCardHeader>
            </MoinCard>
    </Link>
    );
}

export default MoinEventCard;
