import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {MoinCard, MoinCardContent, MoinCardContentTitle, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import MoinSearchField from "../../components/base/MoinSearchField";
import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import {makeStyles} from "@material-ui/core/styles";
import {getModelInstances} from "../../utils/store/selectors";
import DistributorRequest from "../../utils/models/DistributorRequest";
import User from "../../utils/models/User";
import {connect} from "react-redux";
import GroupRequestsEmptyState from "./GroupRequestsEmptyState";

import CheckCircleOutlineOutlinedIcon from '../../assets/icons/notification-success.svg';
import DeleteOutlineOutlinedIcon from '../../assets/icons/delete.svg';
import SelectALlActive from '../../assets/icons/select-all-active.svg';
import SelectALlInactive from '../../assets/icons/select-all-inactive.svg';
import {Colors} from "../../components/helper/ColorHelper";
import MoinDivider from "../../components/base/MoinDivider";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
        width: '100%'
    },
    divider: {
        margin: '0'
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: '100%',
        padding: theme.spacing(0,3),
    },
    action: {
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    active: {
        color: Colors.BRANDPRIMARY
    },
    inactive: {
        color: Colors.BLACKLIGHTER
    }
}));

const GroupRequest = ({distributor, allRequests, allUsers, onAccept, onDecline}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const requests = (allRequests || []).filter( (request) => request.distributorId === distributor.id )
    const users = allUsers.filter( (user) => requests.filter( (request) => user.id === request.id ).length > 0 )

    const [search, setSearch] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);

    const onUserSelection = (user) => {
        let userId = user.id;

        let currentUsers = Array.from(selectedUsers);
        if (currentUsers.includes(userId)) {
            currentUsers = currentUsers.filter((id) => id !== userId);
        } else {
            currentUsers.push(userId);
        }
        setSelectedUsers(currentUsers);
    }

    const selectAllAction = () => {
        if (selectedUsers.length !== users.length) {
            let allUsers = users.map( (user) => user.id);
            setSelectedUsers(allUsers);
        } else {
            setSelectedUsers([]);
        }
    }

    const onAcceptAction = () =>  {
        let selectedIds = Array.from(selectedUsers);
        setSelectedUsers([]);
        let selectedRequests = requests.filter( (request) => selectedIds.includes(request.uid) );
        if (!!selectedRequests) {
            let selectedUsers = [];
            selectedRequests.forEach( (request) => {
                let user = users.find ((user) => user.uid === request.uid);
                if (!!user) {
                    selectedUsers.push(user);
                }
            });
            onAccept(selectedRequests,selectedUsers);
        }
    }

    const onDeclineAction = () => {
        let selectedIds = Array.from(selectedUsers);
        setSelectedUsers([]);
        let selectedRequests = requests.filter( (request) => selectedIds.includes(request.uid) );
        if (!!selectedRequests) {
             onDecline(selectedRequests);
        }
    }

    return (
        <MoinCard>
            <MoinCardHeader>
                <Typography variant="h3">
                    {
                        t('distributor.requests')
                    }
                </Typography>
            </MoinCardHeader>
            <MoinCardContent>
                <div className={classes.content}>
                    {
                        (!requests || requests.length ===0 )
                            ? <GroupRequestsEmptyState/>
                            :  <>
                                <Typography variant={'h2'}>{t('distributor.contacts').toUpperCase()}</Typography>

                                <MoinSearchField
                                    onChange={e => setSearch(e.target.value)}
                                    placeholder={t('distributor.placeholder.searchRequests')}
                                    style={{width: '100%'}}
                                    onClear={e => setSearch('')}
                                />

                                <SearchableSelectableUserList
                                    users={users}
                                    search={search}
                                    useTabs={false}
                                    selectedUsers={selectedUsers}
                                    onSelect={onUserSelection}
                                    hideAuth={false}
                                />
                            </>
                    }
                </div>
                <MoinDivider />
            </MoinCardContent>
            <MoinCardHeader>
                <div className={classes.actions}>
                    <div className={classes.action} onClick={onAcceptAction}>
                        <CheckCircleOutlineOutlinedIcon fill={Colors.BLACKLIGHTER} />
                        <Typography variant={"caption"}>{t('distributor.accept')}</Typography>
                    </div>
                    <div className={classes.action} onClick={onDeclineAction}>
                        <DeleteOutlineOutlinedIcon fill={Colors.BLACKLIGHTER} />
                        <Typography variant={"caption"}>{t('distributor.decline')}</Typography>
                    </div>
                    <div className={classes.action} onClick={selectAllAction}>
                        {
                            selectedUsers.length === users.length
                                ? <SelectALlActive fill={Colors.BRANDPRIMARY} />
                                : <SelectALlInactive fill={Colors.BLACKLIGHTER} />
                        }
                        <Typography className={ selectedUsers.length === users.length ? classes.active : classes.inactive} variant={"caption"}>{t('distributor.selectAll')}</Typography>
                    </div>
                </div>
            </MoinCardHeader>
        </MoinCard>
    );

};

const mapState = (state,ownProps) => {
    return {
        allUsers: getModelInstances(state, User),
        allRequests: getModelInstances(state,DistributorRequest),
    }
};

export default connect(mapState)(GroupRequest);