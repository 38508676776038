import {MentionType} from "../enums/MentionType";
import * as ROUTES from "../../Routes";
import Mention from "../models/Mention";

class MentionHelper {

    /**
     * @param {Mention} mention
     * @return {string}
     */
    static getPageForMention(mention) {
        switch (mention.entityType) {
            case MentionType.USER:
                return ROUTES.userShow.replace(':id', mention.entityId);
            default:
                return undefined;
        }
    }

    /**
     * @param {string} text
     * @param {boolean} [withDeleted=false]
     * @return {Mention[]}
     */
    static getAllMentionsInText(text, withDeleted=false) {
        const wordRegex = RegExp(/(?=\s(?!\d*\s))/); // |(?<=]}!!)/); <-- this does not work on safari (thank you apple)

        let mentionSplit = text.split(']}!!');

        for(let i = 0; i < mentionSplit.length-1; i++) {
            mentionSplit[i] += ']}!!';
        }

        let words = mentionSplit.map(e => e.split(wordRegex)).flat();

        let mentions = [];
        let characterCount = 0;

        for(let word of words) {
            let wordLength = word.length;
            word = word.trim();

            if(Mention.isMentionString(word)) {
                let mention = Mention.fromMentionString(word);
                mention.textStartIndex = characterCount;

                mentions.push(mention);
            } else if(withDeleted && Mention.isMentionString(word + '!')) {
                let mention = Mention.fromMentionString(word + '!');
                mention.textStartIndex = characterCount;

                mentions.push(mention);
            }

            characterCount += wordLength;
        }

        return mentions;
    }
}

export default MentionHelper;