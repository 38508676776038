import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import illustration from "../../assets/illustrations/moin-app-illus-no-content-muted.png";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '64px',
        marginBottom: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '768px',
        flex: '100',
    },
    illustration: {
        maxWidth: '45%',
        height: 'auto'
    },
    title: {
        marginBottom: '12px'
    },
    text: {
        textAlign: 'center'
    },
}));

const EmptyState = () => {
    const [t, i18n] = useTranslation();

    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <img src={illustration} alt={t('common.illustration')} className={classes.illustration}/>
            <Typography variant="h3" className={classes.title}>{t('common.nothingFound')}</Typography>
            <Typography variant="body2" className={classes.text}>{t('common.weCouldNotFindWhatYouAreLookingFor')}</Typography>
        </div>
    );
};
export default EmptyState;
