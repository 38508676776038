import React, {useState} from "react";
import {Link} from "react-router-dom";
import * as ROUTES from "../../Routes";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../components/helper/ColorHelper";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import Typography from "@material-ui/core/Typography";
import MoinDivider from "../../components/base/MoinDivider";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg";
import ChevronUpIcon from "../../assets/icons/chevron-up.svg";
import MoinLinkifiedText from "../../components/base/MoinLinkifiedText";

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: Colors.BRANDSECONDARY,
        display: "flex",
        alignItems: "center"
    },
    backIcon: {
        fill: Colors.BRANDSECONDARY,
        height: 16
    },
    pageHeader: {
        padding: theme.spacing(3, 0)
    },
    entry: {
        cursor: "pointer"
    },
    selectedEntry: {
        backgroundColor: Colors.WHITE,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 10px rgba(0, 0, 0, 0.09), 0px 0px 14px rgba(0, 0, 0, 0.05);",
        borderRadius: 16,
        padding: "1px 0 1px" // Just to keep the 1px from the divider so the entries don't jump
    },
    entryTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(3)
    },
    entryStatusIcon: {
        fill: Colors.BLACK,
        marginLeft: theme.spacing(3)
    },
    entryDescription: {
        padding: theme.spacing(0, 3, 3)
    },
    bottomSpacer: {
        marginBottom: theme.spacing(8)
    }
}));

const FaqOverview = () => {
    const faqEntryAmount = 16;
    const classes = useStyles();
    const [t] = useTranslation();

    const [currentlyOpenEntry, setCurrentlyOpenEntry] = useState( -1);

    const getEntries = () => {
        let entries = [];

        for(let i = 0; i < faqEntryAmount; i++) {
            let isSelected = currentlyOpenEntry === i;
            let nextIsSelected = currentlyOpenEntry === (i + 1);

            entries.push(
                <div
                    className={`${classes.entry} ${isSelected ? classes.selectedEntry : ''}`}
                    onClick={() => {
                        if(isSelected) {
                            setCurrentlyOpenEntry(-1);
                        } else {
                            setCurrentlyOpenEntry(i);
                        }
                    }}
                >
                    <div className={classes.entryTitle}>
                        <Typography variant="h2">{t(`help.faqEntries.entry${i}Title`)}</Typography>
                        {
                            isSelected
                            ? <ChevronUpIcon className={classes.entryStatusIcon}/>
                            : <ChevronDownIcon className={classes.entryStatusIcon}/>
                        }
                    </div>

                    {
                        isSelected &&
                        <div className={classes.entryDescription}>
                            <Typography variant="body2"><MoinLinkifiedText text={t(`help.faqEntries.entry${i}Description`)}/></Typography>
                        </div>
                    }

                    {
                        !isSelected && !nextIsSelected
                        && <MoinDivider/>
                    }
                </div>
            );
        }

        return entries;
    }

    return (
        <>
            <Link to={ROUTES.helpAndSupport} className={classes.link}>
                <ArrowLeftIcon className={classes.backIcon}></ArrowLeftIcon>
                {t('nav.helpAndSupport')}
            </Link>

            <Typography variant="h1" className={classes.pageHeader}>{t('help.faq')}</Typography>

            {
                currentlyOpenEntry !== 0
                && <MoinDivider/>
            }

            { getEntries() }
            <div className={classes.bottomSpacer}/>
        </>
    )
};

export default FaqOverview;
