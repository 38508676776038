import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import React, {useRef} from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";

/**
 * @param value
 * @param onChange
 * @param disabled
 * @param error
 * @param children
 * @param placeholder
 * @param className
 * @param {boolean} executeScroll
 * @returns {JSX.Element}
 * @constructor
 */
const MoinSelect = ({value, onChange, disabled, error, placeholder, children, className, executeScroll}) => {
    const myRef = useRef(null);

    if (executeScroll) {
        // - 100 offset because it's not in view with scrollintoview function
        const y = myRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    return (
        <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={disabled}
            error={!!error}
            className={className}
        >
            <Select
                size="small"
                displayEmpty
                value={value || ""}
                onChange={onChange}
                ref={myRef}
            >
                {placeholder &&
                    <MenuItem value="" disabled>
                        {placeholder}
                    </MenuItem>
                }
                { children }
            </Select>
            {
                !!error &&
                    <FormHelperText>{error}</FormHelperText>
            }
        </FormControl>
    )
}

export default MoinSelect;
