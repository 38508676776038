import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import {FireStorage} from "../../api/FirebaseService";
import {humanFileSize} from "../../utils/helper/TextHelper";

import PDFIcon from '../../assets/icons/pdf.svg';
import clsx from "clsx";
import MoinListItem from "../../components/base/MoinListItem";
import {Colors} from "../../components/helper/ColorHelper";

const useStyles = makeStyles((theme) => ({
    pdfWrapper: {
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        textDecoration: 'none',
        width: "100%"
    },
    pdfListItem: {
        minWidth: '328px',
        width: "100%",
        alignItems: 'center',
    },
    pdfListPadding: {
        padding: "12px 16px"
    },
    pdfListPaddingNone: {
        padding: "0"
    },
    pdfIcon: {
        display: 'flex',
        height: '2rem',
        width: '2rem'
    }
}));

/**
 * @param {ContentDocument} content
 * @param {String} className
 * @param {boolean} ignorePadding
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentPreview = ({content, className, ignorePadding=false}) => {
    const classes = useStyles();
    const [url, setUrl] = useState();

    useEffect(() => {
        if (content && content.url) {
            let src = content.url.replace(/%2F/g, '/');
            FireStorage.ref(src).getDownloadURL()
                .then(setUrl);
        }
    }, [content]);

    if (!content) {
        return (<></>);
    }

    return(
        <Link href={url} target="_blank" rel="noopener" underline="none" style={{width: "100%"}}>
            <div className={clsx(classes.pdfWrapper, className)}>
                <MoinListItem
                    customClass={ clsx(classes.pdfListItem, ignorePadding ? classes.pdfListPaddingNone : classes.pdfListPadding) }
                    withDivider={false}
                    image={<PDFIcon fill={Colors.BRANDPRIMARY}  className={classes.pdfIcon} />}
                    title={content.name}
                    subtitle={content.size ? humanFileSize(content.size) : (content.text ? content.text : "")}
                />
            </div>
        </Link>
    );
};

export default DocumentPreview;