import {model} from "../manager/decorators";

@model(null)
class FireStorageUrl {
    id;
    src;
    url;

}

export default FireStorageUrl;