import React, {useRef} from "react";
import CreateComment from "./CreateComment";
import Comment from "../../../utils/models/Comment";
import ModelManager from "../../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../../utils/auth/AuthStorage";
import {getModelInstance} from "../../../utils/store/selectors";
import User from "../../../utils/models/User";
import {connect} from "react-redux";
import FirebaseStorageManager from "../../../utils/manager/manager/FirebaseStorageManager";
import {sendCommentAtArticleNotification, sendMentionedInCommentNotification} from "../../helper/NotificationHelper";
import {FireIncrement} from "../../../api/FirebaseService";

/**
 * @param {Article} article
 * @param {User} authUser
 * @returns {JSX.Element}
 * @constructor
 */
const CreateCommentContainer = ({article, authUser}) => {
    const saving = useRef(false);

    const saveNewComment = async (text, image) => {
        if(!saving.current) {
            let futures = [];
            saving.current = true;

            const comment = new Comment();
            comment.aId = article.id;
            comment.cName = authUser.fullName;
            comment.cUid = authUser.id;
            comment.text = (text || "").trim();

            try {

                if(!!image?.name) {
                    let fileExtension = image.name.split('.');
                    fileExtension = fileExtension[fileExtension.length - 1];

                    let uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/comment/${comment.id}.${fileExtension}`;
                    /**
                     * This can't be awaited in the futures array as the model manager update is faster than the image upload,
                     * causing the comments image url to give us a 404, since the image is still being uploaded
                     */
                    await new FirebaseStorageManager().uploadImage(image, uploadPath, 1200 , 1);
                    comment.image = uploadPath.substring(1).replace(/\//g, '%2F');
                }

                article.cCount++;

                let articleUpdateData = {
                    cCount: FireIncrement(1)
                };

                let articleCommentedData = {
                    [AUTHSTORAGE.getUserId()]: ""
                };

                futures.push(ModelManager.add({modelInstance: comment}));
                futures.push(ModelManager.update({modelInstance: article, customData: articleUpdateData}));
                futures.push(ModelManager.update({customData: articleCommentedData, resource: `/client/${AUTHSTORAGE.getClientId()}/article/${article.id}/commented`, id: '_all', useSet: true}));

                await Promise.all(futures);

                sendCommentAtArticleNotification(article, comment, authUser.fullName);
                sendMentionedInCommentNotification(article, comment, authUser.fullName);
                saving.current = false;
                return true;
            } catch (ex) {
                saving.current = false;
            }

        }
        return false;
    };

    return <CreateComment saveNewComment={saveNewComment}/>
};


const mapState = (state) => ({
    authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
});

export default connect(mapState)(CreateCommentContainer);
