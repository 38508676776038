import React, {useEffect} from 'react';
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import User from "../../utils/models/User";
import UserProfile from "./UserProfile";
import Department from "../../utils/models/Department";
import DistributorMembership from "../../utils/models/DistributorMembership";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import Role from "../../utils/models/Role";
import Chat from "../../utils/models/Chat";
import EmptyState from "../../components/base/EmptyState";
import OpenChatContainer from "../OpenChatContainer";

/**
 *
 * @param {String} uid
 * @param {Array<Chat>}chats
 * @param {User} user
 * @param {User} authUser
 * @param {Department} department
 * @param distributorMembership
 * @param roles
 * @returns {JSX.Element}
 * @constructor
 */
const UserProfileContainer = ({uid, chats, user, authUser, department, distributorMembership, roles}) => {
    roles = roles.filter(role => user && user.appRoles.includes(role.id));
    distributorMembership = distributorMembership || [];

    useEffect(() => {
        ModelManager.subscribe({
            model: DistributorMembership
        })
        return () => {
            ModelManager.unsubscribe(DistributorMembership);
        }

    }, []);


    if (!user) {
        return <EmptyState/>
    }

    return(
            <OpenChatContainer uid={uid}>
                {
                    ({onMessage}) => <UserProfile
                        isAuthUser={AUTHSTORAGE.getUserId() === user.id}
                        authUser={authUser}
                        user={user}
                        department={department}
                        distributorMembership={distributorMembership}
                        roles={roles}
                        clientType={AUTHSTORAGE.getClient().type}
                        onMessage={onMessage}
                    />
                }
            </OpenChatContainer>
    );
};

const mapState = (state, ownProps) => {
    let user = getModelInstance(state, User,ownProps.uid);
    return {
        user: user,
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId()),
        chats: getModelInstances(state, Chat),
        department: getModelInstance(state, Department, user && user.department),
        distributorMembership: getModelInstance(state, DistributorMembership, user && user.id),
        roles: getModelInstances(state, Role)
    }
};

export default connect(mapState)(UserProfileContainer);