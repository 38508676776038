import Dexie from "dexie";

const startDb = new Dexie("startDb");
startDb.version(1).stores({values: 'id'});

const getPenultimateAppStart = async () => {
    return (await startDb.values.get('penultimateAppStart'))?.value;
};

const setPenultimateAppStart = async (appStart) => {
    startDb.values.put({id: 'penultimateAppStart', value: appStart});
};

const getHideEvents = async () => {
    return (await startDb.values.get('hideEvents'))?.value;
};

const setHideEvents = async (hideEventsDate) => {
    startDb.values.put({id: 'hideEvents', value: hideEventsDate});
};

const getLatestAppStart = async () => {
    return (await startDb.values.get('latestAppStart'))?.value;
};

const setLatestAppStart = async (appStart) => {
    startDb.values.put({id: 'latestAppStart', value: appStart});
};

export {getPenultimateAppStart, setPenultimateAppStart, getLatestAppStart, setLatestAppStart, getHideEvents, setHideEvents};
