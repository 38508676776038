import {getModelInstances} from "../../../utils/store/selectors";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import SurveyAnswers from "./SurveyAnswers";
import SurveyAnswer from "../../../utils/models/SurveyAnswer";
import ModelManager from "../../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../../utils/auth/AuthStorage";
import {firebase} from "../../../api/FirebaseService";
import User from "../../../utils/models/User";

/**
 * @param {string} surveyId
 * @param {SurveyAnswer[]} answers
 * @param {User[]} users
 * @returns {JSX.Element}
 * @constructor
 */
const SurveyAnswersContainer = ({surveyId, answers, users}) => {
    const batchSize = 20;
    const [loading, setLoading] = useState(false);
    const filteredAnswers = answers.filter(answer => answer.surveyId === surveyId);

    useEffect(() => {
        loadMoreAnswers();
    }, []);

    const loadMoreAnswers = async () => {
        if(!loading) {
            setLoading(true);

            if(filteredAnswers.length > 0) {
                await ModelManager.list({model: SurveyAnswer, resource: `/client/${AUTHSTORAGE.getClientId()}/survey/${surveyId}/answer`, orderBy: firebase.firestore.FieldPath.documentId(), startAfter: filteredAnswers[filteredAnswers.length - 1].id, extraProps: {surveyId: surveyId}, limit: batchSize});
            } else {
                await ModelManager.list({model: SurveyAnswer, resource: `/client/${AUTHSTORAGE.getClientId()}/survey/${surveyId}/answer`, orderBy: firebase.firestore.FieldPath.documentId(), extraProps: {surveyId: surveyId}, limit: batchSize});
            }

            setLoading(false);
        }
    };

    return(
        <SurveyAnswers surveyId={surveyId} answers={filteredAnswers} users={users} loading={loading} loadMoreAnswers={loadMoreAnswers} batchSize={batchSize}/>
    );
};

const mapState = (state, ownProps) => ({
    answers: getModelInstances(state, SurveyAnswer),
    users: getModelInstances(state,User)
});

export default connect(mapState)(SurveyAnswersContainer);