import React, {useEffect, useRef, useState} from 'react';
import FireStorageImage from "../../components/base/FirestoreImage";
import { getUserAvatarPath} from "../../utils/helper/ImageHelper";
import {makeStyles} from "@material-ui/core/styles";
import {Divider, Typography} from "@material-ui/core";
import {formatCommentDate, } from "../../utils/helper/TextHelper";
import {useTranslation} from "react-i18next";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import MoinSimpleMenuOption from "../../components/base/MoinSimpleMenuOption";
import {Permission} from "../../utils/enums/Permission";
import MoinSimpleMenu from "../../components/base/MoinSimpleMenu";
import { useHistory, withRouter} from "react-router-dom";
import * as ROUTES from "../../Routes";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import MoinButton from "../../components/base/MoinButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MoinCard} from "../../components/base/MoinCard";
import MoinListItem from "../../components/base/MoinListItem";
import MoinLinkifiedText from "../../components/base/MoinLinkifiedText";
import {Colors} from "../../components/helper/ColorHelper";
import ReactionRowContainer from "../../components/article/ReactionRowContainer";
import CommentReaction from "../../utils/models/CommentReaction";
import RenderedCommentAnswerContainer from "./RenderedCommentAnswerContainer";
import CreateCommentAnswerContainer from "../../components/article/comments/CreateCommentAnswerContainer";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        width: '100%',
        padding: theme.spacing(0, 2),
        margin: theme.spacing(1.5, 0)
    },
    commentContent: {
        margin: "16px 16px 12px 16px"
    },
    commentImage: {
        width: '100%',
        maxHeight: "480px",
        objectFit: "cover"
    },
    commentImageWrapper: {
        position: 'relative',
        marginLeft: '16px',
        marginRight: '16px',
        marginTop: '16px',
        marginBottom: '8px'
    },

    commentPadding: {
        paddingLeft: "16px",
        paddingRight: "16px"
    },
    comment: {
        margin: theme.spacing(1.75, 2, 1.5, 2)
    },
    createCommentWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    contentAlignment: {
        alignItems: "normal"
    },
    deleteDialogActions: {
        justifyContent: 'space-between'
    },
    pdfBackground: {
        backgroundColor: Colors.WHITE
    },
    cardActions: {
        margin: "0 16px 12px 16px"
    },
}));

/**
 * @param {Comment} comment
 * @param {CommentAnswer[]} commentAnswers
 * @param {CommentReaction} reaction
 * @param {boolean} deleting
 * @param {Function} loadMoreCommentAnswers
 * @param {boolean} loadingComments
 * @param {Function} deleteComment
 * @returns {JSX.Element}
 * @constructor
 */
const CommentDetail = ({comment,  commentAnswers,  loadMoreCommentAnswers, loadingComments=false, deleting = false, deleteComment}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const history = useHistory();
    const feedWrapper = useRef();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);


    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle, true);
        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [commentAnswers]);


    const onScrollHandle = () => {
        if(!!feedWrapper.current && feedWrapper.current.getBoundingClientRect().bottom <= window.innerHeight + 250 && !!commentAnswers[commentAnswers.length-1]) {
            loadMoreCommentAnswers(commentAnswers[commentAnswers.length-1].cAt);
        }
    };

    if(!comment) {
        return <div/>;
    }

    const contextMenuOptions = [];

    if(comment.cUid === AUTHSTORAGE.getUserId() || AUTHSTORAGE.hasPermission(Permission.COMMENTDELETE)) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.delete'), () => setShowDeleteDialog(true)));
    }

    const getDeleteDialog = () => {
        return (
            <Dialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('comments.deleteComment') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">{ t('article.comments.deleteCommentDescription') }</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={() => setShowDeleteDialog(false)} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    {
                        deleting &&
                        <div className="moin-circular-progress-container">
                            <CircularProgress />
                        </div>
                    }
                    <MoinButton onClick={deleteComment}>{ t('buttons.delete') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            {
                getDeleteDialog()
            }
            <MoinCard className={classes.contentAlignment}>
                <MoinListItem
                    itemKey={"comment_user_header_"+comment.cUid}
                    withSplashEffect={false}
                    withDivider={false}
                    image={
                        <FireStorageImage
                            isAvatar={true}
                            src={getUserAvatarPath(comment.cUid)}
                            alt={comment.cName}
                            backgroundColor={Colors.identifierAsColor(comment.cUid)}
                        />}
                    title={comment.cName}
                    subtitle={ formatCommentDate(t, comment.cAt)}
                    customClass={classes.cardHeader}
                    trailing={contextMenuOptions.length > 0 ? [ <MoinSimpleMenu key={"comment_simple_menu_"+comment.cUid} options={contextMenuOptions}/>] : undefined}
                    onClick={
                        () => history.push(ROUTES.userShow.replace(':id', comment.cUid))
                    }
                />

                <Divider/>

                <div className={classes.commentContent}>
                    <Typography variant="body2"><MoinLinkifiedText text={ comment.text } /></Typography>
                    {
                        !!comment.image  &&
                            <div className={classes.commentImageWrapper}>
                                <FireStorageImage lightboxEnabled={true} src={comment.image} className={classes.commentImage}/>
                            </div>
                    }
                </div>

                <div className={classes.cardActions}>
                    <ReactionRowContainer
                        model={comment}
                        modelPath={"comment"}
                        reactionModel={CommentReaction}
                        isComment={false}
                        reactionCount={comment.heart}
                        showCommentButtons={true}
                        allReactions={comment.reactions}
                        commentCount={comment.cCount}
                        onLikeCountTap={() => history.push(ROUTES.articleLikeList.replace(":id", comment.id))}
                        showLikeButton={true}
                    />
                </div>


                {
                    commentAnswers.map(commentAnswer => (
                        <>
                            <Divider/>
                            <RenderedCommentAnswerContainer
                                key={"comment-" + comment.aId + "-commentAnswer-" + commentAnswer.id}
                                commentAnswer={commentAnswer}
                                comment={comment}
                            />
                        </>
                    ))
                }

                {/*ScrollCheck to load more Comments*/}
                <div ref={(node) => feedWrapper.current = node}/>
                {
                    loadingComments &&
                    <div className="moin-circular-progress-container">
                        <CircularProgress />
                    </div>
                }
                <Divider/>

                <div className={classes.createCommentWrapper}>
                    <CreateCommentAnswerContainer comment={comment}/>
                </div>

            </MoinCard>
        </>
    );
};

export default withRouter(CommentDetail);
