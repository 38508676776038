import {MessageType} from "../enums/MessageType";
import AUTHSTORAGE from "../auth/AuthStorage";
import { DateTime } from "luxon";
import {RepetitionWeek} from "../enums/repetition/RepetitionWeek";
import {RepetitionType} from "../enums/repetition/RepetitionType";
import {RepetitionWeekdays} from "../enums/repetition/RepetitionWeekdays";

export function toDateString(date) {
    if (!!date && !!date.toLocaleDateString) {
        const dateOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('de-DE', dateOptions);
    }
    return "illegal date"
}

/**
 * @param t
 * @param {Date} date
 */
export function formatCommentDate(t, date) {
    const now = new Date();
    if (!date) {
        return '';
    }

    if(date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) {
        const timeOptions = { hour: '2-digit', minute: '2-digit' };
        if(date.getDate() === now.getDate()) {
            return date.toLocaleTimeString('de-DE', timeOptions);
        } else if(date.getDate() === now.getDate()-1) {
            return t('common.yesterdayTime', {time: date.toLocaleTimeString('de-DE', timeOptions)});
        }
    }

    const dateOptions = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString('de-DE', dateOptions);
}

/**
 * @param t
 * @param {Date} date
 */
export function formatArticleDate(t, date) {
    if (!date) {
        return '';
    }
    const now = new Date();
    if(date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) {
        const timeOptions = { hour: '2-digit', minute: '2-digit' };
        if(date.getDate() === now.getDate()) {
            return date.toLocaleTimeString('de-DE', timeOptions);
        } else if(date.getDate() === now.getDate()-1) {
            return t('common.yesterdayTime', {time: date.toLocaleTimeString('de-DE', timeOptions)});
        }
    }

    const dateOptions = { year: '2-digit', month: '2-digit', day: '2-digit'};
    return date.toLocaleDateString('de-DE', dateOptions);
}

/**
 * @param t
 * @param {Date} date
 */
export function formatChatDate(t, date) {
    if (!date) {
        return '';
    }
    const now = new Date();
    if(date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) {
        if(date.getDate() === now.getDate()) {
            return t('common.today');
        } else if(date.getDate() === now.getDate()-1) {
            return t('common.yesterday');
        }
    }
    const dateOptions = { year: '2-digit', month: '2-digit', day: '2-digit'};
    return date.toLocaleDateString('de-DE', dateOptions);
}


export function getAutomaticMessageText(t, message) {
    switch(message.type) {
        case MessageType.USERADDED:
            if(message.tUid === AUTHSTORAGE.getUserId()) {
                return t('messages.addedYouAutomaticMessage', {inviterName: message.cName});
            }
            return t('messages.addedUserAutomaticMessage', {inviterName: message.cName, invitedName: message.tName});
        case MessageType.USERLEFT:
            if(message.tUid === AUTHSTORAGE.getUserId()) {
                return t('messages.youLeftAutomaticMessage');
            }
            return t('messages.userLeftAutomaticMessage', {leaverName: message.cName});
        case MessageType.USERREMOVED:
            if(message.tUid === AUTHSTORAGE.getUserId()) {
                return t('messages.removedYouAutomaticMessage', {removerName: message.cName});
            }
            return t('messages.removedUserAutomaticMessage', {removerName: message.cName, removedName: message.tName});
        case MessageType.NAMECHANGED:
            return t('messages.chatNameChangedAutomaticMessage', {senderName: message.cName, name: message.tName});
        case MessageType.HUGHEART:
        case MessageType.HUGPRAY:
        case MessageType.HUGIDEA:
        case MessageType.HUGFUNNY:
        case MessageType.HUGCLAP:
        case MessageType.HUGBEE:
            if(message.cUid === AUTHSTORAGE.getUserId()) {
                return t('messages.youSentAHug');
            }
            return t('messages.userSentAHug', {user: message.cName});
        default:
            return '';
    }
}

export function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return (seconds == 60 ? (minutes+1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
}

export function getTeaserText(text, length = 500) {
    const teaserLength = length;

    if(!text) {
        return "";
    }
    if(text.length > teaserLength) {
        return text.substr(0, teaserLength) + "...";
    }

    return text;
}

export function getFormattedTimeSpan(start, end) {
    let luxonStart = DateTime.fromJSDate(start);
    let span;
    span = luxonStart.setLocale(AUTHSTORAGE.getLanguage()).toFormat("HH:mm");
    if (!!end) {
        let luxonEnd = DateTime.fromJSDate(end);
        if ((end.getTime() - start.getTime()) > 100000) {
            span += " - " + luxonEnd.setLocale(AUTHSTORAGE.getLanguage()).toFormat("HH:mm");
        }
    }
    return span;
}

export function getStartToEnd(start, end) {
    let luxonStart = DateTime.fromJSDate(start);
    let text = luxonStart.setLocale(AUTHSTORAGE.getLanguage()).toFormat("dd. MMMM yyyy");

    if (!!end) {
        let luxonEnd  = DateTime.fromJSDate(end);
        let diff = luxonEnd.diff(luxonStart, ['years','months', 'days',]).toObject();
        if ( (diff.years && diff.years >= 1) || (diff.months && diff.months >= 1)  || !(luxonStart.hasSame(luxonEnd, 'day')) ) {
            text += ' - ' + luxonEnd.setLocale(AUTHSTORAGE.getLanguage()).toFormat("dd. MMMM yyyy");
        }
    }
    return text;
}

export function getdFormattedDate(date) {
    date = DateTime.fromJSDate(date);
    return date.setLocale(AUTHSTORAGE.getLanguage()).toFormat("d.");
}

export function getdMMMMyyyyFormattedDate(date) {
    date = DateTime.fromJSDate(date);
    return date.setLocale(AUTHSTORAGE.getLanguage()).toFormat("d. MMMM yyyy");
}

export function getddMMMMyyyyFormattedDate(date) {
    date = DateTime.fromJSDate(date);
    return date.setLocale(AUTHSTORAGE.getLanguage()).toFormat("dd. MMMM yyyy");
}

export function timestampToDate(timestamp) {
    let date = DateTime.fromJSDate(new Date(timestamp));
    return date.setLocale(AUTHSTORAGE.getLanguage()).toFormat("dd. MMM yyyy");
}

export function timestampToDateTasks(timestamp) {
    let date = DateTime.fromJSDate(new Date(timestamp));
    if (date.year > (new Date()).getFullYear()) {
        return date.setLocale(AUTHSTORAGE.getLanguage()).toFormat("EEE d. MMMM yyyy");
    }
    return date.setLocale(AUTHSTORAGE.getLanguage()).toFormat("EEE d. MMMM");
}

export function timestampToDateTasksActivity(timestamp) {
    let date = DateTime.fromJSDate(new Date(timestamp));
    return date.setLocale(AUTHSTORAGE.getLanguage()).toFormat("d. MMM., HH:mm");
}

export function timestampToDateTime(timestamp) {
    let date = DateTime.fromJSDate(new Date(timestamp));
    return date.setLocale(AUTHSTORAGE.getLanguage()).toFormat("HH:mm");
}

export function humanFileSize(bytes) {
    const thresh = 1000;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10**0;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(0) + ' ' + units[u];
}

export function isLink(text) {
    const regex = RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})|([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.((com\/|de\/)|(com$|de$)))/g);
    //const regex = RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/g);
    return regex.test(text);
}

export function getLinkStart(text) {
    const regex = RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/g);
    return regex.exec(text).index;
}

export function isMail(text) {
    const regex = RegExp(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+.[a-zA-Z0-9_-]+)/g);
    return regex.test(text);
}

export function isPhone(text) {
    if (!isTime(text)) {
        const regex = RegExp(/^[+]?[(]?[0-9]{1,4}[)]?[-\s\./0-9]{4,}$/g);
        return regex.test(text);
    }
    return false;
}

function isTime(text) {
    const regex = RegExp(/^[0-9]{1,2}(\s*[-:.]\s*|\s+)[0-9]{1,2}.*$/g);
    return regex.test(text);
}

export function getTranslationForRepetitionType(t, type) {
    let types = {
        [RepetitionType.NEVER]: t('tasks.repetition.never'),
        [RepetitionType.DAILY]: t('tasks.repetition.daily'),
        [RepetitionType.WEEKLY]: t('tasks.repetition.weekly'),
        [RepetitionType.MONTHLY]: t('tasks.repetition.monthly'),
        [RepetitionType.ANNUAL]: t('tasks.repetition.annual'),
        [RepetitionType.CUSTOM]: t('tasks.repetition.custom')
    };

    return types[type];
}

export function getTranslationForRepetitionWeekdays(t, type) {
    let types = {
        [RepetitionWeekdays.WORKDAYS]: t('tasks.repetition.businessDays'),
        [RepetitionWeekdays.ALL]: t('tasks.repetition.allDays'),
    };

    return types[type];
}

export function getShortTranslationForWeekdays(t, day) {
    let days = {
        0: t('tasks.repetition.monday'),
        1: t('tasks.repetition.tuesday'),
        2: t('tasks.repetition.wednesday'),
        3: t('tasks.repetition.thursday'),
        4: t('tasks.repetition.friday'),
        5: t('tasks.repetition.saturday'),
        6: t('tasks.repetition.sunday'),
    };

    if(days[day].length > 2) {
        return days[day].substring(0, 2);
    }

    return days[day];
}

export function getNumberTypes(t, type) {
    let types = {
        1: t('tasks.repetition.first'),
        2: t('tasks.repetition.second'),
        3: t('tasks.repetition.third'),
        4: t('tasks.repetition.fourth'),
    };

    return types[type];
}

export function getTranslationsForWeekdays(t, day) {
    let types = {
        0: t('tasks.repetition.sunday'),
        1: t('tasks.repetition.monday'),
        2: t('tasks.repetition.tuesday'),
        3: t('tasks.repetition.wednesday'),
        4: t('tasks.repetition.thursday'),
        5: t('tasks.repetition.friday'),
        6: t('tasks.repetition.saturday'),
    };

    return types[day];
}
