import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../helper/ColorHelper";
import {useTranslation} from "react-i18next";

import ArrowRightIcon from '../../../assets/icons/arrow-right.svg';
import AttachFileIcon from "../../../assets/icons/attachment.svg";
import CloseIcon from "../../../assets/icons/close.svg";
import MentionTextField from "../../mention/MentionTextField";
import MentionAutocomplete from "../../mention/MentionAutocomplete";

const useStyles = makeStyles((theme) => ({
    sendButton: {
        height: '38px',
        minHeight: '38px',
        width: '38px',
        minWidth: '38px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.WHITE,
        cursor: 'pointer',
    },
    row: {
        display: 'flex',
        alignItems: 'center'
    },
    link: {
        color: Colors.BRANDSECONDARY,
        cursor: "pointer",
        textTransform: "none",
        textDecoration: "none",

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    },
    imageWrapper: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.WHITE
    },
    commentImage: {
        width: '100%',
        position: 'absolute'
    },
    selectImageInfo: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        marginTop: '25%'
    },
    whiteTextColor: {
        color: Colors.WHITE
    },
    bottomBar: {
        background: Colors.WHITE,
        '& > hr' : {
            width: "100%",
        }
    },
    barActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 16px 0 16px',
    },
    barTextField: {
        margin: "0 8px 0 8px"
    },
    allMediaWrapper: {
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: "768px",
        margin: '0 16px 0 16px',
    },
    selectedMediaWrapper: {
        minWidth: '240px',
        width: '240px',
        height: '150px',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'relative',
        marginBottom: theme.spacing(1)
    },
    selectedImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    deleteMediaIcon: {
        position: 'absolute',
        height: '25px',
        width: '25px',
        borderRadius: '25px',
        backgroundColor: Colors.BLACKLIGHTER,
        top: '8px',
        right: '8px',
        cursor: 'pointer',
        color: Colors.WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    clickable: {
        cursor: 'pointer'
    },
    createCommentWrapper: {
        margin: '16px 0 24px 0'
    },
    completeWrapper: {
        position: 'relative'
    }
}));

/**
 * @param {Function} saveNewCommentAnswer
 * @returns {JSX.Element}
 * @constructor
 */
const CreateCommentAnswer = ({saveNewCommentAnswer}) => {
    const classes = useStyles();
    const [textContent, setTextContent] = useState("");
    const [selectedImage, setSelectedImage] = useState();
    const [t, i18n] = useTranslation();
    const imageInputField = useRef();
    const textFieldRef = useRef();
    const wrapperDivRef = useRef();

    const onSave = async () => {
        await saveNewCommentAnswer(textContent, selectedImage);
        setTextContent("");
        setSelectedImage(null);
    };

    const removeImage = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedImage(null);
    };


    const onKeyDown = (e) => {
        if(e.keyCode === 13 && !e.shiftKey){
            onSave();
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    };

    const renderSelectedImage = () => {
        return (
            <div key={"selected-image"} className={classes.selectedMediaWrapper}>
                <img src={URL.createObjectURL(selectedImage)} className={classes.selectedImage}/>
                <div className={classes.deleteMediaIcon} onClick={(e) => removeImage(e)}>
                    <CloseIcon fill={Colors.WHITE}/>
                </div>
            </div>
        );
    };

    const enableSendButton = ( (!!textContent &&  !!textContent.trim() ) || !!selectedImage);

    return (
        <div className={classes.completeWrapper} ref={(wrapperDiv) => wrapperDivRef.current = wrapperDiv}>
            <MentionAutocomplete
                textFieldRef={textFieldRef}
                textValue={textContent}
                replaceText={(text) => setTextContent(text)}
                parent={wrapperDivRef.current}
            />

            <div className={classes.createCommentWrapper}>
                <div className={classes.allMediaWrapper}>
                    {
                        !!selectedImage?.name && renderSelectedImage()
                    }
                </div>

                <div className={classes.barActions}>
                    <input
                        ref={(fileInput) => imageInputField.current = fileInput}
                        type="file" accept="image/png,image/jpeg,image/gif"
                        style={{display: 'none'}}
                        onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                                setSelectedImage(e.target.files[0])
                            } else {
                                setSelectedImage(undefined);
                            }
                        }}
                    />

                    <AttachFileIcon className={classes.clickable} fill={Colors.BLACKLIGHT} onClick={ () => imageInputField.current.click()}/>

                    <MentionTextField
                        textFieldRef={textFieldRef}
                        className={classes.barTextField}
                        multiline={true}
                        rowsMax={5}
                        rows={1}
                        value={textContent}
                        onChange={(text) => setTextContent(text)}
                        placeholder={t('article.comments.writeAComment')}
                        onKeyDown={onKeyDown}
                    />

                    <div className={classes.sendButton} style={{backgroundColor: enableSendButton ? Colors.BRANDSECONDARY : Colors.BLACKLIGHTER}} onClick={() => onSave()}>
                        <ArrowRightIcon fill={Colors.WHITE}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCommentAnswer;
