import {getModelInstances} from "../../utils/store/selectors";
import Article from "../../utils/models/Article";
import {connect} from "react-redux";
import FAVORITEDARTICLES from "../../utils/articles/FavoritedArticles";
import FavoritedArticlesList from "./FavoritedArticlesList";
import React from "react";

const FavoritedArticlesListContainer = ({articles}) => {
    const favoritedArticles = articles.filter((article) => FAVORITEDARTICLES.isFavorited(article.id)).sort((a, b) => FAVORITEDARTICLES.getArticleFavoritedDate(b.id) - FAVORITEDARTICLES.getArticleFavoritedDate(a.id));

    return (
        <FavoritedArticlesList
            articles={favoritedArticles}
        />
    )
}

const mapState = (state) => ({
    articles: getModelInstances(state, Article)
});

export default connect(mapState)(FavoritedArticlesListContainer);
