import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Colors} from "../helper/ColorHelper";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: Colors.BRANDPRIMARY,
        borderColor: Colors.BRANDPRIMARY,
        borderRadius: 24,
        borderStyle: 'solid',
        borderWidth: 2,
        color: Colors.WHITE,
        '&:hover': {
            backgroundColor: Colors.BRANDPRIMARYLIGHT,
            borderColor: Colors.BRANDPRIMARYLIGHT,
            color: Colors.WHITE,
        },
        fontSize: '15px',
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: '22px',
        padding: theme.spacing(1.25, 3),
        letterSpacing: "0px",
        textTransform: "none"
    },
    outlined: {
        backgroundColor: Colors.WHITE,
        color: Colors.BRANDPRIMARY,
    },
    disabled: {
        border: "none",
        color: Colors.WHITE,
        backgroundColor: Colors.BLACKLIGHTEST,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: "transparent",

        '& > .MuiButton-label': {
            color: Colors.WHITE,
        }
    },
    textSecondary: {
        backgroundColor: Colors.WHITE,
        color: Colors.BRANDPRIMARY,
        '&:hover': {
            backgroundColor: Colors.BRANDPRIMARY,
            borderColor: Colors.BRANDPRIMARY,
            color: Colors.WHITE,
        }
    },
    sizeSmall: {
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        letterSpacing: "0px",
        textTransform: "none",
        padding: theme.spacing(.75, 2),
    },
}));

const MoinButton = ({variant, ...rest}) => {
    const classes = useStyles();
    return <Button classes={classes} variant={variant} {...rest} />;
}


export default MoinButton;
