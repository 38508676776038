import React, {useState} from "react";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import AllReactions from "../../utils/models/AllReactions";
import ReactionRow from "./ReactionRow";
import {getModelInstance} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import {deleteModelInstanceDispatch, updateModelInstanceDispatch} from "../../utils/store/actions";
import {connect} from "react-redux";
import {sendReactionAtArticleNotification} from "../helper/NotificationHelper";
import {FireDelete, FireIncrement} from "../../api/FirebaseService";


const ReactionRowContainer = (
    {
        model, modelPath, reactionModel , authReaction, onLikeCountTap,
        isComment=false, showCommentButtons=false, showCommentText= true, showLikeButton=true, commentCount, onCommentCountTap,
        onCommentIconTap, customCommentText, deleteModelInstanceDispatch, updateModelInstanceDispatch,
        authUser
    }) => {
    const [runningQueue, setRunningQueue] =  useState(false);
    const [queue, setQueue] =  useState([]);

    const runAsQueue = async () => {
        if (!runningQueue) {
            setRunningQueue(true);
            try {
                while (queue.length > 0) {
                    let operation = queue[0];
                    await operation();
                    queue.shift();
                    setQueue(queue);
                }
                setRunningQueue(false);
            } catch (ex) {
                setRunningQueue(false);
            }
        }
    };

    /**
     * @param {ReactionType} reaction
     */
    const changeReaction = (reaction) => {
        let authUserReaction = authReaction?.type;
        let oldReactionType = authUserReaction ? authUserReaction.split('.')[1] : undefined;
        let newReactionType = reaction.split('.')[1];

        if(!model.reactions) {
            model.reactions = new AllReactions();
        }
        let userData = {};
        let commentData = {};
        let commentHeartData = {};
        let reactionModelInstance = new reactionModel();
        reactionModelInstance.id = model.id;

        if(reaction === authUserReaction) {
            // Remove ArticleReaction
            model.heart = (model.heart ?? 1) -1 ;

            if(model.reactions[newReactionType] !== undefined && model.reactions[newReactionType] > 0) {
                model.reactions[newReactionType]--;
            }

            deleteModelInstanceDispatch(reactionModel, reactionModelInstance);
            userData[model.id] = FireDelete();
            commentData =  {
                "heart": FireIncrement(-1),
                ["reactions." + newReactionType]: FireIncrement(-1)
            };
            commentHeartData = {[AUTHSTORAGE.getUserId()]: FireDelete()};
        } else {
            if(!!authUserReaction) {
                if(!!model.reactions[oldReactionType] && model.reactions[oldReactionType] > 0) {
                    model.reactions[oldReactionType]--;
                }

                if(model.reactions[newReactionType]=== undefined) {
                    model.reactions[newReactionType] = 1;
                } else {
                    model.reactions[newReactionType]++;
                }

                reactionModelInstance.type = reaction;
                updateModelInstanceDispatch(reactionModel, reactionModelInstance);

                userData[model.id] = reaction;
                commentData = {
                    ["reactions." + oldReactionType]: FireIncrement(-1),
                    ["reactions." + newReactionType]: FireIncrement(1)
                };
                commentHeartData = {[AUTHSTORAGE.getUserId()]: reaction};
            } else {

                model.heart = (model.heart ?? 0) +1 ;
                if(model.reactions[newReactionType] !== undefined) {
                    model.reactions[newReactionType]++;
                } else {
                    model.reactions[newReactionType] = 1;
                }

                reactionModelInstance.type = reaction;
                updateModelInstanceDispatch(reactionModel, reactionModelInstance);

                userData[model.id] = reaction;
                commentData = {
                    ["reactions." + newReactionType]:  FireIncrement(1),
                    "heart":  FireIncrement(1)
                };
                commentHeartData = {[AUTHSTORAGE.getUserId()]: reaction};

                if(modelPath === 'article') {
                    sendReactionAtArticleNotification(model, authUser.fullName);
                }
            }
        }

        let operation = async () => {
            await Promise.all([
                ModelManager.update({resource: '/client/' + AUTHSTORAGE.getClientId() + '/user/' + authUser.id + '/' + modelPath + '_heart/', id: ""+model.cAt.getFullYear(), customData: userData, useSet: true}),
                ModelManager.update({modelInstance: model, customData: commentData}),
                ModelManager.update({resource: '/client/' + AUTHSTORAGE.getClientId() + '/' + modelPath + '/' + model.id + '/heart/', id: '_all', customData: commentHeartData, useSet: true})
            ]);
        }

        setRunningQueue(queue.push(operation));
        runAsQueue();
    };

    return (
        <ReactionRow
            isComment={isComment}
            reaction={authReaction?.type}
            reactionCount={model.heart}
            showCommentButtons={showCommentButtons}
            showCommentText={showCommentText}
            reactions={model.reactions}
            commentCount={commentCount}
            onCommentCountTap={onCommentCountTap}
            onCommentIconTap={onCommentIconTap}
            onLikeCountTap={onLikeCountTap}
            onChangeReaction={changeReaction}
            showLikeButton={showLikeButton}
        />
    )
}

const mapState = (state, ownProps) => {
    return {
        authReaction: getModelInstance(state, ownProps.reactionModel, ownProps.model.id),
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
    };
};

const mapAction = {
    deleteModelInstanceDispatch,
    updateModelInstanceDispatch
};


export default connect(mapState, mapAction)(ReactionRowContainer);