import React, {Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import DistributorMembership from "../../utils/models/DistributorMembership";
import ModelManager from "../../utils/manager/ModelManager";
import {getModelInstances} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import parameters from '../../config/parameters.json';

const GroupMemberContainer = ({distributorId, user, children}) => {

    let userIds =  distributorId === parameters.globalDistributorId
        ? user.filter((user) => !user.external).map( (user) => user.id)
        : user.filter((membership) => (membership.dis && membership.dis.hasOwnProperty(distributorId))).map( (membership) => membership.id) || [];


    useEffect(() => {
        if (distributorId) {
            ModelManager.subscribe(
                {
                    model: DistributorMembership,
                    subscriptionKey: "Membership:" + distributorId,
                    filter:[['dis.' + distributorId, '>=', ""]],
                    extraProps: {
                        'distributorId': distributorId
                    }
                }
            )
        }
        return () => {
            ModelManager.unsubscribe({model: DistributorMembership, subscriptionKey: "Membership:" + distributorId})
        }
    }, []);


    return(
        <Fragment>
            {
                children({userIds})
            }
        </Fragment>
    );
};

const mapState = (state, ownProps) => ({
    distributorId: ownProps.distributorId,
    user:  ownProps.distributorId === parameters.globalDistributorId
        ? getModelInstances(state, User)
        : getModelInstances(state, DistributorMembership)
});


export default connect(mapState)(GroupMemberContainer);
