import ArticleCreateUpdate from "./ArticleCreateUpdate";
import React, {useEffect} from "react";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import Article from "../../utils/models/Article";
import {ContentType} from "../../utils/enums/ContentType";
import CircularProgress from "@material-ui/core/CircularProgress";
import Survey from "../../utils/models/Survey";
import {addModelInstanceDispatch, deleteModelInstanceDispatch} from "../../utils/store/actions";
import {SurveyType} from "../../utils/enums/SurveyType";
import * as httpClient from "../../utils/httpclient/HttpClient";
import parameters from "../../config/parameters.json";
import {generateRandomId} from "../../utils/helper/RandomHelper";
import SUCCESSNOTIFICATIONMANAGER from "../../components/success/SuccessNotificationManager";
import SuccessMessage from "../../utils/models/SuccessMessage";
import {SuccessType} from "../../utils/enums/SuccessType";
import {FireAuth} from "../../api/FirebaseService";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import {getArticleTitlePath} from "../../utils/helper/ImageHelper";

/**
 * @param {String} id
 * @param {Article} article
 * @param {Survey[]} allSurveys
 * @param {Function} addModelInstanceDispatch
 * @param {Function} deleteModelInstanceDispatch
 * @param {String} originPath
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleUpdateContainer = ({id, article, allSurveys, addModelInstanceDispatch, deleteModelInstanceDispatch, originPath}) => {
    const firebaseStorageManager = new FirebaseStorageManager();
    const surveys = allSurveys.filter(survey => survey.aId === id);

    useEffect(() => {
        if(!article) {
            ModelManager.get({model: Article, id: id}).then(loadedArticle => loadSurveysIfNeeded(loadedArticle));
        } else {
            loadSurveysIfNeeded();
        }
    }, [article]);

    const loadSurveysIfNeeded = (loadedArticle) => {
        const loadArticle = article ?? loadedArticle;
        if(!!loadArticle) {
            loadArticle.content.forEach(con => {
                const surveys = allSurveys.filter(survey => survey.aId === id);
                if(con.type === ContentType.SURVEY && !surveys.find(sur => sur.aId === loadArticle.id)) {
                    ModelManager.get({model: Survey, id: con.surveyId, stopEvents: true}).then(async (survey) => {
                        if(survey.type === SurveyType.FREEANSWER) {
                            const authUserAnswer = await ModelManager.get({id: AUTHSTORAGE.getUserId(), resource: "/client/" + AUTHSTORAGE.getClientId() + "/survey/" + con.surveyId + "/answer", stopEvents: true});
                            if(!!authUserAnswer) {
                                survey.authUserAnswer = authUserAnswer['text'];
                            }
                        } else {
                            const authUserVotes = await ModelManager.get({id: AUTHSTORAGE.getUserId(), resource: "/client/" + AUTHSTORAGE.getClientId() + "/survey/" + con.surveyId + "/vote", stopEvents: true});
                            if(!!authUserVotes) {
                                survey.authUserVotes = authUserVotes['votes'];
                            }
                        }

                        addModelInstanceDispatch(Survey, survey);
                    });
                }
            });
        }
    };

    /**
     * @param {string} articleId
     * @param {ArticleContent[]} content
     * @param {string[]} oldMediaUrls
     */
    const deleteOldMedia = (articleId, content, oldMediaUrls) => {
        let mediaContent = content.filter(c => c.type === ContentType.MEDIA || c.type === ContentType.PDF);

        oldMediaUrls.forEach(url => {
            let newContent = mediaContent.find(c => c.image === url || c.video?.url === url || c.video?.thumbnail === url || c.path === url);
            if(!newContent) {
                firebaseStorageManager.deleteFile(url.replace(/%2F/g, '/'));
            }
        });
    };

    const deleteTitleImage = (article) => {
        article.hTI = false;
        let fireStorageUrl = new FireStorageUrl();
        fireStorageUrl.id = getArticleTitlePath(article);
        deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
        firebaseStorageManager.deleteFile(fireStorageUrl.id.replace(/%2F/g, '/'));
    }

    /**
     * @param {Article} article
     * @param {File} titleImage
     */
    const uploadMedia = async (article, titleImage) => {
        const mediaFutures = [];

        if(!!titleImage) {
            let titleImageUploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article/${article.id}/title_image`;
            mediaFutures.push(firebaseStorageManager.uploadImage(titleImage, titleImageUploadPath, 2048 , 1));
            article.hTI = true;

            let fireStorageUrl = new FireStorageUrl();
            fireStorageUrl.id = getArticleTitlePath(article);
            deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
        }

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];
            if(content.type === ContentType.MEDIA) {
                if(!!content.image && !!content.image.name) {
                    const uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article/${article.id}/${generateRandomId()}`;
                    mediaFutures.push(firebaseStorageManager.uploadImage(content.image, uploadPath, 1200 , 1));
                    content.image = uploadPath.substring(1).replace(/\//g, '%2F');
                }
            } else if(content.type === ContentType.PDF) {
                const uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article/${article.id}/${generateRandomId()}`;
                if(!!content.path && !!content.path.name) {
                    mediaFutures.push(firebaseStorageManager.uploadFile(content.path, uploadPath));
                    content.path = uploadPath.substring(1).replace(/\//g, '%2F');
                }
            }
        }

        await Promise.all(mediaFutures);
    }

    /**
     * @param {Article} article
     * @param {Survey[]} surveys
     * @param {Survey[]} oldSurveys
     */
    const createUpdateSurveys = async (article, surveys, oldSurveys) => {
        const surveyFutures = [];

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];

            const oldSurvey = oldSurveys.find(s => s.id === content.surveyId);
            const survey = surveys[content.surveyId];

            //Check if Content Type is Survey and Survey did not exist before (survey is new)
            if(content.type === ContentType.SURVEY && !oldSurvey) {

                if (survey && !!survey.question && survey.options && Object.keys(survey.options).length > 0) {
                    survey.total = 0;

                    const surveyData = {
                        aId: article.id,
                        question: survey.question,
                        total: 0,
                        type: survey.type,
                        options: {},
                        aP: survey.aP ?? "Privacy.PRIVATE"
                    };

                    if (survey.type !== SurveyType.FREEANSWER) {
                        surveyData.options = Object.assign({}, Object.values(survey.options).map(option => ({
                            text: option.text,
                            count: 0
                        })));
                    }

                    surveyFutures.push(ModelManager.update({
                        modelInstance: survey,
                        id: survey.id,
                        customData: surveyData,
                        useSet: true
                    }));
                }

            // Check if Content Type is Survey and there are no votes on the survey
            } else if(content.type === ContentType.SURVEY && !!oldSurvey && (!!oldSurvey.total || oldSurvey.total <= 0)) {
                const surveyData = {
                    aId: article.id,
                    question: survey.question,
                    total: 0,
                    type: survey.type,
                    options: {}
                };

                if(survey.type !== SurveyType.FREEANSWER) {
                    surveyData.options = Object.assign({}, Object.values(survey.options).map(option => ({text: option.text, count: 0})));
                } else {
                    surveyData.aP = survey.aP ?? "Privacy.PRIVATE";
                }

                surveyFutures.push(ModelManager.update({modelInstance: survey, id: survey.id, customData: surveyData, useSet: true}));
            }
        }

        await Promise.all(surveyFutures);
    };

    /**
     * @param {Article} article
     * @returns {Promise<boolean>}
     */
    const compressVideos = async (article) => {
        let uploadedVideos = false;
        let convertFutures = [];

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];

            if(content.type === ContentType.MEDIA && !!content.uploadId) {
                uploadedVideos = true;

                let formData = new FormData();
                formData.append('uploadId', ""+content.uploadId);

                let httpClientInstance = new httpClient.default(parameters.backendVideoUrl, {
                    headers: {
                        'Authorization': `Bearer ${await FireAuth.currentUser.getIdToken()}`
                    }
                });

                let request = httpClientInstance.post('/convert', formData);

                convertFutures.push(request.send());
            }
        }

        await Promise.all(convertFutures);
        return uploadedVideos;
    };

    /**
     * @param {Article} article
     * @param {File} titleImage
     * @param {string[]} oldMediaUrls
     * @param {Survey[]} newSurveys
     * @param {Survey[]} oldSurveys
     * @param {boolean} articleTitleImageChanged
     * @returns {Promise<void>}
     */
    const updateArticle = async (article, titleImage, oldMediaUrls, newSurveys, oldSurveys, articleTitleImageChanged) => {
        article.uAt = new Date();
        article.newFormat = false;

        deleteOldMedia(article.id, article.content, oldMediaUrls);
        await uploadMedia(article, titleImage);

        if (!titleImage && articleTitleImageChanged) {
            await deleteTitleImage(article);
        }
        await createUpdateSurveys(article, newSurveys, oldSurveys);

        // Update Article
        await ModelManager.update({modelInstance: article});
        SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.ARTICLEUPDATED, true));
        let uploadedVideos = await compressVideos(article);

        if(uploadedVideos) {
            ModelManager.subscribeDoc({model: Article, id: article.id, extraProps: {completionCheck: true}});
        }
    };

    if(!article) {
        return (
            <div className="moin-circular-progress-container">
                <CircularProgress />
            </div>
        );
    }

    return <ArticleCreateUpdate saveArticle={updateArticle} initialArticle={article} existingSurveys={surveys}  originPath={originPath}/>
}
const mapState = (state, ownProps) => ({
    article: getModelInstance(state, Article, ownProps.id),
    allSurveys: getModelInstances(state, Survey)
});

const mapAction = {
    addModelInstanceDispatch,
    deleteModelInstanceDispatch
};

export default connect(mapState, mapAction)(ArticleUpdateContainer);
