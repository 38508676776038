import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import NavigationPrompt from "react-router-navigation-prompt";
import MoinDialog from "./MoinDialog";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(1.5),
        backgroundColor: Colors.BLUELIGHT
    },
}));

/**
 * Moin! themed unsaved changes dialog.
 * @param hasChanges
 * @returns {JSX.Element}
 * @constructor
 */
const MoinUnsupportedBrowserDialog = ({onConfirm}) => {
    const [t, i18n] = useTranslation();

    return <MoinDialog
            id={'12345'}
            title={t('dialogs.unsupportedBrowser')}
            continueButtonText={t('dialogs.continue')}
            continueButtonCallback={onConfirm}
        >
            <Typography variant={"body1"} >
                {t('dialogs.unsupportedBrowserDescription')}
            </Typography>

    </MoinDialog>
};

export default MoinUnsupportedBrowserDialog;
