import {id, model, prop} from "../manager/decorators";
import ArticleContent from "./ArticleContent";
import {CURRENT_USER, SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import AllReactions from "./AllReactions";

@model('client/:client/article')
class Article {

    @id()
    id;

    @prop()
    title;

    @prop()
    heart;

    @prop()
    cCount;

    @prop({
        type: "object",
        typeOptions: {
            entry: "model",
            entryOptions: {
                target: AllReactions
            }
        }
    })
    reactions;

    @prop()
    dis;

    @prop()
    hTI;

    @prop()
    receivers;

    @prop({
        nullable: false,
        type: "array",
        typeOptions: {
            entry: "model",
            entryOptions: {
                target: ArticleContent
            }
        }
    })
    content = [];

    @prop({type: "date", readOnly:true,  sentinel: SERVER_TIMESTAMP})
    cAt;

    @prop({sentinel: CURRENT_USER})
    cUid;

    @prop()
    cName;

    @prop()
    newFormat;

    @prop({createOnly: true})
    copyFromUid;

    @prop({createOnly: true})
    copyFromArticleId;

    @prop({createOnly: true})
    public;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP})
    uAt;

    @prop({type: "date"})
    dAt;

    @prop()
    dBy;

    stream = false;

    @prop({type: "object", readOnly:true})
    allReactions;

    getTextTeaser(small) {
        let teaser;
        let teaserLength = small ? 100 : 220;

        for(let i = 0; i < this.content.length; i++) {
            if(!!this.content[i] && this.content[i].type === "ContentType.TEXT") {
                teaser = !teaser ? this.content[i].text : teaser + " " + this.content[i].text;
                if (teaser.length > teaserLength) {
                    teaser = teaser.substring(0, teaserLength) + '...';
                    break;
                }
            }
        }

        return teaser;
    }

    getFirstImage() {
        let image;

        if(!!this.content) {
            for(let i = 0; i < this.content.length; i++) {
                if(!image && !!this.content[i] && this.content[i].type === "ContentType.MEDIA" && (!!this.content[i].image || !!this.content[i].media)) {
                    if (this.content[i].image) {
                        image = this.content[i].image;
                    } else if (!!this.content[i].media && this.content[i].media.length > 0) {
                        image = this.content[i].media[0];
                    }
                    break;
                }
            }
        }

        return image;
    }
}

export default Article;

