import React, {useEffect, useState, Fragment} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import {
    MoinCard,
    MoinCardContent,
    MoinCardHeader
} from "../../components/base/MoinCard";
import MoinTextField from "../../components/base/MoinTextField";
import MoinButton from "../../components/base/MoinButton";
import MenuItem from "@material-ui/core/MenuItem";
import MoinSelect from "../../components/base/MoinSelect";
import {ClientType} from "../../utils/enums/ClientType";
import * as ROUTES from "../../Routes";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Redirect} from "react-router-dom";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import {deleteModelInstanceDispatch} from "../../utils/store/actions";
import {connect} from "react-redux";
import MoinImageUpload from "../../components/base/MoinImageUpload";
import * as ChatHelper from "../../utils/helper/ChatHelper";
import MoinDivider from "../../components/base/MoinDivider";
import {Colors} from "../../components/helper/ColorHelper";
import MoinUnsavedChangesDialog from "../../components/base/MoinUnsavedChangesDialog";
import {DateTime} from "luxon";
import {BirthdayPrivacy} from "../../utils/enums/BirthdayPrivacy";
import * as TranslationHelper from "../../components/helper/TranslationHelper";
import {Feature} from "../../utils/enums/Feature";

const useStyles = makeStyles(() => ({
        content: {
            padding: '16px',
            width: '100%'
        },
        divider: {
            margin: '0'
        },
        input: {
            marginTop: '8px'
        },
        optionalRow: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '12px'
        },
        select: {
            margin: '8px 0 16px 0',
            '& .MuiSelect-selectMenu': {
                padding: '10.5px 14px',
            }
        },
        afterCaption: {
            marginTop: '12px'
        },
        switch: {
            '& .MuiSwitch-colorSecondary.Mui-checked': {
                color: Colors.BRANDPRIMARY
            },
            '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
                backgroundColor: Colors.BRANDPRIMARY
            }
        }
}));

/**
 * User edit page
 *
 * @param {User} user Can only be the auth user
 * @param {Array<Department>}departments
 * @param {string} clientType
 * @param {Function} updateUser
 * @param deleteModelInstanceDispatch
 * @returns {JSX.Element}
 * @constructor
 */
const UserEdit = ({user, departments, clientType, updateUser, deleteModelInstanceDispatch}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [position, setPosition] = useState(user.position);
    const [emailPrivacy, setEmailPrivacy] = useState(user.emailPrivacy);
    const [department, setDepartment] = useState(user.department);
    const [addDep, setAddDep] = useState(user.addDep);
    const [office, setOffice] = useState(user.office);
    const [mobilePhone, setMobilePhone] = useState(user.mobilePhone);
    const [workPhone, setWorkPhone] = useState(user.workPhone);
    const [competencies, setCompetencies] = useState(user.competencies);
    const [personalInfo, setPersonalInfo] = useState(user.personalInfo);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [avatarRemoved, setAvatarRemoved] = useState(false);
    const [showOnlineState, setShowOnlineState] = useState(true);
    const [avatarFile, setAvatarFile] = useState();
    const [birthday, setBirthday] = useState(new DateTime.fromJSDate(user.birthday).toISODate());
    const [birthdayPrivacy, setBirthdayPrivacy] = useState(user.birthdayPrivacy);
    const [hasChanges, setHasChanges] = useState(false);
    const [errors, setErrors] = useState({});
    const [forceRebuild, setForceRebuild] = useState(true);

    useEffect(() => {
        loadShowOnlineState();
    }, []);

    const validate = () => {
        let hasError = false;

        if (!firstName) {
            errors['firstName'] = t('form.errors.cantBeEmpty', {field: t('user.firstName')});
            hasError = true;
        } else if (firstName.length <= 2) {
            errors['firstName'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('user.firstName'), chars: 2});
            hasError = true;
        } else if (firstName.length > 200) {
            errors['firstName'] = t('form.errors.mustBeLessThanXCharacters', {field: t('user.firstName'), chars: 500});
            hasError = true;
        }

        if (!lastName) {
            errors['lastName'] = t('form.errors.cantBeEmpty', {field: t('user.lastName')});
            hasError = true;
        } else if (lastName.length <= 2) {
            errors['lastName'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('user.lastName'), chars: 2});
            hasError = true;
        } else if (lastName.length > 200) {
            errors['lastName'] = t('form.errors.mustBeLessThanXCharacters', {field: t('user.lastName'), chars: 200});
            hasError = true;
        }

        if (!!position && position.length > 500) {
            errors['position'] = t('form.errors.mustBeLessThanXCharacters', {field: t('user.position'), chars: 500});
            hasError = true;
        }

        if (!!office && office.length > 200) {
            errors['office'] = t('form.errors.mustBeLessThanXCharacters', {field: t('user.office'), chars: 200});
            hasError = true;
        }

        if (!!department && department.length > 200) {
            errors['department'] = t('form.errors.mustBeLessThanXCharacters', {field: t('user.department'), chars: 200});
            hasError = true;
        }

        if (!!competencies && competencies.length > 500) {
            errors['competencies'] = t('form.errors.mustBeLessThanXCharacters', {field: t('user.competencies'), chars: 500});
            hasError = true;
        }

        if (!!personalInfo && personalInfo.length > 500) {
            errors['personalInfo'] = t('form.errors.mustBeLessThanXCharacters', {field: t('user.personalInfo'), chars: 500});
            hasError = true;
        }

        if (!!birthday && DateTime.fromISO(birthday) > DateTime.local().minus({years: 10})) {
            errors['birthday'] = t('form.errors.mustBeAtLeast10YearsOld');
            hasError = true;
        } else if (!!birthday && DateTime.fromISO(birthday) < DateTime.local().minus({years: 100})) {
            errors['birthday'] = t('form.errors.mustBeLessThan100YearsOld');
            hasError = true;
        }

        setErrors(errors);
        setForceRebuild(!forceRebuild);
        return !hasError;
    };

    const loadShowOnlineState = async () => {
        setShowOnlineState(await ChatHelper.getShowOnlineState());
    };

    const onChangeAvatar = (file) => {
        setHasChanges(true);
        if (!!file) {
            setAvatarRemoved(false);
            setAvatarFile(file);
        } else {
            setAvatarRemoved(true);
            setAvatarFile(null);
        }
    }

    const onSave = async () => {
        if(!loading && validate()) {
            setLoading(true);
            let updatedUser;
            updatedUser = user;

            updatedUser.firstName = firstName;
            updatedUser.lastName = lastName;
            updatedUser.position = position;
            updatedUser.emailPrivacy = emailPrivacy;
            updatedUser.department = department;
            updatedUser.addDep = addDep;
            updatedUser.office = office;
            updatedUser.mobilePhone = mobilePhone;
            updatedUser.workPhone = workPhone;
            updatedUser.competencies = competencies;
            updatedUser.personalInfo = personalInfo;
            updatedUser.birthday = !!birthday ? new DateTime.fromISO(birthday).toJSDate() : undefined;
            updatedUser.birthdayPrivacy = birthdayPrivacy;

            if(!!avatarFile) {
                updatedUser.hA = true;
                await updateUser(updatedUser, avatarFile, false, showOnlineState);
            } else if(avatarRemoved) {
                updatedUser.hA = false;
                await updateUser(updatedUser, undefined, true, showOnlineState);
            } else {
                await updateUser(updatedUser, undefined, false, showOnlineState);
            }

            let fireStorageUrl = new FireStorageUrl();
            fireStorageUrl.id = updatedUser.getAvatarUrl;
            deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);

            setLoading(false);
            setRedirect(true);
        }
    };

    if(redirect) {
        return (
            <Redirect push to={ROUTES.userShow.replace(':id', AUTHSTORAGE.getUserId())}/>
        )
    }

    return (
        <Fragment>
            <MoinUnsavedChangesDialog hasChanges={hasChanges} />
            <MoinCard>
                <MoinCardHeader>
                    <Typography variant="h3">{t('user.editProfile')}</Typography>
                </MoinCardHeader>
                <MoinDivider />
                <MoinCardContent hideContentSpacer={true}>
                    <div className={classes.content}>
                        <MoinImageUpload image={user.avatar} onChangeCallback={onChangeAvatar} />
                        <MoinTextField
                            className={classes.input}
                            title={t('user.firstName')}
                            maxCharacters={200}
                            value={firstName}
                            onChange={e => {
                                setHasChanges(true);
                                setFirstName(e.target.value);
                            }}
                            error={errors['firstName']}
                            executeScroll={!!errors['firstName']}
                        />

                        <MoinTextField
                            className={classes.input}
                            title={t('user.lastName')}
                            maxCharacters={200}
                            value={lastName}
                            onChange={e => {
                                setHasChanges(true);
                                setLastName(e.target.value);
                            }}
                            error={errors['lastName']}
                            executeScroll={!!errors['lastName']}
                        />

                        {
                            !AUTHSTORAGE.isExternal() &&
                            <MoinTextField
                                className={classes.input}
                                title={t('user.position')}
                                maxCharacters={500}
                                multiline={true}
                                rows={3}
                                rowsMax={3}
                                isOptional={true}
                                value={position}
                                onChange={e => {
                                    setHasChanges(true);
                                    setPosition(e.target.value);
                                }}
                                error={errors['position']}
                                executeScroll={!!errors['position']}
                            />
                        }


                        <MoinTextField
                            className={classes.input}
                            title={t('user.emailAddress')}
                            disabled={true}
                            value={user.email}
                        />
                        <Typography variant={'caption'}>
                            {t('user.changeEmail')}
                        </Typography>

                        {
                            !AUTHSTORAGE.isExternal() &&
                            <>
                                <div className={classes.optionalRow}>
                                    <Typography variant={'body1'}>{t('user.emailPrivacySwitch')}</Typography>
                                    <Switch
                                        className={classes.switch}
                                        checked={!emailPrivacy}
                                        onChange={e => {
                                            setHasChanges(true);
                                            setEmailPrivacy(!e.target.checked);
                                        }}
                                    />
                                </div>
                                <Typography variant={'caption'} >
                                    {
                                        !emailPrivacy
                                            ? t('user.emailVisible')
                                            : t('user.emailInvisible')
                                    }
                                </Typography>
                            </>
                        }


                        <div className={classes.optionalRow}>
                            <Typography variant={'body1'}>{t('user.onlineStatusPrivacy')}</Typography>
                            <Switch
                                className={classes.switch}
                                checked={showOnlineState}
                                onChange={e => {
                                    setHasChanges(true);
                                    setShowOnlineState(e.target.checked);
                                }}
                            />
                        </div>
                        <Typography variant={'caption'} className={classes.caption}>
                            {
                                showOnlineState
                                    ? t('user.onlineStatusVisible')
                                    : t('user.onlineStatusInvisible')
                            }
                        </Typography>

                        {
                            !AUTHSTORAGE.isExternal() &&
                            <>
                                <Typography variant={'h2'} className={classes.afterCaption}>{ TranslationHelper.department(t).toUpperCase() }</Typography>
                                <MoinSelect
                                    disabled={!!AUTHSTORAGE.getClient().features && AUTHSTORAGE.getClient().features.includes(Feature.DEPARTMENTSLOCKED)}
                                    className={classes.select}
                                    value={department}
                                    onChange={e => {
                                        if (!AUTHSTORAGE.getClient().features || !AUTHSTORAGE.getClient().features.includes(Feature.DEPARTMENTSLOCKED)) {
                                            setHasChanges(true);
                                            setDepartment(e.target.value);
                                        }
                                    }}
                                >
                                    <MenuItem
                                        key={'none'}
                                        value={null}
                                    >
                                        { TranslationHelper.noDepartmentSelected(t) }
                                    </MenuItem>
                                    {
                                        departments
                                            .sort((a,b) => a.name.localeCompare(b.name))
                                            .map(department =>
                                                <MenuItem
                                                    key={department.id}
                                                    value={department.id}
                                                >
                                                    {department.name}
                                                </MenuItem>)
                                    }
                                </MoinSelect>

                                {
                                    clientType === ClientType.ASSOCIATION &&
                                    <MoinTextField
                                        className={classes.input}
                                        title={t('user.department')}
                                        isOptional={true}
                                        maxCharacters={200}
                                        value={addDep}
                                        onChange={e => {
                                            setHasChanges(true);
                                            setAddDep(e.target.value);
                                        }}
                                    />
                                }

                                <MoinTextField
                                    className={classes.input}
                                    title={t('user.office')}
                                    isOptional={true}
                                    maxCharacters={200}
                                    value={office}
                                    onChange={e => {
                                        setHasChanges(true);
                                        setOffice(e.target.value);
                                    }}
                                    error={errors['office']}
                                    executeScroll={!!errors['office']}
                                />
                            </>
                        }

                        <MoinTextField
                            className={classes.input}
                            title={t('user.birthday')}
                            value={birthday}
                            isOptional={true}
                            type={"date"}
                            min={DateTime.local().minus({years: 100}).toISODate()}
                            max={DateTime.local().minus({years: 10}).toISODate()}
                            onChange={e => {
                                setHasChanges(true);
                                setBirthday(e.target.value);
                            }}
                            error={errors['birthday']}
                            executeScroll={!!errors['birthday']}
                        />
                        <div className={classes.optionalRow}>
                            <Typography variant={'body1'}>{t('user.birthdateVisible')}</Typography>
                            <Switch
                                className={classes.switch}
                                checked={birthdayPrivacy === BirthdayPrivacy.PUBLIC || birthdayPrivacy === BirthdayPrivacy.NOYEAR}
                                onChange={e => {
                                    setHasChanges(true);
                                    if (e.target.checked) {
                                        setBirthdayPrivacy(BirthdayPrivacy.PUBLIC);
                                    } else {
                                        setBirthdayPrivacy(BirthdayPrivacy.PRIVATE);
                                    }
                                }}
                                disabled={!birthday}
                            />
                        </div>
                        <Typography variant={'caption'} >
                            {
                                birthdayPrivacy === BirthdayPrivacy.PUBLIC || birthdayPrivacy === BirthdayPrivacy.NOYEAR
                                    ? t('user.birthdayVisible')
                                    : t('user.birthdayPrivate')
                            }
                        </Typography>
                        <div className={classes.optionalRow}>
                            <Typography variant={'body1'}>{t('user.showBirthYear')}</Typography>
                            <Switch
                                className={classes.switch}
                                checked={birthdayPrivacy === BirthdayPrivacy.PUBLIC}
                                disabled={!birthday || (birthdayPrivacy === BirthdayPrivacy.PRIVATE || !birthdayPrivacy)}
                                onChange={e => {
                                    setHasChanges(true);
                                    if (e.target.checked) {
                                        setBirthdayPrivacy(BirthdayPrivacy.PUBLIC);
                                    } else {
                                        setBirthdayPrivacy(BirthdayPrivacy.NOYEAR);
                                    }
                                }}
                            />
                        </div>
                        <Typography variant={'caption'} className={classes.caption}>
                            {
                                birthdayPrivacy === BirthdayPrivacy.NOYEAR
                                    ? t('user.yearOfBirthPrivate')
                                    : t('user.yearOfBirthVisible')
                            }
                        </Typography>
                        <MoinTextField
                            className={classes.input}
                            title={t('user.mobile')}
                            isOptional={true}
                            value={mobilePhone}
                            onChange={e => {
                                setHasChanges(true);
                                setMobilePhone(e.target.value);
                            }}
                        />

                        {
                            !AUTHSTORAGE.isExternal() &&
                            <MoinTextField
                                className={classes.input}
                                title={t('user.phone')}
                                isOptional={true}
                                value={workPhone}
                                onChange={e => {
                                    setHasChanges(true);
                                    setWorkPhone(e.target.value);
                                }}
                            />
                        }

                        {
                            !AUTHSTORAGE.isExternal() &&
                            <MoinTextField
                                className={classes.input}
                                title={t('user.skills')}
                                isOptional={true}
                                multiline={true}
                                rows={3}
                                rowsMax={3}
                                maxCharacters={500}
                                value={competencies}
                                onChange={e => {
                                    setHasChanges(true);
                                    setCompetencies(e.target.value);
                                }}
                                error={errors['competencies']}
                                executeScroll={!!errors['competencies']}
                            />
                        }

                        <MoinTextField
                            className={classes.input}
                            title={t('user.info')}
                            isOptional={true}
                            multiline={true}
                            rows={3}
                            rowsMax={3}
                            maxCharacters={500}
                            value={personalInfo}
                            onChange={e => {
                                setHasChanges(true);
                                setPersonalInfo(e.target.value);
                            }}
                            error={errors['personalInfo']}
                            executeScroll={!!errors['personalInfo']}
                        />
                    </div>
                </MoinCardContent>
                <MoinDivider />
                <MoinCardHeader>
                    <MoinButton disabled={loading} onClick={onSave}>{t('common.saveChanges')}</MoinButton>
                </MoinCardHeader>
            </MoinCard>
        </Fragment>
    );
}

const mapAction = {
    deleteModelInstanceDispatch
};

export default connect(null, mapAction)(UserEdit)
