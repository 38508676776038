import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme) => ({
    container : {
        paddingTop: theme.spacing(3),
    },
    headline: {
        textTransform: 'uppercase',
    },
}));


const MoinSectionHeadline =  ({label}) => {
    const classes = useStyles();

    return (
        <ListItem divider={true}  className={classes.container}>
            <Typography variant={"h4"} className={classes.headline}>
                {label}
            </Typography>
        </ListItem>
    )
};

export default MoinSectionHeadline;
