import model from "../manager/decorators/model";
import {prop} from "../manager/decorators";

@model(null)
class ContentDocument {

    @prop()
    name;

    @prop()
    url;

    @prop()
    size;
}

export default ContentDocument;