import React, {Fragment, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../components/helper/ColorHelper";
import CheckOutlineIcon from "../../assets/icons/check-outline.svg";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg";
import ArrowUpIcon from "../../assets/icons/arrow-up.svg";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";
import CheckIcon from "../../assets/icons/check.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import EventsInactiveIcon from "../../assets/icons/events-inactive.svg";
import CalendarIcon from "../../assets/icons/calendar-2.svg";
import CheckclistIcon from "../../assets/icons/checklist.svg";
import UserSharedIcon from "../../assets/icons/user-shared.svg";
import AlphabetIcon from "../../assets/icons/alphabet.svg";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import MoinSimpleMenu from "../../components/base/MoinSimpleMenu";
import MoinSimpleMenuContext from "../../components/base/MoinSimpleMenuContext";
import MoinSimpleMenuOption from "../../components/base/MoinSimpleMenuOption";
import MoinFab from "../../components/base/MoinFab";
import {SortDirections, TaskSortField} from "../../utils/enums/TaskSort";
import TaskInfo from "./TaskInfo";
import clsx from "clsx";
import AddTasksDialog from "../../components/tasks/AddTaskDialog";
import TaskDate from "../../components/tasks/TaskDate";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import FirestoreImage from "../../components/base/FirestoreImage";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import {TaskViewType} from "../../utils/enums/TaskViewType";
import User from "../../utils/models/User";
import {isDateLastWeek, isDateYesterday, isDateToday} from "../../utils/helper/DateHelper";
import MoinSearchField from "../../components/base/MoinSearchField";
import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import {dateIconHelper} from "../../utils/helper/DateIconHelper";
import ColleagueTasksContainer from "./ColleagueTasksContainer";
import TaskEmptyState from "./TaskEmptyState";
import {TaskActivityType} from "../../utils/enums/TaskActivityType";
import Task from "../../utils/models/Task";
import {getActivityDate, getActivityMessage, getTaskUser} from "../../components/helper/TextHelper";
import * as ROUTES from "../../Routes";
import {Route, useHistory} from "react-router-dom";
import TasksContainer from "./TasksContainer";

const useStyles = makeStyles((theme) => ({
    taskWrap: {
        display: "flex",
    },
    taskListWrap: {
        maxWidth: "768px",
        flex: 100,
        minWidth: '0',
    },
    sideSpacer: {
        flex: 1,
    },
    sideSpacerContainer: {
        position: 'relative',
        flex: 1,
        marginLeft:'32px'
    },
    taskOptions: {
        margin: '64px 0 32px',
        '& div div': {
            width: 'calc(50% - 4px)',
            padding: '16px 16px 12px',
            background: Colors.WHITE,
            boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.05)'
        },
        '& div div:nth-child(even)': {
            float: 'right'
        },
        '& p': {
            marginTop: '12px'
        },
        '& h1': {
            float: 'right',
            fontSize: '24px',
            lineHeight: '28px'
        },
        '& svg': {
            height: '32px',
            width: '32px',
            verticalAlign: 'top'
        }
    },
    taskOptionsDrawer: {
        '& p': {
            marginTop: '12px',
            fontSize: '13px',
            lineHeight: '18px'
        },
        '& h1': {
            float: 'right',
            fontSize: '20px',
            lineHeight: '26px'
        },
        '& svg': {
            verticalAlign: 'middle'
        }
    },
    optionWrapper: {
        display: 'inline-block',
        cursor: 'pointer',
        marginBottom: '8px',
        borderRadius: '16px',
        width: '100%',
        padding: '12px 16px 12px 12px',
        background: Colors.WHITE,
        boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.05)"
    },
    dateIcon: {
        '& svg': {
            fill: Colors.BLACK,
            fillRule: "evenodd"
        }
    },
    userName: {
        fontWeight: '700'
    },
    taskDrawer: {
        '& .MuiDrawer-paper': {
            width: "230px",
            margin: "65px 0 0 240px",
            border: 'none',
            padding: '24px 32px',
            background: 'transparent'
        }
    },
    drawerHeader: {
        cursor: 'pointer',
        color: Colors.BRANDSECONDARY,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
        '& svg': {
            height: '16px',
            width: '16px',
            marginRight: '8px',
        }
    },
    taskListHeader: {
        marginTop: '65px',
        paddingBottom: '8px',
        borderBottom: '1px solid' + Colors.BLUELIGHT,
        display: 'flex',
        alignItems: 'center',
    },
    taskSort: {
        display: 'flex',
        alignItems: 'center'
    },
    taskListSort: {
        alignItems: 'center',
        display: 'inline-flex',
        cursor: 'pointer',
        marginLeft: '4px'
    },
    sortIcon: {
        height: '16px',
        '& svg': {
            fill: Colors.BLACKLIGHT,
            height: '16px',
            width: '16px'
        }
    },
    showCompleted: {
        float: 'right',
        color: Colors.BRANDSECONDARY,
        cursor: 'pointer',
        marginLeft: 'auto'
    },
    taskItem: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid' + Colors.BLUELIGHT,
        padding: '8px 4px',
        cursor: 'pointer',
        height: '64px',
        position: 'relative',
        zIndex: '1',
        '&:last-child': {
            borderBottom: 'none'
        },
        '& svg': {
            height: '32px',
            width: '32px'
        },
        '&:hover': {
            background: Colors.WHITE
        },
        '&::before': {
            position: 'absolute',
            content: "''",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundImage: 'linear-gradient(90.00deg, rgba(33, 205, 143, 0.5) 15.00%, rgba(196, 196, 196, 0) 90.00%)',
            zIndex: '-1',
            transition: 'opacity 1.5s linear',
            opacity: 0
        },
    },
    taskItemComplete: {
        '&::before': {
            opacity: 0.5
        }
    },
    taskNameCompleted: {
        color: Colors.BLACKLIGHTER
    },
    taskAssignee: {
        marginLeft: 'auto',
        minWidth: '32px',
        paddingLeft: '12px',
        boxSizing: 'content-box'
    },
    iconContainer: {
        width: '26px',
        height: '26px',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            verticalAlign: 'middle',
            marginRight: '0',
            width: '24px',
            height: '24px',
        }
    },
    taskIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '32px'
    },
    taskInfo: {
        marginLeft: '8px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& p': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    },
    taskDueDate: {
        verticalAlign: 'top'
    },
    taskOptionsItem: {
        padding: '15px 20px',
        backgroundColor: Colors.WHITE + ' !important',
        '& p': {
            fontSize: '15px',
            lineHeight: '24px'
        },
        '&:hover': {
            backgroundColor: Colors.BLUELIGHT + ' !important'
        },
        '& svg': {
            marginRight: '12px'
        }
    },
    taskContextOptions: {
        zIndex: '1249 !important',
        marginTop: '24px'
    },
    taskInbox: {
        paddingBottom: '8px',
        borderBottom: '1px solid' + Colors.BLUELIGHT
    },
    InboxActivityList: {
        '& > div': {
            padding: '8px 16px',
            borderBottom: '1px solid' + Colors.BLUELIGHT,
            background: Colors.WHITE,
            margin: '0'
        },
        '& > div:last-child': {
            borderBottom: 'none'
        },
        '& > div:first-child': {
            margin: '0'
        }
    },
    inboxTimePeriod: {
        margin: '8px 0 4px',
        paddingLeft: '16px'
    },
    taskActivity: {
        padding: '0 0 8px 16px',
        cursor: 'pointer'
    },
    activityTaskIcon: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px'
    },
    taskIconContainer: {
        marginRight: '8px',
        width: '20px',
        height: '20px',
        minWidth: '20px',
        textAlign: 'center'
    },
    iconContainerActivity: {
        width: '16px',
        height: '16px',
        borderRadius: '20px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            verticalAlign: 'middle',
            marginRight: '0',
            width: '16px',
            height: '16px',
        }
    },
    activityIcon: {
        width: '20px',
        height: '20px',
    },
    activityTask: {
        color: Colors.BLACK,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    activityAssigneeMessage: {
        display: 'flex'
    },
    activityTaskAssignee: {
        verticalAlign: 'top',
        marginRight: '12px',
        minWidth: '32px',
        textAlign: 'center'
    },
    activityMessage: {
        display: 'inline-block',
        verticalAlign: 'top',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    message: {
        color: Colors.BLACK,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '& h6': {
            color: Colors.BLACK
        }
    },
    fabContainer: {
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        zIndex: 1200
    },
    fabSizing: {
        flex: '100',
        maxWidth: '768px',
        position: 'relative'
    },
    taskSnackbarContainer: {
        position: 'absolute',
        bottom: '0',
        width: "calc(100% - 70px)",
        left: '0',
        pointerEvents: 'all'
    },
    minWidthAnimation: {
        minWidth: "416px",
        "-webkit-transition-duration": ".15s",
        "transition-duration": ".15s",
        "-webkit-transition-property": "width",
        "transition-property": "width",
        "-webkit-transition-timing-function": "cubic-bezier(0.4,0.0,1,1)",
        "transition-timing-function": "cubic-bezier(0.4,0.0,1,1)"
    },
    minWidthAnimationLeft: {
        minWidth: "214px",
        "-webkit-transition-duration": ".15s",
        "transition-duration": ".15s",
        "-webkit-transition-property": "width",
        "transition-property": "width",
        "-webkit-transition-timing-function": "cubic-bezier(0.4,0.0,1,1)",
        "transition-timing-function": "cubic-bezier(0.4,0.0,1,1)"
    },
    taskSnackbarContent: {
        borderRadius: '8px',
        width: '100%',
        padding: '16px 16px 16px 20px',
        marginBottom: '16px',
        display: "flex",
        alignItems: 'center',
        '& svg': {
            width: '24px',
            height: '24px',
            minWidth: '24px',
            marginLeft: 'auto'
        }
    },
    taskSnackbarContentDelete: {
        padding: '20px 20px 18px',
    },
    snackbarText: {
        color: Colors.WHITE,
        whiteSpace: 'nowrap',
        margin: '0 16px 0 4px'
    },
    snackbarTextTitle: {
        color: Colors.WHITE,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    snackbarButton: {
        color: Colors.WHITE,
        marginLeft: 'auto',
        cursor: 'pointer'
    },
    colleagueListContainer: {
        margin: '64px auto 20px',
        maxWidth: '736px',
        '& .MuiList-root': {
            maxHeight: 'none'
        },
        '& .MuiOutlinedInput-root input': {
            padding: '10px 0'
        },
        '& .MuiTabs-root': {
            minHeight: '46px',
            '& button': {
                minHeight: '46px'
            },
        },
        '& .MuiListItem-root': {
            padding: '8px',
        }
    }
}));

const TaskActivityDateSection = {
    TODAY: "today",
    YESTERDAY: "yesterday",
    LASTWEEK: "lastWeek",
    OLDER: "older"
};

/**
 *
 * @param {Array<Task>}tasks
 * @param {Array<Task>}searchTask
 * @param {Array<User>} users
 * @param {String} initialOpenTask
 * @param {Function} onCreate
 * @param {Function} onDelete
 * @param {Function} onUndoDelete
 * @param {Function} onComplete
 * @param {Function} onTitleChange
 * @param {Function} onDescriptionChange
 * @param {Function} onRepetitionChange
 * @param {Function} setDueDate
 * @param {Function} removeAssignee
 * @param {Function} onComplete
 * @param {Function} onAddImages
 * @param {Function} onAssign
 * @param {Function} onAddDocuments
 * @param {Function} onRemoveDocument
 * @param {Function} onRemoveImage
 * @param {Function} onAddNotification
 * @param {Function} onRemoveNotification
 * @param {Function} onSearch
 * @returns {JSX.Element}
 * @constructor
 */
const Tasks = ({tasks, searchedTasks,  users, initialOpen, onCreate, onDelete, onUndoDelete, onComplete, onTitleChange, onDescriptionChange, onRepetitionChange, setDueDate, onAssign, removeAssignee, onAddImages, onAddDocuments, onRemoveDocument, onRemoveImage,   onAddNotification, onRemoveNotification, onSearch}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const history = useHistory();

    let initialOpenTask;
    let initialOpenView;

    const checkInitialView = () => {
        switch(initialOpen) {
            case TaskViewType.HOME:
            case TaskViewType.TODAY:
            case TaskViewType.PLANNED:
            case TaskViewType.MYTASKS:
            case TaskViewType.COLLEAGUELIST:
                initialOpenView= initialOpen;
                break;
            default:
                initialOpenTask = initialOpen;
                initialOpenView= TaskViewType.HOME;
                break;
        }
    }
    checkInitialView();

    const [showAddTask, setShowAddTask] = useState(false);

    const [showSnackBarDeleted, setShowSnackBarDeleted] = useState(false);
    const [showSnackBarCreated, setShowSnackBarCreated] = useState(false);

    const [view, setView] = useState(searchedTasks ? TaskViewType.SEARCH : initialOpenView);

    const [displayInfoTask, setDisplayInfoTask] = useState(initialOpenTask);
    const [taskActivities, setTaskActivities] = useState({});

    const [search, setSearch] = useState();
    const [deletedTask, setDeletedTask] = useState();

    const sortDirection = useRef(SortDirections.ASCENDING);
    const sortField = useRef(TaskSortField.DUEDATE);
    const [rebuild, setRebuild] = useState(false);

    const scrollAnchor = useRef();
    const activityAmount = useRef(10);

    const recentlyCompletedItems = useRef( {});

    const initialMouseState = {
        mouseX: null,
        mouseY: null,
    };
    const [mouseState, setMouseState] = React.useState(initialMouseState);
    const [showKey, setShowKey] = React.useState(null);
    const [showCompletedTasks, setShowCompletedTasks] = React.useState(true);

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle, true);
        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [view]);

    const onScrollHandle = () => {
        let relevantTasks = tasks.filter( (task) => task.relevant.includes(AUTHSTORAGE.getUserId()));
        if(!!scrollAnchor.current && activityAmount.current < relevantTasks.length &&  (scrollAnchor.current.getBoundingClientRect().bottom <= window.innerHeight + 250) ) {

            if (view === TaskViewType.SEARCH) {
                onSearch(searchedTasks.length);
            } else if (view === TaskViewType.HOME) {
                activityAmount.current += 10;
                setRebuild((rebuild) => !rebuild);
            }

        }
    };

    useEffect( () => {
        let taskActivities = {
            [TaskActivityDateSection.TODAY]: [],
            [TaskActivityDateSection.YESTERDAY] : [],
            [TaskActivityDateSection.LASTWEEK] : [],
            [TaskActivityDateSection.OLDER] : [],
        };
        let relevantTasks = tasks.filter( (task) => task.relevant.includes(AUTHSTORAGE.getUserId()));

        relevantTasks.forEach(
            (task) => {
                task.activities.forEach(
                    (activity) => {
                        if (activity.cUid !== AUTHSTORAGE.getUserId()) {
                            let field;

                            if (isDateToday(activity.cAt) ) {
                                field = TaskActivityDateSection.TODAY;
                            } else if (isDateYesterday(activity.cAt)) {
                                field = TaskActivityDateSection.YESTERDAY;
                            } else if (isDateLastWeek(activity.cAt)) {
                                field = TaskActivityDateSection.LASTWEEK;
                            } else {
                                field = TaskActivityDateSection.OLDER;
                            }

                            taskActivities[field].push({
                                activity: activity,
                                task: task
                            });
                        }
                    }
                )
            }
        )
        Object.keys(taskActivities).forEach( (key) => {
            taskActivities[key].sort((a, b) => b.activity.cAt - a.activity.cAt);
        })
        setTaskActivities(taskActivities);
    }, [tasks])


    const defaultView = () => {
        activityAmount.current = 15;
        setView(TaskViewType.HOME);
        setDisplayInfoTask(undefined);
        setRoutingUrl(TaskViewType.HOME);
    }

    const todayView = () => {
        setView(TaskViewType.TODAY);
        setRoutingUrl(TaskViewType.TODAY);
    }

    const plannedView = () => {
        setView(TaskViewType.PLANNED);
        setRoutingUrl(TaskViewType.PLANNED);
    }

    const myTasksView = () => {
        setView(TaskViewType.MYTASKS);
        setRoutingUrl(TaskViewType.MYTASKS);
    }

    const assignedToOthersView = () => {
        setView(TaskViewType.ASSIGNEDTOOTHERS);
        setRoutingUrl(TaskViewType.ASSIGNEDTOOTHERS);
    }

    const colleagueListView = () => {
        setView(TaskViewType.COLLEAGUELIST);
        setSelectedColleague(null);
        setRoutingUrl(TaskViewType.COLLEAGUELIST);
    }

    const onColleagueListBack = () => {
        setSelectedColleague(null);
    }

    const addContentOptions = (task) => [
        new MoinSimpleMenuOption(task.completed ? t('tasks.markIncomplete') : t('tasks.markCompleted'), () => completeTask(task), <CheckOutlineIcon/>),
        new MoinSimpleMenuOption(t('common.delete'), () => deleteTask(task), <DeleteIcon/>)
    ];

    const sortOptions = () => [
        new MoinSimpleMenuOption(t('tasks.name'), () => setSortField(TaskSortField.NAME), <AlphabetIcon/>),
        new MoinSimpleMenuOption(t('tasks.dueDate'), () => setSortField(TaskSortField.DUEDATE), <EventsInactiveIcon/>)
    ];

    const onChangeSortDirection = () => {
        sortDirection.current = sortDirection.current === SortDirections.ASCENDING ? SortDirections.DESCENDING : SortDirections.ASCENDING;
        setRebuild(!rebuild);
    }

    const setSortField = (field) => {
        sortField.current = field;
        sortDirection.current = SortDirections.ASCENDING;
        setRebuild(!rebuild);
    }

    const deleteTask = (task) => {
        setShowSnackBarDeleted(true);
        setDeletedTask(Object.assign(new Task(),task));
        onDelete(task);
        setTimeout(() => {
            setShowSnackBarDeleted(false);
        }, 5000);
    }

    const undoDelete = () => {
        onUndoDelete(deletedTask);
        setShowSnackBarDeleted(false);
    }

    const [createdTask, setCreatedTask] = useState();

    const createTask = (data) => {
        setShowAddTask(false);
        let task = onCreate(data);
        setShowSnackBarCreated(true);
        setCreatedTask(task);
        setTimeout(() => {
            setShowSnackBarCreated(false);
        }, 5000);
    }

    const viewCreatedTask = () => {
        setShowSnackBarCreated(false);
        setDisplayInfoTask(createdTask);
        setRoutingUrl(createdTask.id);
    }

    const setRoutingUrl = (routeParam) => {
        history.replace({pathname: ROUTES.tasksShow.replace(":id", routeParam)})
    }

    const completeTask = (task, event) => {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (task) {
            if (!task.completed) {
                recentlyCompletedItems.current[task.id] = task.uAt;
            } else {
                delete recentlyCompletedItems.current[task.id];
            }
        }

        onComplete(task);

        setTimeout(() => {
            delete recentlyCompletedItems.current[task.id];
            setRebuild((old) => !old);
        }, 2000);

        setTimeout(() => {
            setRebuild((old) => !old);
        }, 6000);
    }

    const handleClick = (event, key) => {
        event.preventDefault();
        event.stopPropagation();

        if (showKey !== null) {
            handleClose();
        } else {
            setShowKey(key);
            setMouseState({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            });
        }
    };

    const handleClose = () => {
        setShowKey(null);
        setMouseState(initialMouseState);
    };

    const isTaskDueToday = (task, ignoreCompletedTasks) => {
        if (task.completed === true && (ignoreCompletedTasks || !showCompletedTasks)) {
            return false;
        } else if (
            task.aUid === AUTHSTORAGE.getUserId()
            || (
                task.cUid === AUTHSTORAGE.getUserId()
                && task.aUid === undefined
            )
        ) {
            if (task.completed) {
                return task.activities.find( (activity) =>  activity.type === TaskActivityType.FINISHED && isDateToday(activity.cAt) )
            } else {
                if(!task.repetition) {
                    let today = new Date();
                    today.setHours(23);
                    today.setMinutes(59);
                    return task.dueDate && task.dueDate.getTime() < today.getTime();
                } else {
                    return task.isRepeatingAtDay(new Date());
                }
            }
        }
        return false;
    };

    const isTaskPlanned = (task, ignoreCompletedTasks) => {
        if (task.completed === true && (ignoreCompletedTasks || !showCompletedTasks)) {
            return false;
        } else if (
            (task.aUid === AUTHSTORAGE.getUserId() || (task.cUid === AUTHSTORAGE.getUserId() && task.aUid === undefined)) && !!task.dueDate
        ) {
            let today = new Date();
            today.setHours(23);
            today.setMinutes(59);
            return today.getTime() < task.dueDate.getTime();
        }
        return false;
    };

    const isTaskMyTask = (task, ignoreCompletedTasks) => {
        if (task.completed === true && (ignoreCompletedTasks || !showCompletedTasks)) {
            return false;
        } else if (task.aUid === AUTHSTORAGE.getUserId() || (task.cUid === AUTHSTORAGE.getUserId() && task.aUid === undefined)) {
            return true;
        }
        return false;
    };

    const isTaskAssignedToOthers = (task, ignoreCompletedTasks) => {
        if (task.completed === true && (ignoreCompletedTasks || !showCompletedTasks)) {
            return false;
        } else if (task.aUid !== AUTHSTORAGE.getUserId() && task.aUid !== undefined && task.cUid === AUTHSTORAGE.getUserId()) {
            return true;
        }
        return false;
    };

    const triggerShowCompletedChange = () => {
        setShowCompletedTasks(!showCompletedTasks);
    }

    const getTaskCount = () => {
        let taskCount = {todayCount: 0, plannedCount: 0, myTasksCount: 0, assignedToOthers:0};

        if (tasks) {
            tasks.forEach(function(value) {
                if (isTaskDueToday(value, true)) {
                    taskCount.todayCount++;
                }
                if (isTaskPlanned(value, true)) {
                    taskCount.plannedCount++;
                }
                if (isTaskMyTask(value, true)) {
                    taskCount.myTasksCount++;
                }
                if (isTaskAssignedToOthers(value, true)) {
                    taskCount.assignedToOthers++;
                }
            });
        }
        return taskCount;
    };

    const [selectedColleague, setSelectedColleague] = React.useState(null);

    const onUserSelection = (user) => {
        setSelectedColleague(user);
    }

    const displayDateActivity = (date) =>  getActivityDate(t,date);
    const displayActivityMessage = (activity) => getActivityMessage(t,users, activity);

    const displayDateIcon = () => {
        let date = new Date().getDate();

        return <span className={classes.dateIcon}>{dateIconHelper(date)}</span>;
    }


    const displayTask = (task) => {
        let assignee;

        if (task.aUid) {
            assignee = users.find( (user) => user.id === task.aUid)
        }

        return <div
            key={'task_overview_' + task.id}
            className={classes.taskItem}
            onClick={() => onTaskClick(task)}
            onContextMenu={(e) => handleClick(e, task.id)}
            style={showKey === task.id
                ? {background: Colors.BLUELIGHT}
                : (displayInfoTask?.id === task.id ? {background: Colors.BLUELIGHT} : {})
            }>
            <div className={classes.taskIcon}
                 onClick={(e) => completeTask(task, e)}>
                {
                    task.getCompletedStatusForDate(new Date())
                        ? <div className={classes.iconContainer} style={{backgroundColor: Colors.ACTIVEGREEN}}>
                            <CheckIcon fill={Colors.WHITE}/>
                        </div>
                        : <CheckOutlineIcon fill={Colors.BLACKLIGHTEST}/>
                }
            </div>
            <div className={classes.taskInfo}>
                {
                    task.getCompletedStatusForDate(new Date())
                        ? <Typography className={classes.taskNameCompleted} variant={"body2"}>{task.title}</Typography>
                        : <Typography variant={"body2"}>{task.title}</Typography>
                }
                {
                    task.dueDate &&
                        <TaskDate
                            task={task}
                            completed={task.getCompletedStatusForDate(new Date())}/>
                }
            </div>
            <span className={classes.taskAssignee}>
                {
                    task.aUid &&
                    assignee &&
                         <FirestoreImage
                             key={'avatar-flash'}
                             alt={assignee.fullName}
                             src={assignee.avatar}
                             radius={16}
                             style={{}}
                             isAvatar={true}
                             backgroundColor={Colors.identifierAsColor(assignee.id)}
                         >
                             {`${assignee.firstName[0]}${assignee.lastName[0]}`}
                         </FirestoreImage>
                }
            </span>
            {
                showKey === task.id &&
                <MoinSimpleMenuContext options={ addContentOptions(task)}
                   anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
                   transformOrigin={{vertical: 'top', horizontal: 'center',}}
                   paperStyle={ {"width": '270px'}}
                   open={mouseState.mouseY !== null}
                   onClose={handleClose}
                   anchorReference="anchorPosition"
                   handleClose={handleClose}
                   anchorPosition={
                       mouseState.mouseY !== null && mouseState.mouseX !== null
                           ? { top: mouseState.mouseY, left: mouseState.mouseX }
                           : undefined
                   }
                   className={classes.taskContextOptions}
                   listItemClasses={classes.taskOptionsItem}>
                </MoinSimpleMenuContext>
            }
        </div>
    }

    const callDisplayActivity = (taskActivity) => {
        taskActivity.showAvatar = true;
        return displayTaskActivity(taskActivity);
    }

    const displayTaskActivity = ({activity, task, showAvatar, taskInfo = false}) => {

        let assignee;
        if (activity.cUid) {
            assignee = users.find( (user) => user.id === activity.cUid)
        }

        return <div key={"task_activity_" + task.id + '_' + activity.type + '_' + activity.cAt.getTime()} className={classes.taskActivity} onClick={() => onTaskClick(task)}>
            {
                !taskInfo &&
                <span className={classes.activityTaskIcon}>
                    <span className={classes.taskIconContainer}>
                        {
                            task.getCompletedStatusForDate(new Date())
                                ? <span className={classes.iconContainerActivity} style={{backgroundColor: Colors.ACTIVEGREEN}}>
                                    <CheckIcon fill={Colors.WHITE}/>
                                </span>
                                : <CheckOutlineIcon className={classes.activityIcon} fill={Colors.BLACKLIGHTEST}/>
                        }
                    </span>
                    <Typography className={classes.activityTask} variant="caption">{task.title}</Typography>
                </span>
            }
            <div className={classes.activityAssigneeMessage}>
                <span className={classes.activityTaskAssignee}>
                    {
                        taskInfo && activity.type === TaskActivityType.FINISHED &&
                        <span>
                            {
                                task.getCompletedStatusForDate(new Date())
                                    ? <span className={classes.iconContainerActivity}
                                        style={{backgroundColor: Colors.ACTIVEGREEN, width: '26px', height: '26px'}}>
                                        <CheckIcon style={{width: '20px', height: '20px'}} fill={Colors.WHITE}/>
                                    </span>
                                    : <CheckOutlineIcon style={{width: '32px', height: '32px'}} fill={Colors.BLACKLIGHTEST}/>
                            }
                        </span>
                    }
                    {
                        (!taskInfo || (taskInfo && activity.type !== 'TaskActivityType.FINISHED')) &&
                        showAvatar &&
                        assignee && <FirestoreImage
                            key={'avatar-flash'}
                            alt={assignee.fullName}
                            src={assignee.avatar}
                            radius={16}
                            style={{}}
                            isAvatar={true}
                            backgroundColor={Colors.identifierAsColor(assignee.id)}
                        >
                            {`${assignee.firstName[0]}${assignee.lastName[0]}`}
                        </FirestoreImage>
                    }
                </span>
                <span className={classes.activityMessage}>
                    <span className={classes.message}>{displayActivityMessage(activity)}</span>
                    <Typography  variant={"h6"} className={classes.taskDueDate}>
                        {displayDateActivity(activity.cAt)}
                    </Typography>
                </span>
            </div>
        </div>
    }

    const onTaskClick=(task) => {
        if (showKey !== null) {
            return
        }
        setDisplayInfoTask(task);
        setRoutingUrl(task.id);
    }

    const displayTaskOptions = () => {
        let taskCount = getTaskCount();

        return <div>
            <div onClick={todayView}
                 className={classes.optionWrapper}
                 style={view === TaskViewType.TODAY ? {backgroundColor: Colors.BLUELIGHT} : undefined}>
                {displayDateIcon()}
                <Typography variant="h1">{taskCount.todayCount}</Typography>
                <Typography variant="body2">{ t('common.today') }</Typography>
            </div>

            <div onClick={plannedView}
                 className={classes.optionWrapper}
                 style={view === TaskViewType.PLANNED ? {backgroundColor: Colors.BLUELIGHT} : undefined}>
                <CalendarIcon fill={Colors.BLACK}/>
                <Typography variant="h1">{taskCount.plannedCount}</Typography>
                <Typography variant="body2">{ t('tasks.planned') }</Typography>
            </div>

            <div onClick={myTasksView}
                 className={classes.optionWrapper}
                 style={view === TaskViewType.MYTASKS ? {backgroundColor: Colors.BLUELIGHT} : undefined}>
                <CheckOutlineIcon fill={Colors.BLACK}/>
                <Typography variant="h1">{taskCount.myTasksCount}</Typography>
                <Typography variant="body2">{ t('tasks.myTasks') }</Typography>
            </div>

            <div onClick={assignedToOthersView}
                 className={classes.optionWrapper}
                 style={view === TaskViewType.ASSIGNEDTOOTHERS ? {backgroundColor: Colors.BLUELIGHT} : undefined}>
                <UserSharedIcon fill={Colors.BLACK}/>
                <Typography variant="h1">{taskCount.assignedToOthers}</Typography>
                <Typography variant="body2">{ t('tasks.assignedOthers') }</Typography>
            </div>

            <div onClick={colleagueListView}
                 className={classes.optionWrapper}
                 style={view === TaskViewType.COLLEAGUELIST ? {backgroundColor: Colors.BLUELIGHT} : undefined}>
                <CheckclistIcon fill={Colors.BLACK}/>
                <Typography variant="body2">{ t('tasks.listColleagues') }</Typography>
            </div>
        </div>
    }

    const displaySort = () => {
        return <Typography className={classes.taskSort} variant={"caption"}>
            {t('tasks.sortedBy')}
            <span className={classes.taskListSort}>
                <MoinSimpleMenu
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                    transformOrigin={{vertical: 'top', horizontal: 'left',}}
                    listItemClasses={classes.taskOptionsItem}
                    paperStyle={{"width": '270px'}}
                    options={sortOptions()}>
                    <Typography variant={"h5"}>
                        {
                            sortField.current === TaskSortField.NAME ? t('tasks.name') : t('tasks.dueDate')
                        }
                    </Typography>
                </MoinSimpleMenu>
                {
                    sortField.current === TaskSortField.NAME &&
                    <span className={classes.sortIcon} onClick={(_) => onChangeSortDirection()}>
                        {
                            sortDirection.current === SortDirections.ASCENDING
                                ? <ArrowUpIcon/>
                                : <ArrowDownIcon/>
                        }
                    </span>
                }

            </span>
        </Typography>
    }

    const displayShowCompletedTrigger = () => {
        return <Typography onClick={triggerShowCompletedChange} className={classes.showCompleted} variant={"caption"}>
            {
                showCompletedTasks
                    ? t('tasks.hideCompleted')
                    : t('tasks.showCompleted')
            }
        </Typography>
    }

    const sortTasks = (tasks) => {
        let sortedTasks = tasks;

        if (sortField.current === TaskSortField.NAME) {
            const factor = sortDirection.current === SortDirections.ASCENDING ? 1 : -1;

            sortedTasks.sort(function(a, b) {
                let res = 0;

                if (a.completed && !b.completed) {
                    return 1;
                } else if (b.completed && !a.completed) {
                    return -1;
                }

                if (a.title.toLowerCase() < b.title.toLowerCase()) { res = -1; }
                else if (a.title.toLowerCase() > b.title.toLowerCase()) { res = 1; }
                return factor * res;
            });
        } else {
            sortedTasks.sort(function(a, b) {

                let aCompleted =  a.completed;
                let aUAt =  a.uAt;
                let aDueDate =  a.dueDate;

                if (recentlyCompletedItems.current.hasOwnProperty(a.id)) {
                    aCompleted = false;
                    aUAt = recentlyCompletedItems.current[a.id];
                }

                let bCompleted =  b.completed;
                let bUAt =  b.uAt;
                let bDueDate =  b.dueDate;

                if (recentlyCompletedItems.current.hasOwnProperty(b.id)) {
                    bCompleted = false;
                    bUAt = recentlyCompletedItems.current[b.id];
                }

                if (aCompleted && !bCompleted) {
                    return 1;
                } else if (bCompleted && !aCompleted) {
                    return -1;
                }

                let res = 0
                if ((!aDueDate && !bDueDate) || (aCompleted && bCompleted))  {
                    if (!aUAt) {
                        return -1;
                    }
                    if (!bUAt) {
                        return 1;
                    }
                    if (aUAt.getTime() < bUAt.getTime()) {
                        res = 1;
                    }
                    else {
                        res = -1;
                    }
                }
                else if (!aDueDate) {res = 1;}
                else if (!bDueDate) {res = -1;}
                else if (aDueDate.getTime() < bDueDate.getTime()) { res = -1; }
                else if (aDueDate.getTime() > bDueDate.getTime()) { res = 1; }
                else if (aDueDate.getTime() === bDueDate.getTime()) {
                    if (!aUAt) {
                        return -1;
                    }
                    if (!bUAt) {
                        return 1;
                    }
                    if (aUAt.getTime() < bUAt.getTime()) {
                        res = 1;
                    }
                    else {
                        res = -1;
                    }
                }
                return res;
            });
        }
        return sortedTasks;
    }

    const renderInbox = () => {
        let inbox = [];
        let total = 0;
        Object.keys(taskActivities).forEach( (dateKey) => {
            if ( taskActivities[dateKey].length > 0 && total < activityAmount.current ) {
                let inboxElements = [];
                taskActivities[dateKey].forEach( (taskActivity)  => {
                    if (total < activityAmount.current) {
                        inboxElements.push(taskActivity);
                        total++;
                    }
                });
                inbox.push(
                    <div className={classes.InboxActivityList} key={'inbox-'+dateKey} ref={(node) => scrollAnchor.current = node}>
                        {
                            dateKey === TaskActivityDateSection.TODAY
                                ?   <Typography className={classes.inboxTimePeriod} variant="h6">{ t('common.today') }</Typography>
                                :   dateKey === TaskActivityDateSection.YESTERDAY
                                ? <Typography className={classes.inboxTimePeriod} variant="h6">{ t('common.yesterday') }</Typography>
                                : dateKey === TaskActivityDateSection.LASTWEEK
                                    ? <Typography className={classes.inboxTimePeriod} variant="h6">{ t('common.lastWeek') }</Typography>
                                    : <Typography className={classes.inboxTimePeriod} variant="h6">{ t('common.older') }</Typography>
                        }
                        {
                            inboxElements.map( (taskActivity) => callDisplayActivity(taskActivity))
                        }
                    </div>
                )
            }
         });
        return inbox;
    }

    const renderTaskView = () => {
        let displayedTask = [...tasks.filter( (task) => !task.dAt)];

        if (searchedTasks) {
            for (let i= 0; i < searchedTasks.length; i++) {
                let loadedTask = tasks.find( (_) => _.id === searchedTasks[i].id);
                if (loadedTask) {
                    searchedTasks[i] = Object.assign(new Task(), loadedTask);
                }
            }
            searchedTasks = searchedTasks.filter( (task) => !task.dAt);
        }

        if (view === TaskViewType.HOME) {
            let hasAnyTask = false;
            Object.keys(taskActivities).forEach( (dateKey) => {
                if ( taskActivities[dateKey].length > 0  ) {
                    hasAnyTask = true;
                }
            })
            return <div className={classes.taskListWrap}>
                <div className={classes.taskOptions}>
                    {displayTaskOptions()}
                </div>
                {
                    hasAnyTask &&
                    <Typography className={classes.taskInbox} variant="h4">{t('tasks.inbox')}</Typography>
                }
                {renderInbox()}
            </div>;
        } else if (view === TaskViewType.COLLEAGUELIST) {
            if (selectedColleague) {
                return <ColleagueTasksContainer
                    displaySort={displaySort}
                    sortTasks={sortTasks}
                    displayTask={displayTask}
                    sortField={sortField.current}
                    sortDirection={sortDirection.current}
                    selectedColleague={selectedColleague}
                />;
            }
            return <div className={classes.taskListWrap}>
                <div className={classes.colleagueListContainer}>
                <MoinSearchField
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                    placeholder={t('tasks.browseColleagues')}
                    style={{width: '100%', margin: '0'}}
                    onClear={ () => setSearch('')}
                />

                <SearchableSelectableUserList
                    search={search}
                    onSelect={(user) => onUserSelection(user)}
                    useDepartments={false}
                    taskColleagueList={true}
                    showCheckbox={false}/>
                </div>
            </div>;
        } else if (view === TaskViewType.SEARCH) {
            return <div className={classes.taskListWrap}>
                <div className={classes.taskListHeader}></div>
                <div>
                    {
                        searchedTasks.map( (task) =>
                            displayTask(task)
                        )
                    }
                </div>
            </div>;
        } else {
            let filterCheck;
            switch (view) {
                case TaskViewType.TODAY:
                    filterCheck = isTaskDueToday;
                    break;
                case TaskViewType.PLANNED:
                    filterCheck = isTaskPlanned;
                    break;
                case TaskViewType.MYTASKS:
                    filterCheck = isTaskMyTask;
                    break;
                case TaskViewType.ASSIGNEDTOOTHERS:
                    filterCheck = isTaskAssignedToOthers;
                    break;
                default:
                    break;
            }

            if (filterCheck) {
                let sortedTask = sortTasks(displayedTask.filter(task => filterCheck(task)));

                if (sortedTask.length === 0) {
                    return <TaskEmptyState
                        view={view}
                        selectedUser={selectedColleague}
                        showCompletedTrigger={displayShowCompletedTrigger}
                        setShowAddTask={setShowAddTask}
                        myTasksView={myTasksView}/>;
                }

                return <div className={classes.taskListWrap}>
                    <div className={classes.taskListHeader}>
                        {displaySort()}
                        {displayShowCompletedTrigger()}
                    </div>
                    <div>
                        {
                            sortedTask.map( (task) =>
                                displayTask(task)
                            )
                        }
                    </div>
                </div>;
            }
        }
    }

    return (
        <Fragment>
            <div className={classes.taskWrap}>
                <div className={clsx(classes.sideSpacer, {
                    [classes.minWidthAnimationLeft]: view !== TaskViewType.HOME,
                })}/>
                {
                    renderTaskView()
                }
                <div className={clsx(classes.sideSpacerContainer, {
                    [classes.minWidthAnimation]: !!displayInfoTask,
                })}>
                    {
                        displayInfoTask &&
                        <TaskInfo taskId={displayInfoTask?.id || displayInfoTask}
                                  displayTaskActivity={displayTaskActivity}
                                  taskActivities={taskActivities}
                                  deleteTask={() => deleteTask(displayInfoTask)}
                                  markComplete={onComplete}
                                  onTitleChange={onTitleChange}
                                  onDescriptionChange={onDescriptionChange}
                                  onRepetitionChange={onRepetitionChange}
                                  onAddDocuments={onAddDocuments}
                                  onRemoveDocument={onRemoveDocument}
                                  onAddImages={onAddImages}
                                  onRemoveImage={onRemoveImage}
                                  onAddNotification={onAddNotification}
                                  onRemoveNotification={onRemoveNotification}
                                  setDueDate={setDueDate}
                                  onAssign={onAssign}
                                  removeAssignee={removeAssignee}
                                  onClose={() => setDisplayInfoTask(undefined)}
                                  rebuildList={() => setRebuild((rebuild) => !rebuild)}
                                  users={users}
                        />
                    }
                </div>
            </div>

            <div className={classes.fabContainer}>
                <div style={{width: '240px'}}/>
                <div className={clsx(classes.sideSpacer, {
                    [classes.minWidthAnimationLeft]: view !== TaskViewType.HOME,
                })}/>
                <div className={classes.fabSizing}>
                    {
                        showSnackBarDeleted &&

                        <div className={classes.taskSnackbarContainer}>
                            <div className={`${classes.taskSnackbarContent} ${classes.taskSnackbarContentDelete}`}
                                 style={{backgroundColor: Colors.ERROR}}>
                                <Typography className={classes.snackbarText} variant={"caption"}>{ t('tasks.taskDeleted') }</Typography>
                                <Typography className={classes.snackbarButton} onClick={undoDelete} variant={"h5"}>{ t('tasks.undo') }</Typography>
                            </div>
                        </div>
                    }

                    {
                        showSnackBarCreated &&

                        <div className={classes.taskSnackbarContainer}>
                            <div className={classes.taskSnackbarContent}
                                 style={{backgroundColor: Colors.ACTIVEGREEN}}>
                                <Typography className={classes.snackbarTextTitle} variant={"h5"}>{createdTask.title}</Typography>
                                <Typography className={classes.snackbarText} variant={"caption"}>{ t('tasks.created') }</Typography>
                                <ChevronRightIcon
                                    fill={Colors.WHITE}
                                    className={classes.snackbarButton}
                                    onClick={() => {viewCreatedTask()}}
                                />
                            </div>
                        </div>
                    }

                    <MoinFab onClick={() => setShowAddTask(true)} contained={true}/>
                </div>

                <div className={clsx(classes.sideSpacer, {
                    [classes.minWidthAnimation]: !!displayInfoTask,
                })}
                />
            </div>

            {
                view && view !== TaskViewType.HOME &&
                <Drawer className={classes.taskDrawer}
                        variant="persistent"
                        anchor="left"
                        open={true}>
                    <Typography className={classes.drawerHeader} onClick={selectedColleague ? onColleagueListBack : defaultView} variant={"body2"}>
                        <ArrowLeftIcon fill={Colors.BRANDSECONDARY}/>{ t('common.back') }
                    </Typography>
                    <div className={classes.taskOptionsDrawer}>
                        {displayTaskOptions()}
                    </div>
                </Drawer>
            }

            {
                showAddTask &&
                <AddTasksDialog onCreate={createTask} onClose={() => setShowAddTask(false)}/>
            }

        </Fragment>
    );
};

const mapState = (state, ownProps) => ({
    users: getModelInstances(state, User)
});

export default connect(mapState)(Tasks);
