import {Colors} from "../helper/ColorHelper";
import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {timestampToDateTasks} from "../../utils/helper/TextHelper";

const useStyles = makeStyles((theme) => ({
    taskDueDate: {
        verticalAlign: 'top'
    },
    taskDueDateGreen: {
        verticalAlign: 'top',
        color: Colors.ACTIVEGREEN
    },
    taskDueDateRed: {
        verticalAlign: 'top',
        color: Colors.ERROR
    },
}));

/**
 *
 * @constructor
 */
const TaskDate = ({task, completed = false}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    let date = task.dueDate;

    if (typeof date === 'number') {
        date = new Date(date);
    }

    if(task.repetition) {
        if(task.isRepeatingAtDay(new Date())) {
            date = new Date();
        } else {
            date = task.getNextRepetition() ?? task.dueDate;
        }
    }

    date.setHours(0, 0, 0, 0);

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let yesterday = new Date();
    yesterday.setHours(0, 0, 0, 0);
    yesterday.setDate(yesterday.getDate() - 1);

    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (today.getTime() === date.getTime()) {
        return <Typography  variant={"caption"}
                            style={completed ? {color: Colors.BLACKLIGHTER} : {}}
                            className={classes.taskDueDateGreen}>
            { t('common.today') }
        </Typography>
    } else if (yesterday.getTime() === date.getTime()) {
        return <Typography  variant={"caption"}
                            style={completed ? {color: Colors.BLACKLIGHTER} : {}}
                            className={classes.taskDueDateRed}>
            { t('common.yesterday') }
        </Typography>
    } else if (tomorrow.getTime() === date.getTime()) {
        return <Typography  variant={"caption"}
                            style={completed ? {color: Colors.BLACKLIGHTER} : {}}
                            className={classes.taskDueDateGreen}>
            { t('tasks.tomorrow') }
        </Typography>
    } else if (today.getTime() >= date.getTime()) {
        return <Typography  variant={"caption"}
                            style={completed ? {color: Colors.BLACKLIGHTER} : {}}
                            className={classes.taskDueDateRed}>
            {timestampToDateTasks(date)}
        </Typography>
    }

    return <Typography  variant={"caption"}
                        style={completed ? {color: Colors.BLACKLIGHTER} : {}}
                        className={classes.taskDueDate}>
        {timestampToDateTasks(date)}
    </Typography>
}

export default TaskDate;
