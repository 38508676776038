import ModelConverter from "../converter/ModelConverter";
import ModelRepository from "../manager/ModelRepository";
import AUTHSTORAGE from "../../auth/AuthStorage";
import ModelManager from "../ModelManager";
import {firebase, FireStore} from "../../../api/FirebaseService";

/**
 * model decorator
 *
 * with the model decorator is designed as a class decorator.
 * you can register your custom data classes to the ModelManager
 * to store the model data in firestore
 *
 * @param resource firestore collection (defaults lowercased name of applied class)
 * @param converter firestore data converter
 * @param repository model repository
 * @param key unique key (defaults lowercased name of applied class)
 * @param manager
 * @return {function(...[*]=)}
 */
const model = (resource, {converter = null, repository = null, key = null, manager=ModelManager} = {}) => (target) => {

    // apply default fields to meta data object
    target.metadata = Object.assign({
        "idProp": 'id',
        "idGenerator": null,
        "props" : [],
        "refs" : {}
    }, target.metadata);

    // set firestore data default resource path
    target.resource = resource;

    // set the firestore data converter
    target.converter = converter
        ? converter
        : ModelConverter.create(target);

    // set the repository
    target.repository = repository
        ? new repository(ModelManager, target)
        : new ModelRepository(ModelManager, target);

    // set unique key
    target.key = key
        ? key
        : target.name.toLowerCase();


    /**
     * get the id of given model instance
     * @param modelInstance
     * @return {*}
     */
    target.id = (modelInstance) => {
        return modelInstance[target.metadata.idProp];
    };

    /**
     * resolve the document path from given instance
     * @param modelInstance
     * @return {string}
     */
    target.path = (modelInstance) => {
        return (target.resource || "").replace(":client", AUTHSTORAGE.getClientId()).replace(':user', AUTHSTORAGE.getUserId()) +'/'+modelInstance[target.metadata.idProp];
    };

    target.collection = () => (target.resource || "").replace(":client", AUTHSTORAGE.getClientId()).replace(':user', AUTHSTORAGE.getUserId());

    /**
     * create a firebase document reference from a given path
     * @param path
     * @return {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>}
     */
    target.createReference = (id) => {
        return FireStore.collection(target.resource).doc(id);
    };

    /**
     * factory method
     *
     * create model instance from given data
     *
     * @param data
     * @param originalInstance
     * @param resource
     * @return {*}
     */
    target.from = (data = {}, originalInstance = null, resource = null) => {

        let instance;
        if(originalInstance)
        {
            instance = originalInstance;
        } else {
            instance = new target();
            instance.__resource = resource ? resource : target.resource;
        }

        const {props} = target.metadata;

        Object
            .keys(props)
            .forEach(prop => {

                let value = data[prop];

                if (value && value instanceof firebase.firestore.Timestamp) {
                    value =  value.toDate();
                }

                if (typeof value !== "undefined")
                    instance[prop] = value;
        });

        return instance;
    };


    Object.defineProperty(target.prototype, "__resource", {
        value: resource,
        enumerable: false,
        configurable: false,
        writable: true
    });


    // register target to model manager
    manager._register(target);
};

export default model;
