import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import illustration from "../../assets/illustrations/moin-app-illus-no-content-muted.png";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '64px',
        marginBottom: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '768px',
        flex: '100',
    },
    illustration: {
        maxWidth: '45%',
        height: 'auto'
    },
    title: {
        marginBottom: '12px'
    },
    text: {
        textAlign: 'center'
    },
}));

const GroupEmptyState = ({isMember=false}) => {
    const [t, i18n] = useTranslation();

    const classes = useStyles();

    let title;
    let description

    if (isMember) {
        title = t('distributor.createFirstArticle')
        description = t('distributor.createFirstArticleDescription')
    } else {
        title = t('distributor.noArticlesInGroup')
        description = t('distributor.noArticlesInGroupDescription')
    }

    return (
            <div className={classes.paper}>
                <img src={illustration} alt={t('common.illustration')} className={classes.illustration}/>
                <Typography variant="h3" className={classes.title}>{title}</Typography>
                <Typography variant="body2" className={classes.text}>{description}</Typography>
            </div>
    );
};
export default GroupEmptyState;
