import React, {useRef, useState} from "react";
import {getModelInstance} from "../../utils/store/selectors";
import {connect} from "react-redux";
import Distributor from "../../utils/models/Distributor";
import ArticleTeaser from "./ArticleTeaser";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {addModelInstanceDispatch, deleteModelInstanceDispatch, updateModelInstanceDispatch} from "../../utils/store/actions";
import User from "../../utils/models/User";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import {ContentType} from "../../utils/enums/ContentType";
import {FireDelete, FireTimestamp} from "../../api/FirebaseService";
import HIGHLIGHTEDARTICLES from "../../utils/articles/HighlightedArticles";
import AuthStorage from "../../utils/auth/AuthStorage";
import Article from "../../utils/models/Article";
import FAVORITEDARTICLES from "../../utils/articles/FavoritedArticles";

/**
 * @param {Article} article
 * @param {Distributor} group
 * @param {ArticleReaction} authReaction
 * @param authUser
 * @param deleteModelInstanceDispatch
 * @param addModelInstanceDispatch
 * @param updateModelInstanceDispatch
 * @return {JSX.Element}
 * @constructor
 */
const ArticleTeaserContainer = ({article, group, authReaction, authUser, deleteModelInstanceDispatch, addModelInstanceDispatch, updateModelInstanceDispatch}) => {
    const [runningQueue, setRunningQueue] =  useState(false);
    const [queue, setQueue] =  useState([]);
    const deleting = useRef(false);

    if(article && article.dAt) {
        return <></>;
    }

    const runAsQueue = async () => {
        if (!runningQueue) {
            setRunningQueue(true);
            try {
                while (queue.length > 0) {
                    let operation = queue[0];
                    await operation();
                    queue.shift();
                    setQueue(queue);
                }
                setRunningQueue(false);
            } catch (ex) {
                setRunningQueue(false);
            }
        }
    };

    const toggleArticleHighlight = async () => {
        let oldHighlighted = HIGHLIGHTEDARTICLES.isHighlighted(article.id);

        let highlightData = {};

        if (!HIGHLIGHTEDARTICLES.canHighlightMoreArticles() && !oldHighlighted) {
            let oldest = HIGHLIGHTEDARTICLES.getOldestHighlightedArticle()
            HIGHLIGHTEDARTICLES.toggleArticleHighlighted(oldest);
            highlightData[oldest] = FireDelete();
        }

        HIGHLIGHTEDARTICLES.toggleArticleHighlighted(article.id);

        if(!oldHighlighted) {
            highlightData[article.id] = FireTimestamp();
        } else {
            highlightData[article.id] = FireDelete();
        }

        ModelManager.update({useSet: true, stopEvents: true, resource: "/client/" + AuthStorage.getClientId() + "/highlightedArticles/", id: '_all', customData: highlightData});
        updateModelInstanceDispatch(Article, article);
    };

    const toggleArticleFavorited = async () => {
        let oldFavorited = FAVORITEDARTICLES.isFavorited(article.id);

        let favoriteData = {};

        FAVORITEDARTICLES.toggleArticleFavorited(article.id);

        if(!oldFavorited) {
            favoriteData[article.id] = FireTimestamp();
        } else {
            favoriteData[article.id] = FireDelete();
        }

        ModelManager.update({useSet: true, stopEvents: true, resource: `/client/${AuthStorage.getClientId()}/user/${AuthStorage.getUserId()}/article_favorites/`, id: '_all', customData: favoriteData});
        updateModelInstanceDispatch(Article, article);
    };

    const deleteArticle = async () => {
        if(!deleting.current) {
            deleting.current = true;
            const firebaseStorageManager = new FirebaseStorageManager();
            let deleteFutures = [];

            if (HIGHLIGHTEDARTICLES.isHighlighted(article.id)) {
                HIGHLIGHTEDARTICLES.toggleArticleHighlighted(article.id);
                let highlightData = {};
                highlightData[article.id] = FireDelete();
                ModelManager.update({useSet: true, stopEvents: true, resource: "/client/" + AuthStorage.getClientId() + "/highlightedArticles/", id: '_all', customData: highlightData});
            }

            article.content.forEach(content => {
                try {
                    if(content.type === ContentType.PDF && !!content.path) {
                        deleteFutures.push(firebaseStorageManager.deleteFile(content.path.replace(/%2F/g, '/')));
                    } else if(content.type === ContentType.MEDIA) {
                        if(!!content.image) {
                            deleteFutures.push(firebaseStorageManager.deleteFile(content.image.replace(/%2F/g, '/')));
                        } else if(!!content.video) {
                            deleteFutures.push(firebaseStorageManager.deleteFile(content.video.url.replace(/%2F/g, '/')));
                            deleteFutures.push(firebaseStorageManager.deleteFile(content.video.thumbnail.replace(/%2F/g, '/')));
                        }
                    }
                } catch (ex) {
                    //if we have a faulty file it might not be possible to delete it, but we should delete the article anyways
                }
            });

            let customArticleDeleteData = {
                content: FireDelete(),
                title: FireDelete(),
                uAt: FireTimestamp(),
                dAt: FireTimestamp(),
                dBy: authUser.id,
                hTI: FireDelete(),
                newFormat: FireDelete(),
                dis: FireDelete()
            };

            article.uAt = new Date();
            article.dAt = new Date();
            article.dBy = authUser.id;
            article.content = undefined;
            article.title = undefined;
            article.hTI = undefined;
            article.newFormat = undefined;
            article.dis = undefined;

            await ModelManager.update({modelInstance: article, customData: customArticleDeleteData, resource: `/client/${AUTHSTORAGE.getClientId()}/article`});
            await Promise.all(deleteFutures);
            deleting.current = false;
            return true;
        }
        return false;
    };


    return (
        <ArticleTeaser
            article={article}
            group={group}
            deleteArticle={deleteArticle}
            toggleArticleHighlight={toggleArticleHighlight}
            toggleArticleFavorited={toggleArticleFavorited}
        />
    );
};

const mapState = (state, ownProps) => {
    let group;

    if(!!ownProps.article.dis && ownProps.article.dis.length > 0 ) {
        let id =  ownProps.article.dis[0];
        group = getModelInstance(state, Distributor, id);
    }
    return {
        group: group,
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
    };
};

const mapAction = {
    deleteModelInstanceDispatch,
    addModelInstanceDispatch,
    updateModelInstanceDispatch
};

export default connect(mapState, mapAction)(ArticleTeaserContainer);