export const DateFilterRange = {
    ANYTIME: "DateFilterRange.ANYTIME",
    TODAY: "DateFilterRange.TODAY",
    YESTERDAY: "DateFilterRange.YESTERDAY",
    LAST7DAYS: "DateFilterRange.LAST7DAYS",
    LAST30DAYS: "DateFilterRange.LAST30DAYS",
    LAST3MONTHS: "DateFilterRange.LAST3MONTHS",
    LAST6MONTHS: "DateFilterRange.LAST6MONTHS",
    LAST12MONTHS: "DateFilterRange.LAST12MONTHS"
};
