import React from "react";
import {useEffect, useState} from "react";
import {FireStorage} from "../../api/FirebaseService";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Colors} from "../helper/ColorHelper";
import BrokenFileIcon from "../../assets/icons/file-broken.svg";

const FireStorageVideo = ({src, className}) => {
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (src && src.length > 0) {
            const replacedSrc = src.replace(/%2F/g, '/');
            FireStorage.ref(replacedSrc).getDownloadURL()
                .then(setUrl);
        }
    }, [src]);

    if (!src) {
        return  <div style={{padding: "24px 0", display: "flex", justifyContent: "center"}}>
            <BrokenFileIcon fill={Colors.BLACKLIGHTER} width={48} height={48}/>
        </div>;
    }

    if(!url) {
        return <div className="moin-circular-progress-container">
            <CircularProgress />
        </div>;
    }

    return (
        <video controls className={className}>
            <source src={url} type="video/mp4"/>
        </video>
    );
};

export default FireStorageVideo;
