import React, {Fragment, useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import User from "../../utils/models/User";
import {TASKCLIENT} from "../../utils/tasks/TaskClient";

/**
 *
 * @param {Distributor} distributor
 * @param {Function} children
 * @returns {JSX.Element}
 * @constructor
 */
const TaskSearchContainer = ({query, children}) => {
    let hasMore = useRef(true);
    let lastRequest = useRef(0);
    let loading = useRef(true);
    const [rebuild, setRebuild] = useState(true);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        searchTasks({});
    }, []);

    /* if sortBy or direction is undefined, the result will be ordered by match score*/
    const searchTasks = async ({from =0, sortBy, direction}) => {
        let requestTime = Date.now()
        lastRequest.current = requestTime;

        if (hasMore.current) {
            loading.current=true;
            setRebuild((old) => !old);
            let fileData = await TASKCLIENT.searchTasks(query, 25, from, sortBy, direction);

            if (requestTime === lastRequest.current) {
                loading.current=false;
                hasMore.current = fileData?.length > 0;
                setTasks( [...tasks, ...fileData]);
            }
        }

    }

    return(
        <Fragment>
            {
                children({searchTasks, tasks})
            }
        </Fragment>
    );
};

const mapState = (state, ownProps) => ({
    authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId()),
})

export default connect(mapState)(TaskSearchContainer);