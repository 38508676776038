import GiveHugsOverview from "./GiveHugsOverview";
import React, {useRef} from "react";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import Chat from "../../utils/models/Chat";
import {connect} from "react-redux";
import ModelManager from "../../utils/manager/ModelManager";
import {addModelInstanceDispatch, updateModelInstance} from "../../utils/store/actions";
import {addChatMembersData, changeChatMembersAllData} from "../../utils/helper/ChatHelper";
import User from "../../utils/models/User";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import Message from "../../utils/models/Message";
import {hugTypeToMessageType} from "../../utils/helper/EnumHelper";
import * as ROUTES from "../../Routes";
import {useHistory, withRouter} from "react-router-dom";
import {createSymmetricKey} from "../../utils/helper/EncryptionHelper";
import {FireIncrement, FireTimestamp} from "../../api/FirebaseService";

const GiveHugsOverviewContainer = ({chats, authUser, addModelInstanceDispatch}) => {
    const sending = useRef(false);
    const history = useHistory();

    /**
     * @param {HugType} hugType
     * @param {string} text
     * @param {User[]} selectedUsers
     */
    const sendHugToUsers = async (hugType, text, selectedUsers) => {
        if(!sending.current) {
            sending.current = true;

            let users = Array.from(selectedUsers);
            if(!users.find(u => u.id === AUTHSTORAGE.getUserId())) {
                users.push(authUser);
            }

            const chosenUserIds = users.map(user => user.id);

            // Find existing chat
            let chat = chats.find((chatInstance) => {
                if (!chatInstance.archived && chatInstance.member.length === users.length) {
                    let userIds = chatInstance.member.map((user) => user.uid);
                    return chosenUserIds.every((uid) => userIds.includes(uid)) && userIds.every((uid) => chosenUserIds.includes(uid));
                }
                return false;
            });

            // Create new chat if none exist
            if(!chat) {
                let memberCount = users.length;
                let chatName = "";

                if(memberCount === 2) {
                    memberCount = 1;
                }

                users.slice(0, 5 <= users.length ? 5 : users.length).forEach(user => {
                    if(!chatName) {
                        chatName += user.fullName;
                    } else {
                        chatName += ", " + user.fullName;
                    }
                });

                chat = Chat.from(
                    {
                        "name": chatName,
                        "cUid": AUTHSTORAGE.getUserId(),
                        "count": memberCount,
                        "uMC": 0
                    }
                );

                chat.member = users.map((user) => user);
                chat.sample = users.slice(0, 5 <= users.length ? 5 : users.length).map( (user) => user.getAsMinimalUser());
                chat.symKey = createSymmetricKey({length: 32});
                chat.isTemp = true;
            }

            if (chat.isTemp) {
                await ModelManager.add({modelInstance: chat, stopEvents: true});
                chat.isTemp = false;
                updateModelInstance(Chat, chat);
                await changeChatMembersAllData(chat);
                await addChatMembersData(chat, authUser);
            }

            const message = new Message();
            message.chatId = chat.id;
            message.cName = authUser.fullName;
            message.cUid = AUTHSTORAGE.getUserId();
            message.text = text;
            message.type = hugTypeToMessageType(hugType);

            // Clone Message and add unencrypted Message to cache
            let unencryptedMessage = Object.assign(new Message(), message);
            unencryptedMessage.cAt = new Date();

            addModelInstanceDispatch(Message, unencryptedMessage);

            let encryptedMessage = chat.encryptMessage(message);

            await ModelManager.add({modelInstance: encryptedMessage, resource: `/client/${AUTHSTORAGE.getClientId()}/chat/${chat.id}/message`, extra: {chat: chat, isNew: true, unencryptedMessage: unencryptedMessage}});

            // Link message to chat members
            let messageLinkFutures = [];

            const chatLinkData = {
                'cAt': FireTimestamp()
            };

            const unreadMessageData = {
                'uMC': FireIncrement(1)
            };

            chat.member.forEach(user => {
                const userChatRef = `/client/${AUTHSTORAGE.getClientId()}/user/${user.uid}/chat`;
                ModelManager.update({customData: chatLinkData, resource: userChatRef + `/${chat.id}/message`, id: encryptedMessage.id, useSet: true});
                messageLinkFutures.push(ModelManager.update({customData: unreadMessageData, resource: userChatRef, id: chat.id, useSet: true}));
            });

            await Promise.all(messageLinkFutures);

            sending.current = false;
            history.push(ROUTES.messagesShow.replace(':id', chat.id))
        }
    };

    return <GiveHugsOverview sendHugToUsers={sendHugToUsers}/>;
};

const mapState = (state) => ({
    chats: getModelInstances(state, Chat),
    authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
});

const mapAction = {
    addModelInstanceDispatch
};

export default connect(mapState, mapAction)(withRouter(GiveHugsOverviewContainer));