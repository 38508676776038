import React, {useEffect, useRef} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import MoinTextField from "./MoinTextField";
import {firebase} from "../../api/FirebaseService";

const autocompleteService = { current: null };
const geoCodeService = { current: null };

const GoogleMapsAutoComplete = ({title, placeholder, location, error, onChange, className}) => {

    const valueRef = useRef();
    const [value, setValue] = React.useState(location);
    const [options, setOptions] = React.useState([]);

    const makeRequest = (currentValue) => {
        setTimeout(() =>{
            if (!!autocompleteService && !!autocompleteService.current && currentValue === valueRef.current) {
                autocompleteService.current.getPlacePredictions({ input: value }, (results) => {
                    let newOptions = [];
                    if (results) {
                        newOptions = [...results];
                    }

                    setOptions(newOptions);
                });
            }
        }
      , 100);

    }

    const requestPlaceLocation= (newValue) => {
        if (newValue  && typeof newValue !== 'string' && newValue.place_id) {
            if (!!geoCodeService.current ) {
                geoCodeService.current.geocode({ placeId: newValue.place_id }, (results) => {

                    if (!!results && !!results[0] && !!results[0].address_components) {
                        let addressComponents = results[0].address_components;

                        let city;
                        let postalCode;
                        let street;
                        let streetNumber;
                        let country;

                        addressComponents.forEach((comp) => {
                            if (comp.types.includes("locality")) {
                                city = comp.long_name;
                            }
                            if (comp.types.includes("postal_code")) {
                                postalCode = comp.long_name;
                            }
                            if (comp.types.includes("country")) {
                                country = comp.long_name;
                            }
                            if  (comp.types.includes("route")) {
                                street = comp.long_name;
                            }
                            if (comp.types.includes("street_number")) {
                                streetNumber = comp.long_name;
                            }
                        });


                        let locationText;
                        if(!!street) {
                            locationText = street;
                            if(!!streetNumber) {
                                locationText += ' '+ streetNumber;
                            }
                        }

                        if (!!postalCode) {
                            if(!street) {
                                locationText = postalCode;
                            }
                            else {
                                locationText += ', ' + postalCode;
                            }

                        }

                        if(!!city) {
                            if(!street && !postalCode) {
                                locationText = city;
                            } else if (!!postalCode) {
                                locationText += ', ' + city;
                            }
                            else {
                                locationText += ' ' + city;
                            }
                        }

                        if(!!country) {
                            if(!!street  || !!city ) {
                                locationText += ' (' + country + ')';
                            }
                        }

                        if (results[0].geometry && results[0].geometry.location) {
                            let location =  results[0].geometry.location;
                            let geopoint = new firebase.firestore.GeoPoint(location.lat() , location.lng() )
                            onChange({ location: locationText, geopoint: geopoint});
                        } else {
                            onChange({ location: locationText});
                        }
                    }
                });
            }
        } else {
            onChange({ location: newValue});
        }
    }

    const onInputChange= (value ) => {
        setValue(value);
        valueRef.current = value;
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!geoCodeService.current && window.google) {
            geoCodeService.current = new window.google.maps.Geocoder();
        }
        makeRequest(value);
        onChange({ location: value});
    }

    return (
        <Autocomplete
            id="gogole-autocomplete"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            size="small"
            variant="outlined"
            margin="normal"
            filterSelectedOptions
            className={className}
            value={value}
            onChange={(event, newValue) => {
                if (!!newValue)
                    setValue(  typeof newValue === 'string' ? newValue :newValue.description);
                else
                    setValue(undefined);
                requestPlaceLocation(newValue);
            }}

            renderInput={(params) => (
                <MoinTextField
                    fullWidth
                    title={title}
                    placeholder={placeholder}
                    error={error}
                    value={value}
                    onChange={(event) => {
                        onInputChange(event.target.value);
                    }}
                    {...params}
                />
            )}
            renderOption={(option) =>
                <div >
                    <span key={option}>{option.structured_formatting.main_text} </span>

                    <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                    </Typography>
                </div>
            }
        />
    );
}

export default GoogleMapsAutoComplete;