import React, {useEffect, useState} from "react";
import ModelManager from "../../utils/manager/ModelManager";
import {MarkdownTarget} from "./MarkdownTarget";
import MarkdownView from "./MarkdownView";
import {FireStorage} from "../../api/FirebaseService";

const MarkdownViewContainer = ({target}) => {

    const [createdAt, setCreatedAt] =  useState();
    const [content, setContent] =  useState();

    useEffect(() => {
        setCreatedAt();
        setContent();
        let resource;
        switch (target) {
            case MarkdownTarget.PRIVACY:
                resource = "privacies";
                break;
            case MarkdownTarget.TERMS:
                resource = "terms";
                break;
            case MarkdownTarget.IMPRESS:
                resource = "imprint";
                break;
            default:
                break;
        }

        if (resource) {
            ModelManager.list({resource: resource, orderBy: 'createdAt', orderDirection: 'desc',limit: 1})
                .then( (data) => {

                    if (data && Object.keys(data).length > 0) {
                        let doc = data[Object.keys(data)[0]];

                        setCreatedAt(doc.createdAt.toDate());

                        let xmlHttp = new XMLHttpRequest();
                        xmlHttp.onreadystatechange = function() {

                            if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
                            {
                                setContent(new String(xmlHttp.responseText));
                            }

                        }
                        xmlHttp.open( "GET", doc.url );
                        xmlHttp.send( null );

                    }
                });
        }

    }, [target]);
    return <MarkdownView target={target} createdAt={createdAt} content={content}/>;
}

export default  MarkdownViewContainer;