import React, {Fragment, useState} from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

/**
 *
 * @param {Array} allImages
 * @param {number} startIndex
 * @param {boolean} openLightbox
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const LightboxWrapper = ({images = [],startIndex = 0, openLightbox = false, children}) => {

    const [index, setIndex] = useState(!!startIndex  ? startIndex : 0 );
    const [hasNext, setHasNext] = useState(startIndex < (images.length-1) ? "true": null );
    const [hasPrev, setHasPrev] = useState(startIndex > 0 ? "true": null );
    const [lightBoxOpen, setLightBoxOpen] = useState(openLightbox);

    const changeImage = (changeBy) => {
        let newIndex = index +changeBy;

        if (newIndex < 0) {
            newIndex = images.length -1;
        } else if (newIndex > images.length -1) {
            newIndex = 0;
        }

        setIndex(newIndex);
        setHasNext(newIndex < (images.length-1) ? "true": null);
        setHasPrev(newIndex >0  ? "true": null);
    };

    return  <Fragment>
        { lightBoxOpen &&
            <Lightbox
                mainSrc={images[index]}
                nextSrc={hasNext}
                prevSrc={hasPrev}
                onMovePrevRequest={() =>
                    changeImage(-1)
                }
                onMoveNextRequest={() =>
                    changeImage(1)
                }
                onCloseRequest={() => setLightBoxOpen(false)}
                reactModalStyle={{overlay: {zIndex: 9999}}}
            >
            </Lightbox>
        }
        {
            children({setLightBoxOpen})
        }
    </Fragment>
};

export default LightboxWrapper;
