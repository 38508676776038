import {id, model, prop} from "../manager/decorators";
import MinimalUser from "./MinimalUser";
import {getGroupAvatarPath} from "../helper/ImageHelper";
import parameters from "../../config/parameters.json";
import {PrivacyLevel} from "../enums/PrivacyLevel";
import AUTHDISTRIBUTORMEMBERSHIP from "../auth/AuthDistributorMembership";
import {SERVER_TIMESTAMP} from "../manager/decorators/sentinels";

@model('client/:client/distributor')
class Distributor {

    @id()
    id;

    @prop()
    cUid;

    @prop()
    name;

    @prop()
    description;

    @prop()
    imageOwner;

    @prop()
    count;

    @prop()
    requests;

    @prop()
    privacy;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP})
    uAt;

    get isPublic() {
        return this.privacy === PrivacyLevel.PUBLIC || this.id === parameters.globalDistributorId ;
    };

    get isContentVisible() {
        return this.privacy === PrivacyLevel.PUBLIC || this.isAuthUserMember;
    }

    get avatar() {
        if(!this.imageOwner)
            return undefined;
        return getGroupAvatarPath(this.imageOwner, this.id);
    };

    @prop({
        nullable: false,
        type: "array",
        typeOptions: {
            entry: "model",
            entryOptions: {
                target: MinimalUser
            }
        }
    })
    sample = [];

    get isAuthUserMember() {
        return AUTHDISTRIBUTORMEMBERSHIP.isMemberOfDistributor(this.id);
    }
    set isAuthUserMember(has) {
        if (has) {
            AUTHDISTRIBUTORMEMBERSHIP.addMembership(this.id);
        } else {
            AUTHDISTRIBUTORMEMBERSHIP.removeMembership(this.id);
        }
    }

    get hasAuthUserRequest() {
        return AUTHDISTRIBUTORMEMBERSHIP.hasRequestForDistributor(this.id);
    }
    set hasAuthUserRequest(has) {
        if (has) {
            AUTHDISTRIBUTORMEMBERSHIP.addRequest(this.id);
        } else {
            AUTHDISTRIBUTORMEMBERSHIP.removeRequest(this.id);
        }
    }

    get waitingForMembershipStatus() {
        return AUTHDISTRIBUTORMEMBERSHIP.isWaitingForDistributor(this.id);
    }

    set waitingForMembershipStatus(waiting) {
        if (waiting) {
            AUTHDISTRIBUTORMEMBERSHIP.addWaiting(this.id);
        } else {
            AUTHDISTRIBUTORMEMBERSHIP.removeWaiting(this.id);
        }
    }

    deleting = false;
}

export default Distributor;

