import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import MoinSectionHeadline from "../../components/base/MoinSectionHeadline";
import MoinHingedText from "../../components/base/MoinHingedText";
import MoinUserListItem from "../../components/user/MoinUserListItem";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import {Link, withRouter, useHistory} from "react-router-dom";
import * as ROUTES from "../../Routes";
import {makeStyles} from "@material-ui/core/styles";
import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import MoinSearchField from "../../components/base/MoinSearchField";
import MASTERTHEME from "../MasterTheme";


const useStyles = makeStyles((theme) => ({
    detailSpacing: {
        padding: theme.spacing(0,1),
    }
}));

/**
 *
 * @param {Distributor} distributor
 * @param {Array.<String>} userIds
 * @param {Array.<User>} members
 * @param {User} creator
 * @returns {JSX.Element}
 * @constructor
 */
const GroupDetail = ({distributor, userIds= [], creator, users}) => {
    const classes = MASTERTHEME.getStyles();
    const ownClasses = useStyles();

    const [t, i18n] = useTranslation();
    const history = useHistory();
    const [search, setSearch] = useState();
    const members = users.filter( (user) => ((userIds ||[] ).includes(user.uid)) && (!creator || user.id !== creator.id))

    return (
        <div className={ownClasses.detailSpacing}>
            {
                !!distributor.description && <>
                    <MoinSectionHeadline label={t('distributor.info')}/>
                    <ListItem>
                        <MoinHingedText text={distributor.description} count={240}/>
                    </ListItem>
                </>
            }

            {
                !!creator && <>
                    <MoinSectionHeadline label={t('distributor.administrator')}/>
                    <Link to={ROUTES.userShow.replace(':id', creator.id)} className={classes.defaultLink}>
                        <MoinUserListItem user={creator} divider={false}/>
                    </Link>
                </>
            }

            {
                !!members && members.length > 0 && <>
                    <MoinSectionHeadline label={t('distributor.members')}/>

                    <MoinSearchField
                        onChange={e => setSearch(e.target.value)}
                        placeholder={t('distributor.placeholder.searchMembers')}
                        style={{width: '100%', marginTop: "8px"}}
                        value={search}
                        onClear={e => setSearch('')}
                    />

                    <SearchableSelectableUserList
                        search={search}
                        users={members}
                        showCheckbox={false}
                        useDepartments={false}
                        onSelect={
                            (user) => history.push(ROUTES.userShow.replace(':id', user.id))
                        }/>
                </>
            }
        </div>
    );
};

const mapState = (state, ownProps) =>  ({
    users: getModelInstances(state,User),
    creator: getModelInstance(state,User, ownProps.distributor.cUid),
});

export default  connect(mapState)(withRouter(GroupDetail));