export const ActiveNavBar = {
    DASHBOARD: "ActiveNavBar.DASHBOARD",
    CHAT: "ActiveNavBar.CHAT",
    GROUPS: "ActiveNavBar.GROUPS",
    CONTACTS: "ActiveNavBar.CONTACTS",
    EVENTS: "ActiveNavBar.EVENTS",
    FAVORITEDARTICLES: "ActiveNavBar.FAVORITEDARTICLES",
    HUGS: "ActiveNavBar.HUGS",
    ILLNESS: "ActiveNavBar.ILLNESS",
    DPD: "ActiveNavBar.DPD",
    TERMS: "ActiveNavBar.TERMS",
    IMPRESS: "ActiveNavBar.IMPRESS",
    OWNPROFILE: "ActiveNavBar.OWNPROFILE",
    STORAGE: "ActiveNavBar.STORAGE",
    TASKS: "ActiveNavBar.TASKS",
    HELP: "ActiveNavBar.HELP"
};