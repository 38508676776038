import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import GroupDetailShow from "./GroupDetailShow";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import Distributor from "../../utils/models/Distributor";
import DistributorMembership from "../../utils/models/DistributorMembership";
import ModelManager from "../../utils/manager/ModelManager";
import EmptyState from "../../components/base/EmptyState";

/**
 *
 * @param {String} distributorId
 * @param {Distributor} distributor
 * @returns {JSX.Element}
 * @constructor
 */
const GroupDetailShowContainer = ({distributorId, distributor}) => {

    if (!distributor) {
        return <EmptyState/>
    }

    return(
        <GroupDetailShow
            distributor={distributor}
            key={"distributor-tag-" + distributor.id}
        />
    );
};

const mapState = (state, ownProps) => {
    return {
        distributor: getModelInstance(state, Distributor,ownProps.distributorId)
    }
}

export default connect(mapState)(GroupDetailShowContainer);