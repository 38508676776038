import {connect} from "react-redux";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import React, {useEffect, useState} from "react";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {ReactionType} from "../../utils/enums/ReactionType";
import ReactionList from "../article/ReactionList";
import {updateModelInstance} from "../../utils/store/actions";
import User from "../../utils/models/User";
import CommentAnswer from "../../utils/models/CommentAnswer";

/**
 * @param {string} id
 * @param {CommentAnswer} commentAnswer
 * @param {User[]} users
 * @constructor
 */
const CommentAnswerReactionListContainer = ({id, commentAnswer, users}) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!commentAnswer) {
            ModelManager.get({model: CommentAnswer, id: id}).then(comment => loadCommentAnswerReactions(comment));
        } else if(!commentAnswer.allReactions || commentAnswer.reactions.length === 0 || (commentAnswer.reactions.length === 1 && !!commentAnswer.reactions[AUTHSTORAGE.getUserId()])) {
            loadCommentAnswerReactions(commentAnswer);
        }
    }, []);

    const loadCommentAnswerReactions = async (commentAnswer) =>  {
        setLoading(true);
        let heartDocs = await ModelManager.list({
            resource: 'client/' + AUTHSTORAGE.getClientId() + '/comment_answer/' + commentAnswer.id + '/heart/'
        })

        let reactions = {};

        Object.keys(heartDocs).forEach((key)  => {
            if (key === "_all") {
                let allData = heartDocs[key];
                for(let userId of Object.keys(allData)) {
                    if((typeof allData[userId]) === 'string') {
                        reactions[userId] = allData[userId];
                    } else {
                        reactions[userId] = ReactionType.LIKE;
                    }
                }
            } else {
                reactions[key] = ReactionType.LIKE;
            }
        });

        commentAnswer.allReactions = reactions;
        updateModelInstance(CommentAnswer, commentAnswer);

        setLoading(false);
    }

    return <ReactionList allReactions={commentAnswer?.allReactions} users={users} loading={loading}/>;
}

const mapState = (state, ownProps) => ({
    commentAnswer: getModelInstance(state, CommentAnswer, ownProps.id),
    users: getModelInstances(state, User)
});

export default connect(mapState)(CommentAnswerReactionListContainer);
