import React, {Fragment, useEffect, useRef, useState} from "react";
import CalendarDay1Icon from "../../assets/icons/calendar-day-01.svg";
import CalendarDay2Icon from "../../assets/icons/calendar-day-02.svg";
import CalendarDay3Icon from "../../assets/icons/calendar-day-03.svg";
import CalendarDay4Icon from "../../assets/icons/calendar-day-04.svg";
import CalendarDay5Icon from "../../assets/icons/calendar-day-05.svg";
import CalendarDay6Icon from "../../assets/icons/calendar-day-06.svg";
import CalendarDay7Icon from "../../assets/icons/calendar-day-07.svg";
import CalendarDay8Icon from "../../assets/icons/calendar-day-08.svg";
import CalendarDay9Icon from "../../assets/icons/calendar-day-09.svg";
import CalendarDay10Icon from "../../assets/icons/calendar-day-10.svg";
import CalendarDay11Icon from "../../assets/icons/calendar-day-11.svg";
import CalendarDay12Icon from "../../assets/icons/calendar-day-12.svg";
import CalendarDay13Icon from "../../assets/icons/calendar-day-13.svg";
import CalendarDay14Icon from "../../assets/icons/calendar-day-14.svg";
import CalendarDay15Icon from "../../assets/icons/calendar-day-15.svg";
import CalendarDay16Icon from "../../assets/icons/calendar-day-16.svg";
import CalendarDay17Icon from "../../assets/icons/calendar-day-17.svg";
import CalendarDay18Icon from "../../assets/icons/calendar-day-18.svg";
import CalendarDay19Icon from "../../assets/icons/calendar-day-19.svg";
import CalendarDay20Icon from "../../assets/icons/calendar-day-20.svg";
import CalendarDay21Icon from "../../assets/icons/calendar-day-21.svg";
import CalendarDay22Icon from "../../assets/icons/calendar-day-22.svg";
import CalendarDay23Icon from "../../assets/icons/calendar-day-23.svg";
import CalendarDay24Icon from "../../assets/icons/calendar-day-24.svg";
import CalendarDay25Icon from "../../assets/icons/calendar-day-25.svg";
import CalendarDay26Icon from "../../assets/icons/calendar-day-26.svg";
import CalendarDay27Icon from "../../assets/icons/calendar-day-27.svg";
import CalendarDay28Icon from "../../assets/icons/calendar-day-28.svg";
import CalendarDay29Icon from "../../assets/icons/calendar-day-29.svg";
import CalendarDay30Icon from "../../assets/icons/calendar-day-30.svg";
import CalendarDay31Icon from "../../assets/icons/calendar-day-31.svg";

export const dateIconHelper = (date) => {
    switch (date) {
        case 1:
            return <CalendarDay1Icon/>;
        case 2:
            return <CalendarDay2Icon/>;
        case 3:
            return <CalendarDay3Icon/>;
        case 4:
            return <CalendarDay4Icon/>;
        case 5:
            return <CalendarDay5Icon/>;
        case 6:
            return <CalendarDay6Icon/>;
        case 7:
            return <CalendarDay7Icon/>;
        case 8:
            return <CalendarDay8Icon/>;
        case 9:
            return <CalendarDay9Icon/>;
        case 10:
            return <CalendarDay10Icon/>;
        case 11:
            return <CalendarDay11Icon/>;
        case 12:
            return <CalendarDay12Icon/>;
        case 13:
            return <CalendarDay13Icon/>;
        case 14:
            return <CalendarDay14Icon/>;
        case 15:
            return <CalendarDay15Icon/>;
        case 16:
            return <CalendarDay16Icon/>;
        case 17:
            return <CalendarDay17Icon/>;
        case 18:
            return <CalendarDay18Icon/>;
        case 19:
            return <CalendarDay19Icon/>;
        case 20:
            return <CalendarDay20Icon/>;
        case 21:
            return <CalendarDay21Icon/>;
        case 22:
            return <CalendarDay22Icon/>;
        case 23:
            return <CalendarDay23Icon/>;
        case 24:
            return <CalendarDay24Icon/>;
        case 25:
            return <CalendarDay25Icon/>;
        case 26:
            return <CalendarDay26Icon/>;
        case 27:
            return <CalendarDay27Icon/>;
        case 28:
            return <CalendarDay28Icon/>;
        case 29:
            return <CalendarDay29Icon/>;
        case 30:
            return <CalendarDay30Icon/>;
        case 31:
            return <CalendarDay31Icon/>;
    }
}