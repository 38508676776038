import React, {Fragment, useEffect, useRef, useState} from 'react';
import {MediaContentType} from "../../utils/enums/MediaContentType";
import MoinButton from "./MoinButton";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import clsx from "clsx";



const useStyles = makeStyles(() => ({
    wrapper: {
        position: "relative",
        height: '100%'
    },
    overlay: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: '.3',
        backgroundColor: 'transparent',
        position: 'absolute',
        zIndex: '20'
    },
}));
/**
 * /**
 * Moin! Themed Input. Renders a button that triggers the input by default, if given children they will trigger the
 * input instead. If you want to display a selected image/video, you can use URL.createObjectURL(file).
 *
 * @param {String|JSX.Element} uploadButtonText Text of the button that triggers the input. You can also add elements,
 * e.g. an icon.
 * @param {String} [type=MediaContentType.IMAGE] Type of the input. Use MediaContentType. Works
 * with images, videos and pdf. Image by default.
 * @param {Function} onChangeFile Callback function when file(s) are selected. Returns array with files. If you want to
 * display a selected image/video, you can use URL.createObjectURL(file)
 * @param {Boolean} [isMultipleInput=false] Multiple input or single file?
 * @param {JSX.Element} [children] If clicked on, the file input is triggered.
 * @param {Boolean} disableClick
 * @param rest
 * @returns {JSX.Element}
 * @example
 * <MoinUpload
     id={"moin-upload-example"}
     type={MediaContentType.PDF}
     uploadButtonText={"Moin! Upload"}
     onChangeFile={files => console.log(files)}
     isMultipleInput={true} />
 */

const MoinUpload = ({uploadButtonText, type = MediaContentType.IMAGE, onChangeFile, isMultipleInput = false, overlayClass , children, disableClick = false, ...rest}) => {
    let inputElement = '';
    const classes = useStyles();

    const [isDragging, setIsDragging] = useState(false);
    const isFileValid = useRef(false);
    const dragCounter = useRef(0);

    useEffect(() => {
        let dropZone = dropZoneRef.current;
        if (!!dropZone) {
            dropZone.addEventListener('dragenter', handleDragIn);
            dropZone.addEventListener('dragleave', handleDragOut);
            dropZone.addEventListener('dragover', handleDrag);
            dropZone.addEventListener('drop', handleDrop);

            return () => {
                dropZone.removeEventListener('dragenter', handleDragIn);
                dropZone.removeEventListener('dragleave', handleDragOut);
                dropZone.removeEventListener('dragover', handleDrag);
                dropZone.removeEventListener('drop', handleDrop);
            }
        }

    });

    const acceptsType = () => {
        let accept;

        switch (type) {
            case MediaContentType.IMAGE:
                accept = "image/png,image/jpeg,image/gif";
                break;
            case MediaContentType.VIDEO:
                accept = "video/mp4";
                break;
            case MediaContentType.PDF:
                accept = "application/pdf";
                break;
            case MediaContentType.IMAGEORPDF:
                accept = "application/pdf,image/png,image/jpeg";
                break;
            case MediaContentType.ALL:
                accept = "ALL";
                break;
            default:
                break;
        }
        return accept
    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (dragCounter.current === 0) {
            if (e.dataTransfer.items && (e.dataTransfer.items.length === 1 || isMultipleInput)) {
                let accepts = acceptsType();

                if (accepts && accepts === "ALL") {
                    isFileValid.current = true;
                } else if (!!accepts) {
                    let types = accepts.split(',');
                    let isValid = true;

                    for (let i =0; i < e.dataTransfer.items.length; i++) {
                        if (isValid) {
                            isValid = types.includes(e.dataTransfer.items[i].type);
                        }
                    }
                    isFileValid.current = isValid;
                } else {
                    isFileValid.current = false;

                }
            } else {
                isFileValid.current = false;
            }
            setIsDragging(true);
        }
        dragCounter.current++;
    }

    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dragCounter.current--;
        if (dragCounter.current < 0) {
            dragCounter.current = 0;
        }
        if (dragCounter.current === 0) {
            isFileValid.current = false;
            setIsDragging(false);
        }
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dragCounter.current = 0;
        isFileValid.current = false;
        setIsDragging(false);

        if (e.dataTransfer.items && (e.dataTransfer.items.length === 1 || isMultipleInput)) {
            let accepts = acceptsType();

            if (accepts && accepts === "ALL") {
                onChangeFile(e.dataTransfer.files);
            }
            else if (!!accepts) {
                let types = accepts.split(',');
                let isValid = false;
                types.forEach( (type) => {
                    if (  e.dataTransfer.files[0].type.includes(type)) {
                        isValid = true;
                    }
                })
                if (isValid) {
                    onChangeFile(e.dataTransfer.files);
                }
            }
        }
    }

    const uploadClick = e => {
        e.preventDefault();
        inputElement.click();
        return false;
    };

    const handleChange = e => {
        onChangeFile(e.target.files);
    };

    const dropZoneRef = useRef();
    let accept = acceptsType();

    let renderDropZone = () => {
        if (!!children) {
            return <div
                className={classes.wrapper}
                ref={(node) => dropZoneRef.current = node}
                onClick={disableClick ? undefined : uploadClick}
                style={ !disableClick ? {cursor: 'pointer'} : {} }
            >
                {children}
                <div
                    className={clsx(classes.overlay, isDragging ? overlayClass : undefined)}
                    style={
                        isDragging
                            ? {
                                pointerEvents: "none",
                                backgroundColor: isFileValid.current ? Colors.SUCCESSLIGHT : Colors.ERRORLIGHT
                            }
                            : {
                                pointerEvents: disableClick ? "none" : "all"
                            }
                    }
                />
            </div>
          ;
        }

        return <MoinButton
            onClick={uploadClick}
            {...rest}>
            {uploadButtonText}
        </MoinButton>;
    }

    return (
        <>
            {renderDropZone()}
            <input
                ref={(fileInput) => inputElement = fileInput}
                type="file"
                accept={accept}
                style={{display: 'none'}}
                onChange={(e) => {handleChange(e)} }
                multiple={isMultipleInput}
            />
        </>
    );
}

export default MoinUpload;