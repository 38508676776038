import React from 'react';
import {connect} from "react-redux";
import EventOverview from "./EventOverview";
import {sortByDate} from "../../utils/helper/SortHelper";
import EventMembership from "../../utils/models/EventMembership";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import Event from "../../utils/models/Event";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import Distributor from "../../utils/models/Distributor";
import {filterPastEvents, filterUpcomingEvents} from "../../utils/helper/EventHelper";

/**
 *
 * @param {Array.<Event>} events
 * @param {Array.<Distributor>} distributor
 * @param {EventMembership} eventParticipation
 * @returns {JSX.Element}
 * @constructor
 */
const EventOverviewContainer = ({events = [], distributor = [], eventParticipation}) => {
    if (!!eventParticipation) {
        events.forEach( (event) => event.isAuthUserParticipating = eventParticipation.events.hasOwnProperty(event.id) );
    }

    let upcomingEvents = filterUpcomingEvents(events);
    upcomingEvents.sort( (a,b) => sortByDate(a.start,b.start));

    let pastEvents = filterPastEvents(events);
    pastEvents.sort( (a,b) => sortByDate(b.start,a.start));

    return(
        <EventOverview upcomingEvents={upcomingEvents} pastEvents={pastEvents} distributor={distributor} />
    );
};

const mapState = (state) => ({
    events: getModelInstances(state, Event),
    eventParticipation: getModelInstance(state, EventMembership, AUTHSTORAGE.getUserId()),
    distributor: getModelInstances(state, Distributor),
});

export default connect(mapState)(EventOverviewContainer);