import React, {useRef, useState} from "react";
import HelpReportDialog from "./HelpReportDialog";
import ApiClient from "../../api/ApiClient";
import {deviceDetect} from "react-device-detect";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {generateRandomId} from "../../utils/helper/RandomHelper";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import SUCCESSNOTIFICATIONMANAGER from "../../components/success/SuccessNotificationManager";
import SuccessMessage from "../../utils/models/SuccessMessage";
import {SuccessType} from "../../utils/enums/SuccessType";

const HelpReportDialogContainer = ({ closeDialog, show = false, isImproveMoinDialog=false }) => {
    const [sending, setSending] = useState(false);
    const currentlySending = useRef(false);
    const firebaseStorageManager = new FirebaseStorageManager();

    const uploadImages = async (images) => {
        let imageUrls = [];
        let uploadFutures = [];

        images.forEach(image => {
            const filePath = `client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/bugreport/${generateRandomId()}`;

            uploadFutures.push(async function(){
                await firebaseStorageManager.uploadImage(image, filePath, 1200 , 1)
                imageUrls.push(await firebaseStorageManager.getDownloadUrlForPath(filePath));
            }());
        });

        await Promise.all(uploadFutures);
        return imageUrls;
    };

    const uploadVideos = async (videos) => {
        let videoUrls = [];
        let uploadFutures = [];

        videos.forEach(video => {
            const filePath = `client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/bugreport/${generateRandomId()}`;

            uploadFutures.push(async function(){
                await firebaseStorageManager.uploadFile(video, filePath, undefined , undefined, undefined)
                videoUrls.push(await firebaseStorageManager.getDownloadUrlForPath(filePath));
            }());
        });

        await Promise.all(uploadFutures);
        return videoUrls;
    };

    const onSend = async (text, images, videos) => {
        if(!currentlySending.current) {
            setSending(true);
            currentlySending.current = true;

            let imageUrls = await uploadImages(images);
            let videoUrls = await uploadVideos(videos);
            let request;

            if(isImproveMoinDialog) {
                request = await ApiClient.createRequest('/sendFeedback', {
                    'feedback': text,
                    'media': JSON.stringify([...imageUrls, ...videoUrls])
                });
            } else {
                request = await ApiClient.createRequest('/sendBugReport', {
                    'report': text,
                    'data': JSON.stringify({
                        'browserInfo': deviceDetect(undefined), // If you pass undefined, it uses the userAgent of the current browser
                        'reportTime': (new Date()).toISOString(),
                        'appStartTime': AUTHSTORAGE.getCurrentOpening().toISOString()
                    }),
                    'media': JSON.stringify([...imageUrls, ...videoUrls])
                });
            }

            await request.send();

            setSending(false);
            currentlySending.current = false;

            SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification', new SuccessMessage(SuccessType.BUGREPORTSENT, true));

            closeDialog();
        }
    };
    return <HelpReportDialog show={show} onSend={onSend} sending={sending} onClose={closeDialog} isImproveMoinDialog={isImproveMoinDialog}/>;
}

export default HelpReportDialogContainer;
