import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import Card from "@material-ui/core/Card";
import clsx from 'clsx';
import {CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import FavoritedIcon from "../../assets/icons/heart-active.svg";

const useStyles = makeStyles(()  => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.09), 0px 0px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: Colors.WHITE,
        textAlign: 'left',
        marginTop: "16px"
    },
    roundBorders: {
        borderRadius: "16px"
    },
    headerImageContainer: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
    },
    squareHeaderImageContainer: {
        width: '100%',
        height: 0,
        paddingBottom: '85%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
    },
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
    },
    cardContentSpacer: {
        boxShadow: "0px 5px 10px -5px rgba(0, 0, 0, 0.09), 0px 2px 4px -2px rgba(0, 0, 0, 0.1)",
        height: "8px",
        backgroundColor: Colors.BLUEBACKGROUND,
        width: '100%',
    },
    cardContentContainerBlue: {
        width: '100%',
        display: 'block',
        backgroundColor: Colors.BLUEBACKGROUND,
        paddingLeft: "8px",
        paddingRight:"8px",
        paddingBottom: "24px",
    },
    sectionDivider: {
        width: '100%',
        borderBottom: `1px solid ${Colors.BLUELIGHT}`,
        padding: '12px 0',
        marginBottom: '12px'
    },
    bookMark: {
        backgroundColor: Colors.BRANDPRIMARY,
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        padding: '28px 16px',
        position: 'absolute',
        top: '0',
        right: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            fill: Colors.WHITE
        },
        zIndex: '100'
    }
}));

/**
 * Moin! themed card
 *
 * @param {boolean} [hasRoundedBorders = false] Use true for overview page elements
 * @param {Array<JSX.Element>} [children]
 * @param className
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <MoinCard>
     <MoinCardHeaderImage>
        <FireStorageImage fontSize={64} src={source} alt={"alt"}/>
     </MoinCardHeaderImage>
     <MoinCardHeader>
        <Typography variant="h3">Title</Typography>
     </MoinCardHeader>
     <MoinCardContent>
        <MoinCardContentTitle>Content 1</MoinCardContentTitle>
        <MoinDepartmentListItem user={user} divider={false} />
     </MoinCardContent>
 </MoinCard>
 */
const MoinCard = ({hasRoundedBorders = true, children, className, onClick}) => {
    const classes = useStyles();
    return (
        <Card onClick={onClick} className={clsx(classes.card, hasRoundedBorders && classes.roundBorders, className)}>
            {children}
        </Card>
    );
}

/**
 * Image header for a <MoinCard> in 16:9 or 1:1 format
 *
 * @param {boolean} showBookmark Display a bookmark in the top right
 * @param {JSX.Element} children Image to be displayed
 * @param {boolean} isSquare
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <MoinCardHeaderImage>
 <FireStorageImage fontSize={64} src={source} alt={"alt"}/>
 </MoinCardHeaderImage>
 */
const MoinCardHeaderImage = ({showBookmark, children, isSquare=false}) => {
    const classes = useStyles();

    if(isSquare) {
        return (
            <div className={classes.squareHeaderImageContainer}>
                {showBookmark && <div className={classes.bookMark}><FavoritedIcon fill={Colors.WHITE} /></div>}
                {children}
            </div>
        )
    }

    return (
        <div className={classes.headerImageContainer}>
            {showBookmark && <div className={classes.bookMark}><FavoritedIcon fill={Colors.WHITE} /></div>}
            {children}
        </div>
    )
}

/**
 * Header of <MoinCard>. Centered content with white background
 * @param className
 * @param {JSX.Element} children
 * @param style
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <MoinCardHeader>
 <Typography variant="h3">Title</Typography>
 </MoinCardHeader>
 */
const MoinCardHeader = ({className, children, style}) => {
    const classes = useStyles();

    return (
        <CardContent className={clsx(classes.header, className)} style={style}>
            {children}
        </CardContent>
    )
}

/**
 * Content of <MoinCard>. Has built in divider for the header.
 * @param className
 * @param {boolean} showContentSpacer
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <MoinCardContent>
    <MoinCardContentTitle>Content 1</MoinCardContentTitle>
    <MoinDepartmentListItem user={user} divider={false} />
 </MoinCardContent>
 */
const MoinCardContent = ({className, hideContentSpacer = false, children}) => {
    const classes = useStyles();

    return (
        <>
            { !hideContentSpacer && <div className={classes.cardContentSpacer}/>}
            <div className={clsx(classes.cardContentContainerBlue, className)}>
                {children}
            </div>
        </>
    );
}

/**
 * Content title row with underline that divides the content.
 * @param className
 * @param {JSX.Element} children
 * @param {JSX.Element} style
 * @param {String} variant
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <MoinCardContentTitle>Content 1</MoinCardContentTitle>
 */
const MoinCardContentTitle = ({className, children,style, variant}) => {
    const classes = useStyles();

    return (
        <Typography variant={variant} className={clsx(classes.sectionDivider, className)} style={style}>
            {children}
        </Typography>
    )
}

export {MoinCard, MoinCardHeaderImage, MoinCardHeader, MoinCardContent, MoinCardContentTitle};
