import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {Redirect} from "react-router-dom";
import User from "../../utils/models/User";
import * as ROUTES from "../../Routes";
import {
    addModelInstance,
} from "../../utils/store/actions";
import Chat from "../../utils/models/Chat";
import {createSymmetricKey} from "../../utils/helper/EncryptionHelper";
import SingleChatCreate from "./SingleChatCreate";

/**
 *
 * @param {Array<Chat>} chats
 * @param {User} authUser
 * @returns {JSX.Element}
 * @constructor
 */
const SingleChatCreateContainer = ({ authUser, chats }) => {
    const [saving, setSaving] =  useState(false);
    const [redirect, setRedirect] =  useState(false);

    const onSave = ({selectedUser}) => {

        if (!saving) {
            setSaving(true);

            let chat = chats.find((chatInstance) => {
                if (!chatInstance.archived && chatInstance.isSingleChat && chatInstance.member.length === 2) {
                    let userIds = chatInstance.member.map((user) => user.uid)
                    return userIds.includes(authUser.uid) && userIds.includes(selectedUser.uid);
                }
                return false;
            });

            if (!!chat) {
                setRedirect(chat.id);
            } else {
                let newChat = Chat.from(
                    {
                        "name": selectedUser.fullName + ", " + authUser.fullName,
                        "cUid": AUTHSTORAGE.getUserId(),
                        "count": 1,
                        "uMC": 0
                    }
                );
                newChat.isTemp = true;

                newChat.member = [selectedUser, authUser];
                newChat.sample = [selectedUser.getAsMinimalUser(), authUser.getAsMinimalUser()]
                newChat.symKey = createSymmetricKey({length: 32});

                addModelInstance(Chat, newChat);
                setRedirect(newChat.id);
            }

        }
    }


    if(redirect && typeof redirect=== "string")
        return <Redirect push to={ROUTES.messagesShow.replace(':id', redirect)} />;

    return <SingleChatCreate saving={saving} onSave={onSave}/>
};

const mapState= (state, ownProps) => (
     {
        chats: getModelInstances(state, Chat),
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
    }
);

export default connect(mapState)(SingleChatCreateContainer);
