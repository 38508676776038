import {id, model, prop} from "../manager/decorators";

@model("/client/:client/user/:user/private")
class MessagingCount {

    @id()
    id;

    @prop()
    count;

    @prop()
    lastOpen;

    @prop()
    lastCalculated;
}

export default MessagingCount;
