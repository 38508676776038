import {ReactionType} from "../../utils/enums/ReactionType";
import ThumbsUpOutlineIcon from "../../assets/icons/thumb-up-outline.svg";
import ThumbsUpFilledIcon from "../../assets/icons/thumb-up-filled.svg";
import LightbulbOutlineIcon from "../../assets/icons/lightbulb-outline.svg";
import LightbulbFilledIcon from "../../assets/icons/lightbulb-filled.svg";
import ClapFilledIcon from "../../assets/icons/clap-filled.svg";
import LaughOutlineIcon from "../../assets/icons/laugh-outline.svg";
import LaughFilledIcon from "../../assets/icons/laugh-filled.svg";
import SadOutlineIcon from "../../assets/icons/sad-outline.svg";
import SadFilledIcon from "../../assets/icons/sad-filled.svg";
import HeartInactiveIcon from "../../assets/icons/heart-inactive.svg";
import HeartActiveIcon from "../../assets/icons/heart-active.svg";
import BeeIcon from "../../assets/icons/bee.svg";
import PrayIcon from "../../assets/icons/pray.svg";
import {HugType} from "../../utils/enums/HugType";

export function getOutlineIconForReaction(reactionType) {
    let icons = {
        [ReactionType.LIKE]: ThumbsUpOutlineIcon,
        [ReactionType.HEART]: HeartInactiveIcon,
        [ReactionType.IDEA]: LightbulbOutlineIcon,
        [ReactionType.CLAP]: ClapFilledIcon,
        [ReactionType.FUNNY]: LaughOutlineIcon,
        [ReactionType.SAD]: SadOutlineIcon
    };

    if(reactionType.startsWith("ReactionType.")) {
        return icons[reactionType];
    }

    return icons["ReactionType." + reactionType];
}

export function getFilledIconForReaction(reactionType) {
    let icons = {
        [ReactionType.LIKE]: ThumbsUpFilledIcon,
        [ReactionType.HEART]: HeartActiveIcon,
        [ReactionType.IDEA]: LightbulbFilledIcon,
        [ReactionType.CLAP]: ClapFilledIcon,
        [ReactionType.FUNNY]: LaughFilledIcon,
        [ReactionType.SAD]: SadFilledIcon
    };

    if(reactionType.startsWith("ReactionType.")) {
        return icons[reactionType];
    }

    return icons["ReactionType." + reactionType];
}

export function getIconForHug(hugType) {
    let icons = {
        [HugType.HEART]: HeartActiveIcon,
        [HugType.PRAY]: PrayIcon,
        [HugType.IDEA]: LightbulbFilledIcon,
        [HugType.FUNNY]: LaughFilledIcon,
        [HugType.CLAP]: ClapFilledIcon,
        [HugType.BEE]: BeeIcon
    };

    return icons[hugType];
}