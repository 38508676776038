import {id, model, prop} from "../manager/decorators";
import MinimalUser from "./MinimalUser";
import {SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import {getUserAvatarPath} from "../helper/ImageHelper";

@model("/client/:client/key")
class PrivateKey
{

    /**
     * id without generator
     * the uid is handled bay application and must be identical to the firebase user id
     */
    @id(null)
    id;

    @prop({nullable: true})
    iv;

    @prop({nullable: true})
    privateKey;

    @prop({nullable: true})
    browserEncryptionKey;

    @prop({nullable: true})
    browserEncryptionIV;
}

export default PrivateKey;