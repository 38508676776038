import clsx from "clsx";
import {Route, Switch, withRouter} from "react-router-dom";
import * as ROUTES from "../Routes";
import ArticleStreamContainer from "./article/ArticleStreamContainer";
import ArticleDetailContainer from "./article/ArticleDetailContainer";
import React, {useEffect, useState} from "react";
import ArticleCreateContainer from "./article/ArticleCreateContainer";
import ArticleUpdateContainer from "./article/ArticleUpdateContainer";
import UserProfileContainer from "./user/UserProfileContainer";
import ChatOverviewContainer from "./chat/ChatOverviewContainer";
import ChatDetailContainer from "./chat/ChatDetailContainer";
import NotificationOverviewContainer from "./NotificationOverviewContainer";
import UserEditContainer from "./user/UserEditContainer";
import GroupDetailShowContainer from "./groups/GroupDetailShowContainer";
import GroupOverviewContainer from "./groups/GroupOverviewContainer";
import GroupShowContainer from "./groups/GroupShowContainer";
import GroupCreateEditContainerWrapper from "./groups/GroupCreateEditContainer";
import SurveyAnswersContainer from "./article/survey/SurveyAnswersContainer";
import GroupResponsibleContainerWrapper from "./groups/GroupResponsibleContainer";
import GroupRequestContainer from "./groups/GroupRequestContainer";
import EventParticipantsShowContainer from "./event/EventParticipantsShowContainer";
import EventShowContainer from "./event/EventShowContainer";
import EventOverviewContainer from "./event/EventOverviewContainer";
import EventCreateEditContainer from "./event/EventCreateEditContainer";
import ChatCreateEditContainer from "./chat/ChatCreateEditContainer";
import SingleChatCreateContainer from "./chat/SingleChatCreateContainer";
import MarkdownViewContainer from "./legal/MarkdownViewContainer";
import {MarkdownTarget} from "./legal/MarkdownTarget";
import SearchContainer from "./search/SearchContainer";
import CommentDetailContainer from "./comment/CommentDetailContainer";
import ArticleReactionListContainer from "./article/ArticleReactionListContainer";
import CommentReactionListContainer from "./comment/CommentReactionListContainer";
import CommentAnswerReactionListContainer from "./comment/CommentAnswerReactionListContainer";
import IllnessCreateContainer from "./illness/IllnessCreateContainer";
import GiveHugsOverviewContainer from "./hugs/GiveHugsOverviewContainer";
import TasksContainer from "./tasks/TasksContainer";
import StorageContainer from "./storage/StorageContainer";
import StorageSearchContainer from "./storage/StorageSearchContainer";
import TasksSearchResultContainer from "./tasks/TasksSearchResultContainer";
import ContactList from "./company/ContactList";
import MASTERTHEME from "./MasterTheme";
import ChatExternalUserContainer from "../components/chat/ChatExternalUserContainer";
import AUTHSTORAGE from "../utils/auth/AuthStorage";
import HelpOverview from "./help/HelpOverview";
import FaqOverview from "./help/FaqOverview";
import FavoritedArticlesListContainer from "./article/FavoritedArticlesListContainer";
import {articleCopy} from "../Routes";
import ArticleCopyContainer from "./article/ArticleCopyContainer";

const MasterRouting = ({location}) => {
    const classes = MASTERTHEME.getStyles();
    let hasMaxWidth = !( location?.pathname || "" ).startsWith("/storage") && !( location?.pathname || "" ).startsWith("/tasks");
    const [currentPath, setCurrentPath] = useState(null);
    const [previousPath, setPreviousPath] = useState(null);

    useEffect(() => {
        if (location.pathname !== currentPath) {
            if (location.pathname === ROUTES.dpdCookiesNav) {
                window.mmcm.launchDialogue();
            }
            setPreviousPath(currentPath);
            setCurrentPath(location.pathname);
        }
    }, [location.pathname]);

    if (AUTHSTORAGE.isExternal()) {
        return (
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: true,
                    [classes.contentMaxWidth]: hasMaxWidth,
                })}
            >
                <Switch>
                    <Route exact path={ROUTES.userShow}>
                        {({match: {params: {id}}}) => <UserProfileContainer key={"UserProfile" + id} uid={id} />}
                    </Route>
                    <Route exact path={ROUTES.userEdit}>
                        {({match: {params: {id}}}) => <UserEditContainer uid={id} />}
                    </Route>

                    <Route exact path={ROUTES.chat} component={ChatOverviewContainer} />
                    <Route exact path={ROUTES.messagesShow}>
                        {({match: {params: {id}}}) => <ChatDetailContainer id={id} />}
                    </Route>

                    <Route exact path={ROUTES.dpdNav}>
                        <MarkdownViewContainer target={MarkdownTarget.PRIVACY}/>
                    </Route>

                    <Route exact path={ROUTES.dpdCookiesNav}>
                        <MarkdownViewContainer target={MarkdownTarget.PRIVACY}/>
                    </Route>
                    <Route exact path={ROUTES.termsNav}>
                        <MarkdownViewContainer target={MarkdownTarget.TERMS}/>
                    </Route>
                    <Route exact path={ROUTES.impressNav}>
                        <MarkdownViewContainer target={MarkdownTarget.IMPRESS}/>
                    </Route>

                    <Route path={'/*'} component={ChatOverviewContainer}/>
                </Switch>
            </main>
        );
    }

    return (
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: true,
                [classes.contentMaxWidth]: hasMaxWidth,
            })}
        >
            <Switch>
                <Route exact path={ROUTES.home} component={ArticleStreamContainer}/>
                <Route exact path={ROUTES.articleFeed} component={ArticleStreamContainer}/>
                <Route exact path={ROUTES.favoritedArticles} component={FavoritedArticlesListContainer}/>
                <Route exact path={ROUTES.articleCreate}
                   render={
                       (props) =>
                           <ArticleCreateContainer preselectedDisId={props?.location?.state?.preselectedDisId} originPath={previousPath}/>
                   }
                />
                <Route exact path={ROUTES.surveyAnswerShow}>
                    {({match: {params: {sid}}}) => <SurveyAnswersContainer surveyId={sid}/>}
                </Route>

                <Route exact path={ROUTES.articleLikeList}>
                    {({match: {params: {id}}}) => <ArticleReactionListContainer id={id}/>}
                </Route>

                <Route exact path={ROUTES.commentLikeList}>
                    {({match: {params: {id}}}) => <CommentReactionListContainer id={id}/>}
                </Route>

                <Route exact path={ROUTES.commentAnswerLikeList}>
                    {({match: {params: {id}}}) => <CommentAnswerReactionListContainer id={id}/>}
                </Route>

                <Route exact path={ROUTES.articleShow}>
                    {({match: {params: {id}}}) => <ArticleDetailContainer id={id}/>}
                </Route>

                <Route exact path={ROUTES.articleEdit}>
                    {({match: {params: {id}}}) => <ArticleUpdateContainer id={id} originPath={previousPath}/>}
                </Route>

                <Route exact path={ROUTES.articleCopy}>
                    {({match: {params: {id}}}) => <ArticleCopyContainer id={id} originPath={previousPath}/>}
                </Route>

                <Route exact path={ROUTES.commentShow}>
                    {({match: {params: {id}}}) => <CommentDetailContainer id={id}/>}
                </Route>

                <Route exact path={ROUTES.groups} component={GroupOverviewContainer}/>
                <Route exact path={ROUTES.groupCreate}>
                    <GroupCreateEditContainerWrapper />
                </Route>
                <Route exact path={ROUTES.groupEdit}>
                    {({match: {params: {id}}}) => <GroupCreateEditContainerWrapper distributorId={id} />}
                </Route>
                <Route exact path={ROUTES.groupResponsible}>
                    {({match: {params: {id}}}) => <GroupResponsibleContainerWrapper distributorId={id} />}
                </Route>
                <Route exact path={ROUTES.groupRequests}>
                    {({match: {params: {id}}}) => <GroupRequestContainer distributorId={id} />}
                </Route>
                <Route exact path={ROUTES.groupShow}>
                    {({match: {params: {id}}}) => <GroupShowContainer distributorId={id} />}
                </Route>
                <Route exact path={ROUTES.groupDetailsShow}>
                    {({match: {params: {id}}}) => <GroupDetailShowContainer distributorId={id} />}
                </Route>
                <Route exact path={ROUTES.events} component={EventOverviewContainer} />
                <Route exact path={ROUTES.eventCreate} component={EventCreateEditContainer} />
                <Route exact path={ROUTES.eventEdit}>
                    {({match: {params: {id}}}) => <EventCreateEditContainer eventId={id} />}
                </Route>
                <Route exact path={ROUTES.eventShow}>
                    {({match: {params: {id}}}) => <EventShowContainer eventId={id} />}
                </Route>
                <Route exact path={ROUTES.eventParticipantsShow}>
                    {({match: {params: {id}}}) => <EventParticipantsShowContainer eventId={id} />}
                </Route>
                <Route exact path={ROUTES.contacts} component={ContactList} />

                <Route path={ROUTES.inviteExternal}>
                    {({match: {params: {ref}}}) => <ChatExternalUserContainer referrer={ref} />}
                </Route>

                <Route exact path={ROUTES.userShow}>
                    {({match: {params: {id}}}) => <UserProfileContainer key={"UserProfile" + id} uid={id} />}
                </Route>
                <Route exact path={ROUTES.userEdit}>
                    {({match: {params: {id}}}) => <UserEditContainer uid={id} />}
                </Route>
                <Route exact path={ROUTES.notifications} component={NotificationOverviewContainer} />

                <Route exact path={ROUTES.illness} component={IllnessCreateContainer} />

                <Route exact path={ROUTES.storageSearch}>
                    {({match: {params: {query}}}) => <StorageSearchContainer key={"RouteStorageSearchWithPath" + query} query={query.replaceAll("!!",".")} />}
                </Route>

                <Route exact path={ROUTES.storage} component={StorageContainer} />

                <Route exact path={ROUTES.storageWithPath}>
                    {({match: {params: {query}}}) => <StorageContainer key={"RouteStorageWithPath" + query} initialPath={query.replaceAll("!!","/")} />}
                </Route>

                <Route exact path={ROUTES.search}>
                    {({match: {params: {query}}}) => <SearchContainer  key={"RouteStorage" + query} query={query} />}
                </Route>

                <Route exact path={ROUTES.tasks} component={TasksContainer} />

                <Route exact path={ROUTES.tasksShow}>
                    {({match: {params: {id}}}) => <TasksContainer  key={"RouteTasks"} initialOpen={id} />}
                </Route>

                <Route exact path={ROUTES.tasksSearch}>
                    {({match: {params: {query}}}) => <TasksSearchResultContainer key={"RouteStorageSearchWithPath" + query} query={query.replaceAll("!!",".")} />}
                </Route>

                <Route exact path={ROUTES.sendHug} component={GiveHugsOverviewContainer} />
                <Route exact path={ROUTES.chat} component={ChatOverviewContainer} />
                <Route exact path={ROUTES.messagesSingleCreate} component={SingleChatCreateContainer} />
                <Route exact path={ROUTES.messagesCreate} component={ChatCreateEditContainer} />
                <Route exact path={ROUTES.messagesEdit}>
                    {({match: {params: {id}}}) => <ChatCreateEditContainer chatId={id} />}
                </Route>
                <Route exact path={ROUTES.messagesShow}>
                    {({match: {params: {id}}}) => <ChatDetailContainer id={id} />}
                </Route>

                <Route exact path={ROUTES.dpdNav}>
                    <MarkdownViewContainer target={MarkdownTarget.PRIVACY}/>
                </Route>

                <Route exact path={ROUTES.dpdCookiesNav}>
                    <MarkdownViewContainer target={MarkdownTarget.PRIVACY}/>
                </Route>
                <Route exact path={ROUTES.termsNav}>
                    <MarkdownViewContainer target={MarkdownTarget.TERMS}/>
                </Route>
                <Route exact path={ROUTES.impressNav}>
                    <MarkdownViewContainer target={MarkdownTarget.IMPRESS}/>
                </Route>

                <Route exact path={ROUTES.helpAndSupport} component={HelpOverview} />
                <Route exact path={ROUTES.faq} component={FaqOverview} />

                <Route path={'/*'} component={ArticleStreamContainer}/>

            </Switch>
        </main>
    )
}

export default withRouter(MasterRouting);
