import ArticleTeaserContainer from "../../components/article/ArticleTeaserContainer";
import React from "react";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import MoinDivider from "../../components/base/MoinDivider";
import {makeStyles} from "@material-ui/core/styles";
import illustration from "../../assets/illustrations/moin-app-illus-no-content-muted.png";

const useStyles = makeStyles((theme) => ({
    articleCount: {
        marginBottom: theme.spacing(1)
    },
    paper: {
        marginTop: '64px',
        marginBottom: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '768px',
        flex: '100',
    },
    illustration: {
        maxWidth: '45%',
        height: 'auto'
    }
}));

const FavoritedArticlesList = ({articles}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    if (articles.length === 0) {
        return (
            <div className={classes.paper}>
                <img src={illustration} alt={t('common.illustration')} className={classes.illustration}/>
                <Typography variant="h3" className={classes.title}>{t('article.favorite.emptyState')}</Typography>
            </div>
        );
    }

    return (
        <div>
            <Typography variant="h4" className={classes.articleCount}>{articles.length > 1 ? t('article.favorite.xSavedArticles', {count: articles.length}) : t('article.favorite.1SavedArticle')}</Typography>
            <MoinDivider/>
            {
                articles.map((article) => {
                    return <ArticleTeaserContainer article={article} key={"stream-article-" + article.id}/>;
                })
            }
        </div>
    )
}

export default FavoritedArticlesList;
