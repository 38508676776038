import Drawer from "@material-ui/core/Drawer";
import {Typography} from "@material-ui/core";
import CloseIcon from "../../assets/icons/close.svg";
import {Colors} from "../../components/helper/ColorHelper";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import CheckOutlineIcon from "../../assets/icons/check-outline.svg";
import CheckFilledIcon from "../../assets/icons/check-filled.svg";
import NotificationOffIcon from "../../assets/icons/notification-off.svg";
import NotificationInactiveIcon from "../../assets/icons/notification-inactive.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import TaskForm from "../../components/tasks/TaskForm";
import MoinSimpleMenu from "../../components/base/MoinSimpleMenu";
import AttachFileIcon from "../../assets/icons/attachment.svg";
import MoinSimpleMenuOption from "../../components/base/MoinSimpleMenuOption";
import {getModelInstance} from "../../utils/store/selectors";
import Task from "../../utils/models/Task";
import MoinUpload from "../../components/base/MoinUpload";
import {MediaContentType} from "../../utils/enums/MediaContentType";
import FireStorageImage from "../../components/base/FirestoreImage";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import DocumentPreview from "./DocumentPreview";
import TaskRepetition from "../../utils/models/TaskRepetition";
import {RepetitionType as TaskRepetitionType, RepetitionType} from "../../utils/enums/repetition/RepetitionType";

const useStyles = makeStyles((theme) => ({
    taskInfoDrawer: {
        width: "inherit",
        '& .MuiDrawer-paper': {
            // full screen reduced by nav and content, half of that reduced by margin to middle
            width: "calc( (100% - 240px  - 768px) / 2 - 16px)",
            minWidth: "400px",
            height: 'calc(100% - 65px)',
            marginTop: "65px",
            zIndex: "999",
            borderColor: Colors.BLUELIGHT,
            background: Colors.BLUEBACKGROUND
        }
    },
    drawerHeader: {
        display: "flex",
        alignItems: 'center',
        padding: '16px 16px 4px 16px',
        backgroundColor: Colors.WHITE,
        '& h2': {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            lineHeight: '24px',
            margin: '0 auto'
        }
    },
    taskCompleted: {
        display: "inline-flex",
        alignItems: 'center',
        '& svg': {
            marginRight: '4px'
        }
    },
    deleteTask: {
        marginLeft: 'auto',
        cursor: 'pointer',
        marginRight: "16px"
    },
    notificationOff: {
        cursor: 'pointer',
        marginRight: "16px"
    },
    closeIcon: {
        cursor: 'pointer'
    },
    drawerInfo: {
        padding: "0 8px",
        backgroundColor: Colors.WHITE,
        '& svg': {
            height: '20px',
            width: '20px',
        }
    },
    taskForm: {
        '& .MuiFormHelperText-contained': {
            marginLeft: '8px'
        }
    },
    padding: {
        paddingTop: '8px',
    },
    activityList: {
        paddingTop: '8px',
        borderTop: '1px solid' + Colors.BLUELIGHT,
        margin: '0 -8px',
        background: Colors.BLUEBACKGROUND
    },
    showPreviousChanges: {
        textAlign: 'center',
        '& span': {
            color: Colors.BRANDSECONDARY,
            cursor: 'pointer',
            lineHeight: '24px'
        }
    },
    attach: {
        margin: '12px 8px 16px'
    },
    attachIcon : {
        display: "flex"
    },
    selectedMediaWrapper: {
        minWidth: '80px',
        width: '80px',
        height: '80px',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '8px',
        '& img': {
            width: '80px',
            height: '80px',
            objectFit: 'cover'
        }
    },
    selectedDocumentWrapper: {
        width: '100%',
        height: '64px',
        borderRadius: '8px',
        position: 'relative',
        margin: '0',
        border: '1px solid' + Colors.BLUELIGHT,
        display: 'flex',
        alignItems: 'center',
        padding: '16px 12px',
        marginTop: '12px'
    },
    fileIcon: {
        height: '32px !important',
        width: '32px !important',
        minWidth: '32px',
        fill: Colors.BRANDPRIMARY,
        marginRight: '12px'
    },
    selectedDocument: {
        width: '100%',
        overflow: 'hidden',
        '& h4': {
            fontWeight: '500',
            textTransform: 'none',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
        '& h6': {
            lineHeight: '13px'
        }
    },
    documentMediaWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 8px'
    },
    fileInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    allMediaWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '608px',
        overflowY: 'auto',
        marginTop: '16px',
        minHeight: '0',
        padding: '0 8px'
    },
    deleteMediaIcon: {
        position: 'absolute',
        height: '24px',
        width: '24px',
        borderRadius: '25px',
        backgroundColor: Colors.BRANDPRIMARY,
        top: '4px',
        right: '4px',
        cursor: 'pointer',
        color: Colors.WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    deleteMediaIconDocument: {
        top: 'calc(50% - 12px)',
        right: '8px'
    }
}));

/**
 *
 * @param {String} taskId
 * @param {Task} task
 * @param {boolean} open
 * @param {Function} displayTaskActivity
 * @param {Function} deleteTask
 * @param {Function} markComplete
 * @param {Function} onClose
 * @param {Function} onTitleChange
 * @param {Function} onDescriptionChange
 * @param {Function} onRepetitionChange
 * @param {Function} setDueDate
 * @param {Function} onAssign
 * @param {Function} removeAssignee
 * @param {Function} onAddImages
 * @param {Function} onAddDocuments
 * @param {Function} onRemoveDocument
 * @param {Function} onRemoveImage
 * @param {Function} onAddNotification
 * @param {Function} onRemoveNotification
 * @param {Array<User>} users
 * @constructor
 */
const TaskInfo = ({ taskId, task, displayTaskActivity,  deleteTask, markComplete, onTitleChange, onDescriptionChange, onRepetitionChange, setDueDate, onAssign, removeAssignee, onAddImages, onAddDocuments, onRemoveImage, onRemoveDocument, onClose, users, onAddNotification, onRemoveNotification, rebuildList}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    let lastChange = useRef(0);
    let editTask = useRef(Object.assign(new Task(),task));

    let assignedUser = users.find( (user) => user.id === editTask.current.aUid);
    const [search, setSearch] = useState(assignedUser ? assignedUser.fullName: "");
    const [showPrevious, setShowPrevious] = useState(false);
    const [rebuild, setRebuild] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!task && taskId) {
            ModelManager.subscribeDoc({model: Task, id: taskId});
        } if (task) {
            editTask.current = Object.assign(new Task(),task);
            setShowPrevious(false);
            setErrors({});

            if (task.aUid) {
                let assignedUser = users.find( (user) => user.id === task.aUid);
                setSearch(assignedUser ? assignedUser.fullName : '');
            } else {
                setSearch('');
            }
        }
    }, [task,taskId]);


    const validate = (task) => {
        let hasError = false;

        if (!task.title) {
            errors['title'] = t('form.errors.cantBeEmpty', {field: t('tasks.taskName')});
            hasError = true;
        } else if (task.title.length <= 2) {
            errors['title'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('tasks.taskName'), chars: 3});
            hasError = true;
        } else if (task.title.length > 200) {
            errors['title'] = t('form.errors.mustBeLessThanXCharacters', {field: t('tasks.taskName'), chars: 200});
            hasError = true;
        }
        setErrors(errors);
        return !hasError;
    }

    const acceptedImages = ['image/png', 'image/jpeg', 'image/gif'];
    const acceptedDocuments = ['application/pdf'];

    const delaySave = () => {
        let requestTime = Date.now();
        lastChange.current = requestTime;

        let clonedTask = Object.assign(Task,editTask.current);
        setTimeout(() => {
            if (requestTime === lastChange.current) {
                onSave(clonedTask);
            }
        }, 1500);
    }

    const onSave = async (taskToSave, closeAfter = false) => {
        if (validate(taskToSave)) {
            lastChange.current = Date.now();

            if (taskToSave.title !== task.title) {
                await onTitleChange(task, taskToSave.title);
            }

            if (taskToSave.description !== task.description) {
                await onDescriptionChange(task, taskToSave.description);
            }

            if (taskToSave.dueDate !== task.dueDate) {
                await setDueDate(task, !!taskToSave.dueDate?.toJSDate ? taskToSave.dueDate.toJSDate() : taskToSave.dueDate);
            }

            if (((!taskToSave.repetition || taskToSave.repetition.type === TaskRepetitionType.NEVER) && task.repetition) || (taskToSave.repetition && taskToSave.repetition.type !== TaskRepetitionType.NEVER && !task.repetition) || (taskToSave.repetition && task.repetition && !taskToSave.repetition.isSame(task.repetition))) {
                if (!taskToSave.repetition.isValid()) {
                    taskToSave.repetition = new TaskRepetition();
                    taskToSave.repetition.type = RepetitionType.NEVER;
                }

                taskToSave.completedRepetitions = [];
                await onRepetitionChange(task, taskToSave.repetition);
            }

            if (!taskToSave.aUid && task.aUid) {
                await removeAssignee(taskToSave, task.aUid);
            } else if (taskToSave.aUid !== task.aUid) {
                await onAssign(task, taskToSave.aUid);
            }

            if (closeAfter) {
                onClose();
            }
        }
    }

    const imageInputField = useRef();
    const documentInputField = useRef();

    let contextMenuOptions = [
        new MoinSimpleMenuOption( t('chat.image'), (event) => imageInputField.current.click()),
        new MoinSimpleMenuOption( t('chat.document'), (event) => documentInputField.current.click()),
    ];

    const renderSelectedImage = (image, index) => {
        if (image.includes('blob:')) {
            return <div key={"selected-image-" + index} className={classes.selectedMediaWrapper}>
                <img src={image} />
            </div>;
        }
        return (
            <div key={"selected-image-" + index} className={classes.selectedMediaWrapper}>
                <FireStorageImage src={image+ '_preview'} lightboxEnabled={true} lightboxSrc={image}/>
                <div className={classes.deleteMediaIcon} onClick={() => onRemoveImage(task,image)}>
                    <CloseIcon fill={Colors.WHITE}/>
                </div>
            </div>
        );
    };

    const renderSelectedDocument = (document, index) => {

        return (
            <div key={"selected-document-" + index} className={classes.selectedDocumentWrapper}>
                <div className={classes.selectedDocument}>
                    <DocumentPreview content={document}/>
                </div>
                <div className={`${classes.deleteMediaIcon} ${classes.deleteMediaIconDocument}`} onClick={() => onRemoveDocument(task,document)}>
                    <CloseIcon fill={Colors.WHITE} />
                </div>
            </div>

        );
    };

    let currentAssignee = null;

    const callDisplayTaskActivity = ({activity, task}) => {
        let assignee;
        if (activity.cUid) {
            assignee = users.find((user) => user.id === activity.cUid)
        }
        let displayAvatar = false;

        if (assignee !== currentAssignee) {
            currentAssignee = assignee;
            displayAvatar = true;
        }
        return displayTaskActivity({activity: activity, task: task, showAvatar: displayAvatar, taskInfo: true});
    }

    const dropFile = (files) => {
        let images = [];
        let documents = [];

        for (let i = 0; i < files.length; i++) {
            let elem = files[i];

            if (acceptedImages.includes(elem.type)) {
                images.push(elem);
            } else if (acceptedDocuments.includes(elem.type)) {
                documents.push(elem);
            }
        }
        onAddDocuments(task, documents).then( (_) => onAddImages(task, images));
    }

    const completeTask = (task) => {
        markComplete(task);

        setTimeout(() => {
            setRebuild((old) => !old);
            rebuildList();
        }, 6000);
    }

    return <Drawer
        className={classes.taskInfoDrawer}
        variant="persistent"
        anchor="right"
        open={true}>
            {
                !!task
                && <MoinUpload isMultipleInput={true} onChangeFile={files => dropFile(files)} type={MediaContentType.IMAGEORPDF} disableClick={true} allowMultiple={true}>
                    <div className={classes.drawerHeader}>
                        <div style={{cursor: 'pointer'}} onClick={() => completeTask(task)} className={classes.taskCompleted}>
                            {
                                task.getCompletedStatusForDate(new Date())
                                    ?  <>
                                        <CheckFilledIcon fill={Colors.ACTIVEGREEN}/>
                                        <Typography style={{color: Colors.ACTIVEGREEN}} variant={"caption"}>{ t('tasks.completed') }</Typography>
                                    </>
                                    : <>
                                        <CheckOutlineIcon fill={Colors.BLACKLIGHTER}/>
                                        <Typography style={{color: Colors.BLACKLIGHTER}} variant={"caption"}>{ t('tasks.markCompleted') }</Typography>
                                    </>
                            }
                        </div>
                        <DeleteIcon className={classes.deleteTask} onClick={() => deleteTask()} fill={Colors.BLACK}/>
                        {
                            task.relevant.includes(AUTHSTORAGE.getUserId()) && !task.noNot.includes(AUTHSTORAGE.getUserId())
                                ? <NotificationOffIcon className={classes.notificationOff} onClick={() => onRemoveNotification(editTask.current)} fill={Colors.BLACK}/>
                                : <NotificationInactiveIcon className={classes.notificationOff} onClick={() => onAddNotification(editTask.current)} fill={Colors.BLACK}/>
                        }
                        <CloseIcon className={classes.closeIcon} onClick={(e) => onSave(editTask.current,  true)} fill={Colors.BLACK}/>
                    </div>
                    <div className={classes.drawerInfo}>
                        <div className={classes.taskForm}>
                            <TaskForm
                                key={editTask.current.id}
                                title={editTask.current.title}
                                description={editTask.current.description}
                                dueDate={editTask.current.dueDate}
                                repetition={editTask.current.repetition}
                                assignee={assignedUser}
                                setTitle={(e) => {
                                    editTask.current.title = e;
                                    delaySave();
                                    setRebuild((old) => !old);
                                }}
                                setDescription={(e) => {
                                    editTask.current.description = e;
                                    delaySave();
                                    setRebuild((old) => !old);
                                }}
                                setDueDate={ (e) => {
                                    editTask.current.dueDate = e;
                                    onSave(editTask.current);
                                    setRebuild((old) => !old);
                                }}
                                setAssignee={(e) => {
                                    editTask.current.aUid = e?.id;
                                    onSave(editTask.current);
                                    setRebuild((old) => !old);
                                }}
                                setRepetition={(e) => {
                                    editTask.current.repetition = e;
                                    delaySave();
                                    setRebuild((old) => !old);
                                }}
                                search={search}
                                setSearch={setSearch}
                                isEdit={true}
                                errors={errors}
                            />

                            <div className={`${classes.allMediaWrapper}`}>
                                {
                                    task.images.map((image, index) => renderSelectedImage(image, index))
                                }
                            </div>

                            <div className={`${classes.documentMediaWrapper}`}>
                                {
                                    task.documents.map((document, index) => renderSelectedDocument(document, index))
                                }
                            </div>

                            <input ref={(fileInput) => imageInputField.current = fileInput} type="file" accept={acceptedImages.join(',')} style={{display: 'none'}} multiple onChange={(e) => onAddImages(task, [...e.target.files]) }/>
                            <input ref={(fileInput) => documentInputField.current = fileInput} type="file" accept={acceptedDocuments.join(',')} style={{display: 'none'}} multiple onChange={(e) =>  onAddDocuments(task, [...e.target.files]) }/>

                            <div className={classes.attach}>
                                <MoinSimpleMenu options={contextMenuOptions} className={classes.attachIcon}>
                                    <AttachFileIcon fill={Colors.BLACKLIGHT}/>
                                </MoinSimpleMenu>
                            </div>
                        </div>

                        <div className={classes.activityList}>
                            {
                                (task.activities.length > 5 && !showPrevious) &&
                                <div className={classes.showPreviousChanges}
                                     onClick={() => setShowPrevious(true)}>
                                    <Typography  variant={"caption"}>{ t('tasks.showPreviousChanges') }</Typography>
                                </div>
                            }
                            <div className={classes.padding}/>
                            {
                                task.activities.map( (taskActivity, key) =>
                                    <div key={'inbox-'+key}>
                                        {
                                            (key >= (task.activities.length - 6) || showPrevious) &&
                                            callDisplayTaskActivity({activity: taskActivity, task: task})
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </MoinUpload>
            }

    </Drawer>
}

const mapState = (state, ownProps) => ({
    task: getModelInstance(state, Task, ownProps.taskId)
});

export default connect(mapState) (TaskInfo);
