import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {MoinCard} from "../base/MoinCard";
import FireStorageImage from "../base/FirestoreImage";
import {getEventAvatarPath} from "../../utils/helper/ImageHelper";
import Typography from "@material-ui/core/Typography";
import MoinListItem from "../base/MoinListItem";
import {Colors} from "../helper/ColorHelper";
import * as ROUTES from "../../Routes";
import {Redirect} from "react-router-dom";
import CalendarIcon from "../../assets/icons/calendar-2.svg";
import {isAtSameDay} from "../../utils/helper/DateHelper";
import {
    getdFormattedDate,
    getdMMMMyyyyFormattedDate
} from "../../utils/helper/TextHelper";
import LocationIcon from "../../assets/icons/location.svg";

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-between',
        padding: '16px'
    },
    elements: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'space-between'
    },
    event: {
        padding: '0',
        alignItems: 'unset'
    },
    row: {
        display: 'flex',
        alignItems: 'center'
    },
    cardContent: {
        marginLeft: 54
    },
    icon: {
        marginRight: 4,
        height: 16,
        width: 16
    },
    locationRow: {
        marginTop: 2
    }
}));

const NewsEventCard = ({event, index}) => {
    const classes = useStyles();
    const [redirect, setRedirect] = useState(false);

    if(redirect) {
        return <Redirect push to={ROUTES.eventShow.replace(':id', event.id)}/>;
    }

    const renderEventDate = () => {
        if(!event.end || isAtSameDay(event.end, event.start)) {
            return getdMMMMyyyyFormattedDate(event.start);
        }

        return <>{ getdFormattedDate(event.start) } - { getdMMMMyyyyFormattedDate(event.end) }</>;
    }

    return (
        <MoinCard hasRoundedBorders={true} className={classes.card}>
            <div className={classes.elements}>
                <MoinListItem
                    customClass={classes.event}
                    withSplashEffect={false}
                    withDivider={false}
                    image={<FireStorageImage backgroundColor={Colors.identifierAsColor(event.id)} radius={24} isAvatar={true} src={getEventAvatarPath(event.id)} alt={event.title}/>}
                    title={event.title}
                    onClick={() => setRedirect(true)}
                >
                    <div className={classes.cardContent}>
                        <div className={classes.row}>
                            <CalendarIcon fill={Colors.BLACKLIGHT} className={classes.icon}/>
                            <Typography variant="caption">{ renderEventDate() }</Typography>
                        </div>

                        {
                            !!event.location && event.location !== "" &&
                                <div className={`${classes.row} ${classes.locationRow}`}>
                                    <LocationIcon fill={Colors.BLACKLIGHT} className={classes.icon}/>
                                    <Typography variant="caption">{ event.location }</Typography>
                                </div>
                        }
                    </div>
                </MoinListItem>
            </div>
        </MoinCard>
    );
};

export default NewsEventCard;
