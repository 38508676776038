import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Message from "../../utils/models/Message";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import ModelManager from "../../utils/manager/ModelManager";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {Redirect} from "react-router-dom";
import User from "../../utils/models/User";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import {getChatAvatarPath} from "../../utils/helper/ImageHelper";
import * as ROUTES from "../../Routes";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import {
    addModelInstance,
    deleteModelInstanceDispatch, updateModelInstance,
} from "../../utils/store/actions";
import Skeleton from "@material-ui/lab/Skeleton";
import Chat from "../../utils/models/Chat";
import ChatCreateEdit from "./ChatCreateEdit";
import {createSymmetricKey} from "../../utils/helper/EncryptionHelper";
import {
    addChatMembersData,
    changeChatMembersAllData, createChatNameChangedMessage,
    createRemoveMessageForChatMembers,
} from "../../utils/helper/ChatHelper";
import {sendChatNameChangedNotification} from "../../components/helper/NotificationHelper";

/**
 *
 * @param {Chat} chat
 * @param {String} chatId
 * @param {Array<String>} userIds
 * @param {Array<User>} users
 * @returns {JSX.Element}
 * @constructor
 */
const ChatCreateEditContainer = ({chatId, chat,users=[], deleteModelInstanceDispatch }) => {
    const [saving, setSaving] =  useState(false);
    const [redirect, setRedirect] =  useState(false);

    const onSave = ({name, image, imageRemoved, selectedUserIds}) => {

        if (!saving) {
            setSaving(true);
            let selectedUsers = selectedUserIds.map( (id) => users.find( (user) => user.id === id) ).filter( (user) => !!user );
            let authUser = selectedUsers.find( (user) => user.id === AUTHSTORAGE.getUserId());

            if (!chat) {
                let newChat = Chat.from(
                    {
                        "name": name,
                        "cUid": AUTHSTORAGE.getUserId(),
                        "count": selectedUsers.length,
                        "uMC": 0
                    }
                );

                if (  image) {
                    newChat.imageOwner = AUTHSTORAGE.getUserId() ;
                }
                newChat.member = selectedUsers.map( (user) => user);
                newChat.sample = selectedUsers.slice(0, 5 <= selectedUsers.length ? 5 : selectedUsers.length).map( (user) => user.getAsMinimalUser());
                newChat.symKey = createSymmetricKey({length: 32});

                ModelManager.add({modelInstance: newChat, stopEvents: true}).then(async (result) => {
                    addModelInstance(Chat, newChat);
                    await changeChatMembersAllData(newChat);
                    await addChatMembersData(newChat, authUser);
                    setRedirect(newChat.id);

                    let avatarUpload = getChatAvatarPath(AUTHSTORAGE.getUserId(), result.id);
                    if (!!image) {
                        await new FirebaseStorageManager().uploadImage(image, avatarUpload , 2048 , 1);
                        let fireStorageUrl = new FireStorageUrl();
                        fireStorageUrl.id = avatarUpload;
                        deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
                    }
                });
            } else {
                let oldUsers = [];
                let newUsers = [];

                chat.member.forEach( (member) => {
                    if (!selectedUserIds.includes(member.uid)) {
                        oldUsers.push(member);
                    }
                });
                selectedUsers.forEach( (user) => {
                    if (!chat.member.find( (currentUser) => currentUser.uid === user.uid )) {
                        newUsers.push(user);
                    }
                });

                let oldName = chat.name;
                chat.name = name;
                chat.sample = selectedUsers.slice(0, 5 <= selectedUsers.length ? 5 : selectedUsers.length).map( (user) => user.getAsMinimalUser());
                chat.count = selectedUsers.length;
                if ( imageRemoved) {
                    chat.imageOwner =undefined
                } else if (image) {
                    chat.imageOwner = AUTHSTORAGE.getUserId() ;
                }

                ModelManager.update({modelInstance: chat, stopEvents: true}).then(async (result) => {
                    if (oldUsers && oldUsers.length > 0) {
                        await createRemoveMessageForChatMembers(chat, authUser, oldUsers);
                    }

                    /* we can only update the current members after the removed messages for the old ones was sent */
                    chat.member = selectedUsers.map( (user) => user);
                    updateModelInstance(Chat, chat);
                    await changeChatMembersAllData(chat);

                    if (chat.name.trim() !== oldName.trim()) {
                        await createChatNameChangedMessage(chat, authUser);
                        sendChatNameChangedNotification(chat, oldName.trim(), authUser.fullName);
                    }

                    if (newUsers && newUsers.length > 0) {
                        await addChatMembersData(chat, authUser, newUsers);
                    }
                    setRedirect(chat.id);

                    let avatarUpload = getChatAvatarPath(AUTHSTORAGE.getUserId(), result.id);
                    if (!!image) {
                        await new FirebaseStorageManager().uploadImage(image, avatarUpload , 2048 , 1);
                        let fireStorageUrl = new FireStorageUrl();
                        fireStorageUrl.id = avatarUpload;
                        deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
                    } else if (imageRemoved){
                        await new FirebaseStorageManager().deleteFile(avatarUpload);
                    }
                });
            }

        }

    }

    let userIds = [];

    if (chat) {
        userIds = chat.member.map( (user) => user.uid);
    }

    if (userIds.length > 0 && userIds[0] !== AUTHSTORAGE.getUserId()) {
        userIds = userIds.filter( (user) => user !== AUTHSTORAGE.getUserId())
        userIds.splice(0, 0, AUTHSTORAGE.getUserId());
    } else if (userIds.length === 0){
        userIds = [AUTHSTORAGE.getUserId()];
    }

    if(redirect && typeof redirect=== "string")
        return <Redirect push to={ROUTES.messagesShow.replace(':id', redirect)} />;

    if (chatId && !chat)
        return <Skeleton variant="rect" width="100%" height="100%" />

    return <ChatCreateEdit key={chat ? chat.id : 'newDistributor'} chat={chat} userIds={userIds} saving={saving} onSave={onSave}/>
};

const mapState= (state, ownProps) => (
     {
        chatId: ownProps.chatId,
        users: getModelInstances(state, User),
        chat: !!ownProps.chatId
            ? getModelInstance(state, Chat, ownProps.chatId)
            : undefined,
    }
);


const mapAction = {
    deleteModelInstanceDispatch,
};

export default connect(mapState,mapAction)(ChatCreateEditContainer);
