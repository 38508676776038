import React, {useEffect} from 'react';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MoinLogo from "../assets/logo/moin-logo.svg";

import MasterNav from "./MasterNav";
import MasterRouting from "./MasterRouting";
import MasterAppBar from "./MasterAppBar";
import CustomNotification from "../components/notifications/CustomNotification";
import {useHistory, withRouter} from "react-router-dom";
import * as ROUTES from "../Routes";
import SuccessNotification from "../components/success/SuccessNotification";
import FireStorageImage from "../components/base/FirestoreImage";
import MASTERTHEME from "./MasterTheme";


const MasterLayout = ({client, logout}) => {
    const history = useHistory();
    const classes = MASTERTHEME.getStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <>
            <CustomNotification />
            <SuccessNotification />
            <div className={classes.root}  >
                <CssBaseline />
                <MasterAppBar/>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={true}
                    classes={{
                        paper: classes.drawer,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        {
                            !!client && client.hTL
                                ?  <FireStorageImage src={client.getTopBarLogo()} backgroundColor={"transparent"} className={classes.headerLogo} onClick={() => history.push(ROUTES.home)} />
                                :  <MoinLogo className={classes.headerLogo} onClick={() => history.push(ROUTES.home)} />
                        }
                    </div>
                    <Divider/>
                    <MasterNav logout={logout} />
                </Drawer>
                <MasterRouting/>
            </div>
        </>
    );
};


export default  withRouter(MasterLayout);
