import AuthStorage from "../../utils/auth/AuthStorage";
import MoinSimpleMenuOption from "../base/MoinSimpleMenuOption";
import {Permission} from "../../utils/enums/Permission";
import {useTranslation} from "react-i18next";
import MoinSimpleMenu from "../base/MoinSimpleMenu";
import React, {useState} from "react";
import GroupMembershipContainer from "../../pages/groups/GroupMembershipContainer";
import parameters from "../../config/parameters.json";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Role} from "../../utils/enums/Role";
import HIGHLIGHTEDGROUPS from "../../utils/groups/HighlightedGroups";

const GroupMenu = ({distributor, onLeave, onDelete, onHighlight}) => {
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [redirectToResponsible, setRedirectToResponsible] = useState(false);
    const [t] = useTranslation();

    let contextMenuOptions = [];

    let highlightText = HIGHLIGHTEDGROUPS.isHighlighted(distributor.id) ? t('distributor.highlight.removeHighlight') : t('distributor.highlight.highlightGroup');

    if (distributor.id === parameters.globalDistributorId) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('distributor.edit'), (event) => {setRedirectToEdit(true);})); // setRedirectToEdit(true)));
        if(AUTHSTORAGE.hasRole(Role.ADMIN) || AUTHSTORAGE.hasRole(Role.CLIENTMANAGER)) {
            contextMenuOptions.push(new MoinSimpleMenuOption( highlightText, (event) => {onHighlight();}));
        }
    } else {
        if(AUTHSTORAGE.hasRole(Role.ADMIN) || AUTHSTORAGE.hasRole(Role.CLIENTMANAGER)) {
            contextMenuOptions.push(new MoinSimpleMenuOption( highlightText, (event) => {onHighlight();}));
        }

        if(distributor.cUid === AuthStorage.getUserId()) {
            contextMenuOptions.push(new MoinSimpleMenuOption( t('distributor.edit'), (event) => {setRedirectToEdit(true);})); // setRedirectToEdit(true)));
            contextMenuOptions.push(new MoinSimpleMenuOption( t('distributor.changeResponsible'), (event) => {setRedirectToResponsible(true);})); // setRedirectToEdit(true)));
        }
        if(distributor.isAuthUserMember) {
            contextMenuOptions.push(new MoinSimpleMenuOption( t('distributor.leave'), (event) => {onLeave()})); // setRedirectToEdit(true)));
        }

        if(AuthStorage.hasPermission(Permission.GROUPDELETE) || distributor.cUid === AuthStorage.getUserId()) {
            contextMenuOptions.push(new MoinSimpleMenuOption( t('distributor.delete'), (event) => {onDelete()})); // setRedirectToEdit(true)));
        }
    }

    if(redirectToEdit) {
        return <Redirect push to={ROUTES.groupEdit.replace(":id", distributor.id)}/>
    }

    if(redirectToResponsible) {
        return <Redirect push to={ROUTES.groupResponsible.replace(":id", distributor.id)}/>
    }

    return contextMenuOptions.length > 0
        ?  <MoinSimpleMenu noWidth={true} options={contextMenuOptions}/>
        : <></>
}

/**
 *
 * @param {Distributor} distributor
 * @returns {JSX.Element}
 * @constructor
 */
const GroupMenuWrapper = ({distributor}) => {

    if (distributor.id === parameters.globalDistributorId && !AuthStorage.hasPermission(Permission.GLOBALGROUPTITLEIMAGEEDIT)) {
        return <></>;
    }

    return (
        <GroupMembershipContainer distributorId={distributor.id}  key={"distributor-membership-container-" + distributor.id}>
            {
                ({onRequest, onRequestRemove, onLeave, onDelete, toggleGroupHighlight}) => <GroupMenu distributor={distributor} onLeave={onLeave} onDelete={onDelete} onHighlight={toggleGroupHighlight}/>
            }
        </GroupMembershipContainer>
    )
}

export  default GroupMenuWrapper;
