import React, {useEffect, useState} from 'react';

import {useTranslation} from "react-i18next";
import {MoinCard, MoinCardContent, MoinCardContentTitle, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import MoinImageUplaod from "../../components/base/MoinImageUpload";
import MoinTextField from "../../components/base/MoinTextField";
import MoinSelect from "../../components/base/MoinSelect";
import MenuItem from "@material-ui/core/MenuItem";
import MoinButton from "../../components/base/MoinButton";
import {makeStyles} from "@material-ui/core/styles";
import {PrivacyLevel} from "../../utils/enums/PrivacyLevel";
import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import MoinSearchField from "../../components/base/MoinSearchField";
import {FireStorage} from "../../api/FirebaseService";
import MoinDivider from "../../components/base/MoinDivider";


const useStyles = makeStyles(() => ({
    content: {
        padding: '16px',
        width: '100%'
    },
    divider: {
        margin: '0'
    },
}));

/**
 *
 * @param {Distributor} distributor
 * @param {Array<String>} userIds
 * @param {boolean} saving
 * @param {Function} onSave
 * @returns {JSX.Element}
 * @constructor
 */
const GroupCreateEditContainer = ({distributor, saving, onSave}) => {
    const classes = useStyles();

    const [t] = useTranslation();

    const [search, setSearch] = useState();
    const [selectedUser, setSelectedUser] = useState(undefined);

    const validate = ()  => {
        if (!saving && selectedUser) {
          onSave(selectedUser)
        }
    }

    const onUserSelection = (user) => {
        if (selectedUser && selectedUser.id === user.id) {
            setSelectedUser(undefined);
        } else {
            setSelectedUser(user);
        }
    }

    return (
        <MoinCard>
            <MoinCardHeader>
                <Typography variant="h3">
                    {
                       t('distributor.changeResponsible')
                    }
                </Typography>
            </MoinCardHeader>
            <MoinCardContent>
                <div className={classes.content}>
                    <Typography variant={'h2'}>{t('distributor.contacts').toUpperCase()}</Typography>

                    <MoinSearchField
                        onChange={e => setSearch(e.target.value)}
                        placeholder={t('distributor.placeholder.searchUsers')}
                        style={{width: '100%'}}
                        onClear={e => setSearch('')}
                    />

                    <SearchableSelectableUserList search={search} placeholder={t('distributor.selectResponsible')} selectedUsers={selectedUser ? [selectedUser.id] : []} onSelect={onUserSelection}/>

                </div>
                <MoinDivider />
            </MoinCardContent>
            <MoinCardHeader>
                <MoinButton disabled={saving} onClick={ validate}>{t('common.saveChanges')}</MoinButton>
            </MoinCardHeader>
        </MoinCard>
    );
};

export default GroupCreateEditContainer;