import {CardActions, Typography} from "@material-ui/core";
import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import * as ROUTES from "../../Routes";

import {useTranslation} from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import CameraIcon from "../../assets/icons/camera.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";

import {useHistory,withRouter} from "react-router-dom";
import {Colors} from "../../components/helper/ColorHelper";
import MoinButton from "../../components/base/MoinButton";
import FireStorageImage from "../../components/base/FirestoreImage";
import MoinUpload from "../../components/base/MoinUpload";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import MoinSimpleMenuOption from "../../components/base/MoinSimpleMenuOption";
import {Permission} from "../../utils/enums/Permission";
import MoinListItem from "../../components/base/MoinListItem";
import {formatCommentDate} from "../../utils/helper/TextHelper";
import MoinSimpleMenu from "../../components/base/MoinSimpleMenu";
import MoinLinkifiedText from "../../components/base/MoinLinkifiedText";
import {getUserAvatarPath} from "../../utils/helper/ImageHelper";
import ReactionRowContainer from "../../components/article/ReactionRowContainer";
import CommentAnswerReaction from "../../utils/models/CommentAnswerReaction";
import MentionTextField from "../../components/mention/MentionTextField";
import MentionAutocomplete from "../../components/mention/MentionAutocomplete";

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerRight: {
        marginLeft: theme.spacing(1)
    },
    commentContent: {
        marginLeft: '56px',
        marginBottom: '12px',
        marginRight: '16px',
    },
    commentImage: {
        width: '100%',
        borderRadius: '6px',
        objectFit: "contain",
        maxHeight: "400px"
    },
    commentImageWrapper: {
        marginTop: theme.spacing(1),
        position: 'relative'
    },
    editImageWrapper: {
        borderRadius: "16px",
        marginTop: "12px",
        width: '100%',
        overflow: 'hidden',
        maxHeight: '400px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.WHITE
    },
    commentPadding: {
        paddingLeft: "16px",
        paddingRight: "16px"
    },
    editCommentImage: {
        width: '100%',
        objectFit: 'contain'
    },
    selectImageInfo: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        marginTop: '25%'
    },
    whiteTextColor: {
        color: Colors.WHITE
    },
    cardActions: {
        margin: "0 16px 12px 56px"
    },
    editField: {
        margin: "0"
    },
    link: {
        color: Colors.BRANDSECONDARY,
        cursor: "pointer",
        textTransform: "none",
        textDecoration: "none",

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    },
    sendButton: {
        height: '38px',
        minHeight: '38px',
        width: '38px',
        minWidth: '38px',
        borderRadius: '38px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.WHITE,
        marginLeft: theme.spacing(1),
        cursor: 'pointer'
    },
    completeWrapper: {
        position: 'relative'
    }
}));

/**
 * @param {CommentAnswer} commentAnswer
 * @param {CommentAnswerReaction} commentAnswerReaction
 * @param {Function} deleteCommentAnswer
 * @param {Function} updateCommentAnswer
 * @constructor
 */
const RenderedCommentAnswer = ({commentAnswer,commentAnswerReaction, deleteCommentAnswer, updateCommentAnswer}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const history = useHistory();
    const textFieldRef = useRef();
    const wrapperDivRef = useRef();

    const [editTextContent, setEditTextContent] = useState();
    const [editImage, setEditImage] = useState();
    const [deletedImage, setDeletedImage] = useState(false);

    const onDeleteCommentAnswer = async () => {
        setDeleting(true);

        let finishedDeletion = await deleteCommentAnswer();
        if(finishedDeletion) {
            setShowDeleteDialog(false);
            setDeleting(false);
        }
    };

    const switchToEdit = () => {
        setEditTextContent(commentAnswer.text);
        if(!!commentAnswer.media) {
            setEditImage(commentAnswer.media[0]);
        }
        setDeletedImage(false);
        setIsEdit(true);
    };

    const getDeleteDialog = () => {
        return (
            <Dialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('article.comments.deleteCommentAnswer') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">{ t('article.comments.deleteCommentAnswerDescription') }</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={() => setShowDeleteDialog(false)} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    {
                        deleting &&
                        <div className="moin-circular-progress-container">
                            <CircularProgress />
                        </div>
                    }
                    <MoinButton onClick={() => onDeleteCommentAnswer()}>{ t('buttons.delete') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    };

    const removeImage = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditImage(null);
        setDeletedImage(true);
        return false;
    };

    const getEditImage = () => {
        if(!!editImage?.name) {
            return (
                <div className={classes.editImageWrapper}>
                    <img src={URL.createObjectURL(editImage)} className={classes.editCommentImage}/>
                    <div className={classes.selectImageInfo}>
                        <CameraIcon fill={Colors.WHITE} />
                        <Typography variant="body1" className={classes.whiteTextColor}>{ t('article.edit.editImage') }</Typography>
                    </div>
                </div>
            );
        } else if(!!editImage) {
            return (
                <div className={classes.editImageWrapper}>
                    <FireStorageImage lightboxEnabled={true} src={commentAnswer.media[0]} className={classes.editCommentImage}/>
                    <div className={classes.selectImageInfo}>
                        <CameraIcon fill={Colors.WHITE} />
                        <Typography variant="body1" className={classes.whiteTextColor}>{ t('article.edit.editImage') }</Typography>
                    </div>
                </div>
            );
        }

        return <Typography variant="body2" className={classes.link}>+ { t('article.edit.addImage') }</Typography>;
    };

    const getImage = () => {
        if(isEdit) {
            return (
                <>
                    <MoinUpload onChangeFile={files => {
                        setEditImage(files[0]);
                        setDeletedImage(false);
                    }}>
                        { getEditImage() }
                    </MoinUpload>

                    {
                        !!editImage &&
                            <Typography variant="body2" className={classes.link} onClick={removeImage}>{ t('article.comments.removeImage') }</Typography>
                    }
                </>
            );
        } else if(!!commentAnswer.media) {
            return (
                <div className={classes.commentImageWrapper}>
                    <FireStorageImage lightboxEnabled={true} src={commentAnswer.media[0]} className={classes.commentImage}/>
                </div>
            );
        }
    };

    const onSave = async () => {
        await updateCommentAnswer(editTextContent, editImage, deletedImage);
        setIsEdit(false);
    };

    const contextMenuOptions = [];

    if(!!commentAnswer && commentAnswer.cUid === AUTHSTORAGE.getUserId()) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.edit'), () => switchToEdit()));
    }

    if(!!commentAnswer && commentAnswer.cUid === AUTHSTORAGE.getUserId() || AUTHSTORAGE.hasPermission(Permission.COMMENTDELETE)) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.delete'), () => setShowDeleteDialog(true)));
    }

    return (
        <div className={classes.completeWrapper} ref={(wrapperDiv) => wrapperDivRef.current = wrapperDiv}>
            {
                getDeleteDialog()
            }
            {
                isEdit &&
                <MentionAutocomplete
                    textFieldRef={textFieldRef}
                    textValue={editTextContent}
                    replaceText={(text) => setEditTextContent(text)}
                    parent={wrapperDivRef.current}
                />
            }
            <MoinListItem
                image={<FireStorageImage
                    backgroundColor={Colors.identifierAsColor(commentAnswer.cUid)}
                    isAvatar={true}
                    radius={16}
                    src={getUserAvatarPath(commentAnswer.cUid)}
                    alt={commentAnswer.cName}/>
                }
                title={ commentAnswer.cName }
                subtitle={ formatCommentDate(t, commentAnswer.cAt)}
                withDivider={false}
                withSplashEffect={false}
                customClass={classes.commentPadding}
                trailing={
                    contextMenuOptions.length > 0 &&
                        <MoinSimpleMenu options={contextMenuOptions}/>
                }
                onClick={
                    () => history.push(ROUTES.userShow.replace(':id', commentAnswer.cUid))
                }
            />

            <div className={classes.commentContent}>
                {
                    isEdit ?
                        <div className={classes.row}>
                            <MentionTextField
                                textFieldRef={textFieldRef}
                                value={editTextContent}
                                rowsMax={5}
                                rows={1}
                                onChange={(text) => setEditTextContent(text)}
                                className={classes.editField}
                            />
                            <div className={classes.sendButton} style={{backgroundColor: (!!editTextContent || !!editImage) ? Colors.BRANDSECONDARY : Colors.BLACKLIGHTER}} onClick={() => onSave()}>
                                <ArrowRightIcon fill={Colors.WHITE} />
                            </div>
                        </div>
                    :
                        <Typography variant="body2"><MoinLinkifiedText text={ commentAnswer.text } /></Typography>
                }
                {
                    getImage()
                }
            </div>

            <div className={classes.cardActions}>
                <ReactionRowContainer
                    model={commentAnswer}
                    modelPath={"comment_answer"}
                    reactionModel={CommentAnswerReaction}
                    isComment={true}
                    reactionCount={commentAnswer.heart}
                    showCommentButtons={false}
                    showCommentText={false}
                    allReactions={commentAnswer.reactions}
                    onLikeCountTap={() => history.push(ROUTES.commentAnswerLikeList.replace(":id", commentAnswer.id))}
                    showLikeButton={true}
                />
            </div>
        </div>
    );
};

export default withRouter(RenderedCommentAnswer);