export const StorageFileType = {
    FOLDER: "FOLDER",
    IMAGE: "IMAGE",
    VIDEO: "VIDEO",
    WORD: "WORD",
    EXCEL: "EXCEL",
    PDF: "PDF",
    POWERPOINT: "POWERPOINT",
    ZIP: "ZIP",
    OTHER: "OTHER",
};


export const extToStorageFileType = (ext) => {

    switch (ext) {
        case "pdf":
            return StorageFileType.PDF;
        case "doc":
        case "docx":
            return StorageFileType.WORD;
        case "jpg":
        case "png":
        case "jpeg":
        case "gif":
            return StorageFileType.IMAGE;
        case "mp4":
        case "mov":
            return StorageFileType.VIDEO;
        case "pp":
        case "pptx":
            return StorageFileType.POWERPOINT;
        case "xls":
        case "xlsx":
            return StorageFileType.EXCEL;
        case "rar":
        case "zip":
            return StorageFileType.ZIP;
        default:
            return StorageFileType.OTHER;
    }

}