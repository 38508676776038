import {connect} from "react-redux";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import Article from "../../utils/models/Article";
import React, {useEffect, useState} from "react";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {ReactionType} from "../../utils/enums/ReactionType";
import ReactionList from "../article/ReactionList";
import {updateModelInstance} from "../../utils/store/actions";
import User from "../../utils/models/User";
import Comment from "../../utils/models/Comment";

/**
 * @param {string} id
 * @param {Comment} comment
 * @param {User[]} users
 * @constructor
 */
const CommentReactionListContainer = ({id, comment, users}) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!comment) {
            ModelManager.get({model: Comment, id: id}).then(comment => loadCommentReactions(comment));
        } else if(!comment.allReactions || comment.reactions.length === 0 || (comment.reactions.length === 1 && !!comment.reactions[AUTHSTORAGE.getUserId()])) {
            loadCommentReactions(comment);
        }
    }, []);

    const loadCommentReactions = async (comment) =>  {
        setLoading(true);
        let heartDocs = await ModelManager.list({
            resource: 'client/' + AUTHSTORAGE.getClientId() + '/comment/' + comment.id + '/heart/'
        })

        let reactions = {};

        Object.keys(heartDocs).forEach((key)  => {
            if (key === "_all") {
                let allData = heartDocs[key];
                for(let userId of Object.keys(allData)) {
                    if((typeof allData[userId]) === 'string') {
                        reactions[userId] = allData[userId];
                    } else {
                        reactions[userId] = ReactionType.LIKE;
                    }
                }
            } else {
                reactions[key] = ReactionType.LIKE;
            }
        });

        comment.allReactions = reactions;
        updateModelInstance(Comment, comment);

        setLoading(false);
    }

    return <ReactionList allReactions={comment?.allReactions} users={users} loading={loading}/>;
}

const mapState = (state, ownProps) => ({
    comment: getModelInstance(state, Comment, ownProps.id),
    users: getModelInstances(state, User)
});

export default connect(mapState)(CommentReactionListContainer);
