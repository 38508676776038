import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import GroupMemberContainer from "./GroupMemberContainer";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import ModelManager from "../../utils/manager/ModelManager";
import ApiClient from "../../api/ApiClient";
import Distributor from "../../utils/models/Distributor";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {Redirect} from "react-router-dom";
import User from "../../utils/models/User";
import * as ROUTES from "../../Routes";
import {deleteModelInstanceDispatch, updateModelInstance} from "../../utils/store/actions";
import GroupResponsible from "./GroupResponsible";

/**
 *
 * @param {Distributor} distributor
 * @param {Array<String>} userIds
 * @param {Array<User>} users
 * @returns {JSX.Element}
 * @constructor
 */
const GroupResponsibleContainer = ({distributor,userIds= []}) => {
    const [saving, setSaving] =  useState(false);
    const [redirect, setRedirect] =  useState(false);

    const onSave = (user) => {

        if (!!user && !saving && distributor.cUid === AUTHSTORAGE.getUserId()) {

            if (user.id !== distributor.cUid) {
                if (!userIds.includes(user.id)) {
                    distributor.count += 1;
                    if (distributor.sample.length < 5) {
                        distributor.sample.push(user.getAsMinimalUser());
                    }
                    linkUser(user.id);
                }
                distributor.cUid = user.id;
                distributor.isAuthUserMember = true;
                updateModelInstance(Distributor,distributor);

                ModelManager.update({modelInstance: distributor}).then(async () => {
                    setRedirect(true);
                });
            }
        }
    };

    const linkUser = async (userId) => {
        let body = {};
        body['distributorId'] = distributor.id;
        body['distributorName'] = distributor.name;
        body['userIds'] = JSON.stringify([userId]);

        const request = await ApiClient.createRequest('/linkUsersToDistributor', body);
        await request.send();
    }


    if(redirect)
        return <Redirect push to={ROUTES.groups} />;

    return <GroupResponsible key={distributor.id} distributor={distributor} saving={saving} onSave={onSave}/>
};

const mapState= (state, ownProps) => (
     {
        distributorId: ownProps.distributorId,
        distributor: !!ownProps.distributorId
            ? getModelInstance(state, Distributor, ownProps.distributorId)
            : undefined,
    }
);


const GroupResponsibleContainerState = connect(mapState)(GroupResponsibleContainer)

/**
 *
 * @param {String }distributorId
 * @returns {JSX.Element}
 * @constructor
 */
const GroupResponsibleContainerWrapper = ({distributorId}) => {

    if (!!distributorId) {
        return (
            <GroupMemberContainer  distributorId={distributorId}>
                {
                    ({userIds}) => <GroupResponsibleContainerState distributorId={distributorId} userIds={userIds}/>
                }
            </GroupMemberContainer>
        )
    }
    return (
        <></>
    )
}
export default GroupResponsibleContainerWrapper;