import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Colors} from "../helper/ColorHelper";
import {Link} from "react-router-dom";
import * as ROUTES from "../../Routes";
import {CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        color: Colors.BRANDSECONDARY,
        textTransform: "none",
        textDecoration: "none",

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    },
}));


const MoinReadMore =  ({to, onClick}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();

    return (
        <Typography variant="body2" className={classes.root}>
            <Link to={to} onClick={onClick}>{t('article.readMore')}</Link>
        </Typography>
    )
};

export default MoinReadMore;
