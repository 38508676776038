import MoinTextField from "../base/MoinTextField";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import DeleteOutlineIcon from '../../assets/icons/delete.svg';
import {Colors} from "../helper/ColorHelper";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: theme.spacing(4)
    },
    contentOptions: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    deleteIcon: {
        cursor: 'pointer',
        fill: Colors.BLACK
    }
}));

/**
 * @param {Function} onDelete
 * @param {Function} onChange
 * @param {string} value
 * @returns {JSX.Element}
 * @constructor
 */
const TextContentRow = ({onDelete, onChange, value}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();

    return(
        <div className={classes.wrapper}>
            <div className={classes.contentOptions}>
                <div className={classes.row}>
                    <Typography variant="h3">{t('article.edit.textSection')}</Typography>
                    <DeleteOutlineIcon className={classes.deleteIcon} onClick={onDelete}/>
                </div>
                <div>
                {/* TODO: change Order Buttons */}
                </div>
            </div>
            <MoinTextField
                onChange={(e) => onChange(e.target.value)}
                placeholder={ t('article.edit.enterAText') }
                value={value}
                multiline={true}
                rowsMax={10}
            />
        </div>
    )
}

export default TextContentRow;
