class MoinSimpleMenuOption
{
    label;
    icon;
    action;

    constructor(label, action, icon ) {
        this.label = label;
        this.action = action;
        this.icon = icon;
    }
}

export default MoinSimpleMenuOption;
