import React, {useRef, useState, Fragment, useEffect} from 'react';
import MoinSimpleMenu from "./MoinSimpleMenu";
import FireStorageImage from "./FirestoreImage";
import {useTranslation} from "react-i18next";
import MoinSimpleMenuOption from "./MoinSimpleMenuOption";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";

import CameraIcon from "../../assets/icons/camera.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import ImageUploadIcon from "../../assets/icons/image-add.svg";
import ZoomOutIcon from "../../assets/icons/search.svg";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    avatarWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '24px 0'
    },
    avatar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        overflow: 'hidden',
        position: 'relative'
    },
    titleWrapper: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        overflow: 'hidden',
        position: "relative"
    },
    titleImage: {
        width: '100%'
    },
    image: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: '19',
        objectFit: 'cover'
    },
    overlay: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.BRANDPRIMARY,
        opacity: '.3',
        position: 'absolute',
        zIndex: '20'
    },
    cameraIcon: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '21',
        '& svg': {
            height: '33%',
            width: '33%',
            color: Colors.WHITE,
            pointerEvents: 'none'
        }
    },
    hiddenUpload: {
        display: 'none'
    },
    pointer: {
        cursor: 'pointer'
    },
    showDropZone: {
        backgroundColor: Colors.BRANDPRIMARY,
        opacity: '.3'
    },
    invalidDropFile: {
        backgroundColor: Colors.ERROR + '!important',
        opacity: '.3' + '!important',
        cursor: 'not-allowed' + '!important',
        zIndex: '1000'
    }
}));

/**
 *
 * @param {File} avatarFile
 * @param {String} image
 * @param {number} radius
 * @param {boolean} fullWidth
 * @param {Function} onChangeCallback
 * @returns {JSX.Element}
 * @constructor
 */
const MoinImageUpload = ({ file, image, fullWidth=false,radius=64, onChangeCallback}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const [avatarFile, setAvatarFile] = useState(file);
    const [avatarRemoved, setAvatarRemoved] = useState(false);
    const [isDraggingInFile, setIsDraggingInFile] = useState(false);
    const [isDraggingInvalidFile, setIsDraggingInvalidFile] = useState(false);

    const imageRef = useRef();
    const dropZoneRef = useRef();

    useEffect(() => {
        let dropZone = dropZoneRef.current;
        if (!!dropZone) {
            dropZone.addEventListener('dragenter', handleDragIn);
            dropZone.addEventListener('dragleave', handleDragOut);
            dropZone.addEventListener('dragover', handleDrag);
            dropZone.addEventListener('drop', handleDrop);

            return () => {
                dropZone.removeEventListener('dragenter', handleDragIn);
                dropZone.removeEventListener('dragleave', handleDragOut);
                dropZone.removeEventListener('dragover', handleDrag);
                dropZone.removeEventListener('drop', handleDrop);
            }
        }

    });

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items && e.dataTransfer.items.length === 1 && e.dataTransfer.items[0].type.includes("image")) {
            setIsDraggingInFile(true);
        } else {
            setIsDraggingInvalidFile(true);
        }
    }
    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingInFile(false);
        setIsDraggingInvalidFile(false);
    }
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingInFile(false);
        setIsDraggingInvalidFile(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length === 1 && e.dataTransfer.files[0].type.includes("image")) {
            updateImage(e.dataTransfer.files[0]);
        }
    }

    let inputElement = '';
    const uploadClick = () => {
        inputElement.click();
        return false;
    };

    const onChangeInput = e => {
        if (e.target.files.length > 0) {
            updateImage(e.target.files[0]);
        }
    }

    const updateImage = image => {
        setAvatarFile(image);
        setAvatarRemoved(false);
        onChangeCallback(image);
    }

    const showFullscreenAvatar = () => {
        if (imageRef.current) {
            imageRef.current.click();
        }
    }

    const deleteAvatar = () => {
        setAvatarRemoved(true);
        onChangeCallback(undefined);
    }

    let menuOptions = [
        new MoinSimpleMenuOption(t('common.choosePhoto'), uploadClick, <ImageUploadIcon fill={Colors.BLACK} />)
    ];

    //do not show fullscreen option if there's no avatar
    if (!avatarRemoved && (!!avatarFile || !!image)) {
        menuOptions.unshift(new MoinSimpleMenuOption(t('common.showPhoto'), showFullscreenAvatar, <ZoomOutIcon fill={Colors.BLACK} />));
        menuOptions.push(new MoinSimpleMenuOption(t('common.deletePhoto'), deleteAvatar, <DeleteIcon fill={Colors.BLACK} />));
    }

    const imageElement = <Fragment>
        <div
            className={classes.overlay}
            style={
                isDraggingInFile
                    ? {backgroundColor: Colors.SUCCESS}
                    : (
                        isDraggingInvalidFile
                            ? {backgroundColor: Colors.ERROR}
                            : undefined
                    )
            }
        />
        <div className={classes.cameraIcon}><CameraIcon fill={Colors.WHITE}/></div>
        {
            !avatarRemoved &&
            <FireStorageImage
                className={fullWidth ? classes.image : undefined}
                createRef={imageRef}
                isAvatar={!fullWidth}
                variant={ fullWidth ? "square" : undefined}
                radius={radius}
                src={ avatarFile ? URL.createObjectURL(avatarFile) : image }
                lightboxEnabled={true}
            />
        }
    </Fragment>

    return (
        <div className={fullWidth ? classes.titleWrapper : classes.avatarWrapper} ref={dropZoneRef}>
            <div
                className={fullWidth ? classes.titleImage : classes.avatar}
                style={fullWidth ? {} : {height: radius*2 + 'px', width: radius*2 + 'px'}}
            >
                <input
                    ref={(fileInput) => inputElement = fileInput}
                    type="file"
                    accept={'image/*'}
                    style={{display: 'none'}}
                    onChange={onChangeInput}
                />
                {
                    menuOptions.length === 1
                        ? <div onClick={uploadClick} className={classes.pointer}>{imageElement}</div>
                        : <MoinSimpleMenu
                            options={menuOptions}
                            center={true}
                            className={isDraggingInFile ? classes.showDropZone : ""}
                        >
                            { imageElement }
                        </MoinSimpleMenu>
                }
            </div>
        </div>
    );
}

export default MoinImageUpload;