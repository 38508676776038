import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import MoinEventCard from "../../components/event/MoinEventCard";
import EventEmptyState from "./EventEmptyState";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Permission} from "../../utils/enums/Permission";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import MoinFab from "../../components/base/MoinFab";
import ModelManager from "../../utils/manager/ModelManager";
import EventMembership from "../../utils/models/EventMembership";
import {getModelInstance} from "../../utils/store/selectors";
import Event from "../../utils/models/Event";
import {connect} from "react-redux";
import {updateModelInstanceDispatch} from "../../utils/store/actions";
import {FireDelete, FireIncrement, FireTimestamp} from "../../api/FirebaseService";
import AUTHDISTRIBUTORMEMBERSHIP from "../../utils/auth/AuthDistributorMembership";
import parameters from "../../config/parameters.json";
import {Divider, Tab, Tabs} from "@material-ui/core";
import * as TranslationHelper from "../../components/helper/TranslationHelper";
import MoinButton from "../../components/base/MoinButton";
import {sortDepartments, sortUsersByFirstname, sortUsersByLastname} from "../../utils/helper/SortHelper";
import {EventFilter} from "../../utils/enums/EventFilter";
import {Colors} from "../../components/helper/ColorHelper";

const useStyles = makeStyles(() => ({
    cardWrapper: {
      marginBottom: "16px"
    },
    emptyState: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        "& h1": {
            marginBottom: "8px"
        }
    },
    illustration: {
        width: "50%",
        height: "auto",
        marginBottom: "12px"
    },
    pillowContainer : {
        margin: '12px 0'
    },
    activePillow: {
        backgroundColor: Colors.BRANDPRIMARY,
        borderColor: Colors.BRANDPRIMARY,
        color: Colors.WHITE,
        marginRight: "8px"
    },
    inactivePillow: {
        backgroundColor: Colors.LIGHTPURPLE,
        borderColor: Colors.LIGHTPURPLE,
        color: Colors.BRANDPRIMARY,
        marginRight: "8px"
    }
}));
/**
 *
 * @param {Array.<Event>} upcomingEvents
 * @param {Array.<Event>} pastEvents
 * @param {EventMembership} ownParticipation
 * @returns {JSX.Element}
 * @constructor
 */
const EventOverview = ({upcomingEvents, pastEvents, distributor, ownParticipation}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [redirectToCreate, setRedirectToCreate] = useState(false);
    const [eventFilter, setEventFilter] = useState(EventFilter.UPCOMING);
    const _removedParticipationTimestamp = useRef();
    const _blockedTime = 1200;

    useEffect(() => {
        if (!!upcomingEvents) {
            ModelManager.list({model: Event });
        }
    }, []);

    if(redirectToCreate) {
        return <Redirect push to={ROUTES.eventCreate}/>
    }

    const onParticipationChange = (event, isAuthParticipating) => {
        let data = {
            'uAt': FireTimestamp()
        };

        if (!ownParticipation) {
            ownParticipation = new EventMembership();
            ownParticipation.id = AUTHSTORAGE.getUserId();
            ownParticipation.events = {};
        }

        if (!isAuthParticipating && _removedParticipationTimestamp.current && (_removedParticipationTimestamp.current + _blockedTime) > Date.now() ) {
            return;
        }

        if (isAuthParticipating) {
            _removedParticipationTimestamp.current = Date.now();
            event.count--;

            delete ownParticipation.events[event.id]
            ModelManager.update({modelInstance: event, customData: {
                "count": FireIncrement(-1)},
                'uAt': FireTimestamp()
            });

            data[event.id] = FireDelete();
            ModelManager.update({modelInstance: ownParticipation, customData: data, useSet: true});
        } else {
            event.count++;
            ownParticipation.events[event.id] = true;
            ModelManager.update({modelInstance: event, customData: {
                "count": FireIncrement(1)},
                'uAt': FireTimestamp()
            });

            data[event.id] = true;
            ModelManager.update({modelInstance: ownParticipation, customData: data, useSet: true});
        }
        updateModelInstanceDispatch(EventMembership,ownParticipation);
    }

    let renderEvents = upcomingEvents;

    if(eventFilter === EventFilter.PAST) {
        renderEvents = pastEvents;
    }

    return <div>
        <div className={classes.pillowContainer}>
            <MoinButton size="small"
                        className={eventFilter === EventFilter.UPCOMING ? classes.activePillow : classes.inactivePillow}
                        onClick={(e) => {
                            setEventFilter(EventFilter.UPCOMING);
                            e.stopPropagation();
                            e.preventDefault();
                            return false;
                        }}>
                {t('event.upcomingEvents')}
            </MoinButton>

            <MoinButton size="small"
                        className={eventFilter === EventFilter.PAST ? classes.activePillow : classes.inactivePillow}
                        onClick={(e) => {
                            setEventFilter(EventFilter.PAST);
                            e.stopPropagation();
                            e.preventDefault();
                            return false;
                        }}>
                {t('event.pastEvents')}
            </MoinButton>
        </div>

        <Divider/>
        {
            renderEvents.length > 0
                ? renderEvents.map((event) => {
                    if (!AUTHDISTRIBUTORMEMBERSHIP.isMemberOfDistributor(event.distributor)) {
                        return <></>;
                    }

                    let eventDistributor = distributor.find(dis => dis.id === (event.distributorId ?? parameters.globalDistributorId));
                    return <div className={classes.cardWrapper} key={event.id}>
                        <MoinEventCard event={event} distributor={eventDistributor}
                                       onParticipationChange={() => onParticipationChange(event, event.isAuthUserParticipating)}/>
                    </div>
                })
                : <EventEmptyState/>
        }
        {
            AUTHSTORAGE.hasPermission(Permission.EVENTCREATE) &&
            <MoinFab onClick={() => setRedirectToCreate(true)}/>
        }
    </div>;
};

const mapState = (state, ownProps) => {
    return {
        ownParticipation: getModelInstance(state, EventMembership, AUTHSTORAGE.getUserId())
    }
};

export default connect(mapState)(EventOverview);