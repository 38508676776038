import TypeRegistry from "../manager/TypeRegistry";

const prop = ({type="string", typeOptions={}, readOnly=false, createOnly=false, nullable=false, sentinel=null} = {}) => (target, name, descriptor) => {
    const model = target.constructor;

    if(!model.metadata)
        model.metadata = {};

    if(!model.metadata.props)
        model.metadata.props = {};

    const _type = TypeRegistry.configure(type, typeOptions);

    model.metadata.props[name] = {
        type: _type,
        readOnly,
        createOnly,
        nullable,
        sentinel,
        defaultValue: descriptor.initializer ? descriptor.initializer() : undefined
    };


    return descriptor;

    return {
        set: function (value) {
            if(_type && !_type.test(value))
            {
                console.warn('[invalid type] invalid value given at \''+model.name+'.'+name+"\'. Expected value of type "+type+", "+(typeof value)+" given");
                return;
            }

            this['__'+name] = value;
        },

        get: function () {
            return this['__'+name] ? this['__'+name] : model.metadata.props[name]['defaultValue'];
        }
    };


};

export default prop;
