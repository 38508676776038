import AbstractType from "./AbstractType";

export default class RemainingFieldsType extends AbstractType
{

    toFirestore = (value) => undefined;
    fromFirestore = (value) => undefined;

    static type="remaining";

    test = (value) => typeof value === "object";
}