import {id, model, prop} from "../manager/decorators";
import SurveyOption from "./SurveyOption";

@model('client/:client/survey')
class Survey {

    @id()
    id;

    @prop()
    aId;

    @prop()
    aP;

    @prop()
    question;

    @prop()
    total;

    @prop()
    type;

    @prop({type: "date"})
    endDate;

    @prop({
        nullable: false,
        type: "object",
        typeOptions: {
            entry: "model",
            entryOptions: {
                target: SurveyOption
            }
        }
    })
    options = [];

    authUserVotes;
    authUserAnswer;
}

export default Survey;

