import React from "react";
import {Typography} from "@material-ui/core";
import {Colors} from "../helper/ColorHelper";
import {makeStyles} from "@material-ui/core/styles";
import {
  formatCommentDate,
  getTeaserText
} from "../../utils/helper/TextHelper";
import * as ROUTES from "../../Routes";
import { useHistory} from "react-router-dom";
import FireStorageImage from "../base/FirestoreImage";
import {useTranslation} from "react-i18next";
import ONLINESTATUSES from "../../utils/chat/OnlineStatuses";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import MoinSimpleMenu from "../base/MoinSimpleMenu";
import MoinListItem from "../base/MoinListItem";

const useStyles = makeStyles(() => ({
  chatTeaserAvatar: {
    marginRight: "8px",
    position: "relative",
  },
  chatTeaserMeta: {
    "& p": {
      fontSize: "10px",
      lineHeight: "16px",
      wordBreak: "keep-all",
      minWidth: "64px",
      color: Colors.BLACKLIGHT
    },
  },
  chatTeaserMetaUmc: {
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "16px",
    background: Colors.BRANDSECONDARY,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    color: Colors.WHITE,
    marginTop: "4px"
  },
  onlineCircle: {
    height: '14px',
    width: '14px',
    borderRadius: '14px',
    border: '2px solid ' + Colors.BLUEBACKGROUND,
    backgroundColor: Colors.SUCCESS,
    bottom: 0,
    right: 0,
    position: 'absolute'
  }
}));

/**
 * Chat teaser with avatar, name, last message + date, new message count & options
 *
 * @param itemKey
 * @param {Chat} chat
 * @param {Array} options
 * @returns {JSX.Element}
 * @constructor
 */
function ChatTeaser({itemKey,chat, options}) {
  const classes = useStyles(chat);
  const [t] = useTranslation();
  const history = useHistory();

  const otherUserId = chat.sample.find(user => user.uid !== AUTHSTORAGE.getUserId())?.uid;

  const avatar = <div className={classes.chatTeaserAvatar}>
    <FireStorageImage
        isAvatar={true}
        src={chat.avatar}
        alt={chat.title}
        backgroundColor={ chat.isSingleChat ? Colors.identifierAsColor(chat.getOtherUser()?.uid) : Colors.identifierAsColor(chat.id)}
    />
      {
        chat.isSingleChat && ONLINESTATUSES.isOnline(otherUserId) &&
        <div className={classes.onlineCircle}/>
      }
  </div>;

  const lastMessageIsAuthUser = !!chat.lastMessage && chat.lastMessage.cUid === AUTHSTORAGE.getUserId();

  const trailing = [
    <div key={'meta' + itemKey} className={classes.chatTeaserMeta}>
      <Typography variant="body1">{formatCommentDate(t,!!chat.lastMessage ? chat.lastMessage.cAt : chat.cAt)}</Typography>
      {(chat.uMC > 0) && !lastMessageIsAuthUser ? <div className={classes.chatTeaserMetaUmc}>{chat.uMC}</div> : ''}
    </div>,
    !AUTHSTORAGE.isExternal() ? <MoinSimpleMenu key={'menu' + itemKey} options={options}/> : <></>
  ];

  let subtitle = '\u00A0';
  if ( chat.archived) {
    subtitle = t('chat.archived');
  } else if ( chat.lastMessage ) {
    subtitle = getTeaserText(chat.lastMessage.getTeaserText(t), 80);
  }

  return <MoinListItem
      key={itemKey}
      itemKey={itemKey}
      onClick={() => history.push(ROUTES.messagesShow.replace(":id", chat.id))}
      image={avatar}
      title={chat.title}
      subtitle={subtitle}
      isSubtitleBold={chat.uMC > 0}
      trailing={trailing}
  />
}

export default ChatTeaser;
