import React, {useState} from 'react';
import {CardActions, Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import FireStorageImage from "../base/FirestoreImage";
import {getArticleTitlePath, getUserAvatarPath} from "../../utils/helper/ImageHelper";
import {toDateString} from "../../utils/helper/TextHelper";
import * as ROUTES from "../../Routes";
import Typography from "@material-ui/core/Typography";
import MoinReadMore from "../base/MoinReadMore";
import {MoinCard, MoinCardHeader} from "../base/MoinCard";
import MoinListItem from "../base/MoinListItem";
import MoinSimpleMenu from "../base/MoinSimpleMenu";
import MoinSimpleMenuOption from "../base/MoinSimpleMenuOption";
import {Redirect, useHistory, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Permission} from "../../utils/enums/Permission";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import MoinButton from "../base/MoinButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import parameters from "../../config/parameters.json";
import MoinLinkifiedText from "../base/MoinLinkifiedText";
import {ReactionType} from "../../utils/enums/ReactionType";
import ReactionRowContainer from "./ReactionRowContainer";
import ArticleReaction from "../../utils/models/ArticleReaction";
import ArticleTeaserTags from "./ArticleTeaserTags";
import SCROLLHELPER from "../../utils/helper/ScrollHelper";
import {ContentType} from "../../utils/enums/ContentType";
import {Role} from "../../utils/enums/Role";
import HIGHLIGHTEDARTICLES from "../../utils/articles/HighlightedArticles";
import PinActiveFilled from '../../assets/icons/pin-active-filled.svg';
import {getModelInstance} from "../../utils/store/selectors";
import Article from "../../utils/models/Article";
import {connect} from "react-redux";
import FAVORITEDARTICLES from "../../utils/articles/FavoritedArticles";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        width: '100%',
        padding: theme.spacing(0, 2),
        margin: theme.spacing(1.5, 0)
    },
    highlightedArticleLabel: {
        margin: theme.spacing(0, 2, 1, 0),
        color: Colors.BRANDPRIMARY,
        display: 'flex',
        alignItems: 'center'
    },
    highlightedArticleIcon: {
        color: Colors.BRANDPRIMARY,
        height: '16px',
        width: '16px',
        marginRight: '4px'
    },
    deleteDialogActions: {
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    card: {
        marginBottom: theme.spacing(2),
        width: '100%',
        display: 'block'
    },
    cardContent: {
        alignItems: "flex-start",
        padding: theme.spacing(1.5, 2, 0, 2)
    },
    cardActions: {
        margin: theme.spacing(1.5, 2, 2, 2),
        padding: 0
    },
    articleTagContainer: {
        width: '100%',
        position: 'relative'
    },
    articleTitleImageContainer: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.WHITE,
        cursor: "pointer",
    },
    articleTitleImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: "cover"
    },
    articleTitleText: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    cardHeadline: {
        cursor: 'pointer',
        marginTop: theme.spacing(0.5)
    },
    cardText: {
        marginBottom: theme.spacing(1.5),
        whiteSpace: "pre-wrap"
    },
    divider: {
        margin: theme.spacing(0, 2),
        width: 'unset'
    }
}));

/**
 * @param {Article} article
 * @param {Article} oldestHighlightedArticle
 * @param {Distributor} group
 * @param {ReactionType} authReaction
 * @param {Function} deleteArticle
 * @param {Function} toggleArticleHighlight
 * @param {Function} toggleArticleFavorited
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleTeaser = ({article,oldestHighlightedArticle,  group, authReaction,  deleteArticle, toggleArticleHighlight, toggleArticleFavorited}) => {
    const classes = useStyles();
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [redirectToCopy, setRedirectToCopy] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showHighlightDialog, setShowHighlightDialog] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [t] = useTranslation();
    const history = useHistory();

    if(redirectToEdit) {
        return (
            <Redirect push to={ROUTES.articleEdit.replace(':id', article.id)}/>
        );
    }

    if(redirectToCopy) {
        return (
            <Redirect push to={ROUTES.articleCopy.replace(':id', article.id)}/>
        );
    }

    if(article && article.dAt) {
        return <></>;
    }

    const onDeleteArticle = async () => {
        if(!deleting) {
            setDeleting(true);
            let finishedDeletion = await deleteArticle();
            if(finishedDeletion) {
                setShowDeleteDialog(false);
                setDeleting(false);
            }
        }
    };

    const getDeleteDialog = () => {
        return (
            <Dialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('article.delete') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">{ t('article.deleteDescription', {article: article.title, distributor: group?.name ?? t('distributor.deletedGroup')}) }</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={() => setShowDeleteDialog(false)} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    {
                        deleting &&
                        <div className="moin-circular-progress-container">
                            <CircularProgress />
                        </div>
                    }
                    <MoinButton onClick={() => onDeleteArticle()}>{ t('buttons.delete') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    };

    const getHighlightDialog = () => {
        return (
            <Dialog
                open={showHighlightDialog}
                onClose={() => setShowHighlightDialog(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('article.highlight.fixArticle') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        { t('article.highlight.maximumAmountOfArticlesForHighlight') }
                    </DialogContentText>
                    <DialogContentText id="delete-dialog-description">
                        {!!oldestHighlightedArticle && oldestHighlightedArticle.title}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={() => setShowHighlightDialog(false)} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    <MoinButton onClick={() => {
                        toggleArticleHighlight();
                        setShowHighlightDialog(false);
                    }}>{ t('article.highlight.fixArticle') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    }
    const contextMenuOptions = [];

    if(FAVORITEDARTICLES.isFavorited(article.id)) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('article.favorite.removeFromFavorites'), () => toggleArticleFavorited()));
    } else {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('article.favorite.addToFavorites'), () => toggleArticleFavorited()));
    }

    if(article.cUid === AUTHSTORAGE.getUserId()) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.edit'), () => setRedirectToEdit(true)));
    }

    if( AUTHSTORAGE.hasPermission(Permission.ARTICLECREATE)) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.copy'), () => setRedirectToCopy(true)));
    }

    if(article.cUid === AUTHSTORAGE.getUserId() || AUTHSTORAGE.hasPermission(Permission.ARTICLEDELETE)) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.delete'), () => setShowDeleteDialog(true)));
    }

    if(group?.id === parameters.globalDistributorId
        && (article.cUid === AUTHSTORAGE.getUserId() || (AUTHSTORAGE.hasRole(Role.ADMIN) || AUTHSTORAGE.hasRole(Role.CLIENTMANAGER)))) {

        if(HIGHLIGHTEDARTICLES.isHighlighted(article.id)) {
            contextMenuOptions.push(new MoinSimpleMenuOption( t('article.highlight.removeHighlight'), () => toggleArticleHighlight()));
        } else {
            contextMenuOptions.push(new MoinSimpleMenuOption( t('article.highlight.highlightArticle'), () =>{
                if (HIGHLIGHTEDARTICLES.canHighlightMoreArticles()) {
                    toggleArticleHighlight();
                } else {
                    setShowHighlightDialog(true);
                }
            }) );
        }
    }

    const redirectToArticle = () => {
        history.push(ROUTES.articleShow.replace(':id', article.id));
        SCROLLHELPER.updateLastScrollPosition();
    }

    let name = group?.name ?? t('distributor.deletedGroup');
    if (group?.id === parameters.globalDistributorId) {
        name = t('distributor.global');
    }

    let titleImage;

    if (article.hTI) {
        titleImage = getArticleTitlePath(article);
    } else if (article.hTI === undefined && !!article.getFirstImage()) {
        titleImage = article.getFirstImage();
    }

    return (
        <>
            {
                getDeleteDialog()
            }
            {
                getHighlightDialog()
            }
            <MoinCard hasRoundedBorders={true} className={classes.card}>
                <MoinListItem
                    withSplashEffect={false}
                    withDivider={false}
                    image={
                        <FireStorageImage
                            isAvatar={true}
                            src={getUserAvatarPath(article.cUid)}
                            alt={article.cName}
                            backgroundColor={Colors.identifierAsColor(article.cUid)}
                        />
                    }
                    title={
                        <div className={classes.articleTitleText}>
                            <Typography variant="h2">{article.cName}</Typography>
                            <Typography variant="caption">{name + " • " + toDateString(article.cAt)}</Typography>
                        </div>
                    }
                    customClass={classes.cardHeader}
                    trailing={contextMenuOptions.length > 0 ? [<MoinSimpleMenu key={'menu-' + article.id } options={contextMenuOptions}/>] : undefined}
                    leading={
                        HIGHLIGHTEDARTICLES.isHighlighted(article.id) &&
                        <div className={classes.highlightedArticleLabel}>
                            <PinActiveFilled className={classes.highlightedArticleIcon}/>
                            {t('article.highlight.highlightedArticle')}
                        </div>
                    }
                    onClick={
                        () => history.push(ROUTES.userShow.replace(':id', article.cUid))
                    }
                />
                {
                    titleImage &&
                       <div className={classes.articleTitleImageContainer} onClick={redirectToArticle}>
                            <FireStorageImage lightboxEnabled={false} src={titleImage} className={classes.articleTitleImage} variant="square"/>
                            <ArticleTeaserTags article={article} />
                       </div>
                }
                <Divider/>

                {
                    !titleImage
                    && (article.content.filter(element => element.type === ContentType.SURVEY || element.type === ContentType.PDF || (element.type === ContentType.MEDIA && element.video != null )  ).length) > 0
                    && <div className={classes.articleTagContainer} onClick={redirectToArticle}>
                          <ArticleTeaserTags article={article} absolute={false} />
                       </div>
                }

                <MoinCardHeader className={classes.cardContent}>
                    <Typography variant="h3" className={classes.cardHeadline} onClick={redirectToArticle}>{article.title}</Typography>
                    <p className={classes.cardText}>{article.getTextTeaser() && <MoinLinkifiedText text={article.getTextTeaser()} />}</p>
                    <MoinReadMore to={article.id} onClick={redirectToArticle} />
                </MoinCardHeader>

                <CardActions className={classes.cardActions}>
                    <ReactionRowContainer
                        model={article}
                        modelPath={"article"}
                        reactionModel={ArticleReaction}
                        isComment={false}
                        reactionCount={article.heart}
                        showCommentButtons={true}
                        allReactions={article.reactions}
                        commentCount={article.cCount}
                        onLikeCountTap={() => history.push(ROUTES.articleLikeList.replace(":id", article.id))}
                        onCommentIconTap={redirectToArticle}
                        onCommentCountTap={redirectToArticle}
                        showLikeButton={true}
                    />
                </CardActions>
            </MoinCard>
        </>
    );
};

const mapState = (state, ownProps) => {
    const article = getModelInstance(state, Article, HIGHLIGHTEDARTICLES.getOldestHighlightedArticle());

    return {
        oldestHighlightedArticle: article,
    };
};


export default  connect(mapState, {})(withRouter(ArticleTeaser));
