import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import MoinTextField from "../base/MoinTextField";
import {SurveyType} from "../../utils/enums/SurveyType";
import {Colors} from "../helper/ColorHelper";
import SurveyOption from "../../utils/models/SurveyOption";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import MoinSelect from "../base/MoinSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {Privacy} from "../../utils/enums/Privacy";

import DeleteOutlineIcon from "../../assets/icons/delete.svg";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: theme.spacing(4),
        position: 'relative'
    },
    contentOptions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    deleteIcon: {
        cursor: 'pointer',
        fill: Colors.BLACK,
        margin: '0px 0px 12px 8px'
    },
    addOptionText: {
        color: Colors.BRANDSECONDARY,
        cursor: "pointer"
    },
    typeSelect: {
        marginBottom: theme.spacing(1.5)
    },
    editDeactivatedMessage: {
        marginBottom: theme.spacing(1)
    }
}));

/**
 * @param {Function} onDelete
 * @param {Function} onChange
 * @param {boolean} isEdit
 * @param {Survey} value
 * @returns {JSX.Element}
 * @constructor
 */
const SurveyContentRow = ({onDelete, onChange, value, isEdit}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();

    const hasVotes = !!value && !!value.total && value.total > 0 && !!isEdit;

    const addSurveyOption = () => {
        let highestKey = getOptionsCount();

        if (highestKey >= 12) {
            return;
        }

        value.options[highestKey] = new SurveyOption()
        onChange(value);
    };

    const onSurveyQuestionChange = (e) => {
        value.question = e.target.value;
        onChange(value);
    };

    const onSurveyTypeChange = (e) => {
        value.type = e.target.value;
        onChange(value);
    };

    const onSurveyAnswerPrivacyChange = (isPublic) => {
        value.aP = isPublic ? Privacy.PUBLIC : Privacy.PRIVATE;
        onChange(value);
    };

    const deleteOptionKey = (deleteKey) => {
        if(!hasVotes) {
            delete value.options[deleteKey];
            onChange(value);
        }
    };

    const getOptionsCount = () => {
        if (!value || !value.options) {
            return 0;
        }

        return Object.keys(value.options).sort().length;
    }

    const getSurveyOptions = () => {
        let options = [];

        if(value.type !== SurveyType.FREEANSWER) {
            options = [
                <>
                    <Typography variant="h4">{ t('article.edit.surveyOptions') }</Typography>
                    {
                        Object.keys(value.options).map((key) => (
                            <div className={classes.row}>
                                <MoinTextField
                                    onChange={(e) => {
                                        value.options[key].text = e.target.value;
                                        onChange(value);
                                    }}
                                    placeholder={ t('article.edit.possibleAnswer') }
                                    value={value.options[key].text}
                                    disabled={hasVotes}
                                />

                                <DeleteOutlineIcon className={classes.deleteIcon} onClick={() => deleteOptionKey(key)} style={{color: hasVotes ? Colors.BLACKLIGHTER : Colors.BLACK}}/>
                            </div>
                        ))
                    }
                    {
                        !hasVotes &&
                        getOptionsCount() < 12 &&
                            <Typography variant="body2" className={classes.addOptionText} onClick={addSurveyOption}>+ {t('article.edit.addOption')}</Typography>
                    }
                </>
            ];
        }

        if(!isEdit) {
            options.push(
                <FormControlLabel
                    value="start"
                    control={<Switch color="primary" onChange={(e, checked) => onSurveyAnswerPrivacyChange(checked)} disabled={hasVotes} value={value.aP === Privacy.PUBLIC}/>}
                    label={ t('article.edit.showSurveyAnswersPublic') }
                    labelPlacement="start"
                />
            )
        }

        return options;
    };

    if(!value) {
        return (
            <div className="moin-circular-progress-container">
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.contentOptions}>
                <div className={classes.row}>
                    <Typography variant="h3">{t('article.edit.survey')}</Typography>
                    <DeleteOutlineIcon className={classes.deleteIcon} onClick={onDelete}/>
                </div>
                <div>
                    {/* TODO: change Order Buttons */}
                </div>
            </div>

            {
                hasVotes &&
                <div className={classes.editDeactivatedMessage}><Typography variant='body2'>{t('article.edit.cannotEditSurveyAfterVote')}</Typography></div>
            }

            <MoinSelect value={value.type} onChange={onSurveyTypeChange} className={classes.typeSelect} disabled={hasVotes}>
                <MenuItem value={SurveyType.SINGLECHOICE}>{t('article.survey.singleAnswer')}</MenuItem>
                <MenuItem value={SurveyType.MULTIPLECHOICE}>{t('article.survey.multipleAnswer')}</MenuItem>
                <MenuItem value={SurveyType.FREEANSWER}>{t('article.survey.freeAnswer')}</MenuItem>
            </MoinSelect>
            <Typography variant="h4">{ t('article.edit.askAQuestion') }</Typography>
            <MoinTextField
                onChange={onSurveyQuestionChange}
                placeholder={ t('article.edit.askAQuestion') }
                value={value.question}
                disabled={hasVotes}
            />

            {
                getSurveyOptions()
            }
        </div>
    );
};

export default SurveyContentRow;
