import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import logo from "../../assets/logo/moin-logo-splash.png";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({

    '@keyframes blinker': {
        from: {opacity: 1},
        to: {opacity: 0}
    },
    splashScreen: {
        position: "absolute",
        bottom: "0px",
        top: "0px",
        left: "0px",
        right: "0px",
        zIndex: 10000,
        backgroundColor: Colors.BRANDPRIMARY,
        overflow: 'hidden'
    },
    loadingIcon: {
        display: "inline-block",
        position: "fixed",
        top: "50%",
        left: "50%",
        height: "64px",
        transform: "translate(-50%, -50%)",

        animationName: '$blinker',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount:'infinite',
    }
}));

const MoinSplashScreen =  ({withoutBackground = false}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    return (
        <div
            className={classes.splashScreen}
            style={withoutBackground ? { backgroundColor: "transparent"} : {}}
        >
            <img className={classes.loadingIcon} src={logo} alt={t('common.splashScreen')}/>
        </div>
    )
};

export default MoinSplashScreen;
