import {
    USER_LOGOUT,
} from "./actions";
import Reducer from "./Reducer";

export default (state = {  model: []}, action) => {

    if (action.type === USER_LOGOUT) {
        state = {
            model: [],
        }
    }

    return Reducer(state, action)
}
