import React, {useEffect, useState, Fragment} from 'react';

import {useTranslation} from "react-i18next";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import MoinImageUplaod from "../../components/base/MoinImageUpload";
import MoinTextField from "../../components/base/MoinTextField";
import MoinButton from "../../components/base/MoinButton";
import {makeStyles} from "@material-ui/core/styles";
import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import MoinSearchField from "../../components/base/MoinSearchField";
import Divider from "@material-ui/core/Divider";
import MoinUnsavedChangesDialog from "../../components/base/MoinUnsavedChangesDialog";
import * as ROUTES from "../../Routes";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Feature} from "../../utils/enums/Feature";


const useStyles = makeStyles(() => ({
    content: {
        padding: '16px 16px 0 16px',
        width: '100%'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rowStretch: {
        flexGrow: 1,
        paddingLeft: "12px"
    },
    divider: {
        margin: '0'
    },
    input: {
        marginTop: '8px'
    },
    noBottomPadding: {
        paddingBottom: '0px'
    },
}));

/**
 *
 * @param {Chat} chat
 * @param {Array<String>} userIds
 * @param {boolean} saving
 * @param {Function} onSave
 * @returns {JSX.Element}
 * @constructor
 */
const ChatCreateEdit = ({chat, userIds= [], saving, onSave}) => {
    const classes = useStyles();

    const [t] = useTranslation();

    const [search, setSearch] = useState();
    const [selectedUsers, setSelectedUsers] = useState(userIds);
    const [name, setName] = useState(chat?.name );
    const [imageRemoved, setImageRemoved] = useState(false);
    const [image, setImage] = useState();
    const [errors, setErrors] = useState({});
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        if (chat && userIds && !saving) {
            setSelectedUsers(userIds);
        }
    }, [userIds]);

    const validate = ()  => {
        if (!saving) {
            let errors = {};
            if (!name) {
                errors['name'] = t('form.errors.cantBeEmpty', {field: t('chat.name')});
            } else if (name.length < 2) {
                errors['name'] = t('form.errors.mustBeAtLeastXCharacters', {name: t('chat.name'), chars: 2});
            }
            if (Object.keys(errors).length === 0) {
                onSave({name: name,selectedUserIds: Array.from(selectedUsers),image: image,imageRemoved: imageRemoved} )
            }
            setErrors(errors);
        }

    }

    const onChangeAvatar = (file) => {
        setHasChanges(true);
        setImageRemoved(!file);
        if (!!file) {
            setImage(file);
        } else {
            setImage(undefined);
        }
    }

    const onUserSelection = (user) => {
        setHasChanges(true);
        let userId = user.id;

        let currentUsers = Array.from(selectedUsers);
        if (currentUsers.includes( userId)) {
            currentUsers= currentUsers.filter((id) => id !== userId);
        } else {
            currentUsers.push(userId);
        }
        setSelectedUsers(currentUsers);
    }

    return (
        <Fragment>
            <MoinUnsavedChangesDialog hasChanges={hasChanges} />
            <MoinCard>
                <MoinCardHeader>
                    <Typography variant="h3">
                        {
                            chat
                                ? t('chat.editChat')
                                : t('chat.createChat')
                        }
                    </Typography>
                </MoinCardHeader>
                <MoinCardContent className={classes.noBottomPadding}>
                    <div className={classes.content}>
                        <div className={classes.row}>
                            <MoinImageUplaod radius={35} image={chat?.avatar} onChangeCallback={onChangeAvatar} />
                            <div className={classes.rowStretch}>
                                <MoinTextField
                                    className={classes.input}
                                    maxCharacters={200}
                                    showCharacterCount={false}
                                    caption={ t('chat.nameDescription')}
                                    placeholder={t('chat.placeholder.name')}
                                    value={name}
                                    onChange={e => {
                                        setHasChanges(true);
                                        setName(e.target.value)
                                    }}
                                    error={ errors && errors['name']}
                                />
                            </div>

                        </div>

                        <Typography variant={'h2'}>{t('chat.members').toUpperCase()}</Typography>

                        <MoinSearchField
                            onChange={e => setSearch(e.target.value)}
                            placeholder={t('chat.placeholder.searchContacts')}
                            style={{width: '100%'}}
                            onClear={ () => setSearch('')}
                        />

                        <SearchableSelectableUserList search={search} withInviteExternal={true} selectedUsers={selectedUsers} withSort={false}  onSelect={onUserSelection} useExternals={!!AUTHSTORAGE.getClient().paid_features && AUTHSTORAGE.getClient().paid_features.includes(Feature.EXTERNAL)}  referer={ROUTES.messagesCreate} />

                    </div>
                </MoinCardContent>
                <Divider/>
                <MoinCardHeader>
                    <MoinButton disabled={saving} onClick={validate}>{ !!chat ? t('common.saveChanges') : t('chat.createChat')}</MoinButton>
                </MoinCardHeader>
            </MoinCard>
        </Fragment>
    );
};

export default ChatCreateEdit;