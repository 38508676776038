import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FireStorage} from "../../api/FirebaseService";
import ReactPlayer from "react-player";
import {Colors} from "../helper/ColorHelper";
import PlayIcon from "../../assets/icons/play.svg";
import PauseIcon from "../../assets/icons/pause.svg";
import {Typography} from "@material-ui/core";
import {millisToMinutesAndSeconds} from "../../utils/helper/TextHelper";

const useStyles = makeStyles((theme) => ({
    actionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
    },
    seekerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    seeker: {
        "-webkit-appearance": "none",
        height: "4px",
        outline: "none",
        transition: "background 450ms ease-in",
        background: Colors.BLACKLIGHTEST,

        "&:focus": {
            outline: "none"
        },

        "&::-webkit-slider-thumb": {
            "-webkit-appearance": "none",
            background: Colors.OTHERCHATBUBBLEAUDIOBUTTONS,
            border: "none",
            height: "16px",
            width: "16px",
            borderRadius: "50%",
        },

        "&::-moz-range-thumb": {
            background: Colors.OTHERCHATBUBBLEAUDIOBUTTONS,
            border: "none",
        },

        "&::-ms-thumb": {
            background: Colors.OTHERCHATBUBBLEAUDIOBUTTONS,
            border: "none",
        }
    },
    actionIcon: {
        margin: "0 6px 0 12px",
        cursor: "pointer"
    },
    audioLength: {
        lineHeight: '1rem',
        fontSize: '.6375rem',
        fontStyle: 'normal',
        fontWeight: 400,
        color: Colors.BLACKLIGHT,
        marginBottom: "-1rem",
        marginLeft: "2px"
    },
    seekerAuth: {
        "&::-webkit-slider-thumb": {
            background: Colors.OWNCHATBUBBLEAUDIOBUTTONS,
        },
        "&::-moz-range-thumb": {
            background: Colors.OWNCHATBUBBLEAUDIOBUTTONS,
        },
        "&::-ms-thumb": {
            background: Colors.OWNCHATBUBBLEAUDIOBUTTONS,
        }
    },
    seekerPlayingAuth: {
        "&::-webkit-slider-thumb": {
            background: `${Colors.OWNCHATBUBBLEAUDIOACTIVE} !important`,
        },
        "&::-moz-range-thumb": {
            background: `${Colors.OWNCHATBUBBLEAUDIOACTIVE} !important`,
        },
        "&::-ms-thumb": {
            background: `${Colors.OWNCHATBUBBLEAUDIOACTIVE} !important`,
        }
    },
    seekerPlaying: {
        "-webkit-appearance": "none",
        height: "4px",
        outline: "none",
        transition: "background 450ms ease-in",
        background: Colors.BLACKLIGHT,

        "&:focus": {
            outline: "none"
        },

        "&::-webkit-slider-thumb": {
            "-webkit-appearance": "none",
            background: Colors.OTHERCHATBUBBLEAUDIOACTIVE,
            border: "none",
            height: "16px",
            width: "16px",
            borderRadius: "50%",
        },

        "&::-moz-range-thumb": {
            background: Colors.OTHERCHATBUBBLEAUDIOACTIVE,
            border: "none",
        },

        "&::-ms-thumb": {
            background: Colors.OTHERCHATBUBBLEAUDIOACTIVE,
            border: "none"
        }
    }
}));

/**
 * @param {ArticleContent} content
 * @param {JSX.Element} leading
 * @param {JSX.Element} trailing
 * @returns {JSX.Element}
 * @constructor
 */
const AudioPlayer = ({content, leading, trailing, isAuth}) => {
    const classes = useStyles();
    const playerRef= useRef();
    const seeking= useRef(false);
    const [url, setUrl] = useState();
    const [playing, setPlaying] = useState(false);
    const [position, setPosition] = useState(0);

    useEffect(() => {
        if (content && content.url) {
            let src = content.url.replace(/%2F/g, '/');
            FireStorage.ref(src).getDownloadURL()
                .then(setUrl);
        }
    }, [content]);

    const handleProgress = ({played}) => {
        if (!seeking.current) {
            setPosition(played)
        }
    }

    const handleEnd = () => {
        setTimeout(() => {
            setPlaying(false);
            setPosition(0);
        }, 1000)
    }

    const handlePlayPause = () => {
        setPlaying(!playing);
    }

    const handleSeekMouseDown = (e) => {
        seeking.current = true;
    }

    const handleSeekChange = (e) => {
        setPosition(parseFloat(e.target.value) )
    }

    const handleSeekMouseUp = (e) => {
        seeking.current = false;
        playerRef.current.seekTo(parseFloat(e.target.value))
    }


    if (!content || !url) {
        return (<></>);
    }

    let activeAudioSlider = isAuth ? Colors.OWNCHATBUBBLEAUDIOACTIVE : Colors.OTHERCHATBUBBLEAUDIOACTIVE;
    let inactiveAudioSlider = isAuth ? Colors.OWNCHATBUBBLEAUDIOSLIDER : Colors.OTHERCHATBUBBLEAUDIOSLIDER;
    let seekerClasses = `${playing ? classes.seekerPlaying: classes.seeker}`;

    if(isAuth) {
        seekerClasses += ` ${playing ? classes.seekerPlayingAuth: classes.seekerAuth}`;
    }

    return(
        <>
            <ReactPlayer
                ref={(ref) => playerRef.current = ref}
                controls={false}
                url={url}
                playing={playing}
                onEnded={handleEnd}
                onProgress={handleProgress}
                style={{display: "none"}}
            />
            <div className={classes.actionWrapper}>
                {
                    !!leading && leading
                }

                {
                    playing
                        ? <PauseIcon
                            style={{
                                marginLeft: leading ? "12px" : "8px",
                                fill: isAuth ? Colors.OWNCHATBUBBLEAUDIOBUTTONS : Colors.OTHERCHATBUBBLEAUDIOBUTTONS
                            }}
                            className={classes.actionIcon}
                            onClick={handlePlayPause}/>
                        : <PlayIcon
                            style={{
                                marginLeft: leading ? "12px" : "8px",
                                fill: isAuth ? Colors.OWNCHATBUBBLEAUDIOBUTTONS : Colors.OTHERCHATBUBBLEAUDIOBUTTONS
                            }}
                            className={classes.actionIcon}
                            onClick={handlePlayPause
                        }/>
                }

                <div className={classes.seekerWrapper}>
                    <input
                        type='range'
                        className={seekerClasses}
                        min={0}
                        max={0.999999}
                        step='any'
                        value={position}
                        onMouseDown={handleSeekMouseDown}
                        onChange={handleSeekChange}
                        onMouseUp={handleSeekMouseUp}
                        style={{
                            background: "linear-gradient(to right,  " + activeAudioSlider + " 0%, "+ activeAudioSlider + " " + position * 100 + "%, " + inactiveAudioSlider + " " + position * 100 +  "%,  " + inactiveAudioSlider + " 100%)",
                            marginRight: trailing ? "16px" : "8px"
                        }}
                    />

                    <Typography variant={"caption"} className={classes.audioLength} style={{ color: isAuth ? Colors.OWNCHATBUBBLEEXTRATEXT : Colors.OTHERCHATBUBBLEEXTRATEXT }}>
                        {
                            millisToMinutesAndSeconds(content.length)
                        }
                    </Typography>

                </div>

                {
                    !!trailing && trailing
                }

            </div>
        </>

    );
};

export default AudioPlayer;