import store from "./store";

export const ADD_MODEL_INSTANCE = 'ADD_MODEL_INSTANCE';
export const ADD_MODEL_INSTANCES = 'ADD_MODEL_INSTANCES';
export const SET_MODEL_INSTANCES = 'SET_MODEL_INSTANCES';
export const DELETE_MODEL_INSTANCE = 'DISABLE_MODEL_INSTANCE';
export const DELETE_MODEL_INSTANCES = 'DELETE_MODEL_INSTANCE';
export const UPDATE_MODEL_INSTANCE = "UPDATE_MODEL_INSTANCE";
export const UPDATE_MODEL_INSTANCES = "UPDATE_MODEL_INSTANCES";
export const CLEAR_CACHE = "CLEAR_CACHE";
export const USER_LOGOUT = "CLEAR_CACHE";

function shallowClone(obj) {
    return Object.create(
        Object.getPrototypeOf(obj),
        Object.getOwnPropertyDescriptors(obj)
    );
}

/**
 * set modelInstance list to state
 * @return {function(...[*]=)}
 * @param model
 * @param modelInstances
 */
export const setModelInstances = (model, modelInstances) => {
    store.dispatch({
        type: SET_MODEL_INSTANCES,
        model,
        data: modelInstances
    });
};

/**
 * set modelInstances list to state
 * @return {function(...[*]=)}
 * @param model
 * @param modelInstances
 */
export const addModelInstances = (model, modelInstances) => {
    store.dispatch({
        type: ADD_MODEL_INSTANCES,
        model,
        data: modelInstances
    });
};

/**
 * set modelInstances list to state
 * @return {function(...[*]=)}
 * @param model
 * @param modelInstances
 */
export const addModelInstancesDispatch = (model, modelInstances) => dispatch => {
    dispatch({
        type: ADD_MODEL_INSTANCES,
        model,
        data: modelInstances
    });
};


/**
 * add a new modelInstance
 * @return {function(...[*]=)}
 * @param model
 * @param modelInstance
 */
export const addModelInstance = (model, modelInstance) => {
    store.dispatch({
        type: ADD_MODEL_INSTANCE,
        model: model,
        data: modelInstance
    });
};


/**
 * add a new modelInstance
 * @return {function(...[*]=)}
 * @param model
 * @param modelInstance
 */
export const addModelInstanceDispatch = (model, modelInstance) => dispatch => {
    dispatch({
        type: ADD_MODEL_INSTANCE,
        model: model,
        data: modelInstance
    });
};



/**
 * update a modelInstance
 * @param model
 * @param modelInstance
 * @return {function(...[*]=)}
 */
export const updateModelInstance = (model, modelInstance) => {
    let copy = shallowClone(modelInstance);

    store.dispatch({
        type: UPDATE_MODEL_INSTANCE,
        model: model,
        data: copy
    });
};

/**
 * update a modelInstance
 * @param model
 * @param modelInstances
 * @return {function(...[*]=)}
 */
export const updateModelInstances = (model, modelInstances) => {
    let copy = shallowClone(modelInstances);

    store.dispatch({
        type: UPDATE_MODEL_INSTANCES,
        model: model,
        data: copy
    });
};

/**
 * update a modelInstance
 * @param model
 * @param modelInstance
 * @return {function(...[*]=)}
 */
export const updateModelInstanceDispatch = (model, modelInstance) => dispatch => {
    let copy = shallowClone(modelInstance);

    dispatch({
        type: UPDATE_MODEL_INSTANCE,
        model: model,
        data: copy
    });
};


/**
 * mark given modelInstance as deleted
 * @param model
 * @param modelInstance
 * @return {function(...[*]=)}
 */
export const deleteModelInstance = (model, modelInstance) => {
    store.dispatch({
        type: DELETE_MODEL_INSTANCE,
        model: model,
        data: modelInstance
    });
};

/**
 * mark given modelInstance as deleted
 * @param model
 * @param  {{field, value}} fieldTest
 * @return {function(...[*]=)}
 */
export const deleteModelInstancesWhere = (model, fieldTest) => {
    store.dispatch({
        type: DELETE_MODEL_INSTANCES,
        model: model,
        data: fieldTest
    });
};

/**
 * mark given modelInstance as deleted
 * @param model
 * @param  {{field, value}} fieldTest
 * @return {function(...[*]=)}
 */
export const deleteModelInstancesDispatch = (model, fieldTest) => dispatch => {
    dispatch({
        type: DELETE_MODEL_INSTANCES,
        model: model,
        data: fieldTest
    });
};

/**
 * add a new modelInstance
 * @return {function(...[*]=)}
 * @param model
 * @param modelInstance
 */
export const deleteModelInstanceDispatch = (model, modelInstance) => dispatch => {
    dispatch({
        type: DELETE_MODEL_INSTANCE,
        model: model,
        data: modelInstance
    });
};



/**
 * clear complete cache
 * @return {function(...[*]=)}
 */
export const clearCache = () =>  {
    store.dispatch({
        type: CLEAR_CACHE
    });
};