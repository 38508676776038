import React, {Fragment, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import MoinListItem from "../components/user/MoinUserListItem";
import {
    MoinCard,
    MoinCardContent,
    MoinCardHeader,
    MoinCardContentTitle
} from "../components/base/MoinCard";
import MoinSearchField from "../components/base/MoinSearchField";
import { Link } from 'react-router-dom';
import * as ROUTES from "../Routes";
import MoinDivider from "../components/base/MoinDivider";

const useStyles = makeStyles(() => ({
    content: {
        width: "98%",
        padding: "8px 20px 20px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    divider: {
        width: "100%",
        padding: "8px 0 0 0"
    },
    searchBar: {
        marginBottom: "12px"
    },
    title: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        "& > *": {
            marginRight: "auto"
        }
    },
    backButton: {
        width: "auto"
    },
    link: {
        textDecoration: "none",
        width: "100%"
    }
}));

/**
 * Moin! themed card with searchable list of users.
 *
 * @param {String} title Title of the card
 * @param {Array<User>} userList List of users that should be displayed
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <UserListOverview
     userList={users}
     title={'Member'}
     />
 */
const UserListOverview = ({title, userList}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    let [searchValue, setSearchValue] = useState("");

    return (
        <MoinCard>
            <MoinCardHeader>
                <Typography variant="h3">{title}</Typography>
            </MoinCardHeader>
            <MoinCardContent>
                <div className={classes.content}>
                    <MoinSearchField
                        value={searchValue}
                        className={classes.searchBar}
                        placeholder={t('common.search', {list: title})}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onClear={ () => setSearchValue('')}
                    />
                    {
                        userList
                            .filter(user => user.matchesSearch(searchValue))
                            .map(user => <Fragment key={user.id}>
                                <Link to={ROUTES.userShow.replace(':id', user.id)} className={classes.link}><MoinListItem user={user} divider={false}/></Link>
                                <MoinDivider />
                            </Fragment>)
                    }
                </div>
            </MoinCardContent>
        </MoinCard>
    );
}

export default UserListOverview;
