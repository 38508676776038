import React, {Fragment, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import FireStorageImage from "../../components/base/FirestoreImage";
import Typography from "@material-ui/core/Typography";
import {
    MoinCard,
    MoinCardContent,
    MoinCardContentTitle,
    MoinCardHeader,
    MoinCardHeaderImage
} from "../../components/base/MoinCard";
import GroupTag from "../../components/group/GroupTag";
import {Colors} from "../../components/helper/ColorHelper";
import parameters from "../../config/parameters.json";
import {ClientType} from "../../utils/enums/ClientType";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import MoinButton from "../../components/base/MoinButton";
import Divider from "@material-ui/core/Divider";
import {BirthdayPrivacy} from "../../utils/enums/BirthdayPrivacy";
import {DateTime} from "luxon";
import * as TranslationHelper from "../../components/helper/TranslationHelper";

const useStyles = makeStyles(theme => ({
    content: {
        padding: theme.spacing(3),
        width: '100%'
    },
    headerImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: "cover"
    },
    external: {
        padding: "0 0.5rem",
        backgroundColor: Colors.BLUELIGHT,
        color: Colors.BLACK,
        borderRadius: '4px',
        marginTop: '4px'
    },
    profileButton: {
        marginTop: "1.5rem"
    },
    divider: {
        padding: "12px 0 0 0"
    },
    link: {
        textDecoration: 'none',
        color: Colors.BRANDSECONDARY
    },
    sectionDivider: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(1.5)
    },
    contentWrapper: {
        paddingLeft: 0,
        paddingRight: 0
    }
}));

/**
 * User profile view
 *
 * @param {User} user
 * @param {User} authUser
 * @param {Array<Department>} department
 * @param {Array<DistributorMembership>}distributorMembership
 * @param {boolean} isAuthUser
 * @param {Array<String>} roles
 * @param {ClientType} clientType
 * @param {Function} onMessage
 * @returns {JSX.Element}
 * @constructor
 */
const UserProfile = ({user, authUser, department, distributorMembership, isAuthUser, roles, clientType, onMessage}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    let hasUserInfos = (!!user.email && !user.emailPrivacy) || !!user.office || !!user.competencies || !!user.personalInfo;

    if(!authUser.external || isAuthUser) {
        hasUserInfos = hasUserInfos || !!user.mobilePhone || !!user.workPhone;
    }

    const [redirectToEdit, setRedirectToEdit] = useState(false);

    if (redirectToEdit) {
        return <Redirect push to={ROUTES.userEdit.replace(":id", AUTHSTORAGE.getUserId())} />
    }

    const buildProfileInfo = () => {
        let components = [];

        if ((!!user.email && !user.emailPrivacy)) {
            components.push(
                <Fragment key={'user-profile-email-' + user.id}>
                    <Typography variant={'caption'}>{isAuthUser ? t('user.yourEmailAddress') : t('user.emailAddress')}</Typography>
                    <a href={'mailto:' + user.email} className={classes.link}>
                        <Typography variant={'body1'} className={classes.link}>{user.email}</Typography>
                    </a>
                    {
                        (isAuthUser && user.emailPrivacy) &&
                        <Typography variant={'caption'}>{t('user.emailPrivacy')}</Typography>
                    }
                </Fragment>
            );
        }

        if (!!user.office) {
            components.push(
                <Fragment key={'user-profile-office-' + user.id}>
                    <Typography variant={'caption'}>{t('user.office')}</Typography>
                    <Typography variant={'body1'}>{user.office}</Typography>
                </Fragment>
            )
        }

        if (department) {
            components.push(
                <Fragment key={'user-profile-department-' + user.id}>
                    <Typography variant={'caption'}>
                    { TranslationHelper.department(t) }
                    </Typography>
                    <Typography variant={'body1'}>{department.name}</Typography>
                </Fragment>
            );
        }

        if (clientType === ClientType.ASSOCIATION && user.addDep) {
            components.push(
                <Fragment key={'user-profile-additional-department-' + user.id}>
                    <Typography variant={'caption'}>{t('user.department')}</Typography>
                    <Typography variant={'body1'}>{user.addDep}</Typography>
                </Fragment>
            );
        }

        if (isAuthUser && !!roles && roles.length > 0) {
            components.push(
                <Fragment key={'user-profile-roles-' + user.id}>
                    <Typography variant={'caption'}>{t('user.roles')}</Typography>
                    {
                        roles.map((role, index) =>
                            <Typography variant={'body1'} key={'user-profile-' + user.id + '-role-' + role.id}>{role.name + (index !== roles.length - 1 ? ', ' : '')}</Typography>
                        )
                    }
                </Fragment>
            );
        }

        if (!!user.mobilePhone && (!authUser.external || isAuthUser)) {
            components.push(
                <Fragment key={'user-profile-mobile-' + user.id}>
                    <Typography variant={'caption'}>{t('user.mobile')}</Typography>
                    <Typography variant={'body1'}>{user.mobilePhone}</Typography>
                </Fragment>
            );
        }

        if (!!user.workPhone && (!authUser.external || isAuthUser)) {
            components.push(
                <Fragment key={'user-profile-phone-' + user.id}>
                    <Typography variant={'caption'}>{t('user.phone')}</Typography>
                    <Typography variant={'body1'}>{user.workPhone}</Typography>
                </Fragment>
            );
        }

        if (!!user.competencies) {
            components.push(
                <Fragment key={'user-profile-skills-' + user.id}>
                    <Typography variant={'caption'}>{t('user.skills')}</Typography>
                    <Typography variant={'body1'}>{user.competencies}</Typography>
                </Fragment>
            );
        }

        if (!!user.personalInfo) {
            components.push(
                <Fragment key={'user-profile-info-' + user.id}>
                    <Typography variant={'caption'}>{t('user.info')}</Typography>
                    <Typography variant={'body1'}>{user.personalInfo}</Typography>
                </Fragment>
            )
        }

        if (!!user.birthday
            && !!user.birthdayPrivacy
            && (user.birthdayPrivacy === BirthdayPrivacy.PUBLIC || user.birthdayPrivacy === BirthdayPrivacy.NOYEAR)
        ) {
            components.push(
                <Fragment key={'user-profile-birthday-' + user.id}>
                    <Typography variant={'caption'}>{t('user.birthday')}</Typography>
                    {
                        user.birthdayPrivacy === BirthdayPrivacy.PUBLIC
                            ? <Typography variant={'body1'}>{new DateTime.fromJSDate(user.birthday).toFormat('dd.LL.y')}</Typography>
                            : <Typography variant={'body1'}>{new DateTime.fromJSDate(user.birthday).toFormat('dd.LL')}</Typography>
                    }
                </Fragment>
            )
        }

        return (
            components.map( (comp, index) => <Fragment key={'profile-info-' + user.id + '-index-' + index}>
                {index > 0 ? <Divider key={'user-profile-divider-' + user.id + '-' + index} style={{margin: "12px 0"}}/> : undefined}
                {comp}
            </Fragment> )
        )
    }

    const formatSecondaryText = (department, user) => {
        if (!!user.external) {
            return <></>;
        }

        if (AUTHSTORAGE.getClient().type === ClientType.ASSOCIATION) {
            if (!!department && !!user.addDep) return `${department.name} • ${user.addDep}`;
            if (!!department) return department.name;
            if (!!user.addDep) return user.addDep;
        } else {
            if (!!department && !!user.position) return `${department.name} • ${user.position}`;
            if (!!department) return department.name;
            if (!!user.position) return user.position;
        }
    }

    return (
        <Fragment>
            <MoinCard>
                <MoinCardHeaderImage isSquare={true}>
                    <FireStorageImage
                        lightboxEnabled={true}
                        fontSize={64}
                        src={user.largeAvatar}
                        alt={user.fullName}
                        className={classes.headerImage}
                        backgroundColor={Colors.identifierAsColor(user.uid)}
                    />
                </MoinCardHeaderImage>
                <MoinCardHeader>
                    <Typography variant="h3">{user.fullName}</Typography>
                    <Typography variant="caption">
                        {formatSecondaryText(department, user)}
                    </Typography>
                    {
                        user.external &&
                        <Typography variant="caption" className={classes.external}>
                            {t('user.external')}
                        </Typography>
                    }

                    {
                        user.uid !== AUTHSTORAGE.getUserId() ?
                            (<MoinButton className={classes.profileButton} onClick={() => onMessage()} size="small">{ t('buttons.sendMessage') }</MoinButton>)
                        :
                            (<MoinButton className={classes.profileButton} onClick={() => setRedirectToEdit(true)} size="small" variant="outlined">{ t('buttons.editProfile') }</MoinButton>)
                    }
                </MoinCardHeader>
                <MoinCardContent className={classes.contentWrapper}>
                    <div className={classes.content}>
                        {
                            hasUserInfos &&
                            <MoinCardContentTitle variant={"h4"} style={{ paddingTop: 0 }} className={classes.sectionDivider}>{t('user.accountInformation')}</MoinCardContentTitle>
                        }
                        {
                            buildProfileInfo()
                        }

                        { !user.external && <>
                                <MoinCardContentTitle variant={"h4"} className={classes.sectionDivider}>{t('user.groups')}</MoinCardContentTitle>
                                <GroupTag
                                distributorId={parameters.globalDistributorId}
                                key={"distributor-tag-" + parameters.globalDistributorId}
                                />
                                {
                                    !!distributorMembership  && distributorMembership.dis && Object.keys(distributorMembership.dis).map(
                                    (distributorId) =>
                                        <GroupTag
                                            distributorId={distributorId}
                                            key={"distributor-tag-" + distributorId}
                                        />
                                    )
                                }
                            </>
                        }
                    </div>
                </MoinCardContent>
            </MoinCard>
        </Fragment>
    );
}

export default UserProfile;
