import { createTheme } from '@material-ui/core/styles';
import {Colors} from "../../components/helper/ColorHelper";
import React from "react";

const theme = (darkTheme) => createTheme({
    palette: {
        primary: {
            main: Colors.BRANDPRIMARY,
        },
        secondary: {
            main: Colors.BRANDSECONDARY,
        },
        white: {
            main: Colors.WHITE,
        },
        type: darkTheme ? "dark" : "light"
    },
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        body1: {
            lineHeight: '1.25rem',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 400,
            color: Colors.BLACK,
            wordBreak: "break-word"
        },
        body2: {
            lineHeight: '1.5rem',
            fontSize: '.9375rem',
            fontStyle: 'normal',
            fontWeight: 400,
            color: Colors.BLACK,
            wordBreak: "break-word",
            whiteSpace: "break-spaces"
        },
        //COPY SMALL
        caption: {
            lineHeight: '1.125rem',
            fontSize: '.8125rem',
            fontStyle: 'normal',
            fontWeight: 400,
            color: Colors.BLACKLIGHT,
            wordBreak: "break-word"
        },
        h1: {
            lineHeight: '2rem',
            fontSize: '1.75rem',
            fontStyle: 'normal',
            fontWeight: 500,
            color: Colors.BLACK
        },
        h2: {
            lineHeight: '1.375rem',
            fontSize: '.9375rem',
            fontStyle: 'normal',
            fontWeight: 500,
            color: Colors.BLACK
        },
        h3: {
            lineHeight: '1.625rem',
            fontSize: '1.25rem',
            fontStyle: 'normal',
            fontWeight: 500,
            color: Colors.BLACK
        },
        h4: {
            lineHeight: '1.125rem',
            letterSpacing: '.04375rem',
            fontSize: '.8125rem',
            fontStyle: 'normal',
            fontWeight: 700,
            textTransform: 'uppercase',
            color: Colors.BLACK
        },
        // COPY SMALL BOLD
        h5: {
            lineHeight: '1.125rem',
            fontSize: '.8125rem',
            fontStyle: 'normal',
            fontWeight: 700,
            color: Colors.BLACKLIGHT,
            display: "inline-block"
        },
        // COPY X-SMALL
        h6: {
            lineHeight: '1rem',
            fontSize: '.625rem',
            fontStyle: 'normal',
            fontWeight: 400,
            color: Colors.BLACKLIGHT,
        },
        subtitle1: {
            lineHeight: '1.125rem',
            fontSize: '.75rem',
            fontStyle: 'normal',
            fontWeight: 400,
            color: Colors.BLACK
        },
        subtitle2: {
            lineHeight: '1.125rem',
            fontSize: '.75rem',
            fontStyle: 'normal',
            fontWeight: 400,
            color: Colors.BLACK
        },
    },
    overrides: {

        MuiAppBar: {
            colorPrimary: {
                backgroundColor: Colors.WHITE
            }
        },

        MuiTabs: {
            indicator: {
                backgroundColor: Colors.BRANDPRIMARYLIGHT
            }
        },

        MuiCardHeader: {
            root: {
                padding: 0,
                width: "100%",
                minHeight: "48px",
            },
        },

        MuiDialogActions : {
           root: {
               padding: "8px 24px 16px 24px"
           }
        },

        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },

        MuiListItem : {
            root: {
                width: "auto",
            },

            gutters: {
                paddingRight: "8px",
                paddingLeft: "8px",
                paddingTop: "8px",
                paddingBottom: "8px",
            },

            divider: {
                borderBottomColor: Colors.BLUELIGHT,
            }
        },

        MuiListItemSecondaryAction: {
            root: {
                right: "24px",
            }
        },

        MuiFormControl: {
            marginNormal: {
                marginTop: "8px",
                marginBottom: "16px",
            }
        },

        MuiInputBase: {
            root: {
                backgroundColor: Colors.WHITE,
                borderRadius: 20,
                '& fieldset': {
                    borderColor: `${Colors.BLUELIGHT} !important`
                },
                '&:hover fieldset': {
                    borderColor: `${Colors.BLUELIGHT} !important`
                },
            }
        },

        MuiDialogContent: {
            root: {
                '&:first-child': {
                    paddingTop: "12px"
                }
            }
        },

        MuiOutlinedInput: {
            root: {
                borderRadius: 20,
            },
            input: {
                padding: "12px 20px"
            },
            inputMarginDense: {
                paddingTop: "12px",
                paddingBottom: "12px"
            }
        },
        MuiSelect: {
            outlined: {
                borderRadius: 20,
            }
        },

        MuiDivider: {
           root: {
               width: '100%',
               backgroundColor: Colors.BLUELIGHT
           },

        },
    }
});

const drawerWidth = 240;

const master = (theme) => {
    return {
            root: {
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: Colors.BLUEBACKGROUND,
                minHeight: "100vh"
            },
            appBar: {
                padding: theme.spacing(1,0),
                "border-bottom": `1px solid ${Colors.BLUELIGHT}`,
                backgroundColor: Colors.WHITE,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            appBarShift: {
                backgroundColor: Colors.WHITE,
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            menuButton: {
                marginRight: theme.spacing(2),
            },
            hide: {
                display: 'none',
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
                "border-right": `1px solid ${Colors.BLUELIGHT}`,
                alignSelf: "flex-start",
                overflow: "hidden"
            },
            drawerPaper: {
                width: drawerWidth,
            },
            divider: {
                backgroundColor: Colors.BLUELIGHT
            },
            drawerHeader: {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
                justifyContent: 'space-between',
                width: drawerWidth
            },
            contentMaxWidth: {
                maxWidth: "768px"
            },
            content: {
                flexGrow: 1,
                padding: theme.spacing(6,2,4,2),
                boxSizing: "border-box",
                marginLeft: -drawerWidth,
                overflow: "hidden"
            },
            contentShift: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0
            },
            inline: {
                display: 'inline',
            },
            navContent: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: "100%",
                boxSizing: "border-box",
                paddingBottom: "8px"
            },
            navDrawer: {
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1
            },
            navVersion: {
                display: 'flex',
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "0 32px"
            },
            navLink: {
                textDecoration: 'none'
            },
            navIcon: {
                height: "1rem",
                marginLeft: "4px",
                fill: Colors.BLACKLIGHT,
            },
            navText: {
                flexGrow: 1
            },
            navIconSmall: {
                width:'1.5rem',
                minWidth:'1.5rem',
                marginRight: '8px',
                position: "relative"
            },
            navIconRight: {
                fill: Colors.BLACKLIGHT,
            },
            navIconActive: {
                height: "1rem",
                marginLeft: "4px",
                fill: Colors.BRANDPRIMARY,
            },
            navDivider: {
                margin: "8px 16px",
                width: drawerWidth -48,
            },
            navSubMenu: {
                transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                overflow: "hidden",
            },
            userItem: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "24px 16px 16px 16px",
                cursor: "pointer"
            },
            userAvatar: {
                marginBottom: "12px"
            },
            userName: {
                color: Colors.BRANDPRIMARY,
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.25rem"
            },
            userDescription: {
                fontSize: '0.75rem'
            },
            drawerItem: {
                '& > span': {
                    color: Colors.BLACKLIGHT,
                    margin: 0
                },
                padding: "8px 16px",
                display: "flex",
            },
            drawerItemActive: {
                '& > span': {
                    color: Colors.BRANDPRIMARY,
                    margin: 0
                },
                padding: "8px 16px",
                display: "flex",
            },
            drawerItemSub: {
                '& > span': {
                    color: Colors.BLACKLIGHT,
                    fontSize: '0.75rem',
                    margin: 0,
                    marginLeft: '40px'
                },
                display: "flex",
            },
            drawerItemSubActive: {
                '& > span': {
                    color: Colors.BRANDPRIMARY,
                    fontSize: '0.75rem',
                    margin: 0,
                    marginLeft: '40px'
                },
                display: "flex",
            },


            title: {
                textAlign: 'center',
            },
            appBarAction: {
                flexGrow: 1,
                alignSelf: "center",
                display: "flex",
                justifyContent: "flex-end",
            },
            language: {
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                color: Colors.BRANDPRIMARY
            },
            iconWithCounter: {
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                textDecoration: "none",
                color: Colors.BRANDPRIMARY,
            },
            iconCounter: {
                borderRadius: "100%",
                backgroundColor: Colors.BRANDSECONDARY,
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "1rem",

                '& > h4': {
                    color: Colors.WHITE,
                    "marginBottom": "2px"
                }
            },
            iconCounterTextSmall: {
                fontSize: ".6rem"
            },
            iconAttentionMarker: {
                borderRadius: "100%",
                backgroundColor: Colors.BRANDSECONDARY,
                width: "0.5rem",
                height: "0.5rem",
                position: "absolute",
                right: 0,
                top: "-2px"
            },
            headerSearchResultWrapper: {
                display: "flex",
                position: "absolute",
                top: "58px",
                bottom: 0,
                justifyContent: "center",
                width: "100%",
                height: "calc(100vh - 58px)",
                padding: "0 1rem"
            },
            headerSearchResultContainer: {
                maxWidth: "768px",
                width: "100%",
                padding: "0 1rem",
                marginBottom: "8px",
                display: "flex",
                flexDirection: "column"
            },
            headerSearchResultContent: {
                width: "100%",
                maxHeight: "100%",
                overflowY: "scroll",
                padding: "1rem 2rem",
                backgroundColor: Colors.WHITE,
                color: Colors.BLACK,
                borderRadius: "20px",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                position: 'relative'
            },
            headerSearchBarContainer: {
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: "0 1rem"
            },
            headerSearchBar: {
                maxWidth: "768px",
                padding: "0 1rem",
                margin: 0
            },
            searchSuggestion: {
                display: "flex",
                alignItems: "center",
                marginTop: '8px',
                marginBottom: '8px',
                cursor: 'pointer',

                '& > span' : {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }
            },
            tasksSearchSuggestion: {
                display: "flex",
                alignItems: "center",
                padding: '12px 0',
                cursor: 'pointer',
                borderBottom: '1px solid' + Colors.BLUELIGHT,
                margin: '0 -8px',
                height: '64px',
                '&:last-child': {
                    borderBottom: 'none'
                },
                '& > span' : {
                    whiteSpace: "nowrap",
                },
                '& svg': {
                    height: '32px',
                    width: '32px'
                }
            },
            taskIcon: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '32px'
            },
            taskIconContainer: {
                width: '26px',
                height: '26px',
                borderRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                    verticalAlign: 'middle',
                    marginRight: '0',
                    width: '24px',
                    height: '24px',
                }
            },
            taskInfo: {
                marginLeft: '8px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                '& p': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }
            },
            taskNameCompleted: {
                color: Colors.BLACKLIGHTER
            },
            taskAssignee: {
                marginLeft: 'auto',
                minWidth: '32px',
                paddingLeft: '12px',
                boxSizing: 'content-box'
            },
            storageSearchSuggestion: {
                display: "flex",
                alignItems: "center",
                padding: '18px 0',
                cursor: 'pointer',
                borderBottom: '1px solid' + Colors.BLUELIGHT,
                '&:last-child': {
                    borderBottom: 'none'
                },
                '& > span' : {
                    whiteSpace: "nowrap",
                }
            },
            storageResultName: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            storageSearchFileIcon: {
                position: 'relative',
                border: 'none !important',
                width: '40px',
                textAlign: 'center',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "8px",
                '& svg': {
                    width: '26px',
                    height: '26px',
                },
                '& div': {
                    width: "32px",
                    height: "32px",
                    objectFit: "cover",
                    borderRadius: '4px'
                },
                '& img': {
                    width: "32px",
                    height: "32px",
                    objectFit: "cover",
                    borderRadius: '4px'
                }
            },
            storageSearchUpdated: {
                marginLeft: 'auto',
            },
            headerLogo: {
                height: "2rem",
                width: "auto",
                maxWidth: "150px",
                marginLeft: "1rem",
                cursor: "pointer",
            },
            paper: {
                padding: theme.spacing(2),
                textAlign: 'center',
            },
            defaultLink: {
                cursor: "pointer",
                textDecoration: "none"
            },
        };

};

export {
    master,
    theme
};
