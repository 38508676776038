import * as httpClient from "../httpclient/HttpClient";
import parameters from "../../config/parameters.json";
import {FireAuth} from "../../api/FirebaseService";
import LoginHelper from "../helper/LoginHelper";


class BackendController {

    async createExternal(workspace, firstName,lastName,mobilePrefix,mobileNumber, department, language ) {
        let mail = LoginHelper.getLoginFromPhone(workspace,mobilePrefix,mobileNumber);

        let httpClientInstance = new httpClient.default(parameters.backendUrl, {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${await FireAuth.currentUser.getIdToken()}`
            }
        });
        let data = {
            "firstName" : firstName,
            "lastName" : lastName,
            "mail" : mail,
            "phone" : '+' + mail.split('@')[0],
            "workspace": workspace,
            "language" : language
        }

        if (department) {
            data["department"] = department;
        }
        let request = httpClientInstance.post('/invite', JSON.stringify(data));

        let status = 200;
        let response = await request.send().catch((ex) => {
            status = ex.statusCode;
        });

        if (status !== 200) {
            return status;
        } else {
            return response;
        }
    }

    async checkIfExternalExists(workspace,mobilePrefix, mobileNumber)  {

        let mail = LoginHelper.getLoginFromPhone(workspace,mobilePrefix,mobileNumber);

        let httpClientInstance = new httpClient.default(parameters.backendUrl, {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${await FireAuth.currentUser.getIdToken()}`
            }
        });
        let request = httpClientInstance.post('/invite/check', JSON.stringify({'mail' : mail}));

        let result;
        await request.send().catch((ex) => {
            result = true;
            if (ex.statusCode === 302) {
                result = true;
            }

            if (ex.statusCode === 404) {
                result = false;
            }
        });
        return result;
    }
}

export default BackendController;