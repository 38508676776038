class Mention {
    entityType;
    entityId;
    fallbackName;
    textStartIndex;

    // The following regex checks for mentions, a mention in raw text looks like this: !!{[MentionType.USER:hkj78HGaasDAxcg:Geralt#_#von#_#Riva]}!!
    // "#_#" is used to mask spaces, because the splitting of words in the Linkify Rich Test Field would otherwise break the syntax
    // The first match group (in this example 'MentionType.USER') is the mentioned entity
    // The second match group (in this example 'hkj78HGaasDAxcg') is the id of the mentioned entity
    // The third match group (in this example 'Geralt#_#von#_#Riva') is a fallback name that is displayed without a link if the mentioned entity could not be loaded
    static mentionRegex = /^!!{\[(.*):(.*):(.*)\]}!!$/;

    get mentionString() {
        return `!!{[${this.entityType.toString()}:${this.entityId}:${this.fallbackName.replaceAll(' ', '#_#')}]}!!`;
    }

    static fromMentionString(text) {
        let match = this.mentionRegex.exec(text);
        let mention = new Mention();
        mention.entityType = match[1];
        mention.entityId = match[2];
        mention.fallbackName = match[3].replaceAll('#_#', ' ');

        return mention;
    }

    static isMentionString(text) {
        return this.mentionRegex.test(text);
    }
}

export default Mention;