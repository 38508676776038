import React, {useEffect, useRef, useState} from "react";
import ChatMessage from "../../components/chat/ChatMessage";
import MoinTextField from "../../components/base/MoinTextField";
import {useTranslation} from "react-i18next";
import {Colors} from "../../components/helper/ColorHelper";
import {makeStyles} from "@material-ui/core/styles";
import MoinSimpleMenu from "../../components/base/MoinSimpleMenu";
import MoinSimpleMenuOption from "../../components/base/MoinSimpleMenuOption";
import {Divider, Typography} from "@material-ui/core";
import * as ROUTES from "../../Routes";
import {useHistory} from "react-router-dom";
import FireStorageImage from "../../components/base/FirestoreImage";
import ONLINESTATUSES from "../../utils/chat/OnlineStatuses";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import AttachFileIcon from "../../assets/icons/attachment.svg";
import CloseIcon from "../../assets/icons/close.svg";
import PDFIcon from "../../assets/icons/pdf.svg";
import {formatChatDate, getTeaserText} from "../../utils/helper/TextHelper";
import MoinUnsavedChangesDialog from "../../components/base/MoinUnsavedChangesDialog";
import illustration1 from "../../assets/illustrations/birthday/geburtstag-chat-01.png";
import illustration2 from "../../assets/illustrations/birthday/geburtstag-chat-02.png";
import illustration3 from "../../assets/illustrations/birthday/geburtstag-chat-03.png";
import illustration4 from "../../assets/illustrations/birthday/geburtstag-chat-04.png";
import {stringToHash} from "../../utils/helper/EncryptionHelper";
import * as CacheHelper from "../../utils/helper/CacheHelper";
import {FireTimestamp} from "../../api/FirebaseService";
import ModelManager from "../../utils/manager/ModelManager";
import {render} from "react-dom";

const useStyles = makeStyles((theme) => ({
    sendButton: {
        height: '38px',
        minHeight: '38px',
        width: '38px',
        minWidth: '38px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.WHITE,
        cursor: 'pointer',
    },
    selectedMediaWrapper: {
        minWidth: '240px',
        width: '240px',
        height: '150px',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'relative',
        margin: theme.spacing(1)
    },
    selectedImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    selectedDocument: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.BLACKLIGHTEST,
        height: '100%',
        width: '100%',
        padding: theme.spacing(1.5),
        textAlign: 'center'
    },
    deleteMediaIcon: {
        position: 'absolute',
        height: '25px',
        width: '25px',
        borderRadius: '25px',
        backgroundColor: Colors.BLACKLIGHTER,
        top: '8px',
        right: '8px',
        cursor: 'pointer',
        color: Colors.WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    allMediaWrapper: {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: "768px",
    },
    topBar: {
        background: Colors.WHITE,
        '& > hr' : {
            width: "100%",
        }
    },
    topBarActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: "12px 24px",
        justifyContent: "space-between"
    },
    bottomBar: {
        background: Colors.WHITE,
        '& > hr' : {
            width: "100%",
        }
    },
    bottomBarActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: "8px 24px 16px 24px",
    },
    bottomBarTextField: {
        margin: "0 8px 0 8px"
    },
    messagesWrapperOuter: {
        width: "768px",
        padding: "0 1rem",
        overflow: "auto",
        display:"flex",
        flex: 1,
        flexDirection: "column-reverse"
    },
    messagesWrapperInner: {
        width: "100%"
    },
    chatView: {
        display: "flex",
        flexDirection:"column",
        position: "absolute",
        bottom: "0",
        top: "64px",
        margin: "0 -32px 0 -16px",
        maxWidth: "768px"
    },
    messageSpacer: {
        padding: "4px 0"
    },
    demoInfo: {
        padding: "4px 0",
        color: Colors.ERROR,
        textAlign: "center"
    },
    archiveInfo: {
        padding: "4px 0",
        textAlign: "center"
    },
    selectedVideo: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    chatDate : {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: "12px 12px 12px 312px",
        position: "fixed", // Sticky does not work, thanks chrome
        top: "114px"
    },
    chatDateInfo: {
        padding: "0px 8px",
        width: "84px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: Colors.BLACKLIGHTEST,
        color: Colors.BLACKLIGHT,
        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
        borderRadius: "4px"

    },
    chatInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: "relative"
    },
    chatInfoAvatar: {
        marginRight: "8px",
        position: "relative",
    },
    chatInfoText: {
        display: "flex",
        flexDirection: "column"
    },
    onlineCircle: {
        height: '14px',
        width: '14px',
        borderRadius: '14px',
        border: '2px solid ' + Colors.BLUEBACKGROUND,
        backgroundColor: Colors.SUCCESS,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    attachIcon : {
        display: "flex"
    },
    birthdayWrapper: {
        width: '100%',
        height: '140px',
        position: 'relative'
    },
    birthdayBackground: {
        position: 'absolute',
        width: '100%',
        height: '50%',
        bottom: '0',
        left: '0',
        backgroundColor: Colors.LIGHTPURPLE,
        zIndex: '0'
    },
    birthdayContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '100%',
        height: '100%',
        '& *': {
            zIndex: '2'
        }
    },
    birthdayText: {
        width: '160px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '13px',
        lineHeight: '18px',
        color: Colors.BRANDPRIMARY,
        margin: '0 0 16px 16px'
    },
    birthdayActionsWrapper: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    birthdayIllustration: {
        height: '80%',
        width: 'auto',
        marginRight: '8px'
    },
    closeButton: {
        height: '36px',
        width: '36px',
        backgroundColor: Colors.BRANDPRIMARY,
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px',
        border: 'none',
        cursor: 'pointer'
    },
    closeIcon: {
        width: '100%',
        height: '100%',
        fill: Colors.WHITE
    }
}));

/**
 * @param {Chat} chat
 * @param {Message[]} messages
 * @param {Function} sendMessage
 * @param {Function} loadOlderMessages
 * @param {Function} onAddVideo
 * @param {Function} onRemoveVideo
 * @constructor
 */
const ChatDetail = ({chat, messages, sendMessage, loadOlderMessages, onAddVideo, onRemoveVideo, otherUser}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const history = useHistory();

    const [messageText, setMessageText] = useState("");
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [hideBirthday, setHideBirthday] = useState(true);
    const imageInputField = useRef();
    const documentInputField = useRef();
    const videoInputField = useRef();
    const messageRef = useRef();
    const birthdayIllustrations = [illustration1, illustration2, illustration3, illustration4];

    useEffect(() => {
        ModelManager.update({customData: {[AUTHSTORAGE.getUserId()]: FireTimestamp()}, useSet: true, resource: `/client/${AUTHSTORAGE.getClientId()}/chat/${chat.id}/user`, id: 'lastReadDate'});
    }, []);

    useEffect(() => {
        checkHiddenBirthday();
        window.addEventListener('scroll', onScrollHandle, true);
        onScrollHandle(); // This fixes the date label if the chat was just opened, but never scrolled in
        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [messages]);

    const checkHiddenBirthday = async () => {
        if (!!otherUser && otherUser.hasBirthday) {
            setHideBirthday(await CacheHelper.checkHiddenBirthday(otherUser.uid));
        }
    }

    const onScrollHandle = () => {
        for(let dateElement of document.getElementsByClassName(classes.chatDate)) {
            let renderIndex = dateElement.getAttribute('data-index');
            let firstMessageForNextDate = document.getElementById(`rendered-chat-message-${renderIndex}`);
            if(firstMessageForNextDate) {
                let firstMessageBoundingRect = firstMessageForNextDate.getBoundingClientRect();

                // If bottom of message above date is barely out of frame
                if((firstMessageBoundingRect.top + firstMessageBoundingRect.height) < 108) {
                    // Set the position to fixed and top and marginLeft so that the date doesn't move
                    dateElement.style.position = 'fixed';
                    dateElement.style.top = '114px';
                    dateElement.style.marginLeft = '312px';

                    // Display placeholder in list so it doesn't jump
                    document.getElementById(`rendered-chat-date-placeholder-${renderIndex}`).style.display = 'block';
                } else {
                    // Set the position to relative and reset top and marginLeft
                    dateElement.style.position = 'relative';
                    dateElement.style.top = '0';
                    dateElement.style.marginLeft = '0';

                    // Hide placeholder in list so it doesn't jump
                    document.getElementById(`rendered-chat-date-placeholder-${renderIndex}`).style.display = 'none';
                }
            }
        }

        if(!!messageRef.current && !!messages[messages.length - 1]) {
            let lastPixels = (50 - messageRef.current.scrollTop) > ( messageRef.current.scrollHeight - messageRef.current.offsetHeight);

            if (lastPixels) {
                loadOlderMessages(messages[messages.length - 1].cAt);
            }
        }
    };

    const onSendMessage = async () => {
        if (!chat.archived) {
            setHasChanges(false);
            sendMessage(messageText, selectedImages, selectedDocuments, selectedVideos);

            setMessageText("");
            setSelectedImages([]);
            setSelectedDocuments([]);
            setSelectedVideos([]);
        }

    };

    const onKeyDown = (e) => {
        if(e.keyCode === 13 && !e.shiftKey && !chat.archived){
            onSendMessage();
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    };

    const removeSelectedImage = (index) => {
        setSelectedImages(selectedImages.filter((image, i) => i !== index));
    };

    const removeSelectedVideo = (index) => {
        setSelectedVideos(selectedVideos.filter((image, i) => i !== index));
        onRemoveVideo(index);
    };

    const removeSelectedDocument = (index) => {
        setSelectedDocuments(selectedDocuments.filter((image, i) => i !== index));
    };

    const onSelectNewVideos = (fileList) => {
        // This is needed, because the Object returned from an <input> element is not an array, but a FileList which does not have forEach
        const videos = [...fileList];
        setSelectedVideos([...selectedVideos, ...videos]);
        videos.forEach(video => {
            onAddVideo(video);
        });
    };

    const renderSelectedImage = (image, index) => {
        return (
            <div key={"selected-image-" + index} className={classes.selectedMediaWrapper}>
                <img src={URL.createObjectURL(image)} className={classes.selectedImage}/>
                <div className={classes.deleteMediaIcon} onClick={() => removeSelectedImage(index)}>
                    <CloseIcon fill={Colors.WHITE}/>
                </div>
            </div>
        );
    };

    const renderSelectedVideo = (video, index) => {
        return (
            <div key={"selected-video-" + index} className={classes.selectedMediaWrapper}>
                <video className={classes.selectedVideo}>
                    <source src={URL.createObjectURL(video)}/>
                </video>
                <div className={classes.deleteMediaIcon} onClick={() => removeSelectedVideo(index)}>
                    <CloseIcon fill={Colors.WHITE} />
                </div>
            </div>
        );
    };

    const renderSelectedDocument = (document, index) => {
        return (
            <div key={"selected-document-" + index} className={classes.selectedMediaWrapper}>
                <div className={classes.selectedDocument}>
                    <PDFIcon fill={Colors.BLACK} style={{ fontSize: '2.5rem' }}/>
                    <Typography variant="body1">{ document.name }</Typography>
                </div>
                <div className={classes.deleteMediaIcon} onClick={() => removeSelectedDocument(index)}>
                    <CloseIcon fill={Colors.WHITE} />
                </div>
            </div>
        );
    };

    let contextMenuOptions = [
        new MoinSimpleMenuOption( t('chat.image'), (event) => imageInputField.current.click()),
        new MoinSimpleMenuOption( t('chat.document'), (event) => documentInputField.current.click()),
        new MoinSimpleMenuOption( t('chat.video'), (event) => videoInputField.current.click())
    ];

    const enableSendButton = (!!messageText || selectedImages.length > 0 || selectedDocuments.length > 0 || selectedVideos.length > 0);

    let otherUserId;
    let memberPreview = "";
    if (chat.isSingleChat) {
        otherUserId = chat.sample.find(user => user.uid !== AUTHSTORAGE.getUserId())?.uid;
    } else {
        chat.sample.forEach( (user) => {
            memberPreview += user.name + ", ";
        });
        memberPreview = memberPreview.substring(0, memberPreview.length - 2);
        if (chat.count > chat.sample.length) {
            memberPreview += ' ...';
        }
    }

    const buildMessages = () => {

        let lastMessageDate;
        let renderedPart = [];

        messages.forEach( (msg, index) => {
            if (msg && msg.cAt) {

                if ( ( index === messages.length -1 ) ) {
                    renderedPart.push(<ChatMessage key={msg.id} chat={chat} message={msg} />)
                }

                let messageDate = msg.cAt.getFullYear()*10000 + msg.cAt.getMonth()*100 + msg.cAt.getDate();
                if (!lastMessageDate) {
                    lastMessageDate = messageDate;
                } else if (messageDate !== lastMessageDate || ( index === messages.length -1 ) ) {
                    lastMessageDate = messageDate;
                    let zIndex = 9998-index;
                    renderedPart.push(
                        <div id={`rendered-chat-date-${index}`} key={'chat-message-' + msg.id + '-date'} data-index={index} className={classes.chatDate} style={{"zIndex": zIndex }}>
                            <Typography className={classes.chatDateInfo} variant={"body2"}> {formatChatDate(t, messages[index-1].cAt)}</Typography>
                        </div>
                    )
                    // this placeholder is for when the date transitions to a fixed state, so the list doesn't jump around
                    renderedPart.push(
                        <div id={`rendered-chat-date-placeholder-${index}`} key={'chat-message-' + msg.id + '-date-placeholder'} style={{minHeight: '48px', maxHeight: '48px', display: 'none'}}></div>
                    )
                }

                if ( ( index !== messages.length -1 ) ) {
                    renderedPart.push(<ChatMessage id={`rendered-chat-message-${index}`} key={msg.id} chat={chat} message={msg} />)
                }

            }
        })
        return renderedPart;
    }

    return (
        <div className={classes.chatView}>
            <MoinUnsavedChangesDialog hasChanges={hasChanges} />
            <div className={classes.topBar}>
                <div className={classes.topBarActions}>

                    <div className={classes.chatInfo}>
                        <div className={classes.chatInfoAvatar}>
                            <FireStorageImage
                                radius={16}
                                isAvatar={true}
                                src={chat.avatar}
                                alt={chat.name}
                                backgroundColor={Colors.identifierAsColor(chat.id)}
                            />
                            {
                                otherUserId && ONLINESTATUSES.isOnline(otherUserId) &&
                                <div className={classes.onlineCircle}/>
                            }
                        </div>
                        <div className={classes.chatInfoText}>
                            <Typography variant={"h2"}>{chat && getTeaserText(chat.title,150)}</Typography>
                            <Typography variant={"caption"}>{!chat.isSingleChat && memberPreview }</Typography>
                        </div>
                    </div>
                    {
                       !AUTHSTORAGE.isExternal() && chat && !chat.isSingleChat && <MoinSimpleMenu options={[
                            new MoinSimpleMenuOption(
                                t('chat.editChat'),
                                () => history.push(ROUTES.messagesEdit.replace(":id", chat.id))
                            )
                        ]}/>
                    }
                </div>
                <Divider/>
            </div>
            <div className={classes.messagesWrapperOuter} ref={ (node) => messageRef.current = node}>
                {
                    AUTHSTORAGE.getClient().isDemoClient() &&
                    <Typography
                        variant="body1"
                        className={classes.demoInfo}>
                        { t('client.demoClientInfo')}
                    </Typography>
                }
                {
                    chat && chat.archived &&
                    <Typography
                        variant="body1"
                        className={classes.archiveInfo}>
                        { t('chat.isArchived')}
                    </Typography>
                }
                {
                    buildMessages()
                }
                <div className={classes.messageSpacer}/>
            </div>
            {/* Write Message Field */}
            <div className={classes.allMediaWrapper}>
                {
                    selectedImages.map((image, index) => renderSelectedImage(image, index))
                }
                {
                    selectedVideos.map((image, index) => renderSelectedVideo(image, index))
                }
                {
                    selectedDocuments.map((image, index) => renderSelectedDocument(image, index))
                }
            </div>
            {
                !hideBirthday  && <div className={classes.birthdayWrapper}>
                    <div className={classes.birthdayBackground}/>
                    <div className={classes.birthdayContent}>
                        <p className={classes.birthdayText}>
                            {t('chat.birthday', {firstName: chat.getOtherUser().name})}
                        </p>
                        <div className={classes.birthdayActionsWrapper}>
                            <img
                                src={birthdayIllustrations[stringToHash(otherUser.uid) % 4]}
                                alt={"Birthday illustration"}
                                className={classes.birthdayIllustration}
                            />
                            <button
                                className={classes.closeButton}
                                onClick={async () => {
                                    await CacheHelper.setHiddenBirthdays(otherUser.uid);
                                    setHideBirthday(true);
                                }
                            }>
                                <CloseIcon className={classes.closeIcon} />
                            </button>
                        </div>
                    </div>
                </div>
            }
            <div className={`${classes.bottomBar}`}>

                <Divider/>

                <div className={classes.bottomBarActions}>
                    <input ref={(fileInput) => imageInputField.current = fileInput} type="file" accept="image/png,image/jpeg,image/gif" style={{display: 'none'}} multiple onChange={(e) => setSelectedImages([...selectedImages, ...e.target.files]) }/>
                    <input ref={(fileInput) => documentInputField.current = fileInput} type="file" accept="application/pdf" style={{display: 'none'}} multiple onChange={(e) => setSelectedDocuments([...selectedDocuments, ...e.target.files]) }/>
                    <input ref={(fileInput) => videoInputField.current = fileInput} type="file" accept="video/mp4" style={{display: 'none'}} multiple onChange={(e) => onSelectNewVideos(e.target.files) }/>

                    <MoinSimpleMenu options={contextMenuOptions} className={classes.attachIcon}  disabled={chat.archived}>
                        <AttachFileIcon fill={Colors.BLACKLIGHT}/>
                    </MoinSimpleMenu>

                    <MoinTextField
                        className={classes.bottomBarTextField}
                        multiline={true}
                        rowsMax={5}
                        disabled={chat.archived}
                        rows={1}
                        value={messageText}
                        onChange={(e) => {
                            setHasChanges(!!e.target.value);
                            setMessageText(e.target.value)
                        }}
                        placeholder={ t('chat.writeAMessage') }
                        onKeyDown={onKeyDown}
                    />

                    <div className={classes.sendButton} style={{backgroundColor: enableSendButton ? Colors.BRANDSECONDARY : Colors.BLACKLIGHTER}} onClick={() => onSendMessage()}>
                        <ArrowRightIcon fill={Colors.WHITE}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatDetail;
