import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import MoinSearchField from "../../components/base/MoinSearchField";
import * as ROUTES from "../../Routes";
import {Link, useHistory} from "react-router-dom";
import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Feature} from "../../utils/enums/Feature";

const ContactList =  () => {
    const history = useHistory();
    const [t, i18n] = useTranslation();
    const [searchValue, setSearchValue] = useState('');

  return <>
      <MoinSearchField
          onChange={e => {
              setSearchValue(e.target.value);
          }}
          placeholder={t('company.search.placeholder')}
          style={{width: '100%', marginTop: "20px", marginBottom: "4px"}}
          onClear={e => {
              setSearchValue('');
          }}
      />
      <SearchableSelectableUserList
          search={searchValue}
          onSelect={(user) => history.push(ROUTES.userShow.replace(':id', user.id)) }
          useExternals={!!AUTHSTORAGE.getClient().paid_features && AUTHSTORAGE.getClient().paid_features.includes(Feature.EXTERNAL)}
          showCheckbox={false}
          fullHeight={true}
        />
    </>;
}

export default  ContactList;