
class StorageCacheManager
{
    urls={};

    markUrlAsRequested = (url) => {
        this.urls[url] = Date.now();
    }

    checkIfUrlRequested = (url) =>  {
        return this.urls.hasOwnProperty(url) && (this.urls[url] + 5000 > Date.now());
    };
    removeUrlFromRequested = (url) => {
        delete this.urls[url]
    }

};

const STORAGECACHEMANAGER = new StorageCacheManager();
export default STORAGECACHEMANAGER;