import React, {useEffect} from 'react';
import {connect} from "react-redux";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import ModelManager from "../../utils/manager/ModelManager";
import DistributorRequest from "../../utils/models/DistributorRequest";
import ApiClient from "../../api/ApiClient";
import {getModelInstance} from "../../utils/store/selectors";
import Distributor from "../../utils/models/Distributor";
import {deleteModelInstance} from "../../utils/store/actions";
import GroupRequest from "./GroupRequest";

const GroupRequestContainer = ({distributorId, distributor}) => {

    useEffect(() => {
        ModelManager.subscribe({
            model: DistributorRequest,
            resource: "/client/" + AUTHSTORAGE.getClientId() + "/distributor/" + distributorId + '/request/',
            extraProps: {
                'distributorId': distributor.id
            }
        });

        return () => {
            ModelManager.unsubscribe(DistributorRequest)
        }
    }, []);

    const _makeDistributorMembershipRequest = async  (path, userId) => {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }

        let body ={};
        body['distributorId'] = distributor.id;
        body['distributorName'] = distributor.name;
        body['userId'] = userId;
        const request = await ApiClient.createRequest(path,body);
        await request.send();
    }

    const onAccept= (requests, users) => {

        requests.forEach( async (request) => {
            deleteModelInstance(DistributorRequest,request);

            await _makeDistributorMembershipRequest('/acceptDistributorMembershipRequestWeb', request.uid);
            ModelManager.delete({modelInstance: request, resource: '/client/' + AUTHSTORAGE.getClientId() + '/distributor/' + distributor.id + '/request/'});
        })
    };

    const onDecline= (requests) => {
        requests.forEach( async (request) => {
            deleteModelInstance(DistributorRequest,request);

            await _makeDistributorMembershipRequest('/refuseDistributorMembershipRequest', request.uid);
            ModelManager.delete({modelInstance: request, resource: '/client/' + AUTHSTORAGE.getClientId() + '/distributor/' + distributor.id + '/request/'});
        })
    };
    return <GroupRequest distributor={distributor} onAccept={onAccept} onDecline={onDecline}/>
};

const mapState = (state, ownProps) => ({
    distributor: getModelInstance(state, Distributor, ownProps.distributorId),
});
export default connect(mapState)(GroupRequestContainer);
