import FireStorageImage from "../base/FirestoreImage";
import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import {FireStorage} from "../../api/FirebaseService";
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";

import PlayIcon from '../../assets/icons/play.svg';
import CloseIcon from "../../assets/icons/close.svg";

const useStyles = makeStyles((theme) => ({
    videoWrapper: {
        minWidth: '240px',
        width: '240px',
        height: '150px',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'relative',
        marginRight: "8px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    video: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    videoIcon: {
        position: 'absolute',
        height: '56px',
        width: '56px',
        borderRadius: '56px',
        backgroundColor: Colors.BLUELIGHT,
        fill: Colors.WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
    },
    videoLightbox: {
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        overflow: 'auto',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 9999
    },
    videoLightboxContent: {
        position: 'relative',
        margin: 'auto',
        padding: 0,
        width: '90%',
        height: '100%',
        maxWidth: '1200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    videoLightboxClose: {
        fill: Colors.WHITE,
        position: 'absolute',
        top: '10px',
        right: '25px',
        fontSize: '35px',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    videoInfo: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
    },
    videoInfoText: {
        color: Colors.WHITE
    }
}));

const RenderedVideo = ({video, style}) => {
    const classes = useStyles();
    const [videoInLightbox, setVideoInLightbox] = useState();
    const [t] = useTranslation();

    const showLightboxForVideo = (video) => {
        if(!video.uploadId) {
            let src = video.url.replace(/%2F/g, '/');
            FireStorage.ref(src).getDownloadURL()
                .then((url) => setVideoInLightbox({url: url, width: video.width, height: video.height}));
        }
    };

    let videoStyle = {};
    if(!!videoInLightbox) {
        if(videoInLightbox.width > videoInLightbox.height) {
            videoStyle['width'] = '98%';
        } else {
            videoStyle['height'] = '98%';
        }
    }

    return (
        <>
            <div className={classes.videoWrapper} onClick={() => showLightboxForVideo(video)} style={style}>
                {
                    !video.uploadId &&
                        <FireStorageImage src={video.thumbnail} className={classes.video}/>
                }
                {
                    !!video.uploadId ? (
                        <div className={classes.videoInfo}>
                            <Typography variant="body1" className={classes.videoInfoText}>{ t('chat.videoIsProcessing') }</Typography>
                        </div>
                    ) : (
                        <div className={classes.videoIcon}>
                            <PlayIcon fill={Colors.WHITE} style={{ fontSize: '4rem' }}/>
                        </div>
                    )
                }
            </div>
            {
                !!videoInLightbox &&
                <div className={classes.videoLightbox} onClick={() => setVideoInLightbox(null)}>
                    <div className={classes.videoLightboxClose}>
                        <CloseIcon fill={Colors.WHITE} />
                    </div>
                    <div className={classes.videoLightboxContent}>
                        <video controls autoPlay onClick={(e) => e.stopPropagation()} style={videoStyle}>
                            <source src={videoInLightbox.url} type="video/mp4"/>
                        </video>
                    </div>
                </div>
            }
        </>
    );
};

export default RenderedVideo;