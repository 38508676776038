export const Permission = {
    // Articles
    GLOBALARTICLEREAD: 'gar',
    GLOBALARTICLECREATE: 'gac',
    ARTICLECREATE: 'ac',
    ARTICLEDELETE: 'ad',

    // Comments
    COMMENTCREATE: 'cc',
    COMMENTDELETE: 'cd',

    // Events
    EVENTCREATE: 'ec',
    EVENTEDIT: 'ee',
    EVENTDELETE: 'ed',

    // Groups
    GROUPCREATE: 'gc',
    GROUPDELETE: 'gd',
    GLOBALGROUPTITLEIMAGEEDIT: 'ggte',

    INVITEEXTERNAL: 'ie',
    DELETEDOCUMENTS: 'dd',
    UPLOADDOCUMENTS: 'ud'
}