import React, {useEffect, useRef, useState} from 'react';
import ArticleTeaserContainer from "../../components/article/ArticleTeaserContainer";
import {makeStyles} from "@material-ui/core/styles";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import ArticleStreamEmptyState from "./ArticleStreamEmptyState";
import ArticleSkeleton from "./ArticleSkeleton";
import MoinFab from "../../components/base/MoinFab";
import BirthdayCard from "../../components/article/BirthdayCard";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {useTranslation} from "react-i18next";
import {Permission} from "../../utils/enums/Permission";
import VerifyPasswordBanner from "../../components/user/VerifyPasswordBanner";
import ErrorState from "../../components/base/ErrorState";
import {Link} from "react-router-dom";
import {sortByDate} from "../../utils/helper/SortHelper";
import {filterUpcomingEvents} from "../../utils/helper/EventHelper";
import NewsEventCard from "../../components/article/NewsEventCard";
import {Colors} from "../../components/helper/ColorHelper";
import HIGHLIGHTEDARTICLES from "../../utils/articles/HighlightedArticles";
import Typography from "@material-ui/core/Typography";
import {setHideEvents as  startHelperSetHideEvents} from "../../utils/helper/StartHelper";

const useStyles = makeStyles((theme) => ({
    streamWrapper: {
        marginTop: theme.spacing(2)
    },
    birthdaySection: {
        margin: '32px 0'
    },
    eventSection: {
        margin: '32px 0'
    },
    eventHeaderRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: Colors.BRANDSECONDARY,
        '&:hover': {
            color: Colors.BRANDPRIMARY
        }
    }
}));

/**
 * @param articles
 * @param {function} loadOlderArticles
 * @param {function} loadNewerArticles
 * @param {boolean} loading
 * @param {boolean} showError
 * @param {boolean} noMoreArticles
 * @param {Array<User>} users
 * @param {Array<Event>} events
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleStream = ({articles, loadOlderArticles, loadNewerArticles, noMoreArticles, loading, showError, users, events}) => {
    const classes = useStyles();
    const feedWrapper = useRef();
    const [redirectToCreate, setRedirectToCreate] = useState(false);
    const autoRefresh = useRef();
    const [t] = useTranslation();

    const displayedEvents = filterUpcomingEvents(events);
    displayedEvents.sort((a,b) => sortByDate(a.start,b.start));
    const [hideEvents, setHideEvents] = useState(false);

    const relevantArticles = articles.filter((article) => article.stream && !HIGHLIGHTEDARTICLES.isHighlighted(article.id)).sort((a, b) => b.cAt - a.cAt);
    const highlightedArticles = articles.filter((article) => HIGHLIGHTEDARTICLES.isHighlighted(article.id)).sort((a, b) => HIGHLIGHTEDARTICLES.getArticleHighlightDate(b.id) - HIGHLIGHTEDARTICLES.getArticleHighlightDate(a.id));
    const birthdayUsers = users.filter(user => user.uid !== AUTHSTORAGE.getUserId() && user.hasBirthday);


    useEffect(() => {
        if (!!autoRefresh.current) {
            clearTimeout(autoRefresh.current);
        }
        window.addEventListener('scroll', onScrollHandle, true);

        let hideEventsBase = !!AUTHSTORAGE.getHideEvents();
        if (hideEventsBase) {
            hideEventsBase = displayedEvents.filter( (event) => !!event.cAt && AUTHSTORAGE.getHideEvents().getTime() < event.cAt.getTime()).length === 0
        }
        setHideEvents(hideEventsBase);

        autoRefresh.current = setTimeout(
            autoRefreshArticles,
            45000
        );

        return () => {
            if (!!autoRefresh.current) {
                clearTimeout(autoRefresh.current);
            }
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [articles]);


    const onScrollHandle = () => {
        if(!!feedWrapper.current && feedWrapper.current.getBoundingClientRect().bottom <= window.innerHeight + 250 && !!articles[articles.length - 1]) {
            loadOlderArticles(relevantArticles[relevantArticles.length - 1].cAt);
        }
    };

    const autoRefreshArticles = () => {
        if(!!relevantArticles[0]) {
            loadNewerArticles(relevantArticles[0].cAt);
        }
        autoRefresh.current = setTimeout(
            autoRefreshArticles,
            45000
        );
    };


    if(redirectToCreate) {
        return <Redirect push to={ROUTES.articleCreate}/>
    }

    if (showError && !!showError) {
       return (
           <ErrorState title={'errors.stream.errorOccurred'} description={'errors.stream.dataCouldNotBeLoaded'}/>
       );
    }

    return (
        <>
            <VerifyPasswordBanner/>
            <div ref={(node) => feedWrapper.current = node} className={classes.streamWrapper}>
                {
                    (!loading && (!relevantArticles || relevantArticles.length <= 0)) &&
                    <ArticleStreamEmptyState/>
                }
                {
                    (loading && !noMoreArticles && (!relevantArticles || relevantArticles.length <= 0)) &&
                    <div>
                        <ArticleSkeleton/>
                        <ArticleSkeleton/>
                        <ArticleSkeleton/>
                    </div>
                }
                {
                    relevantArticles.map((article, index) => {
                        let extraWidgets = [];
                        let leadingWidgets = [];

                        if(index === 0 && highlightedArticles.length > 0) {
                            highlightedArticles.map(article => leadingWidgets.push(<ArticleTeaserContainer article={article} key={"stream-article-" + article.id}/>));
                        }

                        if (index === 0 && birthdayUsers.length > 0) {
                            let widgets = [
                                <h1>{t('birthday.happyBirthday')}</h1>
                            ];

                            birthdayUsers.map((user, index) => widgets.push(<BirthdayCard user={user} index={index} key={"stream-birthday-" + user.uid}/>));
                            extraWidgets.push(<div className={classes.birthdaySection} key="stream-birthday-section">{widgets}</div>);
                        }

                        if(index === 0 && displayedEvents.length > 0) {
                            let widgets = [
                                <div className={classes.eventHeaderRow} key="stream-event-header-row">
                                    <h1>{t('event.upcomingEvents')}</h1>
                                    <Typography variant={'body2'} className={classes.link} onClick={() => {
                                        if (!hideEvents) {
                                            AUTHSTORAGE.setHideEvents(new Date());
                                            startHelperSetHideEvents(new Date());
                                        } else {
                                            AUTHSTORAGE.setHideEvents(undefined);
                                            startHelperSetHideEvents(undefined);
                                        }
                                        setHideEvents(!hideEvents);
                                    }} >
                                        { hideEvents ? t('common.showMore') : t('event.hide')}
                                    </Typography>
                                </div>
                            ];

                            if (!hideEvents) {
                                displayedEvents.map((event, index) => widgets.push(<NewsEventCard event={event} index={index} key={"stream-event-" + event.id}/>));
                            }
                            extraWidgets.push(<div className={classes.eventSection} key="stream-events-section">{widgets}</div>);

                        }

                        if(extraWidgets.length > 0 || leadingWidgets.length > 0) {
                            return [
                                ...leadingWidgets,
                                <ArticleTeaserContainer article={article} key={"stream-article-" + article.id}/>,
                                ...extraWidgets
                            ];
                        }

                        return <ArticleTeaserContainer article={article} key={"stream-article-" + article.id}/>;
                    })
                }
                {
                    (AUTHSTORAGE.hasPermission(Permission.ARTICLECREATE) || AUTHSTORAGE.hasPermission(Permission.GLOBALARTICLECREATE) ) &&
                    <MoinFab onClick={() => setRedirectToCreate(true)} />
                }

            </div>
        </>
    )
};

export default ArticleStream;
