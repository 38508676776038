import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import parameters from "../../config/parameters.json";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import GroupMemberContainer from "./GroupMemberContainer";
import GroupDetail from "./GroupDetail";
import CardHeader from "@material-ui/core/CardHeader";
import {MoinCard, MoinCardContent, MoinCardHeader, MoinCardHeaderImage} from "../../components/base/MoinCard";
import GroupMenuWrapper from "../../components/group/GroupMenu";
import FireStorageImage from "../../components/base/FirestoreImage";


const useStyles = makeStyles((theme) => ({
    cardContentTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center"
    }
}));


/**
 *
 * @param {Distributor} distributor
 * @param {Array.<String>} userIds
 * @param {Array.<User>} users
 * @returns {JSX.Element}
 * @constructor
 */
const GroupDetailShow = ({distributor}) => {

    const classes = useStyles();
    const [t, i18n] = useTranslation();

    let name = distributor?.name;
    if (distributor?.id === parameters.globalDistributorId) {
        name = t('distributor.global');
    }

    return (
        <MoinCard>
            <MoinCardHeader>
                <CardHeader
                    title={
                        <div className={classes.cardContentTitle}>
                            <Typography variant="h3">{name}</Typography>
                        </div>
                    }

                    action={
                        <GroupMenuWrapper id={distributor.id + '_context_menu'} distributor={distributor}/>
                    }
                />
            </MoinCardHeader>
            <MoinCardContent>
                <GroupMemberContainer  distributorId={distributor.id}>
                    {
                        ({userIds}) => <GroupDetail distributor={distributor} userIds={userIds}/>
                    }
                </GroupMemberContainer>
            </MoinCardContent>
        </MoinCard>
    );
};


export default  GroupDetailShow;