import React, {Suspense, useEffect} from 'react';
import './App.css';
import './grid.css';
import store from "./utils/store/store";
import {Provider} from 'react-redux';
import AuthorizationContainer from "./pages/AuthorizationContainer";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import * as ROUTES from "./Routes";
import MarkdownViewContainer from "./pages/legal/MarkdownViewContainer";
import {MarkdownTarget} from "./pages/legal/MarkdownTarget";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import OnboardingRedirect from "./pages/OnboardingRedirect";


function loadCookieConsent() {
    const script = document.createElement('script');
    script.setAttribute('id', "cct");
    script.src = 'https://gdpr.mandarin-medien.de/manager.js?id=e8119d9b-32e2-11eb-92b7-fa163e4de523';
    document.querySelector('body').prepend(script);

    const scriptListener = document.createElement('script');
    scriptListener.innerHTML = "window.onload = function() {\n" +
        "        mmcm.addListener('save', function(e) {window.location.reload()});\n" +
        "        if (mmcm.isEnabled('google_maps')) {\n" +
        "            let maps = document.createElement('script');\n" +
        "            maps.setAttribute('id', \"google-maps\");\n" +
        "            maps.setAttribute('async', \"true\");\n" +
        "            maps.setAttribute('src', \"https://maps.googleapis.com/maps/api/js?key=AIzaSyADqilxUqsLSQR2wJMZSC83JqtzK-Sj4Fw&libraries=places\");\n" +
        "            document.querySelector('body').append(maps);\n" +
        "        }\n" +
        "    }"
    //document.querySelector('body').append(scriptListener);
}

const BaseRouting = () => {
    return (
        <Switch>
            <Route exact path={ROUTES.dpd}>
                <MarkdownViewContainer target={MarkdownTarget.PRIVACY}/>
            </Route>
            <Route exact path={ROUTES.terms}>
                <MarkdownViewContainer target={MarkdownTarget.TERMS}/>
            </Route>
            <Route exact path={ROUTES.impress}>
                <MarkdownViewContainer target={MarkdownTarget.IMPRESS}/>
            </Route>
            <Route exact path={ROUTES.loginForgotPassword}>
                <ForgotPasswordPage/>
            </Route>
            <Route exact path={ROUTES.onboarding}>
                <OnboardingRedirect />
            </Route>
            <Route path={'/*'} component={AuthorizationContainer}/>
        </Switch>

    )

}

function App() {
    useEffect(() => {
        if (!document.querySelector('#cct')) {
            loadCookieConsent()
        }
    }, []);

  return (
      <Provider store={store}>
        <Suspense fallback="loading">
          <div className="App">
          <Router>
              <BaseRouting/>
          </Router>
          </div>
        </Suspense>
      </Provider>
  );
}

export default App;
