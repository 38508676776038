export const SuccessType = {
    // ARTICLES
    ARTICLEPUBLISHED: "ARTICLEPUBLISHED",
    ARTICLEUPDATED: "ARTICLEUPDATED",
    GROUPCREATED: "GROUPCREATED",
    GROUPUPDATED: "GROUPUPDATED",
    EVENTCREATED: "EVENTCREATED",
    EVENTUPDATED: "EVENTUPDATED",
    PROFILEUPDATED: "PROFILEUPDATED",
    PASSWORDCHANGED: "PASSWORDCHANGED",
    EXTERNALEXISTS: "EXTERNALEXISTS",
    BUGREPORTSENT: "BUGREPORTSENT",
    INVALIDDATA: "INVALIDDATA",
};

