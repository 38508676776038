import React, {useEffect} from "react";
import {getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import Distributor from "../../utils/models/Distributor";
import MenuItem from "@material-ui/core/MenuItem";
import MoinSelect from "./MoinSelect";
import AUTHDISTRIBUTORMEMBERSHIP from "../../utils/auth/AuthDistributorMembership";
import parameters from "../../config/parameters.json";
import {useTranslation} from "react-i18next";
import {sortDistributor} from "../../utils/helper/SortHelper";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Permission} from "../../utils/enums/Permission";
import HIGHLIGHTEDGROUPS from "../../utils/groups/HighlightedGroups";
import PinActiveFilled from "../../assets/icons/pin-active-filled.svg";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";

/**
 * @param {string} selectedDistributorId
 * @param {Function} onSelectDistributor
 * @param {Distributor[]} allDistributor
 * @param {boolean} disabled
 * @param {string} placeholder
 * @param {string} error
 * @param {boolean} executeScroll
 * @returns {JSX.Element}
 * @constructor
 */

const useStyles = makeStyles((theme) => ({
    highlightedGroupIcon: {
        color: Colors.BRANDPRIMARY,
        height: '16px',
        width: '16px',
        marginRight: '4px'
    },
    selectElement: {
        '& .MuiSelect-root': {
            display: 'flex',
            alignItems: 'center'
        }
    }
}));

const MoinGroupSelector = ({selectedDistributorId, onSelectDistributor, placeholder, allDistributor, disabled, error, executeScroll}) => {
    const classes = useStyles();

    const [t, i18n] = useTranslation();
    const allDistributorsSorted = allDistributor.filter(dis => (AUTHDISTRIBUTORMEMBERSHIP.isMemberOfDistributor(dis.id) && !!dis.name) || dis.isPublic).sort(sortDistributor);

    useEffect(() => {
        if (!!selectedDistributorId) {
            onSelectDistributor(allDistributor.find(dis => dis.id === selectedDistributorId));
        }
    }, []);

    return(
        <MoinSelect
            value={selectedDistributorId}
            onChange={(e) => onSelectDistributor(allDistributorsSorted.find(dis => dis.id === e.target.value))}
            disabled={disabled}
            placeholder={placeholder}
            error={error}
            executeScroll={executeScroll}
            className={classes.selectElement}
        >
            {
                allDistributorsSorted.map(dis => {
                    let name = dis?.name;
                    if (dis?.id === parameters.globalDistributorId) {
                        name = t('distributor.global');
                    }
                    if (!!name) {
                        return <MenuItem key={"article-create-distributor-select-" + dis.id} value={dis.id}>{HIGHLIGHTEDGROUPS.isHighlighted(dis.id) && <PinActiveFilled className={classes.highlightedGroupIcon}/>}{name}</MenuItem>
                    }
                })
            }
        </MoinSelect>
    )
}

const mapState = (state) =>  ({
    allDistributor: getModelInstances(state, Distributor).filter( (dis) => (
        (
            dis?.id !==  parameters.globalDistributorId &&
            AUTHSTORAGE.hasPermission(Permission.ARTICLECREATE)
        )
        || (
            AUTHSTORAGE.hasPermission(Permission.GLOBALARTICLECREATE)
            && dis?.id ===  parameters.globalDistributorId
        )
    ))
});

export default connect(mapState)(MoinGroupSelector);
