import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import {Typography} from "@material-ui/core";
import MoreIcon from "../../assets/icons/more.svg";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    option: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: '16px',
            height: '24px',
            fill: Colors.BLACK
        }

    },
    invisible: {
        visibility: 'hidden'
    },
    hidden: {
        display: 'none'
    },
    container: {
        cursor: 'pointer',
        display: 'flex'
    },
    listItem: {
        margin: '0',
        padding: '8px 12px'
    },
    menuSpacing: {
        marginLeft: '-40px'
    },
}));


const defaultPaperProps = {
    color: Colors.BLACK,
    boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '16px'
};

/**
 * Moin! themed simple menu. Automatically aligns the options correctly if there are no leading icons, or not
 * all of them.
 *
 * @param {String} id Needed for screen reader accessibility
 * @param {Array<MoinSimpleMenuOption>}  options
 * @param {boolean} center
 * @param {boolean} noWidth
 * @param {String} listItemClasses
 * @param {String} menuClass
 * @param {String} typography
 * @param {Object} paperStyle
 * @param rest
 * @param {Array<JSX.Element>} [children]
 * @returns {JSX.Element}
 * @constructor
 */
const MoinSimpleMenu = ({id, options = [], children ,center=false,noWidth = false,paperStyle = {},menuClass, listItemClasses, wrapperClass, typography, disabled, ...rest}) => {
    const classes = useStyles();
    const [dialogId, setDialogId] = React.useState(  'long-menu' + (id || Date.now()))
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    let paperPros = Object.assign(defaultPaperProps, paperStyle);


    const handleClick = (event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        event.preventDefault();
        event.stopPropagation();
        return false;
    };

    return (
        <div className={  wrapperClass ? wrapperClass : noWidth ?  classes.menuSpacing : undefined }>
            {
                !!children
                    ? <div onClick={handleClick} className={classes.container}>
                        { children }
                    </div>
                    : <IconButton
                        aria-label="more"
                        aria-controls={'long-menu' + id}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreIcon fill={Colors.BLACKLIGHT} />
                    </IconButton>
            }
            {
                open && <Menu
                    id={dialogId}
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={ center ? { vertical: "bottom", horizontal: "center" } : undefined}
                    transformOrigin={center ? { vertical: "top", horizontal: "center" }: undefined}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: paperPros
                    }}
                    {...rest}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={id + option.label}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                option.action();
                                handleClose(event);
                                return false;
                            }
                            }
                            className={clsx(classes.listItem, listItemClasses ?? {})}
                        >
                            <div className={classes.option}>
                                {option.icon && option.icon }
                                <Typography variant={typography ?? "body2"}>{option.label}</Typography>
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            }
        </div>
    );
}

export default MoinSimpleMenu;