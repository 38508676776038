import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import illustration from "../../assets/illustrations/moin-app-illus-no-notification-muted.png";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {TaskViewType} from "../../utils/enums/TaskViewType";
import MoinButton from "../../components/base/MoinButton";
import {Colors} from "../../components/helper/ColorHelper";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '64px',
        marginBottom: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '768px',
        flex: '100',
    },
    illustration: {
        maxWidth: '45%',
        height: 'auto'
    },
    title: {
        marginBottom: '12px'
    },
    description: {
        textAlign: 'center'
    },
    showAllButton: {
        marginTop: '24px'
    },
    createButton: {
        backgroundColor: Colors.BRANDSECONDARY,
        borderColor: Colors.BRANDSECONDARY,
        marginTop: '24px',
        '&:hover': {
            backgroundColor: Colors.BRANDSECONDARY,
            borderColor: Colors.BRANDSECONDARY
        },
    }
}));

const TaskEmptyState = ({view = TaskViewType.HOME, selectedUser, showCompletedTrigger, setShowAddTask, myTasksView}) => {
    const [t, i18n] = useTranslation();

    const classes = useStyles();

    let title;
    let description

    switch (view) {
        case TaskViewType.MYTASKS:
            title = t('tasks.emptyState.myTitle');
            description = t('tasks.emptyState.myDescription');
            break;
        case TaskViewType.TODAY:
            title = t('tasks.emptyState.todayTitle');
            description = t('tasks.emptyState.todayDescription');
            break;
        case TaskViewType.PLANNED:
            title = t('tasks.emptyState.plannedTitle');
            description = t('tasks.emptyState.plannedDescription');
            break;
        case TaskViewType.ASSIGNEDTOOTHERS:
            title = t('tasks.emptyState.othersTitle');
            description = t('tasks.emptyState.othersDescription');
            break;
        case TaskViewType.COLLEAGUELIST:
            title = t('tasks.emptyState.taskTitle')
            description = t('tasks.emptyState.taskDescription', {user: selectedUser.fullName})
            break;
    }

    if (!title || !description ) {
        return <></>;
    }

    return (
            <div className={classes.paper}>
                {showCompletedTrigger && showCompletedTrigger()}
                <img src={illustration} alt={t('common.illustration')} className={classes.illustration}/>
                <Typography className={classes.title} variant="h3">{title}</Typography>
                <Typography className={classes.description} variant="body2">{description}</Typography>
                {
                    (view === TaskViewType.TODAY || view === TaskViewType.PLANNED) &&
                    <MoinButton className={classes.showAllButton} onClick={myTasksView}>{ t('tasks.showAllTasks') }</MoinButton>
                }
                {
                    (view === TaskViewType.MYTASKS || view === TaskViewType.ASSIGNEDTOOTHERS) &&
                    <MoinButton className={classes.createButton} onClick={() => setShowAddTask(true)}>{ t('tasks.createNewTask') }</MoinButton>
                }
            </div>
    );
};
export default TaskEmptyState;
