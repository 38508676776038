import EventDispatcher from "../manager/manager/eventdispatcher/EventDispatcher";
import HttpRequest from "./HttpRequest";

/**
 * Simple REST Client based on Fetch
 * with simple Event Dispatching
 */
export default class Client extends EventDispatcher {

    constructor(url, props) {
        super();

        this.url = url;
        this.defaultHeaders = {};
        this.props = Object.assign({
            credentials: 'same-origin'
        }, props);

        this.addDefaultHeader = this.addDefaultHeader.bind(this);
        this.removeDefaultHeader = this.removeDefaultHeader.bind(this);
    }


    addDefaultHeader(key, value) {
        this.defaultHeaders[key] = value;
        return this;
    }

    removeDefaultHeader(key)
    {
        delete  this.defaultHeaders[key];
        return this;
    }


    /**
     * GET Request
     * @param path
     * @param data
     * @param options
     * @return {HttpRequest}
     */
    get(path, data, options = {}) {

        return this.__createRequest(this.url + path, Object.assign({
            method: 'GET',
            body: data,
        }, this.props, options))
            .addListener('success', ({data}) => this.dispatch('success', data))
            .addListener('error', ({data}) => this.dispatch('error', data));
    }


    /**
     * POST Request
     * @param path
     * @param data
     * @param options
     * @return {HttpRequest}
     */
    post(path, data, options = {}) {
        return this.__createRequest(this.url + path, Object.assign({
            method: 'POST',
            body: data,
        }, this.props, options))
            .addListener('success', data => this.dispatch('success', data))
            .addListener('error', data => this.dispatch('error', data));
    }


    /**
     * PUT Request
     * @param path
     * @param data
     * @param options
     * @return {HttpRequest}
     */
    put(path, data, options = {}) {
        return this.__createRequest(this.url + path, Object.assign({
            method: 'PUT',
            body: data,
        }, this.props, options))
            .addListener('success', data => this.dispatch('success', data))
            .addListener('error', data => this.dispatch('error', data));
    }


    /**
     * Delete Request
     * @param path
     * @param options
     * @return {HttpRequest}
     */
    delete(path, options = {}) {
        return this.__createRequest(this.url + path, Object.assign({
            method: 'DELETE'
        }, this.props, options, this.defaultOptions))
            .addListener('success', data => this.dispatch('success', data))
            .addListener('error', data => this.dispatch('error', data));
    }


    __createRequest(path, options) {

        if(this.defaultHeaders) {
            if (options.headers) {
                options.headers = Object.assign(options.headers, this.defaultHeaders);
            } else {
                options.headers = this.defaultHeaders;
            }
        }

        return new HttpRequest(path, options);
    }
}
