import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import {Colors} from "../../components/helper/ColorHelper";
import MoinDivider from "../../components/base/MoinDivider";
import {useHistory} from "react-router-dom";
import * as ROUTES from "../../Routes";
import HelpReportDialogContainer from "./HelpReportDialogContainer";

const useStyles = makeStyles((theme) => ({
    pageHeader: {
        paddingBottom: theme.spacing(3)
    },
    helpEntry: {
        padding: theme.spacing(4, 0),
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer"
    },
    entryIcon: {
        fill: Colors.BLACK
    }
}));

const HelpOverview = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const history = useHistory();
    const [showReportProblemDialog, setShowReportProblemDialog] = useState(false);
    const [showSendFeedbackDialog, setShowSendFeedbackDialog] = useState(false);

    const getEntry = (onClick, title, description) => {
        return(
            <div className={classes.helpEntry} onClick={onClick}>
                <div className={classes.entryText}>
                    <Typography variant="h3">{title}</Typography>
                    <Typography variant="caption">{description}</Typography>
                </div>

                <ArrowRightIcon className={classes.entryIcon}></ArrowRightIcon>
            </div>
        )
    }

    return (
        <>
            <HelpReportDialogContainer show={showReportProblemDialog} closeDialog={() => setShowReportProblemDialog(false)} />
            <HelpReportDialogContainer show={showSendFeedbackDialog} closeDialog={() => setShowSendFeedbackDialog(false)} isImproveMoinDialog={true} />
            <Typography variant="h1" className={classes.pageHeader}>{t('nav.helpAndSupport')}</Typography>

            <MoinDivider/>
            { getEntry(() => history.push(ROUTES.faq), t('help.faq'), t('help.faqDescription')) }
            <MoinDivider/>
            { getEntry(() => setShowReportProblemDialog(true), t('help.reportProblem'), t('help.reportProblemDescription')) }
            <MoinDivider/>
            { getEntry(() => setShowSendFeedbackDialog(true), t('help.improveMoin'), t('help.improveMoinDescription')) }
            <MoinDivider/>
        </>
    )
};

export default HelpOverview;
