import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {MoinCard} from "../base/MoinCard";
import {withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FireStorageImage from "../base/FirestoreImage";
import {getUserAvatarPath} from "../../utils/helper/ImageHelper";
import Typography from "@material-ui/core/Typography";
import MoinListItem from "../base/MoinListItem";
import MoinButton from "../base/MoinButton";
import illustration1 from "../../assets/illustrations/birthday/geburtstag-teaser-01.png";
import illustration2 from "../../assets/illustrations/birthday/geburtstag-teaser-02.png";
import illustration3 from "../../assets/illustrations/birthday/geburtstag-teaser-03.png";
import illustration4 from "../../assets/illustrations/birthday/geburtstag-teaser-04.png";
import background from "../../assets/illustrations/birthday/background.png";
import {Colors} from "../helper/ColorHelper";
import OpenChatContainer from "../../pages/OpenChatContainer";
import {DateTime} from "luxon";
import {BirthdayPrivacy} from "../../utils/enums/BirthdayPrivacy";

const useStyles = makeStyles((theme) => ({
     card: {
         display: 'flex',
         flexDirection: 'row',
         position: 'relative',
         justifyContent: 'space-between',
         height: '116px',
         // height: '22vw', //approx 3:1 ratio
         padding: '16px'
     },
    background: {
        position: 'absolute',
        height: '104%',
        width: '150px',
        right: '0',
        top: '0',
        color: Colors.LIGHTPURPLE,
        zIndex: '0',
        marginRight: '-16px'
    },
    elements: {
         display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between'
    },
    illustration: {
        height: '100%',
        width: 'auto',
        zIndex: '2',
        marginRight: '20px'
    },
    congratulateButton: {
         marginLeft: '56px',
        width: 'fit-content'
    },
    user: {
        padding: '0',
        marginBottom: '4px'
    },
    confetti: {
        height: '6px',
        width: '6px',
        borderRadius: '100%',
        zIndex: '2',
        position: 'absolute',
        top: '-6px',
        left: '200px',
        backgroundColor: Colors.CONFETTIDARK,
        animationName: '$confetti',
        animationDuration: '4s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        pointerEvents: 'none'
    },
    '@keyframes confetti': {
        "0%": {
            transform: "translate(0%, 0%)"
        },
        "25%": {
            transform: "translate(-10px, 31px)"
        },
        "50%": {
            transform: "translate(0, 62px)"
        },
        "75%": {
            transform: "translate(10px, 93px)"
        },
        "100%": {
            transform: "translate(0, 124px)"
        }
    }
}));

const BirthdayCard = ({user, index}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const colors = [Colors.CONFETTIDARK, Colors.CONFETTIBLUE, Colors.CONFETTIPURPLE, Colors.CONFETTIYELLOW];
    const illustrations = [illustration1, illustration2, illustration3, illustration4];

    let confetti = [];
    for (let i = 0; i < 15; i++) {
        confetti.push(<div
            className={classes.confetti}
            style={{
                'left': Math.random() * 100 + '%',
                'backgroundColor': colors[Math.floor((Math.random() * 4))],
                'animationDelay': (Math.random() * 5).toString() + 's',
            }}
        />)
    }

    const illuIndex = (index + DateTime.local().day) % 4;

    return <OpenChatContainer uid={user.uid}>
        {
            ({onMessage}) => <MoinCard hasRoundedBorders={true} className={classes.card}>
                <img src={background} alt={'Background element'} className={classes.background}/>
                <div className={classes.elements}>
                    <MoinListItem
                        customClass={classes.user}
                        withSplashEffect={false}
                        withDivider={false}
                        image={<FireStorageImage backgroundColor={Colors.identifierAsColor(user.uid)} radius={24} isAvatar={true} src={getUserAvatarPath(user.uid)} alt={user.fullName}/>}
                        title={
                            <div>
                                <Typography variant="h2">{user.fullName}</Typography>
                                {
                                    user.birthdayPrivacy === BirthdayPrivacy.PUBLIC &&
                                    <Typography variant="caption">{t('birthday.years', {age: user.age})}</Typography>
                                }
                            </div>
                        }
                    />
                    <MoinButton size={'small'} className={classes.congratulateButton} onClick={onMessage}>{t('birthday.congratulate')}</MoinButton>
                </div>
                <img src={illustrations[illuIndex]} alt={'Background element'} className={classes.illustration}/>
                {
                    confetti
                }
            </MoinCard>
        }
    </OpenChatContainer>
};

export default withRouter(BirthdayCard);
