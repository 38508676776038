import MoinTextField from "../base/MoinTextField";
import MoinSearchField from "../base/MoinSearchField";
import InputAdornment from "@material-ui/core/InputAdornment";
import UserIcon from "../../assets/icons/user.svg";
import {Colors} from "../helper/ColorHelper";
import FirestoreImage from "../base/FirestoreImage";
import CloseIcon from "../../assets/icons/close.svg";
import RepeatIcon from "../../assets/icons/repeat.svg";
import {DatePicker} from "@material-ui/pickers";
import EventsInactiveIcon from "../../assets/icons/events-inactive.svg";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import MoinUserAssignmentList from "../base/MoinUserAssignmentList";
import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";
import {RepetitionType} from "../../utils/enums/repetition/RepetitionType";
import {
    getNumberTypes,
    getShortTranslationForWeekdays,
    getTranslationForRepetitionType,
    getTranslationForRepetitionWeekdays, getTranslationsForWeekdays
} from "../../utils/helper/TextHelper";
import {RepetitionWeekdays} from "../../utils/enums/repetition/RepetitionWeekdays";
import Typography from "@material-ui/core/Typography";
import {
    getDateDifferenceInDays,
    getStartOfFirstWeekOfMonth,
    getStartOfLastWeekOfMonth
} from "../../utils/helper/DateHelper";
import {getRepetitionWeekFromInt} from "../../utils/helper/EnumHelper";
import {RepetitionWeek} from "../../utils/enums/repetition/RepetitionWeek";
import {CustomRepetitionIntervalType} from "../../utils/enums/repetition/CustomRepetitionIntervalType";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import TaskRepetition from "../../utils/models/TaskRepetition";
import CustomRepetitionInterval from "../../utils/models/CustomRepetitionInterval";

const useStyles = makeStyles(() => ({
    taskForm: {
        position: 'relative',
        '& input': {
            fontSize: '13px',
            lineHeight: '18px',
            padding: '10px 0',
            height: 'auto'
        },
        '& fieldset': {
            borderColor: 'white !important'
        },
        '&:hover fieldset': {
            borderColor: 'white !important'
        },
        '& .Mui-focused fieldset': {
            borderColor: 'none',
            borderWidth: 0
        },
        '& svg': {
            height: '20px',
            width: '20px'
        }
    },
    withBorder: {
        '& .Mui-focused fieldset': {
            borderColor: Colors.BRANDSECONDARY + ' !important',
            borderWidth: '1px !important'
        }
    },
    taskRow : {
      padding: "0 12px"
    },
    nameTextField: {
        marginBottom: '8px',
        width: 'calc(100% - 48px)',
        '& input': {
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: '500',
            padding: '12px 20px',
            height: '26px'
        },
        '& fieldset': {
            borderColor: 'white !important'
        },
        '&:hover fieldset': {
            borderColor: 'white !important'
        },
        '& .Mui-focused fieldset': {
            borderColor: "none",
            borderWidth: 0
        },
    },
    textField: {
        maxWidth: 'calc(50% - 8px)',
        '& .MuiInputBase-adornedStart': {
            padding: '0 8px'
        },
        '& .MuiIconButton-root': {
            padding: '0'
        },
        '& svg': {
            fill: Colors.BLACKLIGHTER
        },
        '& #date-picker-due-date': {
            flex: 1,
            marginRight: '8px',
            minWidth: '80px'
        }
    },
    textFieldDueDate: {
        '& .MuiFormLabel-root': {
            left: '36px !important'
        }
    },
    descriptionTextField: {
        '& .MuiInputBase-root': {
            padding: '8px 20px'
        }
    },
    withLabel: {
        '& .MuiFormLabel-root': {
            color: Colors.BLACKLIGHT,
            top: '7px',
            left: '40px',
            transform: 'none',
            fontSize: '10px',
            lineHeight: '10px'
        },
        '& input': {
            padding: '10px 0 0',
            fontSize: '10px',
            lineHeight: '14px'
        },
        '& legend': {
            display: 'none'
        },
        '& .MuiInputBase-root': {
            height: '38px'
        },
        '& fieldset': {
            height: '38px',
            marginTop: '5px'
        }
    },
    avatarEdit: {
        marginRight: '8px !important'
    },
    repetitionSettings: {
        marginLeft: 28
    },
    repetitionTypeSelectRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    repetitionIcon: {
        marginRight: 8
    },
    repetition: {
        margin: '0 16px'
    },
    repetitionTypeSelect: {
        margin: 0
    },
    selectDayLabel: {
        color: Colors.BLACK
    },
    selectDayRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: '12px 0'
    },
    selectableDay: {
        marginRight: 4,
        height: 38,
        width: 38,
        border: '2px solid ' + Colors.BRANDPRIMARY,
        borderRadius: 38,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',

        '&.selected': {
            backgroundColor: Colors.BRANDPRIMARY,
        }
    },
    selectableDayText: {
        color: Colors.BRANDPRIMARY,

        '&.selected': {
            backgroundColor: Colors.BRANDPRIMARY,
            color: Colors.WHITE
        }
    },
    repetitionWeekText: {
        margin: '16px 0',
        color: Colors.BLACK
    },
    customRepetitionRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    customRepetitionTypeText: {
        color: Colors.BLACK,
        marginRight: 4
    },
    customRepetitionAmountInput: {
        width: 40,
        marginRight: 8,

        '& .MuiInputBase-root': {
            lineHeight: '1.125rem',
            fontSize: '.8125rem',
            fontStyle: 'normal',
            fontWeight: 700,
            color: Colors.BLACK,
            display: "inline-block"
        }
    }
}));

/**
 *
 * @param title
 * @param description
 * @param dueDate
 * @param assignee
 * @param search
 * @param setSearch
 * @param errors
 * @param {TaskRepetition} repetition
 * @param {Function} setTitle
 * @param {Function} setDescription
 * @param {Function} setDueDate
 * @param {Function} setAssignee
 * @param {Function} setRepetition
 * @param {boolean} isEdit
 * @constructor
 */
const TaskForm = ( {title, description, dueDate, assignee, setTitle, setDescription, setDueDate, setAssignee, setRepetition, search, setSearch, repetition, isEdit = false, errors}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const [showUserList, setShowUserList] = useState(false);
    const selectedRepetitionType = useRef(repetition?.type ?? RepetitionType.NEVER);
    const selectedRepetitionWeek = useRef(repetition?.monthlyRepetitionWeek ?? '');
    const selectedRepetitionWeekdays = useRef(repetition?.dailyRepetitionWeekdays ?? '');
    const selectedRepetitionDays = useRef(repetition?.weeklyRepetitionWeekdays ?? [0, 1, 2, 3, 4]);
    const customRepetitionInterval = useRef(repetition?.customInterval ?? new CustomRepetitionInterval());

    const saveRepetition = () => {
        let taskRepetition = new TaskRepetition();

        switch (selectedRepetitionType.current) {
            case RepetitionType.DAILY:
                taskRepetition.type = RepetitionType.DAILY;
                taskRepetition.dailyRepetitionWeekdays = selectedRepetitionWeekdays.current;
                break;
            case RepetitionType.WEEKLY:
                taskRepetition.type = RepetitionType.WEEKLY;
                taskRepetition.weeklyRepetitionWeekdays = selectedRepetitionDays.current;
                break;
            case RepetitionType.MONTHLY:
                taskRepetition.type = RepetitionType.MONTHLY;
                taskRepetition.monthlyRepetitionWeek = selectedRepetitionWeek.current;
                break;
            case RepetitionType.ANNUAL:
                taskRepetition.type = RepetitionType.ANNUAL;
                break;
            case RepetitionType.CUSTOM:
                taskRepetition.type = RepetitionType.CUSTOM;
                taskRepetition.customInterval = customRepetitionInterval.current.clone();
                break;
            default:
                break;
        }

        setRepetition(taskRepetition);
    }

    const onAssign = (user) => {
        setShowUserList(false);
        setSearch(user.fullName);
        setAssignee(user);
    }

    const onUnassign = () => {
        setAssignee(undefined);
        setSearch('');
        setShowUserList(false);
    }

    const toggleUserList = () => {
        setShowUserList(true);
    }

    const onClearSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setAssignee(undefined);
        setSearch('');
        setShowUserList(false);
    }

    const onSearchFieldBlur = () => {
        // might not be faster otherwise the selection of the users might not work since it's a race between onclick and on blur
        setTimeout (() => {
            setShowUserList((_) => false);
        }, 200);
    }

    const switchTaskRepetitionType = (newRepetitionType) => {
        switch (newRepetitionType) {
            case RepetitionType.DAILY:
                if(!selectedRepetitionWeekdays.current) {
                    selectedRepetitionWeekdays.current = RepetitionWeekdays.WORKDAYS;
                }
                break;
            case RepetitionType.MONTHLY:
                let {totalWeeksInMonth, weekOfRepetitionStart} = calculateDatesForMonthlyRepetition();

                if((weekOfRepetitionStart < 4 || (weekOfRepetitionStart === 4 && totalWeeksInMonth === 5))
                    && (selectedRepetitionWeek.current !== getRepetitionWeekFromInt(weekOfRepetitionStart))) {

                    selectedRepetitionWeek.current = getRepetitionWeekFromInt(weekOfRepetitionStart);
                } else if((weekOfRepetitionStart === 4 && totalWeeksInMonth === 4)
                    && (selectedRepetitionWeek.current !== RepetitionWeek.FOURTH && selectedRepetitionWeek.current !== RepetitionWeek.LAST)) {

                    selectedRepetitionWeek.current = RepetitionWeek.FOURTH;
                } else if(weekOfRepetitionStart === 5 && selectedRepetitionWeek.current !== RepetitionWeek.LAST) {
                    selectedRepetitionWeek.current = RepetitionWeek.LAST;
                }
                break;
            case RepetitionType.CUSTOM:
                if(!customRepetitionInterval.current.repetitionDays) {
                    customRepetitionInterval.current.repetitionDays = [0, 1, 2, 3, 4];
                }

                if(!customRepetitionInterval.current.monthDay) {
                    customRepetitionInterval.current.monthDay = dueDate.getDate();
                }
                break;
        }

        selectedRepetitionType.current = newRepetitionType;
        saveRepetition();
    }

    const renderDaySelection = (selectedDays, setSelectedDays) => {
        const toggleDay = (day) => {
            let newSelectedDays = [...selectedDays];
            if(newSelectedDays.includes(day)) {
                newSelectedDays.splice(newSelectedDays.indexOf(day), 1);
            } else {
                newSelectedDays.push(day);
            }

            setSelectedDays(newSelectedDays);
        };

        const renderDay = (day) => {
            return (
                <div className={`${classes.selectableDay} ${selectedDays.includes(day) ? 'selected': ''}`} onClick={() => toggleDay(day)}>
                    <Typography variant="body2" className={`${classes.selectableDayText} ${selectedDays.includes(day) ? 'selected': ''}`}>{ getShortTranslationForWeekdays(t, day) }</Typography>
                </div>
            );
        }

        return (
            <div className={classes.selectDayRow}>
                { renderDay(0) }
                { renderDay(1) }
                { renderDay(2) }
                { renderDay(3) }
                { renderDay(4) }
                { renderDay(5) }
                { renderDay(6) }
            </div>
        );
    };

    const renderDailyRepetitionSelection = () => {
        return (
            <FormControl
                fullWidth
                variant="standard"
                margin="none"
            >
                <Select
                    size="small"
                    displayEmpty
                    value={selectedRepetitionWeekdays.current}
                    onChange={(e) => {
                        selectedRepetitionWeekdays.current = e.target.value;
                        saveRepetition();
                    }}
                    disableUnderline={true}
                >
                    {
                        Object.values(RepetitionWeekdays).map(repetitionWeekdays => {
                            return (
                                <MenuItem key={"task-form-repetition-weekdays-select-" + repetitionWeekdays} value={repetitionWeekdays}>
                                    <Typography variant="h5" className={classes.customRepetitionTypeText}>{getTranslationForRepetitionWeekdays(t, repetitionWeekdays)}</Typography>
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
        );
    };

    const renderWeeklyRepetitionSelection = () => {
        return (
            <>
                <Typography variant="caption" className={classes.selectDayLabel}>{ t('tasks.repetition.onTheseDays') }</Typography>
                {
                    renderDaySelection(
                        selectedRepetitionDays.current,
                        (e) => {
                            selectedRepetitionDays.current = e;
                            saveRepetition();
                        }
                    )
                }
            </>
        );
    };

    const calculateDatesForMonthlyRepetition = () => {
        let repetitionStartDate = new Date(dueDate);
        let startOfFirstWeek = getStartOfFirstWeekOfMonth(repetitionStartDate);
        let startOfLastWeek = getStartOfLastWeekOfMonth(repetitionStartDate);
        let startOfWeekAfterLastWeek = new Date(startOfLastWeek);
        startOfWeekAfterLastWeek.setDate(startOfWeekAfterLastWeek.getDate() + 7);
        let totalWeeksInMonth = Math.floor(getDateDifferenceInDays(startOfFirstWeek, startOfLastWeek) / 7) + 1;

        let weekOfRepetitionStart = 1;

        if(repetitionStartDate.getTime() < startOfWeekAfterLastWeek.getTime()) {
            weekOfRepetitionStart = Math.floor(getDateDifferenceInDays(startOfFirstWeek, repetitionStartDate) / 7) + 1;
        }

        return {repetitionStartDate: repetitionStartDate, totalWeeksInMonth: totalWeeksInMonth, weekOfRepetitionStart: weekOfRepetitionStart};
    }

    const renderMonthlyRepetitionSelection = () => {
        let {repetitionStartDate, totalWeeksInMonth, weekOfRepetitionStart} = calculateDatesForMonthlyRepetition();

        // If the weekday is not the last in the month
        if(weekOfRepetitionStart < 4 || (weekOfRepetitionStart === 4 && totalWeeksInMonth === 5)) {
            return (
                <Typography variant="caption" className={classes.repetitionWeekText}>{ t('tasks.repetition.everyXthDayOfTheMonth', { 'number': getNumberTypes(t, weekOfRepetitionStart), 'weekday': getTranslationsForWeekdays(t, repetitionStartDate.getDay()) }) }</Typography>
            )
        } else if(weekOfRepetitionStart === 4 && totalWeeksInMonth === 4) {
            // If the selected day is in week 4 of the month, and the month has 4 weeks

            return (
                <FormControl
                    fullWidth
                    variant="standard"
                    margin="none"
                >
                    <Select
                        size="small"
                        displayEmpty
                        value={selectedRepetitionWeek.current}
                        onChange={(e) => {
                            selectedRepetitionWeek.current = e.target.value;
                            saveRepetition();
                        }}
                        disableUnderline={true}
                    >
                        <MenuItem key={"task-form-repetition-week-select-RepetitionWeek.FOURTH"} value={RepetitionWeek.FOURTH}>
                            <Typography variant="h5" className={classes.customRepetitionTypeText}>{ t('tasks.repetition.everyXthDayOfTheMonth', { 'number': t('tasks.repetition.fourth'), 'weekday': getTranslationsForWeekdays(t, repetitionStartDate.getDay()) }) }</Typography>
                        </MenuItem>
                        <MenuItem key={"task-form-repetition-week-select-RepetitionWeek.LAST"} value={RepetitionWeek.LAST}>
                            <Typography variant="h5" className={classes.customRepetitionTypeText}>{ t('tasks.repetition.everyLastDayOfTheMonth', { 'weekday': getTranslationsForWeekdays(t, repetitionStartDate.getDay()) }) }</Typography>
                        </MenuItem>
                    </Select>
                </FormControl>
            );
        } else if(weekOfRepetitionStart === 5) {
            // If the selected day is in week 5 of the month

            return (
                <Typography variant="caption" className={classes.repetitionWeekText}>{ t('tasks.repetition.everyLastDayOfTheMonth', { 'weekday': getTranslationsForWeekdays(t, repetitionStartDate.getDay()) }) }</Typography>
            )
        }
    };

    const renderCustomRepetitionSelection = () => {
        return (
            <>
                <div className={classes.customRepetitionRow}>
                    <Typography variant="caption" className={classes.customRepetitionTypeText}>{ customRepetitionInterval.current.type === CustomRepetitionIntervalType.WEEK ? t('every') : t('everyAlt') }</Typography>

                    <TextField
                        size="small"
                        variant="standard"
                        margin="none"
                        onChange={(e) => {
                            let newInterval = customRepetitionInterval.current.clone();
                            newInterval.amount = parseInt(e.target.value);

                            if(!isNaN(newInterval.amount) ) {
                                if (newInterval.amount > 99) {
                                    newInterval.amount = 99;
                                }

                                if (newInterval.amount < 1) {
                                    newInterval.amount = 1;
                                }

                                customRepetitionInterval.current = newInterval;
                                saveRepetition();
                            }
                        }}
                        className={classes.customRepetitionAmountInput}
                        inputProps={{ maxLength: 2, min: 1, max: 99 }}
                        InputProps={{ disableUnderline: true }}
                        value={customRepetitionInterval.current.amount}
                        type="number"
                    />

                    <FormControl variant="standard" margin="none">
                        <Select
                            size="small"
                            value={customRepetitionInterval.current.type}
                            onChange={(e) => {
                                let newInterval = customRepetitionInterval.current.clone();
                                newInterval.type = e.target.value;
                                customRepetitionInterval.current = newInterval;
                                saveRepetition();
                            }}
                            disableUnderline={true}
                        >
                            <MenuItem key={"task-form-custom-repetition-type-select-CustomRepetitionIntervalType.WEEK"} value={CustomRepetitionIntervalType.WEEK}>
                                <Typography variant="h5" className={classes.customRepetitionTypeText}>{ t('tasks.repetition.week') }</Typography>
                            </MenuItem>
                            <MenuItem key={"task-form-custom-repetition-type-select-CustomRepetitionIntervalType.MONTH"} value={CustomRepetitionIntervalType.MONTH}>
                                <Typography variant="h5" className={classes.customRepetitionTypeText}>{ t('tasks.repetition.month') }</Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>

                    {
                        customRepetitionInterval.current.type === CustomRepetitionIntervalType.MONTH &&
                            <>
                                <Typography variant="caption" className={classes.customRepetitionTypeText}>{ t('tasks.repetition.onThe') }</Typography>
                                <FormControl variant="standard" margin="none">
                                    <Select
                                        size="small"
                                        value={customRepetitionInterval.current.monthDay}
                                        onChange={(e) => {
                                            let newInterval = customRepetitionInterval.current.clone();
                                            newInterval.monthDay = e.target.value;
                                            customRepetitionInterval.current = newInterval;
                                            saveRepetition();
                                        }}
                                        disableUnderline={true}
                                    >
                                        {
                                            Array.from(Array(31), (e,i) => i+1).map(monthDay => {
                                                return <MenuItem key={"task-form-custom-repetition-monthday-select-" + monthDay} value={monthDay}><Typography variant="h5" className={classes.customRepetitionTypeText}>{ monthDay }.</Typography></MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </>
                    }
                </div>

                {
                    customRepetitionInterval.current.type === CustomRepetitionIntervalType.WEEK &&
                        <>
                            <Typography variant="caption" className={classes.selectDayLabel}>{ t('tasks.repetition.onTheseDays') }</Typography>
                            {
                                renderDaySelection(
                                    customRepetitionInterval.current.repetitionDays,
                                    (newDays) => {
                                        let newInterval = customRepetitionInterval.current.clone();
                                        newInterval.repetitionDays = newDays;
                                        customRepetitionInterval.current = newInterval;
                                        saveRepetition();
                                    }
                                )
                            }
                        </>
                }
            </>
        );
    };

    const renderRepetitionSettings = () => {
        if(selectedRepetitionType.current === RepetitionType.DAILY) {
            return renderDailyRepetitionSelection();
        }

        if(selectedRepetitionType.current === RepetitionType.WEEKLY) {
            return renderWeeklyRepetitionSelection();
        }

        if(selectedRepetitionType.current === RepetitionType.MONTHLY) {
            return renderMonthlyRepetitionSelection();
        }

        if(selectedRepetitionType.current === RepetitionType.CUSTOM) {
            return renderCustomRepetitionSelection();
        }
    }

    if (typeof dueDate === 'number') {
        dueDate = new Date(dueDate);
    }

    return (
        <div>
            <MoinTextField
                required
                className={classes.nameTextField}
                autoFocus={false}
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder={t('tasks.taskName')}
                error={errors['title']}
                style={isEdit ? {width: '100%', marginBottom: '0'} : {}}
            />
            <div className={classes.taskForm}>
                <div className={classes.taskRow}>
                    <MoinSearchField
                        onChange={e => setSearch(e.target.value)}
                        className={isEdit && assignee
                            ? clsx(classes.textField, classes.withBorder, classes.withLabel)
                            : clsx(classes.textField, classes.withBorder)
                        }
                        style={isEdit ? {margin: '8px 16px 8px 0'} : {marginRight: '16px'}}
                        placeholder={isEdit ? t('tasks.unassigned') :  t('tasks.assignee')}
                        label= {isEdit && assignee ? t('tasks.assignedTo') : ''}
                        value={search}
                        autoFocus={false}
                        fullWidth={false}
                        onClick={() => {
                            toggleUserList()
                        }}
                        onBlur={() => {
                            onSearchFieldBlur()
                        }}
                        InputProps={
                            assignee
                                ? {
                                    startAdornment: (
                                        <FirestoreImage
                                            key={'avatar' + assignee.id}
                                            alt={assignee.fullName}
                                            src={assignee.avatar}
                                            style={{
                                                height: '24px',
                                                width: '24px',
                                                fontSize: '12px',
                                                marginRight: '4px'
                                            }}
                                            className={isEdit ? classes.avatarEdit : ''}
                                            backgroundColor={Colors.identifierAsColor(assignee.id)}
                                            isAvatar={true}>
                                            {`${assignee.firstName[0]}${assignee.lastName[0]}`}
                                        </FirestoreImage>
                                    ),
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{'cursor': 'pointer', flex: 1}}
                                            onClick={(e) => onClearSearch(e)}>
                                            <CloseIcon fill={Colors.BLACKLIGHTEST}/>
                                        </InputAdornment>
                                    )
                                }
                                : {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <UserIcon fill={Colors.BLACKLIGHTER}/>
                                        </InputAdornment>
                                    )
                                }
                        }
                    />

                    <MoinUserAssignmentList
                        search={search}
                        onAssign={onAssign}
                        onUnassign={onUnassign}
                        show={showUserList}
                        assignee={assignee}
                    />

                    <DatePicker
                        margin="normal"
                        autoOk={true}
                        id="date-picker-due-date"
                        format="d. MMM. yyyy"
                        inputVariant="outlined"
                        value={ dueDate || null}
                        className={isEdit && dueDate
                            ? clsx(classes.textField, classes.textFieldDueDate, classes.withLabel)
                            : clsx(classes.textField)
                        }
                        fullWidth={false}
                        autoFocus={false}
                        placeholder={t('tasks.dueDate')}
                        label={isEdit && dueDate ? t('tasks.dueDate') : ''}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EventsInactiveIcon/>
                                </InputAdornment>
                            ),
                            endAdornment: dueDate && (
                                <InputAdornment
                                    position="end"
                                    style={{cursor: "pointer", flex: 1}}
                                    onClick={(e) => {e.preventDefault(); e.stopPropagation(); setDueDate(undefined); return false;}}>
                                    <CloseIcon/>
                                </InputAdornment>
                            )
                        }}
                        onChange={(d) => {
                            setDueDate(d);
                        }}
                    />
                </div>

                {
                    !!dueDate &&
                    <div className={classes.repetition}>
                        <div className={classes.repetitionTypeSelectRow}>
                            <RepeatIcon className={classes.repetitionIcon} fill={Colors.BLACKLIGHTER}/>
                            <FormControl
                                fullWidth
                                variant="standard"
                                margin="none"
                                className={classes.repetitionTypeSelect}
                            >
                                <Select
                                    size="small"
                                    displayEmpty
                                    renderValue={(repetitionType) => {
                                        if(!repetitionType) {
                                            return <Typography variant="caption">{t('tasks.repetition.repeat')}</Typography>;
                                        } else {
                                            return <Typography variant="h5" className={classes.customRepetitionTypeText}>{getTranslationForRepetitionType(t, repetitionType)}</Typography>;
                                        }
                                    }}
                                    value={selectedRepetitionType.current === RepetitionType.NEVER ? '' : selectedRepetitionType.current}
                                    onChange={(e) => switchTaskRepetitionType(e.target.value)}
                                    disableUnderline={true}
                                >
                                    {
                                        Object.values(RepetitionType).map(repetitionType => {
                                            return (
                                                <MenuItem key={"task-form-repetition-type-select-" + repetitionType} value={repetitionType}>
                                                    <Typography variant="h5" className={classes.customRepetitionTypeText}>{getTranslationForRepetitionType(t, repetitionType)}</Typography>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.repetitionSettings}>
                            {
                                renderRepetitionSettings()
                            }
                        </div>
                    </div>
                }

                <MoinTextField
                    placeholder={isEdit ? t('tasks.enterDescription') :  t('tasks.description')}
                    multiline={true}
                    rowsMax={8}
                    rows={8}
                    style={isEdit ? {margin: '0'} : {}}
                    autoFocus={false}
                    value={description ?? ''}
                    onChange={ (e) => setDescription(e.target.value) }
                    className={classes.descriptionTextField}
                />
            </div>
        </div>
    );
}

export default TaskForm;
