import Event from "./Event";

/**
 * Custom Simple Event Dispatcher Class
 * allows creating and sending custom events in class context
 */
class EventDispatcher
{
    constructor() {
        this._listeners = {};
    }


    /**
     * add listsner
     * @param event
     * @param callable
     */
    addListener(event, callable)
    {
        if(typeof this._listeners[event] === 'undefined')
            this._listeners[event] = [];

        this._listeners[event].push(callable);

        return this;
    }

    /**
     * alias fo addListener
     */
    on()
    {
        this.addListener.apply(this, arguments);
    }


    /**
     * remove listener
     * @param event
     * @param callable
     * @return {EventDispatcher}
     */
    removeListener(event, callable)
    {
        if(typeof this._listeners[event] !== 'undefined') {

            if (typeof callable === "undefined") {
                delete this._listeners[event];
            }

            else {

                const index = this._listeners[event].indexOf(callable);
                if(index >= 0) {
                    this._listeners[event].splice(index, 1);
                }
            }
        }

        return this;
    }


    /**
     * dispatch event
     * @param event
     * @param data
     * @throws
     */
    dispatch(event, data = null)
    {
        if(typeof event === 'string')
            event = new Event(event, data);

        if(typeof event === "object" && !(event instanceof Event))
            throw 'invalid event object given';

        if(typeof this._listeners[event.name] !== 'undefined')
            this._listeners[event.name].forEach((callable) => {callable.call(null, event)});
    }


}

export default EventDispatcher;