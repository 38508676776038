import React, {useEffect, useRef, useState} from 'react';
import FireStorageImage from "../../components/base/FirestoreImage";
import {getArticleTitlePath, getUserAvatarPath} from "../../utils/helper/ImageHelper";
import {makeStyles} from "@material-ui/core/styles";
import {ContentType} from "../../utils/enums/ContentType";
import FireStorageVideo from "../../components/base/FirestoreVideo";
import {Divider, Typography} from "@material-ui/core";
import PdfPreview from "../../components/article/PdfPreview";
import ArticleSurveyContainer from "../../components/article/ArticleSurveyContainer";
import RenderedCommentContainer from "../../components/article/comments/RenderedCommentContainer";
import CreateCommentContainer from "../../components/article/comments/CreateCommentContainer";
import {toDateString} from "../../utils/helper/TextHelper";
import {useTranslation} from "react-i18next";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import MoinSimpleMenuOption from "../../components/base/MoinSimpleMenuOption";
import {Permission} from "../../utils/enums/Permission";
import MoinSimpleMenu from "../../components/base/MoinSimpleMenu";
import {Redirect, useHistory, withRouter} from "react-router-dom";
import * as ROUTES from "../../Routes";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import MoinButton from "../../components/base/MoinButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MoinCard, MoinCardHeaderImage} from "../../components/base/MoinCard";
import MoinListItem from "../../components/base/MoinListItem";
import parameters from "../../config/parameters.json";
import MoinLinkifiedText from "../../components/base/MoinLinkifiedText";
import {Colors} from "../../components/helper/ColorHelper";
import ReactionRowContainer from "../../components/article/ReactionRowContainer";
import ArticleReaction from "../../utils/models/ArticleReaction";
import ArticleTeaserTags from "../../components/article/ArticleTeaserTags";
import DeleteIcon from "../../assets/icons/delete.svg";
import HIGHLIGHTEDARTICLES from "../../utils/articles/HighlightedArticles";
import PinActiveFilled from "../../assets/icons/pin-active-filled.svg";
import {Role} from "../../utils/enums/Role";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import Article from "../../utils/models/Article";
import Distributor from "../../utils/models/Distributor";
import Survey from "../../utils/models/Survey";
import Comment from "../../utils/models/Comment";
import User from "../../utils/models/User";
import {
    addModelInstanceDispatch,
    deleteModelInstanceDispatch,
    updateModelInstanceDispatch
} from "../../utils/store/actions";
import {connect} from "react-redux";
import FAVORITEDARTICLES from "../../utils/articles/FavoritedArticles";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        width: '100%',
        padding: theme.spacing(0, 2),
        margin: theme.spacing(1.5, 0)
    },
    highlightedArticleLabel: {
        margin: theme.spacing(3, 2, 0),
        color: Colors.BRANDPRIMARY,
        display: 'flex',
        alignItems: 'center'
    },
    highlightedArticleIcon: {
        color: Colors.BRANDPRIMARY,
        height: '16px',
        width: '16px',
        marginRight: '4px'
    },
    articleTitleImageWrapper: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        overflow: 'hidden',
        position: 'relative'
    },
    articleTitleImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: "cover"
    },
    articleContentWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '16px',
        marginBottom: '8px'
    },
    articleHeaderText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    articleMedia: {
        width: '100%',
        maxHeight: "480px",
        objectFit: "cover"
    },
    articleMediaCaption: {
        marginTop: '4px'
    },
    articleText: {
        marginLeft: '16px',
        marginRight: '16px',
        whiteSpace: "pre-wrap"
    },
    articleTitle: {
        marginTop: '24px',
        marginLeft: '16px',
        marginRight: '16px'
    },
    articleIsHighlightedTitle: {
        marginTop: '16px',
        marginLeft: '16px',
        marginRight: '16px'
    },
    comment: {
        margin: theme.spacing(1.75, 2, 1.5, 2)
    },
    articleActionsWrapper: {
        margin: "0 16px 12px"
    },
    createCommentWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    contentAlignment: {
        alignItems: "normal"
    },
    deleteDialogActions: {
        justifyContent: 'space-between'
    },
    pdfBackground: {
        backgroundColor: Colors.WHITE,
        height: "64px",
        border: "1px solid",
        borderColor: Colors.BLUELIGHT,
        borderRadius: "8px",
        width: "100%",
    },
    deletedMessage: {
        width: '100%',
        padding: theme.spacing(0, 2),
        margin: theme.spacing(1.5, 0),
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    deletedIcon: {
        fill: Colors.WHITE
    },
    deletedIconWrapper: {
        backgroundColor: Colors.BLACKLIGHTEST,
        width: 48,
        height: 48,
        borderRadius: "48px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    articleDeletedText: {
        color: Colors.BLACKLIGHTER,
        marginLeft: "8px"
    }
}));

/**
 * @param {Article} article
 * @param {Article} oldestHighlightedArticle
 * @param {Distributor} group
 * @param {Comment[]} comments
 * @param {boolean} deleting
 * @param {Function} loadMoreComments
 * @param {boolean} loadingComments
 * @param {Function} deleteArticle
 * @param {Function} toggleArticleHighlight
 * @param {Function} toggleArticleFavorited
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleDetail = ({article,oldestHighlightedArticle ,group, comments,  loadMoreComments, loadingComments=false, deleting = false, deleteArticle, toggleArticleHighlight, toggleArticleFavorited}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const feedWrapper = useRef();
    const history = useHistory();
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [redirectToCopy, setRedirectToCopy] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showHighlightDialog, setShowHighlightDialog] = useState(false);


    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle, true);
        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [comments]);


    const onScrollHandle = () => {
        if(!!feedWrapper.current && feedWrapper.current.getBoundingClientRect().bottom <= window.innerHeight + 250 && !!comments[comments.length-1]) {
            loadMoreComments(comments[comments.length-1].cAt);
        }
    };

    if(!article) {
        return <div/>;
    }

    if(redirectToEdit) {
        return (
            <Redirect push to={ROUTES.articleEdit.replace(':id', article.id)}/>
        );
    }

    if(redirectToCopy) {
        return (
            <Redirect push to={ROUTES.articleCopy.replace(':id', article.id)}/>
        );
    }

    const contextMenuOptions = [];

    if(FAVORITEDARTICLES.isFavorited(article.id)) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('article.favorite.removeFromFavorites'), () => toggleArticleFavorited()));
    } else {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('article.favorite.addToFavorites'), () => toggleArticleFavorited()));
    }

    if(article.cUid === AUTHSTORAGE.getUserId()) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.edit'), () => setRedirectToEdit(true)));
    }

    if( AUTHSTORAGE.hasPermission(Permission.ARTICLECREATE)) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.copy'), () => setRedirectToCopy(true)));
    }

    if(article.cUid === AUTHSTORAGE.getUserId() || AUTHSTORAGE.hasPermission(Permission.ARTICLEDELETE)) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.delete'), () => setShowDeleteDialog(true)));
    }

    if(group?.id === parameters.globalDistributorId
        && (article.cUid === AUTHSTORAGE.getUserId() || (AUTHSTORAGE.hasRole(Role.ADMIN) || AUTHSTORAGE.hasRole(Role.CLIENTMANAGER)))
    ) {
        if(HIGHLIGHTEDARTICLES.isHighlighted(article.id)) {
            contextMenuOptions.push(new MoinSimpleMenuOption( t('article.highlight.removeHighlight'), () => toggleArticleHighlight()));
        } else {
            contextMenuOptions.push(new MoinSimpleMenuOption( t('article.highlight.highlightArticle'), () => {
                if (HIGHLIGHTEDARTICLES.canHighlightMoreArticles()) {
                    toggleArticleHighlight();
                } else {
                    setShowHighlightDialog(true);
                }
            }));
        }
    }

    const getDeleteDialog = () => {
        return (
            <Dialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('article.delete') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">{ t('article.deleteDescription', {article: article.title, distributor: group?.name ?? t('distributor.deletedGroup')}) }</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={() => setShowDeleteDialog(false)} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    {
                        deleting &&
                        <div className="moin-circular-progress-container">
                            <CircularProgress />
                        </div>
                    }
                    <MoinButton onClick={deleteArticle}>{ t('buttons.delete') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    };

    const getHighlightDialog = () => {
        return (
            <Dialog
                open={showHighlightDialog}
                onClose={() => setShowHighlightDialog(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('article.highlight.fixArticle') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        { t('article.highlight.maximumAmountOfArticlesForHighlight') }
                    </DialogContentText>
                    <DialogContentText id="delete-dialog-description">
                        {!!oldestHighlightedArticle && oldestHighlightedArticle.title}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={() => setShowHighlightDialog(false)} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    <MoinButton onClick={() => {
                        toggleArticleHighlight();
                        setShowHighlightDialog(false);
                    }}>{ t('article.highlight.fixArticle') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    };


    /**
     * @param {ArticleContent} content
     * @param {int} index
     * @returns {JSX.Element}
     */
    const renderSingleContent = (content, index) => {
        switch (content.type) {
            case ContentType.TEXT:
                return <p key={"article-" + article.id + "-content-" + index} className={classes.articleText}><MoinLinkifiedText text={content.text} /></p>;
            case ContentType.MEDIA:
                if(!!content.media && content.media.length > 0 && !content.iTI) {

                    let images = [];

                    content.media.map(
                        (media, mediaIndex) => {
                            images.push(
                                <div key={"article-" + article.id + "-content-" + index + '-media-' + mediaIndex} className={classes.articleContentWrapper}>
                                    <FireStorageImage lightboxEnabled={true} src={media} className={classes.articleMedia}/>
                                </div>
                            )
                        }
                    )
                    return <>{images}</>;
                } else if(!!content.image && !content.iTI) {
                    return (
                        <div key={"article-" + article.id + "-content-" + index} className={classes.articleContentWrapper}>
                            <FireStorageImage lightboxEnabled={true} src={content.image} className={classes.articleMedia}/>
                            {
                                !!content.text &&
                                    <div className={classes.articleMediaCaption}>
                                        <Typography variant="subtitle1"><MoinLinkifiedText text={content.text} /></Typography>
                                    </div>
                            }
                        </div>
                    );
                } else if(!!content.video) {
                    return (
                        <div key={"article-" + article.id + "-content-" + index} className={classes.articleContentWrapper}>
                            <FireStorageVideo src={content.video.url} className={classes.articleMedia}/>
                            {
                                !!content.text &&
                                    <div className={classes.articleMediaCaption}>
                                        <Typography variant="subtitle1"><MoinLinkifiedText text={content.text} /></Typography>
                                    </div>
                            }
                        </div>
                    );
                }
                break;
            case ContentType.SURVEY:
                return (
                    <div key={"article-" + article.id + "-content-" + index} className={classes.articleContentWrapper}>
                        <ArticleSurveyContainer id={content.surveyId} article={article}/>
                    </div>
                );
            case ContentType.PDF:
                return(
                    <div key={"article-" + article.id + "-content-" + index} className={classes.articleContentWrapper} style={{margin: "0 16px"}}>
                        <PdfPreview content={content} className={classes.pdfBackground}/>
                    </div>
                );
        }
    };

    const renderArticleContent = () => {
        return (
            <>
                <MoinListItem
                    itemKey={"article_user_header_"+article.id}
                    withSplashEffect={false}
                    withDivider={false}
                    image={
                        <FireStorageImage
                            isAvatar={true}
                            src={getUserAvatarPath(article.cUid)}
                            alt={article.cName}
                            backgroundColor={Colors.identifierAsColor(article.cUid)}
                        />}
                    title={article.cName}
                    subtitle={name + " • " + toDateString(article.cAt)}
                    customClass={classes.cardHeader}
                    trailing={contextMenuOptions.length > 0 ? [ <MoinSimpleMenu key={"article_simple_menu_"+article.id} options={contextMenuOptions}/>] : undefined}
                    onClick={
                        () => history.push(ROUTES.userShow.replace(':id', article.cUid))
                    }
                />
                {
                    article.hTI && <MoinCardHeaderImage>
                        <FireStorageImage
                            lightboxEnabled={true}
                            fontSize={64}
                            className={classes.articleTitleImage}
                            src={getArticleTitlePath(article)}
                            alt={article.title}
                        />
                        <ArticleTeaserTags article={article} />
                    </MoinCardHeaderImage>
                }

                <Divider/>

                {
                    HIGHLIGHTEDARTICLES.isHighlighted(article.id) &&
                    <div className={classes.highlightedArticleLabel}>
                        <PinActiveFilled className={classes.highlightedArticleIcon}/>
                        {t('article.highlight.highlightedArticle')}
                    </div>
                }

                <div className={HIGHLIGHTEDARTICLES.isHighlighted(article.id) ? classes.articleIsHighlightedTitle : classes.articleTitle}>
                    <Typography variant="h3">{article.title}</Typography>
                </div>

                {
                    article.content.map((content, index) => renderSingleContent(content, index))
                }
            </>
        );
    }

    const renderDeletedArticle = () => {
        return (
            <div className={classes.deletedMessage}>
                <div className={classes.deletedIconWrapper}><DeleteIcon className={classes.deletedIcon}/></div>
                <Typography variant="body2" className={classes.articleDeletedText}>{ article.dBy === article.cUid ? t('article.deletedByCreator') : t('article.deletedByAdmin') }</Typography>
            </div>
        );
    }

    let name = group?.name ?? t('distributor.deletedGroup');
    if (group?.id === parameters.globalDistributorId) {
        name = t('distributor.global');
    }

    return (
        <>
            {
                getDeleteDialog()
            }
            {
                getHighlightDialog()
            }
            <MoinCard className={classes.contentAlignment}>
                {
                    !!article && !article.dAt &&
                        renderArticleContent()
                }

                {
                    !!article && !!article.dAt &&
                        renderDeletedArticle()
                }

                <div className={classes.articleActionsWrapper}>
                   <ReactionRowContainer
                       model={article}
                       modelPath={"article"}
                       reactionModel={ArticleReaction}
                       isComment={false}
                       reactionCount={article.heart}
                       showCommentButtons={!!article && !article.dAt}
                       allReactions={article.reactions}
                       commentCount={article.cCount}
                       onLikeCountTap={() => history.push(ROUTES.articleLikeList.replace(":id", article.id))}
                       showLikeButton={!!article && !article.dAt}
                   />
                </div>

                {
                    comments.map(comment => (
                        <div key={"article-" + article.id + "-comment-" + comment.id}>
                            <Divider/>
                            <RenderedCommentContainer comment={comment} article={article}/>
                        </div>
                    ))
                }

                {/*ScrollCheck to load more Comments*/}
                <div ref={(node) => feedWrapper.current = node}/>
                {
                    loadingComments &&
                    <div className="moin-circular-progress-container">
                        <CircularProgress />
                    </div>
                }
                <Divider/>
                <div className={classes.createCommentWrapper}>
                    <CreateCommentContainer article={article}/>
                </div>

            </MoinCard>
        </>
    );
};


const mapState = (state, ownProps) => {
    const article = getModelInstance(state, Article, HIGHLIGHTEDARTICLES.getOldestHighlightedArticle());

    return {
        oldestHighlightedArticle: article,
    };
};

export default connect(mapState, {})(withRouter(ArticleDetail));
