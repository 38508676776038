import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {
    Typography,
} from "@material-ui/core";
import UserListItem from "../../components/user/MoinUserListItem";
import * as ROUTES from "../../Routes";
import {Link, useHistory, withRouter} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import {Colors} from "../../components/helper/ColorHelper";
import {FilterState} from "../../utils/enums/FilterState";
import MoinButton from "../../components/base/MoinButton";
import GroupTag from "../../components/group/GroupTag";
import MoinListItem from "../../components/base/MoinListItem";
import FireStorageImage from "../../components/base/FirestoreImage";
import {MoinCard} from "../../components/base/MoinCard";
import LocationIcon from "../../assets/icons/location.svg";
import {getStartToEnd, getTranslationForDateFilterRange} from "../../utils/helper/TextHelper";
import EventsInactiveIcon from "../../assets/icons/events-inactive.svg";
import {getModelInstances} from "../../utils/store/selectors";
import Article from "../../utils/models/Article";
import ArticleTeaserContainer from "../../components/article/ArticleTeaserContainer";
import {connect} from "react-redux";
import MoinSelect from "../../components/base/MoinSelect";
import {DateFilterRange} from "../../utils/enums/DateFilterRange";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
    resultFilter: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        marginTop: "16px",
    },
    filterChip: {
        marginRight: '8px'
    },
    filterChipInActive: {
        backgroundColor: Colors.LIGHTPURPLE,
        borderColor: Colors.LIGHTPURPLE,
        color: Colors.BRANDPRIMARY,
        marginRight: '8px'
    },
    resultHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "32px",
        marginBottom: "8px",
        justifyContent: "space-between"
    },
    linkText: {
        color: Colors.BRANDSECONDARY,
        cursor: "pointer",
        textDecoration: "none",
    },
    link: {
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
    },
    eventCard: {
        alignItems: "start",
        paddingLeft: "0",
        paddingRight: "0",
    },
    eventCardContent: {
        width: '100%',
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    eventCardSubtitle: {},
    eventCardIconRow: {
        display: "flex",
        alignItems: "center"
    },
    eventCardAdditional: {
        marginLeft: "52px"
    }
}))


/**
 *
 * @param users
 * @param groups
 * @param events
 * @param articles
 * @param articleIds
 * @param articleTotal
 * @param loadMoreArticles
 * @param loadingArticles
 * @returns {JSX.Element}
 * @constructor
 */
const SearchPage = ({users, groups, events, articles, selectedDateRange, onSelectDateRange, articleIds, articleTotal, loadMoreArticles}) => {
    const theme = useTheme();
    const [t, i18n] = useTranslation();
    const classes = useStyles();
    const [filter, setFilter] = useState(FilterState.ALL);
    const [showUsers, setShowUsers] = useState(FilterState.LIMITED);
    const [showGroups, setShowGroups] = useState(FilterState.LIMITED);
    const [showEvents, setShowEvent] = useState(FilterState.LIMITED);
    const limitSize = 5;
    const history = useHistory();
    const articleWrapper = useRef();

    useEffect(() => {
            window.addEventListener('scroll', onScrollHandle, true);
            return () => {
                window.removeEventListener('scroll', onScrollHandle, true);
            }
        }, [articleIds] // needs to be articleIDs otherwise it calls an old Instance of loadMoreArticles
    );

    const onScrollHandle = () => {
        if (articleTotal>0 && articleIds.length < articleTotal && !!articleWrapper.current && articleWrapper.current.getBoundingClientRect().bottom <= window.innerHeight + 250 ) {
            loadMoreArticles();
        }
    }

    return <>
        <div className={classes.resultFilter}>
            <MoinButton
                size="small"
                onClick={(e) => {
                    setFilter(FilterState.ALL);
                }}
                className={filter !== FilterState.ALL ? classes.filterChipInActive : classes.filterChip}
            >
                {t('search.all')}
            </MoinButton>

            {
                users && users.length > 0 &&
                <MoinButton
                    size="small"
                    onClick={(e) => {
                        setFilter(FilterState.CONTACTS)
                    }}
                    className={filter !== FilterState.CONTACTS ? classes.filterChipInActive : classes.filterChip}
                >
                    {t('search.contacts')}
                </MoinButton>
            }

            {
                groups && groups.length > 0 &&
                <MoinButton
                    size="small"
                    onClick={(e) => {
                        setFilter(FilterState.GROUPS)
                    }}
                    className={filter !== FilterState.GROUPS ? classes.filterChipInActive : classes.filterChip}
                >
                    {t('search.groups')}
                </MoinButton>
            }

            {
                events && events.length > 0 &&
                <MoinButton
                    size="small"
                    onClick={(e) => {
                        setFilter(FilterState.EVENTS)
                    }}
                    className={filter !== FilterState.EVENTS ? classes.filterChipInActive : classes.filterChip}
                >
                    {t('search.events')}
                </MoinButton>
            }

            {
                articles && articles.length > 0 &&
                <MoinButton
                    size="small"
                    onClick={(e) => {
                        setFilter(FilterState.ARTICLES)
                    }}
                    className={filter !== FilterState.ARTICLES ? classes.filterChipInActive : classes.filterChip}
                >
                    {t('search.articles')}
                </MoinButton>
            }

        </div>

        {
            users && users.length > 0 && (filter === FilterState.ALL || filter === FilterState.CONTACTS) &&
            <>
                <div className={classes.resultHeader}>
                    <Typography variant="h4">
                        {t('search.headlines.contacts', {count: users.length})}
                    </Typography>
                    {
                        users.length > limitSize &&
                        <Typography variant="body2" className={classes.linkText}
                                    onClick={() => setShowUsers(showUsers === FilterState.ALL ? FilterState.LIMITED : FilterState.ALL)}>
                            {showUsers === FilterState.ALL ? t('search.showLess') : t('search.showAll')}
                        </Typography>
                    }
                </div>
                <Divider/>

                {users.map((user, index, collection) => {
                        if (showUsers === FilterState.ALL || index < limitSize) {
                            return (
                                <Link key={'userLink' + index} className={classes.link} to={ROUTES.userShow.replace(':id', user.id)}>
                                    <UserListItem
                                        divider={
                                            (index !== collection.length - 1)
                                            && (showUsers !== FilterState.LIMITED || index !== limitSize - 1)
                                        }
                                        key={index}
                                        user={user}
                                    />
                                </Link>
                            )
                        }
                    }
                )}
            </>
        }

        {
            groups && groups.length > 0 && (filter === FilterState.ALL || filter === FilterState.GROUPS) &&
            <>
                <div className={classes.resultHeader}>
                    <Typography variant="h4">
                        {t('search.headlines.groups', {count: groups.length})}
                    </Typography>
                    {
                        groups.length > limitSize &&
                        <Typography variant="body2" className={classes.linkText}
                                    onClick={() => setShowGroups(showGroups === FilterState.ALL ? FilterState.LIMITED : FilterState.ALL)}>
                            {showGroups === FilterState.ALL ? t('search.showLess') : t('search.showAll')}
                        </Typography>
                    }
                </div>
                <Divider/>

                {groups.map((group, index, collection) => {
                        if (showGroups === FilterState.ALL || index < limitSize) {
                            return (
                                <GroupTag
                                    distributorId={group.id}
                                    withoutActions={true}
                                    key={"distributor-tag-" + group.id}
                                />
                            )
                        }
                    }
                )}
            </>
        }

        {
            events && events.length > 0 && (filter === FilterState.ALL || filter === FilterState.EVENTS) &&
            <>
                <div className={classes.resultHeader}>
                    <Typography variant="h4">
                        {t('search.headlines.events', {count: events.length})}
                    </Typography>
                    {
                        events.length > limitSize &&
                        <Typography variant="body2" className={classes.linkText}
                                    onClick={() => setShowEvent(showEvents === FilterState.ALL ? FilterState.LIMITED : FilterState.ALL)}>
                            {showEvents === FilterState.ALL ? t('search.showLess') : t('search.showAll')}
                        </Typography>
                    }
                </div>
                <Divider/>

                {events.map((event, index, collection) => {
                        if (showEvents === FilterState.ALL || index < limitSize) {
                            return (
                                <MoinCard key={'event' + index} hasRoundedBorders={true} className={classes.eventCard}>

                                    <MoinListItem
                                        withDivider={false}
                                        withSplashEffect={true}
                                        image={
                                            <FireStorageImage
                                                isAvatar={true}
                                                radius={24}
                                                className={classes.avatar}
                                                alt={event.title}
                                                src={event.avatar}
                                                backgroundColor={Colors.identifierAsColor(event.id)}
                                            />
                                        }
                                        title={event.title}
                                        onClick={() => history.push(ROUTES.eventShow.replace(":id", event.id))}
                                        customClass={classes.eventCardContent}
                                    >
                                        <div className={classes.eventCardAdditional}>
                                            <div className={classes.eventCardIconRow}>
                                                <EventsInactiveIcon fill={Colors.BLACKLIGHT} style={{height: "0.8125rem"}}/>
                                                <Typography
                                                    variant="caption">{getStartToEnd(event.start, event.end)}</Typography>
                                            </div>
                                            {
                                                event.location &&
                                                <div className={classes.cardContentRow}>
                                                    <LocationIcon fill={Colors.BLACKLIGHT} style={{height: "0.8125rem"}}/>
                                                    <Typography variant="caption">{event.location}</Typography>
                                                </div>
                                            }
                                        </div>
                                    </MoinListItem>
                                </MoinCard>
                            );
                        }
                    }
                )}
            </>
        }

        {
            ((selectedDateRange && filter === FilterState.ARTICLES) || (articleIds && articleIds.length > 0 && (filter === FilterState.ALL || filter === FilterState.ARTICLES))) &&
            <div ref={(node) => articleWrapper.current = node}>
                <div className={classes.resultHeader}>
                    <Typography variant="h4">
                        {t('search.headlines.articles', {count: articleTotal})}
                    </Typography>
                    {
                        articleTotal > articleIds.length &&
                        <Typography variant="body2" className={classes.linkText} onClick={() => loadMoreArticles()}>
                            {t('search.loadMore')}
                        </Typography>
                    }
                </div>
                <Divider/>

                {
                    filter === FilterState.ARTICLES &&
                        <div>
                            <MoinSelect
                                value={selectedDateRange}
                                onChange={(e) => onSelectDateRange(e.target.value)}
                            >
                                {
                                    Object.values(DateFilterRange).map((value) => {
                                        return (
                                            <MenuItem key={value} value={value}>{ getTranslationForDateFilterRange(t, value) }</MenuItem>
                                        )
                                    })
                                }
                            </MoinSelect>
                        </div>
                }

                {
                    articleIds.map((id, index, collection) => {
                        let article = articles.find((article) => article.id === id);
                        if (article) {
                            return <ArticleTeaserContainer article={article} key={"article-" + article.id}/>
                        }
                    })
                }
            </div>
        }
    </>
}

const mapState = (state, ownProps) => ({
    articles: getModelInstances(state, Article),
});

export default connect(mapState)(withRouter(SearchPage));
