import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import React, {useEffect, useState} from "react";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../components/helper/ColorHelper";
import MoinSearchField from "../../components/base/MoinSearchField";

const useStyles = makeStyles(() => ({
    root: {
        color: Colors.BRANDSECONDARY,
        textTransform: "none",
        textDecoration: "none",
        cursor: 'pointer',

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    content: {
        paddingLeft: '16px',
        paddingRight: '16px'
    }
}));

/**
 * @param {User[]} initialSelectedUsers
 * @param {function} finishSelection
 * @return {JSX.Element}
 * @constructor
 */
const SelectHugUsersView = ({initialSelectedUsers, finishSelection}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [selectedUsers, setSelectedUsers] = useState({});
    const [search, setSearch] = useState("");

    useEffect(() => {
        let initUserMap = {};
        initialSelectedUsers.forEach(user => initUserMap[user.id] = user);

        setSelectedUsers(initUserMap);
    }, []);

    const onUserSelection = (user) => {
        let userId = user.id;

        let currentUsers = Object.assign({}, selectedUsers);

        if(currentUsers.hasOwnProperty(userId)) {
            delete currentUsers[userId];
        } else {
            currentUsers[userId] = user;
        }

        setSelectedUsers(currentUsers);
    }

    return (
        <MoinCard>
            <MoinCardHeader className={classes.header}>
                {/* Placeholder, so that the header text is centered */}
                <div style={{width: '40px'}}/>
                <Typography variant="h3">
                    {t('hugs.selectColleagues')}
                </Typography>

                <Typography variant="body2" className={classes.root} onClick={() => finishSelection(Object.values(selectedUsers))}>
                    {t('hugs.done')}
                </Typography>
            </MoinCardHeader>
            <MoinCardContent className={classes.content}>
                <MoinSearchField
                    onChange={e => setSearch(e.target.value)}
                    placeholder={t('distributor.placeholder.searchMembers')}
                    style={{width: '100%', marginTop: "8px"}}
                    value={search}
                    onClear={e => setSearch('')}
                />

                <SearchableSelectableUserList
                    search={search}
                    selectedUsers={Object.keys(selectedUsers)}
                    onSelect={onUserSelection}
                />
            </MoinCardContent>
        </MoinCard>

    )
};

export default SelectHugUsersView;