import {id, model, prop} from "../manager/decorators";

@model('client/:client/dis_member')
class DistributorMembership {

    @id()
    id;


    @prop()
    dis;

    distributorId;
}

export default DistributorMembership;

