import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import ApiClient from "../../api/ApiClient";
import {MessagingType} from "../../utils/enums/MessagingType";
import MentionHelper from "../../utils/helper/MentionHelper";
import {MentionType} from "../../utils/enums/MentionType";

const addNotification = async (body, authUserName) => {
    body['cUid'] = AUTHSTORAGE.getUserId();
    body['cName'] = authUserName;

    let request = await ApiClient.createRequest('/data/notifications/add', body);
    await request.send();
};

/**
 * @param {Chat} chat
 * @param {Message} message
 * @param {string} authUserName
 */
export const sendNewMessageInChatNotification = async (chat, message, authUserName) => {
    let body = {
        'cUid': AUTHSTORAGE.getUserId(),
        'cName': authUserName,
        'chatId': chat.id,
        'messageId': message.id,
        'message': message.text
    };

    let request = await ApiClient.createRequest('/data/chat/newMessage', body);
    await request.send();
};

/**
 * @param {Article} article
 * @param {Comment} comment
 * @param {string} authUserName
 */
export const sendCommentAtArticleNotification = (article, comment, authUserName) => {
    if(article.cUid !== comment.cUid) {
        addNotification({
            'type': MessagingType.COMMENTATARTICLE,
            'cId': comment.id,
            'aId': article.id,
            'tUid': article.cUid
        }, authUserName);
    }

    addNotification({
        'type': MessagingType.COMMENTATCOMMENTEDARTICLE,
        'cId': comment.id,
        'aId': article.id
    }, authUserName);
};

/**
 * @param {string} articleId
 * @param {Comment} comment
 * @param {string} answerId
 * @param {string} authUserName
 */
export const sendAnswerAtCommentNotification = (articleId, comment, answerId, authUserName) => {
    addNotification({
        'type': MessagingType.ANSWERATCOMMENT,
        'cId': comment.id,
        'aId': articleId,
        'answerId': answerId,
        'tUid': comment.cUid
    }, authUserName);
};

/**
 * @param {Article} article
 * @param {Comment} comment
 * @param {string} authUserName
 */
export const sendMentionedInCommentNotification = (article, comment, authUserName) => {
    let allMentions = MentionHelper.getAllMentionsInText(comment.text);
    let mentionedUids = [];

    allMentions.forEach((mention) => {
        if(mention.entityType === MentionType.USER) {
            mentionedUids.push(mention.entityId);
        }
    });

    if(mentionedUids.length > 0) {
        addNotification({
            'type': MessagingType.MENTIONEDINCOMMENT,
            'uids': JSON.stringify(mentionedUids),
            'cId': comment.id,
            'aId': article.id
        }, authUserName);
    }
};

/**
 * @param {Article} article
 * @param {string} authUserName
 */
export const sendReactionAtArticleNotification = (article, authUserName) => {
    if(article.cUid !== AUTHSTORAGE.getUserId()) {
        addNotification({
            'type': MessagingType.HEARTATARTICLE,
            'aId': article.id
        }, authUserName);
    }
};

/**
 * @param {Chat} chat
 * @param {string} oldName
 * @param {string} authUserName
 */
export const sendChatNameChangedNotification = (chat, oldName, authUserName) => {
    addNotification({
        'type': MessagingType.CHATNAMECHANGED,
        'chatId': chat.id,
        'chatName': chat.name,
        'oldChatName': oldName,
        'onlyPush': 'true'
    }, authUserName);
};