import Chat from "../../utils/models/Chat";
import {connect} from "react-redux";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import Message from "../../utils/models/Message";
import ChatDetail from "./ChatDetail";
import React, {useEffect, useRef, useState} from "react";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import User from "../../utils/models/User";
import {addModelInstanceDispatch, updateModelInstance} from "../../utils/store/actions";
import {generateRandomId} from "../../utils/helper/RandomHelper";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import {addChatMembersData, changeChatMembersAllData} from "../../utils/helper/ChatHelper";
import * as httpClient from "../../utils/httpclient/HttpClient";
import parameters from "../../config/parameters.json";
import { v4 as uuid } from 'uuid';
import EmptyState from "../../components/base/EmptyState";
import {sendNewMessageInChatNotification} from "../../components/helper/NotificationHelper";
import {FireAuth, FireIncrement, FireTimestamp} from "../../api/FirebaseService";

/**
 * @param {Chat} chat
 * @param {Message[]} messages
 * @param {User} authUser
 * @param {User?} otherUser
 * @param {Function} addModelInstanceDispatch
 * @returns {JSX.Element}
 * @constructor
 */
const ChatDetailContainer = ({chat, messages, authUser,addModelInstanceDispatch, otherUser}) => {
    const sending = useRef(false);
    const loading = useRef(false);
    const noMoreMessages = useRef(false);
    const [sortedMessages, setSortedMessage] = useState(messages);
    const videoUploadIds = useRef([]);
    const videoUploadFutures = useRef([]);
    const newMessageId = useRef();
    const hasLoadedInitialMessages = useRef(false);
    const firebaseStorageManager = new FirebaseStorageManager();
    const batchSize = 10;

    useEffect(() => {
        setSortedMessage(messages.filter(msg => msg.chatId === chat.id).sort((a, b) => b.cAt - a.cAt));
    }, [messages]);

    useEffect(() => {
        if (!!chat) {
            if(!chat.isTemp && chat.uMC !== 0) {
                chat.uMC = 0;
                updateModelInstance(Chat, chat);
                ModelManager.update({customData: {uMC: 0}, resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/chat`, id: chat.id, stopEvents: true});
            }

            const chatMessages = messages.filter(msg => msg.chatId === chat.id).sort((a, b) => b.cAt - a.cAt);
            setSortedMessage(chatMessages);

            if(!hasLoadedInitialMessages.current && !!chat && (!chatMessages || chatMessages.length < batchSize)) {
                loading.current = true;
                hasLoadedInitialMessages.current = true;
                ModelManager.list({model: Message, resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/chat/${chat.id}/message` , orderBy: 'cAt', orderDirection: 'desc', limit: 20, extraProps: {chat: chat}})
                    .then( (result) =>   loading.current = false)
            }
        }

    }, [chat]);

    const loadOlderMessages = (lastCreatedAt) => {
        if(!loading.current && !noMoreMessages.current) {
            loading.current = true;
            ModelManager.list({model: Message, resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/chat/${chat.id}/message` , startAfter: lastCreatedAt ,orderBy: 'cAt', orderDirection: 'desc', limit: batchSize, extraProps: {chat: chat}})
                .then(  (result) => {
                    setTimeout(() => {
                        if(result.length === 0) {
                            noMoreMessages.current = true;
                        }
                        loading.current = false;
                    }, 1000)
                });
        }
    };

    const uploadImages = async (message, images) => {
        let imageUrls = [];
        let uploadFutures = [];

        images.forEach(image => {
            let fileExtension = image.name.split('.');
            fileExtension = fileExtension[fileExtension.length - 1];

            const filePath = `client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/message/${message.id}/${generateRandomId()}.${fileExtension}`;
            imageUrls.push(filePath);

            uploadFutures.push(firebaseStorageManager.uploadImage(image, filePath, 1200 , 1));
        });

        await Promise.all(uploadFutures);
        return imageUrls;
    };

    const uploadDocuments = async (message, documents) => {
        let finalDocuments = [];
        let uploadFutures = [];

        documents.forEach(document => {
            let fileExtension = document.name.split('.');
            fileExtension = fileExtension[fileExtension.length - 1];

            const filePath = `client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/message/${message.id}/${generateRandomId()}.${fileExtension}`;
            finalDocuments.push({
                path: filePath,
                name: document.name,
                size: document.size
            });

            uploadFutures.push(firebaseStorageManager.uploadFile(document, filePath));
        });

        await Promise.all(uploadFutures);
        return finalDocuments;
    };

    const onRemoveVideo = (index) => {
        videoUploadIds.current = videoUploadIds.current.filter((image, i) => i !== index);
    };

    const onAddVideo = async (video) => {
        if(!newMessageId.current) {
            newMessageId.current = uuid();
        }

        const uploadId = generateRandomId();
        videoUploadIds.current.push(uploadId);

        // DO NOT ADD A Content-Type Header, the server will crash
        let httpClientInstance = new httpClient.default(parameters.backendVideoUrl, {
            headers: {
                'Authorization': `Bearer ${await FireAuth.currentUser.getIdToken()}`
            }
        });

        const formData = new FormData();

        formData.append('uploadId', uploadId);
        formData.append('messageId', newMessageId.current);
        formData.append('chatId', chat.id);
        formData.append('video', video);

        let request = httpClientInstance.post('/message/upload', formData);
        let sendFuture = request.send();
        videoUploadFutures.current.push(sendFuture);
        await sendFuture;
    };

    const compressVideos = async () => {
        await Promise.all(videoUploadFutures.current);

        let compressFutures = [];
        let idToken = await FireAuth.currentUser.getIdToken();

        videoUploadIds.current.forEach((uploadId) => {
            let formData = new FormData();
            formData.append('uploadId', uploadId);

            let httpClientInstance = new httpClient.default(parameters.backendVideoUrl, {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            });

            let request = httpClientInstance.post('/convert', formData);

            compressFutures.push(request.send());
        });

        await Promise.all(compressFutures);
    };

    const getVideoUrls = (message) => {
        let videos = [];

        videoUploadIds.current.forEach(uploadId => {
            videos.push({
                url: `client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/message/${message.id}/${uploadId}`,
                thumbnail: `client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/message/${message.id}/${uploadId}_thumbnail`,
                uploadId: uploadId
            });
        });

        return videos;
    };

    /**
     * @param {string} text
     * @param {File[]} images
     * @param {File[]} documents
     * @param {File[]} videos
     * @return {Promise<void>}
     */
    const sendMessage = async (text, images, documents, videos) => {
        if(!sending.current || !chat.isTemp) {
            if (chat.isTemp) {
                await ModelManager.add({modelInstance: chat, stopEvents: true});
                chat.isTemp = false;
                updateModelInstance(Chat, chat);
                await changeChatMembersAllData(chat);
                await addChatMembersData(chat, authUser);
            }

            if(!!text || (!!images && images.length > 0) || (!!documents && documents.length > 0) || (!!videos && videos.length > 0)) {
                sending.current = true;

                // Create Message Model Instance and add Content to Message
                const message = new Message();
                message.chatId = chat.id;
                message.cName = authUser.fullName;
                message.cUid = AUTHSTORAGE.getUserId();
                message.sentTo = chat.member.map(member => member.uid);

                if(!!newMessageId.current) {
                    message.id = newMessageId.current;
                    newMessageId.current = undefined;
                }

                message.text = text;

                if(!!images && images.length > 0) {
                    message.images = images.map(img => URL.createObjectURL(img));
                }

                if(!!videoUploadIds.current && videoUploadIds.current.length > 0) {
                    message.videos = getVideoUrls(message);
                }

                if(!!documents && documents.length > 0) {
                    message.docs = [];
                    documents.forEach(doc => {
                        message.docs.push({name: doc.name, path: URL.createObjectURL(doc), size: doc.size});
                    });
                }

                // Clone Message and add unencrypted Message to cache
                let unencryptedMessage = Object.assign(new Message(), message);
                unencryptedMessage.cAt = new Date();

                addModelInstanceDispatch(Message, unencryptedMessage);

                // Upload Images and Documents and send Message to Chat
                if(!!images && images.length > 0) {
                    message.images = await uploadImages(message, images);
                }

                if(!!documents && documents.length > 0) {
                    message.docs = await uploadDocuments(message, documents);
                }
                unencryptedMessage = Object.assign(new Message(), message);
                unencryptedMessage.cAt = new Date();

                let encryptedMessage = chat.encryptMessage(message);

                await ModelManager.add({modelInstance: encryptedMessage, resource: `/client/${AUTHSTORAGE.getClientId()}/chat/${chat.id}/message`, extra: {chat: chat, isNew: true, unencryptedMessage: unencryptedMessage}});
                if(videoUploadIds.current.length > 0) {
                    // Start compression of Videos
                    await compressVideos();
                }

                // Link message to chat members
                let messageLinkFutures = [];

                const chatLinkData = {
                    'cAt': FireTimestamp()
                };

                const unreadMessageData = {
                    'uMC': FireIncrement(1)
                };

                chat.member.forEach(user => {
                    const userChatRef = `/client/${AUTHSTORAGE.getClientId()}/user/${user.uid}/chat`;
                    messageLinkFutures.push(ModelManager.update({customData: chatLinkData, resource: userChatRef + `/${chat.id}/message`, id: encryptedMessage.id, useSet: true}));
                    messageLinkFutures.push(ModelManager.update({customData: unreadMessageData, resource: userChatRef, id: chat.id, useSet: true}));
                });

                await Promise.all(messageLinkFutures);

                if(videoUploadIds.current.length > 0) {
                    ModelManager.subscribeDoc({model: Message, documentPath: `/client/${AUTHSTORAGE.getClientId()}/chat/${chat.id}/message/${encryptedMessage.id}`, extraProps: {chat: chat, completionCheck: true, unencryptedMessage: unencryptedMessage}});
                }
                videoUploadIds.current = [];
                sending.current = false;

                sendNewMessageInChatNotification(chat, unencryptedMessage, authUser.fullName);
            }
        }
    };

    if (!chat) {
        return <EmptyState/>
    }

    return <ChatDetail
        chat={chat}
        messages={sortedMessages}
        sendMessage={sendMessage}
        loadOlderMessages={loadOlderMessages}
        onAddVideo={onAddVideo}
        onRemoveVideo={onRemoveVideo}
        otherUser={otherUser}
    />
};

const mapState = (state, ownProps) => {
    const chat = getModelInstance(state, Chat, ownProps.id);
    let otherMemberUid;
    if (chat?.isSingleChat ?? false) {
        if (chat.member.length > 1) {
            otherMemberUid = chat.member.filter(member => member.uid !== AUTHSTORAGE.getUserId())[0].uid;
        }
    }
    return {
        chat: chat,
        messages: getModelInstances(state, Message),
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId()),
        otherUser: getModelInstance(state, User, otherMemberUid)
    }
};

const mapAction = {
    addModelInstanceDispatch
};

export default connect(mapState, mapAction)(ChatDetailContainer);
