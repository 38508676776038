import {MessageType} from "../enums/MessageType";
import {HugType} from "../enums/HugType";
import {RepetitionWeek} from "../enums/repetition/RepetitionWeek";

export function messageTypeToHugType(messageType) {
    let types = {
        [MessageType.HUGHEART]: HugType.HEART,
        [MessageType.HUGPRAY]: HugType.PRAY,
        [MessageType.HUGIDEA]: HugType.IDEA,
        [MessageType.HUGFUNNY]: HugType.FUNNY,
        [MessageType.HUGCLAP]: HugType.CLAP,
        [MessageType.HUGBEE]: HugType.BEE,
    };

    return types[messageType];
}

export function hugTypeToMessageType(type) {
    let types = {
        [HugType.HEART]: MessageType.HUGHEART,
        [HugType.PRAY]: MessageType.HUGPRAY,
        [HugType.IDEA]: MessageType.HUGIDEA,
        [HugType.FUNNY]: MessageType.HUGFUNNY,
        [HugType.CLAP]: MessageType.HUGCLAP,
        [HugType.BEE]: MessageType.HUGBEE,
    };

    return types[type];
}

export function getIntFromRepetitionWeek(type) {
    let types = {
        [RepetitionWeek.FIRST]: 1,
        [RepetitionWeek.SECOND]: 2,
        [RepetitionWeek.THIRD]: 3,
        [RepetitionWeek.FOURTH]: 4,
        [RepetitionWeek.FIFTH]: 5
    };

    return types[type];
}

export function getRepetitionWeekFromInt(type) {
    let types = {
        1: RepetitionWeek.FIRST,
        2: RepetitionWeek.SECOND,
        3: RepetitionWeek.THIRD,
        4: RepetitionWeek.FOURTH,
        5: RepetitionWeek.FIFTH
    };

    return types[type];
}
