import {
    ADD_MODEL_INSTANCE,
    ADD_MODEL_INSTANCES,
    DELETE_MODEL_INSTANCE, DELETE_MODEL_INSTANCES,
    SET_MODEL_INSTANCES,
    UPDATE_MODEL_INSTANCE, UPDATE_MODEL_INSTANCES
} from "./actions";
import ModelManager from "../manager/ModelManager";

export default (state = {}, action) => {

    const {type, model, data} = action;

    if(!model)
        return state;

    if(!ModelManager.knows(model))
        throw 'can not perform state update as the given model "'+model.name+'" is not registered to the ModelManager';

    let newState = {...state};
    let collection = newState[model.key] ? newState[model.key] : [];

    switch (type) {
        case ADD_MODEL_INSTANCE: {
            const index = collection.findIndex(instance => model.id(instance) === model.id(data));

            if(index === -1) {
                collection.push(data);
            } else {
                collection.splice(index, 1, data);
            }
            newState[model.key] = [...collection];
            break;
        }

        case DELETE_MODEL_INSTANCE: {
            const index = collection.findIndex(modelInstance => modelInstance[model.metadata.idProp] === data[model.metadata.idProp]);
            if(index >= 0) {
                collection.splice(index, 1);
            }
            newState[model.key] = [...collection];
            break;
        }

        case DELETE_MODEL_INSTANCES: {
            const remainingElements = collection.filter(modelInstance => data.value !== modelInstance[data.field] );
            newState[model.key] = [...remainingElements];
            break;
        }

        case UPDATE_MODEL_INSTANCE: {
            const index = collection.findIndex(modelInstance => modelInstance[model.metadata.idProp] === data[model.metadata.idProp]);
            if(index >= 0) {
                if (model.key === "chat") {

                    let chat = collection[index];

                    if (!data.lastMessage && chat && chat.lastMessage) {
                        data.lastMessage = chat.lastMessage;
                    }
                    if (chat && chat.member && chat.member.length >0 && (!data.member || data.member.length === 0)) {
                        data.member = chat.member;
                        data.count = chat.count;
                    }
                    if(chat && chat.sample && chat.sample.length >0 && (!data.sample || data.sample.length === 0)) {
                        data.sample = chat.sample;
                    }
                    if(chat && chat.isCorrectlyDecrypted()) {
                        data.symKey = chat.symKey;
                    }
                }
                collection.splice(index, 1, data);
            } else {
                collection.push(data);
            }
            newState[model.key] = [...collection];
            break;
        }

        case UPDATE_MODEL_INSTANCES: {
            data.forEach( ( entry) => {
                const index = collection.findIndex(modelInstance => modelInstance[model.metadata.idProp] === entry[model.metadata.idProp]);
                if(index >= 0) {
                    if (model.key === "chat") {
                        let chat = collection[index];

                        if (!entry.lastMessage && chat && chat.lastMessage) {
                            entry.lastMessage = chat.lastMessage;
                        }
                        if (chat && chat.member && chat.member.length >0 && (!entry.member || entry.member.length === 0)) {
                            entry.member = chat.member;
                            entry.count = chat.count;
                        }
                        if(chat && chat.sample && chat.sample.length >0 && (!entry.sample || entry.sample.length === 0)) {
                            entry.sample = chat.sample;
                        }
                        if(chat && chat.isCorrectlyDecrypted()) {
                            entry.symKey = chat.symKey;
                        }
                    }
                    collection.splice(index, 1, entry);
                } else {
                    collection.push(entry);
                }
            })
            newState[model.key] = [...collection];
            break;
        }

        case SET_MODEL_INSTANCES: {
            collection = data;
            newState[model.key] = [...collection];
            break;
        }

        case ADD_MODEL_INSTANCES: {
            data.map(element => {
                let index = collection.findIndex(el => el.id === element.id);
                if(index >= 0) {
                    collection[index] = element;
                } else {
                    collection.push(element);
                }
            });

            newState[model.key] = [...collection];
            break;
        }
        default:
            return newState;
    }
    if (model.key === "user" && newState[model.key] ) {
        newState[model.key] = newState[model.key].filter( (user) => !user.deleted && user.enabled);
    }
    return newState;
}
