import React, {useEffect, useState} from 'react';

import {useTranslation} from "react-i18next";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import MoinSearchField from "../../components/base/MoinSearchField";
import {Colors} from "../../components/helper/ColorHelper";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";

import ChatBubbleOutlineIcon from "../../assets/icons/chats-inactive.svg";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Feature} from "../../utils/enums/Feature";


const useStyles = makeStyles(() => ({
    content: {
        padding: '16px',
        width: '100%'
    },
    input: {
        marginTop: '8px'
    },
    iconRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: "center",
        cursor: "pointer"
    },
    iconContainer: {
        borderRadius: "100%",
        backgroundColor: Colors.BRANDSECONDARY,
        width: "3rem",
        height: "3rem",
        marginRight: "1rem",
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fill: Colors.WHITE,
        width: "1.5rem",
        height: "1.5rem",
    },
}));

/**
 *
 * @param {boolean} saving
 * @param {Function} onSave
 * @returns {JSX.Element}
 * @constructor
 */
const SingleChatCreate = ({saving, onSave}) => {
    const classes = useStyles();

    const [t] = useTranslation();
    const [redirectToCreate, setRedirectToCreate] = useState(false);
    const [search, setSearch] = useState();


    const validate = (user)  => {
        if (!saving) {

            if (!!user && !!user.id) {
                onSave({selectedUser: user} )
            }
        }

    }

    if (redirectToCreate) {
        return <Redirect push to={ROUTES.messagesCreate}/>
    }

    return (
        <MoinCard>
            <MoinCardHeader>
                <Typography variant="h3">
                    {t('chat.selectContacts')}
                </Typography>
            </MoinCardHeader>
            <MoinCardContent>
                <div className={classes.content}>
                    <MoinSearchField
                        onChange={e => setSearch(e.target.value)}
                        placeholder={t('chat.placeholder.searchContacts')}
                        style={{width: '100%', marginBottom: "20px"}}
                        onClear={e => setSearch('')}

                    />

                    <div className={classes.iconRow} onClick={() => setRedirectToCreate(true)}>
                        <div className={classes.iconContainer}>
                            <ChatBubbleOutlineIcon className={classes.icon}/>
                        </div>
                       <Typography variant={"body1"}> {t('chat.newGroupChat')}</Typography>
                    </div>

                    <SearchableSelectableUserList search={search} onSelect={(user) => validate(user)} withInviteExternal={true} showCheckbox={false} withSort={false} useExternals={!!AUTHSTORAGE.getClient().paid_features && AUTHSTORAGE.getClient().paid_features.includes(Feature.EXTERNAL)}  referer={ROUTES.messagesSingleCreate}/>

                </div>
            </MoinCardContent>
        </MoinCard>
    );
};

export default SingleChatCreate;