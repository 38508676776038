import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next"
import translationEN from '../../translations/en/common.json';
import translationDE from '../../translations/de/common.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    }
};

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",
        fallbackLng: "en", // use en if detected lng is not available

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;