import {connect} from "react-redux";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import Article from "../../utils/models/Article";
import React, {useEffect, useState} from "react";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {ReactionType} from "../../utils/enums/ReactionType";
import ReactionList from "./ReactionList";
import {updateModelInstance} from "../../utils/store/actions";
import User from "../../utils/models/User";

/**
 * @param {string} id
 * @param {Article} article
 * @param {User[]} users
 * @constructor
 */
const ArticleReactionListContainer = ({id, article, users}) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!article) {
            ModelManager.get({model: Article, id: id}).then(article => loadArticleReactions(article));
        } else if(!article.allReactions || article.reactions.length === 0 || (article.reactions.length === 1 && !!article.reactions[AUTHSTORAGE.getUserId()])) {
            loadArticleReactions(article);
        }
    }, []);

    const loadArticleReactions = async (article) => {
        setLoading(true);

        let heartDocs = await ModelManager.list({
            resource: 'client/' + AUTHSTORAGE.getClientId() + '/article/' + article.id + '/heart/'
        })

        let reactions = {};

        Object.keys(heartDocs).forEach((key)  => {
            if (key === "_all") {
                let allData = heartDocs[key];
                for(let userId of Object.keys(allData)) {
                    if((typeof allData[userId]) === 'string') {
                        reactions[userId] = allData[userId];
                    } else {
                        reactions[userId] = ReactionType.LIKE;
                    }
                }
            } else {
                reactions[key] = ReactionType.LIKE;
            }
        });

        article.allReactions = reactions;
        updateModelInstance(Article, article);

        setLoading(false);
    }

    return <ReactionList allReactions={article?.allReactions} users={users} loading={loading}/>;
}

const mapState = (state, ownProps) => ({
    article:  getModelInstance(state, Article, ownProps.id),
    users: getModelInstances(state, User)
});

export default connect(mapState)(ArticleReactionListContainer);
