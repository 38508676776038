import React, {useEffect, useState, Fragment} from 'react';

import {useTranslation} from "react-i18next";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import MoinImageUplaod from "../../components/base/MoinImageUpload";
import MoinTextField from "../../components/base/MoinTextField";
import MoinSelect from "../../components/base/MoinSelect";
import MenuItem from "@material-ui/core/MenuItem";
import MoinButton from "../../components/base/MoinButton";
import {makeStyles} from "@material-ui/core/styles";
import {PrivacyLevel} from "../../utils/enums/PrivacyLevel";
import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import MoinSearchField from "../../components/base/MoinSearchField";
import Divider from "@material-ui/core/Divider";
import MoinUnsavedChangesDialog from "../../components/base/MoinUnsavedChangesDialog";
import parameters from "../../config/parameters.json";


const useStyles = makeStyles(() => ({
    content: {
        padding: '16px 16px 0 16px',
        width: '100%'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rowStretch: {
        flexGrow: 1,
        paddingLeft: "12px"
    },
    divider: {
        margin: '0'
    },
    input: {
        marginTop: '8px'
    },
    optionalRow: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    select: {
        margin: '8px 0 16px 0',
        '& .MuiSelect-selectMenu': {
            padding: '10.5px 14px',
        }
    },
    noBottomPadding: {
        paddingBottom: '0px'
    },
}));

/**
 *
 * @param {Distributor} distributor
 * @param {Array<String>} userIds
 * @param {boolean} saving
 * @param {Function} onSave
 * @returns {JSX.Element}
 * @constructor
 */
const GroupCreateEditContainer = ({distributor,userIds= [], saving, onSave}) => {
    const classes = useStyles();

    const [t] = useTranslation();

    const [search, setSearch] = useState();
    const [selectedUsers, setSelectedUsers] = useState(userIds);
    const [name, setName] = useState(distributor?.name || "" );
    const [info, setInfo] = useState(distributor?.description );
    const [privacy, setPrivacy] = useState(distributor?.privacy );
    const [avatarRemoved, setAvatarRemoved] = useState(false);
    const [avatarFile, setAvatarFile] = useState();
    const [errors, setErrors] = useState({});
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        if (distributor && userIds && !saving) {
            setSelectedUsers(userIds);
        }
    }, [userIds]);

    const validate = ()  => {
        if (!saving) {
            let errors = {};
            if (!name) {
                errors['name'] = t('form.errors.cantBeEmpty', {field: t('distributor.name')});
            } else if (name.length < 2) {
                errors['name'] = t('form.errors.mustBeAtLeastXCharacters', {name: t('distributor.name'), chars: 2});
            }
            if (Object.keys(errors).length === 0) {
                onSave(name, privacy, info, selectedUsers,avatarFile,avatarRemoved  )
            }
            setErrors(errors);
        }

    }

    const onChangeAvatar = (file) => {
        setHasChanges(true);
        setAvatarRemoved(!file);
        if (!!file) {
            setAvatarFile(file);
        } else {
            setAvatarFile(undefined);
        }
    }

    const onUserSelection = (user) => {
        setHasChanges(true);
        let userId = user.id;

        let currentUsers = Array.from(selectedUsers);
        if (currentUsers.includes( userId)) {
            currentUsers= currentUsers.filter((id) => id !== userId);
        } else {
            currentUsers.push(userId);
        }
        setSelectedUsers(currentUsers);
    }

    return (
        <Fragment>
            <MoinUnsavedChangesDialog hasChanges={hasChanges} />
            <MoinCard>
                <MoinCardHeader>
                    <Typography variant="h3">
                        {
                            distributor
                                ? t('distributor.editGroup')
                                : t('distributor.createGroup')
                        }
                    </Typography>
                </MoinCardHeader>
                <MoinCardContent  className={classes.noBottomPadding}>
                    <div className={classes.content}>
                        <div className={classes.row}>
                            <MoinImageUplaod radius={35} image={distributor?.avatar} onChangeCallback={onChangeAvatar} />
                            <div className={classes.rowStretch}>
                                <MoinTextField
                                    className={classes.input}
                                    maxCharacters={200}
                                    showCharacterCount={false}
                                    caption={ t('distributor.nameDescription')}
                                    placeholder={t('distributor.placeholder.name')}
                                    value={name}
                                    disabled={distributor && distributor.id === parameters.globalDistributorId }
                                    onChange={e => {
                                        setHasChanges(true);
                                        setName(e.target.value)
                                    }}
                                    error={ errors && errors['name']}
                                />
                            </div>

                        </div>

                        {
                            (!distributor || distributor.id !== parameters.globalDistributorId) &&
                                <>
                                    <Typography variant={'h2'}>{t('distributor.privacy').toUpperCase()}</Typography>
                                    <MoinSelect
                                        className={classes.select}
                                        value={privacy || PrivacyLevel.PRIVATE}
                                        placeholder={t('distributor.placeholder.privacy')}
                                        onChange={e => {
                                            setHasChanges(true);
                                            setPrivacy(e.target.value)
                                        }}
                                    >
                                        <MenuItem key={PrivacyLevel.PUBLIC} value={PrivacyLevel.PUBLIC}>
                                            { t('distributor.public')}
                                        </MenuItem>
                                        <MenuItem key={PrivacyLevel.PRIVATE} value={PrivacyLevel.PRIVATE}>
                                            {t('distributor.private') }
                                        </MenuItem>
                                    </MoinSelect>


                                    <MoinTextField
                                        className={classes.input}
                                        title={t('distributor.info')}
                                        placeholder={t('distributor.placeholder.info')}
                                        isOptional={true}
                                        maxCharacters={500}
                                        value={info}
                                        onChange={e => {
                                            setHasChanges(true);
                                            setInfo(e.target.value)
                                        }}
                                    />

                                    <Typography variant={'h2'}>{t('distributor.members').toUpperCase()}</Typography>

                                    <MoinSearchField
                                        onChange={e => setSearch(e.target.value)}
                                        placeholder={t('distributor.placeholder.searchUsers')}
                                        style={{width: '100%'}}
                                        onClear={e => setSearch('')}
                                    />

                                    <SearchableSelectableUserList search={search} selectedUsers={selectedUsers} onSelect={onUserSelection}/>
                                </>
                        }



                    </div>
                </MoinCardContent>
                <Divider/>
                <MoinCardHeader>
                    <MoinButton disabled={saving} onClick={ validate}>{t('common.saveChanges')}</MoinButton>
                </MoinCardHeader>
            </MoinCard>
        </Fragment>
    );
};

export default GroupCreateEditContainer;