import React, {Fragment, useState, useEffect} from 'react';

import {useTranslation} from "react-i18next";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import MoinImageUplaod from "../../components/base/MoinImageUpload";
import MoinTextField from "../../components/base/MoinTextField";
import MoinButton from "../../components/base/MoinButton";
import {makeStyles} from "@material-ui/core/styles";
import {KeyboardDatePicker, KeyboardTimePicker,} from '@material-ui/pickers';
import {DateTime} from "luxon";
import GoogleMapsAutoComplete from "../../components/base/GoogleMapsAutoComplete";
import {Divider} from "@material-ui/core";
import MoinUnsavedChangesDialog from "../../components/base/MoinUnsavedChangesDialog";
import MoinGroupSelector from "../../components/base/MoinGroupSelector";
import parameters from "../../config/parameters.json";
import MoinSearchField from "../../components/base/MoinSearchField";
import SearchableSelectableUserList from "../../components/base/MoinSearchableSelectableUserList";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import * as ROUTES from "../../Routes";
import InfoIcon from "../../assets/icons/info.svg";
import {Colors} from "../../components/helper/ColorHelper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(() => ({
    content: {
        padding: '16px',
        paddingTop: "24px",
        width: '100%'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    withSpace: {
        marginTop: '8px'
    },
    button: {
        marginBottom: '16px'
    },
    contactPartnersLabel: {
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer'
    },
    contactPartnersLabelIcon: {
        marginLeft: '4px'
    }
}));

/**
 *
 * @param {Distributor} distributor
 * @param {Array<String>} userIds
 * @param {boolean} saving
 * @param {Function} onSave
 * @returns {JSX.Element}
 * @constructor
 */
const EventCreateEdit = ({event, saving, onSave}) => {
    const classes = useStyles();

    const [t] = useTranslation();

    let newDescription = event?.description || '';

    if(event?.agenda) {
        if(newDescription.length > 0) {
            newDescription += '\n\n';
        }
        newDescription += event?.agenda;
    }

    const [selectedDistributor, setSelectedDistributor] = useState();
    const [title, setTitle] = useState(event?.title || "");
    const [description, setDescription] = useState(newDescription || "" );
    const [room, setRoom] = useState(event?.room || "" );
    const [start, setStart] = useState(event?.start ? DateTime.fromJSDate(event.start) : DateTime.local() );
    const [end, setEnd] = useState(event?.end ? DateTime.fromJSDate(event.end) : undefined);
    const [showEnd, setShowEnd] = useState(!!event?.end);
    const [location, setLocation] = useState(event?.location || "" );
    const [geolocation, setGeolocation] = useState(event?.geolocation || "" );
    const [avatarRemoved, setAvatarRemoved] = useState(false);
    const [avatarFile, setAvatarFile] = useState();
    const [errors, setErrors] = useState({});
    const [hasChanges, setHasChanges] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedUsers, setSelectedUsers] = useState(event?.pUids || [AUTHSTORAGE.getUserId()]);
    const [showContactPartnerDialog, setShowContactPartnerDialog] = useState(false);

    const onUserSelection = (user) => {
        setHasChanges(true);
        let userId = user.id;

        let currentUsers = Array.from(selectedUsers);
        if (currentUsers.includes( userId)) {
            currentUsers= currentUsers.filter((id) => id !== userId);
        } else {
            currentUsers.push(userId);
        }
        setSelectedUsers(currentUsers);
    }

    const validate = () => {
        if (!saving) {
            let errors = {};
            if (!title) {
                errors['title'] = t('form.errors.cantBeEmpty', {field: t('event.title')});
            } else if (title.length < 2) {
                errors['title'] = t('form.errors.mustBeAtLeastXCharacters', {name: t('event.title'), chars: 2});
            }
            if (!start) {
                errors['start'] = t('form.errors.cantBeEmpty', {field: t('event.start')});
            }

            if (end && start && (end.toMillis() <= start.toMillis()) ) {
                errors['start'] = t('form.errors.startMustBeBeforeEnd');
            }

            if (!description || !description.trim()) {
                errors['description'] = t('form.errors.cantBeEmpty', {field: t('event.info')});
            }


            if (Object.keys(errors).length === 0) {
                onSave( {
                    selectedDistributor: selectedDistributor,
                    title: title,
                    description: description,
                    agenda: '',
                    location: location,
                    geolocation: geolocation,
                    room: room,
                    start: start.toJSDate(),
                    end: end && end.toJSDate(),
                    contactUids: selectedUsers,
                    image: avatarFile,
                    imageRemoved: avatarRemoved
                })
            }
            setErrors(errors);
        }

    }

    const onShowEnd = () => {
        setEnd( start ? DateTime.fromJSDate(start.toJSDate()) : DateTime.local() );
        setShowEnd(true);
    }

    const onChangeAvatar = (file) => {
        setHasChanges(true);
        setAvatarRemoved(!file);
        if (!!file) {
            setAvatarFile(file);
        } else {
            setAvatarFile(undefined);
        }
    }

    const changeLocation = (location) => {
        if (location?.location !== location)  {
            setHasChanges(true);
            setLocation(location?.location);
            setGeolocation(location?.geopoint);
        }
    }

    const getContactPartnersHintDialog = () => {
        return (
            <Dialog
                open={showContactPartnerDialog}
                onClose={() => setShowContactPartnerDialog(false)}
                aria-labelledby="contact-partner-dialog-title"
                aria-describedby="contact-partner-dialog-description"
            >
                <DialogTitle id="contact-partner-dialog-title">{ t('event.addContactPartner') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="contact-partner-dialog-description">{ t('event.addContactPartnerContent') }</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={() => setShowContactPartnerDialog(false)}>{ t('buttons.close') }</MoinButton>
                </DialogActions>
            </Dialog>
        )
    }

    let distributorId = parameters.globalDistributorId;

    if(!!selectedDistributor) {
        distributorId = selectedDistributor.id ?? parameters.globalDistributorId;
    } else if(event) {
        distributorId = event.distributorId ?? parameters.globalDistributorId;
    }

    return (
        <Fragment>
            { getContactPartnersHintDialog() }
            <MoinUnsavedChangesDialog hasChanges={hasChanges} />
            <MoinCard>
                <MoinCardHeader>
                    <Typography variant="h3">
                        {
                            event
                                ? t('event.edit')
                                : t('event.create')
                        }
                    </Typography>
                </MoinCardHeader>

                <MoinImageUplaod fullWidth={true} image={event?.avatar} onChangeCallback={onChangeAvatar} />

                <MoinCardContent>

                    <div className={classes.content}>
                        <Typography variant={'h2'} className={classes.withSpace}>{t('event.group').toUpperCase()}</Typography>
                        <MoinGroupSelector
                            placeholder={ t('article.edit.selectGroup') }
                            selectedDistributorId={distributorId}
                            onSelectDistributor={(d) => {
                                setHasChanges(true);
                                setSelectedDistributor(d);
                            }}
                            error={errors['distributor']}
                            executeScroll={errors['distributor']}
                        />

                        <MoinTextField
                            maxCharacters={200}
                            showCharacterCount={false}
                            title={t('event.title')}
                            placeholder={t('event.placeholder.title')}
                            value={title}
                            onChange={e => {
                                setHasChanges(true);
                                setTitle(e.target.value)
                            }}
                            error={ errors && errors['title']}
                        />


                        <Typography  variant={'h2'} className={classes.withSpace}>{t('event.startDateAndTime').toUpperCase()}</Typography>
                        <div className={classes.row}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-start"
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                value={start}
                                onChange={(d) => {
                                    setHasChanges(true);
                                    setStart(d);
                                }}
                                error={ errors && errors['start']}
                                helperText={ errors && errors['start'] ? errors['start'] : undefined}
                            />

                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-start"
                                inputVariant="outlined"
                                value={start}
                                ampm={false}
                                onChange={(t) => {
                                    setHasChanges(true);
                                    setStart(t);
                                }}
                            />
                        </div>

                        {
                            !showEnd &&
                            <MoinButton className={classes.button} size="small" variant="outlined" onClick={ (e) => {onShowEnd();  e.stopPropagation(); e.preventDefault(); return false;}}>
                                {t('event.addEndDate')}
                            </MoinButton>
                        }

                        {
                            showEnd && <>
                                <Typography variant={'h2'}  className={classes.withSpace}>{t('event.endDateAndTime').toUpperCase()}</Typography>
                                <div className={classes.row}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-end"
                                        format="dd/MM/yyyy"
                                        inputVariant="outlined"
                                        value={end}
                                        onChange={(d) => {
                                            setHasChanges(true);
                                            setEnd(d);
                                        }}
                                    />

                                    <KeyboardTimePicker
                                        margin="normal"
                                        id="time-picker-end"
                                        inputVariant="outlined"
                                        value={end}
                                        ampm={false}
                                        onChange={(t) => {
                                            setHasChanges(true);
                                            setEnd(t);
                                        }}
                                    />
                                </div>

                            </>
                        }
                        <GoogleMapsAutoComplete
                            className={classes.withSpace}
                            location={location}
                            title={t('event.location')}
                            placeholder={t('event.placeholder.location')}
                            error={errors && errors['location']}
                            onChange={changeLocation}/>

                        <MoinTextField
                            maxCharacters={200}
                            isOptional={true}
                            showCharacterCount={false}
                            title={t('event.room')}
                            placeholder={t('event.placeholder.room')}
                            value={room}
                            onChange={e => {
                                setHasChanges(true);
                                setRoom(e.target.value)
                            }}
                            error={ errors && errors['room']}
                        />

                        <MoinTextField
                            maxCharacters={5000}
                            multiline={true}
                            rowsMax={8}
                            showCharacterCount={true}
                            title={t('event.descriptionAgenda')}
                            placeholder={t('event.placeholder.descriptionAgenda')}
                            value={description}
                            onChange={e => {
                                setHasChanges(true);
                                setDescription(e.target.value)
                            }}
                            error={ errors && errors['description']}
                        />

                        <div className={classes.contactPartnersLabel} onClick={() => setShowContactPartnerDialog(true)}>
                            <Typography variant={'h2'}>{t('event.contactPartners').toUpperCase()}</Typography>
                            <InfoIcon className={classes.contactPartnersLabelIcon} fill={Colors.BLACK}/>
                        </div>
                        <MoinSearchField
                            onChange={e => setSearch(e.target.value)}
                            placeholder={t('chat.placeholder.searchContacts')}
                            style={{width: '100%'}}
                            onClear={ () => setSearch('')}
                        />

                        <SearchableSelectableUserList search={search} withInviteExternal={false} hideAuth={false} allowAuthRemove={true} selectedUsers={selectedUsers} withSort={false} onSelect={onUserSelection} useExternals={false} referer={ROUTES.eventCreate} />

                    </div>
                </MoinCardContent>
                <Divider/>
                <MoinCardHeader>
                    <MoinButton disabled={saving} onClick={ validate}>{event ? t('common.saveChanges') : t('event.publishEvent')}</MoinButton>
                </MoinCardHeader>
            </MoinCard>
        </Fragment>
    );
};

export default EventCreateEdit;