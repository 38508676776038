import React, {useRef} from "react";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import ModelManager from "../../utils/manager/ModelManager";
import RenderedCommentAnswer from "./RenderedCommentAnswer";
import {
    addModelInstanceDispatch,
    deleteModelInstanceDispatch,
    updateModelInstanceDispatch
} from "../../utils/store/actions";
import {connect} from "react-redux";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import {FireDelete, FireIncrement, FireTimestamp} from "../../api/FirebaseService";


/**
 * @param {CommentAnswer} commentAnswer
 * @param {Comment} comment
 * @returns {JSX.Element}
 * @constructor
 */
const RenderedCommentAnswerContainer = ({comment, commentAnswer, deleteModelInstanceDispatch, updateModelInstanceDispatch}) => {
    const saving = useRef(false);
    const deleting = useRef(false);

    const updateCommentAnswer = async (text, image, deletedImage) => {
        if(!saving.current) {
            let futures = [];
            saving.current = true;

            commentAnswer.text = text;

            if(deletedImage) {
                let uploadPath = commentAnswer.media[0].replace(/%2F/g, '/');
                futures.push(new FirebaseStorageManager().deleteFile(uploadPath));
                commentAnswer.media = null;

                let fixedPath = uploadPath.substring(1).replace(/\//g, '%2F');
                let fireStorageUrl = new FireStorageUrl();
                fireStorageUrl.id = fixedPath;
                deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
            }

            if(!!image?.name) {
                let fileExtension = image.name.split('.');
                fileExtension = fileExtension[fileExtension.length - 1];

                let uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/comment_answer/${commentAnswer.id}.${fileExtension}`;
                futures.push(new FirebaseStorageManager().uploadImage(image, uploadPath, 1200 , 1));
                let fixedPath = uploadPath.substring(1).replace(/\//g, '%2F');

                commentAnswer.media = [fixedPath];
                let fireStorageUrl = new FireStorageUrl();
                fireStorageUrl.id = fixedPath;
                deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
            }

            let customCommentData = {
                text: text,
                uAt: FireTimestamp()
            };

            if(!!commentAnswer.media) {
                customCommentData['media'] = commentAnswer.media;
            } else {
                customCommentData['media'] = FireDelete()
            }

            futures.push(ModelManager.update({modelInstance: commentAnswer, customData: customCommentData, id: commentAnswer.id}));

            await Promise.all(futures);
            saving.current = false;
            return true;
        }
        return false;
    };

    const deleteCommentAnswer = () => {
        if(!deleting.current) {
            deleting.current = true;
            ModelManager.delete({modelInstance: commentAnswer, resource: `/client/${AUTHSTORAGE.getClientId()}/comment_answer`});

            comment.aCount--;

            let commentUpdateData = {
                aCount: FireIncrement(-1)
            };

            ModelManager.update({modelInstance: comment, customData: commentUpdateData});

            if(!!commentAnswer.media) {
                new FirebaseStorageManager().deleteFile(commentAnswer.media[0].replace(/%2F/g, '/'));
            }

            deleting.current = false;
            return true;
        }
        return false;
    };

    return <RenderedCommentAnswer commentAnswer={commentAnswer} comment={comment} updateCommentAnswer={updateCommentAnswer} deleteCommentAnswer={deleteCommentAnswer}/>
};

const mapAction = {
    deleteModelInstanceDispatch,
    addModelInstanceDispatch,
    updateModelInstanceDispatch
};


export default connect(null,mapAction)(RenderedCommentAnswerContainer);