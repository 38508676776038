import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import NavigationPrompt from "react-router-navigation-prompt";
import MoinDialog from "./MoinDialog";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(1.5),
        backgroundColor: Colors.BLUELIGHT
    },
}));

/**
 * Moin! themed unsaved changes dialog.
 * @param hasChanges
 * @returns {JSX.Element}
 * @constructor
 */
const MoinUnsavedChangesDialog = ({hasChanges}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();

    if (!hasChanges) {
        return <></>
    }

    return <NavigationPrompt
        when={(crntLocation, nextLocation) =>
            !nextLocation || !nextLocation.pathname.startsWith(crntLocation.pathname)
        }
    >
        {({ isActive, onCancel, onConfirm }) => {
            if (isActive) {
                return (
                    <MoinDialog
                        id={'1'}
                        title={t('dialogs.unsavedChanges')}
                        abortButtonText={t('dialogs.continue')}
                        abortButtonCallback={onCancel}
                        continueButtonText={t('dialogs.leave')}
                        continueButtonCallback={onConfirm}
                    >
                        <Typography variant={"body1"} >
                            {t('dialogs.unsavedChangesDescription')}
                        </Typography>

                    </MoinDialog>
                );
            }
            return <></>
        }}
    </NavigationPrompt>
};

export default MoinUnsavedChangesDialog;
