import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import * as ROUTES from "../Routes";

import AppStoreButton from "../assets/buttons/app-store-button.png";
import PlayStoreButton from "../assets/buttons/play-store-button.png";
import WebAppButton from "../assets/buttons/web-button.png";
import Illustration from "../assets/illustrations/moin-app-illus-home.png";
import Logo from "../assets/logo/moin-logo.svg";
import {Colors} from "../components/helper/ColorHelper";

const useStyles = makeStyles(() => ({
    onboardingWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: Colors.WHITE,
        width: '100vw',
        height: '100vh'
    },
    onboardingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        padding: '0',
        '@media screen and (min-width: 768px)': {
            width: '50%'
        }
    },
    storeButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    onboardingLogo: {
        padding: '3rem 2rem 2rem 2rem'
    },
    storeButton: {
        width: '60%',
        margin: '6px 0',
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    onboardingIllustrationWrapper: {
        width: '100%',
        height: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        overflow: 'hidden',
        '& div': {
            width: '100%',
            height: '80%',
            backgroundColor: Colors.BLUELIGHT,
            borderTopLeftRadius: '45%',
            borderTopRightRadius: '45%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& img': {
            width: 'auto',
            height: '125%',
            marginBottom: '4rem'
        }
    }
}));

const OnboardingRedirect = () => {
    const classes = useStyles();

    const redirectToStores = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent) && !/windows phone/i.test(userAgent)) {
            window.location.href = 'http://play.google.com/store/apps/details?id=de.moinapp&hl=de';
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.href = 'https://itunes.apple.com/de/app/moin/id1512118331';
        }
    }

    return <div className={classes.onboardingWrapper} onLoad={redirectToStores}>
            <div className={classes.onboardingContainer}>
                <Logo alt="MOIN! logo" className={classes.onboardingLogo}/>
                <div className={classes.storeButtons}>
                    <a href="https://play.google.com/store/apps/details?id=de.moinapp" className={classes.storeButton}>
                        <img src={PlayStoreButton} alt="Play Store Button"/>
                    </a>
                    <a href="https://itunes.apple.com/de/app/moin/id1512118331" className={classes.storeButton}>
                        <img src={AppStoreButton} alt="App Store Button"/>
                    </a>
                    <Link to={ROUTES.login} className={classes.storeButton}><img src={WebAppButton} alt="Web Version Button"/></Link>
                </div>
                <div className={classes.onboardingIllustrationWrapper}>
                    <div>
                        <img src={Illustration} alt="login illustration"/>
                    </div>
                </div>
            </div>
        </div>
};
export default OnboardingRedirect;