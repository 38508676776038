export const StorageSortField = {
    NAME: "name",
    SIZE: "size",
    CHANGED: "changedAt",
};


export const SortDirections = {
    ASCENDING: "asc",
    DESCENDING: "desc"
};