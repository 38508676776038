import React, {useRef, useState} from "react";
import MoinDialog from "../../components/base/MoinDialog";
import {useTranslation} from "react-i18next";
import MoinTextField from "../../components/base/MoinTextField";
import {makeStyles} from "@material-ui/core/styles";
import AttachmentIcon from "../../assets/icons/attachment.svg";
import Typography from "@material-ui/core/Typography";
import {Colors} from "../../components/helper/ColorHelper";
import CloseIcon from "../../assets/icons/close.svg";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    uploadMediaContainer: {
        backgroundColor: '#FFF9EF',
        border: `1px dashed ${Colors.BRANDSECONDARY}`,
        borderRadius: 8,
        padding: theme.spacing(1.5),
        display: "flex",
        alignItems: "center",
        minWidth: 464,
        cursor: "pointer"
    },
    uploadMediaText: {
        color: Colors.BRANDSECONDARY
    },
    attachmentIcon: {
        fill: Colors.BRANDSECONDARY,
        height: 16,
        width: 16,
        marginRight: 8
    },
    selectedMediaWrapper: {
        minWidth: '240px',
        width: '240px',
        height: '150px',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'relative',
        margin: theme.spacing(1)
    },
    selectedImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    selectedVideo: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    deleteMediaIcon: {
        position: 'absolute',
        height: '25px',
        width: '25px',
        borderRadius: '25px',
        backgroundColor: Colors.BLACKLIGHTER,
        top: '8px',
        right: '8px',
        cursor: 'pointer',
        color: Colors.WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    allMediaWrapper: {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: "768px",
    },
    sendingRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(3)
    },
    sendingText: {
        marginRight: theme.spacing(2)
    }
}));

const HelpReportDialog = ({ show, onSend, sending = false, isImproveMoinDialog = false, onClose }) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [textHasContent, setTextHasContent] = useState(false);
    const text = useRef('');
    const mediaInputField = useRef();

    const onSelectMedia = (fileList) => {
        let files = [...fileList];

        let images = files.filter(e => !e.name.endsWith('.mp4'));
        let videos = files.filter(e => e.name.endsWith('.mp4'));

        setSelectedImages([...selectedImages, ...images]);
        setSelectedVideos([...selectedVideos, ...videos]);
    };

    const removeSelectedImage = (index) => {
        setSelectedImages(selectedImages.filter((image, i) => i !== index));
    };

    const removeSelectedVideo = (index) => {
        setSelectedVideos(selectedVideos.filter((image, i) => i !== index));
    };

    const onSave = async () => {
        await onSend(text.current, selectedImages, selectedVideos);
        setSelectedImages([]);
        setSelectedVideos([]);
        setTextHasContent(false);
        text.current = '';
    };

    const renderSelectedImage = (image, index) => {
        return (
            <div key={"selected-image-" + index} className={classes.selectedMediaWrapper}>
                <img src={URL.createObjectURL(image)} className={classes.selectedImage}/>
                <div className={classes.deleteMediaIcon} onClick={() => removeSelectedImage(index)}>
                    <CloseIcon fill={Colors.WHITE}/>
                </div>
            </div>
        );
    };

    const renderSelectedVideo = (video, index) => {
        return (
            <div key={"selected-video-" + index} className={classes.selectedMediaWrapper}>
                <video className={classes.selectedVideo}>
                    <source src={URL.createObjectURL(video)}/>
                </video>
                <div className={classes.deleteMediaIcon} onClick={() => removeSelectedVideo(index)}>
                    <CloseIcon fill={Colors.WHITE} />
                </div>
            </div>
        );
    };

    return (
        <MoinDialog
            open={show}
            onClose={onClose}
            showCloseButton={true}
            id='reportProblemDialog'
            key='reportProblemDialog'
            title={isImproveMoinDialog ? t('help.improveMoin') : t('help.reportProblem')}
            continueButtonText={t('help.sendFeedback')}
            continueButtonCallback={onSave}
            continueButtonDisabled={!textHasContent || sending}
        >
            <MoinTextField
                placeholder={ isImproveMoinDialog ? t('help.improveMoinHintText') : t('help.bugReportHintText') }
                multiline={true}
                rowsMax={20}
                onChange={(val) => {
                    let newText = val.target.value;
                    if(textHasContent && !newText) {
                        setTextHasContent(false);
                    } else if(!textHasContent && !!newText) {
                        setTextHasContent(true);
                    }

                    text.current = newText;
                }}
            />

            <div className={classes.allMediaWrapper}>
                {
                    selectedImages.map((image, index) => renderSelectedImage(image, index))
                }
                {
                    selectedVideos.map((image, index) => renderSelectedVideo(image, index))
                }
            </div>

            <input ref={(fileInput) => mediaInputField.current = fileInput} type="file" accept="image/png,image/jpeg,image/gif,video/mp4" style={{display: 'none'}} multiple onChange={(e) => onSelectMedia(e.target.files) }/>

            <div className={classes.uploadMediaContainer} onClick={() => mediaInputField.current.click()}>
                <AttachmentIcon className={classes.attachmentIcon}/>
                <Typography variant="caption" className={classes.uploadMediaText}>{t('help.addImageOrVideo')}</Typography>
            </div>

            {
                sending &&
                <div className={classes.sendingRow}>
                    <Typography variant="caption" className={classes.sendingText}>{t('help.sending')}</Typography>
                    <CircularProgress />
                </div>
            }
        </MoinDialog>
    )
};

export default HelpReportDialog;
