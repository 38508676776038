import React, { useEffect, useState } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import CUSTOMNOTIFICATIONMANAGER from "./CustomNotificationManager";
import {useHistory, withRouter} from "react-router-dom";
import FirestoreImage from "../base/FirestoreImage";
import Typography from "@material-ui/core/Typography";
import {MessagingType} from "../../utils/enums/MessagingType";
import {useTranslation} from "react-i18next";

import CloseIcon from '../../assets/icons/close.svg';

const useStyles = makeStyles((theme) => ({
    notificationWrapper: {
        position: "fixed",
        top: "70px",
        zIndex: 1111,
        display: "flex",
        justifyContent: "center",
        marginLeft: '120px', // HALF OF NAVBAR WIDTH
        width: "100vw",
        cursor: 'pointer'
    },
    content: {
        color: Colors.WHITE,
        marginLeft: "8px",
        marginRight: "24px",
    },
    notification: {
        maxWidth: "768px",
        color: Colors.WHITE,
        background: Colors.BRANDPRIMARY,
        borderRadius: "8px",
        padding: "8px 10px",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    close: {
        color: Colors.WHITE,
        marginRight: '12px',
        fontSize: '20px',
        fontWeight: 'bold',
        cursor: 'pointer'
    }
}));

const CustomNotification = ({ location}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const history = useHistory();

    let timeout = undefined;
    let [visibility, setVisibility] = useState(false);
    let [content, setContent] = useState('');
    let [avatar, setAvatar] = useState('');
    let [avatarAlt, setAvatarAlt] = useState('');
    let [redirect, setRedirect] = useState('');
    let [color, setColor] = useState(undefined);


    useEffect(() => {
        CUSTOMNOTIFICATIONMANAGER.addListener('customNotification', ({data}) => {
            if (data) {
                if (data.type === MessagingType.MESSAGENEW && location.pathname.includes('chat')) {
                    return false;
                }

                if (!!timeout) {
                    clearTimeout(timeout);
                }

                setContent(data.getDescription(t));
                setAvatar(data.getAvatar());
                setAvatarAlt(data.getFallback());
                setRedirect(data.getLinkPath())
                setColor( Colors.identifierAsColor(data.cUid))
                setVisibility(true);

                // A Human reads about 120 Words per minute, he has to read the text at least twice
                let additionalTime = (( (data.getDescription(t).length ?? 0)) /120) * 1000;

                timeout = setTimeout(() => {
                    setVisibility(false);
                }, 4000 + additionalTime);
            }
        });
        return () => {
            CUSTOMNOTIFICATIONMANAGER.removeListener('customNotification');
        }

    }, [ location]);


    const onRedirect = (event) => {
        if (redirect) {
            history.push(redirect);
            setVisibility(false);
        }
        event.stopPropagation();
        event.preventDefault();
        return false;
    }

    return (
        visibility &&
        <div className={classes.notificationWrapper} onClick={(event) => onRedirect(event)}>
            <div className={classes.notification}>
                {avatar &&
                    <FirestoreImage
                        key={'avatar-flash'}
                        src={avatar}
                        alt={avatarAlt}
                        backgroundColor={color}
                        radius={16}
                        isAvatar={true}
                    />
                }
                <Typography variant={'body2'} className={classes.content}> {content}</Typography>
                <div className={classes.close}
                     onClick={
                         (event) => {
                             setVisibility(false);
                             event.stopPropagation();
                             event.preventDefault();
                             return false;
                         }
                     }>
                    <CloseIcon fill={Colors.WHITE}/>
                </div>
            </div>
        </div>
    )
}

export default withRouter(CustomNotification);