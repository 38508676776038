import {id, model, prop} from "../manager/decorators";
import AUTHSTORAGE from "../auth/AuthStorage";

@model()
class StorageFile
{

    /**
     * id
     */
    @id()
    id;

    name;

    path;

    get parent() {
        return this.path.split('/').pop();
    }

    /* Size in Byte */
    size;

    type;


    cAt;

    createdBy;

    uAt;

    updatedBy;

    temporaryPreview;

    get fullPath() {
        return  `/client/${AUTHSTORAGE.getClientId()}/storage/${this.path}/${this.id}`;
    }

    get previewPath() {
        if (!!this.temporaryPreview) {
            return this.temporaryPreview;
        }
        return this.fullPath + '_preview';
    }


}

export default StorageFile;