import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import ModelManager from "../../utils/manager/ModelManager";
import Event from "../../utils/models/Event";
import {getModelInstance,} from "../../utils/store/selectors";
import {Redirect} from "react-router-dom";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import {getEventAvatarPath, getGroupAvatarPath} from "../../utils/helper/ImageHelper";
import * as ROUTES from "../../Routes";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import {deleteModelInstanceDispatch, updateModelInstance} from "../../utils/store/actions";
import EventCreateEdit from "./EventCreateEdit";
import Skeleton from "@material-ui/lab/Skeleton";
import SUCCESSNOTIFICATIONMANAGER from "../../components/success/SuccessNotificationManager";
import SuccessMessage from "../../utils/models/SuccessMessage";
import {SuccessType} from "../../utils/enums/SuccessType";
import EventMembership from "../../utils/models/EventMembership";
import {FireIncrement, FireTimestamp} from "../../api/FirebaseService";

/**
 *
 * @param {Distributor} distributor
 * @param {Array<String>} userIds
 * @param {Array<User>} users
 * @returns {JSX.Element}
 * @constructor
 */
const EventCreateEditContainer = ({event, eventId,ownParticipation, deleteModelInstanceDispatch }) => {
    const [saving, setSaving] =  useState(false);
    const [redirect, setRedirect] =  useState(false);

    const onSave = ({selectedDistributor, title, description, agenda, location, room,geolocation, start, end, contactUids, image, imageRemoved}) => {

        if (!saving) {
            setSaving(true);
            if (!event) {
                let event = Event.from(
                    {
                        "title": title,
                        "start": start,
                        "count": 1,
                        "hasImage": !!image,
                        'cUid': AUTHSTORAGE.getUserId(),
                        'distributorId': selectedDistributor.id,
                        'pUids': contactUids
                    }
                );

                if (description && !!description.trim()) {
                    event.description = description;
                }

                if (agenda && !!agenda.trim()) {
                    event.agenda = agenda;
                }

                if (location && !!location.trim()) {
                    event.location = location;
                }

                if (room && !!room.trim()) {
                    event.room = room;
                }

                if ( !!geolocation) {
                    event.geolocation = geolocation;
                }

                if (!!end) {
                    event.end = end;
                }

                console.log(event);

                ModelManager.add({modelInstance: event}).then(async (result) => {
                    if (!ownParticipation) {
                        ownParticipation = new EventMembership();
                        ownParticipation.id = AUTHSTORAGE.getUserId();
                        ownParticipation.events = {};
                    }

                    ownParticipation.events[eventId] = true;
                    let data = {
                        'uAt': FireTimestamp()
                    };
                    data[event.id] = true;
                    ModelManager.update({modelInstance: ownParticipation, customData: data, useSet: true});

                    let avatarUpload = getEventAvatarPath(AUTHSTORAGE.getUserId(), result.id);
                    if (!!image) {
                        await new FirebaseStorageManager().uploadImage(image, avatarUpload , 2048 , 1);
                        let fireStorageUrl = new FireStorageUrl();
                        fireStorageUrl.id = avatarUpload;
                        deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
                    }
                    SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.EVENTCREATED, true));
                    setRedirect(true);
                });
            } else {
                event.distributorId = selectedDistributor.id;
                event.title = title;
                event.start = start;
                if (imageRemoved) {
                    event.hasImage = false;
                } else if (image) {
                    event.hasImage = true;
                }

                event.description = description ? description : undefined;
                event.quillDescription = undefined;
                event.agenda = agenda ? agenda : undefined;
                event.location = location ? location : undefined;
                event.room = room ? room : undefined;
                event.geolocation = geolocation ? geolocation : undefined;
                event.end = end ? end : undefined;
                event.pUids = contactUids;
                event.uAt = new Date();

                updateModelInstance(Event,event);
                ModelManager.update({modelInstance: event}).then( async () => {
                    let avatarUpload = getEventAvatarPath(event.cUid, event.id);

                    if (!!image) {
                        await new FirebaseStorageManager().uploadImage(image, avatarUpload , 2048 , 1);
                    } else if (imageRemoved){
                        await new FirebaseStorageManager().deleteFile(avatarUpload);
                    }
                    let fireStorageUrl = new FireStorageUrl();
                    fireStorageUrl.id = avatarUpload;
                    deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
                    SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.EVENTUPDATED, true));
                    setRedirect(true);
                });
            }
        }
    };

    if(redirect)
        return <Redirect push to={ROUTES.events} />;

    if (eventId && !event)
        return <Skeleton variant="rect" width="100%" height="100%" />

    return <EventCreateEdit key={event ? event.id : 'newEvent'} event={event} saving={saving} onSave={onSave}/>
};

const mapState= (state, ownProps) => (
     {
        eventId: ownProps.eventId,
        ownParticipation: getModelInstance(state, EventMembership, AUTHSTORAGE.getUserId()),
        event: !!ownProps.eventId
            ? getModelInstance(state, Event, ownProps.eventId)
            : undefined,
    }
);


const mapAction = {
    deleteModelInstanceDispatch
};

export default connect(mapState,mapAction)(EventCreateEditContainer)
