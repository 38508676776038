import * as httpClient from "../utils/httpclient/HttpClient";
import parameters from '../config/parameters.json';
import AUTHSTORAGE from "../utils/auth/AuthStorage";
import {Environment} from "../utils/enums/Environment";

class ElasticClient {

    static createRequest= function(index, option,body) {
        let httpClientInstance = new httpClient.default(parameters.elasticUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getBasicAuthForUserAndPass(AUTHSTORAGE.getClient().elasticAccess.name, AUTHSTORAGE.getClient().elasticAccess.pass)
            }
        });
        return httpClientInstance.post(getIndexPath(index) + '/' + option, JSON.stringify(body));
    }

}

function getBasicAuthForUserAndPass(user,pass)  {
    return 'Basic ' + Buffer.from(user + ':' + pass).toString('base64');
}

function getIndexPath(path)  {
    if (parameters.environment === Environment.PRODUCTION) {
        return "live_" + AUTHSTORAGE.getClient().workspace.toLowerCase() + "-" + path;
    } else if (parameters.environment === Environment.RELEASE) {
        return "release_"+ AUTHSTORAGE.getClient().workspace.toLowerCase() + "-" + path;
    }
    return "dev_"+ AUTHSTORAGE.getClient().workspace.toLowerCase() + "-" + path;
}


export default ElasticClient;