import {id, model, prop} from "../manager/decorators";
import {SERVER_TIMESTAMP} from "../manager/decorators/sentinels";

@model('client/:client/event_member')
class EventMembership {

    @id()
    id;

    @prop({type: 'remaining',readOnly: true})
    events;

    @prop({type: "date", readOnly: true, sentinel: SERVER_TIMESTAMP})
    uAt;
}

export default EventMembership;

