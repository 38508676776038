import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import GroupShow from "./GroupShow";
import GroupMembershipContainer from "./GroupMembershipContainer";
import Distributor from "../../utils/models/Distributor";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import ApiClient from "../../api/ApiClient";
import Article from "../../utils/models/Article";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import DistributorRequest from "../../utils/models/DistributorRequest";
import EmptyState from "../../components/base/EmptyState";
import User from "../../utils/models/User";

/**
 *
 * @param {String} distributorId
 * @param {Array.<Article>} articles
 * @param {Array.<User>} allUsers
 * @param {Array.<DistributorRequest>} allRequests
 * @param {Distributor} distributor
 * @returns {JSX.Element}
 * @constructor
 */
const GroupShowContainer = ({distributorId, distributor, articles=[], allUsers, allRequests}) => {
    const loadBatchSize = 8;

    const [rebuild, setRebuild] = useState(true);
    const [articleIds, setArticleIds] = useState([]);
    const loading = useRef(true);
    const noMoreArticles = useRef(false);

    useEffect(() => {
        if (!!distributor && (distributor.isAuthUserMember || distributor.isPublic)) {
            setArticleIds([]);
            makeLoadingRequest().then((result) => {
                loadArticlesForElasticResult(result);

                // If there are newer articles in redux, add the ids to the list
                // It is possible, that there are newer articles locally, when creating a new article
                let newestLoadedDate;

                result.data.forEach(articleLoadObject => {
                    if(!newestLoadedDate || newestLoadedDate < articleLoadObject.cAt) {
                        newestLoadedDate = new Date(articleLoadObject.cAt);
                    }
                });

                let newerLocalArticles = articles.filter(article => !!article.dis && article.dis.includes(distributorId) && article.cAt > newestLoadedDate);

                if(newerLocalArticles.length > 0) {
                    setArticleIds(articleIds.concat(newerLocalArticles.map(article => article.id)));
                }

                loading.current = false;
                setTimeout(() => {
                    setRebuild(!rebuild);
                }, 200);
            });
        }

        if (!!distributor && distributor.cUid === AUTHSTORAGE.getUserId()) {
            ModelManager.subscribe({
                model: DistributorRequest,
                resource: "/client/" + AUTHSTORAGE.getClientId() + "/distributor/" + distributorId + '/request/',
                extraProps: {
                    'distributorId': distributor.id
                }
            });
        }

    }, [distributor]);

    const loadArticlesForElasticResult = (result) => {
        if (!!result && !!result.data) {
            setArticleIds( articleIds.concat(result.data.map( (data) => data.id)) );
            result.data.forEach((data) => {
                ModelManager.get({model: Article, id: data.id}).then(() => setRebuild(!rebuild));
            })
        }
    }

    const loadOlderArticles = (lastCreatedAt) => {
        if(!loading.current && !noMoreArticles.current) {
            loading.current = true;
            setRebuild(!rebuild);
            makeLoadingRequest(lastCreatedAt).then(
                (result) => {
                    loadArticlesForElasticResult(result);
                    if (!!result && !!result.data && result.data.length === 0) {
                        noMoreArticles.current = true;
                    }
                    loading.current = false;
                }
            );
        }
    };

    const makeLoadingRequest = async (lastCreatedAt) => {
        let body = {};
        body['size'] = loadBatchSize;
        body['from'] = 0;
        body['dis'] = JSON.stringify([distributorId]);
        if (!!lastCreatedAt) {
            body['cAt'] = lastCreatedAt.getTime();
        }
        let request = await ApiClient.createRequest('/load/article', body);
        return request.send();
    }

    if (!distributor) {
        return <EmptyState/>
    }

    return(
        <GroupMembershipContainer distributorId={distributor.id}  key={"distributor-membership-container-" + distributor.id}>
            {
                (onRequest,onRequestRemove,onLeave) =>
                    <GroupShow
                        distributor={distributor}
                        articleIds={articleIds}
                        articles={articles}
                        allUsers={allUsers}
                        allRequests={allRequests}
                        loading={loading.current}
                        loadOlderArticles={loadOlderArticles}
                        key={"distributor-tag-" + distributor.id}
                        onRequest={onRequest}
                        onRequestRemove={onRequestRemove}
                        onLeave={onLeave}
                    />
            }
        </GroupMembershipContainer>
    );
};

const mapState = (state, ownProps) => ({
    distributor: getModelInstance(state, Distributor,ownProps.distributorId),
    articles: getModelInstances(state, Article),
    allUsers: getModelInstances(state, User),
    allRequests: getModelInstances(state, DistributorRequest)
})

export default connect(mapState)(GroupShowContainer);
