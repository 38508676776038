import React from "react";
import {Divider, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../components/helper/ColorHelper";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MoinCard} from "../../../components/base/MoinCard";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: "12px 16px"
    },
    answer: {
        margin: "8px 0"
    },
    link: {
        cursor: 'pointer',
        color: Colors.BRANDSECONDARY,
        textTransform: "none",
        textDecoration: "none",

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    }
}));

/**
 * @param {string} surveyId
 * @param {SurveyAnswer[]} answers
 * @param {User[]} users
 * @param {boolean} loading
 * @param {Function} loadMoreAnswers
 * @param {number} batchSize
 * @constructor
 */
const SurveyAnswers = ({surveyId, answers, users, loading, loadMoreAnswers, batchSize}) => {
    const [t, i18n] = useTranslation();
    const classes = useStyles();
    const noAnswers = !loading && answers.length === 0;

    return (
      <MoinCard className={classes.card}>
          <Typography variant="h3" style={{marginBottom: "8px"}}>{ t('article.survey.answers') }</Typography>

          {
              noAnswers
                  ? <Typography variant="body1">{ t('article.survey.noAnswersYet') }</Typography>
                  :  <Divider/>
          }

          {
              answers.map(answer => {
                  let user = users.find(u => u.id === answer.id);

                  return (
                      <div key={"survey-" + surveyId + "-answer-" + answer.id} className={classes.answer}>
                          <Typography variant="h2">{user?.fullName || t('user.unknownUser')}</Typography>
                          <Typography variant="body1">{answer.text}</Typography>
                      </div>
                  );
              })
          }

          {
              loading &&
              <div className="moin-circular-progress-container">
                  <CircularProgress/>
              </div>
          }

          {
              (!noAnswers && !loading && answers.length % batchSize === 0) &&
                  <Typography variant="body2" className={classes.link} onClick={loadMoreAnswers}>{ t('article.survey.loadMoreAnswers') }</Typography>
          }
      </MoinCard>
    );
};

export default SurveyAnswers;