import parameters from "../../config/parameters.json";
import AUTHSTORAGE from "../auth/AuthStorage";
import HIGHLIGHTEDGROUPS from "../groups/HighlightedGroups";

export const sortUsers = (a, b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase());

export const sortUsersByFirstname = (a, b) => {
  const compareFirstname = a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
  const compareLastname = a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());

  return compareFirstname === 0 ? compareLastname : compareFirstname;
};

export const sortUsersByFirstnameAuthFirst = (a, b) => {
  if (a.id === AUTHSTORAGE.getUserId()) {
    return -1;
  }
  if (b.id === AUTHSTORAGE.getUserId()) {
    return -1;
  }
  const compareFirstname = a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
  const compareLastname = a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
  return compareFirstname === 0 ? compareLastname : compareFirstname;
};

export const sortUsersByLastname = (a, b) => {
  const compareFirstname = a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
  const compareLastname = a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());

  return compareLastname === 0 ? compareFirstname : compareLastname;
};

export const sortEventsWithMatchQuality = (a, b, match) => {
  match = match.toLowerCase();

  if (!a || !a.title ) {
    return 1;
  }
  if (!b  || !b.title) {
    return -1;
  }

  if (a.title.toLowerCase().startsWith(match)) {
    return -1;
  }

  if (b.title.toLowerCase().startsWith(match)) {
    return 1;
  }
  return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
};

export const sortGroupsWithMatchQuality = (a, b, match) => {
  match = match.toLowerCase();

  if (!a || !a.name ) {
    return 1;
  }
  if (!b  || !b.name) {
    return -1;
  }

  if (a.name.toLowerCase().startsWith(match)) {
    return -1;
  }

  if (b.name.toLowerCase().startsWith(match)) {
    return 1;
  }
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
};


export const sortUsersWithMatchQuality = (a, b, match) => {
  match = match.toLowerCase();

  if (!a || !a.firstName || !a.lastName) {
    return 1;
  }
  if (!b  || !b.firstName || !b.lastName) {
    return -1;
  }

  if (a.firstName.toLowerCase().startsWith(match) || a.lastName.toLowerCase().startsWith(match)) {
    return -1;
  }

  if (b.firstName.toLowerCase().startsWith(match) || b.lastName.toLowerCase().startsWith(match)) {
    return 1;
  }
  const compareFirstname = a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
  const compareLastname = a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());

  return compareFirstname === 0 ? compareLastname : compareFirstname;
};


/**
 *
 * @param {Date} a
 * @param {Date} b
 * @returns {number}
 */
export const sortByDate = (a, b) => {
  return a - b;
}

export const sortDepartments = (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());


export const sortDistributor = (a, b) => {
  let aIsHighlighted = HIGHLIGHTEDGROUPS.isHighlighted(a.id);
  let bIsHighlighted = HIGHLIGHTEDGROUPS.isHighlighted(b.id);

  if(aIsHighlighted && !bIsHighlighted) {
    return -2;
  }

  if(!aIsHighlighted && bIsHighlighted) {
    return 2;
  }

  if(aIsHighlighted && bIsHighlighted) {
    if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
    if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
  }

  if (a.id === parameters.globalDistributorId) {
    return -1;
  }
  if (b.id === parameters.globalDistributorId) {
    return 1;
  }
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}
