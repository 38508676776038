import React, {Fragment, useRef} from 'react';
import {connect} from "react-redux";
import {getModelInstance} from "../../utils/store/selectors";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import User from "../../utils/models/User";
import Task from "../../utils/models/Task";
import ModelManager from "../../utils/manager/ModelManager";
import TaskActivity from "../../utils/models/TaskActivity";
import {TaskActivityType} from "../../utils/enums/TaskActivityType";
import {generateRandomId} from "../../utils/helper/RandomHelper";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import { updateModelInstanceDispatch} from "../../utils/store/actions";
import ContentDocument from "../../utils/models/ContentDocument";
import {FireTimestamp} from "../../api/FirebaseService";
import {RepetitionType} from "../../utils/enums/repetition/RepetitionType";
import {getStartOfDay} from "../../utils/helper/DateHelper";

/**
 *
 * @param {Distributor} distributor
 * @param {Function} children
 * @param {Function} updateModelInstanceDispatch
 * @returns {JSX.Element}
 * @constructor
 */
const TaskActionContainer = ({authUser, children, updateModelInstanceDispatch}) => {
    const firebaseStorageManager = new FirebaseStorageManager();
    const delayedDeletes = useRef([]);

    const onCreate = ( {title, description,repetition, assignee, dueDate, documents, images }) => {
        let task = new Task();

        task.id = ModelManager.getId({model: Task});
        task.title = title;
        task.dueDate = dueDate;
        task.description = description;
        task.cAt = new Date();
        task.uAt = new Date();
        task.dAt = null;
        task.cUid = authUser.uid;
        task.relevant = [authUser.uid];
        task.repetition = repetition;

        let activityCreated = new TaskActivity();
        activityCreated.cUid = authUser.uid;
        activityCreated.type = TaskActivityType.CREATED;
        activityCreated.cAt = new Date();

        task.activities = [activityCreated];

        if (assignee) {
            task.aUid = assignee.uid;
            if (assignee.uid !== authUser.uid) {
                task.relevant.push(assignee.uid);
            }

            let activityAssigned = new TaskActivity();
            activityAssigned.cUid = authUser.uid;
            activityAssigned.type = TaskActivityType.ASSIGNED;
            activityAssigned.affected = assignee.uid;
            activityAssigned.cAt = new Date();

            task.activities.push(activityAssigned);
        }

        ModelManager.add({modelInstance: task}).then( async (_task) => {
            await onAddDocuments(_task,documents);
            await onAddImages(_task,images);
        });
        return task;
    }

    const _updateTask = async (task, oldTask) =>  {
        updateModelInstanceDispatch(Task, task);
        await ModelManager.update({modelInstance: task, useSet: true}).catch( (_) =>
            updateModelInstanceDispatch(Task, oldTask)
        );
    }

    /**
     *
     * @param {Task} oldTask
     * @param {Date} dueDate
     */
    const setDueDate = async (oldTask, dueDate) => {
        let task = Object.assign(new Task(),oldTask);
        task.dueDate = dueDate;
        task.uAt = new Date();

        if(!!task.repetition && task.repetition.type !== RepetitionType.NEVER) {
            let startOfTomorrow = new Date();
            startOfTomorrow.setDate(startOfTomorrow.getDate() + 1);
            startOfTomorrow = getStartOfDay(startOfTomorrow);
            task.completedRepetitions = task.completedRepetitions.filter((repetitionDate) => repetitionDate.getTime() < startOfTomorrow.getTime());
        }

        let activity = new TaskActivity();
        activity.cUid = authUser.uid;
        activity.type = dueDate ? TaskActivityType.SETDUEDATE :  TaskActivityType.REMOVEDDUEDATE;
        activity.dueDate = dueDate;
        activity.cAt = new Date();
        task.activities.push(activity);
        await _updateTask (task, oldTask);

        oldTask.completedRepetitions = task.completedRepetitions;
        oldTask.dueDate = task.dueDate;
        oldTask.activities = task.activities;
        oldTask.uAt = task.uAt;
    }

    /**
     *
     * @param {Task} oldTask
     * @param {String} title
     */
    const onTitleChange = async (oldTask, title) => {
        let task = Object.assign(new Task(),oldTask);
        task = _addAuthAsRelevantIfNeeded(task);
        task.title = title;
        task.uAt = new Date();

        let activity = new TaskActivity();
        activity.cUid = authUser.uid;
        activity.type = TaskActivityType.EDITTITLE;
        activity.cAt = new Date();
        task.activities.push(activity);
        await _updateTask (task, oldTask);

        oldTask.title = task.title;
        oldTask.activities = task.activities;
        oldTask.uAt = task.uAt;
    }
    /**
     *
     * @param {Task} oldTask
     * @param {String} description
     */
    const onDescriptionChange = async (oldTask, description) => {
        let task = Object.assign(new Task(), oldTask);
        task = _addAuthAsRelevantIfNeeded(task);
        task.description = description;
        task.uAt = new Date();

        let activity = new TaskActivity();
        activity.cUid = authUser.uid;
        activity.type = TaskActivityType.EDITDESCRIPTION;
        activity.cAt = new Date();
        task.activities.push(activity);
        await _updateTask(task, oldTask);

        oldTask.description = task.description;
        oldTask.activities = task.activities;
        oldTask.uAt = task.uAt;
    }

    /**
     * @param {Task} oldTask
     * @param {TaskRepetition} repetition
     */
    const onRepetitionChange = async (oldTask, repetition) => {
        let task = Object.assign(new Task(), oldTask);
        task = _addAuthAsRelevantIfNeeded(task);
        task.repetition = repetition;
        task.uAt = new Date();

        let startOfTomorrow = new Date();
        startOfTomorrow.setDate(startOfTomorrow.getDate() + 1);
        startOfTomorrow = getStartOfDay(startOfTomorrow);
        task.completedRepetitions = task.completedRepetitions.filter((repetitionDate) => repetitionDate.getTime() < startOfTomorrow.getTime());
        await _updateTask(task, oldTask);

        oldTask.completedRepetitions = task.completedRepetitions;
        oldTask.repetition = task.repetition;
        oldTask.activities = task.activities;
        oldTask.uAt = task.uAt;
    }

    /**
     *
     * @param {Task} oldTask
     * @param {boolean} fallback
     */
    const onComplete = async (oldTask, fallback = true) => {
        let task = Object.assign(new Task(),oldTask);
        let newCompleted = !task.getCompletedStatusForDate(new Date());

        if (!task.cAt && task.id && fallback) {
            ModelManager.subscribeDoc({model: Task, id: task.id, withCallback: (task) => onComplete(task, false)});
        } else if (task.cAt) {
            task = _addAuthAsRelevantIfNeeded(task);

            if(!task.repetition || task.repetition.type === RepetitionType.NEVER) {
                if(newCompleted) {
                    let activity = new TaskActivity();
                    activity.cUid = authUser.uid;
                    activity.type = TaskActivityType.FINISHED;
                    activity.cAt = new Date();
                    task.activities.push(activity);
                } else {
                    task.activities = task.activities.filter((activity) => activity.type !== TaskActivityType.FINISHED);
                }

                task.uAt = new Date();
                task.completed = newCompleted;
            } else {
                let nextRepetitionDate = task.getNextRepetition();

                if(!!nextRepetitionDate) {
                    if(newCompleted) {
                        let activity = new TaskActivity();
                        activity.cUid = authUser.uid;
                        activity.type = TaskActivityType.FINISHED;
                        activity.cAt = new Date();
                        task.activities.push(activity);

                        if(!task.completedRepetitions) {
                            task.completedRepetitions = [];
                        }

                        task.completedRepetitions.push(nextRepetitionDate);
                    } else {
                        let lastFinishedActivityIndex = task.activities.reverse().findIndex(activity => activity.type === TaskActivityType.FINISHED);

                        if(lastFinishedActivityIndex >= 0) {
                            // tasks.activities must be reassigned, because otherwise for some reason the list won't be saved
                            task.activities = task.activities.filter((_, index) => index !== lastFinishedActivityIndex);
                        }

                        task.activities.reverse();
                        // task.completedRepetitions must be reassigned, because otherwise for some reason the list won't be saved
                        task.completedRepetitions = task.completedRepetitions.slice(0, -1);
                    }
                }
            }

            await _updateTask(task, oldTask);

            oldTask.completed = task.completed;
            oldTask.completedRepetitions = task.completedRepetitions;
            oldTask.activities = task.activities;
            oldTask.uAt = task.uAt;
        }
    }

    /**
     *
     * @param {Task} oldTask
     * @param {String} assignee
     */
    const onAssign = async (oldTask, assignee) => {

        if (assignee) {
            let task = Object.assign(new Task(), oldTask);
            task = _addAuthAsRelevantIfNeeded(task);
            task.aUid = assignee;
            task.uAt = new Date();

            let index = task.relevant.findIndex((id) => id === assignee);
            if (index < 0) {
                task.relevant.push(assignee);
            }

            let activity = new TaskActivity();
            activity.affected = assignee;
            activity.cUid = authUser.id;
            activity.type = TaskActivityType.ASSIGNED;
            activity.cAt = new Date();
            task.activities.push(activity);

            await _updateTask(task, oldTask);

            oldTask.aUid = task.aUid;
            oldTask.activities = task.activities;
            oldTask.uAt = task.uAt;
        }
    }

    /**
    * @param {Task} oldTask
    * @param {String} oldAssignee
    */
    const removeAssignee = async (oldTask, oldAssignee) => {
        if (!oldTask.aUid) {
            let task = Object.assign(new Task(), oldTask);
            let activity = new TaskActivity();
            activity.affected = oldAssignee;
            activity.cUid = authUser.id;
            activity.type = TaskActivityType.REMOVEDASSIGNEE;
            activity.cAt = new Date();
            task.activities.push(activity);

            task = _addAuthAsRelevantIfNeeded(task);
            task.aUid = undefined;
            task.uAt = new Date();

            await _updateTask(task, oldTask);

            oldTask.aUid = task.aUid;
            oldTask.activities = task.activities;
            oldTask.uAt = task.uAt;
        }
    }

    /**
     *
     * @param {Task} oldTask
     * @param {Array<File>} documents
     */
    const onAddDocuments = async (oldTask, documents) => {

        if (documents && documents.length > 0) {
            let task = Object.assign(new Task(),oldTask);

            if (!task.documents) {
                task.documents = [];
            }
            let uploadFutures = [];
            let newDocuments = [];
            documents.forEach( (file) => {
                const filePath = `client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/task/${task.id}/${generateRandomId()}`;
                let doc = new ContentDocument();
                doc.name = file.name;
                doc.url = filePath;
                doc.size = file.size
                newDocuments.push(doc);
                uploadFutures.push(firebaseStorageManager.uploadFile(file,filePath));
            });
            await Promise.all(uploadFutures);
            task.documents = [...task.documents, ...newDocuments];
            await _updateTask (task, oldTask);

            oldTask.documents = task.documents;
            oldTask.activities = task.activities;
            oldTask.uAt = task.uAt;
        }
    }

    /**
     *
     * @param {Task} oldTask
     * @param {ContentDocument} document
     */
    const onRemoveDocument = async (oldTask, document) => {
        let task = Object.assign(new Task(),oldTask);

        if (!task.documents) {
            task.documents = [];
        }
        task.documents = task.documents.filter( (entry) => entry.url !== document.url);
        try {
            firebaseStorageManager.deleteFile(document.url);
        } catch (ex) {}
        await _updateTask (task, oldTask);

        oldTask.documents = task.documents;
        oldTask.activities = task.activities;
        oldTask.uAt = task.uAt;
    }

    /**
     *
     * @param {Task} oldTask
     * @param {Array<File>} images
     */
    const onAddImages = async (oldTask, images) => {
        if (images && images.length > 0) {
            let task = Object.assign(new Task(),oldTask);

            if (!task.images) {
                task.images = [];
            }
            task.images = [...task.images, ...images.map( (image) =>  URL.createObjectURL(image))];
            updateModelInstanceDispatch(Task, task);

            let uploadFutures = [];
            let newImagePaths = [];
            images.forEach( (image) => {
                let fileExtension = image.name.split('.');
                fileExtension = fileExtension[fileExtension.length - 1];

                const filePath = `client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/task/${task.id}/${generateRandomId()}.${fileExtension}`;
                newImagePaths.push(filePath);
                uploadFutures.push(firebaseStorageManager.uploadImage(image,filePath));
                uploadFutures.push(firebaseStorageManager.uploadImage(image, filePath+'_preview', 256 , 0.1));
            });
            await Promise.all(uploadFutures);
            task.images = task.images.filter ( (image) => !image.includes("blob:"));
            task.images = [...task.images ,...newImagePaths];
            await _updateTask (task, oldTask);

            oldTask.images = task.images;
            oldTask.activities = task.activities;
            oldTask.uAt = task.uAt;
        }
    }

    /**
     *
     * @param {Task} oldTask
     * @param {ContentDocument} image
     */
    const onRemoveImage = async (oldTask, image) => {
        let task = Object.assign(new Task(),oldTask);
        if (!task.images) {
            task.images = [];
        }
        task.images = task.images.filter( (path) => path !== image);
        try {
            firebaseStorageManager.deleteFile(image);
            firebaseStorageManager.deleteFile(image + '_preview');
        } catch (ex) {}
        await _updateTask (task, oldTask);

        oldTask.images = task.images;
        oldTask.activities = task.activities;
        oldTask.uAt = task.uAt;
    }

    /**
     *
     * @param {Task} oldTask
     */
    const onRemoveNotification = async (oldTask) => {
        let task = Object.assign(new Task(), oldTask);
        task.uAt = new Date();

        if (!task.noNot) {
            task.noNot = [];
        }

        let index = task.noNot.findIndex((id) => id === authUser.uid);
        if (index < 0) {
            task.noNot.push(authUser.uid);
        }
        await _updateTask(task, oldTask);

        oldTask.noNot = task.noNot;
        oldTask.activities = task.activities;
        oldTask.uAt = task.uAt;
    }

    /**
     *
     * @param {Task} oldTask
     */
    const onAddNotification = async (oldTask) => {
        let task = Object.assign(new Task(), oldTask);
        task.uAt = new Date();

        task = _addAuthAsRelevantIfNeeded(task);
        task.noNot = task.noNot.filter((id) => id !== authUser.uid);
        if (
            oldTask.relevant.length !== task.relevant.length
            ||
            oldTask.noNot.length !== task.noNot.length
        ) {
            await _updateTask(task, oldTask);
        }

        oldTask.noNot = task.noNot;
        oldTask.activities = task.activities;
        oldTask.uAt = task.uAt;
    }

    /**
     *
     * @param {Task} task
     * @param {boolean} fallback
     */
    const onDelete = (task, fallback = true) => {
        let clone = Object.assign(new Task(), task);
        if (!task.cAt && task.id && fallback) {
            ModelManager.subscribeDoc({model: Task, id: task.id, withCallback: (task) => onDelete(task, false)});
        } else if (task.cAt) {
            clone.dAt = new Date();
            delayedDeletes.current.push(clone.id);
            updateModelInstanceDispatch(Task, clone);

            setTimeout(() => {
                if (delayedDeletes && delayedDeletes.current && delayedDeletes.current.includes(clone.id)) {
                    ModelManager.set(
                        {
                            customData: {
                                'dAt': FireTimestamp(),
                                'uAt': FireTimestamp(),
                                'relevant': clone.relevant
                            },
                            modelInstance: clone
                        }
                    );
                }
            }, 5000)
        }
    }

    /**
     *
     * @param {Task} task
     */
    const onUndoDelete = (task) => {
        let clone = Object.assign(new Task(), task);
        task.dAt = null;

        updateModelInstanceDispatch(Task, clone);
        if (delayedDeletes && delayedDeletes.current) {
            delayedDeletes.current = delayedDeletes.current.filter( (value => value !== clone.id));
        }
    }


    const _addAuthAsRelevantIfNeeded = (task) => {
        let index = task.relevant.findIndex( (id) => id === authUser.uid);
        if (index < 0) {
            task.relevant.push(authUser.uid);
        }
        return task;
    }

    return(
        <Fragment>
            {
                children({
                    onCreate,
                    onDelete,
                    onUndoDelete,
                    onTitleChange,
                    onDescriptionChange,
                    onRepetitionChange,
                    onComplete,
                    onAssign,
                    removeAssignee,
                    onAddNotification,
                    onRemoveNotification,
                    setDueDate,
                    onAddDocuments,
                    onRemoveDocument,
                    onAddImages,
                    onRemoveImage
                })
            }
        </Fragment>
    );
};

const mapState = (state, ownProps) => ({
    authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId()),
})

const mapAction = {
    updateModelInstanceDispatch
};

export default connect(mapState,mapAction)(TaskActionContainer);