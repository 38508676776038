import FirebaseStorageManager from "../manager/manager/FirebaseStorageManager";
import ElasticClient from "../../api/ElasticClient";
import Task from "../models/Task";

export default class TaskClient {

    constructor() {
        this.firebaseStorageManager = new FirebaseStorageManager();
    }

    async searchTasks (search, size, from = 0, sortBy, direction, withTotal = false) {
        let request = ElasticClient.createRequest('task', '_search', searchQuery(search, size, from,sortBy, direction));

        return this._convertElasticResult( await request.send(), withTotal);
    }

    async searchTasksForUser (search, size, from = 0, sortBy, direction) {
        let request = ElasticClient.createRequest('task', '_search', searchQueryByUser(search, size, from,sortBy, direction));
        return this._convertElasticResult( await request.send());
    }


    _convertElasticResult = (result, withTotal = false) => {
        let resultObjects = [];
        let total = 0;

        if (result && result['hits'] &&  result['hits']['hits']) {
            let hits = result['hits']['hits'];
            total = result['hits'] ['total'];

            for (let i =0; i < hits.length; i++) {
                let file = Object.assign(
                    new Task(),
                    hits[i]["_source"]
                )
                if ( hits[i]["_source"]['uAt']) {
                    file.uAt = new Date( hits[i]["_source"]['uAt']);
                }
                if ( hits[i]["_source"]['dueDate']) {
                    file.dueDate = new Date( hits[i]["_source"]['dueDate']);
                }
                resultObjects.push(file);
            }
        }

        if (withTotal) {
            return {
                total:total,
                result: resultObjects
            };
        }
        return resultObjects;
    }
}

const searchQueryByUser = (search, size = 5, from =0, sortBy, direction) => {
    let queryBody =  {
        "size":size,
        "from":from,
        "query":{
            "bool" : {
                "should": [
                    {
                        "match": {
                            "aUid" : search
                        }
                    },
                    {
                        "bool": {
                            "must": [
                                {
                                    "match": {
                                        "cUid" : search
                                    }
                                }
                            ],
                            "must_not": [
                                {
                                    "exists": {
                                        "field": "aUid"
                                    }
                                }
                            ]
                        }
                    },
                ],
                "minimum_should_match" : 1
            }
        },
    };

    if (sortBy) {
        queryBody['sort'] = [
            {
                [sortBy]:{
                    "order":direction
                }
            }
        ]
    }
    return queryBody;
}

const searchQuery = (search, size = 5, from =0, sortBy, direction) => {
    let queryBody =  {
        "size":size,
        "from":from,
        "query":{
            "match" : {
                "search" : search
            }
        },
    };

    if (sortBy) {
        queryBody['sort'] = [
            {
                [sortBy]:{
                    "order":direction
                }
            }
        ]
    }
    return queryBody;
}

const TASKCLIENT = new TaskClient();
export {TASKCLIENT};