import {ReactionType} from "../../utils/enums/ReactionType";
import {StorageFileType} from "../../utils/enums/StorageFileType";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {TaskActivityType} from "../../utils/enums/TaskActivityType";
import {timestampToDateTasks, timestampToDateTasksActivity, timestampToDateTime} from "../../utils/helper/TextHelper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {isDateLastWeek, isDateToday, isDateYesterday} from "../../utils/helper/DateHelper";

/**
 *
 * @param {string} name
 * @param {string} lastName
 * @returns {string|null}
 */
export function getInitials( name, lastName = "") {
    if (!name) {
        return null;
    }

    if (!!lastName) {
        return name[0].toUpperCase() + lastName[0].toUpperCase();
    }
    let parts = name.trim().split(' ').map((s) => s[0]);
    return parts[0].toUpperCase() + (( parts.length > 1) ? parts[parts.length -1].toUpperCase() : "" );
}

export function getTextForReaction(reaction, t) {
    let texts = {
        [ReactionType.LIKE]: t('article.reactions.like'),
        [ReactionType.HEART]: t('article.reactions.heart'),
        [ReactionType.IDEA]: t('article.reactions.idea'),
        [ReactionType.CLAP]: t('article.reactions.clap'),
        [ReactionType.FUNNY]: t('article.reactions.funny'),
        [ReactionType.SAD]: t('article.reactions.sad')
    };

    if(reaction.startsWith("ReactionType.")) {
        return texts[reaction];
    }

    return texts["ReactionType." + reaction];
}

export function getTextForFileType(type, t) {
    let texts = {
        [StorageFileType.FOLDER]: t('storage.type.folder'),
        [StorageFileType.VIDEO]: t('storage.type.video'),
        [StorageFileType.IMAGE]: t('storage.type.image'),
        [StorageFileType.WORD]: t('storage.type.word'),
        [StorageFileType.EXCEL]: t('storage.type.excel'),
        [StorageFileType.PDF]: t('storage.type.pdf'),
        [StorageFileType.POWERPOINT]: t('article.reactions.powerpoint'),
        [StorageFileType.ZIP]: t('article.reactions.zip'),
        [StorageFileType.OTHER]: t('article.reactions.other')
    };
    let text = texts[type];

    if (text) {
        return text;
    }
    return t('storage.type.unknown');
}

function  getTaskUser(t, users, userID, assignee = false, yourself = false) {
    if (yourself && userID === AUTHSTORAGE.getUserId()) {
        return  t('tasks.yourself');
    } else if (assignee && userID === AUTHSTORAGE.getUserId()) {
        return  t('tasks.toYou');
    } else if (userID === AUTHSTORAGE.getUserId()) {
        return  t('tasks.you');
    }

    let activityUser = users.find( (user) => user.id === userID)

    if (activityUser) {
        return activityUser.fullName;
    }

    return  t('user.unknownUser');
}

export function getActivityMessage(t, users, activity) {
    let messageContent;
    switch (activity.type) {
        case TaskActivityType.CREATED:
            messageContent = t('tasks.activity.createdTask', {user: getTaskUser(t, users,activity.cUid)});
            break;
        case TaskActivityType.FINISHED:
            messageContent = t('tasks.activity.completedTask', {user: getTaskUser(t, users,activity.cUid)});
            break;
        case TaskActivityType.ASSIGNED:
            messageContent = t('tasks.activity.assignedTask', {assigner: getTaskUser(t, users,activity.cUid) ,assignee: getTaskUser(t, users,activity.affected, true,activity.affected === activity.cUid)});
            break;
        case TaskActivityType.SETDUEDATE:
            messageContent = t('tasks.activity.changedDueDate', {user: getTaskUser(t, users,activity.cUid) ,date: timestampToDateTasks(activity.dueDate)});
            break;
        case TaskActivityType.EDITDESCRIPTION:
            messageContent = t('tasks.activity.editedDescription', {user: getTaskUser(t, users,activity.cUid)});
            break;
        case TaskActivityType.EDITTITLE:
            messageContent = t('tasks.activity.editedTitle', {user: getTaskUser(t, users,activity.cUid)});
            break;
        case TaskActivityType.REMOVEDASSIGNEE:
            messageContent = t('tasks.activity.removedAssignee', {user: getTaskUser(t, users,activity.cUid),assignee: getTaskUser(t, users,activity.affected, false,activity.affected === activity.cUid)});
            break;
        case TaskActivityType.REMOVEDDUEDATE:
            messageContent = t('tasks.activity.removedDueDate', {user: getTaskUser(t, users,activity.cUid) });
            break;
    }

    if (messageContent && activity.cUid === AUTHSTORAGE.getUserId()) {
        // correct german translations if auth user is target
        messageContent = messageContent.replaceAll(" hat ", " haben ");
    }

    if (messageContent) {
        return <Typography variant="h6">
            <div dangerouslySetInnerHTML={{__html: messageContent }} />
        </Typography>;
    }
    return '';
}

export function getActivityDate(t, date) {
    let now = new Date();

    let hoursSinceModified = Math.abs(now.getTime() - date.getTime()) / 3600000;
    let minutesSinceModified = Math.floor(hoursSinceModified * 60);
    let daysSinceModified = Math.floor(hoursSinceModified / 24);

    if (isDateToday(date)) {
        if (hoursSinceModified < 1) {
            if (minutesSinceModified < 1) {
                return t('tasks.now')
            }
            return t('tasks.xMinutesAgo', {minutes: minutesSinceModified});
        }

        return Math.floor(hoursSinceModified) === 1 ? t('tasks.oneHourAgo') : t('tasks.xHoursAgo', {hours: Math.floor(hoursSinceModified)});
    }

    if (isDateLastWeek(date)) {
        if (isDateYesterday(date)) {
            return t('common.yesterday');
        }

        return daysSinceModified === 1 ? t('tasks.oneDayAgo') : t('tasks.xDaysAgo', {days: daysSinceModified});
    }

    return timestampToDateTasksActivity(date);
}

export function getFormattedTimeLeftUntil(t, until) {
    const diffTime = Math.abs(until - new Date());
    const diffMinutes = Math.floor(diffTime / (1000 * 60));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    let formattedTimeLeft = "";

    if(diffDays > 0) {
        formattedTimeLeft += diffDays !== 1 ? t('article.survey.multipleDays', { days: diffDays }) : t('article.survey.oneDay');
    }

    if(diffHours > 0) {
        let realHours = diffHours - (diffDays * 24);
        formattedTimeLeft += " " + ((realHours !== 1) ? t('article.survey.multipleHours', { hours: realHours }) : t('article.survey.oneHour'));
    }

    let realMinutes = diffMinutes - (diffHours * 60);
    formattedTimeLeft += " " + (realMinutes !== 1 ? t('article.survey.multipleMinutes', { minutes: realMinutes }) : t('article.survey.oneMinute'));
    formattedTimeLeft += " " + t('article.survey.timeLeft');

    return formattedTimeLeft;
}