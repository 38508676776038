import {getModelInstance} from "../../utils/store/selectors";
import {connect} from "react-redux";
import Survey from "../../utils/models/Survey";
import ArticleSurvey from "./ArticleSurvey";
import React, {useState} from "react";
import {SurveyType} from "../../utils/enums/SurveyType";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {updateModelInstanceDispatch} from "../../utils/store/actions";
import {FireIncrement} from "../../api/FirebaseService";

/**
 * @param {Survey} survey
 * @param {Article} article
 * @param {Function} updateModelInstance
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleSurveyContainer = ({survey, article, updateModelInstanceDispatch}) => {
    const [rebuild, setRebuild] = useState(true);

    const voteOnSurvey = (selectedOptions, answer) => {
        if ((!selectedOptions || selectedOptions.length === 0) && !answer) {
            return;
        }

        if(survey.type === SurveyType.FREEANSWER) {
            const surveyUpdateData = {
                options: {},
                total: FireIncrement(1)
            };

            const surveyAnswerData = {
                text: answer
            };

            survey.total++;
            survey.authUserAnswer = answer;

            updateModelInstanceDispatch(Survey, survey);
            ModelManager.update({modelInstance: survey, customData: surveyUpdateData});
            ModelManager.update({customData: surveyAnswerData, resource: '/client/' + AUTHSTORAGE.getClientId() + '/survey/' + survey.id + '/answer', id: AUTHSTORAGE.getUserId(), useSet: true});
        } else {
            const surveyUpdateData = {
                options: {},
                total: FireIncrement(1)
            };

            selectedOptions.forEach(option => {
                surveyUpdateData['options'][option] = {
                    count: FireIncrement(1)
                };

                survey.options[option].count++;
            });

            survey.total++;
            survey.authUserVotes = selectedOptions;

            updateModelInstanceDispatch(Survey, survey);
            ModelManager.update({modelInstance: survey, customData: surveyUpdateData, useSet: true});
            ModelManager.update({customData: {votes: selectedOptions}, resource: '/client/' + AUTHSTORAGE.getClientId() + '/survey/' + survey.id + '/vote', id: AUTHSTORAGE.getUserId(), useSet: true});
        }

        setRebuild(!rebuild);
    };

    return(
        <ArticleSurvey survey={survey} article={article} voteOnSurvey={voteOnSurvey}/>
    );
};

const mapState = (state, ownProps) => ({
    survey: getModelInstance(state, Survey, ownProps.id)
});

const mapAction = {
    updateModelInstanceDispatch
};

export default connect(mapState, mapAction)(ArticleSurveyContainer);