import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import Storage from "./Storage";
import StorageFile from "../../utils/models/StorageFile";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {
    deleteModelInstance,
    deleteModelInstancesDispatch, deleteModelInstancesWhere,
    updateModelInstance, updateModelInstances,
} from "../../utils/store/actions";
import {STORAGECLIENT} from "../../utils/storage/StorageClient";
import {SortDirections, StorageSortField} from "../../utils/enums/StorageSort";
import {getModelInstances} from "../../utils/store/selectors";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import {StorageFileType} from "../../utils/enums/StorageFileType";
import FireStorageUrl from "../../utils/models/FireStorageUrl";


const StorageContainer = ({initialPath, files, firestoreUrls}) => {
    let batchSize = 50;
    const firebaseStorageManager = new FirebaseStorageManager();
    let [path, setPath] = useState(initialPath ?? "");
    let loading = useRef(true);
    let validPath = useRef(true);
    let hasMore = useRef(true);
    let currentSortField = useRef(StorageSortField.NAME);
    let currentSortDirection = useRef(SortDirections.ASCENDING);
    let shownFiles = useRef([]);
    let lastRequest = useRef(0);
    const [rebuild, setRebuild] = useState(true);
    const [redirectToPath, setRedirectToPath] = useState();

    useEffect(() => {
        if (initialPath) {
            let parts = initialPath.split('/');
            parts.forEach( async (id) => {
                let exists = files.find( (file) => file.id === id);
                if (!exists) {
                    let files = await STORAGECLIENT.loadSingleFile(id);
                    if (id === parts[parts.length -1] && files.length === 0) {
                        validPath.current = false;
                        setRebuild(!rebuild);
                    } else {
                        updateModelInstances(StorageFile, files);
                    }
                }
            })
        }
        loadFiles({path: path});
    }, []);



    const addFile = async ({file, name, path, type, onProgress, isCanceled}) => {
        let oldFile;
        if ( type !== StorageFileType.FOLDER) {
            let sameNameExists = shownFiles.current.findIndex((storageFile) => storageFile.name.toLowerCase() === name.toLowerCase() && storageFile.type === type);
            if (sameNameExists >= 0) {
                oldFile = shownFiles.current[sameNameExists];
            }
        }
        let storageFile = await STORAGECLIENT.addFile(file, name, path, type, onProgress, isCanceled, oldFile);
        if (storageFile && shouldAddNewFile(storageFile) ) {
            updateModelInstance(StorageFile,storageFile);
        }
    }

    const renameFile = async ({file, name}) => {
         STORAGECLIENT.renameFile(file, name);

        let storageFile = Object.assign(new StorageFile(),file,{name: name, uAt: new Date()});
        if (storageFile && shouldAddNewFile(storageFile) ) {
            updateModelInstance(StorageFile,storageFile);
        } else {
            deleteModelInstance(StorageFile,storageFile);
        }
    }


    const shouldAddNewFile = (file) => {
        if (shownFiles.current.length < batchSize)
            return true;

        let sortedFiles = sortActiveFiles(shownFiles.current);
        return !(sortedFiles[sortedFiles.length-1].id === file.id);
    }

    const downloadWithProgress = async ({id, path, name, onProgress, onReady, isCanceled}) => {
        const fullPath = `/client/${AUTHSTORAGE.getClientId()}/storage/${path}/${id}`;
        await firebaseStorageManager.downloadWithProgress(fullPath, name, onProgress, onReady, isCanceled );
    }

    const deleteFile = async ({id, path, type}) => {
       let success =  await STORAGECLIENT.deleteFile(id, path, type);

       if (success) {
           deleteModelInstance(StorageFile,
               Object.assign(new StorageFile(), {
                   id: id
               })
           );
       }
    }

    const loadFiles = async ({path, sortBy = StorageSortField.NAME, direction= SortDirections.ASCENDING, from =0}) => {
        let requestTime = Date.now()
        lastRequest.current = requestTime;
        currentSortField.current = sortBy;
        currentSortDirection.current = direction;

        if (from === 0) {
            hasMore.current = true;
        }

        if (hasMore.current) {
            loading.current = true;
            setRebuild((old) => !old);

            if (from === 0) {
                let searchPath = `${path}`;
                if ( searchPath[searchPath.length-1] === "/") {
                    searchPath = searchPath.substring(0,searchPath.length-1);
                }
                deleteModelInstancesWhere(StorageFile,{field: "path", value: searchPath})
            }

            let files = await STORAGECLIENT.loadPath(path, from, sortBy, direction, batchSize);

            if (requestTime === lastRequest.current) {
                setPath(path);
                updateModelInstances(StorageFile, files);
                loading.current=false;
                hasMore.current = files.length > 0;
                setRebuild((old) => !old);
            }
        }
    }

    const onFolderChange = (path) => {
        setRedirectToPath(path.replaceAll("/","!!"));
    }

    if(redirectToPath || redirectToPath === "") {
        return (
            <Redirect push to={ROUTES.storageWithPath.replace(':query', redirectToPath)}/>
        );
    }

    const sortActiveFiles = (files) => {
        let neededFiles = files.filter( (file) => file.path === path);
        let sortFunction;

        switch (currentSortField.current) {
            case StorageSortField.NAME:
                sortFunction =  function compareName(a, b) {
                    let result = 0;

                    if (a.type === StorageFileType.FOLDER && b.type !== StorageFileType.FOLDER ) {
                        return -1;
                    }
                    if (b.type === StorageFileType.FOLDER && a.type !== StorageFileType.FOLDER) {
                        return 1;
                    }
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        result = -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        result = 1;
                    }
                    return (currentSortDirection.current === SortDirections.ASCENDING ? 1 : -1 ) * result;
                }
                break;
            case StorageSortField.CHANGED:
                sortFunction =  function compareChanged(a, b) {
                    return (currentSortDirection.current === SortDirections.ASCENDING ? 1 : -1 ) * ( (a.uAt ?? a.cAt ?? 0) - (b.uAt ?? b.cAt ?? 0));
                }
                break;
            case StorageSortField.SIZE:
                sortFunction =  function compareSize(a, b) {
                    return (currentSortDirection.current === SortDirections.ASCENDING ? 1 : -1 ) * (parseInt(a.size ?? 0) - parseInt(b.size ?? 0));
                }
                break;
        }
        neededFiles.sort(sortFunction);
        return neededFiles;
    }

    shownFiles.current = [...sortActiveFiles(files)];
    return  <Storage
        files={shownFiles.current}
        allFiles={files}
        loading={loading.current}
        validPath={validPath.current}
        path={path}
        onFolderChange={onFolderChange}
        onAddFile={addFile}
        onRenameFile={renameFile}
        onDeleteFile={deleteFile}
        onDownloadFile={downloadWithProgress}
        loadFiles={loadFiles}
        firestoreUrls={firestoreUrls}
    />
};

const mapState = (state, ownProps) => ({
    firestoreUrls: getModelInstances(state, FireStorageUrl),
    files: getModelInstances(state, StorageFile)
});

const mapAction = {
    deleteModelInstancesDispatch,
};

export default connect(mapState,mapAction)(StorageContainer);
