import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import LuxonUtils from '@date-io/luxon';
import {MuiPickersUtilsProvider } from "@material-ui/pickers";
import i18next from '../src/utils/i18n/i18n';
import {I18nextProvider} from "react-i18next";
import {theme} from "./assets/jss/masterLayout";
import {ThemeProvider} from "@material-ui/core/styles";

document.addEventListener('DOMContentLoaded', (e) => {
    const container = document.body.querySelector('#app');
    ReactDOM.render(
        <ThemeProvider theme={theme()}>
            <React.StrictMode>
                <I18nextProvider i18n={i18next}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <App />
                    </MuiPickersUtilsProvider>
                </I18nextProvider>
            </React.StrictMode>
        </ThemeProvider>,
    container);
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
