import React, {useEffect, useState} from 'react';
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Colors} from "../helper/ColorHelper";
import {isDateYesterdayOrEarlier} from "../../utils/helper/DateHelper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MoinButton from "../base/MoinButton";
import MoinTextField from "../base/MoinTextField";
import {FireAuth, firebase} from "../../api/FirebaseService";
import {encryptLocalStorageData, padDecryptionPassword} from "../../utils/helper/EncryptionHelper";
import * as forge from "node-forge";
import ModelManager from "../../utils/manager/ModelManager";

const useStyles = makeStyles((theme) => ({
    infoText: {
        color: Colors.BLACK
    },
    bannerContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: Colors.WHITE,
        padding: "24px 16px",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.09), 0px 0px 4px rgba(0, 0, 0, 0.1)',
    },
    actionRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    firstLink: {
        marginRight: "32px"
    },
    link: {
        cursor: 'pointer',
        color: Colors.BRANDSECONDARY,
        textTransform: "none",
        textDecoration: "none",

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    },
    confirmDialogOptions: {
        justifyContent: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    dialogTitle: {
        padding: "16px 24px"
    },
    dialogContent: {
        padding: "8px 24px"
    }
}));

const VerifyPasswordBanner = () => {
    const classes = useStyles();
    const [t] = useTranslation();

    const [hasVerifiedPassword, setHasVerifiedPassword] = useState(true);
    const [hasSetRemindLater, setHasSetRemindLater] = useState(true);
    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const [error, setError] = useState(null);
    const [passwordText, setPasswordText] = useState("");

    useEffect(() => {
        setHasVerifiedPassword(!!localStorage.getItem("pwe_" + AUTHSTORAGE.getUserId()));

        let remindLaterDate = localStorage.getItem('remindLaterPassword');
        if(remindLaterDate) {
            let convertedDate = new Date(remindLaterDate);

            if(isDateYesterdayOrEarlier(convertedDate)) {
                setHasSetRemindLater(false);
            }
        } else {
            setHasSetRemindLater(false);
        }

        return () => {};
    }, []);

    if(hasVerifiedPassword || hasSetRemindLater) {
        return <div/>;
    }

    const updatePrivateKey = async (newPassword) => {
        let encryptionKey = AUTHSTORAGE.getBrowserEncryptionKey();

        let newEncryptionPassword = padDecryptionPassword(newPassword, AUTHSTORAGE.getUserId());
        let encryptedPassword = encryptLocalStorageData(encryptionKey.browserEncryptionKey, encryptionKey.browserEncryptionIV, newEncryptionPassword);
        localStorage.setItem("pwe_" + AUTHSTORAGE.getUserId(), encryptedPassword);

        let iv = forge.random.getBytesSync(16);
        let bytePassword = forge.util.encodeUtf8(newEncryptionPassword);

        let cipher = forge.cipher.createCipher('AES-CBC', bytePassword);
        cipher.start({iv: iv});
        cipher.update(forge.util.createBuffer(AUTHSTORAGE.getRawPrivateKey()));
        cipher.finish();

        encryptionKey.privateKey = cipher.output.toHex();
        encryptionKey.iv = forge.util.encode64(iv);

        await ModelManager.set({resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/private`, modelInstance: encryptionKey, id: 'key'});
    };

    const onEnteredPassword = async () => {
        setError(null);
        let reauthenticationSuccess = false;

        let credential = firebase.auth.EmailAuthProvider.credential(
            FireAuth.currentUser.email,
            passwordText
        );

        await FireAuth.currentUser.reauthenticateWithCredential(credential)
            .then((_) => {
                reauthenticationSuccess = true;
            })
            .catch(({code}) => {
                let message = t('errors.login.unknown');

                switch (code) {
                    case "auth/wrong-password":
                        message = t('changePassword.wrongPassword');
                        break;
                    default:
                        break;
                }

                setError(message);
                reauthenticationSuccess = false;
            });

        if(reauthenticationSuccess) {
            await updatePrivateKey(passwordText);

            setHasVerifiedPassword(true);
            setShowPasswordDialog(false);
        }
    };

    const remindLater = () => {
        let now = new Date();
        localStorage.setItem('remindLaterPassword', now.toISOString());
        setHasSetRemindLater(true);
    };

    const getPasswordDialog = () => {
        return (
            <Dialog
                open={showPasswordDialog}
                onClose={() => setShowPasswordDialog(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <Typography variant="h2" id="dialog-title" className={classes.dialogTitle}>{ t('login.enterPassword') }</Typography>

                <DialogContent>
                    <Typography variant="caption">{ t('login.enterPasswordDialogDescription') }</Typography>
                    <MoinTextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        type="password"
                        autoComplete="current-password"
                        value={passwordText}
                        placeholder={ t('login.yourPassword') }
                        onChange={(e) => setPasswordText(e.target.value)}
                        error={error}
                    />
                </DialogContent>
                <DialogActions className={classes.confirmDialogOptions}>
                    <MoinButton onClick={() => onEnteredPassword()}>{ t('buttons.accept') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            {
                getPasswordDialog()
            }
            <div className={classes.bannerContainer}>
                <Typography variant="h3" className={classes.infoText}>{ t('login.newSecurityUpdate') }</Typography>
                <span className={classes.infoText}>{ t('login.newSecurityUpdateConfirmPassword') }</span>

                <div className={classes.actionRow}>
                    <Typography variant="body1" className={`${classes.link} ${classes.firstLink}`} onClick={remindLater}>{ t('login.remindLater') }</Typography>
                    <MoinButton onClick={() => setShowPasswordDialog(true)}>{ t('login.enterPassword') }</MoinButton>
                </div>
            </div>
        </>
    );
}

export default VerifyPasswordBanner;
