import {model, prop} from "../manager/decorators";

@model()
class SurveyOption {
    @prop()
    text;

    @prop()
    count;
}

export default SurveyOption;
