import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";

import DeleteIcon from '../../assets/icons/delete.svg';
import PDFIcon from "../../assets/icons/pdf.svg";
import CloseIcon from '../../assets/icons/close.svg';
import CheckIcon from '../../assets/icons/check.svg';
import {FireStorage} from "../../api/FirebaseService";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    deleteIcon: {
        cursor: 'pointer',
        marginLeft: "8px",
        width: "20px",
        height: "20px"
    },
    progressBar: {
        width: "40px",
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    pdf: {
        width: "100%",
        height: "64px",
        padding: "20px 16px",
        backgroundColor: Colors.WHITE,
        borderRadius: '16px',
        border: '1px solid ' + Colors.BLUELIGHT,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        textDecoration: 'none',
    },
    pdfInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
    },
    uploadInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
    },
    pdfName: {
        marginLeft: theme.spacing(1)
    },
    iconContainer: {
        marginLeft: "16px",
        width: "20px",
        height: "20px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

/**
 * @param {File} file
 * @param {boolean} withActions
 * @param {boolean} reverseColor
 * @param {Object} style
 * @param {Function} onDelete
 * @returns {JSX.Element}
 * @constructor
 */
const IllnessFileUpload = ({file,style,withActions=true, reverseColor= false, onDelete}) => {
    const classes = useStyles();
    const [upload, setUpload] = useState(withActions);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let duration =  Math.random() * 1000 + 500;
        if (file) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress>= 100) {
                        setUpload(false);
                        clearInterval(timer);
                    }
                    return Math.min(oldProgress + (100/ (duration / 100) ), 100);
                });
            }, 100);
        }
    }, []);


    return(
        <div className={classes.wrapper} style={style}>
            <div className={classes.pdf}>
                <div className={classes.pdfInfo}>
                    {
                        file.name.split('.').pop() === "pdf"
                            ?   <PDFIcon fill={Colors.BRANDPRIMARY} style={{ height: '2rem' }}/>
                            :   <PDFIcon fill={Colors.BRANDPRIMARY} style={{ height: '2rem' }}/>
                    }

                    <div className={classes.pdfName}>
                        <Typography variant="body1">{file.name}</Typography>
                    </div>
                </div>

                <div className={classes.uploadInfo}>
                    {
                        upload &&
                        <LinearProgress color="primary" className={classes.progressBar} variant="determinate" value={progress}/>
                    }
                    {
                        upload
                            ? <div className={classes.iconContainer} style={{backgroundColor: Colors.BLACKLIGHTEST, cursor: "pointer"}}>
                                <CloseIcon  fill={Colors.WHITE} width={"16px"} height={"16px"} />
                            </div>
                            : <div className={classes.iconContainer} style={{backgroundColor: reverseColor? Colors.WHITE : Colors.SUCCESS}}>
                                <CheckIcon fill={reverseColor? Colors.SUCCESS : Colors.WHITE } width={"16px"} height={"16px"}  />
                            </div>
                    }
               </div>

            </div>
            {
                withActions &&
                <div className={classes.deleteIcon}>
                    {!upload &&
                         <DeleteIcon fill={Colors.BLACKLIGHT} style={{ height: '20px', width: '20px'}} onClick={ () => onDelete()}/>
                    }
                </div>
            }

        </div>
    )
}

export default IllnessFileUpload;
