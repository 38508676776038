import ArticleCreateUpdate from "./ArticleCreateUpdate";
import React from "react";
import ApiClient from "../../api/ApiClient";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {getModelInstance} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import {connect} from "react-redux";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import {ContentType} from "../../utils/enums/ContentType";
import {SurveyType} from "../../utils/enums/SurveyType";
import * as httpClient from "../../utils/httpclient/HttpClient";
import parameters from "../../config/parameters.json";
import Article from "../../utils/models/Article";
import {generateRandomId} from "../../utils/helper/RandomHelper";
import SuccessMessage from "../../utils/models/SuccessMessage";
import SUCCESSNOTIFICATIONMANAGER from "../../components/success/SuccessNotificationManager";
import {SuccessType} from "../../utils/enums/SuccessType";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import {deleteModelInstance} from "../../utils/store/actions";
import {getArticleTitlePath} from "../../utils/helper/ImageHelper";
import {FireAuth, FireTimestamp} from "../../api/FirebaseService";

/**
 * @param {User} authUser
 * @param {String} preselectedDisId
 * @param {String} originPath
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleCreateContainer = ({authUser, preselectedDisId, originPath}) => {
    const firebaseStorageManager = new FirebaseStorageManager();
    /**
     * @param {Article} article
     * @param {File} titleImage
     * @returns {Promise<void>}
     */
    const uploadMedia = async (article, titleImage) => {
        const mediaFutures = [];

        if(!!titleImage) {
            let titleImageUploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article/${article.id}/title_image`;
            mediaFutures.push(firebaseStorageManager.uploadImage(titleImage, titleImageUploadPath, 2048 , 1));
            article.hTI = true;
        }

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];

            if(content.type === ContentType.MEDIA) {
                if(!!content.image && !!content.image.name) {
                    let fileExtension = content.image.name.split('.');
                    fileExtension = fileExtension[fileExtension.length - 1];

                    const uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article/${article.id}/${generateRandomId()}.${fileExtension}`;
                    mediaFutures.push(async function () {
                        await firebaseStorageManager.uploadImage(content.image, uploadPath, 1200 , 1);
                        content.image = uploadPath.substring(1).replace(/\//g, '%2F');
                    }());
                }
            } else if(content.type === ContentType.PDF) {
                article.content[i].size = content.path.size;
                if(!!content.path && !!content.path.name) {
                    let fileExtension = content.path.name.split('.');
                    fileExtension = fileExtension[fileExtension.length - 1];

                    const uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article/${article.id}/${generateRandomId()}.${fileExtension}`;

                    mediaFutures.push(async function () {
                        await firebaseStorageManager.uploadFile(content.path, uploadPath);
                        content.path = uploadPath.substring(1).replace(/\//g, '%2F');
                    }());
                }
            }
        }

        await Promise.all(mediaFutures);

        let titleUrl = new FireStorageUrl();
        titleUrl.id = getArticleTitlePath(article);
        deleteModelInstance(FireStorageUrl, titleUrl);
    };

    /**
     * @param {Article} article
     * @param surveys
     */
    const createSurveys = async (article, surveys) => {
        const surveyFutures = [];

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];
            if(content.type === ContentType.SURVEY) {

                const survey = surveys[content.surveyId];

                if (survey && !!survey.question && survey.options && Object.keys(survey.options).length > 0) {

                    survey.total = 0;

                    const surveyData = {
                        aId: article.id,
                        question: survey.question,
                        total: 0,
                        type: survey.type,
                        options: {},
                        aP: survey.aP ?? "Privacy.PRIVATE"
                    };

                    if(survey.type !== SurveyType.FREEANSWER) {
                        surveyData.options = Object.assign({}, Object.values(survey.options).map(option => ({text: option.text, count: 0})));
                    }

                    surveyFutures.push(ModelManager.update({modelInstance: survey, id: survey.id, customData: surveyData, useSet: true}));
                }
            }
        }

        await Promise.all(surveyFutures);
    };

    /**
     * @param {Article} article
     * @returns {Promise<boolean>}
     */
    const compressVideos = async (article) => {
        let uploadedVideos = false;
        let convertFutures = [];

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];

            if(content.type === ContentType.MEDIA && !!content.uploadId) {
                uploadedVideos = true;

                let formData = new FormData();
                formData.append('uploadId', ""+content.uploadId);


                let httpClientInstance = new httpClient.default(parameters.backendVideoUrl, {
                    headers: {
                        'Authorization': `Bearer ${await FireAuth.currentUser.getIdToken()}`
                    }
                });

                let request = httpClientInstance.post('/convert', formData);

                convertFutures.push(request.send());
            }
        }

        await Promise.all(convertFutures);
        return uploadedVideos;
    };

    /**
     * @param {Article} article
     * @param {File} titleImage
     * @param {Distributor} distributor
     * @param surveys
     * @returns {Promise<void>}
     */
    const createNewArticle = async (article, titleImage, distributor, surveys) => {
        article.cName = authUser.fullName;
        article.cUid = AUTHSTORAGE.getUserId();
        article.heart = 0;
        article.cCount = 0;
        article.receivers = distributor.count;
        article.stream = true;
        article.dis = [distributor.id];
        article.newFormat = false;

        if(distributor.isPublic) {
            article.public = true;
        }

        await uploadMedia(article, titleImage);

        // Create Article
        await ModelManager.add({modelInstance: article});
        let uploadedVideos = await compressVideos(article);
        await createSurveys(article, surveys);

        // Link Article to AuthUser
        let articleLinkData = {
            "cAt": FireTimestamp(),
            "dis": article.dis
        };

        await ModelManager.update({customData: articleLinkData, resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article`, id: article.id, useSet: true});

        // Link article to Distributor
        let body = {'articleId': article.id};
        let request = await ApiClient.createRequest('/linkArticle', body);
        request.send().then(() => {
            SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.ARTICLEPUBLISHED, true));
        });

        if(uploadedVideos) {
            ModelManager.subscribeDoc({model: Article, id: article.id, extraProps: {completionCheck: true}});
        }
    };

    return <ArticleCreateUpdate saveArticle={createNewArticle} preselectedDisId={preselectedDisId} originPath={originPath}/>
}


const mapState = (state) => ({
    authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
});

export default connect(mapState)(ArticleCreateContainer);
