import {StorageFileType} from "../enums/StorageFileType";
import PDFIcon from "../../assets/icons/pdf.svg";
import {Colors} from "../../components/helper/ColorHelper";
import FolderFilledIcon from "../../assets/icons/folder-filled.svg";
import BrokenFileIcon from "../../assets/icons/file-broken.svg";
import FileZipIcon from "../../assets/icons/file-zip.svg";
import FilePowerpointIcon from "../../assets/icons/file-powerpoint.svg";
import FileExcelIcon from "../../assets/icons/file-excel.svg";
import FileWordIcon from "../../assets/icons/file-word.svg";
import FileIcon from "../../assets/icons/file.svg";
import FireStorageImage from "../../components/base/FirestoreImage";
import React from "react";
import PlayIcon from "../../assets/icons/play.svg";
import {Typography} from "@material-ui/core";

export function setFileTypeIcon (file, fallbackWidget) {
    if (file.type === StorageFileType.PDF) {
        return <FireStorageImage src={file.previewPath} fallbackWidget={fallbackWidget ||  <PDFIcon fill={Colors.ERROR}/>}/>
    } else if (file.type === StorageFileType.FOLDER) {
        return <FolderFilledIcon fill={Colors.BRANDTERTIARY}/>
    } else if (file.type === StorageFileType.ZIP) {
        return <FileZipIcon fill={Colors.BLACKLIGHTER}/>
    } else if (file.type === StorageFileType.POWERPOINT) {
        return <FilePowerpointIcon fill={Colors.MSPOWERPOINTORANGE}/>
    } else if (file.type === StorageFileType.EXCEL) {
        return <FileExcelIcon fill={Colors.MSEXCELGREEN}/>
    } else if (file.type === StorageFileType.WORD) {
        return <FileWordIcon fill={Colors.MSWORDBLUE}/>
    } else if (file.type === StorageFileType.OTHER) {
        return <FileIcon fill={Colors.BLACKLIGHTER}/>
    } else if (file.type === StorageFileType.IMAGE ) {
        return <FireStorageImage src={file.previewPath} fallbackWidget={ <BrokenFileIcon fill={Colors.BLACKLIGHTER}/>}/>
    } else if (file.type === StorageFileType.VIDEO ) {
        return <div style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <FireStorageImage src={file.previewPath} fallbackWidget={ <BrokenFileIcon fill={Colors.BLACKLIGHTER}/>}/>
            <div style={{
                position: 'absolute',
                height: '14px',
                width: '14px',
                borderRadius: '14px',
                backgroundColor: Colors.BLUELIGHT,
                fill: Colors.WHITE,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pointerEvents: 'none',
            }}>
                <PlayIcon fill={Colors.WHITE}/>
            </div>
        </div>
    }
}