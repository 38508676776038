import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {sortUsersByFirstname} from "../../utils/helper/SortHelper";
import * as ROUTES from "../../Routes";
import UserListItem from "../user/MoinUserListItem";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

import ChevronRightIcon from "../../assets/icons/chevron-right.svg";
import {Colors} from "../helper/ColorHelper";


const useStyles = makeStyles(theme => ({
    link: {
        cursor: "pointer",
        textDecoration: "none"
    },
}))

/**
 *
 * @param {Department} department
 * @param {Array<User>} users
 * @param {boolean} divider
 * @param {function} altTile
 * @returns {JSX.Element}
 * @constructor
 */
 const MoinDepartmentListItem = ({department, users = [], divider = true, altTile}) => {
  const [t, i18n] = useTranslation();
  const [showUsers, setShowUsers] = useState(false);
  const classes = useStyles();

  return <>
    <ListItem
      button
      divider={divider}
      onClick={() => setShowUsers(!showUsers)}
    >
      <ListItemText
        primary={department.name}
        secondary={users.length > 1 ? `${users.length} ${t("common.persons")}` : `${users.length} ${t("common.person")}`}
      />
      <ListItemSecondaryAction onClick={() => setShowUsers(!showUsers)}>
        <ChevronRightIcon fill={Colors.BLACK} />
      </ListItemSecondaryAction>
    </ListItem>
    {showUsers && users.sort(sortUsersByFirstname).map((user, index, collection) =>
        altTile
            ? altTile({user,index,collection})
            : <Link className={classes.link} to={ROUTES.userShow.replace(':id', user.id)}>
              <UserListItem
                      divider={index !== collection.length-1}
                      key={index}
                      user={user}
              />
        </Link>
    )}
  </>
};

export default MoinDepartmentListItem;
