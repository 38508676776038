import React, {useState} from 'react';
import {CardContent} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import {useTranslation} from "react-i18next";
import parameters from "../../config/parameters.json";
import FireStorageImage from "../base/FirestoreImage";
import MoinSampleUsersList from "../base/MoinSampleUsersList";
import {getModelInstance} from "../../utils/store/selectors";
import {connect} from "react-redux";
import Distributor from "../../utils/models/Distributor";
import * as ROUTES from "../../Routes";
import {Link, Redirect} from "react-router-dom";
import GroupMembershipContainer from "../../pages/groups/GroupMembershipContainer";
import Typography from "@material-ui/core/Typography";
import {MoinCard} from "../base/MoinCard";
import {Colors} from "../helper/ColorHelper";
import GroupMenuWrapper from "./GroupMenu";
import GroupJoinButtonWrapper from "./GroupJoinButton";
import MoinListItem from "../base/MoinListItem";
import HIGHLIGHTEDGROUPS from "../../utils/groups/HighlightedGroups";
import PinActiveFilled from "../../assets/icons/pin-active-filled.svg";

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: theme.spacing(1.5),
        padding: theme.spacing(1.5,2),
        alignItems: 'start',
        "& > :last-child": {
            padding: theme.spacing(0)
        },
    },
    content: {
        padding: theme.spacing(0),
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "space-between",
        width: "100%"
    },
    header: {
        width: '100%',
        margin: theme.spacing(0,0,1,0),
        padding: '0'
    },
    link: {
        cursor: "pointer",
        textDecoration: "none"
    },
    highlightedGroupLabel: {
        margin: theme.spacing(0, 2, 1, 0),
        color: Colors.BRANDPRIMARY,
        display: 'flex',
        alignItems: 'center'
    },
    highlightedGroupIcon: {
        color: Colors.BRANDPRIMARY,
        height: '16px',
        width: '16px',
        marginRight: '4px'
    }
}));

/**
 *
 * @param {Distributor} distributor
 * @param {Function} onRequest
 * @param {Function} onRequestRemove
 * @param {Function} onLeave
 * @param {boolean} withoutActions
 * @returns {JSX.Element}
 * @constructor
 */
const GroupTag = ({distributor, onRequest, onRequestRemove, onLeave, withoutActions = false}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    const [redirect, setRedirect] = useState(false);

    if (!distributor) {
        return <></>;
    }

    let name = distributor?.name;
    if (distributor?.id === parameters.globalDistributorId) {
        name = t('distributor.global');
    }

    let memberCount;
    switch (distributor?.count) {
        case undefined:
            break;
        case 0:
            memberCount = t('distributor.noMembersCount');
            break;
        case 1:
            memberCount = t('distributor.singleMemberCount');
            break;
        default:
            memberCount = t('distributor.xMembersCount', {count: distributor?.count})
            break;
    }

    if (redirect) {
        return <Redirect push to={ROUTES.groupShow.replace(":id", distributor.id)}/>
    }

    return (
        <Link to={ROUTES.groupShow.replace(":id", distributor.id)} className={classes.link} underline="none">
            <MoinCard hasRoundedBorders={true} className={classes.card}>
                <MoinListItem
                    withDivider={false}
                    withSplashEffect={false}
                    customClass={classes.header}
                    image={
                        <FireStorageImage
                            isAvatar={true}
                            radius={24}
                            className={classes.avatar}
                            alt={name}
                            src={distributor.avatar}
                            backgroundColor={Colors.identifierAsColor(distributor.id)}
                        />
                    }
                    title={name}
                    subtitle={(distributor.isPublic ? t('distributor.public') : t('distributor.private')) + ' • ' +  memberCount}
                    trailing={
                        !withoutActions &&
                        [<GroupMenuWrapper id={distributor.id + '_context_menu'} key={distributor.id + '_context_menu'} distributor={distributor}/>]
                    }
                    leading={
                        HIGHLIGHTEDGROUPS.isHighlighted(distributor.id) &&
                        <div className={classes.highlightedGroupLabel}>
                            <PinActiveFilled className={classes.highlightedGroupIcon}/>
                            {t('distributor.highlight.highlightedGroup')}
                        </div>
                    }
                />
                <CardContent className={classes.content}>
                    {
                        distributor && distributor.sample &&
                        <MoinSampleUsersList ids={distributor.sample.map( (user) => user.uid)} showMore={distributor.count > distributor.sample.length}/>
                    }
                    <GroupJoinButtonWrapper id={distributor.id + '_joinButton'} distributor={distributor}/>
                </CardContent>
            </MoinCard>
        </Link>
    );
};

const GroupTagContainer  = ({distributor, withoutActions = false}) => {
    if (!distributor) {
        return <></>;
    }

    return <GroupMembershipContainer distributorId={distributor.id}  key={"distributor-membership-container-" + distributor.id}>
        {
            ({onRequest, onRequestRemove, onLeave}) =>
                <GroupTag
                    distributor={distributor}
                    onRequest={onRequest}
                    onRequestRemove={onRequestRemove}
                    onLeave={onLeave}
                    withoutActions={withoutActions}
                />
        }
    </GroupMembershipContainer>
}


const mapState = (state, ownProps) => ({
    distributor: getModelInstance(state, Distributor,ownProps.distributorId),
});

export default connect(mapState)(GroupTagContainer);