import {model, prop} from "../manager/decorators";

@model(null)
class ExternalLink {

    @prop()
    name;

    @prop()
    url;

}

export default ExternalLink;

