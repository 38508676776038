import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import { Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(1.5),
        backgroundColor: Colors.BLUELIGHT
    },
}));


const MoinDivider =  () => {
    const classes = useStyles();

    return (
        <Divider/>
    )
};

export default MoinDivider;
