import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../components/helper/ColorHelper";
import {MoinCard} from "../../components/base/MoinCard";
import MoinDivider from "../../components/base/MoinDivider";
import Typography from "@material-ui/core/Typography";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    '@keyframes blinker': {
        from: {opacity: 1},
        to: {opacity: 0}
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: "100%"
    },
    bottomRow: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: "space-between",
        margin: "0 16px"
    },
    textContainer:  {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: '1',
        marginLeft: "8px"
    },
    circle: {
        height: '48px',
        width: '48px',
        borderRadius: '24px',
        backgroundColor: Colors.BLACKLIGHTEST,
        marginRight: theme.spacing(2)
    },
    lineSmall: {
        height: '14px',
        width: '100%',
        backgroundColor: Colors.BLACKLIGHTEST,
    },
    line: {
        height: '20px',
        width: '100%',
        margin: "12px 0",
        backgroundColor: Colors.BLACKLIGHTEST,
    },
    lineShort: {
        height: '20px',
        width: '80px',
        backgroundColor: Colors.BLACKLIGHTEST,
    },
    media: {
        height: '240px',
        width: '100%',
        backgroundColor: Colors.BLACKLIGHTEST,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    wrapper: {
        width: "100%",
        animationName: '$blinker',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount:'infinite',
        marginBottom:"12px"
    },
    wrapperLines:{
        position: "relativ",
        margin: "0 16px"
    },
    card: {
        marginBottom: theme.spacing(2),
        width: '100%'
    }
}));

const ArticleSkeleton = () => {
    const classes = useStyles();
    return (
        <MoinCard hasRoundedBorders={true} className={classes.card}>
            <div className={classes.wrapper}>
                <div className={classes.row} style={{padding: "12px 16px"}}>
                    <div className={classes.circle}/>
                    <div className={classes.textContainer}>
                        <Typography variant={'h2'} style={{margin: "8px 32px 0 0"}}>
                            <div className={classes.line} style={{margin: 0}} />
                        </Typography>
                        <Typography variant={'caption'} style={{margin: "8px 32px 0 0"}}>
                            <div className={classes.lineSmall} style={{margin: 0}}/>
                        </Typography>
                    </div>
                </div>
                <MoinDivider/>
                <div className={classes.wrapperLines}>
                    <div className={classes.line}/>
                    <div className={classes.line}/>
                    <div className={classes.line}/>
                    <div className={classes.line}/>
                    <div className={classes.line}/>
                </div>

                <div className={classes.media}/>
                <Divider style={{margin: "8px 16px 10px 16px " }}/>
                <div className={classes.bottomRow} >
                    <div className={classes.lineShort}/>
                    <div className={classes.lineShort}/>
                </div>
            </div>
        </MoinCard>
    )
}

export default ArticleSkeleton;