import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ChatTeaser from "../../components/chat/ChatTeaser";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import MoinSearchField from "../../components/base/MoinSearchField";
import {useTranslation} from "react-i18next";
import ChatEmptyState from "./ChatEmptyState";
import ChatSearchEmptyState from "./ChatSearchEmptyState";

import MoinFab from "../../components/base/MoinFab";
import MoinSimpleMenuOption from "../../components/base/MoinSimpleMenuOption";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";

const useStyles = makeStyles(() => ({
    searchField: {
        margin: '16px 0 8px 0',
        width: '100%'
    }
}));

/**
 * @param {Chat[]} chats
 * @param {Function} deleteChat
 * @param {Function} readChat
 * @returns {JSX.Element}
 * @constructor
 */
const ChatOverview = ({chats, deleteChat, readChat}) => {
    const classes = useStyles();
    const [redirectToCreate, setRedirectToCreate] = useState(false);
    const [search, setSearch] = useState("");
    const [t] = useTranslation();


    if(redirectToCreate) {
        return <Redirect push to={ROUTES.messagesSingleCreate}/>
    }

    if(!chats || chats.length <= 0) {
        return <ChatEmptyState/>;
    }

    let renderChats = chats.filter( (chat) => !chat.hidden);

    if(!!search) {
        renderChats = renderChats.filter(chat => chat.title.toLowerCase().includes(search.toLowerCase()))
    }

    return (
        <>
            <MoinSearchField
                onChange={e => setSearch(e.target.value)}
                placeholder={t('chat.placeholder.searchChats')}
                className={classes.searchField}
                onClear={e => setSearch('')}
            />

            {
                (!renderChats || renderChats.length <= 0) &&
                    <ChatSearchEmptyState/>
            }
            {
                renderChats.map((chat) =>
                    <ChatTeaser chat={chat} key={chat.id} itemKey={chat.id} options={[
                        new MoinSimpleMenuOption( t('chat.markAsRead'), () => readChat(chat)),
                        new MoinSimpleMenuOption( t('chat.delete'), () => deleteChat(chat)),
                    ]}/>
                )
            }
            {
                !AUTHSTORAGE.isExternal() && <MoinFab onClick={() => setRedirectToCreate(true)}/>
            }
        </>
    )
};

export default ChatOverview;
