import {id, model, prop} from "../manager/decorators";
import {CURRENT_USER, SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import {getAutomaticMessageText, millisToMinutesAndSeconds} from "../helper/TextHelper";

@model('client/:client/chat/:chat/message')
class Message {

    @id()
    id;

    @prop()
    cName;

    @prop({sentinel: CURRENT_USER})
    cUid;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP, readOnly: true})
    cAt;

    @prop({type: "date", readOnly: true})
    dAt;

    @prop()
    text;

    @prop()
    type;

    @prop()
    tName;

    @prop()
    tUid;

    @prop()
    iv;

    @prop({
        type: "array",
        typeOptions: {
            entry: "string"
        }
    })
    images = [];

    @prop()
    videos;

    @prop()
    audio;

    @prop()
    docs;

    @prop()
    sentTo;

    chatId;

    getText(trans) {
        if(!!this.text) {
            return this.text;
        } else if(!!this.type) {
            return getAutomaticMessageText(trans, this);
        }
    }

    getTeaserText(trans) {
        if(!!this.text) {
            return this.text;
        } else if(!!this.type) {
            return getAutomaticMessageText(trans, this);
        } else if(!!this.images && this.images.length > 0) {
            return trans('messages.image');
        } else if(!!this.videos && this.videos.length > 0) {
            return trans('messages.video');
        } else if(!!this.audio ) {
            return trans('messages.audio', {length: millisToMinutesAndSeconds(this.audio.length)});
        } else {
            return trans('messages.document');
        }
    }
}

export default Message;
