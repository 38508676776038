import {getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import Chat from "../../utils/models/Chat";
import ChatOverview from "./ChatOverview";
import React from "react";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {updateModelInstance} from "../../utils/store/actions";
import * as CacheHelper from "../../utils/helper/CacheHelper";

const ChatOverviewContainer = ({chats}) => {
    const activeChats = chats.filter(chat => !chat.isTemp).sort((a, b) => (b.lastMessage?.cAt ?? b.cAt) - (a.lastMessage?.cAt ?? a.cAt));

    const onChatDelete = (chat) => {
        chat.hidden = true;
        chat.uMC = 0;
        updateModelInstance(Chat, chat);
        CacheHelper.setCachedChat(chat);
        ModelManager.update({modelInstance: chat, customData: {uMC: 0}, resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/chat`, id: chat.id});
    }

    const onChatRead = (chat) => {
        chat.uMC = 0;
        updateModelInstance(Chat, chat);
        CacheHelper.setCachedChat(chat);
        ModelManager.update({modelInstance: chat, customData: {uMC: 0}, resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/chat`, id: chat.id});
    }

    return (
        <ChatOverview chats={activeChats} deleteChat={onChatDelete} readChat={onChatRead}/>
    )
};

const mapState = (state) => ({
    chats: getModelInstances(state, Chat)
});

export default connect(mapState)(ChatOverviewContainer);
