import AUTHSTORAGE from "../auth/AuthStorage";
import {StorageFileType} from "../enums/StorageFileType";
import FirebaseStorageManager from "../manager/manager/FirebaseStorageManager";
import ApiClient from "../../api/ApiClient";
import StorageFile from "../models/StorageFile";
import ElasticClient from "../../api/ElasticClient";
import { v4 as uuid } from 'uuid';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';


if (process.env.NODE_ENV !== 'production') {
    //NOTE: don't use the "entry" file -- the script will fail and the web worker will not be used
    pdfjs.GlobalWorkerOptions.workerSrc = `node_modules/pdfjs-dist/legacy/build/pdf.worker.js`;
} else {
    //in prod, get it from the build directory
    pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;
}

export default class StorageClient {

    constructor() {
        this.firebaseStorageManager = new FirebaseStorageManager();
    }

    async searchFiles (search, size, from = 0) {
        let request = ElasticClient.createRequest('storage', '_search', searchQuery(search, size, from));

        return this._convertElasticResult( await request.send());
    }

    async loadSingleFile (id) {
        let request = ElasticClient.createRequest('storage', '_search', searchFile(id));

        return this._convertElasticResult( await request.send());
    }

    async loadPath (path, from = 0, sortBy = "name", direction="asc", size=50) {
        let searchPath = path;
        if (!path.startsWith(`client/${AUTHSTORAGE.getClientId()}`)) {
            searchPath = `client/${AUTHSTORAGE.getClientId()}/storage/${path}`
        }

        if ( searchPath[searchPath.length-1] === "/") {
            searchPath = searchPath.substring(0,searchPath.length-1);
        }
        let request = ElasticClient.createRequest('storage', '_search', pathQuery(searchPath,from, sortBy, direction,size ));

        return this._convertElasticResult( await request.send());
    }

    async addFile(file, name, originPath, type, onProgress, isCanceled, oldFile)  {
        let path = originPath;
        if (path.length === 1 && path[0] === "/") {
            path = "";
        } else if (path.length > 0 &&  path[path.length-1] !== "/") {
            path += "/";
        }

        const id = oldFile && oldFile.id ? oldFile.id : uuid();

        // Do not add a '/' between path and id here, the slash is already added to the path
        const uploadPath = `/client/${AUTHSTORAGE.getClientId()}/storage/${path}${id}`;

        let data = {
            name: name,
            path: uploadPath,
            type: type
        }
        if (type !== StorageFileType.FOLDER) {
            await this.firebaseStorageManager.uploadFile(file, uploadPath, (progress) => onProgress && onProgress(progress * 0.9), isCanceled,() =>  onProgress && onProgress(1000) );
            if (type === StorageFileType.IMAGE) {
                await this.firebaseStorageManager.uploadImage(file, uploadPath+'_preview', 256 , 0.1);
            } else if (type === StorageFileType.VIDEO) {
                let result = await createVideoThumbnail(file);
                if (result) {
                    await this.firebaseStorageManager.uploadImage( result, uploadPath+'_preview', 256 , 0.1);
                }
            } else if(type === StorageFileType.PDF) {
                let result = await createPdfPreview(file);

                if(result) {
                    await this.firebaseStorageManager.uploadImage(result, uploadPath+'_preview', 768 , 0.25);
                }
            }
        } else {
            await this.firebaseStorageManager.uploadFile(new File([""], "filename"), uploadPath + '/__meta', (progress) => {} );
        }
        let result = await this._makeStorageRequest(oldFile ? "update" : "add", data);
        if (result) {
            if (onProgress) {
                onProgress(100);
            }
            return Object.assign(new StorageFile(), {
                id: id,
                name: name,
                path: originPath,
                type: type,
                size: file?.size,
                cAt: new Date(),
                createdBy: AUTHSTORAGE.getUserId()
            })
        }
        return null;
    }


    async renameFile(file, name)  {
        let path = file.path;
        if (path.length === 1 && path[0] === "/") {
            path = "";
        } else if (path.length > 0 &&  path[path.length-1] !== "/") {
            path += "/";
        }

        // Do not add a '/' between path and id here, the slash is already added to the path
        const uploadPath = `/client/${AUTHSTORAGE.getClientId()}/storage/${path}${file.id}`;

        let data = {
            name: name,
            path: uploadPath,
            type: file.type,
        }

        let result = await this._makeStorageRequest("update", data);

        if (result) {
            file.name = name;
            return file;
        }
        return null;
    }


    async deleteFile(id, path, type) {
        if (path.length === 1 && path[0] === "/") {
            path = "";
        } else if (path.length > 0 &&  path[path.length-1] !== "/") {
            path += "/";
        }

        // Do not add a '/' between path and id here, the slash is already added to the path
        const uploadPath = `/client/${AUTHSTORAGE.getClientId()}/storage/${path}${id}`;

        let data = {
            path: uploadPath,
            type: type
        }

        let result = await this._makeStorageRequest("delete", data);

        if (result) {
            return true;
        }
        return false;
    }

    _makeStorageRequest= async (action, data) => {
        let body = Object.assign({},data);
        const apiRequest = await ApiClient.createRequest('/storage/' + action,body);
        return apiRequest.send();
    }

    _convertElasticResult = (result) => {
        let resultObjects = [];

        if (result && result['hits'] &&  result['hits']['hits']) {
            let hits = result['hits']['hits'];

            for (let i =0; i < hits.length; i++) {
                let file = Object.assign(
                    new StorageFile(),
                    hits[i]["_source"]
                )
                file.path = file.path.replace(`client/${AUTHSTORAGE.getClientId()}/storage`,"");
                if (   file.path.length > 1 && file.path[0] === "/") {
                    file.path = file.path.substring(1);
                }

                resultObjects.push(file);
            }
        }
        return resultObjects;
    }
}

const createVideoThumbnail =(videoFile)  => {
    return new Promise((resolve, reject) => {
        try {
            let video = document.createElement("video");
            video.src = URL.createObjectURL(videoFile);
            // if (videoFile.type === "video/quicktime") {
            //     video.type= "video/mp4";
            // }

            video.addEventListener('loadeddata', function() {
                try {
                    let canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;

                    let ctx = canvas.getContext('2d');
                    ctx.drawImage(video, 0, 0, canvas.width,  canvas.height);
                    let dataURI = canvas.toDataURL('image/jpeg');

                    let imageFile = dataURLtoFile(dataURI, `preview.jpg`);
                    URL.revokeObjectURL(video.src);
                    resolve(imageFile);
                } catch (ex) {
                    console.log("Could create thumbnail for video");
                    console.log(ex);
                    resolve(null);
                }
            }, false);
            video.addEventListener('error', function () {
                console.log("Could not decode Video");
                resolve(null);
            })
            video.load();
        } catch (ex) {
            resolve(null);
        }

    });

}

export const createPdfPreview = async (newDocument) => {
    // The try catch is just in case someone uploads a broken PDF, or a PDF that for some reason can't be loaded
    try {
        let loadedDocument = await (await pdfjs.getDocument(URL.createObjectURL(newDocument))).promise;

        if(loadedDocument.numPages > 0) {
            let firstPage = await loadedDocument.getPage(1);
            let viewport = firstPage.getViewport({ scale: 1.5 }); //eventuell hier 0.8
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            console.log("viewport.width");
            console.log(viewport.width);
            canvas.width = viewport.width; // * 0.8 auch hier
            canvas.height = viewport.height;

            let renderContext = {
                canvasContext: ctx,
                viewport: viewport
            };

            await firstPage.render(renderContext).promise;
            let dataURI = canvas.toDataURL('image/jpeg');
            let imageFile = dataURLtoFile(dataURI, `preview.jpg`);
            URL.revokeObjectURL(newDocument);

            return imageFile;
        }
    } catch (ex) {
        console.log(ex);
    }
}

export const createFirstPdfPreview = async (newDocument, originPath) => {
    try {
        let loadedDocument = await (await pdfjs.getDocument(URL.createObjectURL(newDocument))).promise;

        if(loadedDocument.numPages > 0) {
            let firstPage = await loadedDocument.getPage(1);
            let viewport = firstPage.getViewport({ scale: 0.6 });
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            canvas.width = viewport.width;
            canvas.height = viewport.height;

            let renderContext = {
                canvasContext: ctx,
                viewport: viewport
            };

            await firstPage.render(renderContext).promise;
            let dataURI = canvas.toDataURL('image/jpeg');
            let imageFile = dataURLtoFile(dataURI, `preview.jpg`);
            URL.revokeObjectURL(newDocument);

            const newFirebaseStorageManager = new FirebaseStorageManager();
            await newFirebaseStorageManager.uploadImage(imageFile, originPath+'_preview', 512 , 0.2);

            return dataURI;
        }
    } catch (ex) {
        console.log(ex);
    }
}

const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}



const pathQuery = (path, from, sortBy, direction, size = 50) => {
    let queryBody =  {
        "size": size,
        "from":from,
        "query":{
            "function_score":{
                "query":{
                    "match":{
                        "path": path
                    }
                },
                "boost":1,
                "functions":[
                    {
                        "filter":{
                            "match":{
                                "type":"FOLDER"
                            }
                        },
                        "weight":100
                    }
                ],
                "score_mode":"max",
                "boost_mode":"replace"
            }
        },
        "sort":[
            {
                "_score":{
                    "order":"desc"
                }
            },
            {
                [sortBy  === "name" ? 'name_lowercase' : sortBy]:{
                    "order":direction
                }
            }
        ]
    };

    if (sortBy === "changedAt") {
        queryBody['sort'] = [
            {
                "_score":{
                    "order":"desc"
                }
            },
            {
                "_script": {
                    "type": "number",
                    "script": {
                        "lang": "painless",
                        "source": "if(doc['uAt'].size() != 0) { return doc['uAt'].value; } return doc['cAt'].value;"
                    },
                    "order":direction
                }
            }
        ];
    }

    if (sortBy === "size") {
        queryBody["query"]['function_score']["functions"] = [
            {
                "filter":{
                    "terms":{
                        "type":[
                            "IMAGE",
                            "VIDEO",
                            "WORD",
                            "EXCEL",
                            "PDF",
                            "POWERPOINT",
                            "OTHER"
                        ]
                    }
                },
                "weight":100
            }
        ];
    }

    return queryBody;
}


const searchQuery = (search, size = 5, from =0) => {
    let queryBody =  {
        "size":size,
        "from":from,
        "query":{
            "match" : {
                "search" : search
            }
        },

    };
    return queryBody;
}


const searchFile = (id) => {
    let queryBody =  {
        "query":{
            "match" : {
                "id" : id
            }
        },

    };
    return queryBody;
}

const STORAGECLIENT = new StorageClient();
export {STORAGECLIENT, createVideoThumbnail};
