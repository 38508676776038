import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {getModelInstances} from "../../utils/store/selectors";
import Distributor from "../../utils/models/Distributor";
import User from "../../utils/models/User";
import Event from "../../utils/models/Event";
import Department from "../../utils/models/Department";
import {
    sortEventsWithMatchQuality,
    sortGroupsWithMatchQuality,
    sortUsersWithMatchQuality
} from "../../utils/helper/SortHelper";
import SearchPage from "./SearchPage";
import ModelManager from "../../utils/manager/ModelManager";
import Article from "../../utils/models/Article";
import ApiClient from "../../api/ApiClient";
import AUTHDISTRIBUTORMEMBERSHIP from "../../utils/auth/AuthDistributorMembership";
import parameters from "../../config/parameters.json";

/**
 *
 * @param {Array.<Distributor>} distributors
 * @returns {JSX.Element}
 * @constructor
 */
const SearchContainer = ({query, users, groups, events }) => {
    const [searchArticleResult, setSearchArticleResult] =  useState([]);
    const [articleTotal, setArticleTotal] =  useState(0);
    const [forceRebuild, setForceRebuild] =  useState(false);
    const loading = useRef(true);

    useEffect(() => {
        setSearchArticleResult([]);
        makeLoadingRequest(query).then( (result) =>  {
            loadPostsForElasticResult(result);
        });
    }, [query]);

    const loadMoreArticles = async () => {

        if (!loading.current) {
            loading.current = true;
            setForceRebuild(!forceRebuild);

            makeLoadingRequest(query).then( (result) =>  {
                loadPostsForElasticResult(result);
            });
        }
    }

    const loadPostsForElasticResult = (requestResult) => {
        if (!!requestResult) {
            let idList =  [];

            if (!!requestResult.data) {
                requestResult.data.forEach( (data) => {
                    ModelManager.get({model: Article,  id: data.id});
                    idList.push(data.id);
                })
            }

            if (!!requestResult.total) {
                setArticleTotal(requestResult.total)
            }

            let result;
            if (!!searchArticleResult) {
                result= [ ...searchArticleResult, ...idList];
            } else {
                result =  idList
            }
            setSearchArticleResult(result);
        }
        loading.current = false;
        setForceRebuild(!forceRebuild);
    }

    const makeLoadingRequest= async (search) => {
        let memberDistributor = Array.from(AUTHDISTRIBUTORMEMBERSHIP.getAllMemberships());
        memberDistributor.push(parameters.globalDistributorId);

        let body = {};
        body['text'] = search;
        body['dis'] = JSON.stringify(memberDistributor);
        body['size'] = 5;
        body['from'] = 0;

        if (!!searchArticleResult) {
            body['from'] = searchArticleResult.length;
        }
        const apiRequest = await ApiClient.createRequest('/search/article',body);
        return apiRequest.send();
    }


    if (!!query) {
        let searchString = query.toLowerCase();

        users = users.filter ( (user) => user.fullName.toLowerCase().includes(searchString));
        if (users && users.length > 0) {
            users.sort((a,b) => sortUsersWithMatchQuality(a,b,searchString))
        }

        groups = groups.filter ( (group) => group.name.toLowerCase().includes(searchString));
        if (groups && groups.length > 0) {
            groups.sort((a,b) => sortGroupsWithMatchQuality(a,b,searchString))
        }

        events = events.filter ( (event) => event.title.toLowerCase().includes(searchString));
        if (events && events.length > 0) {
            events.sort((a,b) => sortEventsWithMatchQuality(a,b,searchString))
        }
    }

    return(
        <SearchPage
            users={users}
            groups={groups}
            events={events}
            articleIds={searchArticleResult}
            articleTotal={articleTotal}
            loadingArticles={loading.current}
            loadMoreArticles={loadMoreArticles}
            forceRebuild={forceRebuild}
        />
    );
};


const mapState = (state) => ({
    users: getModelInstances(state,User),
    events: getModelInstances(state,Event),
    groups: getModelInstances(state,Distributor),
    departments: getModelInstances(state, Department)
});


export default connect(mapState)(SearchContainer);