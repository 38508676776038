import React, {useEffect, useRef} from 'react';
import {connect} from "react-redux";
import Event from "../../utils/models/Event";
import EventDetail from "./EventShow";
import User from "../../utils/models/User";
import ModelManager from "../../utils/manager/ModelManager";
import EventMembership from "../../utils/models/EventMembership";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {updateModelInstanceDispatch} from "../../utils/store/actions";
import EmptyState from "../../components/base/EmptyState";
import {FireDelete, FireIncrement, FireTimestamp} from "../../api/FirebaseService";
import Distributor from "../../utils/models/Distributor";
import parameters from "../../config/parameters.json";

/**
 * Event detail view container that loads the event and it's participants
 *
 * @param {String} eventId
 * @param {EventMembership} ownParticipation
 * @param {Array<EventMembership>} memberships
 * @param {Array<Distributor>} distributors
 * @param {Event} event
 * @param {Array<User>} users
 * @param {Function} updateModelInstance
 * @returns {JSX.Element}
 * @constructor
 */
const EventShowContainer = ({eventId, distributors, ownParticipation, event, memberships, users,updateModelInstanceDispatch}) => {

    const eventMemberships = (memberships || []).filter( (participation) => !!participation && !!participation.events && participation.events.hasOwnProperty(eventId) ).map(participation => participation.id);
    const members = users.filter(user => eventMemberships.includes(user.uid));
    const contactPartners = users.filter(user => event.pUids?.includes(user.uid));
    const _removedParticipationTimestamp = useRef();
    const _blockedTime = 1200;

    useEffect(() => {
        if (!!event ) {
            ModelManager.list({model: EventMembership, orderBy:  event.id });
        }
    }, [event]);

    const isAuthParticipating = !!event && !!ownParticipation && !!ownParticipation.events && ownParticipation.events.hasOwnProperty(event.id);
    const eventDistributor = distributors.find(dis => !!event && dis.id === (event.distributorId ?? parameters.globalDistributorId));

    const onParticipationChange = () => {
        let data = {
            'uAt': FireTimestamp()
        };

        if (!ownParticipation) {
            ownParticipation    = new EventMembership();
            ownParticipation.id = AUTHSTORAGE.getUserId();
            ownParticipation.events = {};
        }

        if (!isAuthParticipating && _removedParticipationTimestamp.current && (_removedParticipationTimestamp.current + _blockedTime) > Date.now() ) {
            return;
        }

        if (isAuthParticipating) {
            _removedParticipationTimestamp.current = Date.now();
            event.count--;

            delete ownParticipation.events[eventId]
            ModelManager.update({modelInstance: event, customData: {
                "count": FireIncrement(-1)},
                'uAt': FireTimestamp()
            });

            data[event.id] = FireDelete();
            ModelManager.update({modelInstance: ownParticipation, customData: data, useSet: true});
        } else {
            event.count++;
            ownParticipation.events[eventId] = true;
            ModelManager.update({modelInstance: event, customData: {
                "count": FireIncrement(1)},
                'uAt': FireTimestamp()
            });

            data[event.id] = true;
            ModelManager.update({modelInstance: ownParticipation, customData: data, useSet: true});
        }
        updateModelInstanceDispatch(EventMembership,ownParticipation);
    }

    if (!event) {
        return <EmptyState/>
    }

    return(
            <EventDetail event={event} distributor={eventDistributor} members={members} contactPartners={contactPartners} key={event.id} onParticipationChange={onParticipationChange} isAuthParticipating={isAuthParticipating} />
    );
};

const mapState = (state, ownProps) => {

    return {
        ownParticipation: getModelInstance(state, EventMembership, AUTHSTORAGE.getUserId()) ,
        event: getModelInstance(state, Event, ownProps.eventId),
        memberships: getModelInstances(state, EventMembership),
        users: getModelInstances(state, User),
        distributors: getModelInstances(state, Distributor)
    }
};

const mapAction = {
    updateModelInstanceDispatch,
};


export default connect(mapState,mapAction)(EventShowContainer);