import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {isLink, isMail, isPhone} from "../../utils/helper/TextHelper";
import {Colors} from "../helper/ColorHelper";
import Mention from "../../utils/models/Mention";
import RenderedMention from "../mention/RenderedMention";

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: Colors.BRANDSECONDARY
    }
}));

const MoinLinkifiedText = ({text = ""}) => {
    const classes = useStyles();

    const wordRegex = RegExp(/(?=\s(?!\d*\s))/); // |(?<=]}!!)/); <-- this does not work on safari (thank you apple)

    let mentionSplit = text.split(']}!!');

    for(let i = 0; i < mentionSplit.length-1; i++) {
        mentionSplit[i] += ']}!!';
    }

    let words = mentionSplit.map(e => e.split(wordRegex)).flat();

    words = words.map( (word, index) => {
        let trimmedWord = word.trim();

        if (isLink(trimmedWord)) {
            let prefix = "http://";
            trimmedWord = trimmedWord.replace('http://', '');
            if (trimmedWord.includes('https://')) {
                trimmedWord = trimmedWord.replace('https://', '');
                prefix = "https://";
            }
            return <a key={index} href={prefix + trimmedWord} target={'blank'} className={classes.link}>{word}</a>
        } else if (isPhone(trimmedWord)) {
            return <a key={index} href={'tel:' + trimmedWord} className={classes.link}>{word}</a>
        } else if (isMail(trimmedWord)) {
            return <a key={index} href={'mailto:' + trimmedWord} className={classes.link}>{word}</a>
        } else if(Mention.isMentionString(trimmedWord)) {
            let mention = Mention.fromMentionString(trimmedWord);
            return <RenderedMention key={index} mention={mention}/>
        }
        return <span key={index}>{word}</span>;
    })

    return (
        <>
            {words}
        </>
    );
}

export default MoinLinkifiedText;