import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import {Typography} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    option: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: '16px',
            height: '24px',
            fill: Colors.BLACK
        }

    },
    invisible: {
        visibility: 'hidden'
    },
    hidden: {
        display: 'none'
    },
    container: {
        cursor: 'pointer',
        display: 'flex'
    },
    listItem: {
        margin: '0',
        padding: '8px 12px'
    },
    menuSpacing: {
        marginLeft: '-40px'
    },
}));

const defaultPaperProps = {
    color: Colors.BLACK,
    boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '16px'
};

/**
 * Moin! themed simple menu. Automatically aligns the options correctly if there are no leading icons, or not
 * all of them.
 *
 * @param {String} id Needed for screen reader accessibility
 * @param {Array<MoinSimpleMenuOption>}  options
 * @param {boolean} center
 * @param {boolean} noWidth
 * @param {String} listItemClasses
 * @param {String} typography
 * @param {Object} paperStyle
 * @param rest
 * @param {Array<JSX.Element>} [children]
 * @returns {JSX.Element}
 * @constructor
 */
const MoinSimpleMenuContext = ({id, options = [] ,center=false, noWidth = false,paperStyle = {}, listItemClasses,typography, handleClose, ...rest}) => {
    const classes = useStyles();

    let paperPros = Object.assign(defaultPaperProps, paperStyle);

    return (
        <div className={ noWidth ?  classes.menuSpacing : {}}>
            <Menu getContentAnchorEl={null}
                  anchorOrigin={ center ? { vertical: "bottom", horizontal: "center" } : undefined}
                  transformOrigin={center ? { vertical: "top", horizontal: "center" }: undefined}
                  keepMounted
                  PaperProps={{
                      style: paperPros
                  }}
                  {...rest}
            >
                {options.map((option) => (
                    <MenuItem
                        key={id + option.label}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            option.action();
                            handleClose(event);
                            return false;
                        }
                        }
                        className={clsx(classes.listItem, listItemClasses ?? {})}
                    >
                        <div className={classes.option}>
                            {option.icon && option.icon }
                            <Typography variant={typography ?? "body2"}>{option.label}</Typography>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default MoinSimpleMenuContext;