import React, {useState} from 'react';
import {connect} from "react-redux";
import TaskActionContainer from "./TasksActionContainer";
import TaskSearchContainer from "./TasksSearchContainer";
import {getModelInstances} from "../../utils/store/selectors";
import Task from "../../utils/models/Task";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import Tasks from "./Tasks";

const TasksSearchResultContainer = ({query, allTasks}) => {
    let relevantTasks = allTasks.filter( (task) => task.relevant && task.relevant.includes(AUTHSTORAGE.getUserId()));

    return <TaskSearchContainer query={query}>
        {
            ({onSearch, tasks}) =>  <TaskActionContainer>
                {
                    ({onCreate, onDelete, onUndoDelete, onTitleChange,onDescriptionChange,onRepetitionChange,onComplete, onAssign, removeAssignee, onAddNotification, onRemoveNotification, setDueDate, onAddImages, onRemoveImage,onAddDocuments,onRemoveDocument}) =>
                        <Tasks
                            onCreate={onCreate}
                            onDelete={onDelete}
                            onUndoDelete={onUndoDelete}
                            onTitleChange={onTitleChange}
                            onDescriptionChange={onDescriptionChange}
                            onRepetitionChange={onRepetitionChange}
                            onComplete={onComplete}
                            onAssign={onAssign}
                            onAddImages={onAddImages}
                            onRemoveImage={onRemoveImage}
                            onAddDocuments={onAddDocuments}
                            onRemoveDocument={onRemoveDocument}
                            onRemoveNotification={onRemoveNotification}
                            onAddNotification={onAddNotification}
                            setDueDate={setDueDate}
                            removeAssignee={removeAssignee}
                            tasks={relevantTasks}
                            onSearch={onSearch}
                            searchedTasks={tasks}
                        />
                }
            </TaskActionContainer>
        }

    </TaskSearchContainer>
};

const mapState = (state) => ({
    allTasks: getModelInstances(state,Task)
});

export default connect(mapState)(TasksSearchResultContainer);
