import React, {useEffect, useRef} from 'react';
import {getModelInstance, getModelInstances} from "../utils/store/selectors";
import {connect} from "react-redux";
import Messaging from "../utils/models/Messaging";
import NotificationOverview from "./NotificationOverview";
import {addModelInstancesDispatch, updateModelInstanceDispatch} from "../utils/store/actions";
import ModelManager from "../utils/manager/ModelManager";
import AUTHSTORAGE from "../utils/auth/AuthStorage";
import {sortByDate} from "../utils/helper/SortHelper";
import User from "../utils/models/User";
import {FireIncrement, FireTimestamp} from "../api/FirebaseService";
import {MessagingType} from "../utils/enums/MessagingType";
import MessagingCount from "../utils/models/MessagingCount";

/**
 *
 * @param {Array.<Distributor>} distributors
 * @param {Object} users
 * @param {MessagingCount} unreadMessagesCount
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationOverviewContainer = ({messagings = [], users, updateModelInstanceDispatch, addModelInstancesDispatch, unreadMessagesCount}) => {
    const loadAmount = 25;
    let loading = useRef(false);
    let markedAll = useRef(false);
    let markedNotifications = useRef([]);
    let lastNotificationOpen = useRef(AUTHSTORAGE.getLastNotificationOpen());

    messagings.sort((a,b) => sortByDate(b.cAt, a.cAt));

    useEffect(() => {
        loadMessagings();
        //changeNotificationCount(); //setting notification count to 0 so the notification icon doesn't have a messaging count number
        return () => {}
    }, []);

    const onMessagingRead = (messaging) => {
        if (messaging.read === false && !markedNotifications.current.includes(messaging.id)) {
            markedNotifications.current.push(messaging.id);
            markMessagingAsRead(messaging);

            messaging.read = true;
            updateModelInstanceDispatch(Messaging,messaging);
        }
    }

    const markMessagingAsRead=(messaging, reduceCount = true) => {
        ModelManager.update({
            resource: "/client/" + AUTHSTORAGE.getClientId() + "/user/" +  AUTHSTORAGE.getUserId()+ '/notification/',
            id:  messaging.id,
            customData: {"read": true}
        });

        if (reduceCount) {
            ModelManager.update({
                resource: "/client/" +AUTHSTORAGE.getClientId() + "/user/" + AUTHSTORAGE.getUserId() + '/private/',
                id: "notcount",
                customData: {"count": FireIncrement(-1)}
            });
        }
    }

    const onAllMessagingsRead=() => {
        if (!markedAll.current) {
            markedAll.current = true;

            messagings.forEach((messaging) => {
                if (messaging.read === false) {
                    if (!markedNotifications.current.includes(messaging.id)) {
                        markedNotifications.current.push(messaging.id);
                    }
                    messaging.read = true;
                    updateModelInstanceDispatch(Messaging,messaging);
                }
            });

            ModelManager.list( {model: Messaging, filter: [['read','==',false]],cache: false}).then(async function (result) {
                result.forEach((messaging) => markMessagingAsRead(messaging, false));
                await ModelManager.update({
                    resource: "/client/" +AUTHSTORAGE.getClientId() + "/user/" + AUTHSTORAGE.getUserId() + '/private/',
                    id: "notcount",
                    customData: {"count": 0,  "lastCalculated": FireTimestamp() }
                });

                markedAll.current = false;
            });
        }
    }

    const loadMessagings = (lastCreatedAt) => {
        if(!loading.current) {
            loading.current = true;
            ModelManager.list({model: Messaging, startAfter: lastCreatedAt, orderBy: 'cAt', orderDirection: "desc", limit: loadAmount, cache: false}).then(async function (result) {
                result = result.filter(notification => Object.values(MessagingType).includes(notification.type));
                addModelInstancesDispatch(Messaging, result);
                loading.current = false;
            });
        }
    }

    return(
        <NotificationOverview messagings={messagings} unreadMessagesCount={unreadMessagesCount} loadMessagings={loadMessagings} onMessagingRead={onMessagingRead} onAllMessagingsRead={onAllMessagingsRead} lastNotificationOpen={lastNotificationOpen.current} users={users}/>
    );
};

const mapAction = {
    updateModelInstanceDispatch,
    addModelInstancesDispatch
};

const mapState = (state) => {
    let users = getModelInstances(state, User);
    let messagings = getModelInstances(state, Messaging);

    messagings.forEach(messaging => {
        let user = users.find(user => user.id === messaging.cUid);
        if(!!user) {
            messaging.cName = user.fullName;
        }
    });

    return {
        unreadMessagesCount: getModelInstance(state, MessagingCount, "notcount"),
        messagings: messagings,
        users: users
    }
};

export default connect(mapState, mapAction)(NotificationOverviewContainer);