import {DateTime, Duration} from "luxon";

/**
 * @param {Array<Event>} events
 * @return Array<Event>
 */
export function filterUpcomingEvents(events) {
    let yesterday = DateTime.local().minus(Duration.fromObject({days: 1})).endOf('day').toMillis();
    let now = DateTime.local().toMillis();

    return events.filter(event => !event.deleted
        && event.start
        && (
            ( !event.end && event.start.getTime() > yesterday)
            ||
            ( !!event.end && event.end.getTime() > now)
        ));
}

export function filterPastEvents(events) {
    let yesterday = DateTime.local().minus(Duration.fromObject({days: 1})).endOf('day').toMillis();
    let now = DateTime.local().toMillis();

    return events.filter(event => !event.deleted
        && event.start
        && (
            ( !event.end && event.start.getTime() < yesterday)
            ||
            ( !!event.end && event.end.getTime() < now)
        ));
}