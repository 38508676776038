import React, {Fragment} from 'react';
import {connect} from "react-redux";
import Department from "../../utils/models/Department";
import FirestoreImage from "../base/FirestoreImage";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {getModelInstance} from "../../utils/store/selectors";
import MoinCheckBox from "../base/MoinCheckBox";
import MoinListItem from "../base/MoinListItem";
import {Colors} from "../helper/ColorHelper";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {ClientType} from "../../utils/enums/ClientType";
import {useTranslation} from "react-i18next";

const formatSecondaryText = (t, department, user) => {
  if (!!user.external) {
    return t('user.externalMember');
  }

  if (AUTHSTORAGE.getClient().type === ClientType.ASSOCIATION) {
    if (!!department && !!user.addDep) return `${department.name} • ${user.addDep}`;
    if (!!department) return department.name;
    if (!!user.addDep) return user.addDep;
  } else {
    if (!!department && !!user.position) return `${department.name} • ${user.position}`;
    if (!!department) return department.name;
    if (!!user.position) return user.position;
  }
}

/**
 *
 * @param {Department} department
 * @param {User} user
 * @param {boolean} avatar
 * @param {boolean} divider
 * @param {boolean} selectable
 * @param {boolean} selected
 * @param {boolean} selectDisabled
 * @param {Function} onSelect
 * @param {boolean} smallHeadline
 * @param {boolean} showChevron
 * @param {boolean} showSubtitle
 * @returns {JSX.Element}
 * @constructor
 */
const MoinUserListTile = ({department, user, avatar = true, divider = true, selectable = false, selected = false, selectDisabled = false,  onSelect, smallHeadline = false, showChevron = false, showSubtitle = true}) =>  {
  const [t] = useTranslation();


  if (!user) {
    return (<Fragment/>);
  }

  let avatarComponent;

  if (avatar) {
    avatarComponent = <FirestoreImage
        onClick={() => {!selectDisabled &&  onSelect(user)} }
        key={'avatar' + user.id}
        alt={user.fullName}
        src={user.avatar}
        radius={smallHeadline ? 12 : 24}
        backgroundColor={Colors.identifierAsColor(user.id)}
        isAvatar={true}
    >
      {`${user.firstName[0]}${user.lastName[0]}`}
    </FirestoreImage>;
  }

    const trailing = [<ListItemSecondaryAction key={'list-item-user-action-' + user?.id}>
        <MoinCheckBox
            edge="end"
            disabled={selectDisabled}
            onChange={() => !selectDisabled && onSelect(user)}
            checked={selected}
        />
    </ListItemSecondaryAction>];


  return <MoinListItem
      key={user?.id}
      onClick={ () => !selectDisabled &&  onSelect(user)}
      image={avatarComponent}
      title={user.fullName}
      // we can not use the splash Effect here since it adds a bunch of events that block the click handler
      withSplashEffect={false}
      withDivider={divider}
      subtitle={ showSubtitle && formatSecondaryText(t,department, user)}
      trailing={ selectable && trailing }
      smallHeadline={ smallHeadline}
      showChevron={showChevron}
  />
};

const mapState =  (state,ownProps) => ({
    department: ownProps.user ? getModelInstance(state, Department, ownProps.user.department) : undefined
});


export default connect(mapState)(MoinUserListTile)