import React, {useState} from "react";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import UserListItem from "../../components/user/MoinUserListItem";
import * as ROUTES from "../../Routes";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Tab, Tabs} from "@material-ui/core";
import {getFilledIconForReaction} from "../../components/helper/IconHelper";
import List from "@material-ui/core/List";
import SwipeableViews from "react-swipeable-views";
import {Colors} from "../../components/helper/ColorHelper";
import ThumbsUpFilledIcon from "../../assets/icons/thumb-up-filled.svg";
import {ReactionType} from "../../utils/enums/ReactionType";

const useStyles = makeStyles(() => ({
    cardContentTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center"
    },
    emptyStateText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center",
        marginTop: '24px'
    },
    tab: {
        '& > .MuiTab-wrapper': {
            flexDirection: 'row'
        }
    }
}));

/**
 * @param allReactions
 * @param {User[]} users
 * @param {boolean} loading
 * @return {JSX.Element}
 * @constructor
 */
const ReactionList = ({allReactions, users, loading}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState(0);

    const getUserList = (userIds) => {
        return userIds.map((userId, index, collection) => {
            let user = users.find((u) => u.id === userId);

            return (
                <UserListItem
                    selectable={false}
                    onSelect={user => history.push(ROUTES.userShow.replace(':id', user.id))}
                    key={index}
                    divider={index !== collection.length - 1}
                    user={user}
                />
            )
        })
    };

    if((!users || !allReactions) && loading) {
        return <div/>;
    }

    if(!users || !allReactions) {
        return (
            <MoinCard>
                <MoinCardHeader>
                    <div className={classes.cardContentTitle}>
                        <Typography variant="h3">{t('article.reactions.reactions')}</Typography>
                    </div>
                </MoinCardHeader>
                <MoinCardContent>
                    <div className={classes.emptyStateText}>
                        <Typography variant="body1">{t('article.reactions.noReactions')}</Typography>
                    </div>
                </MoinCardContent>
            </MoinCard>
        )
    }

    let reactionUsers = {};

    Object.keys(allReactions).forEach(userId => {
        let reaction = allReactions[userId];

        if(!reaction) {
            reaction = ReactionType.LIKE;
        }

        if(!!reactionUsers[reaction]) {
            reactionUsers[reaction].push(userId);
        } else {
            reactionUsers[reaction] = [userId];
        }
    });

    let tabs = [];
    let tabViews = [];

    Object.keys(reactionUsers).forEach((reaction, index) => {
        let ReactionIcon = getFilledIconForReaction(reaction) ?? ThumbsUpFilledIcon;
        let reactionColor = Colors.colorForReactionType(reaction) ?? Colors.REACTIONBLUE;

        let userIds = reactionUsers[reaction];
        tabs.push(<Tab key={"reactiontab_"+index} className={classes.tab} label={userIds.length} icon={<ReactionIcon style={{marginRight: '4px', fill: reactionColor}}/>}/>);

        tabViews.push(
            <List key={"reactiontab_view_"+index} hidden={selectedTab !== index+1}>
                { getUserList(userIds) }
            </List>
        )
    });

    tabs.splice(0, 0, <Tab key="allreactionstab" label={t('article.reactions.allReactionsCount', {count: Object.keys(allReactions).length})}/>);
    tabViews.splice(0, 0, <List key="reactionlist" hidden={selectedTab !== 0}> { getUserList(Object.keys(allReactions)) } </List>)

    return (
        <MoinCard>
            <MoinCardHeader>
                <div className={classes.cardContentTitle}>
                    <Typography variant="h3">{t('article.reactions.reactions')}</Typography>
                </div>
            </MoinCardHeader>
            <MoinCardContent>
                <Tabs
                    key="reactiontabs"
                    variant="scrollable"
                    onChange={(e, v) => setSelectedTab(v)}
                    value={selectedTab}
                >
                    { tabs }
                </Tabs>
                <SwipeableViews key="reactionlist_swiper" index={selectedTab}>
                    { tabViews }
                </SwipeableViews>
            </MoinCardContent>
        </MoinCard>
    )
};

export default ReactionList;
