import {id, model, prop} from "../manager/decorators";
import Client from "./Client";

@model('client/:client/department')
class Department {

    @id()
    id;

    @prop()
    name;

    @prop({type: "reference", typeOptions: {target: Client}})
    client;
}

export default Department;
