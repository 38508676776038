import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import ListItem from "@material-ui/core/ListItem";
import MoinDialog from "../base/MoinDialog";
import MoinTextField from "../base/MoinTextField";
import {FireAuth, firebase} from "../../api/FirebaseService";
import MuiAlert from "@material-ui/lab/Alert";
import SUCCESSNOTIFICATIONMANAGER from "../success/SuccessNotificationManager";
import SuccessMessage from "../../utils/models/SuccessMessage";
import {SuccessType} from "../../utils/enums/SuccessType";
import MASTERTHEME from "../../pages/MasterTheme";
import {encryptLocalStorageData, padDecryptionPassword} from "../../utils/helper/EncryptionHelper";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import * as forge from "node-forge";
import ModelManager from "../../utils/manager/ModelManager";

const useStyles = makeStyles((theme) => ({
    changePasswordDialog: {
        '& .MuiDialogTitle-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 4px 10px rgb(0 0 0 / 5%)',
            zIndex: '1',
            '& .MuiTypography-root h2': {
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: '26px'
            }
        },
        '& .MuiDivider-root': {
            display: 'none'
        },
        '& .MuiDialogContent-root': {
            background: Colors.BLUEBACKGROUND,
            '& h2': {
                fontWeight: '700',
                fontSize: '13px',
                lineHeight: '18px'
            }
        },
        '& .MuiDialogContentText-root': {
            margin: '0'
        },
        '& .MuiDialogActions-root': {
            background: Colors.BLUEBACKGROUND,
            justifyContent: 'center',
            '& .MuiButtonBase-root': {
                padding: '8px 24px'
            }
        }
    },
    changePasswordDescription: {
        padding: '8px 0 24px'
    }
}));

const ChangePassword = () => {
    const classes = MASTERTHEME.getStyles();
    const addClasses = useStyles();
    const [t, i18n] = useTranslation();

    const [errors, setErrors] = useState({});
    const [error, setError] = useState(null);
    const [forceRebuild, setForceRebuild] = useState(true);

    const [showChangePassword, setShowChangePassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [repeatNewPassword, setRepeatNewPassword] = useState();

    const validate = () => {
        let hasError = false;

        for (let error in errors) {
            delete errors[error];
        }

        if (!currentPassword) {
            errors['currentPassword'] = t('form.errors.cantBeEmpty', {field: t('changePassword.currentPassword')});
            hasError = true;
        } else if (currentPassword && currentPassword === newPassword) {
            errors['currentPassword'] = t('changePassword.passwordsIdentical');
            hasError = true;
        }

        if (!newPassword) {
            errors['newPassword'] = t('form.errors.cantBeEmpty', {field: t('changePassword.newPassword')});
            hasError = true;
        } else if (newPassword.length < 6) {
            errors['newPassword'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('changePassword.newPassword'), chars: 6});
            hasError = true;
        } else if (newPassword.length > 200) {
            errors['newPassword'] = t('form.errors.mustBeLessThanXCharacters', {field: t('changePassword.newPassword'), chars: 200});
            hasError = true;
        } else if (repeatNewPassword && repeatNewPassword !== newPassword) {
            errors['newPassword'] = t('changePassword.mustMatch');
            hasError = true;
        }

        if (!repeatNewPassword) {
            errors['repeatNewPassword'] = t('form.errors.cantBeEmpty', {field: t('changePassword.repeatPassword')});
            hasError = true;
        } else if (repeatNewPassword.length < 6) {
            errors['repeatNewPassword'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('changePassword.repeatPassword'), chars: 6});
            hasError = true;
        } else if (repeatNewPassword.length > 200) {
            errors['repeatNewPassword'] = t('form.errors.mustBeLessThanXCharacters', {field: t('changePassword.repeatPassword'), chars: 200});
            hasError = true;
        } else if (repeatNewPassword !== newPassword) {
            errors['repeatNewPassword'] = t('changePassword.mustMatch');
            hasError = true;
        }

        setErrors(errors);
        setForceRebuild(!forceRebuild);

        return !hasError;
    };

    const onCloseDialog = () => {
        setShowChangePassword(false);
        setErrors({});
        setCurrentPassword();
        setNewPassword();
        setRepeatNewPassword();
        setError("");
    }

    const reauthenticate = async () => {
        let reauthenticationSuccess = false;

        let credential = firebase.auth.EmailAuthProvider.credential(
            FireAuth.currentUser.email,
            currentPassword
        );

        await FireAuth.currentUser.reauthenticateWithCredential(credential)
            .then((_) => {
                reauthenticationSuccess = true;
            })
            .catch(({code}) => {
                let message = t('errors.login.unknown');

                switch (code) {
                    case "auth/wrong-password":
                        message = t('changePassword.wrongPassword');
                        break;
                    default:
                        break;
                }

                setError(message);
                reauthenticationSuccess = false;
            })

        return reauthenticationSuccess;
    }

    const onUpdatePassword = async () => {
        if(validate()) {
            if (await reauthenticate()) {
                setError("");

                FireAuth.currentUser.updatePassword(newPassword)
                    .then((_) => {
                        updatePrivateKey(newPassword);
                        onCloseDialog();
                        SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification', new SuccessMessage(SuccessType.PASSWORDCHANGED, true));
                    })
                    .catch(({code}) => {
                        let message = t('errors.login.unknown');

                        switch (code) {
                            case "auth/requires-recent-login":
                                message = t('errors.login.usernameOrPassword');
                                break;
                            default:
                                break;
                        }

                        setError(message);
                    })
            }
        }
    }

    const updatePrivateKey = async (newPassword) => {
        let encryptionKey = AUTHSTORAGE.getBrowserEncryptionKey();

        let newEncryptionPassword = padDecryptionPassword(newPassword, AUTHSTORAGE.getUserId());
        let encryptedPassword = encryptLocalStorageData(encryptionKey.browserEncryptionKey, encryptionKey.browserEncryptionIV, newEncryptionPassword);
        localStorage.setItem("pwe_" + AUTHSTORAGE.getUserId(), encryptedPassword);

        let iv = forge.random.getBytesSync(16);
        let bytePassword = forge.util.encodeUtf8(newEncryptionPassword);

        let cipher = forge.cipher.createCipher('AES-CBC', bytePassword);
        cipher.start({iv: iv});
        cipher.update(forge.util.createBuffer(AUTHSTORAGE.getRawPrivateKey()));
        cipher.finish();

        encryptionKey.privateKey = cipher.output.toHex();
        encryptionKey.iv = forge.util.encode64(iv);

        await ModelManager.set({resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/private`, modelInstance: encryptionKey, id: 'key'});
    };

    const getChangePasswordDialog = () => {
        return (
            <MoinDialog
                open={showChangePassword}
                onClose={onCloseDialog}
                showCloseButton={true}
                onClick={(event) => {event.stopPropagation(); event.preventDefault();return false}}
                id={'changePasswordDialog'}
                key={"changePasswordDialog"}
                title={t('changePassword.changePassword')}
                continueButtonText={t('changePassword.savePassword')}
                continueButtonCallback={onUpdatePassword}
                className={addClasses.changePasswordDialog}
            >
                <Typography variant={'body2'} className={addClasses.changePasswordDescription}>
                    {t('changePassword.passwordChangeDescription')}
                </Typography>

                <input type="text" name="prevent_autofill" id="prevent_autofill" style={{height: '0', border: '0', padding: '0', position: 'absolute'}} />
                <MoinTextField
                    className={classes.input}
                    title={t('changePassword.currentPassword')}
                    value={currentPassword}
                    placeholder={t('changePassword.enterCurrentPassword')}
                    onChange={e => {
                        setCurrentPassword(e.target.value);
                    }}
                    autoFocus
                    type="password"
                    id="password"
                    error={errors['currentPassword']}
                />
                <MoinTextField
                    className={classes.input}
                    title={t('changePassword.newPassword')}
                    value={newPassword}
                    placeholder={t('changePassword.enterNewPassword')}
                    onChange={e => {
                        setNewPassword(e.target.value);
                    }}
                    autoFocus={false}
                    type="password"
                    id="newPassword"
                    error={errors['newPassword']}
                />
                <MoinTextField
                    className={classes.input}
                    title={t('changePassword.repeatPassword')}
                    value={repeatNewPassword}
                    placeholder={t('changePassword.repeatNewPassword')}
                    onChange={e => {
                        setRepeatNewPassword(e.target.value);
                    }}
                    autoFocus={false}
                    type="password"
                    id="repeatPassword"
                    error={errors['repeatNewPassword']}
                />
                {
                    error &&
                    <MuiAlert elevation={6} variant="filled" severity="error">{error}</MuiAlert>
                }
            </MoinDialog>
        );
    };

    return (
        <>
            {
                showChangePassword && getChangePasswordDialog()
            }

            <ListItem className={ classes.drawerItemSub}  onClick={() => setShowChangePassword(true) } button key='nav.changePassword'>
                <Typography variant={'caption'} >
                    {t('changePassword.changePassword')}
                </Typography>
            </ListItem>
        </>
    )
}

export default ChangePassword;
