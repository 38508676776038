export const MessageType = {
    USERADDED: "MessageType.USERADDED",
    USERREMOVED: "MessageType.USERREMOVED",
    USERLEFT: "MessageType.USERLEFT",
    NAMECHANGED: "MessageType.NAMECHANGED",

    HUGHEART: "MessageType.HUGHEART",
    HUGPRAY: "MessageType.HUGPRAY",
    HUGIDEA: "MessageType.HUGIDEA",
    HUGFUNNY: "MessageType.HUGFUNNY",
    HUGCLAP: "MessageType.HUGCLAP",
    HUGBEE: "MessageType.HUGBEE",
};