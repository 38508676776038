import {id, model, prop} from "../manager/decorators";

@model()
class SurveyAnswer {

    @id()
    id;

    @prop()
    text;

    surveyId;
}

export default SurveyAnswer;
