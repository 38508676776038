import {Typography} from "@material-ui/core";
import React from "react";
import {getddMMMMyyyyFormattedDate} from "../../utils/helper/TextHelper";
import {useTranslation} from "react-i18next";
import {MarkdownTarget} from "./MarkdownTarget";
import {Skeleton} from "@material-ui/lab";
import ReactMarkdown from "react-markdown";
import {Colors} from "../../components/helper/ColorHelper";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.BLUEBACKGROUND,
        minHeight: "100vh"
    },
    content: {
        marginTop: '24px',
        width: "768px",
        display: 'flex',
        flexDirection: "column"
    }
}));

const MarkdownView = ({target, createdAt, content}) => {
    const [t] = useTranslation();
    const classes = useStyles();
    let header;

    switch (target) {
        case MarkdownTarget.PRIVACY:
            header = t('legal.dpd');
            break;
        case MarkdownTarget.TERMS:
            header = t('legal.terms');
            break;
        case MarkdownTarget.IMPRESS:
            header = t('legal.impress');
            break;
        default:
            break;
    }

    return <div className={classes.root}>
        <div className={classes.content}>
            <Typography variant="h1">
                {header && header}
            </Typography>
            <Typography variant={"caption"}>
                { createdAt
                    ? t('legal.lastChanged') + ':' + getddMMMMyyyyFormattedDate(createdAt)
                    :  <Skeleton/>
                }
            </Typography>
            <Typography variant={"body2"}>
                {content
                    ? <ReactMarkdown>{""+content}</ReactMarkdown>
                    : <>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                    </>
                }
            </Typography>
        </div>
    </div>
}

export default MarkdownView;