import {id, model, prop} from "../manager/decorators";
import {CURRENT_USER, SERVER_TIMESTAMP} from "../manager/decorators/sentinels";

@model()
class TaskActivity
{
    @prop()
    cUid;

    @prop()
    type;

    @prop({type: "string"})
    affected;

    @prop({type: "date"})
    dueDate;

    @prop({type: "date"})
    cAt;
}

export default TaskActivity;
