import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import AddIcon from "../../assets/icons/plus.svg";
import Fab from "@material-ui/core/Fab";
import MoinSimpleMenu from "./MoinSimpleMenu";

const useStyles = makeStyles((theme) => ({
    margin: {
        position: 'fixed',
        zIndex: 10001,
        marginLeft: '666px',
    },
    marginDiv: {
        width: "56px",
        height: "56px",
        bottom: '0px'
    },
    addButton: {
        color: Colors.WHITE,
        backgroundColor: Colors.BRANDSECONDARY,
        bottom: "16px",
        '&:hover': {
            backgroundColor: Colors.BRANDTERTIARY,
        },
        '@media screen and (max-width: 1000px)': {
            marginLeft: '0',
            right: "32px"
        }
    },
    containedButton: {
        position: 'absolute',
        right: 0,
        marginLeft: '0'
    }
}));

/**
 * Moin! themed floating action button. Automatically aligns with main content.
 *
 * @param {Function} onClick
 * @param {Array<JSX.Element>} children
 * @param {Boolean} contained
 * @returns {JSX.Element}
 * @constructor
 */
const MoinFab = ({onClick, children,contained = false}) => {
    const classes = useStyles();

    return <Fab color="primary" aria-label="add"
                className={
                    contained
                        ? `${classes.addButton} ${classes.margin} ${classes.containedButton}`
                        : `${classes.addButton} ${classes.margin}`
                }
                onClick={onClick}>
            <AddIcon fill={Colors.WHITE}/>
        </Fab>;
}

const MoinFabMenu = ({options, listItemClass, menuClass, buttonClass, style}) => {
    const classes = useStyles();
    return <div className={`${classes.margin} ${classes.marginDiv} `} style={style}>
        <MoinSimpleMenu options={options}
            anchorOrigin={{vertical: "center", horizontal: "center"}}
            transformOrigin={{vertical: "bottom", horizontal: "right"}}
            paperStyle={ {
                "width": '270px',
            }}
            listItemClasses={listItemClass}
            wrapperClass={menuClass}>
            <Fab color="primary" aria-label="add" className={classes.addButton} >
                <AddIcon fill={Colors.WHITE}/>
            </Fab>
        </MoinSimpleMenu>
    </div>
}

export default MoinFab;
export {MoinFabMenu};