import {Typography} from "@material-ui/core";
import React, {useRef, useState} from "react";
import {getUserAvatarPath} from "../../../utils/helper/ImageHelper";
import FireStorageImage from "../../base/FirestoreImage";
import {makeStyles} from "@material-ui/core/styles";
import {formatCommentDate} from "../../../utils/helper/TextHelper";
import {useTranslation} from "react-i18next";
import MoinSimpleMenu from "../../base/MoinSimpleMenu";
import AUTHSTORAGE from "../../../utils/auth/AuthStorage";
import MoinSimpleMenuOption from "../../base/MoinSimpleMenuOption";
import {Permission} from "../../../utils/enums/Permission";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import MoinButton from "../../base/MoinButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import MoinUpload from "../../base/MoinUpload";
import {Colors} from "../../helper/ColorHelper";
import DeleteIcon from '../../../assets/icons/delete.svg';
import CameraIcon from "../../../assets/icons/camera.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import MoinListItem from "../../base/MoinListItem";
import * as ROUTES from "../../../Routes";
import {useHistory,withRouter} from "react-router-dom";
import MoinLinkifiedText from "../../base/MoinLinkifiedText";
import ReactionRowContainer from "../ReactionRowContainer";
import CommentReaction from "../../../utils/models/CommentReaction";
import MentionTextField from "../../mention/MentionTextField";
import MentionAutocomplete from "../../mention/MentionAutocomplete";

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerRight: {
        marginLeft: theme.spacing(1)
    },
    commentContent: {
        marginLeft: '56px',
        marginBottom: '12px',
        marginRight: '16px',
    },
    commentImage: {
        width: '100%',
        borderRadius: '6px',
        objectFit: "contain",
        maxHeight: "400px"
    },
    commentImageWrapper: {
        marginTop: theme.spacing(1),
        position: 'relative'
    },
    editField: {
        margin: "0"
    },
    editImageWrapper: {
        borderRadius: "16px",
        marginTop: "12px",
        width: '100%',
        maxHeight: '400px',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.WHITE
    },
    commentPadding: {
        paddingLeft: "16px",
        paddingRight: "16px"
    },
    editCommentImage: {
        width: '100%',
        objectFit: 'contain'
    },
    cardActions: {
        margin: "0 16px 12px 56px"
    },
    selectImageInfo: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        marginTop: '25%'
    },
    whiteTextColor: {
        color: Colors.WHITE
    },
    link: {
        color: Colors.BRANDSECONDARY,
        cursor: "pointer",
        textTransform: "none",
        textDecoration: "none",

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    },
    sendButton: {
        height: '38px',
        minHeight: '38px',
        width: '38px',
        minWidth: '38px',
        borderRadius: '38px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.WHITE,
        marginLeft: theme.spacing(1),
        cursor: 'pointer'
    },
    completeWrapper: {
        position: 'relative'
    },
    deletedCommentCircle: {
        width: '48px',
        height: '48px',
        borderRadius: '24px',
        backgroundColor: Colors.BLACKLIGHTEST,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '8px'
    },
    deletedCommentIcon: {
        fill: Colors.WHITE,
        width: '24px',
        height: '24px'
    },
    commentDeletedMessage: {
        display: 'flex',
        alignItems: 'center',
        margin: "16px"
    },
    commentDeletedText: {
        color: Colors.BLACKLIGHTER
    }
}));

/**
 * @param {Comment} comment
 * @param {CommentReaction} commentReaction
 * @param {Function} changeReaction
 * @param {Function} deleteComment
 * @param {Function} updateComment
 * @constructor
 */
const RenderedComment = ({comment,authReaction, changeReaction, deleteComment, updateComment }) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const history = useHistory();
    const textFieldRef = useRef();
    const wrapperDivRef = useRef();

    const [editTextContent, setEditTextContent] = useState();
    const [editImage, setEditImage] = useState();
    const [deletedImage, setDeletedImage] = useState(false);

    const onDeleteComment = async () => {
        setDeleting(true);

        let finishedDeletion = await deleteComment();
        if(finishedDeletion) {
            setShowDeleteDialog(false);
            setDeleting(false);
        }
    };

    const switchToEdit = () => {
        setEditTextContent(comment.text);
        setEditImage(comment.image);
        setDeletedImage(false);
        setIsEdit(true);
    };

    const getDeleteDialog = () => {
        return (
            <Dialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('article.comments.deleteComment') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">{ t('article.comments.deleteCommentDescription') }</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.deleteDialogActions}>
                    <MoinButton onClick={() => setShowDeleteDialog(false)} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    {
                        deleting &&
                        <div className="moin-circular-progress-container">
                            <CircularProgress />
                        </div>
                    }
                    <MoinButton onClick={() => onDeleteComment()}>{ t('buttons.delete') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    };

    const removeImage = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditImage(null);
        setDeletedImage(true);
        return false;
    };

    const getEditImage = () => {
        if(!!editImage?.name) {
            return (
                <div className={classes.editImageWrapper}>
                    <img src={URL.createObjectURL(editImage)} className={classes.editCommentImage}/>
                    <div className={classes.selectImageInfo}>
                        <CameraIcon fill={Colors.WHITE} />
                        <Typography variant="body1" className={classes.whiteTextColor}>{ t('article.edit.editImage') }</Typography>
                    </div>
                </div>
            );
        } else if(!!editImage) {
            return (
                <div className={classes.editImageWrapper}>
                    <FireStorageImage lightboxEnabled={true} src={comment.image} className={classes.editCommentImage}/>
                    <div className={classes.selectImageInfo}>
                        <CameraIcon fill={Colors.WHITE} />
                        <Typography variant="body1" className={classes.whiteTextColor}>{ t('article.edit.editImage') }</Typography>
                    </div>
                </div>
            );
        }

        return <Typography variant="body2" className={classes.link}>+ { t('article.edit.addImage') }</Typography>;
    };

    const getImage = () => {
        if(isEdit) {
            return (
                <>
                    <MoinUpload onChangeFile={files => {
                        setEditImage(files[0]);
                        setDeletedImage(false);
                    }}>
                        { getEditImage() }
                    </MoinUpload>

                    {
                        !!editImage &&
                            <Typography variant="body2" className={classes.link} onClick={removeImage}>{ t('article.comments.removeImage') }</Typography>
                    }
                </>
            );
        } else if(!!comment.image) {
            return (
                <div className={classes.commentImageWrapper}>
                    <FireStorageImage lightboxEnabled={true} src={comment.image} className={classes.commentImage}/>
                </div>
            );
        }
    };

    const onSave = async () => {
        await updateComment(editTextContent, editImage, deletedImage);
        setIsEdit(false);
    };

    if(comment.dAt) {
        return (
            <div className={classes.commentDeletedMessage}>
                <div className={classes.deletedCommentCircle}>
                    <DeleteIcon className={classes.deletedCommentIcon}/>
                </div>

                {
                    comment.dBy === comment.cUid
                    ? <Typography variant="body2" className={classes.commentDeletedText}>{ t('article.comments.deletedByCreator') }</Typography>
                    : <Typography variant="body2" className={classes.commentDeletedText}>{ t('article.comments.deletedByAdmin') }</Typography>
                }
            </div>
        );
    }

    const contextMenuOptions = [];

    if(comment.cUid === AUTHSTORAGE.getUserId()) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.edit'), () => switchToEdit()));
    }

    if(comment.cUid === AUTHSTORAGE.getUserId() || AUTHSTORAGE.hasPermission(Permission.COMMENTDELETE)) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('common.delete'), () => setShowDeleteDialog(true)));
    }

    return (
        <div className={classes.completeWrapper} ref={(wrapperDiv) => wrapperDivRef.current = wrapperDiv}>
            {
                getDeleteDialog()
            }
            {
                isEdit &&
                    <MentionAutocomplete
                        textFieldRef={textFieldRef}
                        textValue={editTextContent}
                        replaceText={(text) => setEditTextContent(text)}
                        parent={wrapperDivRef.current}
                    />
            }
            <MoinListItem
                image={<FireStorageImage backgroundColor={Colors.identifierAsColor(comment.cUid)} isAvatar={true} radius={16} src={getUserAvatarPath(comment.cUid)} alt={comment.cName}/>}
                title={ comment.cName }
                subtitle={ formatCommentDate(t, comment.cAt)}
                withDivider={false}
                withSplashEffect={false}
                customClass={classes.commentPadding}
                trailing={
                    contextMenuOptions.length > 0 &&
                        <MoinSimpleMenu options={contextMenuOptions}/>
                }
                onClick={
                    () => history.push(ROUTES.userShow.replace(':id', comment.cUid))
                }
            />

            <div className={classes.commentContent}>
                {
                    isEdit ?
                        <div className={classes.row}>
                            <MentionTextField
                                textFieldRef={textFieldRef}
                                value={editTextContent}
                                multiline={true}
                                rowsMax={5}
                                rows={1}
                                onChange={(text) => setEditTextContent(text)}
                                className={classes.editField}
                            />
                            <div className={classes.sendButton} style={{backgroundColor: (!!editTextContent || !!editImage) ? Colors.BRANDSECONDARY : Colors.BLACKLIGHTER}} onClick={() => onSave()}>
                                <ArrowRightIcon fill={Colors.WHITE} />
                            </div>
                        </div>
                    :
                        <Typography variant="body2"><MoinLinkifiedText text={ comment.text } /></Typography>
                }
                {
                    getImage()
                }
            </div>

            <div className={classes.cardActions}>
                <ReactionRowContainer
                    model={comment}
                    modelPath={"comment"}
                    reactionModel={CommentReaction}
                    isComment={true}
                    showCommentButtons={true}
                    commentCount={comment.aCount}
                    onLikeCountTap={() => history.push(ROUTES.commentLikeList.replace(":id", comment.id))}
                    onCommentIconTap={() => history.push(ROUTES.commentShow.replace(":id", comment.id))}
                    onCommentCountTap={() => history.push(ROUTES.commentShow.replace(":id", comment.id))}
                    showLikeButton={true}
                />
            </div>
        </div>
    );
};

export default withRouter(RenderedComment);