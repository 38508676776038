import {EventDispatcher} from "./eventdispatcher";

class ModelRepository extends EventDispatcher
{
    manager;
    model;

    /**
     * @param manager
     * @param model
     */
    constructor(manager, model) {
        super();
        this.model = model;
        this.manager = manager;
    }

    /**
     * get a single instance by id
     * @param id
     * @return {Promise<unknown>}
     */
    get(id) {
        return new Promise((resolve, reject) => this.manager.connection
            .collection(this.model.resource)
            .withConverter(this.model.converter)
            .doc(id)
            .get()
            .then(doc => {
                resolve(doc.data())
            })
        );
    }


    /**
     * get all instances
     * @return {Promise<unknown>}
     */
    getAll() {
        return new Promise((resolve, reject) => this.manager.connection
            .collection(this.model.resource)
            .withConverter(this.model.converter)
            .get()
            .then(snapshot => {
                const data = [];
                snapshot.forEach(doc => data.push(doc.data()));
                resolve(data);
            }));
    }
}

export default ModelRepository;