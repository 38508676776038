import {model, prop} from "../manager/decorators";
import {CustomRepetitionIntervalType} from "../enums/repetition/CustomRepetitionIntervalType";

@model()
class CustomRepetitionInterval {
    @prop()
    type = CustomRepetitionIntervalType.WEEK;

    @prop()
    amount = 3;

    @prop()
    monthDay;

    @prop()
    repetitionDays;

    clone() {
        let copy = new CustomRepetitionInterval();
        copy.type = this.type;
        copy.amount = this.amount;
        copy.monthDay = this.monthDay;
        copy.repetitionDays = this.repetitionDays;

        return copy;
    }

    /**
     * @param {CustomRepetitionInterval} interval
     */

    isSame(interval) {
        if(this.type === interval.type && this.amount === interval.amount) {
            switch (this.type) {
                case CustomRepetitionIntervalType.WEEK:
                    return this.repetitionDays.every(element => interval.repetitionDays.includes(element)) && interval.repetitionDays.every(element => this.repetitionDays.includes(element));
                case CustomRepetitionIntervalType.MONTH:
                    return this.monthDay === interval.monthDay;
            }
        }

        return false;
    }

    isValid() {
        switch (this.type) {
            case CustomRepetitionIntervalType.WEEK:
                return this.amount > 0 && !!this.repetitionDays && this.repetitionDays.length > 0;
            case CustomRepetitionIntervalType.MONTH:
                return this.amount > 0 && !!this.monthDay;
        }
    }
}

export default CustomRepetitionInterval;
