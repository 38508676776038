class ScrollHelper {
    #_lastScrollPosition;
    #_shouldRestorePosition;

    updateLastScrollPosition() {
        this.#_lastScrollPosition = document.getElementById('app').scrollTop;
        this.#_shouldRestorePosition = true;
    }

    scrollToTop() {
        document.getElementById('app').scrollTo(0, 0);
    }

    restoreScrollPosition() {
        if(this.#_shouldRestorePosition) {
            document.getElementById('app').scrollTo(0, this.#_lastScrollPosition);
            this.#_shouldRestorePosition = false;
        }
    }
}

const SCROLLHELPER = new ScrollHelper();

export default SCROLLHELPER;
