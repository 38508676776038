import React from "react";
import MentionHelper from "../../utils/helper/MentionHelper";
import MoinTextField from "../base/MoinTextField";

const MentionTextField = ({onChange, value, textFieldRef, ...rest}) => {
    const originalValue = value;

    const onTextChange = (e) => {
        let text = e.target.value;
        let newCursorOffset;
        let allMentions = MentionHelper.getAllMentionsInText(originalValue, true);

        if(allMentions.length > 0) {
            let mentionAtCursor;

            // replace all mentioned names and filler zero width spaces with the correct mention string
            for(let mention of allMentions) {
                let textOffset = mention.textStartIndex > 0 ? 1 : 0;
                text = text.substring(0, mention.textStartIndex + textOffset) + mention.mentionString + text.substring(mention.textStartIndex + mention.mentionString.length + textOffset);
                if(textFieldRef.current.selectionStart > mention.textStartIndex && textFieldRef.current.selectionStart <= (mention.textStartIndex + mention.mentionString.length )) {
                    mentionAtCursor = mention;
                }
            }

            // check if 'textFieldRef.current.selectionStart' is inside a mention, if yes: remove mention, if no: do nothing
            if(!!mentionAtCursor) {
                let textOffset = mentionAtCursor.textStartIndex > 0 ? 1 : 0;
                let keepSpaceAfterMention = originalValue[mentionAtCursor.textStartIndex + mentionAtCursor.mentionString.length + 1] === " ";

                text = text.substring(0, mentionAtCursor.textStartIndex + textOffset) + mentionAtCursor.fallbackName + (keepSpaceAfterMention ? " " : "") + text.substring(mentionAtCursor.textStartIndex + mentionAtCursor.mentionString.length + textOffset);
                newCursorOffset = textFieldRef.current.selectionStart - mentionAtCursor.mentionString.length + mentionAtCursor.fallbackName.length + 1;
            }
        }

        onChange(text);

        if (newCursorOffset === 0 || !!newCursorOffset) {
            // timeout is required otherwise this will be ignored in some browsers
            setTimeout(() =>  textFieldRef.current.setSelectionRange(newCursorOffset, newCursorOffset), 1);
        }
    };

    const getRenderText = () => {
        let allMentions = MentionHelper.getAllMentionsInText(value);
        let renderText = value;

        for(let mention of allMentions) {
            let textOffset = mention.textStartIndex > 0 ? 1 : 0;

            let lengthDiff = mention.mentionString.length - mention.fallbackName.length;
            let paddingString = '';

            for(let i = 0; i < lengthDiff; i++) {
                paddingString += '​'; // This string is not empty, it is a zero-width space. You should be able to "see" it when moving the cursor with the arrow keys across it
            }

            renderText = renderText.substring(0, mention.textStartIndex + textOffset) + paddingString + mention.fallbackName + renderText.substring(mention.textStartIndex + mention.mentionString.length + textOffset);
        }

        return renderText;
    };


    // function CustomMentionInputField(props) {
    //     const { inputRef, onChange, value, ...other } = props;
    //
    //     return (
    //         <div
    //             {...other}
    //             ref={inputRef}
    //             onInput={(values) => {
    //                 onChange({
    //                     target: {
    //                         name: props.name,
    //                         value: values.currentTarget.textContent,
    //                     }
    //                 });
    //             }}
    //             contentEditable={true}
    //             textContent={value}
    //         />
    //     );
    // }

    return (
        <MoinTextField
            textFieldRef={(input) => textFieldRef.current = input}
            value={getRenderText()}
            onChange={onTextChange}
            // InputProps={{
            //     inputComponent: CustomMentionInputField,
            // }}
            {...rest}
        />
    )
}

export default MentionTextField;