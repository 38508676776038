import React, {Fragment, useEffect, useLayoutEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../components/helper/ColorHelper";
import ArrowUpIcon from '../../assets/icons/arrow-up.svg';
import ArrowDownIcon from '../../assets/icons/arrow-up.svg';
import {useTranslation} from "react-i18next";
import MoreHorizontalIcon from "../../assets/icons/more-horizontal.svg";
import DownloadIcon from "../../assets/icons/download.svg";
import UploadIcon from "../../assets/icons/upload.svg";
import InfoIcon from "../../assets/icons/info.svg";
import EditIcon from "../../assets/icons/edit.svg";
import CloseIcon from "../../assets/icons/close.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg";
import CheckIcon from "../../assets/icons/check.svg";
import FileUploadIcon from "../../assets/icons/file-upload.svg";
import FolderAddIcon from "../../assets/icons/folder-add.svg";
import MoinSimpleMenuOption from "../../components/base/MoinSimpleMenuOption";
import StorageFile from "../../utils/models/StorageFile";
import MoinSimpleMenuContext from "../../components/base/MoinSimpleMenuContext";
import {Typography} from "@material-ui/core";
import {humanFileSize, timestampToDate} from "../../utils/helper/TextHelper";
import {setFileTypeIcon} from "../../utils/helper/FileIconHelper";
import {extToStorageFileType, StorageFileType} from "../../utils/enums/StorageFileType";
import MoinDialog from "../../components/base/MoinDialog";
import MoinTextField from "../../components/base/MoinTextField";
import FireStorageImage from "../../components/base/FirestoreImage";
import {SortDirections, StorageSortField} from "../../utils/enums/StorageSort";
import CircularProgress from "@material-ui/core/CircularProgress";
import StorageInfo from "./StorageInfo";
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import illustration from "../../assets/illustrations/moin-app-illus-no-content.png";
import MoinButton from "../../components/base/MoinButton";
import FireStorageVideo from "../../components/base/FirestoreVideo";
import { v4 as uuid } from 'uuid';
import {MoinFabMenu} from "../../components/base/MoinFab";
import MoinUpload from "../../components/base/MoinUpload";
import {MediaContentType} from "../../utils/enums/MediaContentType";
import clsx from "clsx";
import PDFIcon from "../../assets/icons/pdf.svg";

import { createFirstPdfPreview } from "../../utils/storage/StorageClient";
import {FireStorage} from "../../api/FirebaseService";
import STORAGECACHEMANAGER from "../../components/base/FirestoreCacheManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Permission} from "../../utils/enums/Permission";



const useStyles = makeStyles((theme) => ({
    sideSpacer: {
        flex: 1
    },
    minWidthAnimation: {
        minWidth: "300px",
        "-webkit-transition-duration": ".15s",
        "transition-duration": ".15s",
        "-webkit-transition-property": "width",
        "transition-property": "width",
        "-webkit-transition-timing-function": "cubic-bezier(0.4,0.0,1,1)",
        "transition-timing-function": "cubic-bezier(0.4,0.0,1,1)"
    },
    storageWrap: {
        display: "flex",
    },
    storageItemListWrap: {
        maxWidth: "768px",
        flex: 100,
    },
    storageItemList: {
        width: '100%',
        marginBottom: '64px',
        '& th': {
            borderBottom: '1px solid' + Colors.BLUELIGHT,
        },
        '& td': {
            height: '60px',
            borderBottom: '1px solid' + Colors.BLUELIGHT
        },
        '& tr:last-child td': {
            borderBottom: 'none'
        },
        '& tbody > tr:hover': {
            background: Colors.WHITE,
            cursor: 'pointer'
        }
    },
    breadCrumb : {
        display: "inline-block",
        margin: '24px 0 18px 0',
        verticalAlign: 'top'
    },
    breadCrumbIcon : {
        display: "inline-block",
        margin: '26px 8px',
        verticalAlign: 'top',
        height: '24px',
        width: '24px'
    },
    breadCrumbIconMore : {
        display: "inline-block",
        margin: '26px 0',
        verticalAlign: 'top',
        height: '24px',
        width: '24px'
    },
    clickable: {
        cursor: 'pointer'
    },
    titleName: {
        textAlign: 'left',
        cursor: 'pointer'
    },
    titleUpdated: {
        textAlign: 'center',
        cursor: 'pointer',
        width: '24%'
    },
    titleSize: {
        textAlign: 'left',
        cursor: 'pointer',
        width: '24%'
    },
    showMore: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: 'pointer'
    },
    valueSizeNone: {
        fontWeight: '300',
        fontSize: '24px',
        color: Colors.BLACKLIGHT
    },
    storageItemListIcon: {
        display: 'inline-block',
        verticalAlign: 'inherit',
        height: '20px'
    },
    chevronDownIcon: {
        height: '16px',
        fill: Colors.BLACKLIGHT
    },
    headerRow: {
        height: '34px'
    },
    moreHorizontalIcon: {
        height: '24px',
        fill: Colors.BLACK
    },
    fileListItem: {
        padding: '14px 24px',
        backgroundColor: Colors.WHITE + ' !important',
        '& p': {
            fontSize: '15px',
            lineHeight: '24px'
        },
        '&:hover': {
            backgroundColor: Colors.BLUELIGHT + ' !important'
        }
    },
    fabContainer: {
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        display: 'flex',
        zIndex: 1299
    },
    fabSizing: {
        flex: '100',
        maxWidth: '768px',
        position: 'relative'
    },
    fileAddListItem: {
        padding: '18px 24px',
        backgroundColor: Colors.WHITE + ' !important',
        '&:first-child': {
            borderBottom: '1px solid' + Colors.BLACKLIGHTEST,
        },
        '& p': {
            fontSize: '15px',
            lineHeight: '24px'
        },
    },
    backgroundBlue: {
        backgroundColor: Colors.BLUELIGHT + ' !important'
    },
    fileIcon: {
        position: 'relative',
        border: 'none !important',
        width: '40px',
        textAlign: 'center',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& svg': {
            width: '32px',
            height: '32px',
        },
        '& div': {
            width: "32px",
            height: "32px",
            objectFit: "cover",
            borderRadius: '4px'
        },
        '& img': {
            width: "32px",
            height: "32px",
            objectFit: "cover",
            borderRadius: '4px'
        }
    },
    fileName: {
        maxWidth: '20em',
        minWidth: '48%',
        '& h2': {
            marginLeft: '4px',
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
        },
    },
    addFolderDialog: {
        '& .MuiDialog-paper': {
            borderRadius: '16px !important',
            '& h3': {
                fontSize: '15px',
                fontWeight: '500',
                lineHeight: '22px'
            },
            '& .MuiDialogTitle-root': {
                padding: '16px 16px 12px'
            },
            '& .MuiDialogContent-root': {
                padding: '8px 16px'
            },
            '& .MuiDialogContentText-root': {
                margin: '0'
            },
            '& .MuiDialogActions-root': {
                padding: '0 16px 16px'
            },
            '& fieldset': {
                borderColor: Colors.BRANDTERTIARY + '!important'
            },
            '& input': {
                fontSize: '13px',
                lineHeight: '18px'
            }
        }
    },
    deleteMessage: {
        color: Colors.BLACK,
        marginBottom: '16px',
        display: 'block'
    },
    previewDialog: {
        zIndex: '11002 !important',
        '& .MuiDialog-paper': {
            maxHeight: '80vh',
            maxWidth: '80vw',
            borderRadius: '8px !important',
        },
        '& video': {
            maxHeight: '80vh',
            width: 'auto',
            borderRadius: '8px !important',
        },
        '& img': {
            maxWidth: "80vw",
            maxHeight: "80vh",
            borderRadius: '8px !important',
        },
    },
    filePreviewDialog: {
        zIndex: '11002 !important',
        '& .MuiDialog-paper': {
            minWidth : '330px',
            maxHeight: '80vh',
            maxWidth: '80vw',
            borderRadius: '8px !important'
        },
        '& .MuiDialogTitle-root': {
            display: 'none'
        },
        '& .MuiDialogContent-root': {
            padding: '0'
        },
        '& .MuiDialogContentText-root': {
            margin: '0'
        },
        '& .MuiDialogActions-root': {
            padding: '16px'
        },
    },
    fileNoPreviewText: {
        textAlign: 'center',
        maxWidth: '250px',
        margin: '0 auto 24px',
        '& h2': {
            marginBottom: '8px'
        }
    },
    filePreviewIcon: {
        textAlign: 'center',
        margin: '38px 0',
        '& svg': {
            height: '72px',
            width: '72px'
        }
    },
    fileSnackbarContainer: {
        position: 'absolute',
        bottom: '0',
        width: "calc(100% - 70px)",
        left: '0',
        pointerEvents: 'all'
    },
    fileSnackbarContent: {
        borderRadius: '16px',
        background: 'white',
        width: '100%',
        padding: '18px 24px',
        marginBottom: '16px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.05)",
        '& span': {
            color: Colors.BLACK,
        },
        '& svg': {
            marginRight: '12px',
            width: '20px',
            height: '20px',
        }
    },
    iconContainer: {
        width: "20px",
        height: "20px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& svg': {
            verticalAlign: 'middle',
            marginRight: '0',
            width: '20px',
            height: '20px',
            cursor: 'pointer'
        }
    },
    progressBar: {
        width: '40px',
        marginRight: '16px',
        borderRadius: '20px',
        backgroundColor: Colors.BLACKLIGHTEST
    },
    progressContainer: {
        marginLeft: 'auto',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    noContent: {
        marginTop: '40px',
        textAlign: 'center',
    },
    noContentUpload: {
        display: 'block',
        margin: '12px 0 32px'
    },
    illustration: {
        marginBottom: '12px'
    },
    addFile: {
        zIndex: '11000',
        position: "absolute",
        right: 0,
        pointerEvents: "all"
    },
    fileOptions: {
        zIndex: '11001',
    },
    container: {
        cursor: 'pointer'
    },
}));

/**
 * @param {String} path
 * @param {Array<StorageFile>} files
 * @param {Array<StorageFile>} allFiles
 * @param {Function} onNav
 * @param {Function} loadFiles
 * @param {Function} onFolderChange
 * @param {Function} onRenameFile
 * @param {Function} onRenameFile
 * @param {boolean} loading
 * @param {boolean} canSort
 * @param {boolean} validPath
 * @constructor
 */
const Storage = ({path, files, allFiles, loadFiles, onFolderChange, onAddFile, onRenameFile, onDownloadFile, onDeleteFile, loading, canSort = true, validPath=true, firestoreUrls}) => {
    const [showAddFolder, setShowAddFolder] = useState(false);
    const [showFilePreview, setShowFilePreview] = useState(false);
    const [showInfo, setShowInfo] = useState(true);

    const sortField = useRef(StorageSortField.NAME);
    const sortDirection = useRef(SortDirections.ASCENDING);
    const [rebuild,setRebuild] = useState(false);

    const filesList = useRef();
    const [folderName, setFolderName] = useState("");

    const [changedName, setChangedName] = useState("");
    const [changedExtension, setChangedExtension] = useState("");
    const [changedFile, setChangedFile] = useState();
    const [deletedFile, setDeletedFile] = useState();

    const fileInputField = useRef();
    const tablePositionRef = useRef();

    const classes = useStyles();
    const [t, i18n] = useTranslation();

    const [selectedFile, setSelectedFile] = useState();
    const [displayInfoFile, setDisplayInfoFile] = useState();
    const [displayPreviewFile, setDisplayPreviewFile] = useState(new StorageFile());

    const [selectedFileLoading, setSelectedFileLoading] = useState();

    const [operations, setStorageOperations] = useState([]);

    const blockKeyEvents = useRef(false);

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle, true);

        if (files.length > 0) {
            window.addEventListener('keyup', onKeyUp, true);
            window.addEventListener('keydown', onKeyDown, true);
        }

        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
            window.removeEventListener('keyup', onKeyUp, true);
            window.removeEventListener('keydown', onKeyDown, true);
        }
    }, [files]);

    const onScrollHandle = () => {
        if(!!filesList.current && filesList.current.getBoundingClientRect().bottom <= window.innerHeight + 250 && files.length > 0 && !loading) {
            loadFiles({path: path,sortBy: sortField.current, direction: sortDirection.current, from: files.length});
        }
    };

    const onKeyDown = (e) => {
        if ( (e.keyCode === 37 || e.keyCode === 39) && !blockKeyEvents.current) {
            blockKeyEvents.current = true;
            setDisplayPreviewFile(
                (currentPreviewFile) => {
                    if (currentPreviewFile && e.keyCode === 37 ) {
                        let currentIndex = files.findIndex( (file) => file.id === currentPreviewFile.id);
                        let remainingFiles = files.slice(0,currentIndex).filter( (file) => file.type === StorageFileType.IMAGE ||  file.type === StorageFileType.VIDEO);

                        if (remainingFiles && remainingFiles.length) {
                            return  remainingFiles[remainingFiles.length -1];
                        }
                    } else if (currentPreviewFile && e.keyCode === 39) {
                        let currentIndex = files.findIndex( (file) => file.id === currentPreviewFile.id);
                        let remainingFiles = files.slice(currentIndex+1,files.length).filter( (file) => file.type === StorageFileType.IMAGE ||  file.type === StorageFileType.VIDEO);
                        if (remainingFiles && remainingFiles.length) {
                            return  remainingFiles[0];
                        }
                    }
                    return currentPreviewFile;
                }
            )
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    const onKeyUp = (e) => {
        if  (e.keyCode === 37 || e.keyCode === 39) {
            blockKeyEvents.current = false
        }
    };

    const assignSelectedFile = (file) => {

        let newSrc = file.fullPath.replace(/%2F/g, '/');
        let firestoreUrl = firestoreUrls.find( (url) => url.id === newSrc || url.id === newSrc + '_preview');

        if( file.type ===  StorageFileType.PDF && !!firestoreUrl && !firestoreUrl.url && !file.temporaryPreview ) {
            setSelectedFileLoading(true);

            FireStorage.ref(newSrc).getDownloadURL()
            .then((url) => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = async (event) => {
                    const blob = xhr.response;

                    const fullBlob = new Blob([blob], {type: "application/pdf"});
                    let pdfPreview = await createFirstPdfPreview(fullBlob, newSrc);
                    file.temporaryPreview = pdfPreview;
                    STORAGECACHEMANAGER.removeUrlFromRequested(newSrc);
                    setSelectedFileLoading(false);

                    setSelectedFile(prevState => ({
                        ...prevState,
                        temporaryPreview: pdfPreview
                    }))
                }
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                console.log(error.message)
            })
        }
        setSelectedFile(file)
    }

    const addContentOptions= (file) => [
        ...(
            file.type !== StorageFileType.FOLDER
            ? [new MoinSimpleMenuOption( t('storage.download'), () => onDownload(file) , <DownloadIcon/>)]
            : []
        ),
        new MoinSimpleMenuOption( t('storage.details'), () => {setDisplayInfoFile(file); assignSelectedFile(file); setShowInfo(true);}, <InfoIcon/>),
        new MoinSimpleMenuOption( t('storage.rename'),() => {
            let nameParts = file.name.split('.');
            if (nameParts.length > 1) {
                let ext = nameParts[nameParts.length -1];
                setChangedExtension(ext);
                setChangedName(nameParts.slice(0, -1).join('.'));
            } else {
                setChangedName(file.name)
                setChangedExtension("");
            }
            setChangedFile(file);
        } , <EditIcon/>),

        ...(
            file.createdBy === AUTHSTORAGE.getUserId() || AUTHSTORAGE.hasPermission(Permission.DELETEDOCUMENTS)
            ? [new MoinSimpleMenuOption( t('storage.delete') ,() => setDeletedFile (file) , <DeleteIcon/>)]
            : []
        )
    ];

    const onChangeSort = (field) => {
        if (sortField.current === field ) {
            sortDirection.current =  sortDirection.current === SortDirections.ASCENDING ? SortDirections.DESCENDING : SortDirections.ASCENDING;
        } else {
            sortDirection.current = field === StorageSortField.NAME ? SortDirections.ASCENDING : SortDirections.DESCENDING;
            sortField.current =  field;
        }
        loadFiles({path: path,sortBy: sortField.current, direction: sortDirection.current, from: 0});
        setRebuild(!rebuild);
    }

    const onReloadPage = () => {
        sortDirection.current = SortDirections.ASCENDING;
        sortField.current =  StorageSortField.NAME;
        loadFiles({path: path,sortBy: sortField.current, direction: sortDirection.current, from: 0});
        setRebuild(!rebuild);
    }

    const onAddFolder =() => {
        let name = folderName;

        if (name && name.trim().length > 0) {
            setFolderName("");
            onAddFile( {name: name, type: StorageFileType.FOLDER, path: path});
            setShowAddFolder(false);
        }
    }

    const onRename =() => {
        let name = changedName;

        if (name && name.trim().length > 0) {
            if (changedExtension) {
               name = name + '.' +  changedExtension;
            }
            onRenameFile({file: changedFile, name: name});
            setChangedName("");
            setChangedExtension("");
            setChangedFile(undefined);
        }
    }

    const onDelete =() => {
        onDeleteFile (deletedFile);
        setDeletedFile(undefined);
    }

    const removeOperation = (instance) => {
        setStorageOperations( (storageOperations) => {
            let index = storageOperations.findIndex( (element) => element.id === instance.id);

            if (index >= 0) {
                if (index === 0 && storageOperations.length === 1) {
                    return [];
                }
                storageOperations.splice(index, 1)
                return [...storageOperations];
            } else {
                return storageOperations || [];
            }
        });
    }

    const updateOperation = (instance) => {
        setStorageOperations( (storageOperations) => {
            let index = storageOperations.findIndex( (element) => element.id === instance.id);
            if (index < 0) {
                return [instance, ...storageOperations];
            }
            storageOperations[index] = instance;

            return [...storageOperations];
        });
    }

    const onCancel = (upload) => {
        upload.cancel = true
        updateOperation(upload);
    }

    const onProgress = (instance,newProgress) => {

        instance.progress = newProgress;
        updateOperation(instance);

        if (newProgress >= 100) {
            setTimeout(() => {
                removeOperation(instance);
            }, 3500);
        }

    }

    const onDownload =(file) => {
        let download = {
            id: uuid(),
            file: file,
            name: file.name,
            type: file.type,
            progress: 0,
            upload: false,
            cancel : false
        }
        onDownloadFile( {id: file.id, path: file.path, name: file.name, onProgress: (progress) =>  onProgress(download,progress),onReady: () =>  onProgress(download,100),isCanceled: () => download.cancel });
    }

    const onPreviewDownload =() => {
        onDownload(displayPreviewFile);
        setShowFilePreview(false);
    }

    const onPreviewClose = () => {
        setShowFilePreview(false);
    };

    const onUploadFiles =(files) => {
        if (files && files.length > 0) {
            files.forEach( (file) => {
                const name = file.name;

                const lastDot = name.lastIndexOf('.');
                const ext = name.substring(lastDot + 1);
                let type = StorageFileType.OTHER;

                if (lastDot > 0) {
                    if (file.type.startsWith('video/')) {
                        type = StorageFileType.VIDEO;
                    } else if (file.type.startsWith('image/')) {
                        type = StorageFileType.IMAGE;
                    } else {
                        type = extToStorageFileType(ext);
                    }
                }

                let upload = {
                    id: uuid(),
                    file: file,
                    name: name,
                    type: type,
                    progress: 0,
                    upload: true,
                    cancel : false
                }
                onAddFile( {file: file, name: name, type: type, path: path, onProgress: (progress) =>  onProgress(upload,progress),isCanceled: () => upload.cancel });
            })
        }
    }

    const addFileOptions= () => [
        new MoinSimpleMenuOption( t('storage.addFolder') ,() => setShowAddFolder(true) , <FolderAddIcon/>),
        new MoinSimpleMenuOption( t('storage.addFile'),() => fileInputField.current.click() , <FileUploadIcon/>)
    ];

    const onPathClick =( file) => {
        let folderPath = file.path + '/' +file.id;
        if ( folderPath.length > 1 && folderPath[0] === "/") {
            folderPath = folderPath.substring(1);
        }
        onFolderChange(folderPath);
    }

    const onFileClick=(file) => {
        if (showKey !== null) {
            return
        }

        if (selectedFile?.id === file.id) {
            if (file.type === StorageFileType.FOLDER) {
                let folderPath = file.path + '/' +file.id;
                if ( folderPath.length > 1 && folderPath[0] === "/") {
                    folderPath = folderPath.substring(1);
                }
                onFolderChange(folderPath);
            } else {
                setDisplayPreviewFile(file);
                setShowFilePreview(true);
            }
        } else {
            if ( showInfo) {
                setDisplayInfoFile(file);
            }
            assignSelectedFile(file);
        }
    }

    const buildOperation = (operation) => {
        let icon;
        let progressText;
        let progressBar;
        if (operation.upload) {
            icon = <UploadIcon fill={Colors.BLACK}/>;

            if (operation.cancel) {
                progressText = <Typography variant={"caption"}> {t('storage.uploadCancel')}</Typography>;
            } else if(operation.progress >= 100) {
                progressText = <Typography variant={"caption"}> {t('storage.uploadSuccess')}</Typography>;
            } else {
                progressText = <Typography variant={"caption"}> {t('storage.uploadProgress')}</Typography>;
            }
        } else {
            icon = <DownloadIcon fill={Colors.BLACK}/>;

            if (operation.cancel) {
                progressText = <Typography variant={"caption"}> {t('storage.downloadCancel')}</Typography>;
            } else if(operation.progress >= 100) {
                progressText = <Typography variant={"caption"}> {t('storage.downloadSuccess')}</Typography>;
            } else {
                progressText = <Typography variant={"caption"}> {t('storage.downloadProgress')}</Typography>;
            }
        }

        if (operation.cancel) {
            progressBar =  <div className={classes.progressContainer}>
                <div className={classes.iconContainer} style={{backgroundColor: Colors.ERROR}}>
                    <CloseIcon fill={Colors.WHITE}/>
                </div>
            </div>
        }
        else if(operation.progress >= 100) {
            progressBar = <div className={classes.progressContainer}>
                <div className={classes.iconContainer} style={{backgroundColor: Colors.SUCCESS}}>
                    <CheckIcon fill={Colors.WHITE}/>
                </div>
            </div>;
        } else {
            progressBar =  <div className={classes.progressContainer}>
                <LinearProgress color="secondary" className={classes.progressBar} variant="determinate" value={operation.progress}/>
                <div className={classes.iconContainer} style={{backgroundColor: Colors.BLACKLIGHTEST}} onClick={() => onCancel(operation)}>
                    <CloseIcon fill={Colors.WHITE}/>
                </div>
            </div>;
        }

        return <div key={operation.id} className={classes.fileSnackbarContent}>
            {icon}
            {progressText}
            {progressBar}
        </div>
    }

    const buildPath = () => {
        const location = window.location;
        const isStorage = location && location.pathname &&  location.pathname.startsWith("/storage/search")

        if (isStorage) {
            return <Typography variant={"h3"} className={`${classes.breadCrumb}`} >
                {t("storage.searchResults")}
            </Typography> ;
        }

        let folders = [
            <Typography key={"path-start"} variant={"h3"} className={`${classes.breadCrumb} ${classes.clickable}`} onClick={
                () => path && path.length > 1 ? onFolderChange("") : onReloadPage()
            }>
                {t("storage.storage")}
            </Typography>
        ];

        if (path && path.length > 1) {
            let pathParts = path.split('/');

            if (pathParts.length > 2) {
                folders.push(
                    <ChevronRightIcon key={"path-start-next"} className={classes.breadCrumbIcon} fill={Colors.BLACKLIGHT}/>
                )
                folders.push(
                    <MoreHorizontalIcon key={"path-start-horizontal"} className={classes.breadCrumbIconMore} fill={Colors.BLACK}/>
                )
            }
            let relevantPaths = pathParts.slice(-2);
            relevantPaths.forEach( (path,index) => {
                let file = allFiles.find( (file) => file.id === path);
                if (file) {
                    folders.push(
                        <ChevronRightIcon key={"path-chevron-" + index} className={classes.breadCrumbIcon} fill={Colors.BLACKLIGHT}/>
                    )
                    folders.push(
                        <Typography key={"path-file-name-" + index} variant={"h3"} className={ (relevantPaths.length -1) > index ? `${classes.breadCrumb} ${classes.clickable}`: classes.breadCrumb}
                                    onClick={ (e) => (relevantPaths.length -1) > index ? onPathClick(file) : {} }
                        >
                            {file.name}
                        </Typography>
                    );

                }
            })
        }
        return folders;
    }

    const initialState = {
        mouseX: null,
        mouseY: null,
    };

    const [state, setState] = React.useState(initialState);
    const [showKey, setShowKey] = React.useState(null);

    const handleClick = (event, key) => {
        event.preventDefault();
        event.stopPropagation();

        if (showKey !== null) {
            handleClose();
        } else {
            setShowKey(key);
            setState({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            });
        }
    };

    const handleClose = () => {
        setShowKey(null);
        setState(initialState);
    };

    if (!validPath) {
       return (
           <div className={classes.noContent}>
                <img src={illustration} alt={t('common.illustration')} className={classes.illustration}/>
                <Typography variant="h2">{t('storage.folderDoesNotExist')}</Typography>
                <Typography variant="caption" className={classes.noContentUpload}>{t('storage.folderDoesNotExistDescription')}</Typography>
           </div>
       )
    }

    return (
        <Fragment>
            <div className={classes.fabContainer}>
                <div style={{width: '240px'}}/>
                <div
                    className={classes.sideSpacer}
                />
                <div className={classes.fabSizing}>
                    {
                        operations && operations.length > 0 && <div className={classes.fileSnackbarContainer}>
                            {
                                operations.map(
                                    (operation) => buildOperation(operation)
                                )
                            }
                        </div>
                    }

                    {

                        onAddFile && AUTHSTORAGE.hasPermission(Permission.UPLOADDOCUMENTS) ? (
                          <MoinFabMenu
                            options={addFileOptions()}
                            listItemClass={classes.fileAddListItem}
                            menuClass={classes.addFile}
                            style={ {
                                position: 'absolute',
                                right: 0,
                                marginLeft: '0'
                            }}
                        />
                       ) : null
                    }

                </div>

                <div
                    className={clsx(classes.sideSpacer, {
                        [classes.minWidthAnimation]: !!displayInfoFile,
                    })}
                />
            </div>

            <input ref={(fileInput) => fileInputField.current = fileInput} type="file" style={{display: 'none'}} multiple onChange={(e) => onAddFile && onUploadFiles([...e.target.files]) }/>
            {
                files.length === 0 && !loading &&
                    <div className={classes.storageWrap} >
                        <div className={classes.sideSpacer} />

                        <div className={classes.storageItemListWrap}>
                            {
                                buildPath()
                            }
                            <div className={classes.noContent}>

                                <img src={illustration} alt={t('common.illustration')} className={classes.illustration}/>
                                <Typography variant="h2">{t('storage.emptyFolder')}</Typography>
                                <Typography variant="caption" className={classes.noContentUpload}>{t('storage.uploadFile')}</Typography>
                                <MoinButton onClick={() => fileInputField.current.click()}>
                                    { t('storage.addFile') }
                                </MoinButton>
                            </div>
                        </div>
                        <div className={classes.sideSpacer} />
                    </div>
            }

            {
                (files.length > 0 || (files.length ===0 && loading) )  &&
                <MoinUpload isMultipleInput={true} onChangeFile={files => onAddFile && onUploadFiles([...files])} type={MediaContentType.ALL} disableClick={true}>
                   <div className={classes.storageWrap} >
                       <div className={classes.sideSpacer} />

                       <div ref={ (node) => tablePositionRef.current = node} className={classes.storageItemListWrap}>
                           {
                               buildPath()
                           }
                           <table className={classes.storageItemList} cellSpacing="0" ref={(node) => filesList.current = node}>
                               <thead>
                               <tr className={classes.headerRow}>
                                   <th colSpan="2" className={classes.titleName}  onClick={(_) => canSort && onChangeSort(StorageSortField.NAME) }>
                                       <Typography variant={"h5"}> {t('storage.name')} </Typography>
                                       {
                                           sortField.current === StorageSortField.NAME &&  canSort &&
                                           <div className={classes.storageItemListIcon}>
                                               {
                                                   sortDirection.current === SortDirections.ASCENDING
                                                       ? <ArrowUpIcon className={classes.chevronDownIcon}/>
                                                       : <ArrowDownIcon className={classes.chevronDownIcon}/>
                                               }
                                           </div>
                                       }
                                   </th>
                                   <th className={classes.titleUpdated} onClick={(_) => canSort && onChangeSort(StorageSortField.CHANGED) }>
                                       <Typography variant={"h5"}>{t('storage.lastChanged')}</Typography>
                                       {
                                           sortField.current === StorageSortField.CHANGED &&  canSort &&
                                           <div className={classes.storageItemListIcon}>
                                               {
                                                   sortDirection.current === SortDirections.ASCENDING
                                                       ? <ArrowUpIcon className={classes.chevronDownIcon}/>
                                                       : <ArrowDownIcon className={classes.chevronDownIcon}/>
                                               }
                                           </div>
                                       }
                                   </th>
                                   <th colSpan="2" className={classes.titleSize} onClick={(_) => canSort && onChangeSort(StorageSortField.SIZE) }>
                                       <Typography variant={"h5"}> {t('storage.fileSize')} </Typography>
                                       {
                                           sortField.current === StorageSortField.SIZE &&  canSort &&
                                           <div className={classes.storageItemListIcon}>
                                               {
                                                   sortDirection.current === SortDirections.ASCENDING
                                                       ? <ArrowUpIcon className={classes.chevronDownIcon}/>
                                                       : <ArrowDownIcon className={classes.chevronDownIcon}/>
                                               }
                                           </div>
                                       }
                                   </th>
                               </tr>
                               </thead>
                               <tbody>
                               {
                                   files.map( (file) =>
                                       <tr key={'file' + file.id}
                                           className={ selectedFile?.id === file.id ? classes.backgroundBlue : undefined}
                                           onContextMenu={(e) => handleClick(e, file.id)}
                                           onClick={() => onFileClick(file)}
                                       >
                                           <td className={classes.fileIcon}>
                                               {setFileTypeIcon(file)}
                                           </td>
                                           <td className={classes.fileName}>
                                               <Typography variant={"h2"}> {file.name} </Typography>
                                           </td>
                                           <td className={classes.titleUpdated}>
                                               <Typography variant={"caption"}>
                                                   {
                                                       file.uAt !== undefined
                                                           ? timestampToDate(file.uAt)
                                                           : timestampToDate(file.cAt)
                                                   }
                                               </Typography>
                                           </td>
                                           <td>
                                               <Typography variant={"caption"}>{
                                                   file.size === undefined
                                                       ? <span className={classes.valueSizeNone}>-</span>
                                                       : humanFileSize(file.size)
                                               }
                                               </Typography></td>
                                           <td>
                                               <div>
                                                   <div  className={classes.showMore} onClick={(e) => handleClick(e, file.id)}>
                                                       <MoreHorizontalIcon className={classes.moreHorizontalIcon}/>
                                                   </div>
                                                   {
                                                       showKey === file.id &&

                                                       <MoinSimpleMenuContext
                                                           options={ addContentOptions(file)}
                                                           anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
                                                           transformOrigin={{vertical: 'top', horizontal: 'center',}}
                                                           paperStyle={ {"width": '270px'}}
                                                           open={state.mouseY !== null}
                                                           onClose={handleClose}
                                                           anchorReference="anchorPosition"
                                                           handleClose={handleClose}
                                                           anchorPosition={
                                                               state.mouseY !== null && state.mouseX !== null
                                                                   ? { top: state.mouseY, left: state.mouseX }
                                                                   : undefined
                                                           }
                                                           className={classes.fileOptions}
                                                           listItemClasses={classes.fileListItem}>
                                                       </MoinSimpleMenuContext>
                                                   }
                                               </div>
                                           </td>
                                       </tr>
                                   )
                               }
                               </tbody>
                           </table>


                       </div>
                       <div
                           className={clsx(classes.sideSpacer, {
                               [classes.minWidthAnimation]: !!displayInfoFile,
                           })}
                       />
                   </div>
                </MoinUpload>
            }

            {
                loading && files.length > 0 &&
                <div className="moin-circular-progress-container">
                    <CircularProgress />
                </div>
            }

            {
                showAddFolder &&

                <MoinDialog
                    id={'addFolderDialog'}
                    key={"newFolderDialog"}
                    title={t('storage.newFolder')}
                    abortButtonText={t('storage.cancel')}
                    abortButtonCallback={() => setShowAddFolder(false)}
                    continueButtonText={t('storage.create')}
                    continueButtonCallback={ onAddFolder}
                    className={classes.addFolderDialog}
                >
                    <MoinTextField
                        variant="outlined"
                        margin="normal"
                        min={3}
                        required
                        fullWidth
                        autoFocus
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                    />

                </MoinDialog>
            }

            {
                changedFile &&

                <MoinDialog
                    id={'2'}
                    key={"changeFileDialog"}
                    title={t('storage.renameFile')}
                    abortButtonText={t('storage.cancel')}
                    abortButtonCallback={() => setChangedFile(undefined)}
                    continueButtonText={t('storage.rename')}
                    continueButtonCallback={ onRename}
                    className={classes.addFolderDialog}
                >
                    <div>
                        <MoinTextField
                            variant="outlined"
                            margin="normal"
                            min={3}
                            required
                            fullWidth
                            autoFocus
                            value={changedName}
                            onChange={(e) => setChangedName(e.target.value)}
                        />
                    </div>
                </MoinDialog>
            }

            {
                deletedFile &&

                <MoinDialog
                    id={'deleteStorageDialog'}
                    title={deletedFile.type === StorageFileType.FOLDER ? t('storage.deleteFolder') : t('storage.deleteFile')}
                    abortButtonText={t('storage.cancel')}
                    abortButtonCallback={() => setDeletedFile(undefined)}
                    continueButtonText={deletedFile.type === StorageFileType.FOLDER ? t('storage.deleteFolder') :t('storage.deleteFile')}
                    continueButtonCallback={ onDelete}
                    className={classes.addFolderDialog}
                >
                    <div>
                        <Typography variant={"caption"} className={classes.deleteMessage}>{ deletedFile.type === StorageFileType.FOLDER ? t('storage.deleteMessageFolder') : t('storage.deleteFileMessage')}</Typography>
                    </div>
                </MoinDialog>
            }

            {
                displayInfoFile &&
                    <StorageInfo file={displayInfoFile} onClose={() => { setDisplayInfoFile(undefined); setShowInfo(false);}}/>
            }

            {
                showFilePreview && displayPreviewFile.type === StorageFileType.IMAGE && displayPreviewFile.fullPath &&
                    <Dialog onClose={onPreviewClose} open={true} className={classes.previewDialog}>
                        <FireStorageImage src={displayPreviewFile.fullPath} />
                    </Dialog>
            }

            {
                showFilePreview && displayPreviewFile.type === StorageFileType.VIDEO && displayPreviewFile.fullPath &&
                    <Dialog onClose={onPreviewClose} open={true} className={classes.previewDialog}>
                        <FireStorageVideo src={displayPreviewFile.fullPath}/>
                    </Dialog>
            }

            {
                showFilePreview &&
                (
                    displayPreviewFile.type !== StorageFileType.IMAGE &&
                    displayPreviewFile.type !== StorageFileType.VIDEO &&
                    displayPreviewFile.type !== StorageFileType.FOLDER
                ) &&

                <MoinDialog
                    abortButtonText={t('storage.cancel')}
                    abortButtonCallback={() => setShowFilePreview(false)}
                    continueButtonText={t('storage.download')}
                    continueButtonCallback={onPreviewDownload}
                    className={classes.filePreviewDialog}
                >

{/* file preview */}

                    <div className={classes.filePreviewIcon}>
                        {
                            setFileTypeIcon(
                                displayPreviewFile,
                                <>
                                    { selectedFileLoading ?
                                        (
                                            <>
                                                <div className="moin-circular-progress-container">
                                                    <CircularProgress style={{width: "auto", height: "auto"}} />
                                                </div>
                                            </>
                                        )
                                        : 
                                        (
                                            <>
                                            <PDFIcon fill={Colors.ERROR}/>
                                            <div className={classes.fileNoPreviewText}>
                                                <Typography variant={"h2"}>{t('storage.noPreview')}</Typography>
                                                <Typography variant={"caption"}>{t('storage.cannotDisplay')}</Typography>
                                            </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                    {
                        (!displayPreviewFile || displayPreviewFile.type !== StorageFileType.PDF)
                        && <div className={classes.fileNoPreviewText}>
                            <Typography variant={"h2"}>{t('storage.noPreview')}</Typography>
                            <Typography variant={"caption"}>{t('storage.cannotDisplay')}</Typography>
                        </div>
                    }

                </MoinDialog>
            }
        </Fragment>
    );
};

export default Storage;
