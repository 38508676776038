import ArticleCreateUpdate from "./ArticleCreateUpdate";
import React, {useEffect} from "react";
import ModelManager from "../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import Article from "../../utils/models/Article";
import {ContentType} from "../../utils/enums/ContentType";
import CircularProgress from "@material-ui/core/CircularProgress";
import Survey from "../../utils/models/Survey";
import {addModelInstanceDispatch, deleteModelInstanceDispatch} from "../../utils/store/actions";
import {SurveyType} from "../../utils/enums/SurveyType";
import * as httpClient from "../../utils/httpclient/HttpClient";
import parameters from "../../config/parameters.json";
import {generateRandomId} from "../../utils/helper/RandomHelper";
import SUCCESSNOTIFICATIONMANAGER from "../../components/success/SuccessNotificationManager";
import SuccessMessage from "../../utils/models/SuccessMessage";
import {SuccessType} from "../../utils/enums/SuccessType";
import {FireAuth, FireTimestamp} from "../../api/FirebaseService";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import {getArticleTitlePath} from "../../utils/helper/ImageHelper";
import ApiClient from "../../api/ApiClient";
import User from "../../utils/models/User";

/**
 * @param {String} id
 * @param {Article} article
 * @param {Survey[]} allSurveys
 * @param {User} authUser
 * @param {Function} addModelInstanceDispatch
 * @param {Function} deleteModelInstanceDispatch
 * @param {String} originPath
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleUpdateContainer = ({id, article, allSurveys, authUser, addModelInstanceDispatch, deleteModelInstanceDispatch, originPath}) => {
    const firebaseStorageManager = new FirebaseStorageManager();
    const surveys = allSurveys.filter(survey => survey.aId === id);

    useEffect(() => {
        if(!article) {
            ModelManager.get({model: Article, id: id}).then(loadedArticle => loadSurveysIfNeeded(loadedArticle));
        } else {
            loadSurveysIfNeeded();
        }
    }, [article]);

    const loadSurveysIfNeeded = (loadedArticle) => {
        const loadArticle = Object.assign(new Article(),article ?? loadedArticle );
        if(!!loadArticle) {
            loadArticle.content.forEach(con => {
                const surveys = allSurveys.filter(survey => survey.aId === id);
                if(con.type === ContentType.SURVEY && !surveys.find(sur => sur.aId === loadArticle.id)) {
                    ModelManager.get({model: Survey, id: con.surveyId, stopEvents: true}).then(async (survey) => {
                        if(survey.type === SurveyType.FREEANSWER) {
                            const authUserAnswer = await ModelManager.get({id: AUTHSTORAGE.getUserId(), resource: "/client/" + AUTHSTORAGE.getClientId() + "/survey/" + con.surveyId + "/answer", stopEvents: true});
                            if(!!authUserAnswer) {
                                survey.authUserAnswer = authUserAnswer['text'];
                            }
                        } else {
                            const authUserVotes = await ModelManager.get({id: AUTHSTORAGE.getUserId(), resource: "/client/" + AUTHSTORAGE.getClientId() + "/survey/" + con.surveyId + "/vote", stopEvents: true});
                            if(!!authUserVotes) {
                                survey.authUserVotes = authUserVotes['votes'];
                            }
                        }

                        addModelInstanceDispatch(Survey, survey);
                    });
                }
            });
        }
    };


    /**
     * @param {Article} article
     * @param {File} titleImage
     */
    const uploadMedia = async (article, titleImage) => {
        const mediaFutures = [];

        if(!!titleImage) {
            let titleImageUploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article/${article.id}/title_image`;
            mediaFutures.push(firebaseStorageManager.uploadImage(titleImage, titleImageUploadPath, 2048 , 1));
            article.hTI = true;

            let fireStorageUrl = new FireStorageUrl();
            fireStorageUrl.id = getArticleTitlePath(article);
            deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
        }

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];
            if(content.type === ContentType.MEDIA) {
                if(!!content.image && !!content.image.name) {
                    const uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article/${article.id}/${generateRandomId()}`;
                    mediaFutures.push(firebaseStorageManager.uploadImage(content.image, uploadPath, 1200 , 1));
                    content.image = uploadPath.substring(1).replace(/\//g, '%2F');
                }
            } else if(content.type === ContentType.PDF) {
                const uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article/${article.id}/${generateRandomId()}`;
                if(!!content.path && !!content.path.name) {
                    mediaFutures.push(firebaseStorageManager.uploadFile(content.path, uploadPath));
                    content.path = uploadPath.substring(1).replace(/\//g, '%2F');
                }
            }
        }

        await Promise.all(mediaFutures);
    }

    /**
     * @param {Article} article
     * @param {Survey[]} surveys
     * @param {Survey[]} oldSurveys
     */
    const createSurveys = async (article, surveys) => {
        const surveyFutures = [];

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];
            if(content.type === ContentType.SURVEY) {
                let survey = surveys[content.surveyId];

                if (survey && !!survey.question && survey.options && Object.keys(survey.options).length > 0) {
                    survey.total = 0;

                    const surveyData = {
                        aId: article.id,
                        question: survey.question,
                        total: 0,
                        type: survey.type,
                        options: {}
                    };

                    if(survey.type !== SurveyType.FREEANSWER) {
                        surveyData.options = Object.assign({}, Object.values(survey.options).map(option => ({text: option.text, count: 0})));
                    } else {
                        surveyData.aP = survey.aP ?? "Privacy.PRIVATE";
                    }

                    surveyFutures.push(ModelManager.update({modelInstance: survey, id: survey.id, customData: surveyData, useSet: true}));
                }
            }
        }

        await Promise.all(surveyFutures);
    };


    /**
     * @param {Article} article
     * @returns {Promise<boolean>}
     */
    const compressVideos = async (article) => {
        let uploadedVideos = false;
        let convertFutures = [];

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];

            if(content.type === ContentType.MEDIA && !!content.uploadId) {
                uploadedVideos = true;

                let formData = new FormData();
                formData.append('uploadId', ""+content.uploadId);

                let httpClientInstance = new httpClient.default(parameters.backendVideoUrl, {
                    headers: {
                        'Authorization': `Bearer ${await FireAuth.currentUser.getIdToken()}`
                    }
                });

                let request = httpClientInstance.post('/convert', formData);

                convertFutures.push(request.send());
            }
        }

        await Promise.all(convertFutures);
        return uploadedVideos;
    };

    /**
     * @param {Article} copyArticle
     * @param {File} titleImage
     * @param {string[]} oldMediaUrls
     * @param {Survey[]} newSurveys
     * @param {Survey[]} oldSurveys
     * @param {boolean} articleTitleImageChanged
     * @returns {Promise<void>}
     */
    const createNewArticle = async (copyArticle, titleImage, distributor, surveys) => {
        let article = new Article();
        article = Object.assign(article, {...copyArticle, id: article.id});
        article.cName = authUser.fullName;
        article.copyFromUid = copyArticle.cUid;
        article.copyFromArticleId = copyArticle.id;
        article.cUid = AUTHSTORAGE.getUserId();
        article.heart = 0;
        article.cCount = 0;
        article.reactions = undefined;
        article.receivers = distributor.count;
        article.stream = true;
        article.dis = [distributor.id];
        article.newFormat = false;

        if(distributor.isPublic) {
            article.public = true;
        }

        for(let i = 0; i < article.content.length; i++) {
            const content = article.content[i];
            if(content.type === ContentType.SURVEY) {
                let oldSurveyId = content.surveyId;
                let survey = new Survey();
                content.surveyId = survey.id;
                surveys[content.surveyId] = Object.assign(new Survey(), surveys[oldSurveyId], {id: survey.id});
            }
        }

        await uploadMedia(article, titleImage);

        console.log("article");
        console.log(article);
        // Create Article
        await ModelManager.add({modelInstance: article});
        let uploadedVideos = await compressVideos(article);
        await createSurveys(article, surveys);

        // Link Article to AuthUser
        let articleLinkData = {
            "cAt": FireTimestamp(),
            "dis": article.dis
        };

        await ModelManager.update({customData: articleLinkData, resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/article`, id: article.id, useSet: true});

        // Link article to Distributor
        let body = {'articleId': article.id};
        let request = await ApiClient.createRequest('/linkArticle', body);
        request.send().then(() => {
            SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.ARTICLEPUBLISHED, true));
        });

        if(uploadedVideos) {
            ModelManager.subscribeDoc({model: Article, id: article.id, extraProps: {completionCheck: true}});
        }
    };

    if(!article) {
        return (
            <div className="moin-circular-progress-container">
                <CircularProgress />
            </div>
        );
    }

    return <ArticleCreateUpdate saveArticle={createNewArticle} initialArticle={article} existingSurveys={surveys}  originPath={originPath} isCopy={true}/>
}
const mapState = (state, ownProps) => ({
    article: getModelInstance(state, Article, ownProps.id),
    allSurveys: getModelInstances(state, Survey),
    authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
});

const mapAction = {
    addModelInstanceDispatch,
    deleteModelInstanceDispatch
};

export default connect(mapState, mapAction)(ArticleUpdateContainer);
