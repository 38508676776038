class LoginHelper
{
    static domain = 'moin.app';
    static getDomainFromWorkspace = (workspace) => workspace+"."+LoginHelper.domain;
    static getFromWorkspace = (username, workspace) => username.trim()+'@'+LoginHelper.getDomainFromWorkspace(workspace.trim());

    static getLoginFromPhone = (workspace,prefix,mobile) => {
        prefix = prefix?.replaceAll(/[^0-9]/g, "") ?? "49";
        mobile = mobile.replaceAll(/[^0-9]/g, "");

        if (mobile.startsWith('00')) {
            mobile = mobile.substring(2, mobile.length);
        }

        if (mobile.startsWith(prefix) && mobile.length > 3) {
            mobile = mobile.substring(prefix.length, mobile.length);
        }

        if (mobile.startsWith('0')) {
            // user germany as default if no prefix is known
            mobile = mobile.substring(1, mobile.length);
        }
        mobile = prefix + mobile;
        return mobile + '@' + workspace + '.' + LoginHelper.domain;
    }
}

export default LoginHelper;