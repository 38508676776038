
class AuthStorage {
    #_userId;
    #_isExternal;
    #_clientId;
    #_client;
    #_permissions;
    #_roles;
    #_privateKey;
    #_rawPrivateKey;
    #_lang;
    #_lastNotificationOpen;
    #_browserEncryptionKey;
    #_latestOpening;
    #_currentOpening;
    #_penultimateOpeningDay;
    #_hideEvents;

    setAuth = (client, userId, external,  permissions, lang, roles) => {
        this.#_userId = userId;
        this.#_clientId = client?.id;
        this.#_client = client;
        this.#_isExternal = external;
        this.#_permissions = permissions;
        this.#_lang = lang;
        this.#_roles = roles;
    };

    getUserId() {
        return this.#_userId;
    }

    isExternal() {
        return this.#_isExternal ?? false;
    }

    getClientId() {
        return this.#_clientId;
    }

    getClient() {
        return this.#_client;
    }

    setPrivateKey = (privateKey) => {
        this.#_privateKey = privateKey;
    }

    getPrivateKey() {
        return this.#_privateKey;
    }

    setRawPrivateKey = (privateKey) => {
        this.#_rawPrivateKey = privateKey;
    }

    getRawPrivateKey() {
        return this.#_rawPrivateKey;
    }

    getLanguage() {
        return this.#_lang;
    }

    setLanguage(lang) {
        this.#_lang = lang;
    }

    getLastNotificationOpen() {
        return this.#_lastNotificationOpen;
    }

    setLastNotificationOpen(lastOpen) {
        this.#_lastNotificationOpen = lastOpen;
    }

    getBrowserEncryptionKey() {
        return this.#_browserEncryptionKey;
    }

    setBrowserEncryptionKey(key) {
        this.#_browserEncryptionKey = key;
    }

    setPermissions(permissions) {
        this.#_permissions = permissions;
    }

    setRoles(roles) {
        this.#_roles = roles;
    }

    getLatestOpening() {
        return this.#_latestOpening;
    }

    setLatestOpening(latestOpening) {
        this.#_latestOpening = latestOpening;
    }

    getCurrentOpening() {
        return this.#_currentOpening;
    }

    setCurrentOpening(currentOpening) {
        this.#_currentOpening = currentOpening;
    }

    getPenultimateOpeningDay() {
        return this.#_penultimateOpeningDay;
    }

    setPenultimateOpeningDay(latestOpening) {
        this.#_penultimateOpeningDay = latestOpening;
    }

    getHideEvents() {
        return this.#_hideEvents;
    }

    setHideEvents(lastChange) {
        this.#_hideEvents = lastChange;
    }

    /**
     *
     * @param {String} permission
     * @returns {boolean}
     */
    hasPermission(permission) {
        return !!this.#_permissions && this.#_permissions.includes(permission);
    }

    /**
     *
     * @param {String} role
     * @returns {boolean}
     */
    hasRole(role) {
        return !!this.#_roles && this.#_roles.includes(role);
    }

    reset() {
        this.#_userId = undefined;
        this.#_clientId = undefined;
        this.#_client = undefined;
        this.#_privateKey = undefined;
        this.#_permissions = undefined;
        this.#_browserEncryptionKey = undefined;
        this.#_latestOpening = undefined;
        this.#_penultimateOpeningDay = undefined;
        this.#_hideEvents = undefined;
    }
}

const AUTHSTORAGE = new AuthStorage();

export default AUTHSTORAGE;
