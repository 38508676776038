import React, {useState} from 'react';
import {connect} from "react-redux";
import Tasks from "./Tasks";
import Task from "../../utils/models/Task";
import TaskActionContainer from "./TasksActionContainer";
import {getModelInstances} from "../../utils/store/selectors";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";

const TasksContainer = ({initialOpen,tasks}) => {
    let relevantTasks = tasks.filter( (task) => task.relevant && task.relevant.includes(AUTHSTORAGE.getUserId()));

    return  <TaskActionContainer>
        {
            ({onCreate, onDelete, onUndoDelete, onTitleChange,onDescriptionChange,onRepetitionChange,onComplete, onAssign, removeAssignee, onAddNotification, onRemoveNotification, setDueDate, onAddImages, onRemoveImage,onAddDocuments,onRemoveDocument}) =>
                <Tasks
                    initialOpen={initialOpen}
                    onCreate={onCreate}
                    onDelete={onDelete}
                    onUndoDelete={onUndoDelete}
                    onTitleChange={onTitleChange}
                    onDescriptionChange={onDescriptionChange}
                    onRepetitionChange={onRepetitionChange}
                    onComplete={onComplete}
                    onAssign={onAssign}
                    onAddImages={onAddImages}
                    onRemoveImage={onRemoveImage}
                    onAddDocuments={onAddDocuments}
                    onRemoveDocument={onRemoveDocument}
                    onRemoveNotification={onRemoveNotification}
                    onAddNotification={onAddNotification}
                    setDueDate={setDueDate}
                    removeAssignee={removeAssignee}
                    tasks={relevantTasks}
                />
        }
    </TaskActionContainer>
};

const mapState = (state) => ({
    tasks: getModelInstances(state,Task)
});

export default connect(mapState)(TasksContainer);
