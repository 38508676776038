import {id, model, prop} from "../manager/decorators";

@model("/client/:client/role")
class Role
{
    @id(null)
    id;

    @prop()
    name;
}

export default Role;