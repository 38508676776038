import React, {useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Colors} from "../helper/ColorHelper";
import {Link} from "react-router-dom";
import * as ROUTES from "../../Routes";
import {CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {getTeaserText} from "../../utils/helper/TextHelper";
import ListItem from "@material-ui/core/ListItem";
import MoinLinkifiedText from "./MoinLinkifiedText";

const useStyles = makeStyles((theme) => ({
    root: {
        color: Colors.BRANDSECONDARY,
        cursor: "pointer"
    },
}));


const MoinHingedText =  ({text, charCount = 240, more = false}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    const [showMore,setShowMore] = useState(more);

    let hingedText = text;
    if (!showMore) {
        hingedText = getTeaserText(text, charCount );
    }

    return (<span>
            <Typography variant="body1"><MoinLinkifiedText text={hingedText} /></Typography>
            {
                text.length > charCount &&
                <Typography variant="body2" className={classes.root} onClick={() => setShowMore(!more)}>
                    { showMore ? t('common.showMore') : t('common.showLess')}
                </Typography>
            }
        </span>
    )
};

export default MoinHingedText;
