import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import {ListItem} from "@material-ui/core";
import {
    MoinCard,
    MoinCardContent,
    MoinCardContentTitle,
    MoinCardHeader,
    MoinCardHeaderImage
} from "../../components/base/MoinCard";
import User from "../../utils/models/User";
import {connect} from "react-redux";
import * as ROUTES from "../../Routes";
import {Link} from "react-router-dom";
import {Colors} from "../../components/helper/ColorHelper";
import FireStorageImage from "../../components/base/FirestoreImage";
import {getFormattedTimeSpan, getStartToEnd} from "../../utils/helper/TextHelper";
import {getModelInstance} from "../../utils/store/selectors";
import MoinSampleUsersList from "../../components/base/MoinSampleUsersList";
import MoinUserListItem from "../../components/user/MoinUserListItem";
import MoinButton from "../../components/base/MoinButton";
import CardHeader from "@material-ui/core/CardHeader";
import EventMenu from "../../components/event/EventMenu";
import Grid from "@material-ui/core/Grid";
import GoogleMapsMap from "../../components/base/GoogleMapsMap";

import LocationIcon from '../../assets/icons/location.svg';
import ClockIcon from '../../assets/icons/clock.svg';
import InfoIcon from '../../assets/icons/info.svg';
import ChevronRightIcon from '../../assets/icons/chevron-right.svg';
import MoinLinkifiedText from "../../components/base/MoinLinkifiedText";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";

const useStyles = makeStyles((theme) => ({
    cardContentRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center",
        color: Colors.BLACKLIGHT,
        '& svg': {
            marginRight: '6px'
        },
        marginBottom: '2px'
    },
    chevron: {
        marginLeft: "10px",
        fill: Colors.BLACK,
        fontSize: "1.5rem",
    },
    cardContentTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center"
    },
    headerImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: "cover"
    },
    date: {
        color: Colors.BRANDPRIMARY,
        marginBottom: "12px"
    },
    moreButton: {
        marginTop: "24px"
    },
    description: {
        padding: '26px 8px',
        width: "100%",
        height: "100%"
    },
    userList: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px"
    },
    info: {
        marginBottom: "20px"
    },
    infoDescriptionAgenda: {
        '& p': {
            whiteSpace: 'break-spaces'
        }
    },
    link: {
        width: "100%",
        textDecoration: "none"
    },
    tag: {
        padding: "0px 8px",
        borderRadius: "4px",
        margin: "0px 4px",
        backgroundColor: Colors.BLUELIGHT,
        color: Colors.BLACK,
        font: theme.typography.copySmall,
        zIndex: 100,
        height: "1.5rem",
        position: "absolute",
        bottom: "16px"
    }
}));

/**
 * Event detail page
 *
 * @param {Event} event
 * @param {Distributor} distributor
 * @param {Array<User>} members
 * @param {Array<User>} contactPartners
 * @param {User} creator
 * @param {boolean} isAuthParticipating
 * @param {Function} onParticipationChange
 * @returns {JSX.Element}
 * @constructor
 */
const EventShow = ({event, distributor, members, creator, contactPartners, isAuthParticipating, onParticipationChange}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    let memberCount = members.length > 1 ? members.length : event?.count;

    const renderContact = () => {
        if(contactPartners.length === 0) {
            return (
                <>
                    <MoinCardContentTitle variant="h4">{t('common.contact').toUpperCase()}</MoinCardContentTitle>
                    {
                        //check is needed because the creator is initially undefined
                        !!creator &&
                        <Link to={ROUTES.userShow.replace(':id', creator.id)} className={classes.link}>
                            <MoinUserListItem user={creator} divider={false} />
                        </Link>
                    }
                </>
            );
        } else {
            return (
                <>
                    <MoinCardContentTitle variant="h4">{t('event.contactPartners').toUpperCase()}</MoinCardContentTitle>
                    {
                        contactPartners.map(user => (
                            <Link key={'contact-partner-link-' + user.id} to={ROUTES.userShow.replace(':id', user.id)} className={classes.link}>
                                <MoinUserListItem user={user} divider={false} />
                            </Link>
                        ))
                    }
                </>
            );
        }
    };

    return (
        <MoinCard>

            <MoinCardHeaderImage showBookmark={event.isAuthUserParticipating}>
                {
                    distributor &&
                    <div className={classes.tag}>
                        { distributor.name }
                    </div>
                }
                <FireStorageImage
                    lightboxEnabled={true}
                    fontSize={64}
                    className={classes.headerImage}
                    src={event.avatar}
                    alt={event.title}
                    backgroundColor={Colors.identifierAsColor(event.id)}
                />
            </MoinCardHeaderImage>

            <MoinCardHeader>
                <CardHeader
                    title={
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h4" className={classes.date}>{getStartToEnd(event.start, event.end).toUpperCase()}</Typography>
                            <Typography variant="h3">{event.title}</Typography>
                        </Grid>
                    }

                    subheader={
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {
                                event.location &&
                                <div className={classes.cardContentRow}>
                                    <LocationIcon fill={Colors.BLACKLIGHT} style={{height:"1rem"}}/>
                                    <Typography variant="caption">
                                        {
                                            !!event.geolocation
                                                ? event.location
                                                : <MoinLinkifiedText text={event.location} />
                                        }
                                    </Typography>
                                </div>
                            }
                            {
                                event.room  &&
                                <div className={classes.cardContentRow}>
                                    <InfoIcon fill={Colors.BLACKLIGHT} style={{height:"1rem"}} />
                                    <Typography variant="caption">
                                        <MoinLinkifiedText text={event.room} />
                                    </Typography>
                                </div>
                            }
                            <div className={classes.cardContentRow}>
                                <ClockIcon fill={Colors.BLACKLIGHT}  style={{height:"1rem"}} />
                                <Typography variant="caption">{getFormattedTimeSpan(event.start, event.end) + " " + t('common.oclock')}</Typography>
                            </div>

                            <MoinButton
                                className={classes.moreButton}
                                onClick={() => onParticipationChange()}
                                color={isAuthParticipating ? 'secondary' : 'default'}
                                size="small"
                            >
                                {isAuthParticipating ? t('event.cancel') : t('event.participate')}
                            </MoinButton>
                        </Grid>
                    }

                    action={
                         <EventMenu id={event.id + '_context_menu'} event={event}/>
                    }
                />
            </MoinCardHeader>
            <MoinCardContent>
                <div className={classes.description}>
                    {
                        !!event.description &&
                            <div className={`${classes.info} ${classes.infoDescriptionAgenda}`}>
                                <MoinCardContentTitle variant="h4">{t('event.info')}</MoinCardContentTitle>
                                <Typography><MoinLinkifiedText text={event.description} /></Typography>
                            </div>
                    }
                    {
                        !!event.agenda &&
                        <div className={`${classes.info} ${classes.infoDescriptionAgenda}`}>
                            <MoinCardContentTitle variant="h4">{t('event.agenda')}</MoinCardContentTitle>
                            <Typography><MoinLinkifiedText text={event.agenda} /></Typography>
                        </div>
                    }

                    {
                        members && members.length > 0 &&
                        <>
                            <MoinCardContentTitle variant="h4">{t('event.member').toUpperCase() + ' (' + memberCount.toString() + ')'}</MoinCardContentTitle>
                            <Link to={ROUTES.eventParticipantsShow.replace(":id", event.id)} className={classes.link}>
                                <ListItem button className={classes.userList}>
                                    <MoinSampleUsersList ids={members.map((user) => user.uid)}  radius={24}/>
                                    <ChevronRightIcon className={classes.chevron} />
                                </ListItem>
                            </Link>
                        </>
                    }

                    {
                        !!event.geolocation &&
                            <div className={classes.info}>
                                <MoinCardContentTitle variant="h4">{t('event.directions').toUpperCase()}</MoinCardContentTitle>
                                <GoogleMapsMap
                                    title={event.title}
                                    lat={event.geolocation.latitude}
                                    long={event.geolocation.longitude}
                                />
                            </div>
                    }

                    {
                        renderContact()
                    }
                </div>
            </MoinCardContent>
        </MoinCard>
    );
}


const mapState = (state, ownProps) => ({
    creator: getModelInstance(state, User, ownProps.event.cUid),
});

export default connect(mapState)(EventShow);
