import {id, model, prop} from "../manager/decorators";

@model()
class SurveyVote {

    @id()
    id;

    @prop({
        type: "array",
        typeOptions: {
            entry: "number"
        }
    })
    votes;

    surveyId;
}

export default SurveyVote;
