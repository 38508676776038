import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import Storage from "./Storage";
import StorageFile from "../../utils/models/StorageFile";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import { v4 as uuid } from 'uuid';
import {
    deleteModelInstance,
    deleteModelInstancesDispatch, deleteModelInstancesWhere,
    updateModelInstance, updateModelInstances,
} from "../../utils/store/actions";
import {STORAGECLIENT} from "../../utils/storage/StorageClient";
import {SortDirections, StorageSortField} from "../../utils/enums/StorageSort";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";

const StorageSearchContainer = ({query}) => {
    const firebaseStorageManager = new FirebaseStorageManager();
    let loading = useRef(true);
    let hasMore = useRef(true);
    let lastRequest = useRef(0);
    const [files, setFiles] = useState([]);
    const [rebuild, setRebuild] = useState(true);
    const [redirectToPath, setRedirectToPath] = useState();

    useEffect(() => {
        searchFiles({});
    }, []);


    const downloadWithProgress = async ({id, path, name, onProgress, onReady, isCanceled}) => {
        const fullPath = `/client/${AUTHSTORAGE.getClientId()}/storage/${path}/${id}`;
        await firebaseStorageManager.downloadWithProgress(fullPath, name, onProgress, onReady, isCanceled );
    }


    const renameFile = async ({file, name}) => {
        STORAGECLIENT.renameFile(file, name);

        let storageFile = Object.assign(new StorageFile(),file,{name: name, uAt: new Date()});
        if (name.includes(query) ) {
            updateModelInstance(StorageFile,storageFile);
            let newFiles = [...files];
            let index = newFiles.findIndex( element => element.id === file.id);
            if (index >= 0) {
                newFiles[index] = storageFile;
                setFiles(newFiles);
            }

        } else {
            deleteModelInstance(StorageFile,storageFile);
        }
    }


    const deleteFile = async ({id, path, type}) => {
       let success =  await STORAGECLIENT.deleteFile(id, path, type);

       if (success) {
           deleteModelInstance(StorageFile,
               Object.assign(new StorageFile(), {
                   id: id
               })
           );
       }
    }

    const searchFiles = async ({ from =0}) => {
        let requestTime = Date.now()
        lastRequest.current = requestTime;

        if (hasMore.current) {
            loading.current=true;
            setRebuild((old) => !old);
            let fileData = await STORAGECLIENT.searchFiles(query, 25, from);

            if (requestTime === lastRequest.current) {
                loading.current=false;
                hasMore.current = fileData?.length > 0;
                setFiles( [...files, ...fileData]);
            }
        }

    }

    const onFolderChange = (path) => {
        setRedirectToPath(path.replaceAll("/","!!"));
    }

    if(redirectToPath) {
        return (
            <Redirect push to={ROUTES.storageWithPath.replace(':query', redirectToPath)}/>
        );
    }

    return  <Storage loading={loading.current} files={files}  onDeleteFile={deleteFile} onFolderChange={onFolderChange} onRenameFile={renameFile} onDownloadFile={downloadWithProgress} loadFiles={searchFiles} canSort={false} />
};

const mapAction = {
    deleteModelInstancesDispatch,
};

export default connect(null,mapAction)(StorageSearchContainer);
