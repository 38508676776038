import React, {Fragment, useState, useRef} from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    optionalRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '8px',
        marginRight: "16px"
    },
    character: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '16px',
        marginBottom: '8px',
        color: Colors.BLACKLIGHTEST
    },
    caption: {
        marginLeft: "16px",
        marginRight: "16px",
        marginBottom: '8px',
    }
}));

/**
 * Moin! themed text input
 *
 * @param className
 * @param {String} Title label that is displayed on the top left of the input
 * @param {String} value
 * @param {String} caption
 * @param {Function} onChange
 * @param {boolean} [isOptional=false] If set to true, an optional label is displayed on the top right of the input
 * @param {boolean} [multiline=false] If set to true, than an text area will be created
 * @param {number} [rows=5] if multiline is true set number of default rows
 * @param {number} [rowsMax=5] if multiline is true set number of max rows
 * @param {number} maxCharacters Max amount of fillable characters
 * @param {boolean} [showCharacterCount=true] Toggles the dynamic character count label on the bottom right of the input
 * @param textFieldRef
 * @param {boolean} executeScroll
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <MoinTextField
     className={classes.input}
     title={t('user.position')}
     maxCharacters={500}
     value={position}
     onChange={e => setPosition(e.target.value)}
     />
 */
const MoinTextField = ({ className, inputClassName, title, caption,  value, onChange, textFieldRef, multiline=false, rowsMax=5, rows=5, isOptional = false,maxCharacters, showCharacterCount = true, error, min, max, executeScroll, ...rest}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const myRef = useRef(null);

    if (executeScroll) {
        // - 100 offset because it's not in view with scrollintoview function
        const y = myRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    const [characterCount, setCharacterCount] = useState(!!value ? value.length : 0);

    return (
        <Fragment >
            {
                !!title &&
                <div className={classes.optionalRow}>
                    <Typography variant={'h2'}>{title.toUpperCase()}</Typography>
                    {isOptional && <Typography variant={'caption'}>{t('common.optional')}</Typography>}
                </div>
            }
            <TextField
                ref={myRef}
                inputRef={textFieldRef}
                size="small"
                variant="outlined"
                margin="normal"
                fullWidth
                multiline={multiline}
                maxRows={multiline ? rowsMax : undefined}
                minRows={multiline ? rows : undefined}
                autoFocus
                className={clsx(classes.root,className)}
                onChange={(e) => {setCharacterCount(e.target.value.length); onChange(e) }}
                inputProps={{ maxLength: maxCharacters, className: inputClassName, min: min, max: max }}
                value={value}
                error={ !!error}
                style={ (caption || (maxCharacters && showCharacterCount)) ? { marginBottom: "4px"} : {}}
                helperText={ !!error && error}
                {...rest}
            />
            {
                !!maxCharacters && showCharacterCount && <div className={classes.character}><Typography variant={'caption'}>{characterCount + '/' + maxCharacters}</Typography> </div>
            }
            {
                !!caption &&
                <Typography className={classes.caption} variant={'caption'}>{caption}</Typography>
            }
        </Fragment>
    );
}

export default MoinTextField;
