import * as forge from "node-forge";

export const createSymmetricKey= ({length = 32}) => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let key = "";
    for (let i =0; i< length; i++ ) {
        key += chars[getRandomInt(chars.length)];
    }
    return key;
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export const stringToHash = (str) => {
    let hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
}

export const decryptLocalStorageData = (key, iv, data) => {
    if (!!key && !!iv && !!data) {
        let byteKey = forge.util.decode64(key);
        let byteIV = forge.util.decode64(iv);
        let byteData = forge.util.createBuffer(forge.util.decode64(data));

        let decipher = forge.cipher.createDecipher('AES-CBC', byteKey);
        decipher.start({iv: byteIV});
        decipher.update(byteData);
        let success = decipher.finish();
        if (success) {
            try {
                return forge.util.decodeUtf8(decipher.output.getBytes());
            } catch (ex) {
                console.warn("Could not decrypted password");
                return null;
            }
        }
    }
    return null;
};

export const encryptLocalStorageData = (key, iv, data) => {
    let byteKey = forge.util.decode64(key);
    let byteIV = forge.util.decode64(iv);
    let byteData = forge.util.decodeUtf8(data);

    let cipher = forge.cipher.createCipher('AES-CBC', byteKey);
    cipher.start({iv: byteIV});
    cipher.update(forge.util.createBuffer(byteData));
    cipher.finish();

    return forge.util.encode64(cipher.output.getBytes());
};

export const generateBrowserEncryptionKey = () => {
    let key = forge.random.getBytesSync(16);
    let iv = forge.random.getBytesSync(16);

    return {key: forge.util.encode64(key), iv: forge.util.encode64(iv)};
};

export const padDecryptionPassword = (passphrase, salt) => {
    if (passphrase.length + salt.length > 16 &&  passphrase.length < 32) {
        let missingCharacters = 8 - passphrase.length % 8 ;

        if (passphrase.length < 8) {
            missingCharacters += 8;
        }

        if (salt.length > missingCharacters) {

            if ( (salt.length > missingCharacters + 8) && (missingCharacters + passphrase.length) === 16) {
                missingCharacters += 8;
            }

            if ( (salt.length > missingCharacters + 8) && (missingCharacters + passphrase.length) === 24) {
                missingCharacters += 8;
            }
            return passphrase + salt.substring(0, missingCharacters);
        }
    } else if (passphrase.length >= 32) {
        return passphrase.substring(0,32);
    }
};