import React, {useEffect, useRef, useState, Fragment} from "react";
import MoinButton from "../../components/base/MoinButton";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Divider, Typography} from "@material-ui/core";
import {Colors} from "../../components/helper/ColorHelper";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";

import FolderAddIcon from '../../assets/icons/folder-add.svg';
import CheckIcon from '../../assets/icons/check.svg';
import CloseIcon from '../../assets/icons/close.svg';
import MoinUnsavedChangesDialog from "../../components/base/MoinUnsavedChangesDialog";
import MoinDivider from "../../components/base/MoinDivider";
import MoinSplashScreen from "../../components/base/MoinSplashScreen";
import {MediaContentType} from "../../utils/enums/MediaContentType";
import MoinUpload from "../../components/base/MoinUpload";
import MoinTextField from "../../components/base/MoinTextField";
import IllnessFileUpload from "../../components/illness/IllnessFileUpload";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({

    actionButton: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    divider: {
        marginTop: theme.spacing(3)
    },
    dropContainer: {
        width:  "100%",
        height: "168px",

        /* brand secondary */
        background: Colors.BRANDSECONDARY + "14",
        borderRadius: "16px",
        border: "2px dashed",
        borderColor: Colors.BRANDSECONDARY,
        padding: "40px 16px 32px 16px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center"
    },
    dropContainerOverlay: {
        borderRadius: "16px",
    },
    commentHeader: {
        paddingTop: "32px",
    },
    illnessDescription: {
        padding: "0 0 32px 0",
    },
    dropContainerInfo: {
        color: Colors.BRANDSECONDARY,
    },
    articleTitleImageWrapper: {
        width: '100%',
        display: 'block',
    },
    contentPadding: {
        padding: '24px 16px'
    },
    centeredContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    resultIconContainer: {
        width: "64px",
        height: "64px",
        borderRadius: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    resultDescription: {
        margin: "24px 16px 16px 16px",
        textAlign: "center"
    },
    linkText: {
        marginTop: "24px",
        color: Colors.BRANDSECONDARY,
        cursor: "pointer",
        textDecoration: "none",
    },
}));

/**
 * @param {Function} onSubmit
 * @param {Function} onReset
 * @param {boolean} waiting
 * @param {boolean} reportSend
 * @param {boolean} reportFailed
 * @constructor
 */
const IllnessCreate = ({onSubmit, onReset,waiting,reportSend, reportFailed}) => {
    const classes = useStyles();

    const [comment, setComment] = useState("");
    const [files, setFiles] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);

    const [redirect, setRedirect] = useState(false);
    const [t, i18n] = useTranslation();

    const onSave = async () => {
        if( !!files && files.length > 0) {
           onSubmit(comment,files);
        }
    };

    const onAddFile = (newFiles) => {
        setHasChanges(true);
        setFiles([...files, ...newFiles])
    };

    const deleteFileIndex = (index) => {
        setHasChanges(files.length > 1);
        setFiles(files.filter((val, i) => i !== index));
    };

    if(redirect) {
        return (
            <Redirect push to={ROUTES.home}/>
        )
    }

    const getSuccess = () => {
        return(
            <>
                <MoinCardContent className={clsx(classes.contentPadding,classes.centeredContent)}>
                    <div  className={classes.resultIconContainer} style={{backgroundColor: Colors.SUCCESS}}>
                        <CheckIcon fill={Colors.WHITE} width={40} height={40} />
                    </div>
                    <Typography className={classes.resultDescription} variant={"body2"}>{ t('illness.uploadSuccessfulDescription') }</Typography>

                    {files.length > 0 &&

                        files.map( (file, index) => <IllnessFileUpload
                                style={ { margin: index=== 0 ? "24px 0 0 0" :"8px 0 0 0", width: "100%"} }
                                key={'illness-file-success-' + index}
                                file={file}
                                withActions={false}
                                reverseColor={true}
                                onDelete={() => deleteFileIndex(index)}
                            />
                        )
                    }

                    <MoinButton
                        onClick={() => setRedirect(true)}
                        className={classes.actionButton}
                        style={{marginTop: "32px"}}
                    >
                        {t('illness.done')}
                    </MoinButton>

                    <Typography variant="body2"
                        className={classes.linkText}
                        onClick={() => {
                            setFiles([]);
                            setComment("");
                            onReset()
                        }}
                    >
                        { t('illness.new') }

                    </Typography>

                </MoinCardContent>

            </>
        )
    }

    const getFail = () => {
        return(
            <>
                <MoinCardContent className={clsx(classes.contentPadding,classes.centeredContent)}>
                    <div  className={classes.resultIconContainer} style={{backgroundColor: Colors.ERROR}}>
                        <CloseIcon fill={Colors.WHITE} width={40} height={40} />
                    </div>
                    <Typography className={classes.resultDescription} variant={"body2"}>{ t('illness.uploadFailedDescription') }</Typography>


                    <MoinButton
                        onClick={() => onReset()}
                        className={classes.actionButton}
                        style={{marginTop: "32px"}}
                    >
                        {t('illness.tryAgain')}
                    </MoinButton>

                    <Typography variant="body2"
                                className={classes.linkText}
                                onClick={() => setRedirect(true)}
                    >
                        { t('illness.close') }

                    </Typography>

                </MoinCardContent>

            </>
        )
    }

    const getForm = () => {
        return(
            <>
                <MoinCardContent className={classes.contentPadding}>
                    <Typography className={classes.illnessDescription} variant={"body2"}>{ t('illness.description') }</Typography>

                    <MoinUpload overlayClass={classes.dropContainerOverlay} isMultipleInput={true} onChangeFile={files => onAddFile(Array.from(files))} type={MediaContentType.IMAGEORPDF}>
                        <div className={classes.dropContainer}>
                            <FolderAddIcon fill={Colors.BRANDSECONDARY} width={64} height={64}/>
                            <Typography className={classes.dropContainerInfo} variant={"body2"}>{ t('illness.fileInfo') }</Typography>
                        </div>
                    </MoinUpload>

                    {files.length > 0 &&

                       files.map( (file, index) => <IllnessFileUpload
                           style={ { margin: index=== 0 ? "24px 0 0 0" :"8px 0 0 0"} }
                           key={'illness-file-form-' + index}
                           file={file}
                           withActions={true}
                           onDelete={() => deleteFileIndex(index)}
                           />
                       )
                    }

                    <Typography className={classes.commentHeader} variant={'h4'}>{t('illness.comment')}</Typography>
                    <MoinTextField
                        variant="outlined"
                        margin="normal"
                        placeholder={t('common.optional')}
                        required
                        fullWidth
                        multiline={true}
                        id="comment"
                        value={comment}
                        name="comment"
                        autoComplete="comment"
                        autoFocus
                        onChange={(e) => setComment(e.target.value)}
                    />
                </MoinCardContent>

                <Divider/>
                <MoinCardHeader>
                    <MoinButton disabled={files.length <= 0} onClick={onSave} className={classes.actionButton}>{t('illness.send')}</MoinButton>
                </MoinCardHeader>
            </>
        )
    }

    let content = getForm();

    if (reportFailed) {
        content =  getFail();
    } else if(reportSend) {
        content =  getSuccess();
    } else if(waiting) {
        content =  <MoinSplashScreen withoutBackground={true} />;
    }

    return (
        <Fragment>
            <MoinUnsavedChangesDialog hasChanges={hasChanges} />

            <MoinCard>
                <MoinCardHeader>
                    <Typography variant="h3">
                        {
                             t('illness.sickNote')
                        }
                    </Typography>
                </MoinCardHeader>
                <MoinDivider />
                {
                    content
                }
            </MoinCard>
        </Fragment>
    );
};

export default IllnessCreate;
