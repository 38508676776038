import React  from 'react';
import {ListItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg";
import {Colors} from "../helper/ColorHelper";


/**
 * Moin! themed list item with an avatar, title, subtitle and additional trailing elements.
 *
 * @param {} itemKey Item Key
 * @param {React.ReactNode} image Avatar image that is displayed on the far left. Ideally 48x48px.
 * @param {String} title Primary text of listItem
 * @param {String} subtitle Secondary text of listItem
 * @param {boolean} [isSubtitleBold = false] Whether or not to display the subtitle bold
 * @param {Function} onClick Behaviour when listItem is clicked
 * @param {Array<React.ReactNode>} [trailing] Array with items to be displayed on the far right of the listItem
 * @param {React.ReactNode} [leading] item to be displayed at the start of the list item
 * @param {boolean} [withDivider] Whether or not to display a divider
 * @param {String} [customClass] Optional custom class of outer listItem component
 * @param {String} [customSubtitleClass] Optional custom class of outer listItem component
 * @param {Array<JSX.Element>} [children] children of the component,
 * @param {boolean} smallHeadline
 * @param {boolean} showChevron
 * @returns {JSX.Element}
 * @constructor
 */
const MoinListItem = ({itemKey,image, title, titleColor, subtitle, subtitleColor, withBoldSubtitle = false, onClick, trailing, leading, withDivider = true, customClass, customSubtitleClass, withSplashEffect = true, smallHeadline = false, showChevron = false, children}) =>  {
  let subtitleClasses = 'moin-list-item-subtitle';
  if (withBoldSubtitle) {
    subtitleClasses += ' moin-list-item-bold';
  }
  if (customSubtitleClass) {
    subtitleClasses = clsx(subtitleClasses, customSubtitleClass)
  }

  return <ListItem
    key={itemKey}
    button={withSplashEffect}
    alignItems={'center'}
    divider={withDivider}
    onClick={ () => !!onClick && onClick() }
    style={ !!onClick ? {cursor: "pointer"} : {}}
    className={ customClass ? clsx("moin-list-item", customClass) : "moin-list-item"}
  >
    <div className={"moin-list-item-leading-trailing-container"}>
      <div className={"moin-list-item-leading-container"}>
        { leading }
        <div className={"moin-list-item-align-container"} >
          <div className={"moin-list-item-avatar"} >
            { image }
          </div>
          <div className={"moin-list-item-text-container"}>
            <Typography variant={'h2'} className={smallHeadline ? "moin-list-item-subtitle" : "moin-list-item-title"} style={{ color: titleColor }}>{title}</Typography>
            <Typography variant={'caption'} className={subtitleClasses} style={{ color: subtitleColor }}>{subtitle}</Typography>
          </div>
          {
              showChevron &&
              <ChevronRightIcon fill={Colors.BLACK} className={"moin-list-item-chevron"}/>
          }
        </div>
      </div>
      { trailing }
    </div>
    {
      children
    }
  </ListItem>
};

export default MoinListItem;