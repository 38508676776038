import {DateFilterRange} from "../enums/DateFilterRange";

/**
 * @param {Date} date
 */
export function isDateToday( date) {
    let today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}

/**
 * @param {Date} date
 */
export function isDateYesterday( date) {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.getDate() === yesterday.getDate() && date.getMonth() === yesterday.getMonth() && date.getFullYear() === yesterday.getFullYear();
}

/**
 * @param {Date} date
 */
export function isDateLastWeek( date) {
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    return date.getFullYear() > lastWeek.getFullYear()
        || ( date.getFullYear() === lastWeek.getFullYear() && date.getMonth() > lastWeek.getMonth() )
        || ( date.getFullYear() === lastWeek.getFullYear() && date.getMonth() === lastWeek.getMonth() && date.getDate() >= lastWeek.getDate() )
}

/**
 * @param {Date} date
 */
export function isDateYesterdayOrEarlier( date) {
    let startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    return date.getTime() < startOfToday.getTime();
}

/**
 * @param {Date} date
 */
export function getStartOfDay( date) {
    let newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

/**
 * @param {Date} date
 */
export function getStartOfMonth( date) {
    let newDate = new Date(date);
    newDate.setDate(1);
    return newDate;
}

/**
 * @param {Date} date
 */
export function getEndOfMonth( date) {
    let newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1, 0);
    return newDate;
}

/**
 * @param {Date} date
 */
export function getStartOfNextMonth( date) {
    let newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    newDate.setDate(1);
    return newDate;
}

/**
 * @param {Date} date
 */
export function getStartOfFirstWeekOfMonth( date) {
    let firstDayOfMonth = getStartOfMonth(date);
    let weekdayOfFirstDayOfMonth = firstDayOfMonth.getDay();

    if(weekdayOfFirstDayOfMonth === 0) {
        weekdayOfFirstDayOfMonth = 7;
    }

    if(firstDayOfMonth.getDay() === 0 || firstDayOfMonth.getDay() > 4) {
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (7 - weekdayOfFirstDayOfMonth + 1));
        return firstDayOfMonth;
    } else {
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() - (weekdayOfFirstDayOfMonth - 1));
        return firstDayOfMonth;
    }
}

/**
 * @param {Date} date
 */
export function getStartOfLastWeekOfMonth( date) {
    let lastDayOfMonth = getEndOfMonth(date);
    let weekdayOfLastDayOfMonth = lastDayOfMonth.getDay();

    if(weekdayOfLastDayOfMonth === 0) {
        weekdayOfLastDayOfMonth = 7;
    }

    if(lastDayOfMonth.getDay() === 0 || lastDayOfMonth.getDay() >= 4) {
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() - (weekdayOfLastDayOfMonth - 1));
        return lastDayOfMonth;
    } else {
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() - (weekdayOfLastDayOfMonth + 6));
        return lastDayOfMonth;
    }
}

/**
 * @param {Date} date1
 * @param {Date} date2
 */
export function getDateDifferenceInDays(date1, date2) {
    let difference = date2.getTime() - date1.getTime();
    return Math.floor(difference / 1000 / 60 / 60 / 24);
}

/**
 * @param {Date} date1
 * @param {Date} date2
 */
export function getDateDifferenceInMonths(date1, date2) {
    let yearDifference = Math.abs(date1.getFullYear() - date2.getFullYear());

    if(yearDifference === 0) {
        return date2.getMonth() - date1.getMonth();
    } else if(yearDifference === 1) {
        if(date1.getFullYear() < date2.getFullYear()) {
            return (11 - date1.getMonth()) + date2.getMonth();
        } else {
            return (11 - date2.getMonth()) + date1.getMonth();
        }
    } else {
        let monthDifference = (yearDifference - 1) * 12;

        if(date1.getFullYear() < date2.getFullYear()) {
            return (11 - date1.getMonth()) + date2.getMonth() + monthDifference;
        } else {
            return (11 - date2.getMonth()) + date1.getMonth() + monthDifference;
        }
    }
}

/**
 * @param {Date} date
 * @param months
 */
export function addMonthsToDate(date, months) {
    return new Date(date.getFullYear(), date.getMonth() + months, date.getDate());
}

/**
 * @param {Date} date1
 * @param {Date} date2
 */
export function isAtSameDay(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

/**
 * @param {Date} date
 */
export function getStartOfWeek(date) {
    let startOfWeek = new Date(date);

    let weekdayOfDate = date.getDay();
    if(weekdayOfDate === 0) {
        weekdayOfDate = 7;
    }

    startOfWeek.setDate(startOfWeek.getDate() - (weekdayOfDate - 1));
    return startOfWeek;
}

/**
 * @param {DateFilterRange} range
 */
export function getDaysForDateFilterRange(range) {
    switch (range) {
        case DateFilterRange.TODAY:
            return 0;
        case DateFilterRange.YESTERDAY:
            return 1;
        case DateFilterRange.LAST7DAYS:
            return 7;
        case DateFilterRange.LAST30DAYS:
            return 30;
        case DateFilterRange.LAST3MONTHS:
            return 31 * 3;
        case DateFilterRange.LAST6MONTHS:
            return 31 * 6;
        case DateFilterRange.LAST12MONTHS:
            return 31 * 12;
    }
}
