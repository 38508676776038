import {SuccessType} from "../enums/SuccessType";

class SuccessMessage {

    type;
    success = true;

    constructor(type, success) {
        this.type = type;
        this.success = success;
    }

    /*
    * @param t translation
    * */
    getMessage(t) {
        switch (this.type) {
            case SuccessType.ARTICLEPUBLISHED:
                return t('success.articlePublishSuccessful');
            case SuccessType.ARTICLEUPDATED:
                return t('success.articleUpdatedSuccessful');
            case SuccessType.GROUPCREATED:
                return t('success.groupCreatedSuccessful');
            case SuccessType.GROUPUPDATED:
                return t('success.groupEditedSuccessful');
            case SuccessType.EVENTCREATED:
                return t('success.eventCreatedSuccessful');
            case SuccessType.EVENTUPDATED:
                return t('success.eventEditedSuccessful');
            case SuccessType.PROFILEUPDATED:
                return t('success.profileEditedSuccessful');
            case SuccessType.PASSWORDCHANGED:
                return t('success.passwordChangedSuccessful');
            case SuccessType.EXTERNALEXISTS:
                return t('success.externalAlreadyExists');
            case SuccessType.BUGREPORTSENT:
                return t('success.bugReportSent');
            case SuccessType.INVALIDDATA:
                return t('success.invalidData');
            default:
                break;
        }
    }


}

export default SuccessMessage;
