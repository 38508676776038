import MoinTextField from "../base/MoinTextField";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MoinUpload from "../base/MoinUpload";
import {Colors} from "../helper/ColorHelper";
import {MediaContentType} from "../../utils/enums/MediaContentType";

import DeleteIcon from '../../assets/icons/delete.svg';
import PDFIcon from "../../assets/icons/pdf.svg";
import EditIcon from '../../assets/icons/edit.svg';
import AddIcon from '../../assets/icons/plus.svg';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    contentOptions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    deleteIcon: {
        cursor: 'pointer',
        fill: Colors.BLACK
    },
    pdfWrapper: {
        backgroundColor: Colors.WHITE,
        borderRadius: '20px',
        border: '1px solid ' + Colors.BLUELIGHT,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 2.5),
        cursor: 'pointer',
        textDecoration: 'none',
    },
    pdfInfo: {
        height: "20px",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    pdfName: {
        marginLeft: theme.spacing(1)
    },
    endIcon: {
        marginLeft: theme.spacing(4)
    }
}));

/**
 * @param {Function} onDelete
 * @param {Function} onChange
 * @param {ArticleContent} value
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentContentRow = ({onDelete, onChange, value}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();

    const onSelectPdf = (file) => {
        value.path = file;
        value.name = file.name;
        onChange(value);
    };

    const getPdf = () => {
        if(!!value.path) {
            if(typeof value.path === 'string' && !! value.path) {
                return (
                    <div className={classes.pdfWrapper}>
                        <div className={classes.pdfInfo}>
                            <PDFIcon fill={Colors.BLACK} style={{ height: '1rem' }}/>
                            <div className={classes.pdfName}>
                                <Typography variant="body1">{value.name}</Typography>
                            </div>
                        </div>
                        <div className={classes.endIcon}>
                            <EditIcon  style={{ height: '1rem' }}/>
                        </div>
                    </div>
                );
            } else if(!!value.path.name) {
                return (
                    <div className={classes.pdfWrapper}>
                        <div className={classes.pdfInfo}>
                            <PDFIcon fill={Colors.BLACK} style={{ height: '1rem' }}/>
                            <div className={classes.pdfName}>
                                <Typography variant="body1">{value.path.name}</Typography>
                            </div>
                        </div>
                        <div className={classes.endIcon}>
                            <EditIcon  fill={Colors.BLACK} style={{ height: '1rem' }}/>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className={classes.pdfWrapper}>
                <div className={classes.pdfInfo}>
                    <PDFIcon fill={Colors.BLACK} style={{ height: '1rem' }}/>
                    <div className={classes.pdfName}>
                        <Typography variant="body2">{ t('article.edit.addPDF') }</Typography>
                    </div>
                </div>
                <AddIcon  fill={Colors.BLACK} style={{ height: '1rem' }}/>
            </div>
        );
    };

    return(
        <div className={classes.wrapper}>
            <div className={classes.contentOptions}>
                <div className={classes.row}>
                    <Typography variant="h3">{t('article.edit.pdf')}</Typography>
                    <DeleteIcon className={classes.deleteIcon} onClick={onDelete}/>
                </div>
                <div>
                {/* TODO: change Order Buttons */}
                </div>
            </div>

            <MoinUpload onChangeFile={files => onSelectPdf(files[0])} type={MediaContentType.PDF}>
                {
                    getPdf()
                }
            </MoinUpload>

        </div>
    )
}

export default DocumentContentRow;
