import {ArrayType, BoolType, DateType, NumberType, ObjectType, ReferenceType, StringType, ModelType} from "./../types";
import GeoPointType from "../types/GeoPointType";
import RemainingFieldsType from "../types/RemainingFieldsType";

class TypeRegistry {

    types;

    constructor() {
        this.types = {};
        this.register = this.register.bind(this);
        this.getType = this.getType.bind(this);
        this.configure = this.configure.bind(this);

        this
            .register(ArrayType)
            .register(BoolType)
            .register(DateType)
            .register(NumberType)
            .register(ObjectType)
            .register(ReferenceType)
            .register(ModelType)
            .register(GeoPointType)
            .register(RemainingFieldsType)
            .register(StringType);

    }

    register(type) {
        this.types[type.type] = type;
        return this;
    };

    getType(name) {
        return this.types[name];
    };

    configure(name, options) {
        const className = this.getType(name);
        return new className(options);
    }
};

export default new TypeRegistry();