import React, {Fragment, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import FirestoreImage from "../../components/base/FirestoreImage";
import {Colors} from "../../components/helper/ColorHelper";
import Typography from "@material-ui/core/Typography";
import TaskEmptyState from "./TaskEmptyState";
import {TaskViewType} from "../../utils/enums/TaskViewType";
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    taskListWrap: {
        maxWidth: "768px",
        flex: 100,
        minWidth: '0',
        marginBottom: '64px'
    },
    colleagueListSelected: {
        marginTop: '20px',
    },
    colleagueListSelectedHeader: {
        display: "flex",
        alignItems: 'center',
        marginBottom: '24px',
        '& .MuiAvatar-root': {
            display: 'inline-flex',
            marginRight: '8px'
        },
        '& h1': {
            display: 'inline-block',
            fontSize: '24px',
            lineHeight: '28px'
        }
    },
    colleagueListSort: {
        paddingBottom: '8px',
        borderBottom: '1px solid' + Colors.BLUELIGHT
    },
    loadingIndicator: {
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "32px"
    }
}));

/**
 * @param selectedColleague
 * @param {boolean} loaeding
 * @param {Function} displaySort
 * @param {Function} sortTasks
 * @param {Function} showCompletedTasks
 * @param {Function} displayTask
 * @param {Function} loadMore
 */
const ColleagueTasks = ({tasks, selectedColleague, displaySort, sortTasks, displayTask, loadMoreTasks, loading}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    const feedWrapper = useRef();

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle, true);
        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [tasks]);


    const onScrollHandle = () => {
        if(!!feedWrapper.current && feedWrapper.current.getBoundingClientRect().bottom <= window.innerHeight + 250 && !!tasks[tasks.length-1]) {
            loadMoreTasks(selectedColleague);
        }
    };

    const isTaskColleaguesTask = (task) => {
        return task.aUid === selectedColleague.id || task.cUid === selectedColleague.id;
    }

    if (tasks.length === 0 && !loading) {
        return <TaskEmptyState view={TaskViewType.COLLEAGUELIST} selectedUser={selectedColleague}/>;
    }

    return <div className={classes.taskListWrap}>
        <div className={classes.colleagueListSelected}>
            <div className={classes.colleagueListSelectedHeader}>
                <FirestoreImage
                    key={'avatar-flash'}
                    alt={selectedColleague.fullName}
                    src={selectedColleague.avatar}
                    radius={16}
                    style={{}}
                    isAvatar={true}
                    backgroundColor={Colors.identifierAsColor(selectedColleague.id)}
                >
                    {`${selectedColleague.firstName[0]}${selectedColleague.lastName[0]}`}
                </FirestoreImage>
                <Typography variant={"h1"}>
                    {selectedColleague.fullName} {t('tasks.tasks')}
                </Typography>
            </div>
            <div className={classes.colleagueListSort}>
                {displaySort()}
            </div>
            {
                sortTasks(tasks,{withCompleted: true}).filter(task => isTaskColleaguesTask(task)).map( (task) =>
                    displayTask(task)
                )
            }
            {
                tasks.length === 0 && loading &&
                    <div className={classes.loadingIndicator}>
                        <CircularProgress color="secondary" />
                    </div>
            }
        </div>
    </div>;
}

export default ColleagueTasks;
