import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";

const useStyles = makeStyles((theme) => ({
    tag: {
        "padding": "0px 8px",
        "border-radius": "4px",
        "margin": "0px 4px",
        "background-color": Colors.BLUELIGHT,
        "color": Colors.BLACK,
        "font": theme.typography.copySmall
    },

}));

/**
 * Tag that displays if article contains e.g. an survey or images
 *
 * @param {String} label
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleTeaserTag = ({label}) => {
    const classes = useStyles();

    return <div className={classes.tag}>
        {label}
    </div>
};

export default ArticleTeaserTag;