import {getModelInstance, getModelInstances} from "../../../utils/store/selectors";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import ModelManager from "../../../utils/manager/ModelManager";
import AUTHSTORAGE from "../../../utils/auth/AuthStorage";
import {firebase} from "../../../api/FirebaseService";
import User from "../../../utils/models/User";
import SurveyVote from "../../../utils/models/SurveyVote";
import SurveyVotes from "./SurveyVotes";
import Survey from "../../../utils/models/Survey";

/**
 * @param {string} surveyId
 * @param {Survey} survey
 * @param {SurveyVote[]} votes
 * @param {User[]} users
 * @returns {JSX.Element}
 * @constructor
 */
const SurveyVotesContainer = ({surveyId, survey, votes, users}) => {
    const batchSize = 20;
    const [loading, setLoading] = useState(false);
    const filteredVotes = votes.filter(vote => vote.surveyId === surveyId);

    useEffect(() => {
        loadMoreVotes();

        if(!survey) {
            ModelManager.get({model: Survey, id: surveyId});
        }
    }, []);

    const loadMoreVotes = async () => {
        if(!loading) {
            setLoading(true);

            if(filteredVotes.length > 0) {
                await ModelManager.list({model: SurveyVote, resource: `/client/${AUTHSTORAGE.getClientId()}/survey/${surveyId}/vote`, orderBy: firebase.firestore.FieldPath.documentId(), startAfter: filteredVotes[filteredVotes.length - 1].id, extraProps: {surveyId: surveyId}, limit: batchSize});
            } else {
                await ModelManager.list({model: SurveyVote, resource: `/client/${AUTHSTORAGE.getClientId()}/survey/${surveyId}/vote`, orderBy: firebase.firestore.FieldPath.documentId(), extraProps: {surveyId: surveyId}, limit: batchSize});
            }

            setLoading(false);
        }
    };

    return(
        <SurveyVotes surveyId={surveyId} survey={survey} votes={filteredVotes} users={users} loading={loading} loadMoreVotes={loadMoreVotes} batchSize={batchSize}/>
    );
};

const mapState = (state, ownProps) => ({
    votes: getModelInstances(state, SurveyVote),
    users: getModelInstances(state, User),
    survey: getModelInstance(state, Survey, ownProps.surveyId)
});

export default connect(mapState)(SurveyVotesContainer);
