import {ReactionType} from "../../utils/enums/ReactionType";
import {HugType} from "../../utils/enums/HugType";

export const Colors = {
    BRANDPRIMARYORIGINAL: "#582e6b",
    BRANDPRIMARY: "#582e6b",
    BRANDPRIMARYLIGHT: "#67357d",
    BRANDPRIMARYLIGHTER: "#8543a2",

    BLACK: "#212121",
    BLACKLIGHT: "#767676",
    BLACKLIGHTER: "#a9a9a9",
    BLACKLIGHTEST: "#d7d7d7",
    WHITE: "#ffffff",


    BRANDSECONDARY: "#f39200",
    BRANDSECONDARYLIGHT: "#ffaf38",
    BRANDTERTIARY: "#fdc200",

    BLUEBACKGROUND: "#f9fdff",
    BLUELIGHT: "#dceefe",

    LIGHTPURPLE: "#EEE5FF",

    ACTIVEGREEN: "#21CD8F",

    ERROR: "#db404a",
    ERRORLIGHT: "#dba4ae",
    SUCCESS: "#2c9630",
    SUCCESSLIGHT: "#a8e4ad",


    REACTIONBLUE: "#7690EB",
    REACTIONRED: "#F5525D",
    REACTIONGREEN: "#3CCC65",

    MSWORDBLUE: "#4269A5",
    MSEXCELGREEN: "#39825A",
    MSPOWERPOINTORANGE: "#DC6141",

    CONFETTIYELLOW: "#FDC300",
    CONFETTIBLUE: "#E9F4FB",
    CONFETTIPURPLE: "#9D86AA",
    CONFETTIDARK: "#2E0942",

    // Chat Colors
    OWNCHATBUBBLE: "#dceefe", // Colors.BLUELIGHT,
    OTHERCHATBUBBLE: "#ffffff", // Colors.WHITE,
    OWNCHATBUBBLECONTENT: "#212121", // Colors.BLACK,
    OTHERCHATBUBBLECONTENT: "#212121", // Colors.BLACK,
    OTHERCHATBUBBLEEXTRATEXT: "#767676", // Colors.BLACKLIGHT,
    OWNCHATBUBBLEEXTRATEXT: "#767676", // Colors.BLACKLIGHT,

    OWNCHATBUBBLEAUDIOBUTTONS: "#767676", // Colors.BLACKLIGHT,
    OTHERCHATBUBBLEAUDIOBUTTONS: "#767676", // Colors.BLACKLIGHT,
    OWNCHATBUBBLEAUDIOACTIVE: "#f39200", // Colors.BRANDSECONDARY,
    OTHERCHATBUBBLEAUDIOACTIVE: "#f39200", // Colors.BRANDSECONDARY,
    OWNCHATBUBBLEAUDIOSLIDER: "#d7d7d7", // Colors.BLACKLIGHTEST,
    OTHERCHATBUBBLEAUDIOSLIDER: "#d7d7d7", // Colors.BLACKLIGHTEST,

    OWNCHATBUBBLEDOCUMENTUPLOADING: "#d7d7d7", // Colors.BLACKLIGHTEST,
    OTHERCHATBUBBLEDOCUMENTUPLOADING: "#d7d7d7", // Colors.BLACKLIGHTEST,

    identifierAsColor: function ( id ) {
        if (!!id && !!id.trim()) {
            return "hsl(" + Colors.hashCode(id) % 361 + ", 60%, 75%)";
        }
        return Colors.BLUEBACKGROUND;
    },


    hashCode: function (string) {
        var hash = 0, i, chr;
        for (i = 0; i < string.length; i++) {
            chr   = string.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    },

    colorForReactionType: function(reactionType) {
        let colors = {
            [ReactionType.LIKE]: Colors.REACTIONBLUE,
            [ReactionType.HEART]: Colors.REACTIONRED,
            [ReactionType.IDEA]: Colors.BRANDTERTIARY,
            [ReactionType.CLAP]: Colors.BRANDSECONDARY,
            [ReactionType.FUNNY]: Colors.REACTIONGREEN,
            [ReactionType.SAD]: Colors.BRANDPRIMARYORIGINAL
        };

        if(reactionType.startsWith("ReactionType.")) {
            return colors[reactionType];
        }

        return colors["ReactionType." + reactionType];
    },

    colorForHugType: function(hugType) {
        let colors = {
            [HugType.HEART]: this.REACTIONRED,
            [HugType.PRAY]: this.REACTIONBLUE,
            [HugType.IDEA]: this.BRANDTERTIARY,
            [HugType.FUNNY]: this.REACTIONGREEN,
            [HugType.CLAP]: this.BRANDSECONDARY,
            [HugType.BEE]: this.BRANDPRIMARYORIGINAL
        };

        return colors[hugType];
    }
};


