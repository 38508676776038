import React, {useState} from 'react';
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {getModelInstance} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import {connect} from "react-redux";
import parameters from '../../config/parameters.json';
import * as httpClient from "../../utils/httpclient/HttpClient";
import IllnessCreate from "./IllnessCreate";
import {FireAuth} from "../../api/FirebaseService";

const IllnessCreateContainer = ({authUser}) => {
    const [waiting, setWaiting] = useState(false);
    const [reportSend, setReportSend] = useState(undefined);
    const [reportFailed, setReportFailed] = useState(false);

    const reset= () => {
        setWaiting(false);
        setReportSend(false);
        setReportFailed(false);
    }

    const submit = async (comment, files) =>  {
        if (!waiting) {
            setWaiting(true);

            let client =AUTHSTORAGE.getClient();

            let formData = new FormData();
            formData.append('name', authUser.fullName);
            formData.append('comment', comment);
            formData.append('mail', client.hrMail);
            files.forEach( (file) => {
                formData.append('files',file, file.name.split(/(\\|\/)/g).pop() );
            });

            let httpClientInstance = new httpClient.default(parameters.backendUrl, {
                headers: {
                    'Authorization': `Bearer ${await FireAuth.currentUser.getIdToken()}`
                }
            });

            let request = httpClientInstance.post('/sickness', formData);

            request.send().then(
                () => {
                    setReportSend(true);
                    setReportFailed(false)
                    setWaiting(false);
                }
            ).catch(error => {
                setReportSend(false);
                setReportFailed(true)
                setWaiting(false);
            });
        }

    }

    return  <IllnessCreate onSubmit={submit} onReset={reset} waiting={waiting} reportSend={reportSend} reportFailed={reportFailed}/>
};



const mapState = (state) => ({
    authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
});

export default connect(mapState)(IllnessCreateContainer);