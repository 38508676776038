import parameters from "../../config/parameters.json";

class AuthDistributorMembership {
    /**
     *
     * @private {Array} _distributorMember
     * @private {Array} _distributorRequests
     * @private {Array} _distributorWaiting
     */
    #_distributorMember = [];
    #_distributorRequests = [];
    #_distributorWaiting = [];

    /**
     *
     * @param {Array} distributorIds
     */
    setMemberships = (distributorIds) => {
        this.#_distributorMember = distributorIds;
        this.#_distributorRequests = this.#_distributorRequests.filter ( (dis) => !distributorIds.includes(dis));
        this.#_distributorWaiting = this.#_distributorWaiting.filter ( (dis) => !distributorIds.includes(dis));
    };

    /**
     *
     * @param {String} distributorId
     */
    addMembership = (distributorId) => {
        this.#_distributorMember.push(distributorId);
        this.#_distributorRequests = this.#_distributorRequests.filter ( (dis) => dis !== distributorId);
        this.#_distributorWaiting = this.#_distributorWaiting.filter ( (dis) => dis !== distributorId);
    };

    /**
     *
     * @param {String} distributorId
     */
    removeMembership = (distributorId) => {
        this.#_distributorMember = this.#_distributorMember.filter ( (dis) => dis !== distributorId);
    };

    /**
     *
     * @param {Array} distributorIds
     */
    setRequests= (distributorIds) => {
        this.#_distributorRequests = distributorIds;
        this.#_distributorMember = this.#_distributorMember.filter ( (dis) => !distributorIds.includes(dis));
        this.#_distributorWaiting = this.#_distributorWaiting.filter ( (dis) => !distributorIds.includes(dis));
    };

    /**
     *
     * @param {String} distributorId
     */
    addRequest= (distributorId) => {
        this.#_distributorRequests.push(distributorId);
        this.#_distributorMember = this.#_distributorMember.filter ( (dis) => dis !== distributorId);
        this.#_distributorWaiting = this.#_distributorWaiting.filter ( (dis) => dis !== distributorId);
    };

    /**
     *
     * @param {String} distributorId
     */
    removeRequest= (distributorId) => {
        this.#_distributorRequests = this.#_distributorRequests.filter ( (dis) => dis !== distributorId);
    };

    /**
     *
     * @param {Array} distributorIds
     */
    setWaiting= (distributorIds) => {
        this.#_distributorWaiting = distributorIds;
        this.#_distributorRequests = this.#_distributorRequests.filter ( (dis) => !distributorIds.includes(dis));
        this.#_distributorMember = this.#_distributorMember.filter ( (dis) => !distributorIds.includes(dis));
    };

    /**
     *
     * @param {String} distributorId
     */
    removeWaiting= (distributorId) => {
        this.#_distributorWaiting = this.#_distributorWaiting.filter ( (dis) => dis !== distributorId);
    };

    /**
     *
     * @param {String} distributorId
     */
    addWaiting = (distributorId) => {
        this.#_distributorWaiting.push(distributorId);
        this.#_distributorRequests = this.#_distributorRequests.filter ( (dis) => dis !== distributorId);
        this.#_distributorMember = this.#_distributorMember.filter ( (dis) => dis !== distributorId);
    };

    isMemberOfDistributor(distributorId) {
        return this.#_distributorMember.includes(distributorId) || distributorId === parameters.globalDistributorId;
    }

    hasRequestForDistributor(distributorId) {
        return this.#_distributorRequests.includes(distributorId);
    }

    isWaitingForDistributor(distributorId) {
        return this.#_distributorWaiting.includes(distributorId);
    }

    getAllMemberships() {
        return this.#_distributorMember;
    }

    reset() {
        this.#_distributorMember = [];
        this.#_distributorRequests = [];
        this.#_distributorWaiting = [];
    }
}

const AUTHDISTRIBUTORMEMBERSHIP = new AuthDistributorMembership();

export default AUTHDISTRIBUTORMEMBERSHIP;