import {model} from "../manager/decorators";

@model("/client/:client/user/:user/comment_answer_heart")
class CommentAnswerReaction {
    id;
    type;
}

export default CommentAnswerReaction;

