import AbstractType from "./AbstractType";

export default class ModelType extends AbstractType
{
    target;

    constructor({target}) {
        super();
        this.target = target;
    }

    toFirestore = (value) => value ? this.target.converter.toFirestore(value) : null;
    fromFirestore = (value) => value ? this.target.from(value) : null;

    static type = "model";

};