import React from "react";
import {useTranslation} from "react-i18next";
import FireStorageImage from "../base/FirestoreImage";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import MoinUpload from "../base/MoinUpload";
import {MediaContentType} from "../../utils/enums/MediaContentType";
import MoinTextField from "../base/MoinTextField";

import DeleteIcon from "../../assets/icons/delete.svg";
import VideoIcon from '../../assets/icons/camera.svg';

const useStyles = makeStyles((theme) => ({
    videoWrapper: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.WHITE
    },
    videoContent: {
        width: '100%',
        position: 'absolute'
    },
    selectVideoInfo: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        marginTop: '25%'
    },
    wrapper: {
        marginTop: theme.spacing(4)
    },
    contentOptions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    deleteIcon: {
        cursor: 'pointer',
        fill: Colors.BLACK
    },
    whiteText: {
        color: Colors.WHITE
    }
}));

/**
 * @param {Function} onDelete
 * @param {Function} onChangeVideo
 * @param {Function} onChangeText
 * @param {ArticleContent} value
 * @returns {JSX.Element}
 * @constructor
 */
const VideoContentRow = ({onDelete, onChangeVideo, onChangeText, value}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();

    const getVideo = () => {
        if(!!value.video && (!!value.video.thumbnail || !!value.video.url)) {
            return (
                <div className={classes.videoWrapper}>
                    {
                        // Render thumbnail if it is set, otherwise try to render video by blob url
                        value.video.thumbnail ?
                            <FireStorageImage src={value.video.thumbnail} className={classes.videoContent}/>
                        : value.video.url?.startsWith('blob') &&
                            <video muted className={classes.videoContent}>
                                <source src={value.video.url} type="video/mp4"/>
                            </video>
                    }
                    <div className={`${classes.selectVideoInfo} ${classes.whiteText}`}>
                        <VideoIcon fill={Colors.WHITE} />
                        <Typography variant="body1" className={classes.whiteText}>{ t('article.edit.editVideo') }</Typography>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.videoWrapper}>
                <div className={classes.selectVideoInfo}>
                    <VideoIcon fill={Colors.BLACK} />
                    <Typography variant="body1">{ t('article.edit.addVideo') }</Typography>
                </div>
            </div>
        );
    };

    return(
        <div className={classes.wrapper}>
            <div className={classes.contentOptions}>
                <div className={classes.row}>
                    <Typography variant="h3">{t('article.edit.video')}</Typography>
                    <DeleteIcon className={classes.deleteIcon} onClick={onDelete}/>
                </div>
                <div>
                    {/* TODO: change Order Buttons */}
                </div>
            </div>
            <MoinUpload onChangeFile={files => onChangeVideo(files[0])} type={MediaContentType.VIDEO}>
                {
                    getVideo()
                }
            </MoinUpload>
            <MoinTextField
                onChange={(e) => onChangeText(e.target.value)}
                placeholder={ t('article.edit.enterAText') }
                value={value.text}
            />
        </div>
    )
}

export default VideoContentRow;
