import {id, model, prop} from "../manager/decorators";
import {CURRENT_USER, SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import {getEventAvatarPath, getGroupAvatarPath} from "../helper/ImageHelper";
import parameters from "../../config/parameters.json";
import ArticleContent from "./ArticleContent";
import {DateTime, Duration} from "luxon";

@model('client/:client/event')
class Event {

    @id()
    id;

    @prop()
    title;

    @prop()
    description;

    @prop()
    quillDescription;

    @prop()
    agenda;

    @prop()
    location;

    @prop()
    room;

    @prop()
    distributorId;

    @prop({type: "number", createOnly: true })
    count;

    @prop({type: "geopoint"})
    geolocation;

    @prop({sentinel: CURRENT_USER, createOnly: true})
    cUid;

    @prop({
        type: "array",
        typeOptions: {
            entry: "string"
        }
    })
    pUids;

    @prop({type: "bool"})
    hasImage;

    @prop({type: "date"})
    start;

    @prop({type: "date"})
    end;

    @prop({type: "date", readOnly: true ,sentinel: SERVER_TIMESTAMP})
    cAt;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP})
    uAt;

    @prop({type: "bool", readOnly: true})
    deleted;

    isAuthUserParticipating = false;

    get avatar() {
        if (!this.hasImage)
            return undefined;
        return getEventAvatarPath(this.cUid, this.id);
    }

    get distributor() {
        return this.distributorId ||  parameters.globalDistributorId;
    }

    get isFuture() {
        let yesterday = DateTime.local().minus(Duration.fromObject({days: 1})).endOf('day').toMillis();
        let now = DateTime.local().toMillis();

        return (
            ( !this.end && this.start.getTime() > yesterday)
            || ( !!this.end && this.end.getTime() > now)
        )
    }
}

export default Event
