import {model, prop} from "../manager/decorators";
import CustomRepetitionInterval from "./CustomRepetitionInterval";
import {isAtSameDay} from "../helper/DateHelper";
import {RepetitionType} from "../enums/repetition/RepetitionType";

@model()
class TaskRepetition {
    @prop()
    type;

    @prop()
    dailyRepetitionWeekdays;

    @prop()
    weeklyRepetitionWeekdays;

    @prop()
    monthlyRepetitionWeek;

    @prop({
        type: "model",
        typeOptions: {
            target: CustomRepetitionInterval
        }
    })
    customInterval;

    /**
     * @param {TaskRepetition} repetition
     */
    isSame(repetition) {
        if(this.type === repetition.type) {
            switch (this.type) {
                case RepetitionType.DAILY:
                    return this.dailyRepetitionWeekdays === repetition.dailyRepetitionWeekdays;
                case RepetitionType.WEEKLY:
                    return this.weeklyRepetitionWeekdays.every(element => repetition.weeklyRepetitionWeekdays.includes(element)) && repetition.weeklyRepetitionWeekdays.every(element => this.weeklyRepetitionWeekdays.includes(element));
                case RepetitionType.MONTHLY:
                    return this.monthlyRepetitionWeek === repetition.monthlyRepetitionWeek;
                case RepetitionType.CUSTOM:
                    return this.customInterval.isSame(repetition.customInterval);
                default:
                    return true;
            }
        }

        return false;
    }

    isValid() {
        switch (this.type) {
            case RepetitionType.DAILY:
                return !!this.dailyRepetitionWeekdays;
            case RepetitionType.WEEKLY:
                return !!this.weeklyRepetitionWeekdays && this.weeklyRepetitionWeekdays.length > 0;
            case RepetitionType.MONTHLY:
                return !!this.monthlyRepetitionWeek;
            case RepetitionType.CUSTOM:
                return this.customInterval.isValid();
            default:
                return true;
        }
    }
}

export default TaskRepetition;
