import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from "@material-ui/core/Container";
import {useTranslation} from "react-i18next";
import MoinTextField from "../components/base/MoinTextField";
import MoinButton from "../components/base/MoinButton";
import illustration from "../assets/illustrations/moin-app-illus-website-header.png";
import Typography from "@material-ui/core/Typography";
import {Colors} from "../components/helper/ColorHelper";

import ChevronLeftIcon from "../assets/icons/chevron-left.svg";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cancel: {
        margin: theme.spacing(3, 0, 2),
        border: "none",
        color: Colors.BRANDPRIMARY,
        backgroundColor: "transparent",
        padding: theme.spacing(1.25, 2),

        '&:hover': {
            backgroundColor: "transparent",
            border: "none",
            color: Colors.BRANDPRIMARY,
            textDecoration: "underline"
        },
    },
    backLink: {
        textDecoration: 'none',
        color: Colors.BRANDSECONDARY,
        '& svg': {
            fill: Colors.BRANDSECONDARY,
        },
        '&:hover': {
            color: Colors.BRANDPRIMARY,
            '& svg': {
                fill: Colors.BRANDPRIMARY,
            },
        },
        display: 'flex',
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        alignItems: 'center'
    },
    actionRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    illustration: {
        width: '65%',
        height: 'auto'
    },
    logo: {
        width: '40%',
        height: 'auto',
        marginRight: 'auto'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    textField: {
        textAlign: 'center'
    }
}));

const MasterPasswordPage = ({error,onSubmit, onCancel}) => {

    const [t, i18n] = useTranslation();
    const [password, setPassword] = useState('');
    const [submitErrors, setSubmitErrors] = useState({});

    const classes = useStyles();

    const submit = () =>  {
        let errors = {};

        if (!password) {
            errors['password'] = t('form.errors.cantBeEmpty', {field: t('login.password')});
        } else if (password.length !== 19) {
            errors['password'] = t('form.errors.wrongFormat', {field: t('login.password'), format: "XXXX-XXXX-XXXX-XXXX"});
        }

        setSubmitErrors(errors);
        if (Object.keys(errors).length  === 0 ) {
           onSubmit(password.replaceAll("-", ''))
        }
    }

    const changePassword = (event) => {
        let inputValue = event.target.value,
            targetEl = event.target,
            selectionStart = event.target.selectionStart,
            selectionEnd = event.target.selectionEnd;

        if (inputValue.length < password.length) {
            inputValue = stringToKey(inputValue);
            targetEl.value = inputValue;
            setPassword(inputValue);

            targetEl.selectionStart = selectionStart;
            targetEl.selectionEnd = selectionEnd;

            return;
        }

        inputValue = stringToKey(inputValue);

        setPassword(inputValue);
        targetEl.value = inputValue;

        if (selectionStart < inputValue.length - 1) {
            targetEl.selectionStart = selectionStart;
            targetEl.selectionEnd = selectionEnd;
        }
    }

    const stringToKey = (string) => {
        let key = string.toUpperCase();
        key = key.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1-').trim();

        if (key.length > 19) {
            key = key.slice(0,key.length -1 > 19 ? 19 : key.length -1 );
        }

        return key;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={illustration} alt={t('common.illustration')} className={classes.illustration}/>
                <form className={classes.form} noValidate
                      onSubmit={(e) => {
                          submit(e);
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                      }}
                >
                    <div className={classes.row}>
                        <div onClick={onCancel} className={`${classes.backLink} ${classes.content}`}>
                            <ChevronLeftIcon />
                            {t('common.back')}
                        </div>
                    </div>
                    <Typography variant={'h5'}>{t('login.masterPassword')}</Typography>
                    <Typography variant={'body2'}>{t('login.masterPasswordDescription')}</Typography>
                    <MoinTextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        // value={password}
                        placeholder="XXXX-XXXX-XXXX-XXXX"

                        onChange={(e) => changePassword(e)}
                        error={ error ? error : submitErrors.hasOwnProperty('password')}
                        helperText={  error ? error : submitErrors.hasOwnProperty('password') &&  submitErrors["password"]}
                        inputClassName={classes.textField}
                    />

                    <div className={classes.actionRow}>
                        <MoinButton
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={submit}
                            disabled={!password || password.length !== 19}
                        >
                            {t('login.commit')}
                        </MoinButton>

                    </div>

                </form>
            </div>
        </Container>
    );
};
export default MasterPasswordPage;
