import React from "react";
import {Typography} from "@material-ui/core";
import {Colors} from "../helper/ColorHelper";
import {makeStyles} from "@material-ui/core/styles";
import {getFormattedTimeSpan} from "../../utils/helper/TextHelper";
import {getUserAvatarPath} from "../../utils/helper/ImageHelper";
import FireStorageImage from "../base/FirestoreImage";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import RenderedVideo from "./RenderedVideo";
import {useTranslation} from "react-i18next";
import MoinLinkifiedText from "../base/MoinLinkifiedText";
import PdfPreview from "../article/PdfPreview";
import AudioPlayer from "../article/AudioPlayer";
import {messageTypeToHugType} from "../../utils/helper/EnumHelper";
import {getIconForHug} from "../helper/IconHelper";

const useStyles = makeStyles(() => ({
  chatAutomaticMessage: {
    margin: "0 0 16px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlignment: "center"
  },
  messageText: {
    wordWrap: 'break-word'
  },
  chatMessage: {
    marginBottom: "8px",
    "&.current-user-message": {
      "& .message-text": {
        color: Colors.OWNCHATBUBBLECONTENT,
      },

      "& .chat-message-text-inner": {
        background: Colors.OWNCHATBUBBLE,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.09), 0px 0px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "21px 21px 0px 21px"
      },

      "& .chat-message-name": {
        color: Colors.OWNCHATBUBBLEEXTRATEXT,
      },

      "& .chat-message-time": {
        textAlign: "right"
      }
    },

    "& .chat-message-avatar": {
      marginRight: "8px",
      alignSelf: "flex-end",
      marginBottom: "20px"
    },

    "& .chat-message-text": {
      maxWidth: "calc(100% -  56px - 48px)",
      hyphens: "auto"
    },

    "& .chat-message-text-inner": {
      padding: "12px 16px",
      background: Colors.OTHERCHATBUBBLE,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.09), 0px 0px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "21px 21px 21px 0px",
    },

    "& .message-text": {
      color: Colors.OTHERCHATBUBBLECONTENT,
    },

    "& .chat-message-name": {
      color: Colors.OTHERCHATBUBBLEEXTRATEXT,
      fontSize: "10px",
      lineHeight: "16px"
    },

    "& .chat-message-time": {
      color: Colors.BLACKLIGHT,
      fontSize: "10px",
      lineHeight: "16px",
      marginTop: "4px",
    }
  },
  mediaRow: {
    overflow: 'hidden',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxHeight: '150px',
  },
  imageWrapper: {
    position: "relative",
    minWidth: '240px',
    width: '240px',
    height: '150px',
    borderRadius: '16px',
    overflow: 'hidden',
    marginRight: "8px"
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  hugMessageText: {
    width: '100%'
  },
  hugMessageTextInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hugIcon: {
    fill: Colors.WHITE,
    marginBottom: '12px',
    height: '24px',
    width: '24px'
  }
}));

/**
 * @param {Chat} chat
 * @param {Message} message
 * @return {JSX.Element}
 * @constructor
 */
function ChatMessage({id, chat, message}) {
  const classes = useStyles();
  const currentUserId = AUTHSTORAGE.getUserId();
  const [t] = useTranslation();

  if(message.dAt) {
    return (
        <div id={id} className={`${classes.chatMessage} ${currentUserId === message.cUid ? 'current-user-message' : ''}`}>
          <div className={currentUserId !== message.cUid ? "row" : "row row-reverse"}>
            {currentUserId !== message.cUid ?
                <div className="chat-message-avatar">
                  <FireStorageImage
                      isAvatar={true}
                      radius="12"
                      src={getUserAvatarPath(message.cUid)}
                      alt={message.cName}
                      backgroundColor={Colors.identifierAsColor(message.cUid)}
                  />
                </div> : ''
            }
            <div className="chat-message-text">
              <div
                  className="chat-message-text-inner"
                  style={{padding: "12px 16px"}}
              >
                <Typography
                    variant="subtitle1"
                    className={"classes.messageText"}
                    >
                  <MoinLinkifiedText text={ message.cUid === currentUserId ? t('chat.youDeletedThisMessage') : t('chat.messageDeleted') }/>
                </Typography>
              </div>
              <div className="chat-message-time">{getFormattedTimeSpan(message.cAt)}</div>
            </div>
          </div>
        </div>
    );
  }

  if (!!message.type) {
    let hugType = messageTypeToHugType(message.type);

    if(!!hugType) {
      let HugIcon = getIconForHug(hugType);

      const getMessageText = () => {
        if(!!message.text) {
          return (
            <Typography variant="body2" style={{color: Colors.WHITE}}>
              { message.text }
            </Typography>
          );
        }

        return (
          <Typography variant="body2" style={{color: Colors.WHITE, fontWeight: 700}}>
            {
              message.cUid === currentUserId
                ? t('messages.youSentAHug')
                : t('messages.userSentAHug', { user: message.cName })
            }
          </Typography>
        );
      }

      return (
        <div id={id} className={`${classes.chatMessage} ${currentUserId === message.cUid ? 'current-user-message' : ''}`}>
          <div className={currentUserId !== message.cUid ? "row" : "row row-reverse"}>
            <div className={`chat-message-text ${classes.hugMessageText}`}>
              <div
                  className={`chat-message-text-inner ${classes.hugMessageTextInner}`}
                  style={{padding: "20px 16px", backgroundColor: Colors.colorForHugType(hugType)}}
              >
                <HugIcon className={classes.hugIcon} />
                { getMessageText() }
              </div>
              <div className="chat-message-time">{getFormattedTimeSpan(message.cAt)}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id={id} className={classes.chatAutomaticMessage}>
          <Typography variant="body1">{ message.getText(t) }</Typography>
          <Typography variant="caption">{ getFormattedTimeSpan(message.cAt) }</Typography>
        </div>
      );
    }
  }

  let hasMedia = ((!!message.videos && message.videos.length > 0) || (!!message.images && message.images.length > 0)) || !!message.audio;
  let hasText = !!message.getText(t);

  return (
      <div id={id} className={`${classes.chatMessage} ${currentUserId === message.cUid ? 'current-user-message' : ''}`}>
        <div className={currentUserId !== message.cUid ? "row" : "row row-reverse"}>
          {currentUserId !== message.cUid ?
            <div className="chat-message-avatar">
              <FireStorageImage
                  isAvatar={true}
                  radius="12"
                  src={getUserAvatarPath(message.cUid)}
                  alt={message.cName}
                  backgroundColor={Colors.identifierAsColor(message.cUid)}
              />
            </div> : ''
          }
          <div className="chat-message-text">
            <div
                className="chat-message-text-inner"
                style={{
                  padding: hasMedia
                      ? hasText
                          ? "8px 8px 16px 8px"
                          : "8px"
                      : "12px 16px"
                }}
            >
              {
                !chat.isSingleChat && currentUserId !== message.cUid &&
                <div
                    className="chat-message-name"
                    style={hasMedia ? {marginLeft: "8px", marginRight: "8px"} : {}}>
                  {message.cName}
                </div>
              }
              {
                ((!!message.videos && message.videos.length > 0) || (!!message.images && message.images.length > 0)) &&
                  <div className={classes.mediaRow}>
                    {
                      (!!message.images && message.images.length > 0) &&
                      message.images.map((imagePath, index,collection) =>
                          <div key={"message-image-" + index}
                               className={classes.imageWrapper}
                               style={{
                                 borderBottomLeftRadius : currentUserId !== message.cUid ? 0 : "16px",
                                 borderBottomRightRadius :currentUserId === message.cUid ? 0 : "16px",
                                 marginRight: index === collection.length -1 ? 0 : "8px"
                               }}
                          >
                            <FireStorageImage lightboxEnabled={true} src={imagePath} className={classes.image}/>
                          </div>)
                    }

                    {
                      (!!message.videos && message.videos.length > 0) &&
                      message.videos.map((video, index, collection) =>
                          <RenderedVideo
                              key={"message-video-" + index + "-upload-" + (video.uploadId ?? "1")}
                              video={video}
                              style={{
                                borderBottomLeftRadius : currentUserId !== message.cUid ? 0 : "16px",
                                borderBottomRightRadius :currentUserId === message.cUid ? 0 : "16px",
                                marginRight: index === collection.length -1 ? 0 : "8px"
                              }}
                          />)
                    }
                  </div>
              }
              {
                (!!message.docs && message.docs.length > 0) &&
                  <>
                    { message.docs.map((document, index) => <PdfPreview
                        content={document}
                        iconColor={currentUserId === message.cUid ? Colors.OWNCHATBUBBLEEXTRATEXT : Colors.OTHERCHATBUBBLEEXTRATEXT}
                        titleColor={currentUserId === message.cUid ? Colors.OWNCHATBUBBLECONTENT : Colors.OTHERCHATBUBBLECONTENT}
                        subtitleColor={currentUserId === message.cUid ? Colors.OWNCHATBUBBLEEXTRATEXT : Colors.OTHERCHATBUBBLEEXTRATEXT}
                        key={"message-document-" + index}
                        ignorePadding={true}
                    />) }
                  </>
              }
              {
                (!!message.audio) &&
                  <div>
                     <AudioPlayer
                         content={message.audio}
                         isAuth={currentUserId === message.cUid}
                         key={"message-audio-" + message.id}
                         leading={
                           currentUserId === message.cUid
                             ?  <FireStorageImage
                                   isAvatar={true}
                                   radius={16}
                                   src={getUserAvatarPath(message.cUid )}
                                   alt={message.cName}
                                   backgroundColor={Colors.identifierAsColor(message.cUid)}
                               />
                             : undefined}
                         trailing={
                           currentUserId !== message.cUid
                               ?  <FireStorageImage
                                   isAvatar={true}
                                   radius={16}
                                   src={getUserAvatarPath(message.cUid )}
                                   alt={message.cName}
                                   backgroundColor={Colors.identifierAsColor(message.cUid)}
                               />
                               : undefined
                         }
                     />
                  </div>
              }
              {
                hasText &&  <Typography
                    variant="body1"
                    className={`${classes.messageText} message-text`}
                    style={hasMedia
                        ? {margin: "8px 8px 0"}
                        : {}
                    }>
                  <MoinLinkifiedText text={ message.getText(t) }/>
                </Typography>
              }

            </div>
            <div className="chat-message-time">{getFormattedTimeSpan(message.cAt)}</div>
          </div>
        </div>
      </div>
  );
}

export default ChatMessage;
