import React, {useEffect, useRef, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";

const mapsService = { current: null };

const useStyles = makeStyles(theme => ({
    link: {
        cursor: "pointer",
        textDecoration: "none",
        color: Colors.BRANDSECONDARY
    },
    show: {
        marginTop: "1rem",
        height: "400px",
        width: "100%",
        display: "block"
    },
    hide: {
        display: "none"
    }
}))

const GoogleMapsMap = ({title, lat,long}) => {

    const mapRef = useRef();
    const [showMap,setShowMap] = useState(false);
    const [googleMapsIsActive,setGoogleMapsIsActive] = useState(false);
    const [t] = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        if (document.querySelector('#google-maps')) {
            setGoogleMapsIsActive(true);
        }
    },[])

    const renderMap= () => {
        setShowMap(!showMap);
        if (lat  && long && !mapsService.current && document.querySelector('#google-maps')) {
            const position = { lat: lat, lng: long };
            mapsService.current = new window.google.maps.Map(mapRef.current, {
                center: position,
                zoom: 10
            });
            let marker =  new window.google.maps.Marker({
                position: position,
                title: title
            });
            marker.setMap(mapsService.current);

        }
    }

    return (
        <>
            {
                googleMapsIsActive && !!lat && !!long &&
                    <Typography variant="body2" onClick={renderMap} className={classes.link}>
                        {showMap ? t('event.hideMap'): t('event.showMap')}
                    </Typography>
            }
            {
               !googleMapsIsActive &&
                   <Typography variant="body2">
                       {t('event.googleMapsIsDisabled')}
                   </Typography>
            }
            <div ref={(node) => mapRef.current = node} className={showMap ? classes.show : classes.hide}/>
        </>
    );
}

export default GoogleMapsMap;