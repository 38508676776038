import { Typography} from "@material-ui/core";
import { sortUsersByFirstnameAuthFirst} from "../../utils/helper/SortHelper";
import UserListItem from "../user/MoinUserListItem";
import React, {useEffect, useRef, useState} from "react";
import {getModelInstances} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import MoinListItem from "./MoinListItem";
import {useTranslation} from "react-i18next";
import UserIcon from "../../assets/icons/user.svg";

const useStyles = makeStyles((theme) => ({
    listContainer: {
        overflow: 'auto',
        maxHeight: '184px',
        width: 'calc(50% - 6px)',
        zIndex: '1',
        position: 'absolute',
        top: '46px',
        boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.05)',
        maxWidth: '288px',
        borderRadius: "8px",
    },
    list: {
        margin: '0',
        padding: '4px 0',
        background: Colors.WHITE,
        '& .MuiListItem-root': {
            padding: '8px 12px',
        },
    },
    unassignListItem: {
        '& div': {
            margin: 0
        }
    },
    iconContainer: {
        width: '24px',
        height: '24px',
        borderRadius: '20px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '8px !important',
        '& svg': {
            verticalAlign: 'middle',
            marginRight: '0',
            width: '15px !important',
            height: '15px !important',
        }
    },
    unassign: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            color: Colors.BLACK,
            fontWeight: '500'
        }
    }
}));

/**
 *
 * @param {User} assignee
 * @param {String} search
 * @param {Array<User>} users
 * @param {String} placeholder
 * @param {Function} onAssign
 * @param {Function} onUnassign
 * @param {boolean} show
 * @returns {JSX.Element}
 * @constructor
 */

const MoinUserAssignmentList = ({assignee,search, users = [], onAssign, onUnassign, show=true }) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [rebuild, setRebuild] = useState(false);

    const userAmount = useRef(25);
    if (!show) {
        userAmount.current = 15;
    }
    const lastSearch = useRef(search);
    const scrollAnchor = useRef();

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle, true);
        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [users]);

    const onScrollHandle = () => {
        if(!!scrollAnchor.current && userAmount.current < users.length &&  (scrollAnchor.current.scrollHeight - scrollAnchor.current.scrollTop - scrollAnchor.current.clientHeight < 50) ) {
            userAmount.current +=15;
            setRebuild((rebuild) => !rebuild);
        }
    };

    let filteredUsers = users;
    if (!!search && search.trim().length > 0 && (!assignee || search !== assignee.fullName)) {
        filteredUsers = users.filter( user =>  ( user.fullName.toLowerCase().includes(search.toLowerCase())));
    }

    if (!!search && lastSearch.current !== search  ) {
        lastSearch.current = search;
        userAmount.current = 15;
    }
    let paginatedUsers = filteredUsers.sort(sortUsersByFirstnameAuthFirst).slice(0,userAmount.current);

    return (
        <div className={classes.listContainer} ref={(node) => scrollAnchor.current = node} style={!show ? {display: "none"}: {}}>
            <div className={classes.list} >
                {
                    assignee &&
                        <MoinListItem
                            key={"default_option"}
                            onClick={onUnassign}
                            // we can not use the splash Effect here since it adds a bunch of events that block the click handler
                            withSplashEffect={false}
                            title={
                                <div className={classes.unassign}>
                                    <div className={classes.iconContainer} style={{backgroundColor: Colors.BLACKLIGHTEST}}>
                                        <UserIcon fill={Colors.WHITE}/>
                                    </div>
                                    <Typography variant="caption">{t('tasks.unassign')}</Typography>
                                </div>
                            }
                            customClass={classes.unassignListItem}
                            withDivider={false}
                        />
                }
                {
                    paginatedUsers.map((user, index, collection) =>
                        <UserListItem
                            //selected={selectedUser === user.id}
                            onSelect={onAssign}
                            divider={false}
                            key={index}
                            user={user}
                            showSubtitle={false}
                            smallHeadline={true}
                        />
                    )
                }
            </div>
        </div>
    );
}

const mapState =  (state, ownProps) =>  {
    return  {
        users: getModelInstances(state, User),
    }
}

export default connect(mapState)(MoinUserAssignmentList)