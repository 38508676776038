import React, {useRef, useState} from "react";
import RenderedComment from "./RenderedComment";
import ModelManager from "../../../utils/manager/ModelManager";
import FirebaseStorageManager from "../../../utils/manager/manager/FirebaseStorageManager";
import AUTHSTORAGE from "../../../utils/auth/AuthStorage";
import {getModelInstance} from "../../../utils/store/selectors";
import User from "../../../utils/models/User";
import {
    deleteModelInstanceDispatch,
    updateModelInstanceDispatch
} from "../../../utils/store/actions";
import {connect} from "react-redux";
import FireStorageUrl from "../../../utils/models/FireStorageUrl";
import {FireDelete, FireIncrement, FireTimestamp} from "../../../api/FirebaseService";

/**
 * @param {Article} article
 * @param {Comment} comment
 * @param {CommentReaction} authReaction
 * @param {User} authUser
 * @returns {JSX.Element}
 * @constructor
 */
const RenderedCommentContainer = ({article, comment,authReaction,  authUser, deleteModelInstanceDispatch}) => {
    const saving = useRef(false);
    const deleting = useRef(false);

    const updateComment = async (text, image, deletedImage) => {
        if(!saving.current) {
            let futures = [];
            saving.current = true;

            comment.text = text;



            if(deletedImage) {
                let uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/comment/${comment.id}`;
                futures.push(new FirebaseStorageManager().deleteFile(uploadPath));
                let fixedPath = uploadPath.substring(1).replace(/\//g, '%2F');

                let fireStorageUrl = new FireStorageUrl();
                fireStorageUrl.id = fixedPath;
                deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
                comment.image = null;
                comment.media = null;
            }

            if(!!image?.name) {
                let uploadPath = `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/comment/${comment.id}`;
                futures.push(new FirebaseStorageManager().uploadImage(image, uploadPath, 1200 , 1));
                let fixedPath = uploadPath.substring(1).replace(/\//g, '%2F');

                comment.image = fixedPath;
                comment.media = [fixedPath];

                let fireStorageUrl = new FireStorageUrl();
                fireStorageUrl.id = fixedPath;
                deleteModelInstanceDispatch(FireStorageUrl, fireStorageUrl);
            }

            let customCommentData = {
                text: text,
                uAt: FireTimestamp()
            };

            if(!!comment.image) {
                customCommentData['image'] = comment.image;
                customCommentData['media'] = [comment.image];
            } else {
                customCommentData['image'] = FireDelete()
                customCommentData['media'] = FireDelete()
            }

            futures.push(ModelManager.update({modelInstance: comment, customData: customCommentData, id: comment.id}));

            await Promise.all(futures);
            saving.current = false;
            return true;
        }
        return false;
    };

    const deleteComment = () => {
        if(!deleting.current) {
            deleting.current = true;

            if(!!comment.image) {
                new FirebaseStorageManager().deleteFile(comment.image.replace(/%2F/g, '/'));
            }

            if(comment.aCount > 0) {
                let customData = {
                    dAt: FireTimestamp(),
                    text: FireDelete(),
                    image: FireDelete(),
                    media: FireDelete(),
                    reactions: FireDelete(),
                    dBy: AUTHSTORAGE.getUserId()
                };

                comment.dAt = new Date();

                ModelManager.update({modelInstance: comment, customData: customData});
            } else {
                article.cCount--;
                let articleUpdateData = {
                    cCount: FireIncrement(-1)
                };

                ModelManager.update({modelInstance: article, customData: articleUpdateData});
                ModelManager.delete({modelInstance: comment, resource: `/client/${AUTHSTORAGE.getClientId()}/comment`});
            }

            deleting.current = false;
            return true;
        }
        return false;
    };

    return <RenderedComment
        comment={comment}
        deleteComment={deleteComment}
        updateComment={updateComment}
        authReaction={authReaction}
    />
};

const mapState = (state, ownProps) => {
    return {
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
    };
};

const mapAction = {
    deleteModelInstanceDispatch,
    updateModelInstanceDispatch
};

export default connect(mapState, mapAction) (RenderedCommentContainer);