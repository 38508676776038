import React, {Fragment,  useState} from 'react';
import {connect} from "react-redux";
import {getModelInstance, getModelInstances} from "../utils/store/selectors";
import User from "../utils/models/User";
import Chat from "../utils/models/Chat";
import AUTHSTORAGE from "../utils/auth/AuthStorage";
import {createSymmetricKey} from "../utils/helper/EncryptionHelper";
import {addModelInstance} from "../utils/store/actions";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../Routes";

const OpenChatContainer = ({authUser, user, chats, uid, children}) => {
    const [saving, setSaving] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const onMessage = () => {
        if (authUser.uid !== user.uid) {
            setSaving(true);

            let chat = chats.find((chatInstance) => {
                if (!chatInstance.archived && chatInstance.isSingleChat && chatInstance.member.length === 2) {
                    let userIds = chatInstance.member.map((user) => user.uid)
                    return userIds.includes(authUser.uid) && userIds.includes(user.uid);
                }
                return false;
            });

            if (!!chat) {
                setRedirect(chat.id);
            } else {
                let newChat = Chat.from(
                    {
                        "name": user.fullName + ", " + authUser.fullName,
                        "cUid": AUTHSTORAGE.getUserId(),
                        "count": 1,
                        "uMC": 0
                    }
                );
                newChat.isTemp = true;

                newChat.member = [user, authUser];
                newChat.sample = [user.getAsMinimalUser(), authUser.getAsMinimalUser()]
                newChat.symKey = createSymmetricKey({length: 32});

                addModelInstance(Chat, newChat);
                setRedirect(newChat.id);
            }
        }
    }

    if(redirect && typeof redirect=== "string")
        return <Redirect push to={ROUTES.messagesShow.replace(':id', redirect)} />;


    return(
        <Fragment>
            {
                children({onMessage})
            }
        </Fragment>
    );
};

const mapState = (state, ownProps) => {
    return {
        user: getModelInstance(state, User,ownProps.uid),
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId()),
        chats: getModelInstances(state, Chat)
    }
};

export default connect(mapState)(OpenChatContainer);
