import React, {useState} from 'react';
import MoinSearchField from "../../components/base/MoinSearchField";
import GroupTag from "../../components/group/GroupTag";
import {makeStyles} from "@material-ui/core/styles";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import GroupSearchEmptyState from "./GroupSearchEmptyState";
import MoinFab from "../../components/base/MoinFab";
import {useTranslation} from "react-i18next";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Permission} from "../../utils/enums/Permission";

const useStyles = makeStyles(() => ({
    searchField: {
        margin: '16px 0 8px 0'
    }
}));

/**
 *
 * @param {Array.<Distributor>} distributors
 * @param {String} search
 * @param {Function} onSearch
 * @returns {JSX.Element}
 * @constructor
 */
const GroupOverview = ({distributors, onSearch}) => {
    const [redirectToCreate, setRedirectToCreate] = useState(false);
    const [t, i18n] = useTranslation();
    const classes= useStyles();

    if(redirectToCreate) {
        return <Redirect push to={ROUTES.groupCreate}/>
    }

    return (
        <div>
            <MoinSearchField
                onChange={(e) => onSearch(e.target.value)}
                className={classes.searchField}
                placeholder={t('search.searchGroups')}
            />
            {
                (!distributors || distributors.length <= 0) &&
                    <GroupSearchEmptyState/>
            }
            {
                distributors.map(
                    distributor => !distributor.deleting && <GroupTag
                        distributorId={distributor.id}
                        key={"distributor-tag-" + distributor.id}
                    />
                )
            }
            {
                AUTHSTORAGE.hasPermission(Permission.GROUPCREATE) &&
                <MoinFab onClick={() => setRedirectToCreate(true)} />
            }
        </div>
    );
};

export default GroupOverview;