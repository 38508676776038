import {id, model, prop} from "../manager/decorators";
import {CURRENT_USER, SERVER_TIMESTAMP} from "../manager/decorators/sentinels";

@model('client/:client/comment_answer')
class CommentAnswer {

    @id()
    id;

    @prop()
    aId;

    @prop()
    cId;

    @prop({type: "date", readOnly: true, sentinel: SERVER_TIMESTAMP})
    cAt;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP})
    uAt;

    @prop()
    cName;

    @prop({sentinel: CURRENT_USER})
    cUid;

    @prop({})
    text;

    @prop({})
    media;

    @prop({})
    heart;

    @prop({})
    cCount;

    @prop({})
    reactions;

    allReactions;
}

export default CommentAnswer;

