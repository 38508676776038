import React, {useState} from 'react';
import parameters from '../../config/parameters.json';
import {connect} from "react-redux";
import GroupOverview from "./GroupOverview";
import {getModelInstances} from "../../utils/store/selectors";
import Distributor from "../../utils/models/Distributor";
import HIGHLIGHTEDGROUPS from "../../utils/groups/HighlightedGroups";
import {sortDistributor} from "../../utils/helper/SortHelper";

/**
 *
 * @param {Array.<Distributor>} distributors
 * @returns {JSX.Element}
 * @constructor
 */
const GroupOverviewContainer = ({distributors = []}) => {
    const [search, setSearch] = useState("");

    const onSearch = (search) => {
        setSearch(search);
    }

    if (search.length > 0) {
        distributors = distributors.filter(distributor =>  distributor.name.toLowerCase().includes(search.toLowerCase()));
    }

    distributors.filter( (distributor) => distributor && distributor.name);
    distributors.sort(sortDistributor);

    return(
        <GroupOverview distributors={distributors} onSearch={onSearch}/>
    );
};

const mapState = (state) => ({
    distributors: getModelInstances(state, Distributor),
});

export default connect(mapState)(GroupOverviewContainer);