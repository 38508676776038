import AuthStorage from "../../utils/auth/AuthStorage";
import MoinSimpleMenuOption from "../base/MoinSimpleMenuOption";
import {Permission} from "../../utils/enums/Permission";
import {useTranslation} from "react-i18next";
import MoinSimpleMenu from "../base/MoinSimpleMenu";
import React, {useState} from "react";
import GroupMembershipContainer from "../../pages/groups/GroupMembershipContainer";
import parameters from "../../config/parameters.json";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import ModelManager from "../../utils/manager/ModelManager";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import MoinButton from "../base/MoinButton";
import {updateModelInstance} from "../../utils/store/actions";
import {FireTimestamp} from "../../api/FirebaseService";


/**
 *
 * @param  {Event} event
 * @returns {JSX.Element}
 * @constructor
 */
const EventMenu = ({event}) => {
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [redirectToOverview, setRedirectToOverview] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [t] = useTranslation();

    const onDeleteConfirm = async () => {
        event.deleted = true;
        updateModelInstance(Event, event);
        setRedirectToOverview(true);
        await ModelManager.update({modelInstance: event, customData: {
                deleted : true,
                uAt: FireTimestamp()
            }
        });
    }

    const getDeleteDialog = () => {
        return (
            <Dialog
                open={showDelete}
                onClose={() => setShowDelete(false)}
                onClick={(event) => {event.stopPropagation(); event.preventDefault();return false}}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{ t('event.delete') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">{ t('event.deleteDescription', {title: event.title}) }</DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between'}}>
                    <MoinButton onClick={(event) => {setShowDelete(false);event.preventDefault(); return false;}} variant="outlined">{ t('buttons.cancel') }</MoinButton>
                    <MoinButton onClick={(event) => {setShowDelete(false);event.preventDefault(); onDeleteConfirm(); return false;}}>{ t('buttons.delete') }</MoinButton>
                </DialogActions>
            </Dialog>
        );
    };

    let contextMenuOptions = [];

    if(AuthStorage.hasPermission(Permission.EVENTEDIT) || event.cUid === AuthStorage.getUserId() || event.pUids?.includes(AuthStorage.getUserId())) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('event.edit'), (event) => {setRedirectToEdit(true);})); // setRedirectToEdit(true)));
    }

    if(AuthStorage.hasPermission(Permission.EVENTDELETE) || event.cUid === AuthStorage.getUserId() || event.pUids?.includes(AuthStorage.getUserId())) {
        contextMenuOptions.push(new MoinSimpleMenuOption( t('event.delete'), () => setShowDelete(true))); // setRedirectToEdit(true)));
    }

    if(redirectToEdit) {
        return <Redirect push to={ROUTES.eventEdit.replace(":id", event.id)}/>
    }

    if(redirectToOverview) {
        return <Redirect push to={ROUTES.events}/>
    }

    return <>
        {
            contextMenuOptions.length > 0 && <MoinSimpleMenu noWidth={true} options={contextMenuOptions}/>
        }
        {
            showDelete && getDeleteDialog()
        }
        </>
}

export  default EventMenu;