import React, {useEffect, useRef, useState} from 'react';
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import CommentDetail from "./CommentDetail";
import ModelManager from "../../utils/manager/ModelManager";
import Comment from "../../utils/models/Comment";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import User from "../../utils/models/User";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import EmptyState from "../../components/base/EmptyState";
import {Redirect, useHistory, withRouter} from "react-router-dom";
import CommentAnswer from "../../utils/models/CommentAnswer";
import CommentReaction from "../../utils/models/CommentReaction";
import Article from "../../utils/models/Article";
import * as ROUTES from "../../Routes";
import {FireDelete, FireIncrement, FireTimestamp} from "../../api/FirebaseService";

/**
 *
 * @param {string} id
 * @param {Article} article
 * @param {Comment} comment
 * @param {CommentAnswer[]} commentAnswers
 * @param {Function} addModelInstance
 * @param {CommentReaction} authReaction
 * @param {User} authUser
 * @returns {JSX.Element}
 * @constructor
 */
const CommentDetailContainer = ({id,  article, comment, allCommentAnswers,  authReaction, authUser}) => {
    const [noContent, setNoContent] = useState(false);
    const [loadedOnce, setLoadedOnce] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [rebuild, setRebuild] = useState(false);

    const deleting = useRef(false);
    const loading = useRef(false);
    const hasMoreComments = useRef(true);
    const commentBatchCount = 10;
    const commentAnswers = allCommentAnswers.filter(comment => comment.cId === id).sort((a, b) => a.cAt - b.cAt)

    useEffect(() => {
        async function fetchData() {
            if(!article) {
                try {
                    ModelManager.get({model: Article, id: id});
                } catch (ex) {
                }
            }

            if(!comment || !setLoadedOnce) {
                try {
                    let comment = await ModelManager.get({model: Comment, id: id});
                    setNoContent(!comment)
                    setLoadedOnce(true);
                } catch (ex) {
                    setNoContent(true);
                    setLoadedOnce(true);
                }
            }

            const commentAnswers = allCommentAnswers.filter(comment => comment.cId === id).sort((a, b) => a.cAt - b.cAt)
            if(comment && commentAnswers.length < commentBatchCount) {
                loadMoreCommentAnswers();
            }
        }

        fetchData();
    }, [comment]);

    if(redirect) {
        return (
            <Redirect push to={ROUTES.articleShow.replace(':id', article.id)}/>
        );
    }

    if (noContent) {
        return <EmptyState/>
    }

    const loadMoreCommentAnswers = (lastCreatedAt) => {
        if(!loading.current && hasMoreComments.current) {
            loading.current = true;
            if(!!lastCreatedAt) {
                ModelManager.list({model: CommentAnswer, limit: commentBatchCount, filter: [['cId', '==', id],['aId', '==', comment.aId]], orderBy: 'cAt', startAfter: lastCreatedAt}).then(result => {
                    loading.current = false;
                    hasMoreComments.current = result.length >= commentBatchCount;
                    setRebuild(!rebuild);
                });
            } else {
                ModelManager.list({model: CommentAnswer, limit: commentBatchCount, filter: [['cId', '==', id],['aId', '==', comment.aId]], orderBy: 'cAt'}).then(result => {
                    loading.current = false;
                    hasMoreComments.current = result.length >= commentBatchCount;
                    setRebuild(!rebuild);
                });
            }
        }
    };

    const deleteComment = () => {
        if(!deleting.current) {
            deleting.current = true;

            if(!!comment.image) {
                new FirebaseStorageManager().deleteFile(comment.image.replace(/%2F/g, '/'));
            }

            if(comment.aCount > 0) {
                let customData = {
                    dAt: FireTimestamp(),
                    text: FireDelete(),
                    image: FireDelete(),
                    media: FireDelete(),
                    reactions: FireDelete(),
                    dBy: AUTHSTORAGE.getUserId()
                };

                comment.dAt = new Date();

                ModelManager.update({modelInstance: comment, customData: customData});
            } else {
                article.cCount--;
                let articleUpdateData = {
                    cCount: FireIncrement(-1)
                };

                ModelManager.update({modelInstance: article, customData: articleUpdateData});
                ModelManager.delete({modelInstance: comment, resource: `/client/${AUTHSTORAGE.getClientId()}/comment`});
            }

            deleting.current = false;
            setRedirect(true);
            return true;
        }
        return false;
    };

    return (
        <CommentDetail
            comment={comment}
            commentAnswers={commentAnswers}
            reaction={authReaction}
            loadMoreCommentAnswers={loadMoreCommentAnswers}
            loadingComments={ loading.current}
            deleting={deleting.current}
            deleteComment={deleteComment}
        />
    );
};

const mapState = (state, ownProps) => {
    const comment = getModelInstance(state, Comment, ownProps.id);

    return {
        comment: comment,
        article: !!comment ? getModelInstance(state, Article, comment.aId) : undefined,
        allCommentAnswers: getModelInstances(state, CommentAnswer),
        authReaction: getModelInstance(state, CommentReaction, ownProps.id),
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
    };
};


export default connect(mapState)(withRouter(CommentDetailContainer));
