import parameters from '../../config/parameters.json';
import User from "../models/User";
import AUTHSTORAGE from "../auth/AuthStorage";
import {Feature} from "../enums/Feature";
import {ExternalVisibility} from "../enums/ExternalVisibility";

/**
 * get all instances of model
 * @param state
 * @param model
 * @return {*}
 */
export const getModelInstances = (state, model) =>  {

    if (state[model.key]) {
        if (model.key === "distributor") {
            let globalDistributor = getModelInstance(state,model,parameters.globalDistributorId);
            if (globalDistributor) {
                let index = state[model.key].findIndex( (distributor) => distributor.id === parameters.globalDistributorId);
                state[model.key][index] = globalDistributor;
            }
        }

        if (model.key === "user" && AUTHSTORAGE.getClient().externalVisibility === ExternalVisibility.DEPARTMENTS) {
            let authUser = getModelInstance(state, User, AUTHSTORAGE.getUserId());
            return state[model.key].filter( (user) => !user.external || ( !!authUser && !!user.department && user.department === authUser.department) );
        }
        return state[model.key];
    }
    return [];
};

/**
 * get instance of model by id
 * @param state
 * @param model
 * @param id
 * @return {null}
 */
export const getModelInstance = (state, model, id) => {
    if (state[model.key]) {
        let instance = state[model.key].find(modelInstance => modelInstance[model.metadata.idProp] === id);
        if (model.key === "user") {

            if (AUTHSTORAGE.getClient().externalVisibility === ExternalVisibility.DEPARTMENTS ) {
                let authUser = state[model.key].find(modelInstance => modelInstance[model.metadata.idProp] === AUTHSTORAGE.getUserId());
                return   instance  && !instance.deleted  && instance.enabled && (!instance.external || ( !!authUser && !!instance.department && instance.department === authUser.department) ) ? instance : null;
            }
            return  instance  && !instance.deleted  && instance.enabled ? instance : null;
        }

        if (model.key === "distributor" && !!instance && instance.id === parameters.globalDistributorId && state["user"]) {
            let users = getModelInstances(state,User).filter( (user) => !user.external);
            instance.count = users.length;
            instance.sample = users.slice(0,5);
        }
        return instance;
    }
    return null;
};