import React from 'react';
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import User from "../../utils/models/User";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import UserEdit from "./UserEdit";
import Department from "../../utils/models/Department";
import ModelManager from "../../utils/manager/ModelManager";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import * as ROUTES from "../../Routes";
import {Redirect} from "react-router-dom";
import * as ChatHelper from "../../utils/helper/ChatHelper";
import FireStorageUrl from "../../utils/models/FireStorageUrl";
import {deleteModelInstance} from "../../utils/store/actions";
import EmptyState from "../../components/base/EmptyState";
import SUCCESSNOTIFICATIONMANAGER from "../../components/success/SuccessNotificationManager";
import SuccessMessage from "../../utils/models/SuccessMessage";
import {SuccessType} from "../../utils/enums/SuccessType";

/**
 * User edit page container that loads the necessary information
 *
 * @param {String} uid
 * @param {User} user
 * @param {Array<Department>} departments
 * @returns {JSX.Element}
 * @constructor
 */
const UserEditContainer = ({uid, user, departments}) => {
    if (!user) {
        return <EmptyState/>
    }

    if (user.id !== AUTHSTORAGE.getUserId()) {
        return <Redirect push to={ROUTES.articleFeed} />
    }

    const updateUser = async (user, newAvatar, deleteAvatar = false, showOnlineState = true) => {
        let promises = [
            ModelManager.update({modelInstance: user, id: AUTHSTORAGE.getUserId().id}),
            ChatHelper.setShowOnlineState(showOnlineState)
        ];

        if (!!newAvatar) {
            promises.push(new FirebaseStorageManager().uploadImage(newAvatar, user.getAvatarUrl, 256 , 0.1));
            promises.push(new FirebaseStorageManager().uploadImage(newAvatar, user.getLargeAvatarUrl, 1200 , 1));
        } else if (deleteAvatar){
            promises.push(new FirebaseStorageManager().deleteFile(user.getAvatarUrl));
            promises.push(new FirebaseStorageManager().deleteFile(user.getLargeAvatarUrl));
        }
        await Promise.all(promises);

        SUCCESSNOTIFICATIONMANAGER.dispatch('successNotification',  new SuccessMessage(SuccessType.PROFILEUPDATED, true));

        let smallFirestoreUrl = new FireStorageUrl();
        smallFirestoreUrl.id =  user.getAvatarUrl;
        deleteModelInstance(FireStorageUrl, smallFirestoreUrl);

        let largeFirestoreUrl = new FireStorageUrl();
        largeFirestoreUrl.id =  user.getLargeAvatarUrl;
        deleteModelInstance(FireStorageUrl, largeFirestoreUrl);
    };



    return(
            <UserEdit
                user={user}
                departments={departments}
                clientType={AUTHSTORAGE.getClient().type}
                updateUser={updateUser}
            />
    );
};

const mapState = (state, ownProps) => {
    return {
        user: getModelInstance(state, User,ownProps.uid),
        departments: getModelInstances(state, Department)
    }
};

export default connect(mapState)(UserEditContainer);