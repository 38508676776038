import {id, model, prop} from "../manager/decorators";
import ArticleContent from "./ArticleContent";

@model(null)
class MinimalUser {

    @prop()
    uid;

    @prop()
    fName;

    @prop()
    lName;

    @prop()
    name;

    @prop()
    position;

    @prop()
    avatar;

    get fullName() {
        return !!this.name ? this.name : this.fName + " " + this.lName
    }

}

export default MinimalUser;

