import {id, model, prop} from "../manager/decorators";
import {CURRENT_USER, SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import ExternalLink from "./ExternalLink";

@model('client')
class Client {

    #_LOGO_IMAGE_PATH = '/client/{id}/logo';
    #_TOP_BAR_LOGO_IMAGE_PATH = '/client/{id}/top_bar_logo';

    @id()
    id;

    @prop()
    name;

    @prop()
    hrMail;

    @prop()
    shortName;

    @prop()
    workspace;

    @prop()
    address;

    @prop()
    costCenter;

    @prop()
    hTL;

    @prop()
    type;

    @prop({type: "object"})
    brand;

    @prop({type: "object"})
    trans;

    @prop({
        type: "array",
        typeOptions: {
            entry: "string"
        }
    })
    features = [];

    @prop({
        type: "array",
        typeOptions: {
            entry: "string"
        }
    })
    paid_features = [];

    @prop({
        type: "string",
    })
    externalVisibility = [];

    @prop({
        type: "array",
        typeOptions: {
            entry: "model",
            entryOptions: {
                target: ExternalLink
            }
        }
    })
    externalLinks = [];

    @prop({type: "date", readOnly: true, sentinel: SERVER_TIMESTAMP})
    createdAt;

    @prop({type: "bool", readOnly: true})
    isSeeded;

    @prop({sentinel: CURRENT_USER, readOnly: true})
    createdBy;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP})
    updatedAt;

    @prop({sentinel: CURRENT_USER})
    updatedBy;

    @prop({type: "bool"})
    enabled = false;

    elasticAccess;

    getLogo = () => this.#_LOGO_IMAGE_PATH.replace("{id}", this.id);
    getTopBarLogo = () => this.#_TOP_BAR_LOGO_IMAGE_PATH.replace("{id}", this.id);

    isDemoClient = () => typeof this.isSeeded === "boolean" && this.isSeeded;
}

export default Client;

