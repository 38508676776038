import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {Colors} from "../helper/ColorHelper";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import MoinButton from "../base/MoinButton";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import TaskForm from "./TaskForm";
import CloseIcon from "../../assets/icons/close.svg";
import MoinSimpleMenu from "../base/MoinSimpleMenu";
import AttachFileIcon from "../../assets/icons/attachment.svg";
import MoinSimpleMenuOption from "../base/MoinSimpleMenuOption";
import PDFIcon from "../../assets/icons/pdf.svg";
import {MediaContentType} from "../../utils/enums/MediaContentType";
import MoinUpload from "../base/MoinUpload";
import {humanFileSize} from "../../utils/helper/TextHelper";

const useStyles = makeStyles((theme) => ({
    addTaskDialog: {
        zIndex: '1250 !important',
        margin: '0 20px',
        '& .MuiDialog-paper': {
            borderRadius: '16px',
            maxWidth: '640px',
            width: '100%'
        },
        '& .MuiButton-outlined': {
            marginLeft: 'auto'
        },
        '& .MuiTextField-root': {

        }
    },
    addTaskDialogContent: {
        padding: '12px 4px 0',
        '& .MuiFormHelperText-contained': {
            marginLeft: '8px'
        }
    },
    closeIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: '24px',
        top: '24px'
    },
    dialogActions: {
        padding: '0 12px 12px'
    },
    dialogCancel: {
        cursor: 'pointer',
        marginRight: '12px',
        '& p': {
            color: Colors.BLACKLIGHT,
        }
    },
    addButton: {
        padding: '8px 24px'
    },
    attach: {
        margin: '0 auto 0 12px !important',
        '& svg': {
            height: '20px',
            width: '20px',
        }
    },
    attachIcon : {
        display: "flex"
    },
    selectedDocumentWrapper: {
        maxWidth: '328px',
        width: '100%',
        height: '64px',
        borderRadius: '8px',
        position: 'relative',
        margin: '0',
        border: '1px solid' + Colors.BLUELIGHT,
        display: 'flex',
        alignItems: 'center',
        padding: '16px 40px 16px 12px',
        marginTop: '12px',
        '&:last-child': {
            marginBottom: '12px'
        }
    },
    fileIcon: {
        height: '32px !important',
        width: '32px !important',
        minWidth: '32px',
        fill: Colors.BRANDPRIMARY,
        marginRight: '12px'
    },
    selectedDocument: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        '& h4': {
            fontWeight: '500',
            textTransform: 'none',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
        '& h6': {
            lineHeight: '13px'
        }
    },
    documentMediaWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 12px'
    },
    fileInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    selectedMediaWrapper: {
        minWidth: '80px',
        width: '80px',
        height: '80px',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '8px',
        '& img': {
            width: '80px',
            height: '80px',
            objectFit: 'cover'
        }
    },
    allMediaWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '608px',
        overflowY: 'auto',
        marginTop: '6px',
        padding: '0 12px'
    },
    deleteMediaIcon: {
        position: 'absolute',
        height: '24px',
        width: '24px',
        borderRadius: '25px',
        backgroundColor: Colors.BRANDPRIMARY,
        top: '4px',
        right: '4px',
        cursor: 'pointer',
        color: Colors.WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    deleteMediaIconDocument: {
        top: '12px',
        right: '8px'
    }
}));

/**
 *
 * @param {Function} onCreate
 * @param {Function} onClose
 * @constructor
 */
const AddTasksDialog = ({onCreate, onClose}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [dueDate, setDueDate] = useState();
    const [assignee, setAssignee] = useState();

    const [search, setSearch] = useState(assignee !== undefined ? assignee.fullName : '');

    const [repetition, setRepetition] = useState();

    const [errors, setErrors] = useState({});
    const [forceRebuild, setForceRebuild] = useState(true);

    const validate = () => {
        let hasError = false;

        if (!title) {
            errors['title'] = t('form.errors.cantBeEmpty', {field: t('tasks.taskName')});
            hasError = true;
        } else if (title.trim().length <= 2) {
            errors['title'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('tasks.taskName'), chars: 3});
            hasError = true;
        } else if (title.trim().length > 200) {
            errors['title'] = t('form.errors.mustBeLessThanXCharacters', {field: t('tasks.taskName'), chars: 200});
            hasError = true;
        }

        setErrors(errors);
        setForceRebuild(!forceRebuild);
        return !hasError;
    }

    const onCreateTask = () => {
        if (validate()) {
            let data = {
                title: title.trim()
            }

            if (description) {
                data['description'] = description;
            }

            if (assignee) {
                data['assignee'] = assignee;
            }

            if (repetition) {
                data['repetition'] = repetition;
            }

            if (dueDate) {
                data['dueDate'] =  dueDate.toJSDate();
            }

            if (selectedImages) {
                data['images'] =  selectedImages;
            }

            if (selectedDocuments) {
                data['documents'] =  selectedDocuments;
            }
            onCreate(data);
            onClose();
        }
    }

    const imageInputField = useRef();
    const documentInputField = useRef();

    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const acceptedImages = ['image/png', 'image/jpeg', 'image/gif'];
    const acceptedDocuments = ['application/pdf'];

    let contextMenuOptions = [
        new MoinSimpleMenuOption( t('chat.image'), (event) => imageInputField.current.click()),
        new MoinSimpleMenuOption( t('chat.document'), (event) => documentInputField.current.click()),
    ];

    const removeSelectedImage = (index) => {
        setSelectedImages(selectedImages.filter((image, i) => i !== index));
    };

    const removeSelectedDocument = (index) => {
        setSelectedDocuments(selectedDocuments.filter((image, i) => i !== index));
    };

    const renderSelectedImage = (image, index) => {
        return (
            <div key={"selected-image-" + index} className={classes.selectedMediaWrapper}>
                <img src={URL.createObjectURL(image)} className={classes.selectedImage}/>
                <div className={classes.deleteMediaIcon} onClick={() => removeSelectedImage(index)}>
                    <CloseIcon fill={Colors.WHITE}/>
                </div>
            </div>
        );
    };

    const renderSelectedDocument = (document, index) => {
        return (
            <div key={"selected-document-" + index} className={classes.selectedDocumentWrapper}>
                <div className={classes.selectedDocument}>
                    <PDFIcon fill={Colors.BLACK} className={classes.fileIcon}/>
                    <div className={classes.fileInfo}>
                        <Typography variant="h4">{ document.name }</Typography>
                        <Typography variant="h6">{ humanFileSize(document.size) }</Typography>
                    </div>
                </div>
                <div className={`${classes.deleteMediaIcon} ${classes.deleteMediaIconDocument}`} onClick={() => removeSelectedDocument(index)}>
                    <CloseIcon fill={Colors.WHITE} />
                </div>
            </div>
        );
    };

    const dropFile = (files) => {
        let images = selectedImages;
        let documents = selectedDocuments;

        for (let i = 0; i < files.length; i++) {
            let elem = files[i];

            if (acceptedImages.includes(elem.type)) {
                images.push(elem);
            } else if (acceptedDocuments.includes(elem.type)) {
                documents.push(elem);
            }
        }

        setSelectedImages(images);
        setSelectedDocuments(documents);
        setForceRebuild(!forceRebuild);
    }

    return (
        <Dialog id={'showAddTask'}
            key={"addTaskDialog"}
            open={true}
            onClose={() => onClose()}
            className={classes.addTaskDialog}>
            <MoinUpload isMultipleInput={true} onChangeFile={files => dropFile(files)} type={MediaContentType.IMAGEORPDF} disableClick={true} allowMultiple={true}>
                <DialogContent className={classes.addTaskDialogContent}>
                    <CloseIcon className={classes.closeIcon} onClick={() => onClose()} fill={Colors.BLACK}/>
                    <TaskForm
                        title={title}
                        description={description}
                        dueDate={dueDate}
                        assignee={assignee}
                        repetition={repetition}
                        setTitle={setTitle}
                        setDescription={setDescription}
                        setDueDate={setDueDate}
                        setAssignee={setAssignee}
                        search={search}
                        setSearch={setSearch}
                        setRepetition={setRepetition}
                        errors={errors}
                    />
                    <div className={`${classes.allMediaWrapper}`}>
                        {
                            selectedImages.map((image, index) => renderSelectedImage(image, index))
                        }
                    </div>
                    <div className={`${classes.documentMediaWrapper}`}>
                        {
                            selectedDocuments.map((image, index) => renderSelectedDocument(image, index))
                        }
                    </div>
                    <input ref={(fileInput) => imageInputField.current = fileInput} type="file" accept={acceptedImages.join(',')} style={{display: 'none'}} multiple onChange={(e) => setSelectedImages([...selectedImages, ...e.target.files]) }/>
                    <input ref={(fileInput) => documentInputField.current = fileInput} type="file" accept={acceptedDocuments.join(',')} style={{display: 'none'}} multiple onChange={(e) => setSelectedDocuments([...selectedDocuments, ...e.target.files]) }/>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <div className={classes.attach}>
                        <MoinSimpleMenu options={contextMenuOptions} className={classes.attachIcon}>
                            <AttachFileIcon fill={Colors.BLACKLIGHT}/>
                        </MoinSimpleMenu>
                    </div>

                    <span className={classes.dialogCancel}
                          onClick={() => {
                              onClose()
                          }}>
                        <Typography variant={"body2"}>{t('buttons.cancel')}</Typography>
                    </span>
                    <MoinButton className={classes.addButton} onClick={onCreateTask}>
                        {t('tasks.createTask')}
                    </MoinButton>
                </DialogActions>
            </MoinUpload>
        </Dialog>
    );
}
export default AddTasksDialog;
