import React, {useEffect, useRef, useState, Fragment} from "react";
import Article from "../../utils/models/Article";
import MoinButton from "../../components/base/MoinButton";
import {useTranslation} from "react-i18next";
import MoinTextField from "../../components/base/MoinTextField";
import MoinGroupSelector from "../../components/base/MoinGroupSelector";
import ArticleContent from "../../utils/models/ArticleContent";
import {ContentType} from "../../utils/enums/ContentType";
import TextContentRow from "../../components/article/TextContentRow";
import {makeStyles} from "@material-ui/core/styles";
import {Divider, Typography} from "@material-ui/core";
import {Colors} from "../../components/helper/ColorHelper";
import {getArticleTitlePath} from "../../utils/helper/ImageHelper";
import MoinSimpleMenu from "../../components/base/MoinSimpleMenu";
import MoinSimpleMenuOption from "../../components/base/MoinSimpleMenuOption";
import ImageContentRow from "../../components/article/ImageContentRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../Routes";
import Survey from "../../utils/models/Survey";
import SurveyContentRow from "../../components/article/SurveyContentRow";
import {SurveyType} from "../../utils/enums/SurveyType";
import SurveyOption from "../../utils/models/SurveyOption";
import DocumentContentRow from "../../components/article/DocumentContentRow";
import VideoContentRowContainer from "../../components/article/VideoContentRowContainer";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";

import AddIcon from '../../assets/icons/plus.svg';
import MoinUnsavedChangesDialog from "../../components/base/MoinUnsavedChangesDialog";
import MoinDivider from "../../components/base/MoinDivider";
import MoinImageUpload from "../../components/base/MoinImageUpload";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme) => ({
    articleTitleImageContainer: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.WHITE
    },
    articleTitleImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: "cover"
    },
    selectTitleImageInfo: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        marginTop: '25%'
    },
    actionButtons: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    divider: {
        marginTop: theme.spacing(3)
    },
    titleInput: {
        margin: '16px 0'
    },
    articleTitleImageWrapper: {
        width: '100%',
        display: 'block',
    },
    contentPadding: {
        padding: ' 16px 20px'
    }
}));

/**
 * @param {Article} initialArticle
 * @param {Function} saveArticle
 * @param {Survey[]} existingSurveys
 * @param {String} preselectedDisId
 * @param {String} originPath
 * @param {boolean} isCopy
 * @constructor
 */
const ArticleCreateUpdate = ({initialArticle, saveArticle, existingSurveys, preselectedDisId, originPath, isCopy = false}) => {
    const classes = useStyles();
    let isEdit = useRef(false);
    let article = useRef(initialArticle);

    const videoUploads = useRef([]);
    const [articleTitle, setArticleTitle] = useState("");
    const [articleTitleImage, setArticleTitleImage] = useState();
    const [articleTitleImageChanged, setArticleTitleImageChanged] = useState(false);

    const [selectedDistributor, setSelectedDistributor] = useState();
    const [contentParts, setContentParts] = useState([]);
    const [oldMediaUrls, setOldMediaUrls] = useState([]);
    const [surveys, setSurveys] = useState({});
    const [hasChanges, setHasChanges] = useState(false);
    const [forceRebuild, setForceRebuild] = useState(true);
    const [saving, setSaving] = useState(false);
    const [redirect, setRedirect] = useState();

    const [errors, setErrors] = useState({});

    const [t, i18n] = useTranslation();

    useEffect(() => {
        isEdit.current = !!article.current;
        if(isEdit.current) {
            setArticleTitle(article.current.title);
            setContentParts(article.current.content);

            let oldMediaUrls = [];

            article.current.content.forEach(content => {
                if(content.type === ContentType.MEDIA) {
                    if(!!content.image) {
                        oldMediaUrls.push(content.image);
                    } else if(!!content.video) {
                        oldMediaUrls.push(content.video.url);
                        oldMediaUrls.push(content.video.thumbnail);
                    }
                } else if(content.type === ContentType.PDF) {
                    oldMediaUrls.push(content.path);
                }
            });

            setOldMediaUrls(oldMediaUrls);
        } else {
            article.current = new Article();
        }
    }, []);

    useEffect(() => {
        if(isEdit.current) {
            if(!!existingSurveys) {
                existingSurveys.forEach(survey => {
                    surveys[survey.id] = survey;
                });

                setSurveys(surveys);
                setForceRebuild(!forceRebuild);
            }
        }
    }, [existingSurveys]);

    const validate = () => {
        let hasError = false;

        if (!articleTitle) {
            errors['title'] = t('form.errors.cantBeEmpty', {field: t('article.edit.titleOfArticle')});
            hasError = true;
        } else if (articleTitle.length < 2) {
            errors['title'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('article.edit.titleOfArticle'), chars: 2});
            hasError = true;
        } else if (articleTitle.length > 92) {
            errors['title'] = t('form.errors.mustBeLessThanXCharacters', {field: t('article.edit.titleOfArticle'), chars: 92});
            hasError = true;
        }

        // Only check on Article creation
        if (!isEdit.current || isCopy) {
            if (!selectedDistributor) {
                errors['distributor'] = t('article.edit.selectGroup');
                hasError = true;
            }
        }

        // Only check on Article creation
        if (!contentParts || contentParts.length === 0) {
            errors['other'] = {};
            errors['other']['body'] = t('article.edit.mustHaveContent');
            errors['other']['title'] = t('article.edit.noArticleContent');
            hasError = true;
        }

        if (!hasError && contentParts && contentParts.length > 0) {

            contentParts.forEach( (part) => {
                if (!hasError && part && part.type) {
                    switch (part.type) {
                        case ContentType.TEXT:
                            if (!part.text) {
                                errors['other'] = {};
                                errors['other']['title'] = t('article.edit.noTextInTextSection');
                                errors['other']['body'] = t('article.edit.noTextInTextSectionContent');
                            }
                            break;
                        case ContentType.MEDIA:
                            if (part.image != null && !part.image) {
                                errors['other'] = {};
                                errors['other']['title'] = t('article.edit.noImageInImageSection');
                                errors['other']['body'] = t('article.edit.noImageInImageSectionContent');
                            }
                            if (!!part.video && !part.video.url) {
                                errors['other'] = {};
                                errors['other']['title'] = t('article.edit.noVideoInVideoSection');
                                errors['other']['body'] = t('article.edit.noVideoInVideoSectionContent');
                            }
                            break;
                        case ContentType.SURVEY:
                            let survey = surveys[part.surveyId];
                            if (!survey.question) {
                                errors['other'] = {};
                                errors['other']['title'] = t('article.edit.noQuestionInSurveySection');
                                errors['other']['body'] = t('article.edit.noQuestionInSurveySectionContent');
                            }
                            else if (!survey.options || (survey.options.length < 2 &&  survey.type !== SurveyType.FREEANSWER ) ){
                                errors['other'] = {};
                                errors['other']['title'] = t('article.edit.surveyWithToViewOptions');
                                errors['other']['body'] = t('article.edit.surveyWithToViewOptionsContent');
                            } else if (survey.options && survey.type !== SurveyType.FREEANSWER){
                                let optionKeys = Object.keys(survey.options );
                                optionKeys.forEach( (key) => {
                                    if (survey.options[key] && !survey.options[key].text) {
                                        errors['other'] = {};
                                        errors['other']['title'] = t('article.edit.optionWithoutText');
                                        errors['other']['body'] = t('article.edit.optionWithoutTextContent');
                                    }
                                });
                            }
                            break;
                        case ContentType.PDF:
                            if (!part.name) {
                                errors['other'] = {};
                                errors['other']['title'] = t('article.edit.noPDFinPDFSection');
                                errors['other']['body'] = t('article.edit.noPDFinPDFSectionContent');
                            }
                            break;
                        default:
                            break;
                    }
                    if (errors['other']) {
                        hasError = true;
                    }
                }
            })
        }

        setErrors(errors);
        setForceRebuild(!forceRebuild);
        return !hasError;
    };

    const onSave = async () => {
        if(!saving && validate()) {
            setSaving(true);

            await Promise.all(videoUploads.current);
            article.current.title = articleTitle;
            article.current.content = contentParts;
            article.current.content =  article.current.content.filter((content) => !(content.type === ContentType.MEDIA && !content.image && !content.video && !content.media ) );

            if(isEdit.current && !isCopy) {
                let oldTitleImage =  article.current.content.find( (content) => content.image != null && content.iTI );
                if (!!oldTitleImage) {
                    oldMediaUrls.push(oldTitleImage.image);
                    article.current.content =  article.current.content.filter((content) => !content.image || !content.iTI );
                }
                await saveArticle(article.current, articleTitleImage, oldMediaUrls, surveys, existingSurveys, articleTitleImageChanged);
            } else {
                if (isCopy && !articleTitleImage)  {
                    let oldTitleImage =  article.current.content.find( (content) => content.image != null && content.iTI );
                    if (!!oldTitleImage) {
                        article.current.content =  article.current.content.filter((content) => !content.image || !content.iTI );


                    }
                }
                await saveArticle(article.current, articleTitleImage, selectedDistributor, surveys);
            }
            setSaving(false);
            setRedirect( originPath || ROUTES.articleFeed );
        }
    };

    const onAddTextContent = () => {
        let newContent = new ArticleContent();
        newContent.type = ContentType.TEXT;
        setHasChanges(true);
        setContentParts([...contentParts, newContent])
    };

    const onAddImageContent = () => {
        let newContent = new ArticleContent();
        newContent.type = ContentType.MEDIA;
        newContent.image = "";
        setHasChanges(true);
        setContentParts([...contentParts, newContent])
    };

    const onAddVideoContent = () => {
        let newContent = new ArticleContent();
        newContent.type = ContentType.MEDIA;
        newContent.video = {};
        setHasChanges(true);
        setContentParts([...contentParts, newContent])
    };

    const onAddSurveyContent = () => {
        let newContent = new ArticleContent();
        newContent.type = ContentType.SURVEY;
        setHasChanges(true);

        let newSurvey = new Survey();
        newSurvey.type = SurveyType.SINGLECHOICE;
        newSurvey.options = {
            0: new SurveyOption(),
            1: new SurveyOption()
        };
        newContent.surveyId = newSurvey.id;

        surveys[newSurvey.id] = newSurvey;
        setSurveys(surveys);
        setContentParts([...contentParts, newContent])
    };

    const onAddDocumentContent = () => {
        let newContent = new ArticleContent();
        newContent.type = ContentType.PDF;
        setHasChanges(true);

        setContentParts([...contentParts, newContent])
    };

    const deleteContentIndex = (index) => {
        setHasChanges(true);
        setContentParts(contentParts.filter((val, i) => i !== index));
    };

    const setContent = (index,content) => {
        setHasChanges(true);
        let parts=[...contentParts];
        parts[index] = content;
        setContentParts(parts);
    };

    /**
     * @param {ArticleContent} content
     * @param {number} index
     */
    const renderContent = (content, index) => {
        switch (content.type) {
            case ContentType.TEXT:
                return <TextContentRow key={"create-article-text-content-" + index} value={content.text} onDelete={() => deleteContentIndex(index)} onChange={(value) => {
                    content.text = value;
                    setContent(content, index);
                }}/>
            case ContentType.MEDIA:
                // Has to be 'content.image != null' because an empty string is used when the content was just added
                if(content.image != null && !content.iTI) {
                    return (
                        <ImageContentRow key={"create-article-image-content-" + index} value={content} onDelete={() => deleteContentIndex(index)} onChange={(value) => {
                            content = value;
                            content.image = content.image ?? "";
                            setContent(content, index);
                        }}/>
                    );
                } else if(!!content.video) {
                    return (
                        <VideoContentRowContainer
                            key={"create-article-video-content-" + index}
                            value={content}
                            articleId={article.current.id}
                            onDelete={() => deleteContentIndex(index)}
                            onUpload={ (upload) => videoUploads.current.push(upload) }
                            onChange={(value) => {
                                content = value;
                                setContent(content, index);
                            }}
                        />
                    );
                }
                break;
            case ContentType.SURVEY:
                return (
                    <SurveyContentRow
                        key={"create-article-survey-content-" + index}
                        value={surveys[content.surveyId]}
                        onDelete={() => deleteContentIndex(index)}
                        onChange={(value) => {
                            surveys[content.surveyId] = value;
                            setHasChanges(true);
                            setSurveys(surveys);
                            setContent(content, index);
                        }}
                        isEdit={isEdit.current && !isCopy}
                    />
                );
            case ContentType.PDF:
                return (
                    <DocumentContentRow key={"create-article-document-content-" + index}
                        value={content}
                        onDelete={() => deleteContentIndex(index)}
                        onChange={(value) => {
                            content = value;
                            setContent(content, index);
                        }}
                    />
                )
        }
    }

    if(redirect) {
        return (
            <Redirect push to={redirect}/>
        )
    }

    const addContentOptions = [
        new MoinSimpleMenuOption( t('article.edit.addTextSection'), onAddTextContent),
        new MoinSimpleMenuOption( t('article.edit.addImage'), onAddImageContent),
        new MoinSimpleMenuOption( t('article.edit.addVideo'), onAddVideoContent),
        new MoinSimpleMenuOption( t('article.edit.addSurvey'), onAddSurveyContent),
        new MoinSimpleMenuOption( t('article.edit.addPDF'), onAddDocumentContent),
    ];

    let distributorId = "";

    if(isEdit.current && article.current.dis && !isCopy) {
        distributorId = article.current.dis[0];
    } else if(!!selectedDistributor) {
        distributorId = selectedDistributor.id;
    } else if(!!preselectedDisId) {
        distributorId = preselectedDisId;
    }

    return (
        <Fragment>
            <MoinUnsavedChangesDialog hasChanges={hasChanges} />

            {
                errors && errors['other'] &&
                    <Dialog
                        open={true}
                        onClose={() => {errors['other'] = undefined; setErrors(errors);  setForceRebuild(!forceRebuild); }}
                        onClick={(event) => {event.stopPropagation(); event.preventDefault();return false}}
                        aria-labelledby="error-dialog-title"
                        aria-describedby="error-dialog-description"
                    >
                        <DialogTitle id="error-dialog-title">{ errors['other']['title'] }</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="delete-dialog-description">{  errors['other']['body'] }</DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center'}}>
                            <MoinButton onClick={(event) => { errors['other'] = undefined; setErrors(errors); setForceRebuild(!forceRebuild); event.preventDefault(); return false;}} variant="outlined">{ t('common.ok') }</MoinButton>
                        </DialogActions>
                    </Dialog>
            }

            <MoinCard>
                <MoinCardHeader>
                    <Typography variant="h3">
                        {
                            initialArticle
                                ? t('article.editArticle')
                                : t('article.createArticle')
                        }
                    </Typography>
                </MoinCardHeader>
                <MoinDivider />
                <MoinImageUpload
                    onChangeCallback={files => {
                        setHasChanges(true);
                        setArticleTitleImage(files);
                        setArticleTitleImageChanged(true);
                    }}
                    fullWidth={true}
                    image={isEdit.current && article.current.hTI  && getArticleTitlePath(article.current)}
                />
                <MoinCardContent className={classes.contentPadding}>
                    <MoinGroupSelector
                        placeholder={ t('article.edit.selectGroup') }
                        selectedDistributorId={distributorId}
                        onSelectDistributor={(d) => {
                            setHasChanges(true);
                            setSelectedDistributor(d);
                         }}
                        disabled={isEdit.current && !isCopy}
                        error={errors['distributor']}
                        executeScroll={errors['distributor']}
                    />
                    <MoinTextField
                        placeholder={ t('article.edit.titleOfArticle') }
                        onChange={(e) => {
                            setHasChanges(true);
                            setArticleTitle(e.target.value)
                        }}
                        value={articleTitle}
                        error={errors['title']}
                        executeScroll={errors['title']}
                        className={classes.titleInput}
                    />

                    {
                        contentParts.map((content, index) => renderContent(content, index))
                    }

                    <div className={classes.actionButtons}>
                        <MoinSimpleMenu disabled={saving} options={addContentOptions}>
                            <MoinButton disabled={saving} ><AddIcon fill={Colors.WHITE}/> {t('article.edit.addContent')}</MoinButton>
                        </MoinSimpleMenu>
                    </div>
                </MoinCardContent>
                <Divider/>
                <MoinCardHeader>
                    {
                        saving &&
                        <div className="moin-circular-progress-container">
                            <CircularProgress />
                        </div>
                    }
                    {
                        !saving &&
                        <MoinButton disabled={saving} onClick={onSave} className={classes.actionButton}>{t('article.edit.publish')}</MoinButton>
                    }
                </MoinCardHeader>
            </MoinCard>
        </Fragment>
    );
};

export default ArticleCreateUpdate;
