import {getModelInstances} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import {connect} from "react-redux";
import React from "react";
import Mention from "../../utils/models/Mention";
import {MentionType} from "../../utils/enums/MentionType";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import FireStorageImage from "../base/FirestoreImage";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    resultWrapper: {
        position: 'absolute',
        width: '100%',
        maxHeight: '400px',
        overflowX: 'scroll',
        backgroundColor: Colors.WHITE,
        borderTop: "1px solid",
        borderColor: Colors.BLUELIGHT,
        borderRadius: "16px 16px 0 0"
    },
    suggestedUser: {
        display: "flex",
        alignItems: 'center',
        position: "relative",
        textAlign: "left",
        padding: '12px 24px',
        cursor: 'pointer'
    },
    userAvatar: {
        marginRight: '8px'
    }
}));

/**
 * @param {User[]} users
 * @param textFieldRef
 * @param {string} textValue
 * @param {function} replaceText
 * @param {HTMLElement} parent
 * @return {JSX.Element}
 * @constructor
 */
const MentionAutocomplete = ({users, textFieldRef, textValue, replaceText, parent}) => {
    const classes = useStyles();

    // Cursor position: textFieldRef.current.selectionStart

    /**
     * @param {User} user
     */
    const onUserClick = (user) => {
        let cursorPosition = textFieldRef.current.selectionStart;
        if(cursorPosition > 0) {
            let lastAtSignAt = textValue.substring(0, cursorPosition).lastIndexOf(' ')+1;
            let endOfAtSignAt = cursorPosition;

            endOfAtSignAt = endOfAtSignAt > 0 ? endOfAtSignAt : textValue.length;

            let mention = new Mention();
            mention.entityType = MentionType.USER;
            mention.entityId = user.id;
            mention.fallbackName = user.fullName;

            let replacement = mention.mentionString + " ";
            textValue = textValue.replace(textValue.substring(lastAtSignAt, endOfAtSignAt), replacement);

            replaceText(textValue);
            let newCursorOffset = cursorPosition + replacement.length - (endOfAtSignAt - lastAtSignAt);
            textFieldRef.current.setSelectionRange(newCursorOffset, newCursorOffset);
            textFieldRef.current.focus();
        }
    };

    const renderResults = () => {
        if(!!textFieldRef.current) {
            let showMentions = false;
            let searchText;
            let cursorPosition = textFieldRef.current.selectionStart;

            if(cursorPosition > 0) {
                let textBeforeCursor = textValue.substring(0, cursorPosition);
                let lastAtSignAt = textBeforeCursor.lastIndexOf('@');

                if(((lastAtSignAt > 0 && textValue.charAt(lastAtSignAt-1) === " ") || lastAtSignAt === 0) && textBeforeCursor.length > lastAtSignAt + 1) {
                    let endOfAtSignAt = textBeforeCursor.indexOf(' ', lastAtSignAt);
                    showMentions = endOfAtSignAt < 0;
                }

                if(showMentions) {
                    searchText = textValue.substring(lastAtSignAt+1, textBeforeCursor.length).toLowerCase();
                }
            }

            if(showMentions) {
                let filteredUsers = users.filter(user => user.fullName.toLowerCase().includes(searchText));

                let maxHeight = parent?.getBoundingClientRect()?.y;
                if (!maxHeight || maxHeight > 575) {
                    maxHeight = 400;
                } else {
                    maxHeight = maxHeight - 175;
                }

                return (
                    <div className={classes.resultWrapper} style={{bottom: `${(parent?.offsetHeight ?? 0) + 1}px`, maxHeight: `${maxHeight}px`}}>
                        {
                            filteredUsers.map( (user,index, collection) => (
                                <div key={"mention-suggestion-" + user.uid}>
                                    <div className={classes.suggestedUser} onClick={() => onUserClick(user)}>
                                        <FireStorageImage
                                            className={classes.userAvatar}
                                            radius={16}
                                            isAvatar={true}
                                            src={user.avatar}
                                            alt={user.fullName}
                                            backgroundColor={Colors.identifierAsColor(user.id)}
                                        />
                                        { user.fullName }
                                    </div>
                                    {
                                        (index < collection.length -1) &&
                                            <Divider/>
                                    }
                                </div>
                            ))
                        }
                    </div>
                )
            }
        }

        return <div/>;
    }

    return renderResults();
};

const mapState = (state) => ({
    users: getModelInstances(state, User)
});

export default connect(mapState)(MentionAutocomplete);