import {id, model, prop} from "../manager/decorators";
import {CURRENT_USER, SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import AllReactions from "./AllReactions";

@model('client/:client/comment')
class Comment {

    @id()
    id;

    @prop()
    aId;

    @prop({type: "date", readOnly: true, sentinel: SERVER_TIMESTAMP})
    cAt;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP})
    uAt;

    @prop({type: "date"})
    dAt;

    @prop()
    dBy;

    @prop()
    cName;

    @prop({sentinel: CURRENT_USER})
    cUid;

    @prop({})
    text;

    @prop({})
    image;

    @prop({})
    media;

    @prop()
    heart;

    @prop()
    aCount;

    @prop({
        type: "object",
        typeOptions: {
            entry: "model",
            entryOptions: {
                target: AllReactions
            }
        }
    })
    reactions;

    allReactions;
}

export default Comment;

