import {id, model, prop} from "../manager/decorators";
import {SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import TaskActivity from "./TaskActivity";
import ContentDocument from "./ContentDocument";
import TaskRepetition from "./TaskRepetition";
import {
    addMonthsToDate,
    getDateDifferenceInDays, getDateDifferenceInMonths,
    getStartOfDay,
    getStartOfFirstWeekOfMonth,
    getStartOfLastWeekOfMonth, getStartOfNextMonth, getStartOfWeek, isAtSameDay
} from "../helper/DateHelper";
import {RepetitionType} from "../enums/repetition/RepetitionType";
import {RepetitionWeekdays} from "../enums/repetition/RepetitionWeekdays";
import {RepetitionWeek} from "../enums/repetition/RepetitionWeek";
import {getIntFromRepetitionWeek} from "../helper/EnumHelper";
import CustomRepetitionInterval from "./CustomRepetitionInterval";
import {CustomRepetitionIntervalType} from "../enums/repetition/CustomRepetitionIntervalType";
import {TaskActivityType} from "../enums/TaskActivityType";

@model("/client/:client/task")
class Task
{

    @id()
    id;

    @prop()
    title;

    @prop()
    description;

    @prop({type: "bool"})
    completed = false;

    @prop({type: "date"})
    dueDate;

    @prop({type: "date", readOnly:true,  sentinel: SERVER_TIMESTAMP})
    cAt;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP})
    uAt;

    @prop({type: "date", nullable:true})
    dAt;

    @prop()
    cUid;

    @prop()
    aUid;

    @prop({
        nullable: false,
        type: "array",
        typeOptions: {
            entry: "string"
        }
    })
    relevant = [];

    @prop({
        nullable: false,
        type: "array",
        typeOptions: {
            entry: "string"
        }
    })
    noNot = [];

    @prop({
        nullable: false,
        type: "array",
        typeOptions: {
            entry: "model",
            entryOptions: {
                target: TaskActivity
            }
        }
    })
    activities = [];


    @prop({
        nullable: false,
        type: "array",
        typeOptions: {
            entry: "string",
        }
    })
    images = [];

    @prop({
        nullable: false,
        type: "array",
        typeOptions: {
            entry: "model",
            entryOptions: {
                target: ContentDocument
            }
        }
    })
    documents = [];

    @prop({
        type: "model",
        typeOptions: {
            target: TaskRepetition
        }
    })
    repetition;

    @prop({
        type: "array",
        typeOptions: {
            entry: "date",
        }
    })
    completedRepetitions = [];

    getCompletedStatusForDate(date) {
        if(!!this.repetition && this.repetition.type !== RepetitionType.NEVER) {
            return !!this.activities.find(activity => !!activity && activity.type === TaskActivityType.FINISHED && Math.abs((activity.cAt.getTime() - date.getTime()) / 1000) < 5);
        }

        return this.completed;
    }

    getNextRepetition() {
        let nextRepetitionDate = this.getNextRepetitionAfterDate(new Date());

        if(nextRepetitionDate != null) {
            while(this.completedRepetitions.some(date => isAtSameDay(date, nextRepetitionDate))) {
                nextRepetitionDate.setDate(nextRepetitionDate.getDate() + 1);
                nextRepetitionDate = this.getNextRepetitionAfterDate(nextRepetitionDate);
            }
        }

        return nextRepetitionDate;
    }

    /**
     * @param {Date} date
     */
    getNextRepetitionAfterDate(date) {
        if(this.repetition && this.dueDate) {
            if(date.getTime() < this.dueDate.getTime()) {
                date = this.dueDate;
            }

            let startOfDate = new Date(date);
            startOfDate.setHours(0, 0, 0, 0);

            let returnDate = new Date(date);
            let weekdayOfDay = date.getDay();

            if(weekdayOfDay === 0) {
                weekdayOfDay = 7;
            }

            let weekdayOfDueDate = this.dueDate.getDay();

            if(weekdayOfDueDate === 0) {
                weekdayOfDueDate = 7;
            }

            switch (this.repetition.type) {
                case RepetitionType.DAILY:
                    // If the task should repeat daily on work days
                    if(this.repetition.dailyRepetitionWeekdays === RepetitionWeekdays.WORKDAYS) {
                        if(weekdayOfDay < 6) {
                            return date;
                        } else {
                            returnDate.setDate(returnDate.getDate() + (7 - weekdayOfDay) + 1);
                            return returnDate;
                        }
                    } else {
                        // If the task should repeat every single day
                        return date;
                    }
                case RepetitionType.WEEKLY:
                    // If the task should repeat weekly, and the current day is one of the selected days
                    if(this.repetition.weeklyRepetitionWeekdays.includes(weekdayOfDay - 1)) {
                        return date;
                    } else {
                        let sortedRepetitionDays = this.repetition.weeklyRepetitionWeekdays.sort();

                        // If a selected repetition day is in this week in the future
                        if(this.repetition.weeklyRepetitionWeekdays.some(selectedDay => selectedDay > (weekdayOfDay - 1))) {
                            let nextWeekday = sortedRepetitionDays.find(element => element > (weekdayOfDay - 1));
                            returnDate.setDate(returnDate.getDate() + (nextWeekday - (weekdayOfDay - 1)));
                            return returnDate;
                        } else {
                            // If the next day for this task is next week
                            let nextWeekBeginning = new Date(date);
                            nextWeekBeginning.setDate(nextWeekBeginning.getDate() + (7 - weekdayOfDay) + 1);

                            nextWeekBeginning.setDate(nextWeekBeginning.getDate() + sortedRepetitionDays[0]);
                            return nextWeekBeginning;
                        }
                    }
                case RepetitionType.MONTHLY:
                    let repetitionWeekInt = getIntFromRepetitionWeek(this.repetition.monthlyRepetitionWeek);
                    let startOfFirstWeek = getStartOfFirstWeekOfMonth(date);
                    let weekOfNow = Math.floor(getDateDifferenceInDays(startOfFirstWeek, date) / 7) + 1;

                    // If the week this task should repeat in is this week and the day it should repeat on is today or in the future
                    if(weekOfNow === repetitionWeekInt && weekdayOfDueDate >= weekdayOfDay) {
                        let dayDifference = weekdayOfDueDate - weekdayOfDay;
                        returnDate.setDate(returnDate.getDate() + dayDifference);
                        return returnDate;
                    } else if(repetitionWeekInt > weekOfNow) {
                        // If the next repetition for this task is this month, but another week
                        let weekDifference = repetitionWeekInt - weekOfNow;
                        let dayDifference = weekdayOfDueDate - weekdayOfDay;

                        returnDate.setDate(returnDate.getDate() + dayDifference + (weekDifference * 7));
                        return returnDate;
                    } else {
                        // If the next repetition for this task is next month
                        let startOfNextMonth = getStartOfNextMonth(date);
                        let weekOfRepetition = new Date(startOfNextMonth);
                        weekOfRepetition.setDate(weekOfRepetition.getDate() + repetitionWeekInt * 7);
                        weekOfRepetition.setDate(weekOfRepetition.getDate() + weekdayOfDueDate - 1);
                        return weekOfRepetition;
                    }
                case RepetitionType.ANNUAL:
                    let repetitionDateThisYear = new Date(date.getFullYear(), this.dueDate.getMonth(), this.dueDate.getDate());

                    // If this task has yet to be repeated this year
                    if(repetitionDateThisYear.getTime() > startOfDate.getTime()) {
                        return repetitionDateThisYear;
                    } else {
                        // If the next repetition of this task is next year
                        return new Date(date.getFullYear() + 1, this.dueDate.getMonth(), this.dueDate.getDate());
                    }
                case RepetitionType.CUSTOM:
                    // If this task should repeat every x weeks
                    if(this.repetition.customInterval.type === CustomRepetitionIntervalType.WEEK) {
                        let weeksSinceStart = Math.floor(getDateDifferenceInDays(getStartOfWeek(this.dueDate), date) / 7);

                        // If this task should repeat this week
                        if(weeksSinceStart % this.repetition.customInterval.amount === 0) {
                            // If this task should repeat today
                            if(this.repetition.customInterval.repetitionDays.includes(weekdayOfDay - 1)) {
                                return returnDate;
                            } else {
                                // If this task should repeat this week on another day
                                let sortedRepetitionDays = this.repetition.customInterval.repetitionDays.sort();

                                // If the repetition day for this task is this week in the future
                                if(this.repetition.customInterval.repetitionDays.some(selectedDay => selectedDay > (weekdayOfDay - 1))) {
                                    let nextWeekday = sortedRepetitionDays.find(element => element > (weekdayOfDay - 1));
                                    returnDate.setDate(returnDate.getDate() + (nextWeekday - (weekdayOfDay - 1)));
                                    return returnDate;
                                } else {
                                    // If the repetition day for this task is this week, but has already passed
                                    let nextRepetitionWeek = new Date(startOfDate);
                                    nextRepetitionWeek.setDate(nextRepetitionWeek.getDate() + (this.repetition.customInterval.amount * 7));

                                    let weekdayOfNextRepetitionWeek = nextRepetitionWeek.getDay();
                                    if(weekdayOfNextRepetitionWeek === 0) {
                                        weekdayOfNextRepetitionWeek = 7;
                                    }

                                    let startOfNextRepetitionWeek = new Date(nextRepetitionWeek);
                                    startOfNextRepetitionWeek.setDate(startOfNextRepetitionWeek.getDate() - (weekdayOfNextRepetitionWeek - 1));

                                    let weekdayOfStartOfNextRepetitionWeek = startOfNextRepetitionWeek.getDay();
                                    if(weekdayOfStartOfNextRepetitionWeek === 0) {
                                        weekdayOfStartOfNextRepetitionWeek = 7;
                                    }

                                    let nextWeekday = sortedRepetitionDays.find(element => element >= (weekdayOfStartOfNextRepetitionWeek - 1));
                                    startOfNextRepetitionWeek.setDate(startOfNextRepetitionWeek.getDate() + (nextWeekday - (weekdayOfStartOfNextRepetitionWeek - 1)));
                                    return startOfNextRepetitionWeek;
                                }
                            }
                        } else {
                            // If this task should repeat in another week

                            if(this.repetition.customInterval.amount > weeksSinceStart) {
                                // If the first repetition is in the future

                                let nextRepetitionWeek = new Date(startOfDate);
                                nextRepetitionWeek.setDate(nextRepetitionWeek.getDate() + ((this.repetition.customInterval.amount - weeksSinceStart) * 7));
                                let weekdayOfNextRepetitionWeek = nextRepetitionWeek.getDay();
                                if(weekdayOfNextRepetitionWeek === 0) {
                                    weekdayOfNextRepetitionWeek = 7;
                                }

                                let startOfNextRepetitionWeek = new Date(nextRepetitionWeek);
                                startOfNextRepetitionWeek.setDate(startOfNextRepetitionWeek.getDate() - (weekdayOfNextRepetitionWeek - 1));
                                let sortedRepetitionDays = this.repetition.customInterval.repetitionDays.sort();

                                startOfNextRepetitionWeek.setDate(startOfNextRepetitionWeek.getDate() + (sortedRepetitionDays[0]));
                                return startOfNextRepetitionWeek;
                            } else {
                                let nextWeekRepetition = Math.ceil(weeksSinceStart / this.repetition.customInterval.amount);
                                let missingWeeks = (nextWeekRepetition * this.repetition.customInterval.amount) - weeksSinceStart;
                                let nextRepetitionWeek = new Date(returnDate);
                                nextRepetitionWeek.setDate(nextRepetitionWeek.getDate() + (missingWeeks * 7));

                                let weekdayOfNextRepetitionWeek = nextRepetitionWeek.getDay();
                                if(weekdayOfNextRepetitionWeek === 0) {
                                    weekdayOfNextRepetitionWeek = 7;
                                }

                                let sortedRepetitionDays = this.repetition.customInterval.repetitionDays.sort();
                                let startOfNextRepetitionWeek = new Date(nextRepetitionWeek);
                                startOfNextRepetitionWeek.setDate(startOfNextRepetitionWeek.getDate() - (weekdayOfNextRepetitionWeek - 1));
                                startOfNextRepetitionWeek.setDate(startOfNextRepetitionWeek.getDate() + sortedRepetitionDays[0]);
                                return startOfNextRepetitionWeek;
                            }
                        }
                    } else {
                        // If the task should repeat every x months

                        let monthsSinceStart = getDateDifferenceInMonths(this.dueDate, date);

                        if(monthsSinceStart % this.repetition.customInterval.amount === 0) {
                            // If the task should repeat this month

                            if(this.repetition.customInterval.monthDay >= startOfDate.getDate()) {
                                let dayDifference = this.repetition.customInterval.monthDay - startOfDate.getDate();
                                returnDate.setDate(returnDate.getDate() + dayDifference);
                            } else {
                                let nextRepetitionMonth = addMonthsToDate(startOfDate, this.repetition.customInterval.amount);
                                returnDate = new Date(nextRepetitionMonth.getFullYear(), nextRepetitionMonth.getMonth(), this.repetition.customInterval.monthDay);
                            }
                        } else {
                            // If the task should repeat in a different month
                            let nextMonthRepetition = Math.ceil(monthsSinceStart / this.repetition.customInterval.amount);
                            let missingMonths = (nextMonthRepetition * this.repetition.customInterval.amount) - monthsSinceStart;
                            let nextRepetitionMonth = addMonthsToDate(startOfDate, missingMonths);
                            returnDate = new Date(nextRepetitionMonth.getFullYear(), nextRepetitionMonth.getMonth(), this.repetition.customInterval.monthDay);
                        }

                        // If the day is not the same day, that this task should repeat on
                        // calculate the next repetition after this date
                        // This can happen if the user selects the 31. day of a month for repetition
                        // because not all months have 31 days
                        if(returnDate.getDate() === this.repetition.customInterval.monthDay) {
                            return returnDate;
                        } else {
                            return this.getNextRepetitionAfterDate(returnDate);
                        }
                    }
                case RepetitionType.NEVER:
                    return null;
            }
        }
    }

    /**
     * @param {Date} day
     */
    isRepeatingAtDay(day) {
        if(this.repetition && this.dueDate) {
            if(this.completedRepetitions.find(element => isAtSameDay(element, day))) {
                return false;
            }

            let startOfDay = getStartOfDay(day);
            let startOfDueDate = getStartOfDay(this.dueDate);

            let weekdayOfDay = startOfDay.getDay();

            if(weekdayOfDay === 0) {
                weekdayOfDay = 7;
            }

            if(startOfDay.getTime() >= startOfDueDate.getTime()) {
                switch (this.repetition.type) {
                    case RepetitionType.DAILY:
                        if(this.repetition.dailyRepetitionWeekdays === RepetitionWeekdays.WORKDAYS) {
                            return startOfDay.getDay() > 0 && startOfDay.getDay() < 6;
                        } else {
                            return true;
                        }
                    case RepetitionType.WEEKLY:
                        return this.repetition.weeklyRepetitionWeekdays.includes(weekdayOfDay - 1);
                    case RepetitionType.MONTHLY:
                        if(startOfDay.getDay() === startOfDueDate.getDay()) {
                            let startOfFirstWeek = getStartOfFirstWeekOfMonth(startOfDay);
                            let startOfLastWeek = getStartOfLastWeekOfMonth(startOfDay);

                            let totalWeeksInMonth = Math.floor(getDateDifferenceInDays(startOfFirstWeek, startOfLastWeek) / 7) + 1;
                            let weekOfDay = Math.floor(getDateDifferenceInDays(startOfFirstWeek, startOfDay) / 7) + 1;

                            if(this.repetition.monthlyRepetitionWeek !== RepetitionWeek.LAST) {
                                let repetitionWeekInt = getIntFromRepetitionWeek(this.repetition.monthlyRepetitionWeek);
                                return repetitionWeekInt === weekOfDay;
                            } else if(weekOfDay === totalWeeksInMonth) {
                                return true;
                            }
                        }

                        break;
                    case RepetitionType.ANNUAL:
                        return startOfDay.getDay() === startOfDueDate.getDay() && startOfDay.getMonth() === startOfDueDate.getMonth();
                    case RepetitionType.CUSTOM:
                        if(this.repetition.customInterval.type === CustomRepetitionIntervalType.WEEK) {
                            let diffWeeks = Math.floor(getDateDifferenceInDays(startOfDueDate, startOfDay) / 7) + 1;
                            return (diffWeeks % this.repetition.customInterval.amount) === 0 && this.repetition.customInterval.repetitionDays.includes(weekdayOfDay - 1);
                        } else {
                            let startMonthDifference = getDateDifferenceInMonths(startOfDueDate, startOfDay);
                            return (startMonthDifference % this.repetition.customInterval.amount) === 0 && startOfDay.getDate() === this.repetition.customInterval.monthDay;
                        }
                    case RepetitionType.NEVER:
                        return false;
                }
            }
        }

        return false;
    }
}

export default Task;
