import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {master, theme} from "../assets/jss/masterLayout";

class MasterTheme {
    #_useStyles = makeStyles(master(theme("light")));

    recreateStyles() {
        this.#_useStyles = makeStyles(master(theme("light")));
    }

    getStyles() {
        return this.#_useStyles();
    }

}

const MASTERTHEME = new MasterTheme();

export default MASTERTHEME;

