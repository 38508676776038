class FavoritedArticles {
    #_favoritedArticles = {};

    /**
     *
     * @param {Object} highlightedArticles
     */
    setFavoritedArticles = (highlightedArticles) => {
        this.#_favoritedArticles = {};
        Object.keys(highlightedArticles).forEach(articleId => {
            this.#_favoritedArticles[articleId] = highlightedArticles[articleId].toDate();
        });
    };

    toggleArticleFavorited = (articleId) => {
        if(this.isFavorited(articleId)) {
            delete this.#_favoritedArticles[articleId];
        } else {
            this.#_favoritedArticles[articleId] = new Date();
        }
    }

    isFavorited(articleId) {
        return Object.keys(this.#_favoritedArticles).includes(articleId);
    }

    getArticleFavoritedDate(articleId) {
        return this.#_favoritedArticles[articleId];
    }

    reset() {
        this.#_favoritedArticles = [];
    }
}

const FAVORITEDARTICLES = new FavoritedArticles();

export default FAVORITEDARTICLES;
