import React, { useEffect, useState } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import {useHistory, withRouter} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

import CheckIcon from "../../assets/icons/check.svg";
import InfoIcon from "../../assets/icons/info.svg";
import SUCCESSNOTIFICATIONMANAGER from "./SuccessNotificationManager";
import CloseIcon from "../../assets/icons/close.svg";

const useStyles = makeStyles((theme) => ({
    notificationWrapper: {
        position: "fixed",
        top: "70px",
        zIndex: 1111,
        display: "flex",
        justifyContent: "center",
        width: "100vw",
        marginLeft: '120px', // HALF OF NAVBAR WIDTH
        cursor: 'pointer'
    },
    content: {
        marginLeft: "8px",
        marginRight: "24px",
        fontSize: '13px',
        lineHeight: '18px'
    },
    notification: {
        width: '100%',
        height: '100%',
        color: Colors.BLACK,
        background: Colors.SUCCESS + '26',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px 10px",
        borderRadius: "8px",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
    },
    notificationBackdrop: {
        maxWidth: "768px",
        background: 'white',
        borderRadius: "8px",
    }
}));

const SuccessNotification = ({ location}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const history = useHistory();

    let timeout = undefined;
    let [visibility, setVisibility] = useState(false);
    let [content, setContent] = useState('');
    let [success, setSuccess] = useState(false);


    useEffect(() => {
        /* Data should be a SuccessMessage */
        SUCCESSNOTIFICATIONMANAGER.addListener('successNotification', ({data}) => {
            if (data) {
                if (!!timeout) {
                    clearTimeout(timeout);
                }

                let message = data.getMessage(t);
                setContent(message);
                setSuccess(data.success);
                setVisibility(true);

                // A Human reads about 120 Words per minute, he has to read the text at least twice
                let additionalTime = (( (message.length ?? 0)) /120) * 1000;

                timeout = setTimeout(() => {
                    setVisibility(false);
                }, 4000 + additionalTime);
            }
        });
        return () => {
            SUCCESSNOTIFICATIONMANAGER.removeListener('customNotification');
        }

    }, [ location]);


    return (
        visibility &&
        <div className={classes.notificationWrapper}>
            <div className={classes.notificationBackdrop}>
                <div className={classes.notification}>
                    {
                        success
                            ? <CheckIcon fill={Colors.SUCCESS} />
                            : <InfoIcon fill={Colors.ERROR} />
                    }

                    <Typography variant={'body2'} className={classes.content}>{content}</Typography>

                    <CloseIcon fill={success ? Colors.SUCCESS :Colors.ERROR } onClick={
                        (event) => {
                            setVisibility(false);
                            event.stopPropagation();
                            event.preventDefault();
                            return false;
                        }
                    }/>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SuccessNotification);