import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from "@material-ui/core/Container";
import {useTranslation} from "react-i18next";
import MoinTextField from "../components/base/MoinTextField";
import MoinButton from "../components/base/MoinButton";
import illustration from "../assets/illustrations/moin-app-illus-website-header.png";
import logo from "../assets/illustrations/logo.png";
import {Colors} from "../components/helper/ColorHelper";
import {Link, withRouter, useHistory} from "react-router-dom";
import * as ROUTES from "../Routes";
import {Typography} from "@material-ui/core";
import parameters from '../config/parameters.json';
import LoginHelper from "../utils/helper/LoginHelper";

import ChevronLeft from '../assets/icons/chevron-left.svg';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        zIndex: 2000,
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    illustration: {
        width: '65%',
        height: 'auto'
    },
    logo: {
        width: '40%',
        height: 'auto',
        marginRight: 'auto',
        marginBottom: '24px'
    },
    backToLoginLink: {
        textDecoration: 'none',
        color: Colors.BRANDSECONDARY,
        '&:hover': {
            color: Colors.BRANDPRIMARY,
            '& svg': {
                fill: Colors.BRANDPRIMARY
            }
        },
        display: 'block',
        width: '100%',
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        '& svg': {
            fill: Colors.BRANDSECONDARY,
        }
    },
    resetHint: {
        margin: "8px 0"
    },
    link: {
        textDecoration: 'none',
        color: Colors.BRANDSECONDARY,
        '&:hover': {
            color: Colors.BRANDPRIMARY
        },
        display: 'block',
        width: '100%',
        textAlign: 'center',
        padding: "0 1rem",
    },
    bottomLinks: {
        marginTop: '32px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    colorOverlay: {
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        position: 'fixed',
        backgroundColor: Colors.BLUEBACKGROUND,
        zIndex: 0
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    content: {
        zIndex: 1,
        position: 'relative'
    }
}));

const ForgotPasswordPage = () => {

    const [t] = useTranslation();
    const [workspace, setWorkspace] = useState('');
    const [username, setUsername] = useState('');
    const [submitErrors, setSubmitErrors] = useState({});
    const [resetSend, setResetSend] = useState(undefined);
    const [resetFailed, setResetFailed] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const history = useHistory();


    const classes = useStyles();

    const submit = () =>  {
        let errors = {};

        if (!workspace) {
            errors['workspace'] = t('form.errors.cantBeEmpty', {field: t('login.workspace')});
        } else if (workspace.length < 3) {
            errors['workspace'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('login.workspace'), chars: 3});
        }

        if (!username) {
            errors['username'] = t('form.errors.cantBeEmpty', {field: t('login.username')});
        } else if (username.length < 3) {
            errors['username'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('login.username'), chars: 3});
        }

        setSubmitErrors(errors);
        if (Object.keys(errors).length  === 0 ) {
            setWaiting(true);
            let accountName = LoginHelper.getFromWorkspace(username, workspace);

            let xmlHttp = new XMLHttpRequest();

            xmlHttp.onreadystatechange = function () {
                if (xmlHttp.readyState === 4) {

                    if (xmlHttp.status === 200) {
                        let jsonResponse = JSON.parse(xmlHttp.responseText);
                        setResetSend(jsonResponse.success);
                        setResetFailed(!jsonResponse.success)
                    } else {
                        setResetSend(false);
                        setResetFailed(true)
                    }
                    setWaiting(false);
                }
            }
            xmlHttp.open("POST", parameters.backendUrl + '/forgot-password');
            xmlHttp.setRequestHeader('Content-type', 'application/json');
            xmlHttp.send(JSON.stringify({email: accountName}));
        }
    }

    return (
        <>
            <div className={classes.colorOverlay}/>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={illustration} alt={t('common.illustration')} className={`${classes.illustration} ${classes.content}`}/>
                    <img src={logo} alt={t('common.moinlogo')} className={`${classes.logo} ${classes.content}`}/>
                    <div className={classes.row}>
                        <Link to={ROUTES.login} className={`${classes.backToLoginLink} ${classes.row} ${classes.content}`}>
                            <ChevronLeft />
                            {t('login.backToLogin')}
                        </Link>
                    </div>
                    <div className={`${classes.row} ${classes.content}`}>
                        <Typography variant={"h5"}> {t('login.forgotPassword')}</Typography>
                    </div>
                    <form className={classes.form} noValidate
                          onSubmit={(e) => {
                              submit(e);
                              e.preventDefault();
                              e.stopPropagation();
                              return false;
                          }}
                    >
                        {
                            !resetSend
                            && <>
                                {
                                    resetFailed
                                    &&
                                    <Typography variant={"body2"} className={`${classes.resetHint} ${classes.content}`}>
                                        {t('login.passwordResetFailed')}
                                    </Typography>
                                }
                                <MoinTextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="workspace"
                                    label= {t('login.workspace')}
                                    value={workspace}
                                    name="workspace"
                                    autoComplete="workspace"
                                    autoFocus

                                    onChange={(e) => setWorkspace(e.target.value)}
                                    error={ submitErrors.hasOwnProperty('workspace')}
                                    helperText={ submitErrors.hasOwnProperty('workspace') &&  submitErrors["workspace"]}
                                />
                                <MoinTextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label= {t('login.username')}
                                    name="username"
                                    value={username}
                                    autoComplete="username"
                                    autoFocus

                                    onChange={(e) => setUsername(e.target.value)}
                                    error={ submitErrors.hasOwnProperty('username')}
                                    helperText={ submitErrors.hasOwnProperty('username') &&  submitErrors["username"]}
                                />

                                <MoinButton
                                    disabled={waiting}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={submit}
                                >
                                    {t('login.resetPassword')}
                                </MoinButton>
                            </>
                        }

                        {
                            resetSend &&
                            <>
                                <Typography variant={"body2"}>
                                    {t('login.passwordResetSuccess')}
                                </Typography>

                                <MoinButton
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={
                                        () => history.push(ROUTES.login)
                                    }
                                >
                                    {t('login.backToLogin')}
                                </MoinButton>
                            </>
                        }
                        <div className={classes.bottomLinks}>
                            <Link to={ROUTES.dpd} className={classes.link}>
                                {t('legal.dpd')}
                            </Link>

                            <Link to={ROUTES.terms} className={classes.link}>
                                {t('legal.terms')}
                            </Link>

                            <Link to={ROUTES.impress} className={classes.link}>
                                {t('legal.impress')}
                            </Link>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    );
};
export default withRouter(ForgotPasswordPage);
