import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ContentType} from "../../utils/enums/ContentType";
import ArticleTeaserTag from "./ArticleTeaserTag";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    tagOverlay: {
        position: 'absolute',
        left: '0',
        bottom: '0',
        margin: '0 0 12px 16px',
        display: 'flex'
    },
    tagRow: {
        display: 'flex',
        padding: '12px 8px 0px',
    }
}));

/**
 * Tags for an article to be displayed in the header of the teaser/detail view
 *
 * @param {Article} article
 * @param {boolean} absolute
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleTeaserTags = ({article, absolute = true}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    return <div className={absolute ? classes.tagOverlay : classes.tagRow}>
        {
            (article.content.filter(element => element.type === ContentType.SURVEY).length > 0) &&
            <ArticleTeaserTag label={t('article.edit.survey')} />
        }
        {
            (article.content.filter(element => element.type === ContentType.PDF).length > 0) &&
            <ArticleTeaserTag label={t('article.edit.pdf')} />
        }
        {
            (article.content.filter(element => element.type === ContentType.MEDIA && element.video != null).length > 0) &&
            <ArticleTeaserTag label={t('article.edit.video')} />
        }
    </div>
};

export default ArticleTeaserTags;