import React from "react";
import {useTranslation} from "react-i18next";
import FireStorageImage from "../base/FirestoreImage";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import MoinUpload from "../base/MoinUpload";
import MoinTextField from "../base/MoinTextField";
import MoinImageUpload from "../base/MoinImageUpload";

import CameraIcon from "../../assets/icons/camera.svg";
import DeleteIcon from "../../assets/icons/delete.svg";

const useStyles = makeStyles((theme) => ({
    imageWrapper: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.WHITE
    },
    articleTitleImage: {
        height: '100%',
        width: '100%',
        position: 'absolute'
    },
    selectTitleImageInfo: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        marginTop: '25%'
    },
    wrapper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    contentOptions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    deleteIcon: {
        cursor: 'pointer',
        fill: Colors.BLACK
    }
}));

/**
 * @param {Function} onDelete
 * @param {Function} onChange
 * @param {ArticleContent} value
 * @returns {JSX.Element}
 * @constructor
 */
const ImageContentRow = ({onDelete, onChange, value}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();


    const onImageChange = (image) => {
        value.image = image;
        onChange(value);
    };

    return(
        <div className={classes.wrapper}>
            <div className={classes.contentOptions}>
                <div className={classes.row}>
                    <Typography variant="h3">{t('article.edit.image')}</Typography>
                    <DeleteIcon className={classes.deleteIcon} onClick={onDelete}/>
                </div>
                <div>
                    {/* TODO: change Order Buttons */}
                </div>
            </div>
            <MoinImageUpload
                file={value.image instanceof File ? value.image : undefined}
                image={typeof value.image === "string" ? value.image : undefined}
                fullWidth={true}
                onChangeCallback={onImageChange}
            />

        </div>
    )
}

export default ImageContentRow;
