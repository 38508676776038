import React, {Fragment, useRef, useState} from "react";
import {MoinCard, MoinCardContent, MoinCardHeader} from "../../components/base/MoinCard";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {HugType} from "../../utils/enums/HugType";
import {Colors} from "../../components/helper/ColorHelper";
import {getIconForHug} from "../../components/helper/IconHelper";
import AddIcon from '../../assets/icons/plus.svg';
import MoinTextField from "../../components/base/MoinTextField";
import MoinButton from "../../components/base/MoinButton";
import SelectHugUsersView from "./SelectHugUsersView";
import FireStorageImage from "../../components/base/FirestoreImage";
import EditIcon from "../../assets/icons/edit.svg";
import MoreHorizIcon from "../../assets/icons/more-horizontal.svg";
import Grow from "@material-ui/core/Grow";

const useStyles = makeStyles(() => ({
    content: {
        padding: '32px 16px',
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    hugDescription: {
        marginTop: '16px',
        marginBottom: '56px',
        width: '65%'
    },
    hugIcons: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: 25,
        position: 'relative'
    },
    hugIconWrapper: {
        height: '80px',
        width: '80px',
        borderRadius: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '16px 32px',
        cursor: 'pointer'
    },
    hugIcon: {
        fill: Colors.WHITE,
        height: '40px',
        width: '40px'
    },
    smallHugIconWrapper: {
        height: '40px',
        width: '40px',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '16px',
        cursor: 'pointer'
    },
    smallHugIcon: {
        fill: Colors.WHITE,
        height: '20px',
        width: '20px'
    },
    selectedHugIconWrapper: {
        marginTop: '40px',
        marginBottom: '46px',
        height: '120px',
        width: '120px',
        borderRadius: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 25,
        position: 'relative'
    },
    selectedHugIcon: {
        fill: Colors.WHITE,
        height: '56px',
        width: '56px'
    },
    selectUsersRow: {
        width: '100%',
        marginBottom: '32px',
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row'
    },
    addButton: {
        backgroundColor: Colors.BRANDSECONDARY,
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '12px',
        '&:hover': {
            backgroundColor: Colors.BRANDTERTIARY,
        },
    },
    addButtonIcon: {
        height: '24px',
        width: '24px',
        fill: Colors.WHITE
    },
    sendButton: {
        marginTop: '32px'
    },
    userEditIcon: {
        fill: Colors.BRANDPRIMARY
    },
    userEditIconWrapper: {
        border: `1px solid ${Colors.BLUELIGHT}`,
        backgroundColor: Colors.WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconAnimation: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '-8px',
        marginTop: '-58px',
        zIndex: 998
    },
    cardWrapper: {
        position: 'relative'
    },
    animateHugIconWrapper: {
        width: '100%',
        height: '100%',
        borderRadius: '16px'
    },
    textFieldWrapper: {
        width: '100%'
    },
    blueCircleBackground: {
        position: 'absolute',
        backgroundColor: Colors.BLUELIGHT,
        width: '100%',
        height: '35%',
        borderRadius: '0 0 100% 100%',
        marginLeft: '-8px',
        marginTop: '-58px'
    },
    cardTitle: {
        position: 'relative',
        zIndex: 25
    },
    rowBreak: {
        flexBasis: '100%',
        height: 0
    }
}));

const GiveHugsOverview = ({sendHugToUsers}) => {
    const [selectedHugType, setSelectedHugType] = useState();
    const [animateHugType, setAnimateHugType] = useState();
    const [showOverlay, setShowOverlay] = useState(false);
    const [hugIconRef, setHugIconRef] = useState();
    const [showUserSelection, setShowUserSelection] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [hugText, setHugText] = useState("");
    const wrapperRef = useRef();
    const classes = useStyles();
    const [t] = useTranslation();

    if(showUserSelection) {
        return (
            <SelectHugUsersView
                initialSelectedUsers={selectedUsers}
                finishSelection={users => {
                    setSelectedUsers(users);
                    setShowUserSelection(false);
                }}
            />
        );
    }

    const renderHugSelection = () => {
        return (
            <>
                <Typography variant="h3">{ t('hugs.giveHugs') }</Typography>
                <Typography variant="body2" className={classes.hugDescription}>{ t('hugs.giveHugsDescription') }</Typography>

                <div className={classes.hugIcons}>
                    {
                        Object.values(HugType).map((hugType, index) => {
                            let HugIcon = getIconForHug(hugType);

                            return (
                                <Fragment key={'hug-selection-' + hugType}>
                                    <div className={classes.hugIconWrapper} style={{backgroundColor: Colors.colorForHugType(hugType)}} onClick={(e) => {
                                        setAnimateHugType(hugType);
                                        setHugIconRef(e.target);
                                        setShowOverlay(true);
                                    }}>
                                        <HugIcon className={classes.hugIcon}/>
                                    </div>

                                    {
                                        index === 2 && <div className={classes.rowBreak}/>
                                    }
                                </Fragment>
                            );
                        })
                    }
                </div>
            </>
        );
    }

    const renderUserSelectRow = () => {
        if(selectedUsers.length > 0) {
            const radius = 20;

            let shownUsers = selectedUsers;
            if(shownUsers.length > 7) {
                shownUsers = selectedUsers.slice(0, 7);
            }

            return (
                <div className={classes.selectUsersRow} onClick={() => setShowUserSelection(true)}>
                    <div className={classes.userEditIconWrapper} style={{width: radius*2, height: radius*2, borderRadius: radius, zIndex: shownUsers.length + 1}}>
                        <EditIcon className={classes.userEditIcon}/>
                    </div>
                    {
                        shownUsers.map((user, index) => (
                            <FireStorageImage
                                radius={radius}
                                backgroundColor={Colors.identifierAsColor(user.id)}
                                style={{marginLeft: Math.ceil(-(radius / (5/3))) + "px", zIndex: shownUsers.length - index}}
                                key={"avatar-user-" + user.uid}
                                isAvatar={true}
                                src={user.avatar}
                                alt={user.fullName}
                            />
                        ))
                    }
                    {
                        selectedUsers.length > 7
                        && (
                            <div className={classes.userEditIconWrapper} style={{width: radius*2, height: radius*2, borderRadius: radius, marginLeft: Math.ceil(-(radius / (5/3))) + "px"}}>
                                <MoreHorizIcon className={classes.userEditIcon}/>
                            </div>
                        )
                    }
                </div>
            );
        }

        return (
            <div className={classes.selectUsersRow} onClick={() => setShowUserSelection(true)}>
                <div className={classes.addButton}>
                    <AddIcon className={classes.addButtonIcon}/>
                </div>
                <Typography variant="h2">{ t('hugs.addContacts') }</Typography>
            </div>
        );
    };

    const renderHugSendForm = () => {
        let SelectedHugIcon = getIconForHug(selectedHugType);

        return (
            <>
                <div className={classes.hugIcons}>
                    {
                        Object.values(HugType).filter(t => t !== selectedHugType).map(hugType => {
                            let HugIcon = getIconForHug(hugType);

                            return (
                                <div key={'hug-send-form-icon-' + hugType} className={classes.smallHugIconWrapper} style={{backgroundColor: Colors.colorForHugType(hugType)}} onClick={() => setSelectedHugType(hugType)}>
                                    <HugIcon className={classes.smallHugIcon}/>
                                </div>
                            );
                        })
                    }
                </div>

                <div className={classes.selectedHugIconWrapper} style={{backgroundColor: Colors.colorForHugType(selectedHugType)}}>
                    <SelectedHugIcon className={classes.selectedHugIcon}/>
                </div>

                { renderUserSelectRow() }

                <div className={classes.textFieldWrapper}>
                    <MoinTextField
                        maxCharacters={200}
                        multiline={true}
                        rows={4}
                        rowsMax={4}
                        placeholder={t('chat.writeAMessage')}
                        value={hugText}
                        onChange={e => setHugText(e.target.value)}
                    />
                </div>

                <MoinButton
                    className={classes.sendButton}
                    disabled={selectedUsers.length === 0}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => sendHugToUsers(selectedHugType, hugText, selectedUsers)}
                >
                    {t('hugs.send')}
                </MoinButton>
            </>
        );
    };

    const renderHugIconAnimation = () => {
        let transformOrigin = '0 0 0';
        let overlayStyle = {width: 0, height: 0};


        if(!!hugIconRef && !!wrapperRef.current) {
            let iconRect = hugIconRef.getBoundingClientRect();
            let wrapperRect = wrapperRef.current.getBoundingClientRect();
            transformOrigin = `${iconRect.left - wrapperRect.left + 20}px ${iconRect.top - wrapperRect.top + 20}px 0`;
            overlayStyle = {};
        }

        return (
            <div className={classes.iconAnimation} style={overlayStyle}>
                <Grow in={showOverlay} timeout={800} style={{ transformOrigin: transformOrigin }} addEndListener={(node, onDone) => {
                    const done = () => {
                        setTimeout(() => {
                            if(showOverlay) {
                                setSelectedHugType(animateHugType);
                                setShowOverlay(false);
                            } else {
                                setAnimateHugType(undefined);
                                setHugIconRef(undefined);
                            }
                        }, 400);
                    };

                    node.addEventListener('transitionend', done, false);
                }}>
                    <div className={classes.animateHugIconWrapper} style={{backgroundColor: Colors.colorForHugType(animateHugType)}}/>
                </Grow>
            </div>
        );
    };

    return (
        <div ref={(ref) => wrapperRef.current = ref}>
            <MoinCard className={classes.cardWrapper}>
                <MoinCardHeader>
                    <Typography variant="h3" className={classes.cardTitle}>
                        {t('hugs.hugs')}
                    </Typography>
                </MoinCardHeader>
                <MoinCardContent hideContentSpacer={true}>
                    {
                        renderHugIconAnimation()
                    }
                    {
                        !!selectedHugType && <div className={classes.blueCircleBackground}/>
                    }
                    <div className={classes.content}>
                        {
                            !!selectedHugType
                                ? renderHugSendForm()
                                : renderHugSelection()
                        }
                    </div>
                </MoinCardContent>
            </MoinCard>
        </div>
    );
}

export default GiveHugsOverview;