export const TaskViewType = {
    HOME: "home",
    SEARCH: "search",
    TODAY: "today",
    PLANNED: "planned",
    MYTASKS: "myTasks",
    ASSIGNEDTOOTHERS: "assignedToOthers",
    COLLEAGUELIST: "colleagueList"
};

