import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {CardContent} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import {Link, Redirect} from "react-router-dom";
import GroupMemberContainer from "./GroupMemberContainer";
import GroupDetail from "./GroupDetail";
import CardHeader from "@material-ui/core/CardHeader";
import {Colors} from "../../components/helper/ColorHelper";
import parameters from '../../config/parameters.json';
import * as ROUTES from "../../Routes";
import GroupJoinButtonWrapper from "../../components/group/GroupJoinButton";
import MoinSampleUsersList from "../../components/base/MoinSampleUsersList";
import GroupMenuWrapper from "../../components/group/GroupMenu";
import {MoinCard, MoinCardContent, MoinCardHeader, MoinCardHeaderImage} from "../../components/base/MoinCard";
import FireStorageImage from "../../components/base/FirestoreImage";
import {getModelInstances} from "../../utils/store/selectors";
import Article from "../../utils/models/Article";
import AuthStorage from "../../utils/auth/AuthStorage";
import {Permission} from "../../utils/enums/Permission";
import ArticleTeaserContainer from "../../components/article/ArticleTeaserContainer";
import DistributorRequest from "../../utils/models/DistributorRequest";
import User from "../../utils/models/User";
import MoinSectionHeadline from "../../components/base/MoinSectionHeadline";
import GroupEmptyState from "./GroupEmptyState";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg";
import MoinFab from "../../components/base/MoinFab";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";


const useStyles = makeStyles((theme) => ({
    cardContentArticles: {
        padding:  theme.spacing(0,1),
    },
    cardContentTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center"
    },
    cardContentMember: {
       marginTop: "16px"
    },
    chevron: {
        marginLeft: "8px",
        fill: Colors.BLACK,
        height: "1rem",
    },
    headerImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: "cover"
    },
    link: {
        cursor: "pointer",
        textDecoration: "none"
    },
    button: {
        marginTop: theme.spacing(3),
    },
    groupInfo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    circleDivider: {
        display: "inline-block",
        margin: theme.spacing(0,1),
        borderRadius: "100%",
        backgroundColor: Colors.BLACKLIGHTER,
        width: "4px",
        height: "4px",
    },
    requestsLink: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: "center",
        textDecoration: "none",
        marginTop: "12px",
        marginLeft: "24px",
        marginRight: "32px"
    }
}));


/**
 *
 * @param {Distributor} distributor
 * @param {Array.<Article>} articles
 * @param {Array.<String>} articleIds
 * @param {Array.<User>} allUsers
 * @param {Array.<DistributorRequest>} allRequests
 * @param {Function} loadOlderArticles
 * @param {boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
const GroupShow = ({distributor, articleIds, articles=[], allUsers, allRequests, loadOlderArticles, loading= false}) => {
    const articlesWrapper = useRef();
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    const [redirectToCreate, setRedirectToCreate] = useState(false);

    const distributorRequests = (allRequests || []).filter( (request) => request.distributorId === distributor.id );
    const requestUserIds= allUsers.filter( (user) => !!distributorRequests.find( (request) => user.id === request.id ) ).map( (user) => user.id);
    const displayArticles = articles.filter(( article) => articleIds.includes(article.id)).sort((a, b) => b.cAt.getTime() - a.cAt.getTime());

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle, true);
        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [articles]);

    const onScrollHandle = () => {
        const displayArticles = articles.filter(( article) => articleIds.includes(article.id))
        if(!!articlesWrapper.current && articlesWrapper.current.getBoundingClientRect().bottom <= window.innerHeight + 250 && !!displayArticles[displayArticles.length - 1]) {
            loadOlderArticles(displayArticles[displayArticles.length - 1].cAt);
        }
    };

    let name = distributor?.name;
    if (distributor?.id === parameters.globalDistributorId) {
        name = t('distributor.global');
    }

    let memberCount;
    switch (distributor?.count) {
        case undefined:
            break;
        case 0:
            memberCount = t('distributor.noMembersCount');
            break;
        case 1:
            memberCount = t('distributor.singleMemberCount');
            break;
        default:
            memberCount = t('distributor.xMembersCount', {count: distributor?.count})
            break;
    }

    const buildCardHeader = () => {
        if (distributor.isContentVisible) {
            return (
                <>
                    <CardHeader
                        title={
                            <Link underline="none" to={ROUTES.groupDetailsShow.replace(":id", distributor.id)} className={classes.link}>
                                <div className={classes.cardContentTitle}>
                                    <Typography variant="h3">{name}</Typography>
                                    <ChevronRightIcon className={classes.chevron} />
                                </div>
                            </Link>
                        }

                        subheader={
                            <Typography variant="caption" className={classes.groupInfo}>
                                {distributor.isPublic ? t('distributor.public') : t('distributor.private') }
                                <div className={classes.circleDivider}/>
                                {memberCount}
                            </Typography>
                        }

                        action={
                            ( AuthStorage.hasPermission(Permission.GROUPDELETE)
                            || distributor.cUid === AuthStorage.getUserId()
                            || distributor.isAuthUserMember )
                            && <GroupMenuWrapper id={distributor.id + '_context_menu'}  distributor={distributor}/>
                        }
                    />

                    <div className={classes.cardContentMember}>
                        <Link underline="none" to={ROUTES.groupDetailsShow.replace(":id", distributor.id)} className={classes.link}>
                            <MoinSampleUsersList ids={distributor.sample.map( (user) => user.uid)} radius={24} showMore={distributor.count > distributor.sample.length}/>
                        </Link>
                    </div>

                    {
                        !distributor.isAuthUserMember && <GroupJoinButtonWrapper className={classes.button} distributor={distributor}/>
                    }
                </>

            )
        }

        return (
            <>
                <CardHeader
                    title={
                        <div className={classes.cardContentTitle}>
                            <Typography variant="h3">{name}</Typography>
                        </div>
                    }
                    subheader={
                        <Typography variant="caption" className={classes.groupInfo}>
                            {distributor.isPublic ? t('distributor.public') : t('distributor.private') }
                            <div className={classes.circleDivider}/>
                            {memberCount}
                        </Typography>
                    }

                    action={
                        <GroupMenuWrapper id={distributor.id + '_context_menu'} distributor={distributor}/>
                    }
                />

                {
                    !distributor.isAuthUserMember && <GroupJoinButtonWrapper className={classes.button} distributor={distributor}/>
                }
            </>
        )
    }

    if(redirectToCreate) {
        return <Redirect push to={{
            pathname: ROUTES.articleCreate,
            state: { preselectedDisId: distributor.id }
        }}/>
    }

    return (
        <>
            <MoinCard>
                <MoinCardHeaderImage>
                    <FireStorageImage
                        lightboxEnabled={true}
                        fontSize={64}
                        className={classes.headerImage}
                        src={distributor.avatar}
                        alt={name}
                        backgroundColor={Colors.identifierAsColor(distributor.id)}
                    />
                </MoinCardHeaderImage>
                <MoinCardHeader>
                    {buildCardHeader()}
                </MoinCardHeader>
                <MoinCardContent>
                    {
                        distributor.cUid === AuthStorage.getUserId() && requestUserIds && requestUserIds.length > 0 &&
                            <>
                                <MoinSectionHeadline label={t('distributor.membershipRequests')}/>
                                <Link to={ROUTES.groupRequests.replace(":id", distributor.id)} className={classes.requestsLink}>
                                    <MoinSampleUsersList ids={requestUserIds} radius={24} showMore={distributor.requests > 5}/>
                                    <ChevronRightIcon className={classes.chevron}/>
                                </Link>
                            </>
                    }

                    {
                        distributor.isContentVisible ?
                            <CardContent className={classes.cardContentArticles}  ref={(node) => articlesWrapper.current = node}>
                                {
                                    (!displayArticles || displayArticles.length === 0) && !loading
                                        ? <GroupEmptyState isMember={distributor.isAuthUserMember}/>
                                        : displayArticles.map(article => <ArticleTeaserContainer article={article} key={"group-article-" + article.id}/>)
                                }
                            </CardContent>
                            :
                            <GroupMemberContainer  distributorId={distributor.id}>
                                {
                                    ({userIds}) => <GroupDetail distributor={distributor} userIds={userIds}/>
                                }
                            </GroupMemberContainer>
                    }
                </MoinCardContent>
            </MoinCard>

            {
                distributor.isAuthUserMember && AUTHSTORAGE.hasPermission(Permission.ARTICLECREATE) &&
                    <MoinFab onClick={() => setRedirectToCreate(true)} />
            }
        </>
    );
};

export default GroupShow;
