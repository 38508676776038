import React, {useEffect, useRef, useState} from 'react';
import ColleagueTasks from "./ColleagueTasks";
import {TASKCLIENT} from "../../utils/tasks/TaskClient";
import {SortDirections, TaskSortField} from "../../utils/enums/TaskSort";
import { getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import Task from "../../utils/models/Task";

const ColleagueTasksContainer = ({selectedColleague, displaySort,sortTasks,displayTask, sortField,sortDirection, loadedTasks  }) => {
    let hasMore = useRef(true);
    let lastRequest = useRef(0);
    let loading = useRef(true);
    let tasks = useRef([]);
    let loadedAmount = useRef(0);
    let [rebuild, setRebuild] = useState(true);

    useEffect(() => {
        hasMore.current = true;
        loading.current = true;
        tasks.current = [];
        loadedAmount.current =0;
        searchTasks(selectedColleague, 0);
    }, [selectedColleague]);

    const mapTasksToRealOnes =  () => {
        let oldTasks = tasks.current;
        if (oldTasks && oldTasks.length > 0) {
            let knownTaskForUser = loadedTasks;

            if (selectedColleague) {
                knownTaskForUser.filter( (task) => task.aUid === selectedColleague.id ||  (!task.aUid && task.cUid === selectedColleague.id ) );
            }

            for (let i= 0; i < oldTasks.length; i++) {
                let task = oldTasks[i];
                let loadedTask = knownTaskForUser.find( (_) => _.id === task.id)
                if (loadedTask) {
                    oldTasks[i] = Object.assign(new Task(), loadedTask);
                }
            }
            let updatedTasks = [...oldTasks];


            let entryToCompare =  updatedTasks[updatedTasks.length -1];
            knownTaskForUser.forEach((task) => {
                if (oldTasks.findIndex( (old) => old.id === task.id ) < 0) {
                    if (sortField === TaskSortField.NAME ) {
                        if (sortDirection === SortDirections.ASCENDING && task.title.trim() < entryToCompare.title.trim()) {
                            updatedTasks.push(task);
                        } else if (task.title.trim() > entryToCompare.title.trim()) {
                            updatedTasks.push(task);
                        }
                    } else {
                        if (
                                (!task.dueDate && !entryToCompare.dueDate)
                                || (!!task.dueDate &&  entryToCompare.dueDate && task.dueDate.getTime() < entryToCompare.dueDate.getTime())
                            )
                        {
                            updatedTasks.push(task);
                        }
                    }
                }
            })
            updatedTasks = updatedTasks.filter((task) => !task.dAt);
            tasks.current = updatedTasks;
        }
    }

    const searchTasks = async (selectedColleague) => {
        let requestTime = Date.now()
        lastRequest.current = requestTime;

        if (hasMore.current && (!loading || tasks.current.length === 0)) {
            loading.current=true;
            let taskData = await TASKCLIENT.searchTasksForUser(selectedColleague?.id, 20, loadedAmount.current, sortField === TaskSortField.NAME ? "title_lowercase": "dueDate", sortDirection);
            if (requestTime === lastRequest.current) {
                loading.current=false;
                hasMore.current = taskData?.length > 0;
                loadedAmount.current +=taskData.length;
                tasks.current = [...tasks.current, ...taskData];
                setRebuild( (old) => !old);
            }
        }
    }
    mapTasksToRealOnes();

    return  <ColleagueTasks
        selectedColleague={selectedColleague}
        displaySort={displaySort}
        sortTasks={sortTasks}
        displayTask={displayTask}
        loadMoreTasks={searchTasks}
        tasks={tasks.current}
        loading = {loading.current}
    />
};

const mapState = (state, ownProps) => {
    return {
        loadedTasks: getModelInstances(state, Task)
    }
};


export default connect(mapState)(ColleagueTasksContainer);
