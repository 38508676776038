import AbstractType from "./AbstractType";
import {firebase} from "../../../api/FirebaseService";

export default class DateType extends AbstractType
{

    toFirestore = (value) => value ? firebase.firestore.Timestamp.fromDate(value) : null;
    fromFirestore = (value) => value && value instanceof firebase.firestore.Timestamp ? value.toDate() : null;

    static type = "date";

    test = (value) => value instanceof Date;
};