import {model} from "../manager/decorators";

@model("/client/:client/user/:user/comment_heart")
class CommentReaction {
    id;
    type;
}

export default CommentReaction;

