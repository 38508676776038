import React, {useEffect, useRef} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MoinButton from "./MoinButton";
import MoinDivider from "./MoinDivider";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "../../assets/icons/close.svg";
import {Colors} from "../helper/ColorHelper";


const useStyles = makeStyles((theme) => ({
    dialogActionsMultiple: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    dialogActionsSingle: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    dialog: {
        '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded': {
            borderRadius: '20px',
        }
    },
    description: {
        marginTop: '8px'
    },
    closeIcon: {
        position: 'absolute',
        right: 16,
        top: 14,
        fill: Colors.BRANDPRIMARY,
        cursor: "pointer"
    }
}));

/**
 * Moin! themed Dialog.
 * @param {String} id Needed for screen reader accessibility
 * @param {String|JSX.Element} [openButtonText] Text of the button that opens the dialog. You can also add elements, e.g.
 * an icon. If left out the dialog will pop up immediately
 * @param {String} title Title of the dialog
 * @param onClose
 * @param {String} [abortButtonText] Text of the button that closes the dialog. The abort button will not render if this
 * is null
 * @param {Function} [abortButtonCallback] Function that is executed if abort button is clicked
 * @param {String} continueButtonText Text of the continue button
 * @param {Function} [continueButtonCallback] Function that is executed if continue button is clicked. Optional in case
 * of a simple dialog
 * @param continueButtonDisabled
 * @param showCloseButton
 * @param {String} className
 * @param children Elements that will be displayed inside of the dialog
 * @param rest
 * @returns {JSX.Element}
 * @example
 * <MoinDialog
 id={'1'}
 openButtonText={"MoinDialog öffnen"}
 title={'MoinDialog Titel'}
 abortButtonText={'MoinDialog abbrechen'}
 abortButtonCallback={() => console.log("MoinDialog abort")}
 continueButtonText={'MoinDialog bestätigen'}
 continueButtonCallback={() => console.log("MoinDialog continue")}
 >
 Sind Sie sicher, dass Sie fortfahren möchten?
 </MoinDialog>
 */
const MoinDialog = ({id, openButtonText, title, onClose = () => {}, abortButtonText, abortButtonCallback = () => {}, continueButtonText, continueButtonCallback = () => {}, continueButtonDisabled = false, showCloseButton = false, className, children, ...rest}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const continueButtonRef = useRef();

    useEffect(() => {
        window.addEventListener('keydown', onEnter, true);

        return () => {
            window.removeEventListener('keydown', onEnter, true);
        }
    }, []);

    const onEnter = (event) =>  {
        if(event.keyCode === 13 && (open || !openButtonText) ) {
            // We have to fake a button click otherwise we will call the save methode with the wrong states if children are used
            continueButtonRef.current.click();
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let classNames = classes.dialog;

    if (!!className) {
        classNames = clsx(classes.dialog, className);
    }

    return (
        <div>
            {
                !!openButtonText &&
                    <MoinButton onClick={handleClickOpen}>
                        {openButtonText}
                    </MoinButton>
            }
            <Dialog
                open={!openButtonText ? true : open}
                onClose={handleClose}
                aria-labelledby={'moin-dialog-title' + id}
                aria-describedby={'moin-dialog-description' + id}
                className={classNames}
                {...rest}
            >
                <DialogTitle id={'moin-dialog-title' + id}>
                    <Typography variant={"h2"}>{title}</Typography>
                    {
                        showCloseButton &&
                        <CloseIcon onClick={onClose} className={classes.closeIcon}/>
                    }
                </DialogTitle>
                <MoinDivider />
                <DialogContent>
                    <DialogContentText id={'moin-dialog-description' + id}>
                        <div className={classes.description}>
                            {children}
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className={abortButtonText != null ? classes.dialogActionsMultiple : classes.dialogActionsSingle}>
                    {
                        abortButtonText != null
                            ? <MoinButton
                                    onClick={() => {
                                        abortButtonCallback();
                                        handleClose();
                                    }}
                                    variant={'outlined'}
                                >
                                    {abortButtonText}
                                </MoinButton>
                            : ""
                    }
                    <MoinButton
                        onClick={() => {
                            continueButtonCallback();
                            handleClose();
                        }}
                        disabled={continueButtonDisabled}
                    >
                        <span ref={(ref) => continueButtonRef.current = ref}/>
                        {continueButtonText}
                    </MoinButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default MoinDialog;
