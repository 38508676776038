export const terms = '/login/terms';
export const dpd = '/login/privacy';
export const impress = '/login/impress';
export const onboarding = '/onboarding';

export const termsNav = '/terms';
export const dpdNav = '/privacy';
export const dpdCookiesNav = '/privacy_cookies';
export const impressNav = '/impress';

export const articleFeed = '/news';
export const articleCreate = '/article/create';
export const articleLikeList = '/article/:id/reactions';
export const commentLikeList = '/comment/:id/reactions';
export const commentAnswerLikeList = '/answer/:id/reactions';

export const favoritedArticles = '/article/favorites';

export const articleShow = '/article/:id';
export const articleEdit = '/article/:id/edit';
export const articleCopy = '/article/:id/copy';
export const surveyAnswerShow = '/article/:aid/survey/:sid/answers';

export const commentShow = '/comment/:id';

export const contacts = '/contacts';
export const dashboard = '/dashboard';
export const groups = '/groups';
export const chat = '/chat';
export const events = '/events';
export const illness = '/illness';
export const tasks = '/tasks';
export const tasksShow = '/tasks/:id';
export const tasksSearch = '/tasks/search/:query';
export const storage = '/storage';
export const storageWithPath = '/storage/:query';
export const storageSearch = '/storage/search/:query';

export const groupCreate = '/group/create';
export const groupEdit = '/group/edit/:id';
export const groupResponsible = '/group/responsible/:id';
export const groupRequests = '/group/request/:id';
export const groupShow = '/group/:id';
export const groupDetailsShow = '/group/:id/details';

export const login = '/';
export const loginForgotPassword = '/forgot-password';
export const logout = '/logout';

export const sendHug = '/hug';

export const messagesSingleCreate = '/chat/singleCreate';
export const messagesCreate = '/chat/create';
export const messagesEdit = '/chat/edit/:id';
export const messagesShow = '/chat/:id';
export const notifications = '/notifications';

export const userShow = '/user/:id';
export const userEdit = '/user/:id/edit';
export const changePassword = '/user/change-password';
export const inviteExternal = '/invite_external/:ref';

export const home = '/';
export const searchByType = '/search/:type';
export const search = '/search/:query';

export const eventShow = '/events/:id';
export const eventCreate = '/events/create';
export const eventEdit = '/events/:id/edit';
export const eventParticipantsShow = '/events/:id/participants';

export const helpAndSupport = '/help';
export const faq = '/faq';
