class OnlineStatuses {
    /**
     *
     * @private {Array} _onlineUsers
     */
    #_onlineUsers = [];

    /**
     *
     * @param {Array} onlineUserIds
     */
    setOnlineUsers = (onlineUserIds) => {
        this.#_onlineUsers = onlineUserIds;
    };

    isOnline(userId) {
        return this.#_onlineUsers.includes(userId) ;
    }

    reset() {
        this.#_onlineUsers = [];
    }
}

const ONLINESTATUSES = new OnlineStatuses();

export default ONLINESTATUSES;