import Message from "../models/Message";
import AUTHSTORAGE from "../auth/AuthStorage";
import {MessageType} from "../enums/MessageType";
import ModelManager from "../manager/ModelManager";
import * as forge from "node-forge";
import {addModelInstance} from "../store/actions";
import Dexie from "dexie";
import {FireDelete, FireIncrement, FireTimestamp} from "../../api/FirebaseService";

const settingsDb = new Dexie("settingsDb");
settingsDb.version(1).stores({settings: 'id'});

const getShowOnlineState = async () => {
    return (await settingsDb.settings.get('showOnlineState'))?.setting ?? true;
};

const setShowOnlineState = async (show) => {
    let oldState = await getShowOnlineState();

    // Remove last online from firestore
    if(oldState && !show) {
        clearOnlineState();
    } else if(!oldState && show) {
        updateOnlineState(true);
    }

    settingsDb.settings.put({id: 'showOnlineState', setting: show});
};

const updateOnlineState = async (force) => {
    if(!!force || await getShowOnlineState()) {
        let updateData = {[AUTHSTORAGE.getUserId()]: FireTimestamp()};
        await ModelManager.update({customData: updateData, resource: `/client/${AUTHSTORAGE.getClientId()}/online_status`, id: '_all'});
    }
};

const clearOnlineState = async () => {
    let authUserDeleteData = {[AUTHSTORAGE.getUserId()]: FireDelete()};
    ModelManager.update({customData: authUserDeleteData, resource: `/client/${AUTHSTORAGE.getClientId()}/online_status`, id: '_all'});
};

const createRemoveMessageForChatMembers = (currentChat, authUser, members) => {
    for(let member of members) {
        if (member.uid !== AUTHSTORAGE.getUserId()) {
            let userRemovedMessage = Message.from({cUid: AUTHSTORAGE.getUserId(), cName: authUser.fullName, tName: member.fullName, tUid: member.id, type: MessageType.USERREMOVED, text: ""});
            userRemovedMessage.chatId = currentChat.id;
            userRemovedMessage.cAt = new Date();
            addModelInstance(Message, userRemovedMessage);
            userRemovedMessage = currentChat.encryptMessage(userRemovedMessage);

            ModelManager.add({
                modelInstance: userRemovedMessage,
                resource: '/client/' + AUTHSTORAGE.getClientId() + '/chat/' + currentChat.id + '/message'
            }).then(result => linkMessageToChatMember(currentChat, result));
        }
    }
}


const createChatNameChangedMessage = (currentChat, authUser) => {
    let chatNameChanged = Message.from({cUid: AUTHSTORAGE.getUserId(), cName: authUser.fullName, tName: currentChat.name, type: MessageType.NAMECHANGED, text: ""});
    chatNameChanged.chatId = currentChat.id;
    chatNameChanged.cAt = new Date();
    addModelInstance(Message, chatNameChanged);
    chatNameChanged = currentChat.encryptMessage(chatNameChanged);

    ModelManager.add({
        modelInstance: chatNameChanged,
        resource: '/client/' + AUTHSTORAGE.getClientId() + '/chat/' + currentChat.id + '/message'
    }).then(result => linkMessageToChatMember(currentChat, result));
}

const createAddedMessageForChatMembers = (currentChat, members, authUser) => {
    for(let member of members) {
        if (member.uid !== AUTHSTORAGE.getUserId()) {
            let userAddedMessage = Message.from({cUid: AUTHSTORAGE.getUserId(), cName: authUser.fullName, tName: member.fullName, tUid: member.id, type: MessageType.USERADDED, text: ""});
            userAddedMessage.chatId = currentChat.id;
            userAddedMessage.cAt = new Date();
            addModelInstance(Message, userAddedMessage);
            userAddedMessage = currentChat.encryptMessage(userAddedMessage);

            ModelManager.add({
                modelInstance: userAddedMessage,
                resource: '/client/' + AUTHSTORAGE.getClientId() + '/chat/' + currentChat.id + '/message'
            }).then(result => linkMessageToChatMember(currentChat, result));
        }
    }
}

const linkMessageToChatMember = (currentChat, message, members=[]) => {
    const memberMessageData = {
        'cAt': FireTimestamp()
    };

    const unreadMessageData = {
        'uMC': FireIncrement(1)
    };

    (members.length>0 ? members: currentChat.member).forEach(member => {
        ModelManager.update({
            customData: memberMessageData,
            resource: '/client/' + AUTHSTORAGE.getClientId() + '/user/' + member.uid + '/chat/' + currentChat.id + '/message',
            id: message.id,
            useSet: true,
            stopEvents: true,
            extra: {chat: currentChat, isNew: true}
        });
        ModelManager.update({
            customData: unreadMessageData,
            resource: '/client/' + AUTHSTORAGE.getClientId() + '/user/' + member.uid + '/chat/',
            id: currentChat.id,
            useSet: true
        });
    });
};

const addMembersToChat = async (currentChat, members) => {
    let promises = [];
    // This has to be in an extra loop, because the user is only allowed to create these documents, when the user is a member of the chat
    for(let member of members) {
        let memberChatData;

        if (AUTHSTORAGE.getClient().isDemoClient() ) {
            memberChatData = {
                name: currentChat.name,
                uAt: FireTimestamp(),
                encrypted: false
            };

        } else {
            const publicKey =  member.publicKey
            let pemPublicKey = "-----BEGIN PUBLIC KEY-----\n" +publicKey + "\n-----END PUBLIC KEY-----\n";
            let parsedPublicKey = forge.pki.publicKeyFromPem(pemPublicKey);

            memberChatData = {
                name: currentChat.name,
                symKey: forge.util.encode64(parsedPublicKey.encrypt(forge.util.encodeUtf8(currentChat.symKey))),
                iv:  forge.util.encode64(forge.random.getBytesSync(16)),
                uAt: FireTimestamp()
            };
        }

        promises.push(ModelManager.update({
            customData:memberChatData,
            resource: '/client/' + AUTHSTORAGE.getClientId() + '/user/' + member.id + '/chat',
            id: currentChat.id,
            useSet: true
        }));
    }
    await Promise.all(promises);
};

const changeChatMembersAllData= async (chat) => {
    let chatMemberDocumentData = {};

    chat.member.forEach( (member) =>  {
        chatMemberDocumentData[member.id] = member.fullName;
    });

    await ModelManager.add({
        customData: chatMemberDocumentData,
        resource: '/client/' + AUTHSTORAGE.getClientId() + '/chat/' + chat.id + '/user',
        id: '_all',
    });
}

const addChatMembersData = async (chat, authUser, newMembers=[]) => {
    await addMembersToChat(chat, newMembers.length > 0 ? newMembers : chat.member);
    if (!chat.isSingleChat) {
        createAddedMessageForChatMembers(chat, newMembers.length > 0 ? newMembers :  chat.member,authUser);
    }
}

export {createChatNameChangedMessage, createAddedMessageForChatMembers, addModelInstance, addMembersToChat, changeChatMembersAllData, addChatMembersData, createRemoveMessageForChatMembers, setShowOnlineState, getShowOnlineState, updateOnlineState, clearOnlineState}