import {Divider, List, Tab, Tabs, Typography} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import {sortDepartments, sortUsersByFirstname, sortUsersByLastname} from "../../utils/helper/SortHelper";
import UserListItem from "../user/MoinUserListItem";
import DepartmentListItem from "../department/MoinDepartmentListItem";
import React, {useEffect, useRef, useState} from "react";
import { theme} from "../../assets/jss/masterLayout";
import {useTranslation} from "react-i18next";
import Department from "../../utils/models/Department";
import {getModelInstances} from "../../utils/store/selectors";
import User from "../../utils/models/User";
import {connect} from "react-redux";
import FireStorageImage from "./FirestoreImage";
import CloseIcon from "../../assets/icons/close.svg";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {ClientType} from "../../utils/enums/ClientType";
import * as TranslationHelper from "../../components/helper/TranslationHelper";
import MoinListItem from "./MoinListItem";

import ChatBubbleOutlineIcon from "../../assets/icons/chats-inactive.svg";
import {withRouter, useHistory} from "react-router-dom";
import * as ROUTES from "../../Routes";
import {Permission} from "../../utils/enums/Permission";
import MoinButton from "./MoinButton";


const useStyles = makeStyles((theme) => ({
    list: {
        overflow: 'auto',
        maxHeight: 480,
    },
    avatarWrapper: {
        display: "inline-block",
        position: "relative",
        marginRight: theme.spacing(2),
    },
    avatarDelete: {
        cursor: "pointer",
        position: "absolute",
        top: "-4px",
        right: "-8px",
        borderRadius: "100%",
        backgroundColor: Colors.BRANDPRIMARY,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "1.5rem",
        height: "1.5rem",
    },
    avatarDeleteIcon: {
        fontSize: "1rem",
        fill: Colors.WHITE,
    },
    avatarList: {
        overflow:"hidden",
        overflowX: "scroll",
        display: "flex",
        padding: theme.spacing(0.5,0,2,0),
    },
    iconContainer: {
        borderRadius: "100%",
        backgroundColor: Colors.BRANDSECONDARY,
        width: "3rem",
        height: "3rem",
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fill: Colors.WHITE,
        width: "1.5rem",
        height: "1.5rem",
    },
    pillowContainer : {
      margin: '12px 0'
    },
    activePillow: {
        backgroundColor: Colors.BRANDPRIMARY,
        borderColor: Colors.BRANDPRIMARY,
        color: Colors.WHITE,
        marginRight: "8px"
    },
    inactivePillow: {
        backgroundColor: Colors.LIGHTPURPLE,
        borderColor: Colors.LIGHTPURPLE,
        color: Colors.BRANDPRIMARY,
        marginRight: "8px"
    }
}));




/**
 *
 * @param {String} search
 * @param {Array<User>} users
 * @param {Array<Department>} departments
 * @param {String} search
 * @param {String} placeholder
 * @param {Array<String>} selectedUsers
 * @param {Function} onSelect
 * @param {boolean} useTabs
 * @param {boolean} withSort
 * @param {boolean} hideAuth
 * @param {boolean} allowAuthRemove
 * @param {boolean} useDepartments
 * @param {boolean} useExternals
 * @param {boolean} withInviteExternal
 * @param {string} referer
 * @param {boolean} showCheckbox
 * @param {boolean} showChevron
 * @param {boolean} fullHeight
 * @returns {JSX.Element}
 * @constructor
 */

const SearchableSelectableUserList = ({search, users = [], departments = [], selectedUsers = [], onSelect, placeholder , useTabs = true, hideAuth=true, allowAuthRemove=false, useDepartments=true, withSort = true, withInviteExternal = false, useExternals=false, referer= "", showCheckbox=true, showChevron=false, fullHeight=false }) => {
    const sort = useRef( sortUsersByFirstname);
    const [selectedTab, setSelectedTab] = useState(0);
    const [rebuild, setRebuild] = useState(false);

    const userAmount = useRef(25);
    const lastSearch = useRef(search);
    const scrollAnchor = useRef();

    const [t, i18n] = useTranslation();
    const classes = useStyles();

    const history = useHistory();


    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle, true);
        return () => {
            window.removeEventListener('scroll', onScrollHandle, true);
        }
    }, [users]);

    const onScrollHandle = () => {
        if(!!scrollAnchor.current && userAmount.current < users.length &&  scrollAnchor.current.getBoundingClientRect().bottom <= window.innerHeight + 250) {
            userAmount.current +=25;
            setRebuild((rebuild) => !rebuild);
        }
    };

    let filteredUsers = users;
    if (!!search && search.trim().length > 0) {
        filteredUsers = users.filter( user =>  ( user.fullName.toLowerCase().includes(search.toLowerCase())));
    }

    if(hideAuth) {
        filteredUsers = filteredUsers.filter(user => user.id !== AUTHSTORAGE.getUserId());
    }

    if (!!search && lastSearch.current !== search) {
        lastSearch.current = search;
        userAmount.current = 25;
    }

    const buildRemovableUserAvatar= (user) =>  {
        if (!user) {
            return <></>;
        }
        return (
            <div className={classes.avatarWrapper}  key={user.id}>
                <FireStorageImage
                    radius={28}
                    isAvatar={true}
                    src={ user.avatar}
                    alt={ user.fullName}
                    backgroundColor={Colors.identifierAsColor(user.id)}
                />
                {
                    (allowAuthRemove || (user.id !== AUTHSTORAGE.getUserId())) &&
                    <Typography variant="body1" className={classes.avatarDelete} onClick={() => onSelect(user)}><CloseIcon className={classes.avatarDeleteIcon}/></Typography>
                }
            </div>
        )
    }

    const externalTab = (filteredUsers,sort) => {

        if (sort.current !== sortDepartments) {
            return filteredUsers.sort(sort.current).slice(0,userAmount.current).map((user, index, collection) =>
                <UserListItem
                    selectable={showCheckbox}
                    selected={selectedUsers.includes(user.id)}
                    onSelect={onSelect}
                    divider={index !== collection.length - 1}
                    key={index}
                    user={user}
                    showChevron={showChevron}
                />
            );
        }

        let departmentPlaceholder;
        let departmentKeys = new Set();
        let shownDepartmentKeys = new Set();

        filteredUsers.map( (user) => departmentKeys.add(user.department) );
        let shownDepartments = departments.filter( (department) => departmentKeys.has(department.id)) ;
        shownDepartments.map( (department) => shownDepartmentKeys.add(department.id));
        if (departmentKeys.size >  shownDepartments.length) {
            departmentPlaceholder= new Department();
            departmentPlaceholder.name = TranslationHelper.unknownDepartment(t);
        }
        let usersWithUnknownDepartment = filteredUsers.filter( (user) => !shownDepartmentKeys.has(user.department));

        return  <>
            { shownDepartments.sort(sort.current).map((department, index, collection) =>
                <DepartmentListItem
                    divider={departmentPlaceholder ? false : index !== collection.length - 1}
                    key={'department'+department.id}
                    users={filteredUsers.filter((user) => user.department === department.id)}
                    department={department}
                    altTile={
                        ({user,index,collection}) =>  <UserListItem
                            selectable={showCheckbox}
                            selected={selectedUsers.includes(user.id)}
                            onSelect={onSelect}
                            divider={index !== collection.length - 1}
                            key={'userListDepartment' + user.id}
                            user={user}
                        />
                    }
                />
            )}
            {
                departmentPlaceholder &&
                usersWithUnknownDepartment.length !== 0 &&
                <DepartmentListItem
                    divider={false}
                    key={"departments-none"}
                    users={usersWithUnknownDepartment}
                    department={departmentPlaceholder}
                    altTile={
                        ({user,index,collection}) =>  <UserListItem
                            selectable={showCheckbox}
                            selected={selectedUsers.includes(user.id)}
                            onSelect={onSelect}
                            selectDisabled={ user.id === AUTHSTORAGE.getUserId()}
                            divider={index !== collection.length - 1}
                            key={'userListDepartment' + user.id}
                            user={user}
                        />
                    }
                />
            }
        </>;
    }

    const externalUserButton = () => {
        return (
            <MoinListItem
                onClick={ () => history.push(ROUTES.inviteExternal.replace(":ref", encodeURIComponent(referer))) }
                image={
                    <div className={classes.iconContainer}>
                        <ChatBubbleOutlineIcon className={classes.icon}/>
                    </div>
                }
                title={t('chat.inviteExternal')}
                // we can not use the splash Effect here since it adds a bunch of events that block the click handler
                withSplashEffect={false}
            />
        );
    }

    if (useExternals && !search) {

        let externalUsers = filteredUsers.filter( (user) => !!user.external);
        let colleagues = filteredUsers.filter( (user) => !user.external);

        return (
            <div ref={(node) => scrollAnchor.current = node}>
                {
                    selectedUsers && selectedUsers.length>0 &&
                    <div className={classes.avatarList}>
                        {
                            selectedUsers
                                .filter(userId => (!hideAuth || userId !== AUTHSTORAGE.getUserId()))
                                .map((user) => users.find((userModel) => userModel.id === user))
                                .map((user) => (
                                    buildRemovableUserAvatar(user)
                                ))
                        }
                    </div>
                }

                {
                    (!selectedUsers || selectedUsers.length === 0) && !!placeholder &&
                    <div className={classes.avatarList}>
                        <Typography variant="body1">{placeholder}</Typography>
                    </div>
                }


                {
                    withSort
                        ? <>
                               <Tabs
                                   onChange={(e, v) => setSelectedTab(v)}
                                   value={selectedTab}
                                   textColor="primary"
                               >
                                   <Tab label={t('user.all')}/>
                                   {
                                       (externalUsers.length > 0 || AUTHSTORAGE.hasPermission(Permission.INVITEEXTERNAL)) &&  <Tab label={t('user.contacts')}/>
                                   }
                                   {
                                       (externalUsers.length > 0 || AUTHSTORAGE.hasPermission(Permission.INVITEEXTERNAL)) &&  <Tab label={t('user.externals')}/>
                                   }
                                   </Tabs>
                               <Divider/>
                               <div className={classes.pillowContainer}>

                                   <MoinButton size="small" className={sort.current === sortUsersByFirstname ? classes.activePillow : classes.inactivePillow} onClick={ (e) => {sort.current = sortUsersByFirstname; setRebuild((_rebuild) => !rebuild); e.stopPropagation(); e.preventDefault(); return false;}}>
                                        {t('user.firstName')}
                                   </MoinButton>

                                   <MoinButton size="small" className={sort.current ===  sortUsersByLastname ? classes.activePillow : classes.inactivePillow} onClick={(e) => {sort.current = sortUsersByLastname;  setRebuild((_rebuild) => !rebuild); e.stopPropagation(); e.preventDefault(); return false;}}>
                                        {t('user.lastName')}
                                   </MoinButton>

                                    <MoinButton size="small" className={sort.current ===  sortDepartments ? classes.activePillow : classes.inactivePillow} onClick={(e) => {sort.current = sortDepartments;  setRebuild((_rebuild) => !rebuild); e.stopPropagation(); e.preventDefault(); return false;}}>
                                        {TranslationHelper.department(t)}
                                   </MoinButton>
                               </div>
                            </>
                    : <>
                        <Tabs
                            onChange={(e, v) => setSelectedTab(v)}
                            value={selectedTab}
                            textColor="primary"
                        >
                            <Tab label={t('user.contacts')}/>
                            {
                                (externalUsers.length > 0 || AUTHSTORAGE.hasPermission(Permission.INVITEEXTERNAL)) &&  <Tab label={t('user.externals')}/>
                            }
                        </Tabs>
                        <Divider/>
                    </>
                }

                <SwipeableViews index={selectedTab}>
                    {
                        // cannot be "&&" because it adds undefined to the list and react-swipeable-views doesn't like that
                        selectedTab === 0
                            ? (
                                <List dir={theme.direction} className={fullHeight ? '' : classes.list}>
                                    { externalTab(withSort ? filteredUsers : colleagues,sort)}
                                </List>
                            )
                            : <div/>
                    }
                    {
                        // cannot be "&&" because it adds undefined to the list and react-swipeable-views doesn't like that
                        selectedTab === 1
                            ? (
                                <List dir={theme.direction} className={fullHeight ? '' : classes.list}>
                                    {
                                        !withSort && withInviteExternal && AUTHSTORAGE.hasPermission(Permission.INVITEEXTERNAL)  && externalUserButton()
                                    }
                                    { externalTab(withSort ? colleagues : externalUsers,sort)}
                                </List>
                            )
                            : <div/>
                    }
                    {
                        // cannot be "&&" because it adds undefined to the list and react-swipeable-views doesn't like that
                        selectedTab === 2
                            ? (
                                <List dir={theme.direction} className={fullHeight ? '' : classes.list}>
                                    {
                                        withInviteExternal && AUTHSTORAGE.hasPermission(Permission.INVITEEXTERNAL)  && externalUserButton()
                                    }
                                    { externalTab(externalUsers,sort)}
                                </List>
                            )
                            : <div/>
                    }
                </SwipeableViews>
            </div>
        );
    }

    if (useTabs && !search) {
        let departmentPlaceholder;
        let departmentKeys = new Set();
        let shownDepartmentKeys = new Set();

        filteredUsers.map( (user) => departmentKeys.add(user.department) );
        let shownDepartments = departments.filter( (department) => departmentKeys.has(department.id)) ;
        shownDepartments.map( (department) => shownDepartmentKeys.add(department.id));
        if (departmentKeys.size >  shownDepartments.length) {
            departmentPlaceholder= new Department();
            departmentPlaceholder.name = TranslationHelper.unknownDepartment(t);
        }

        let usersWithUnknownDepartment = filteredUsers.filter( (user) => !shownDepartmentKeys.has(user.department));

        return (
            <div ref={(node) => scrollAnchor.current = node}>
                {
                    selectedUsers && selectedUsers.length>0 &&
                    <div className={classes.avatarList}>
                        {
                            selectedUsers
                                .filter(userId => (!hideAuth || userId !== AUTHSTORAGE.getUserId()))
                                .map((user) => users.find((userModel) => userModel.id === user))
                                .map((user) => (
                                    buildRemovableUserAvatar(user)
                                ))
                        }
                    </div>
                }

                {
                    (!selectedUsers || selectedUsers.length === 0) && !!placeholder &&
                    <div className={classes.avatarList}>
                        <Typography variant="body1">{placeholder}</Typography>
                    </div>
                }


                <Tabs
                    onChange={(e, v) => setSelectedTab(v)}
                    value={selectedTab}
                    textColor="primary"
                >
                    <Tab label={t('user.firstName')}/>
                    <Tab label={t('user.lastName')}/>
                    {
                        useDepartments &&
                        <Tab label={ TranslationHelper.department(t) }/>
                    }
                </Tabs>
                <Divider/>

                <SwipeableViews index={selectedTab}>
                    {
                        // cannot be "&&" because it adds undefined to the list and react-swipeable-views doesn't like that
                        selectedTab === 0
                        ? (
                            <List dir={theme.direction} className={fullHeight ? '' : classes.list}>
                                {filteredUsers.sort(sortUsersByFirstname).slice(0,userAmount.current).map((user, index, collection) =>
                                    <UserListItem
                                        selectable={showCheckbox}
                                        selected={selectedUsers.includes(user.id)}
                                        onSelect={onSelect}
                                        divider={index !== collection.length - 1}
                                        key={index}
                                        user={user}
                                        showChevron={showChevron}
                                    />
                                )}
                            </List>
                        )
                        : <div/>
                    }
                    {
                        // cannot be "&&" because it adds undefined to the list and react-swipeable-views doesn't like that
                        selectedTab === 1
                        ? (
                            <List dir={theme.direction} className={fullHeight ? '' : classes.list}>

                                {filteredUsers.sort(sortUsersByLastname).slice(0,userAmount.current).map((user, index, collection) =>
                                    <UserListItem
                                        selectable={showCheckbox}
                                        selected={selectedUsers.includes(user.id)}
                                        onSelect={onSelect}
                                        divider={index !== collection.length - 1}
                                        key={'userList' + user.id}
                                        user={user}
                                        showChevron={showChevron}
                                    />
                                )}
                            </List>
                        )
                        : <div/>
                    }


                    {
                        // cannot be "&&" because it adds undefined to the list and react-swipeable-views doesn't like that
                        useDepartments && selectedTab === 2
                        ? (
                            <List dir={theme.direction} className={fullHeight ? '' : classes.list}>
                                {shownDepartments.sort(sortDepartments).map((department, index, collection) =>
                                    <DepartmentListItem
                                        divider={departmentPlaceholder ? false : index !== collection.length - 1}
                                        key={'department'+department.id}
                                        users={filteredUsers.filter((user) => user.department === department.id)}
                                        department={department}
                                        altTile={
                                            ({user,index,collection}) =>  <UserListItem
                                                selectable={showCheckbox}
                                                selected={selectedUsers.includes(user.id)}
                                                onSelect={onSelect}
                                                divider={index !== collection.length - 1}
                                                key={'userListDepartment' + user.id}
                                                user={user}
                                            />
                                        }
                                    />
                                )}
                                {
                                    departmentPlaceholder &&
                                    usersWithUnknownDepartment.length !== 0 &&
                                    <DepartmentListItem
                                        divider={false}
                                        key={"departments-none"}
                                        users={usersWithUnknownDepartment}
                                        department={departmentPlaceholder}
                                        altTile={
                                            ({user,index,collection}) =>  <UserListItem
                                                selectable={showCheckbox}
                                                selected={selectedUsers.includes(user.id)}
                                                onSelect={onSelect}
                                                selectDisabled={ user.id === AUTHSTORAGE.getUserId()}
                                                divider={index !== collection.length - 1}
                                                key={'userListDepartment' + user.id}
                                                user={user}
                                            />
                                        }
                                    />
                                }
                            </List>
                        )
                        : <div/>
                    }
                </SwipeableViews>
            </div>
        )
    }


    return (
        <div ref={(node) => scrollAnchor.current = node}>
            <div hidden={!search}>
                <Typography variant="h4" display="block" style={{marginTop: "18px", marginBottom: "12px", color: Colors.BLACKLIGHT}}>
                    {
                        filteredUsers.length === 1
                            ? t('company.search.singleResult', {count: filteredUsers.length})
                            : t('company.search.result', {count: filteredUsers.length})
                    }
                </Typography>
                <Divider/>
            </div>
            <List dir={theme.direction} className={fullHeight ? '' : classes.list}  ref={(node) => scrollAnchor.current = node}>
            {
                filteredUsers.sort(sortUsersByFirstname).slice(0,userAmount.current).map((user, index, collection) =>
                    <UserListItem
                        selectable={showCheckbox}
                        selected={selectedUsers.includes(user.id)}
                        onSelect={onSelect}
                        divider={index !== collection.length - 1}
                        key={index}
                        user={user}
                        showChevron={showChevron}
                    />
                )
            }
            </List>
        </div>
    );
}

const mapState =  (state, ownProps) =>  {
    return  {
        departments: getModelInstances(state, Department),
        users: ownProps.users ? ownProps.users : getModelInstances(state, User),
    }
}

export default withRouter(connect(mapState)(SearchableSelectableUserList))