import AUTHSTORAGE from "../auth/AuthStorage";
import STORAGECACHEMANAGER from "../../components/base/FirestoreCacheManager";
import { FireStorage } from "../../api/FirebaseService";
import FireStorageUrl from "../models/FireStorageUrl";
import { addModelInstanceDispatch } from "../store/actions";

export function getUserAvatarPath( userId) {
    return "client/" + AUTHSTORAGE.getClientId() + "/user/" + userId + "/avatar";
}

export function getLargeUserAvatarPath( userId) {
    return "client/" + AUTHSTORAGE.getClientId() + "/user/" + userId + "/avatar_large";
}

export function getArticleTitlePath(article) {
    if ( article.cAt != null && (article.cAt.getTime() + 300000 > Date.now() ) && !!article.copyFromUid  && !!article.copyFromArticleId ) {
        return 'client%2F' + AUTHSTORAGE.getClientId() + '%2Fuser%2F' + (article.copyFromUid) +"%2Farticle%2F" + (article.copyFromArticleId) +'%2Ftitle_image';
    }
    return 'client%2F' + AUTHSTORAGE.getClientId() + '%2Fuser%2F' + (article.cUid) +"%2Farticle%2F" + (article.id) +'%2Ftitle_image';
}

export function getGroupAvatarPath( imageOwner, groupId ) {
    return "client/" + AUTHSTORAGE.getClientId() + "/user/" + imageOwner + "/group/" + groupId + "_header";
}

export function getEventAvatarPath( eventCreator, eventId ) {
    return "client/" + AUTHSTORAGE.getClientId() + "/user/" + eventCreator + "/event/" + eventId + "_header";
}

export function getChatAvatarPath( chatCreator, chatId ) {
    return "client/" + AUTHSTORAGE.getClientId() + "/user/" + chatCreator + "/chat/" + chatId + "_header";
}
