import {id, model, prop} from "../manager/decorators";

@model("/client/:client/distributor/:distributor/request")
class DistributorRequest {

    @id()
    id;

    @prop()
    uid

    @prop()
    cAt

    distributorId;
}

export default DistributorRequest;

