import React, {useEffect, useRef, useState} from 'react';
import ArticleStream from "./ArticleStream";
import ModelManager from "../../utils/manager/ModelManager";
import Article from "../../utils/models/Article";
import {getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import ApiClient from "../../api/ApiClient";
import parameters from "../../config/parameters.json";
import AUTHDISTRIBUTORMEMBERSHIP from "../../utils/auth/AuthDistributorMembership";
import {updateModelInstance} from "../../utils/store/actions";
import User from "../../utils/models/User";
import Event from "../../utils/models/Event";
import SCROLLHELPER from "../../utils/helper/ScrollHelper";

/**
 * @param articles
 * @param users
 * @param events
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleStreamContainer = ({articles, users, events}) => {
    const loadBatchSize = 10;
    const [rebuild, setRebuild] = useState(true);
    const loading = useRef(true);
    const noMoreArticles = useRef(false);
    const failedLoadingOnce = useRef(false);
    const showError = useRef(false);

    useEffect(() => {
        SCROLLHELPER.restoreScrollPosition();
        loadingRequest();
    }, []);

    const loadingRequest = () =>  {
        makeLoadingRequest().then((result) =>  {
            loadPostsForElasticResult(result);
            setTimeout(() => {
                setRebuild((old) => !old);
            }, 200);
        }).catch( (ex) => {
            if (!failedLoadingOnce.current) {
                failedLoadingOnce.current = true;
                loadingRequest();
                setTimeout(() => {
                    failedLoadingOnce.current = false;
                }, 5000);
            } else {
                showError.current = true;
                setRebuild((old) => !old);
            }
        });
    }

    const loadPostsForElasticResult = (result) => {
        if (!!result && !!result.data) {
            let atLastOneNewArticle = false;
            result.data.forEach((data) => {
                let article = articles.find( (article) => article.id === data.id);
                if (!!article) {
                    if (!article.stream) {
                        article.stream = true;
                        updateModelInstance(Article,article);
                    }
                } else {
                    atLastOneNewArticle = true;
                    ModelManager.get({model: Article, id: data.id, extraProps: {stream: true}}).then(() => {
                        loading.current = false;
                        setRebuild((old) => !old);
                    });
                }
            })

            if (result.data.length === 0 || !atLastOneNewArticle) {
                loading.current = false;
                noMoreArticles.current = result.data.length === 0 ;
                setRebuild((old) => !old);
            }
        }
    }

    const loadOlderArticles = (lastCreatedAt) => {
        if(!loading.current && !noMoreArticles.current) {
            loading.current = true;
            setRebuild(!rebuild);
            makeLoadingRequest(lastCreatedAt).then(
                (result) => {
                    loadPostsForElasticResult(result);
                }
            );
        }
    };

    const loadNewerArticles = (newestCreatedAt) => {
        makeLoadingRequest(newestCreatedAt, true).then(
            (result) => {
                // ignore refresh if it does not contain any data
                if ( result && result.data && result.data.length > 0) {
                    loadPostsForElasticResult(result);
                } else {
                    loading.current = false;
                    setRebuild((old) => !old);
                }
            }
        ).catch( (ex) => {
            loading.current = false;
            setRebuild((old) => !old);
        });
    };


    const makeLoadingRequest = async (lastCreatedAt, newer = false) => {
        loading.current = true;

        let memberDistributor = Array.from(AUTHDISTRIBUTORMEMBERSHIP.getAllMemberships());
        memberDistributor.push(parameters.globalDistributorId);

        let body = {};
        body['size'] = loadBatchSize;
        body['from'] = 0;
        body['withGlobal'] = true;
        body['dis'] = JSON.stringify(memberDistributor);
        if (!!lastCreatedAt) {
            body['cAt'] = lastCreatedAt.getTime();
        }
        if (newer) {
            body['newer'] = "1";
        }

        let request = await ApiClient.createRequest('/load/article', body);
        return request.send();
    }

    return(
        <ArticleStream
            users={users}
            events={events}
            articles={articles}
            loadOlderArticles={loadOlderArticles}
            loadNewerArticles={loadNewerArticles}
            loading={loading.current}
            showError={showError.current}
            noMoreArticles={noMoreArticles.current}
        />
    );
};

const mapState = (state) => ({
    articles: getModelInstances(state, Article),
    users: getModelInstances(state, User),
    events: getModelInstances(state, Event)
});

export default connect(mapState)(ArticleStreamContainer);
