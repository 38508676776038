import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from "@material-ui/core/Container";
import {useTranslation} from "react-i18next";
import MuiAlert from '@material-ui/lab/Alert';
import {LoginState} from "../utils/enums/LoginState";
import MoinTextField from "../components/base/MoinTextField";
import MoinButton from "../components/base/MoinButton";
import MoinSplashScreen from "../components/base/MoinSplashScreen";
import illustration from "../assets/illustrations/moin-app-illus-website-header.png";
import logo from "../assets/illustrations/logo.png";
import {Colors} from "../components/helper/ColorHelper";
import {Link} from "react-router-dom";
import * as ROUTES from "../Routes";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent : 'space-between'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        boxShadow: 'none'
    },
    illustration: {
        width: '65%',
        height: 'auto'
    },
    logo: {
        width: '40%',
        height: 'auto',
        marginRight: 'auto'
    },
    link: {
        textDecoration: 'none',
        color: Colors.BRANDSECONDARY,
        '&:hover': {
            color: Colors.BRANDPRIMARY
        },
        display: 'block',
        width: '100%',
        textAlign: 'center',
        padding: "0 1rem",
    },
    bottomLinks: {
        marginTop: '32px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    colorOverlay: {
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        position: 'fixed',
        backgroundColor: Colors.BLUEBACKGROUND,
        zIndex: 0
    },
    content: {
        zIndex: 1,
        position: 'relative'
    },
    contentWrapper: {
        zIndex: 1,
        position: 'relative',
        minHeight: '100vh',
        display: "flex",
        flexDirection: "column",
        justifyContent: 'space-between'
    }
}));

const AuthorizationLoginPage = ({login, loginState, loginError}) => {

    const [t, i18n] = useTranslation();
    const [workspace, setWorkspace] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submitErrors, setSubmitErrors] = useState({});

    const classes = useStyles();

    const submit = () =>  {
        let errors = {};

        if (!workspace) {
            errors['workspace'] = t('form.errors.cantBeEmpty', {field: t('login.workspace')});
        } else if (workspace.length < 3) {
            errors['workspace'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('login.workspace'), chars: 3});
        }

        if (!username) {
            errors['username'] = t('form.errors.cantBeEmpty', {field: t('login.username')});
        } else if (username.length < 3) {
            errors['username'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('login.username'), chars: 3});
        }
        if (!password) {
            errors['password'] = t('form.errors.cantBeEmpty', {field: t('login.password')});
        } else if (password.length < 6) {
            errors['password'] = t('form.errors.mustBeAtLeastXCharacters', {field: t('login.password'), chars: 6});
        }

        setSubmitErrors(errors);
        if (Object.keys(errors).length  === 0 ) {
           login({
               workspace: workspace.trim(),
               username: username.trim(),
               password: password,
           })
        }
    }

    if (loginState === LoginState.WAITING) {
        return (<MoinSplashScreen />)
    }

    return (
        <>
            <div className={classes.colorOverlay}/>
            <Container component="main" maxWidth="xs" className={classes.contentWrapper}>
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={illustration} alt={t('common.illustration')} className={`${classes.illustration} ${classes.content}`}/>
                    <img src={logo} alt={t('common.moinlogo')} className={`${classes.logo} ${classes.content}`}/>
                    <form className={classes.form} noValidate
                          onSubmit={
                              (e) => {
                                  submit(e);
                                  e.preventDefault();
                                  e.stopPropagation();
                                  return false;
                              }
                          }
                    >
                        <MoinTextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="workspace"
                            label= {t('login.workspace')}
                            value={workspace}
                            name="workspace"
                            autoComplete="workspace"
                            autoFocus

                            onChange={(e) => setWorkspace(e.target.value)}
                            error={ submitErrors.hasOwnProperty('workspace')}
                            helperText={ submitErrors.hasOwnProperty('workspace') &&  submitErrors["workspace"]}
                        />
                        <MoinTextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label= {t('login.username')}
                            name="username"
                            value={username}
                            autoComplete="username"
                            autoFocus

                            onChange={(e) => setUsername(e.target.value)}
                            error={ submitErrors.hasOwnProperty('username')}
                            helperText={ submitErrors.hasOwnProperty('username') &&  submitErrors["username"]}
                        />
                        <MoinTextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('login.password')}
                            type="password"
                            id="password"
                            autoComplete="current-password"

                            onChange={(e) => setPassword(e.target.value)}
                            error={ submitErrors.hasOwnProperty('password')}
                            helperText={ submitErrors.hasOwnProperty('password') &&  submitErrors["password"]}
                        />
                        <MoinButton
                            disabled={loginState === LoginState.WAITING}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={submit}
                        >
                            {t('login.signIn')}
                        </MoinButton>

                        {
                            loginState === LoginState.ERROR && !!loginError &&
                            <MuiAlert elevation={6} variant="filled" severity="error">{loginError}</MuiAlert>
                        }

                        <Link to={ROUTES.loginForgotPassword} className={`${classes.link} ${classes.content}`}>
                            {t('login.forgotPassword')}
                        </Link>
                    </form>
                </div>
                <div className={classes.bottomLinks}>
                    <Link to={ROUTES.dpd} className={classes.link}>
                        {t('legal.dpd')}
                    </Link>

                    <Link to={ROUTES.terms} className={classes.link}>
                        {t('legal.terms')}
                    </Link>

                    <Link to={ROUTES.impress} className={classes.link}>
                        {t('legal.impress')}
                    </Link>
                </div>
            </Container>
        </>
    );
};
export default AuthorizationLoginPage;
