import React, {useRef} from "react";
import VideoContentRow from "./VideoContentRow";
import * as httpClient from "../../utils/httpclient/HttpClient";
import parameters from "../../config/parameters.json";
import {FireAuth} from "../../api/FirebaseService";
import {createVideoThumbnail} from "../../utils/storage/StorageClient";

/**
 * @param {string} articleId
 * @param {Function} onDelete
 * @param {Function} onChange
 * @param {ArticleContent} value
 * @returns {JSX.Element}
 * @constructor
 */
const VideoContentRowContainer = ({articleId, onDelete, onChange, value,onUpload}) => {
    let currentUpload = useRef(0);

    const onVideoChange = async (video) => {
        const uploadId = Date.now();
        currentUpload.current = uploadId;

        value.uploadId = uploadId;
        value.video.url = URL.createObjectURL(video);

        let thumbnailFile = await createVideoThumbnail(video);
        if(thumbnailFile) {
            value.video.thumbnail = URL.createObjectURL(thumbnailFile);
        }
        onChange(value);

        // DO NOT ADD A Content-Type Header, the server will crash
        let httpClientInstance = new httpClient.default(parameters.backendVideoUrl, {
            headers: {
                'Authorization': `Bearer ${await FireAuth.currentUser.getIdToken()}`
            }
        });

        const formData = new FormData();

        formData.append('uploadId', ""+uploadId);
        formData.append('articleId', articleId);
        formData.append('video', video);

        let request = httpClientInstance.post('/upload', formData);

        onUpload(request.send());
    };

    const onTextChange = (text) => {
        value.text = text;
        onChange(text);
    };

    return <VideoContentRow onDelete={onDelete} value={value} onChangeVideo={onVideoChange} onChangeText={onTextChange}/>
}

export default VideoContentRowContainer;