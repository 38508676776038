import model from "../manager/decorators/model";
import {prop} from "../manager/decorators";

@model(null)
class ArticleContent {
    @prop()
    type;

    @prop()
    text;

    @prop()
    name;

    @prop()
    path;

    @prop()
    surveyId;

    @prop()
    image;

    @prop()
    media;

    @prop()
    video;

    @prop()
    uploadId;

    @prop()
    size;

    @prop()
    iTI;
}

export default ArticleContent;