import EventDispatcher from "../manager/manager/eventdispatcher/EventDispatcher";

const STATE_IDLE = 'idle';
const STATE_PROCESS = 'process';
const STATE_SUCCESS = 'success';
const STATE_FAIL = 'fail';

export default class HttpRequest extends EventDispatcher {


    constructor(path, options)
    {
        super();

        this.path = path;
        this.options = options;
        this.promise = null;
        this._processTimeout = null;
        this.currentState = STATE_IDLE;


        this
            .addListener('startTrack.js', () => {
                this._dispatchProgress()
            })
            .addListener('done', () => {
                clearTimeout(this._processTimeout);
            });
    }


    /**
     * execute the actual request
     * @return {Promise}
     */
    send()
    {
        this.promise = new Promise((resolve, reject) => fetch(this.path, this.options)
            .then(async (response) =>  {
                let responseContent = await response.text();
                try {
                    responseContent = JSON.parse(responseContent);
                } catch (ex) {}

                if (response.status === 200 || response.status === 204) {
                    resolve(responseContent)
                } else {
                    reject({statusCode: response.status, body: responseContent})
                }
            })
            .then(
                (result) => {
                    this.currentState = STATE_SUCCESS;
                    this.dispatch('success', {...result} );
                    this.dispatch('done', {...result} );
                    resolve(result);
                },
                (error) => {
                    this.currentState = STATE_FAIL;
                    this.dispatch('error', {
                        code: error.code,
                        name: error.name,
                        message: error.message,
                        details: error.details
                    });
                    this.dispatch('done');
                    reject(error);
                })
            .catch(error => {
                reject(error)
            })
        );

        this.dispatch('start', this.promise);
        this.currentState = STATE_PROCESS;


        return this.promise;
    }

    _dispatchProgress()
    {
        this.dispatch('progress', this);
        this._processTimeout = setTimeout(() => this._dispatchProgress(), 50);
    }

    getState()
    {
        return this.currentState;
    }


    /**
     * encode sing uri components
     * @param url
     * @return {string}
     * @private
     */
    _encode(url)
    {
        return url.split('/').map(encodeURIComponent).join('/');
    }
}
