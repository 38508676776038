import RemainingFieldsType from "../types/RemainingFieldsType";
import {FireDelete} from "../../../api/FirebaseService";

class ModelConverter {

    static create(Model) {
        return {
            toFirestore(modelInstance, isUpdate=false) {

                const data = {};

                const {props} = Model.metadata;

                Object.keys(props)
                    .filter(prop => props[prop].sentinel || typeof modelInstance[prop] !== "undefined" ||   props[prop].nullable)
                    .forEach(prop => {

                        const {sentinel, readOnly, createOnly, type} = props[prop];
                        const instanceValue = modelInstance[prop];
                        let dataValue = modelInstance[prop];

                        // handle sentinel values
                        if( (!createOnly || !isUpdate)
                            && ((sentinel && !readOnly)
                            || (sentinel && readOnly && (typeof dataValue === "undefined" || !isUpdate) ))
                        ) {
                            data[prop] = sentinel();
                        } else if (type instanceof RemainingFieldsType)  {
                           Object.keys(dataValue).forEach ( (key)  => {
                               data[key] = dataValue[key];
                           });
                        } else if (!readOnly && !(createOnly && isUpdate) &&  (dataValue === false || dataValue === "" || dataValue === 0 || !!dataValue)) {
                            data[prop] = type.toFirestore(dataValue);
                        } else if (props[prop].nullable && !dataValue) {
                            data[prop] = null;
                        }
                    });

                if(isUpdate) {
                    Object.keys(props)
                        .filter(prop => (typeof modelInstance[prop] === "undefined" &&  !props[prop].readOnly))
                        .forEach(prop => {
                            data[prop] = FireDelete();
                        });
                }
                return data;

            },

            fromFirestore(snapshot, options) {

                const data = snapshot.data(options);
                const id = snapshot.id;

                const resource = snapshot.ref.parent.path;

                const {props, idProp} = Model.metadata;

                const modelInstance = new Model();

                modelInstance.__resource = resource;

                // protect "__resource" property
                Object.defineProperty(modelInstance, '__resource', {
                    configurable: false,
                    writable: false
                });

                // make "id" property writable again
                Object.defineProperty(modelInstance, idProp, {
                    configurable: true,
                    writable: true
                });

                modelInstance[idProp] = id;

                // make "id" property readOnly again
                Object.defineProperty(modelInstance, idProp, {
                    configurable: true,
                    writable: false
                });


                Object.keys(props)
                    .forEach(prop => {

                        const {type, nullable, readOnly} = props[prop];
                        const value = data[prop];
                        //if(typeof value !== "undefined")

                        if (type instanceof RemainingFieldsType) {
                            let remainingFields = {};
                            let existingFields = Object.keys(props);

                            Object.keys(data).forEach ( (key)  => {
                                if (!existingFields.hasOwnProperty(key)) {
                                    remainingFields[key] = data[key]
                                }
                            } );
                            modelInstance[prop] = remainingFields;
                        } else {
                            modelInstance[prop] = value !== "undefined"
                                ? type.fromFirestore(value)
                                : null;
                        }
                    });

                return modelInstance;
            }
        };
    };
}


export default ModelConverter;
