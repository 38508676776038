class HighlightedGroups {
    #_highlightedGroups = {};

    /**
     *
     * @param {Object} highlightedGroups
     */
    setHighlightedGroups = (highlightedGroups) => {
        this.#_highlightedGroups = {};
        Object.keys(highlightedGroups).forEach(groupId => {
            let highlightDate = highlightedGroups[groupId];

            // When a group is highlighted, the listener fires with a null timestamp,
            // and shortly after that fires again with the correct timestamp
            if(highlightDate) {
                highlightDate = highlightDate.toDate();
            } else {
                highlightDate = new Date();
            }

            this.#_highlightedGroups[groupId] = highlightDate;
        });
    };

    toggleGroupHighlighted = (groupId) => {
        if(this.isHighlighted(groupId)) {
            delete this.#_highlightedGroups[groupId];
        } else {
            this.#_highlightedGroups[groupId] = new Date();
        }
    }

    isHighlighted(groupId) {
        return Object.keys(this.#_highlightedGroups).includes(groupId);
    }

    getGroupHighlightDate(groupId) {
        return this.#_highlightedGroups[groupId];
    }

    canHighlightMoreGroups() {
        return Object.keys(this.#_highlightedGroups).length < 3;
    }

    getOldestHighlightedGroup() {
        if (Object.keys(this.#_highlightedGroups).length === 0) {
            return undefined;
        }
        return Object.keys(this.#_highlightedGroups).reduce((a, b) => this.#_highlightedGroups[a].getTime() < this.#_highlightedGroups[b].getTime() ? a : b);
    }

    reset() {
        this.#_highlightedGroups = [];
    }
}

const HIGHLIGHTEDGROUPS = new HighlightedGroups();

export default HIGHLIGHTEDGROUPS;
