import imageCompression from "browser-image-compression";
import {EventDispatcher} from "./eventdispatcher";
import {FireStorage} from "../../../api/FirebaseService";

class FirebaseStorageManager extends EventDispatcher {
    /**
     * firebase storage instance
     * @type {firebase.storage.Storage}
     */
    connection = FireStorage;

    /**
     * @param connection
     */
    constructor(connection = null) {
        super();
        this.uploadImage = this.uploadImage.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    /**
     * upload new file to firebase storage
     * @param file
     * @param path
     * @param maxWidthOrHeight
     * @param maxSizeMB
     * @param onProgress
     * @return {Promise<object>}
     */
    uploadImage(file, path, maxWidthOrHeight=1200, maxSizeMB=0.5, onProgress) {
        const options = {
            maxSizeMB: maxSizeMB,
            maxWidthOrHeight: maxWidthOrHeight
        };

        return new Promise((resolve, reject) => {
            imageCompression(file, options).then(compressedImage => {
                let uploadTask = this.connection.ref(path).put(compressedImage);

                if (!!onProgress) {
                    uploadTask.on('state_changed', function(snapshot){
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        onProgress(progress);
                    });
                }

                uploadTask.then(snapshot => {
                    resolve(snapshot);
                }, error => {
                    const errorObject = {
                        model: null,
                        modelInstance: null,
                        resource: null,
                        code: "storage_manager/upload-failed",
                        message: error.message
                    };

                    this.dispatch('error', {...errorObject});

                    reject(errorObject);
                });
            });
        });
    }

    /**
     * upload new file to firebase storage
     * @param file
     * @param path
     * @param {Function} onProgress,
     * @param {Function} isCanceled
     * @param {Function} onCancel
     * @return {Promise<object>}
     */
    uploadFile(file, path, onProgress, isCanceled,onCancel) {
        return new Promise((resolve, reject) => {
            let uploadTask = this.connection.ref(path).put(file);

            if (!!onProgress) {
                uploadTask.on('state_changed', function(snapshot){
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    if (isCanceled && isCanceled()) {
                        if (onCancel) {
                            onCancel();
                        }
                        uploadTask.cancel();
                    } else {
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        onProgress(progress);
                    }
                });
            }

            uploadTask.then(snapshot => {
                resolve(snapshot);
            }, error => {
                const errorObject = {
                    model: null,
                    modelInstance: null,
                    resource: null,
                    code: "storage_manager/upload-failed",
                    message: error.message
                };

                this.dispatch('error', {...errorObject});

                reject(errorObject);
            });
        });
    }

    /**
     * delete a file from firebase storage
     * @param path
     * @return {Promise<object>}
     */
    deleteFile(path) {
        return new Promise((resolve, reject) => {
            let ref = this.connection.ref(path);
            ref.delete().then(() => {
                resolve();
            }, (ex) => {
                console.log(ex);
                reject();
            });
        });
    }

    downloadFile(path, name) {
        return new Promise((resolve, reject) => {
            let ref = this.connection.ref(path);
            ref.getDownloadURL().then((url) => {

                let link = document.createElement('a');
                link.href = url;
                link.target= "_blank";
                link.download = name;
                link.click();

                resolve();
            }, (ex) => {
                console.log(ex);
                reject();
            });
        });
    }

    downloadBlob = (file, name) => {
        let blob = new Blob([file], {type: "octet/stream"});
        let link = document.createElement('a');
        link.target= "_blank";
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
    }

    downloadWithProgress = async (path, name, onProgress, onReady, isCanceled) => {
        let req = new XMLHttpRequest();
        req.onprogress = (event) => {
            if (isCanceled && isCanceled()) {
                req.abort();
            } else {
                let progress = Math.floor((event.loaded / event.total) * 100);
                if (onProgress && progress)
                    onProgress(progress);
            }
        }  ;
        req.abort()
        let ref = this.connection.ref(path);
        ref.getDownloadURL().then((url) => {
            req.open('GET', url, true);
            req.responseType = 'arraybuffer';
            req.onreadystatechange = function (aEvt) {
                if (req.readyState == 4) {
                    if (typeof onReady === 'function') {
                        onReady(req.response);
                    }
                    if (!isCanceled || !isCanceled()) {
                        this.downloadBlob(req.response, name);
                    }
                }
            }.bind(this);
            req.send();
        });
    }

    getDownloadUrlForPath = (path) => {
        let ref = this.connection.ref(path);
        return ref.getDownloadURL();
    }
}

export default FirebaseStorageManager;