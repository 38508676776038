import i18n from './i18n';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../components/helper/ColorHelper";
import ListItem from "@material-ui/core/ListItem";
import ModelManager from "../manager/ModelManager";
import AUTHSTORAGE from "../auth/AuthStorage";
import MASTERTHEME from "../../pages/MasterTheme";

const useStyles = makeStyles((theme) => ({
    options: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    option: {
        color: Colors.BRANDPRIMARY,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        cursor: "pointer"
    }
}));


const Language= () => {
    const classes = MASTERTHEME.getStyles();
    const addClasses = useStyles();
    const [showLanguage,setShowLanguage]  =useState(false);
    const [t] = useTranslation();

    const setLanguage = (lang) => {
        ModelManager.update({
            resource: "/client/" +AUTHSTORAGE.getClientId() + "/user/" + AUTHSTORAGE.getUserId() + '/private/',
            id: "settings",
            customData: {'lang': lang},
            useSet: true
        });
        i18n.changeLanguage(lang);
        AUTHSTORAGE.setLanguage(lang);
        setShowLanguage(false);
    }

    const getLanguageDialog = () => {
        return (
            <Dialog
                open={showLanguage}
                onClose={() => setShowLanguage(false)}
                onClick={(event) => {event.stopPropagation(); event.preventDefault();return false}}
                aria-labelledby="language-dialog-title"
                aria-describedby="language-dialog-description"
            >
                <DialogTitle id="language-dialog-title">{ t('language.changeLanguage') }</DialogTitle>
                <DialogContent>
                    <div className={addClasses.options}>
                        <Typography className={addClasses.option} variant={"body2"} onClick={() => setLanguage("de")}>{ t('language.german') }</Typography>
                        <Typography className={addClasses.option} variant={"body2"} onClick={() => setLanguage("en")}>{ t('language.english') }</Typography>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };


    return (
        <>
            {
                showLanguage && getLanguageDialog()
            }
            <ListItem className={ classes.drawerItemSub}  onClick={() => setShowLanguage(true) } button key='nav.language'>
                <Typography variant={'caption'} >
                    {t('language.language')}
                </Typography>
            </ListItem>
        </>
    )
}

export default Language;