class FirestoreError{
    model;
    modelInstance;
    resource;
    code;
    message;

    constructor(model, modelInstance, resource, code, message) {
        this.model = model;
        this.modelInstance = modelInstance;
        this.resource = resource;
        this.code = code;
        this.message = message;
    }

}

export default FirestoreError;