import app from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import config from '../config/firebase.json';

const Firebase = app.initializeApp(config);
export default Firebase;
export const FireStore = Firebase.firestore();
export const FireStorage = Firebase.storage();
export const FireAuth = Firebase.auth();
export const FireTimestamp = app.firestore.FieldValue.serverTimestamp;
export const FireIncrement = app.firestore.FieldValue.increment;
export const FireDelete = app.firestore.FieldValue.delete;
export const firebase = app;
