import React, {useEffect, useRef, useState} from 'react';
import MasterLayoutContainer from "./MasterLayoutContainer";
import {LoginState} from "../utils/enums/LoginState";
import AuthorizationLoginPage from "./AuthorizationLoginPage";
import {FireAuth, FireIncrement, FireTimestamp} from "../api/FirebaseService";
import LoginHelper from "../utils/helper/LoginHelper";
import {
    ADD_MODEL_INSTANCE,
    addModelInstances, deleteModelInstance, updateModelInstances,
    USER_LOGOUT
} from "../utils/store/actions";
import * as forge from "node-forge";
import store from "../utils/store/store";
import {useTranslation} from "react-i18next";
import AUTHSTORAGE from "../utils/auth/AuthStorage";
import ModelManager from "../utils/manager/ModelManager";
import Client from "../utils/models/Client";
import User from "../utils/models/User";
import {Colors} from "../components/helper/ColorHelper";
import MASTERTHEME from "./MasterTheme";
import {connect} from "react-redux";
import AuthStorage from "../utils/auth/AuthStorage";
import AUTHDISTRIBUTORMEMBERSHIP from "../utils/auth/AuthDistributorMembership";
import Distributor from "../utils/models/Distributor";
import PrivateKey from "../utils/models/PrivateKey";
import MasterPasswordPage from "./MasterPasswordPage";
import * as CacheHelper from "../utils/helper/CacheHelper";
import {ClientType} from "../utils/enums/ClientType";
import Department from "../utils/models/Department";
import {DateTime} from "luxon";
import Event from "../utils/models/Event";
import ElasticAccess from "../utils/models/ElasticAccess";
import Task from "../utils/models/Task";
import {Translations} from "../components/helper/TranslationHelper";
import {
    decryptLocalStorageData,
    encryptLocalStorageData,
    generateBrowserEncryptionKey, padDecryptionPassword
} from "../utils/helper/EncryptionHelper";
import MoinUnsupportedBrowserDialog from "../components/base/MoinUnsupportedBrowserDialog";
import {Feature} from "../utils/enums/Feature";
import HIGHLIGHTEDARTICLES from "../utils/articles/HighlightedArticles";
import Article from "../utils/models/Article";
import FAVORITEDARTICLES from "../utils/articles/FavoritedArticles";
import HIGHLIGHTEDGROUPS from "../utils/groups/HighlightedGroups";

const AuthorizationContainer = () => {
    let [loginState, setLoginState] = useState(LoginState.WAITING)
    let [error, setError] = useState(null);
    let [browserError, setBrowserError] = useState(null);
    let [privateKey, setPrivateKey] = useState(null);
    let enteredUserPassword = useRef(null);
    const [t, i18n] = useTranslation();

    useEffect(() => {
        store.dispatch({
            type: USER_LOGOUT
        });

        FireAuth.onAuthStateChanged(async (firebaseUser) =>  {
            setLoginState(LoginState.WAITING);
            if(firebaseUser) {
                // retrieve the user token and setup admin client
                let tokenResult  = await firebaseUser.getIdTokenResult(true).catch((e) => {
                    onError( t('errors.login.userDisabled') )
                });
                const {claims, token} = tokenResult;
                const {clientId, appRoles, roles} = claims;

                if(clientId) {
                    let clientModel = await ModelManager.get({model: Client, id: clientId}).catch((e) => {
                        onError(t('errors.login.clientDisabled'))
                    });
                    // clear old store if it still exists
                    store.dispatch({
                        type: USER_LOGOUT
                    });

                    if (!!clientModel && clientModel.enabled) {
                        if (!clientModel.type)  {
                            clientModel.type = ClientType.COMPANY;
                        }
                        if (!clientModel.features)  {
                            clientModel.features =  [];
                        }

                        loadClientColors(clientModel);
                        loadClientTranslations(clientModel);
                        let permissions = {};
                        if (!!appRoles) {
                            permissions=Object.keys(appRoles);
                        }
                        // we must do this before loading the user model otherwise it cant be loaded
                        let userModel = await ModelManager.get({model: User, id: firebaseUser.uid, resource: `client/${clientModel.id}/user` }).catch((e) => {
                            onError(t('errors.login.userDisabled'))
                        });

                        if (userModel && userModel.enabled) {
                            let external = userModel.external;
                            AUTHSTORAGE.setAuth(clientModel, firebaseUser.uid, !!external, permissions, null, roles ?? []);
                            try {
                                clientModel.elasticAccess =  await ModelManager.get({model: ElasticAccess, id: "elastic"}).catch((e) => {
                                    console.log(e);
                                    onError(t('errors.login.noElasticAccess'))
                                });
                                AUTHSTORAGE.setAuth(clientModel, firebaseUser.uid, !!external, permissions, null, roles ?? []);
                            } catch(ex) {
                                // Client has no elastic access
                            }


                            let userSettings = await ModelManager.get({
                                resource: "/client/" +AUTHSTORAGE.getClientId() + "/user/" + AUTHSTORAGE.getUserId() + '/private/',
                                id: "settings"
                            });

                            AUTHSTORAGE.setLanguage(userSettings?.lang ?? "de");
                            i18n.changeLanguage(userSettings?.lang ?? "de");

                            let currentMonth = (new Date()).toISOString().substring(0, 7);
                            if(!userSettings || !Object.keys(userSettings).includes('lastLoginMonth') || currentMonth > userSettings?.lastLoginMonth) {
                                ModelManager.update({
                                    resource: "/client/" +AUTHSTORAGE.getClientId() + "/user/" + AUTHSTORAGE.getUserId() + '/private',
                                    id: "settings",
                                    useSet: true,
                                    customData: {
                                        lastLoginMonth: currentMonth,
                                        lang: userSettings?.lang ??"de"
                                    }
                                });

                                ModelManager.update({
                                    resource: "/client/" +AUTHSTORAGE.getClientId() + "/last_login_months",
                                    id: "_all",
                                    useSet: true,
                                    customData: {
                                        [currentMonth]: FireIncrement(1)
                                    }
                                });
                            }

                            if(!userModel.hasLoggedIn) {
                                ModelManager.update({
                                    modelInstance: User,
                                    id: firebaseUser.uid,
                                    resource: `client/${clientModel.id}/user`,
                                    customData: {
                                        hasLoggedIn: true,
                                        updatedAt: FireTimestamp()
                                    }
                                })
                            }

                            let encryptedPrivateKeyMaster = await ModelManager.get({model: PrivateKey, id: firebaseUser.uid});
                            let encryptedPrivateKeyPassword = await getBrowserEncryptionKey();

                            store.dispatch({
                                type: ADD_MODEL_INSTANCE,
                                model: Client,
                                data: clientModel
                            })

                            store.dispatch({
                                type: ADD_MODEL_INSTANCE,
                                model: User,
                                data: userModel
                            })
                            CacheHelper.setCachedUser(userModel, true);

                            if (!!encryptedPrivateKeyMaster) {
                                let mustInputMaster = true;

                                let existingMasterPassword = localStorage.getItem("mp_" + AUTHSTORAGE.getUserId());
                                let existingEncryptedMasterPassword = localStorage.getItem("mpe_" + AUTHSTORAGE.getUserId());
                                let existingEncryptedUserPassword = localStorage.getItem("pwe_" + AUTHSTORAGE.getUserId());
                                let decryptedMasterPassword;
                                let decryptedUserPassword;

                                if(existingEncryptedMasterPassword) {
                                    decryptedMasterPassword = decryptLocalStorageData(encryptedPrivateKeyPassword.browserEncryptionKey, encryptedPrivateKeyPassword.browserEncryptionIV, existingEncryptedMasterPassword)
                                } else if(existingMasterPassword) {
                                    decryptedMasterPassword = existingMasterPassword;
                                    let encryptedMasterPassword = encryptLocalStorageData(encryptedPrivateKeyPassword.browserEncryptionKey, encryptedPrivateKeyPassword.browserEncryptionIV, existingMasterPassword);
                                    localStorage.setItem("mpe_" + AUTHSTORAGE.getUserId(), encryptedMasterPassword);
                                    localStorage.removeItem("mp_" + AUTHSTORAGE.getUserId());
                                }

                                if(existingEncryptedUserPassword) {
                                    decryptedUserPassword = decryptLocalStorageData(encryptedPrivateKeyPassword.browserEncryptionKey, encryptedPrivateKeyPassword.browserEncryptionIV, existingEncryptedUserPassword);
                                }

                                if((!!encryptedPrivateKeyPassword && !!encryptedPrivateKeyPassword.privateKey && !!encryptedPrivateKeyPassword.iv)
                                    && (!!decryptedUserPassword || enteredUserPassword.current)) {

                                    let decryptPassword = decryptedUserPassword;
                                    let passwordChanged = false;

                                    if(!decryptPassword) {
                                        decryptPassword = padDecryptionPassword(enteredUserPassword.current, firebaseUser.uid);
                                        passwordChanged = true;
                                    }

                                    mustInputMaster = !decryptPrivateKey(decryptPassword, encryptedPrivateKeyPassword);
                                    setError(undefined);

                                    if(!mustInputMaster && passwordChanged) {
                                        let encryptedUserPassword = encryptLocalStorageData(encryptedPrivateKeyPassword.browserEncryptionKey, encryptedPrivateKeyPassword.browserEncryptionIV, decryptPassword);
                                        localStorage.setItem("pwe_" + AUTHSTORAGE.getUserId(), encryptedUserPassword);
                                    }
                                }

                                if (!!decryptedMasterPassword && mustInputMaster) {
                                    mustInputMaster = !decryptPrivateKey(decryptedMasterPassword.replaceAll("-", ''), encryptedPrivateKeyMaster);
                                    setError(undefined);

                                    if(!mustInputMaster) {
                                        let encryptedMasterPassword = encryptLocalStorageData(encryptedPrivateKeyPassword.browserEncryptionKey, encryptedPrivateKeyPassword.browserEncryptionIV, decryptedMasterPassword);
                                        localStorage.setItem("mpe_" + AUTHSTORAGE.getUserId(), encryptedMasterPassword);
                                    }
                                }

                                if (mustInputMaster) {
                                    setPrivateKey(encryptedPrivateKeyMaster);
                                    setLoginState(LoginState.MASTERPASSWORD);
                                }
                            } else {
                                initData();
                            }


                        } else {
                            onError(t('errors.login.userDisabled'));
                        }

                    } else {
                        onError(t('errors.login.userDisabled'));
                    }
                } else {
                    onError(t('errors.login.userDisabled'));
                }
            } else {
                setLoginState(LoginState.NONE);
            }
        })
    }, []);

    const initData = async () => {
        let loadingProcesses = [];

        loadingProcesses.push(CacheHelper.loadCachedUsers());
        loadingProcesses.push(CacheHelper.loadCachedEvents());
        loadingProcesses.push(CacheHelper.loadCachedChats());
        loadingProcesses.push(CacheHelper.loadCachedTask());
        // Do not comment this in, deleted Distributors would still be shown in the web app
        // loadingProcesses.push(CacheHelper.loadCachedDistributors());

        let cachingFailed =  !(await CacheHelper.canCache());

        loadingProcesses.push(
            ( async () => {
                let existingUsers = await CacheHelper.loadCachedUsers();
                let lastUpdatedAt;
                existingUsers.forEach((user) => {
                    if (!lastUpdatedAt || user.updatedAt > lastUpdatedAt) {
                        lastUpdatedAt = user.updatedAt;
                    }
                });

                let loadedUsers;
                if(!!lastUpdatedAt){
                    loadedUsers = await ModelManager.list({model: User, filter: [['updatedAt', '>', new Date(lastUpdatedAt)]], cache:false});
                } else {
                    loadedUsers = await ModelManager.list({model: User, cache:false});
                }
                updateModelInstances(User, loadedUsers.filter((user) => !user.deleted && user.enabled));

                loadedUsers.sort((a,b) =>  b.updatedAt - b.updatedAt);

                loadedUsers.forEach((user) => {
                    if (user.id !== AuthStorage.getUserId()) {
                        if (user.deleted  || !user.enabled ) {
                            deleteModelInstance(User, user);
                            CacheHelper.removeCachedUser(user);
                        } else {
                            CacheHelper.setCachedUser(user);
                        }
                    }

                });
            }
            )());

        if (!AUTHSTORAGE.isExternal()) {


            if ( AUTHSTORAGE.getClient().paid_features.includes(Feature.TASKS)) {
                loadingProcesses.push(
                    ( async () => {
                            let lastUpdatedAt = await CacheHelper.getLastTaskUpdatedAt();
                            let loadedTask;
                            if(lastUpdatedAt){
                                loadedTask = await ModelManager.list({model: Task, filter: [['relevant', "array-contains", AuthStorage.getUserId()],['uAt', '>', new Date(lastUpdatedAt)]]});
                            } else {
                                loadedTask = await ModelManager.list({model: Task,  filter: [['relevant', "array-contains", AuthStorage.getUserId()]]});
                            }
                            updateModelInstances(Task, loadedTask.filter( (task) => !task.dAt));

                        loadedTask.forEach((task) => {
                            if (task.dAt) {
                                deleteModelInstance(Task, task);
                                CacheHelper.removeCachedTask(task);
                            } else {
                                CacheHelper.setCachedTask(task);
                            }
                        });
                    }
                )());
        }
        loadingProcesses.push(
            ( async () => {
                    let loadedDistributors = await ModelManager.list({model: Distributor});
                    updateModelInstances(Distributor, loadedDistributors.filter((dis) =>  !!dis && !!dis.name));
                    loadedDistributors.forEach( async (distributor) => {
                        if (!!distributor && !!distributor.name) {
                            let model = await ModelManager.get({id: AuthStorage.getUserId(), resource: Distributor.collection() + '/' + distributor.id + '/request/', stopEvents: true})
                            if (!!model) {
                                AUTHDISTRIBUTORMEMBERSHIP.addRequest(distributor.id)
                            }
                        }
                    });
                }
            )())
        loadingProcesses.push(
            ( async () => {
                    let distributorMembershipResult = await ModelManager.get({id: AuthStorage.getUserId(),  resource: "/client/" + AuthStorage.getClientId() + "/dis_member/"});
                    if (distributorMembershipResult && distributorMembershipResult.dis) {
                        AUTHDISTRIBUTORMEMBERSHIP.setMemberships(Object.keys(distributorMembershipResult?.dis));
                    } else {
                        AUTHDISTRIBUTORMEMBERSHIP.setMemberships([]);
                    }
                }
            )())

        loadingProcesses.push(
            ( async () => {
                    let loadedDepartments = await ModelManager.list({model: Department});
                    updateModelInstances(Department, loadedDepartments);
                }
            )())
        loadingProcesses.push(
            ( async () => {
                    let lastUpdatedAt = await CacheHelper.getLastEventUpdatedAt();
                    let result;
                    if(!!lastUpdatedAt) {
                        result = await ModelManager.list({model: Event,  filter: [['uAt', '>=', new Date(lastUpdatedAt)]],ignoreDeletes: true});
                    } else {
                        let lastYear = DateTime.local().minus({year: 1}).toJSDate();
                        result = await ModelManager.list({model: Event, filter: [['start', '>=', lastYear]],ignoreDeletes: true});
                    }

                        if (result) {
                            updateModelInstances(Event, result);
                        }

                    }
                )());

            loadingProcesses.push(
                ( async () => {
                        try {
                            let notcountDocument = await ModelManager.get({id: "notcount",  resource: "/client/" + AuthStorage.getClientId() + "/user/" + AUTHSTORAGE.getUserId() + "/private"});
                            if(!!notcountDocument && !!notcountDocument['lastOpen']) {
                                AUTHSTORAGE.setLastNotificationOpen(notcountDocument['lastOpen'].toDate());
                            }
                        } catch (ex) {
                            console.log(ex);
                        }
                    }
                )())

            loadingProcesses.push(
                ( async () => {
                    try {
                        let highlightedArticlesResult = await ModelManager.get({id: '_all',  resource: "/client/" + AuthStorage.getClientId() + "/highlightedArticles/", stopEvents: true});
                        if (highlightedArticlesResult) {
                            HIGHLIGHTEDARTICLES.setHighlightedArticles(highlightedArticlesResult);

                            Object.keys(highlightedArticlesResult).forEach(articleId => {
                                ModelManager.get({model: Article, id: articleId});
                            });
                        } else {
                            HIGHLIGHTEDARTICLES.setHighlightedArticles({});
                        }
                    } catch(ex) {
                        console.log('error while loading highlighted articles')
                    }
                })())

            loadingProcesses.push(
                ( async () => {
                    try {
                        let highlightedGroupsResult = await ModelManager.get({id: '_all',  resource: "/client/" + AuthStorage.getClientId() + "/highlightedGroups/", stopEvents: true});
                        if (highlightedGroupsResult) {
                            HIGHLIGHTEDGROUPS.setHighlightedGroups(highlightedGroupsResult);
                        } else {
                            HIGHLIGHTEDGROUPS.setHighlightedGroups({});
                        }
                    } catch(ex) {
                        console.log('error while loading highlighted groups')
                    }
                    try {
                        let favoritedArticlesResult = await ModelManager.get({id: '_all',  resource: `/client/${AuthStorage.getClientId()}/user/${AuthStorage.getUserId()}/article_favorites/`, stopEvents: true});
                        if (favoritedArticlesResult) {
                            FAVORITEDARTICLES.setFavoritedArticles(favoritedArticlesResult);

                            Object.keys(favoritedArticlesResult).forEach(articleId => {
                                ModelManager.get({model: Article, id: articleId});
                            });
                        } else {
                            FAVORITEDARTICLES.setFavoritedArticles({});
                        }
                    } catch(ex) {
                        console.log('error while loading favorited articles')
                    }
                })())
        }

        loadingProcesses.push(
            ( async () => {
                    let loadedDepartments = await ModelManager.list({model: Department});
                    updateModelInstances(Department, loadedDepartments);
                }
            )());

        await Promise.all(loadingProcesses);
        setLoginState(cachingFailed ? LoginState.SUCCESSFULWITHERROR : LoginState.SUCCESSFUL);
        setPrivateKey(undefined);
    }

    const loadClientTranslations = (clientModel) => {
        if(!!clientModel.trans) {
            for(let translation of Object.keys(clientModel.trans)) {
                Translations[translation] = clientModel.trans[translation];
            }
        }
    }

    const loadClientColors= (clientModel) => {
        if (!!clientModel.brand) {
            const colorIsValid = (color) => !!color && color.length === 7 && color.startsWith("#");

            if (colorIsValid(clientModel.brand.primary)) {
                Colors.BRANDPRIMARY =  clientModel.brand.primary;
            }
            if (colorIsValid(clientModel.brand.primaryLight)) {
                Colors.BRANDPRIMARYLIGHT = clientModel.brand.primaryLight;
            }
            if (colorIsValid(clientModel.brand.primaryLighter)) {
                Colors.BRANDPRIMARYLIGHTER = clientModel.brand.primaryLighter;
            }
            if (colorIsValid(clientModel.brand.secondary)) {
                Colors.BRANDSECONDARY =  clientModel.brand.secondary;
            }
            if (colorIsValid(clientModel.brand.tertiary)) {
                Colors.BRANDTERTIARY =  clientModel.brand.tertiary;
            }
            if (colorIsValid(clientModel.brand.black)) {
                Colors.BLACK = clientModel.brand.black;
            }
            if (colorIsValid(clientModel.brand.blackLight)) {
                Colors.BLACKLIGHT = clientModel.brand.blackLight;
            }
            if (colorIsValid(clientModel.brand.blackLighter)) {
                Colors.BLACKLIGHTER = clientModel.brand.blackLighter;
            }
            if (colorIsValid(clientModel.brand.blackLightest)) {
                Colors.BLACKLIGHTEST = clientModel.brand.blackLightest;
            }
            if (colorIsValid(clientModel.brand.white)) {
                Colors.WHITE = clientModel.brand.white;
            }
            if (colorIsValid(clientModel.brand.blueLight)) {
                Colors.BLUELIGHT = clientModel.brand.blueLight;
            }
            if (colorIsValid(clientModel.brand.lightPurple)) {
                Colors.LIGHTPURPLE = clientModel.brand.lightPurple;
            }
            if (colorIsValid(clientModel.brand.blueBackground)) {
                Colors.BLUEBACKGROUND = clientModel.brand.blueBackground;
            }

            if (colorIsValid(clientModel.brand.ownChatBubble)) {
                Colors.OWNCHATBUBBLE = clientModel.brand.ownChatBubble;
            }
            if (colorIsValid(clientModel.brand.otherChatBubble)) {
                Colors.OTHERCHATBUBBLE = clientModel.brand.otherChatBubble;
            }
            if (colorIsValid(clientModel.brand.ownChatBubbleContent)) {
                Colors.OWNCHATBUBBLECONTENT = clientModel.brand.ownChatBubbleContent;
            }
            if (colorIsValid(clientModel.brand.otherChatBubbleContent)) {
                Colors.OTHERCHATBUBBLECONTENT = clientModel.brand.otherChatBubbleContent;
            }
            if (colorIsValid(clientModel.brand.otherChatBubbleExtraText)) {
                Colors.OTHERCHATBUBBLEEXTRATEXT = clientModel.brand.otherChatBubbleExtraText;
            }
            if (colorIsValid(clientModel.brand.ownChatBubbleExtraText)) {
                Colors.OWNCHATBUBBLEEXTRATEXT = clientModel.brand.ownChatBubbleExtraText;
            }

            if (colorIsValid(clientModel.brand.ownChatBubbleAudioButtons)) {
                Colors.OWNCHATBUBBLEAUDIOBUTTONS = clientModel.brand.ownChatBubbleAudioButtons;
            }
            if (colorIsValid(clientModel.brand.otherChatBubbleAudioButtons)) {
                Colors.OTHERCHATBUBBLEAUDIOBUTTONS = clientModel.brand.otherChatBubbleAudioButtons;
            }
            if (colorIsValid(clientModel.brand.ownChatBubbleAudioActive)) {
                Colors.OWNCHATBUBBLEAUDIOACTIVE = clientModel.brand.ownChatBubbleAudioActive;
            }
            if (colorIsValid(clientModel.brand.otherChatBubbleAudioActive)) {
                Colors.OTHERCHATBUBBLEAUDIOACTIVE = clientModel.brand.otherChatBubbleAudioActive;
            }
            if (colorIsValid(clientModel.brand.ownChatBubbleAudioSlider)) {
                Colors.OWNCHATBUBBLEAUDIOSLIDER = clientModel.brand.ownChatBubbleAudioSlider;
            }
            if (colorIsValid(clientModel.brand.otherChatBubbleAudioSlider)) {
                Colors.OTHERCHATBUBBLEAUDIOSLIDER = clientModel.brand.otherChatBubbleAudioSlider;
            }

            if (colorIsValid(clientModel.brand.ownChatBubbleDocumentUploading)) {
                Colors.OWNCHATBUBBLEDOCUMENTUPLOADING = clientModel.brand.ownChatBubbleDocumentUploading;
            }
            if (colorIsValid(clientModel.brand.otherChatBubbleDocumentUploading)) {
                Colors.OTHERCHATBUBBLEDOCUMENTUPLOADING = clientModel.brand.otherChatBubbleDocumentUploading;
            }

            MASTERTHEME.recreateStyles();
        }
    }

    const getBrowserEncryptionKey = async () => {
        let encryptedPrivateKeyPassword = await ModelManager.get({resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/private`, model: PrivateKey, id: 'key'});

        if(!encryptedPrivateKeyPassword || !encryptedPrivateKeyPassword.browserEncryptionKey || !encryptedPrivateKeyPassword.browserEncryptionIV) {
            let {key, iv} = generateBrowserEncryptionKey();

            if(!encryptedPrivateKeyPassword) {
                encryptedPrivateKeyPassword = new PrivateKey();
            }

            encryptedPrivateKeyPassword.browserEncryptionKey = key;
            encryptedPrivateKeyPassword.browserEncryptionIV = iv;

            await ModelManager.update({ useSet: true, resource: `/client/${AUTHSTORAGE.getClientId()}/user/${AUTHSTORAGE.getUserId()}/private`, modelInstance: encryptedPrivateKeyPassword, id: 'key'});
        }

        AUTHSTORAGE.setBrowserEncryptionKey(encryptedPrivateKeyPassword);

        return encryptedPrivateKeyPassword;
    };

    const onEnterMasterPassword = async (masterPassword) => {
        let result = decryptPrivateKey(masterPassword);

        if(result) {
            let browserEncryptionKey = await getBrowserEncryptionKey();
            let encryptedMasterPassword = encryptLocalStorageData(browserEncryptionKey.browserEncryptionKey, browserEncryptionKey.browserEncryptionIV, masterPassword);
            localStorage.setItem("mpe_" + AUTHSTORAGE.getUserId(), encryptedMasterPassword);
        }
    };

    const decryptPrivateKey= (password, altPrivateKey) => {
        let keyToDecrypt = altPrivateKey || privateKey;

        try {
            let decipher = forge.cipher.createDecipher('AES-CBC', forge.util.encodeUtf8(password));
            decipher.start({iv: forge.util.decode64(keyToDecrypt.iv)});
            decipher.update(forge.util.createBuffer(forge.util.hexToBytes(keyToDecrypt.privateKey), 'raw'));
            let success = decipher.finish();

            if(success) {
                let rawPrivateKey = decipher.output.getBytes();
                let pemPrivateKey = "-----BEGIN PRIVATE KEY-----\n"+forge.util.encode64(rawPrivateKey)+"\n-----END PRIVATE KEY-----\n";
                let parsedPrivateKey = forge.pki.privateKeyFromPem(pemPrivateKey);

                AUTHSTORAGE.setRawPrivateKey(rawPrivateKey);
                AUTHSTORAGE.setPrivateKey(parsedPrivateKey);
                initData();
                return true;
            } else {
                setError(t('errors.login.masterPassword'));
            }
        } catch(ex) {
            setError(t('errors.login.masterPassword'));
        }
        return false;
    }

    const onError= (error) => {

        if (!!error) {
            setLoginState(LoginState.ERROR);
            setError(error);
            AUTHSTORAGE.reset();
            FireAuth.signOut();
            store.dispatch({
                type: USER_LOGOUT
            });
        }
    }

    const onCancelPrivateKey = () => {
        FireAuth.signOut();
        setError(null);
        AUTHSTORAGE.reset();
        store.dispatch({
            type: USER_LOGOUT
        });
        setLoginState(LoginState.NONE);
        setPrivateKey(undefined);
    }

    const logout = () =>  {
        FireAuth.signOut();
        setError(null);
        AUTHSTORAGE.reset();
        store.dispatch({
            type: USER_LOGOUT
        });
        setLoginState(LoginState.NONE);
        setPrivateKey(undefined);
    }

    const login = (values) =>  {
        setLoginState(LoginState.WAITING);
        setError("");
        let {workspace, username, password} = values;

        if ((/^[0-9-()+\s]*$/).test(username)) {
            username = LoginHelper.getLoginFromPhone(workspace,undefined, username);
        } else {
            username = LoginHelper.getFromWorkspace(username, workspace);
        }

        enteredUserPassword.current = password;
        FireAuth.signInWithEmailAndPassword(username, password)
            .then((_) => {
            })
            .catch(({code}) => {
                let message = t('errors.login.unknown');

                switch(code) {
                    case "auth/user-not-found":
                    case "auth/wrong-password":
                        message = t('errors.login.usernameOrPassword');
                        break;
                    case "auth/too-many-requests":
                        message = t('errors.login.tooManyLoginAttempts');
                    default:
                        break;
                }

                setError(message);
                setLoginState(LoginState.ERROR);
            })
    }

    if (!!privateKey && loginState === LoginState.MASTERPASSWORD) {
        return (<MasterPasswordPage error={error} onSubmit={onEnterMasterPassword} onCancel={onCancelPrivateKey}/>);
    }

    if (loginState === LoginState.SUCCESSFUL || loginState === LoginState.SUCCESSFULWITHERROR ) {

        return (
            <>
                {
                    loginState === LoginState.SUCCESSFULWITHERROR &&
                    <MoinUnsupportedBrowserDialog  onConfirm={() => setLoginState(LoginState.SUCCESSFUL)} />
                }
                <MasterLayoutContainer logout={logout}/>
            </>
        )
    }

    return (<AuthorizationLoginPage login={login} loginState={loginState} loginError={error}/>);
};

const mapAction = {
    addModelInstances,
};

export default connect(null,mapAction) (AuthorizationContainer);
