import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import MoinDivider from "../base/MoinDivider";
import Typography from "@material-ui/core/Typography";
import ChatBubbleOutlineIcon from "../../assets/icons/comment-inactive.svg";
import ThumbsUpOutlineIcon from "../../assets/icons/thumb-up-outline.svg";
import {Colors} from "../helper/ColorHelper";
import {ReactionType} from "../../utils/enums/ReactionType";
import {getFilledIconForReaction} from "../helper/IconHelper";
import Popover from "@material-ui/core/Popover";
import {getTextForReaction} from "../helper/TextHelper";

const useStyles = makeStyles(() => ({
    column: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    smallRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    greyIcon: {
        fill: Colors.BLACKLIGHT,
        height: 20,
        width: 20,
        marginRight: '8px'
    },
    reactionIcon: {
        height: 10,
        width: 10,
        fill: Colors.WHITE
    },
    reactionCount: {
        marginLeft: '8px'
    },
    reactionIconWrapper: {
        position: 'relative'
    },
    reactionBobbleWrapper: {
        position: 'absolute',
    },
    reactionBobble: {
        height: 16,
        width: 16,
        borderRadius: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    divider: {
        marginTop: '8px',
        marginBottom: '10px'
    },
    commentCount: {
        marginLeft: '16px'
    },
    reactionButton: {
        borderRadius: 32,
        width: '42px',
        height: '42px',
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    reactionButtonIcon: {
        height: 20,
        width: 20,
        fill: Colors.WHITE
    },
    popover: {
        '& > .MuiPopover-paper': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '50px'
        }
    }
}));

/**
 * @param {ReactionType} reaction
 * @param {number} reactionCount
 * @param {Function} onLikeCountTap
 * @param {Function} onChangeReaction
 * @param {AllReactions} reactions
 * @param {boolean} [isComment=false]
 * @param {boolean} [showCommentButtons=false]
 * @param {boolean} [showCommentText=true]
 * @param {boolean} [showLikeButton=true]
 * @param {number} commentCount
 * @param {Function} onCommentCountTap
 * @param {Function} onCommentIconTap
 * @param {string} customCommentText
 * @constructor
 */

const ReactionRow = ({reaction, reactionCount, onLikeCountTap, onChangeReaction, reactions, isComment=false, showCommentButtons=false, showCommentText=true, showLikeButton=true, commentCount, onCommentCountTap, onCommentIconTap, customCommentText}) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [likeButtonAnchor, setLikeButtonAnchor] = useState(null);
    const reactionPopOverId = likeButtonAnchor ? 'additional-reactions' : undefined;

    if(!!reactions) {
        let allReactionTypes = Object.keys(reactions);
        let calculatedReactionCount = 0;

        for(let i = 0; i < allReactionTypes.length; i++) {
            let singleReactionCount = reactions[allReactionTypes[i]];
            calculatedReactionCount += singleReactionCount;
        }

        if(reactionCount > calculatedReactionCount) {
            let missingReactions = reactionCount - calculatedReactionCount;

            if(reactions.LIKE > 0) {
                reactions.LIKE += missingReactions;
            } else {
                reactions.LIKE = missingReactions;
            }
        }
    }

    const onClickLikeButton = (e) => {
        if(!!reaction) {
            onChangeReaction(reaction);
        } else {
            setLikeButtonAnchor(e.currentTarget);
        }
    }

    const getReactionIcons = () => {
        if(!!reactions) {
            let radius = 8;
            let allReactionTypes = Object.keys(reactions);
            let reactionBobbles = [];
            let nullValues = 0;

            for(let i = 0; i < allReactionTypes.length; i++) {
                let reactionCount = reactions[allReactionTypes[i]];

                if(reactionCount > 0) {
                    let margin = (i - nullValues) * (radius * 5/3);

                    let ReactionIcon = getFilledIconForReaction(allReactionTypes[i]);

                    reactionBobbles.push(
                        <div key={'reaction' + i} style={{marginLeft: `${margin}px`}} className={classes.reactionBobbleWrapper}>
                            <div className={classes.reactionBobble} style={{backgroundColor: Colors.colorForReactionType(allReactionTypes[i])}}>
                                <ReactionIcon className={classes.reactionIcon}/>
                            </div>
                        </div>
                    );
                } else {
                    nullValues++;
                }
            }

            if(reactionBobbles.length > 0) {
                return (
                    <div onClick={onLikeCountTap} className={classes.smallRow} style={{cursor: 'pointer'}}>
                        <div className={classes.reactionIconWrapper} style={{height: '16px', width: reactionBobbles.length * (radius * 5/3)}}>
                            { reactionBobbles }
                        </div>
                        <div className={classes.reactionCount}>
                            <Typography variant="caption">{ reactionCount }</Typography>
                        </div>
                    </div>
                );
            }
        }

        return <div/>;
    };

    const getLargeLayout = () => {
        let commentText;

        if(commentCount != null) {
            if(commentCount === 1) {
                commentText = t('article.comments.oneComment');
            } else {
                commentText = t('article.comments.multipleComments', {count: commentCount});
            }
        }

        let ReactionIcon = ThumbsUpOutlineIcon;
        let reactionColor = Colors.BLACKLIGHT;
        let reactionText = t('article.reactions.like');

        if(!!reaction) {
            ReactionIcon = getFilledIconForReaction(reaction);
            reactionColor = Colors.colorForReactionType(reaction);
            reactionText = getTextForReaction(reaction, t);
        }

        return (
            <div className={classes.column}>
                <div className={classes.row}>
                    { getReactionIcons() }

                    {
                        showCommentText && commentCount != null && commentCount > 0 &&
                            (
                                <div onClick={onCommentCountTap} style={{cursor: (!!onCommentCountTap ? 'pointer' : null)}}>
                                    <Typography variant="caption">{ customCommentText ?? commentText }</Typography>
                                </div>
                            )
                    }
                </div>

                {
                    (showLikeButton || showCommentButtons) &&
                        <div className={classes.divider}>
                            <MoinDivider/>
                        </div>
                }

                <div className={classes.row}>
                    {
                        showLikeButton &&
                            (
                                // <div className={classes.smallRow} onClick={() => onChangeReaction(reaction ?? ReactionType.LIKE)} ref={likeButtonRef} style={{cursor: 'pointer'}}>
                                <div aria-describedby={reactionPopOverId} className={classes.smallRow} onClick={onClickLikeButton} style={{cursor: 'pointer'}}>
                                    <ReactionIcon className={classes.greyIcon} style={{fill: reactionColor}}/>
                                    <Typography variant="caption" style={{color: reactionColor}}>{ reactionText }</Typography>
                                </div>
                            )
                    }

                    {
                        showCommentButtons &&
                            (
                                <div className={classes.smallRow} onClick={onCommentIconTap} style={{cursor: 'pointer'}}>
                                    <ChatBubbleOutlineIcon className={classes.greyIcon}/>
                                    <Typography variant="caption">{ t('article.comments.commentOn') }</Typography>
                                </div>
                            )
                    }
                </div>
            </div>
        )
    };

    const getCommentLayout = () => {
        let commentText;

        if(commentCount != null) {
            if(commentCount === 1) {
                commentText = t('article.comments.oneComment');
            } else {
                commentText = t('article.comments.multipleComments', {count: commentCount});
            }
        }

        let ReactionIcon = ThumbsUpOutlineIcon;
        let reactionColor = Colors.BLACKLIGHT;

        if(!!reaction) {
            ReactionIcon = getFilledIconForReaction(reaction);
            reactionColor = Colors.colorForReactionType(reaction);
        }

        return (
            <div className={classes.row} aria-describedby={reactionPopOverId}>
                <div className={classes.smallRow} style={{cursor: 'pointer'}}>
                    {
                        showLikeButton &&
                        (
                            <div aria-describedby={reactionPopOverId} className={classes.smallRow} onClick={onClickLikeButton} style={{cursor: 'pointer'}}>
                                <ReactionIcon className={classes.greyIcon} style={{fill: reactionColor}}/>
                            </div>
                        )
                    }

                    {
                        showCommentButtons &&
                        (
                            <ChatBubbleOutlineIcon className={classes.greyIcon} onClick={onCommentIconTap}/>
                        )
                    }
                </div>
                <div className={classes.smallRow} style={{cursor: 'pointer'}}>
                    { getReactionIcons() }

                    {
                        showCommentButtons && commentCount != null && commentCount > 0 &&
                        (
                            <div onClick={onCommentCountTap} className={classes.commentCount} style={{cursor: (!!onCommentCountTap ? 'pointer' : null)}}>
                                <Typography variant="caption">{ customCommentText ?? commentText }</Typography>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    };

    const selectAdditionalReaction = (reaction) => {
        onChangeReaction(reaction);
        setLikeButtonAnchor(null);
    };

    const getRenderedIconForReaction = (reactionType) => {
        let ReactionIcon = getFilledIconForReaction(reactionType);

        return <ReactionIcon className={classes.reactionButtonIcon}/>;
    }

    return (
        <>
            { isComment ? getCommentLayout() : getLargeLayout() }
            <Popover
                className={classes.popover}
                id={reactionPopOverId}
                open={!!likeButtonAnchor}
                anchorEl={likeButtonAnchor}
                onClose={() => setLikeButtonAnchor(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <div className={classes.smallRow} style={{marginTop: '4px', marginBottom: '4px'}}>
                    <div className={classes.reactionButton} style={{marginLeft: '6px', backgroundColor: Colors.colorForReactionType(ReactionType.LIKE)}} onClick={() => selectAdditionalReaction(ReactionType.LIKE)}>
                        { getRenderedIconForReaction(ReactionType.LIKE) }
                    </div>

                    <div className={classes.reactionButton} style={{backgroundColor: Colors.colorForReactionType(ReactionType.HEART)}} onClick={() => selectAdditionalReaction(ReactionType.HEART)}>
                        { getRenderedIconForReaction(ReactionType.HEART) }
                    </div>

                    <div className={classes.reactionButton} style={{backgroundColor: Colors.colorForReactionType(ReactionType.IDEA)}} onClick={() => selectAdditionalReaction(ReactionType.IDEA)}>
                        { getRenderedIconForReaction(ReactionType.IDEA) }
                    </div>

                    <div className={classes.reactionButton} style={{backgroundColor: Colors.colorForReactionType(ReactionType.CLAP)}} onClick={() => selectAdditionalReaction(ReactionType.CLAP)}>
                        { getRenderedIconForReaction(ReactionType.CLAP) }
                    </div>

                    <div className={classes.reactionButton} style={{backgroundColor: Colors.colorForReactionType(ReactionType.FUNNY)}} onClick={() => selectAdditionalReaction(ReactionType.FUNNY)}>
                        { getRenderedIconForReaction(ReactionType.FUNNY) }
                    </div>

                    <div className={classes.reactionButton} style={{marginRight: '6px', backgroundColor: Colors.colorForReactionType(ReactionType.SAD)}} onClick={() => selectAdditionalReaction(ReactionType.SAD)}>
                        { getRenderedIconForReaction(ReactionType.SAD) }
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default ReactionRow;