import {EventDispatcher} from "./eventdispatcher";
import {FireStore} from "../../../api/FirebaseService";
import FirestoreError from "./errors/FirestoreError";

/**
 * ModelManager
 *
 * this class handles and maps firestore data to js custom classes and vice versa.
 * you can register new models by decorating your custom class with the @model decorator
 *
 * at every firestore transaction, events getting dispatched
 * you can also subscribe your models to firestore realtime updates
 */
class FirestoreModelManager extends EventDispatcher
{

    /**
     * list of known models
     * @type {{}}
     */
    models = {};

    /**
     * data cache
     * @type {{}}
     */
    collections = {};


    /**
     * firestore instance
     * @type {firebase.firestore.Firestore}
     */
    connection;


    /**
     *
     */
    subscriptions;


    /**
     * @todo: refactor FireStore singleton
     * @param connection
     */
    constructor() {
        super();

        this.connection = FireStore;
        this.subscriptions = {};

        this.get = this.get.bind(this);
        this.list = this.list.bind(this);
        this.delete = this.delete.bind(this);
        this.update = this.update.bind(this);
        this.subscribe = this.subscribe.bind(this);
    }

    /**
     * register new model
     * @param model
     * @private
     */
    _register = (model) => {
        this.models[model.name] = model;
        this.collections[model.name] = {};
    };


    /**
     * get the model by name
     * @param name
     * @return {*}
     */
    getModel = (name) => this.models[name];


    /**
     * check if the given class is known to manager
     * @param model
     * @return {boolean}
     */
    knows(model) {
        return !!this.models[model.name];
    }


    /**
     * persist given model to firestore
     * @param modelInstance
     * @param customData
     * @param resource
     * @param id
     * @param extra
     * @param stopEvents
     * @throws FirestoreError
     * @return {Promise<object>}
     */
    add({modelInstance, customData=null, resource = null,id=null,  extra={}, stopEvents = false}) {

        let _firestoreData;
        let _resource;
        let _id;
        if (modelInstance && !customData) {
            const model = modelInstance.constructor;
            if(!this.knows(model))
                throw 'can not add given object because its model is not known to ModelManager';

            _firestoreData = model.converter.toFirestore(modelInstance);

            if (model.name !== "Event" && model.name !== "Distributor" && model.name !== "Task") {
                delete _firestoreData.uAt;
            }
            _resource = resource ? resource : model.collection();
            _id =  modelInstance[model.metadata.idProp];
        } else if (customData) {
            _firestoreData = customData;
            _resource = resource;
            if (!_resource && modelInstance) {
                _resource = modelInstance.constructor.collection();
            }
            _id = id;
            if (!_id && modelInstance) {
                _id = modelInstance[modelInstance.constructor.metadata.idProp];
            }
        }

        return new Promise((resolve, reject) => this.connection
            .collection(_resource)
            .doc(_id)
            .set(_firestoreData)
            .then(docRef => {
                if (modelInstance) {
                    if(modelInstance.hasOwnProperty('cAt')) {
                        modelInstance.cAt = new Date();
                    }
                    if (!stopEvents) {
                        let model = modelInstance.constructor;
                        this.dispatch('add', {model, modelInstance, extra});
                    }
                }

                resolve(modelInstance);
            })
            .catch(error => {
                const errorObject = new FirestoreError(
                    modelInstance ? modelInstance.constructor : id,
                    _firestoreData,
                    _resource,
                    "model_manager/add-failed",
                    error.message
                )
                if (modelInstance) {
                    let model = modelInstance.constructor;
                    this.dispatch('delete', {model, modelInstance, extra});
                }
                reject(errorObject);
            }));
    }


    /**
     * get a modelInstance from firestore
     * @param model
     * @param id
     * @param resource
     * @param hideErrors
     * @param extraProps
     * @throws FirestoreError
     * @return {Promise<model>}
     */
    get({model, id, resource = null, extraProps={}, stopEvents = false}) {
        const _resource = resource ? resource : model.collection();
        let reference = this.connection.collection(_resource);

        if(!!model) {
            reference = reference.withConverter(model.converter);
        }

        return new Promise((resolve, reject) =>
            reference
            .doc(id)
            .get()
            .then(doc => {
                let modelInstance = doc.data();
                if(!!modelInstance) {
                    if (!stopEvents) {
                        this.dispatch('add', {model, modelInstance, extra: extraProps});
                    }
                    resolve(modelInstance)
                } else {
                    resolve(null)
                }
            })
            .catch(error => {
                const errorObject = new FirestoreError(
                    model,
                    id,
                    _resource,
                    "model_manager/get-failed",
                    error.message
                )
                reject(errorObject);
            })
        );
    }

    getId({model, resource=null}) {
        const _resource = resource ? resource : model.collection()

        return  this.connection
            .collection(_resource)
            .doc().id;
    }


    /**
     * get all modelInstances from firestore of given model
     * @param model
     * @param resource
     * @param startAfter
     * @param limit
     * @param filter
     * @param orderBy
     * @param orderDirection
     * @param extraProps
     * @reject FirestoreError
     * @return {Promise<[model]>}
     */
    list({model, resource=null, startAfter=null, orderBy=null, orderDirection="asc", limit, filter=[], extraProps={}, cache=true, convert = true}) {

        const _resource = resource ? resource : model.collection()

        return new Promise((resolve, reject) => {
            let reference = this.connection.collection(_resource);

            if(!!orderBy) {
                reference = reference.orderBy(orderBy, orderDirection);
            }

            if(!!filter) {
                filter.forEach(function (where) {
                    reference = reference.where(where[0], where[1], where[2]);
                });
            }

            if(!!startAfter) {
                reference = reference.startAfter(startAfter);
            }

            if(!!limit) {
                reference = reference.limit(limit);
            }

            if(!!model && convert) {
                reference = reference.withConverter(model.converter);
            }

            return reference
                .get()
                .then(snapshot => {
                    if(!!model) {
                        const data = [];
                        snapshot.docs.forEach(doc => data.push(doc.data()));
                        if (cache) {
                            this.dispatch('list', {model, data, extra: extraProps});
                        }
                        resolve(data);
                    } else {
                        const data = {};
                        snapshot.docs.forEach(doc => data[doc.id] = doc.data());
                        resolve(data);
                    }
                })
                .catch(error => {
                    const errorObject = new FirestoreError(
                        model,
                        null,
                        _resource,
                        "model_manager/list-failed",
                        error.message
                    )
                    reject(errorObject);
                });
        });
    }


    /**
     * update given model to firestore
     * @param modelInstance
     * @param customData
     * @param resource
     * @param id
     * @param useSet
     * @param stopEvents
     * @reject FirestoreError
     * @return {Promise<object>}
     */
    update({modelInstance, customData=null, resource=null, id=null, useSet=false, stopEvents = false} ) {

        const model = modelInstance?.constructor;
        const _resource = resource ? resource : model.collection()

        return new Promise((resolve, reject) => {
            let ref = this.connection
                .collection(_resource)
                .doc(id ? id : modelInstance[model.metadata.idProp]);

            // Update Fails if the document doesn't exist. Set just creates the document instead
            let update;
            if(useSet) {
                update = ref.set(customData ? customData : model.converter.toFirestore(modelInstance, true), { merge: true });
            } else {
                update = ref.update(customData ? customData : model.converter.toFirestore(modelInstance, true));
            }

            return update
                .then(() => {
                    if (!customData && !stopEvents) {
                        this.dispatch('update', {model, modelInstance});
                    }
                    resolve(modelInstance);
                })
                .catch(error => {
                    console.log(error);
                    const errorObject = new FirestoreError(
                        customData || model ,
                        model && modelInstance
                            ? model.converter.toFirestore(modelInstance, true)
                            : undefined,
                        _resource+ '/'+ id,
                        "model_manager/update-failed",
                        error.message
                    )
                    reject(errorObject);
                })
        });
    }

    /**
     * update given model to firestore
     * @param modelInstance
     * @param customData
     * @param resource
     * @param id
     * @param stopEvents
     * @reject FirestoreError
     * @return {Promise<object>}
     */
    set({modelInstance, customData=null, resource=null, id=null, stopEvents = false} ) {

        const model = modelInstance?.constructor;
        const _resource = resource ? resource : model.collection()

        return new Promise((resolve, reject) => {
            let ref = this.connection
                .collection(_resource)
                .doc(id ? id : modelInstance[model.metadata.idProp]);


            let set = ref.set(customData ? customData : model.converter.toFirestore(modelInstance, true), { merge: false });


            return set
                .then(() => {
                    if (!customData && !stopEvents) {
                        this.dispatch('update', {model, modelInstance});
                    }
                    resolve(modelInstance);
                })
                .catch(error => {
                    const errorObject = new FirestoreError(
                        model || customData,
                        model && modelInstance
                            ? model.converter.toFirestore(modelInstance, true)
                            : undefined,
                        _resource,
                        "model_manager/update-failed",
                        error.message
                    )
                    reject(errorObject);
                })
        });
    }


    /**
     * delete given model from firstore
     * @param modelInstance
     * @param resource
     * @reject FirestoreError
     * @return {Promise<object>}
     */
    delete({modelInstance, resource=null}) {

        const model = modelInstance.constructor;
        const _resource = resource ? resource : modelInstance.collection;

        return new Promise((resolve, reject) => this.connection
            .collection(_resource)
            .doc(modelInstance[model.metadata.idProp])
            .delete()
            .then(() => {
                this.dispatch('delete', {model, modelInstance});
                resolve(modelInstance);
            })
            .catch(error => {
                const errorObject = new FirestoreError(
                    model,
                    null,
                    _resource,
                    "model_manager/delete-failed",
                    error.message
                )
                reject(errorObject);

            }));
    }


    /**
     * listen to realtime changes
     * @param model
     * @param subscriptionKey
     * @param filter
     * @param resource
     * @param orderBy
     * @param orderDirection
     * @param limit
     * @param startAfter
     * @param extraProps
     * @param ignoreDeletes
     * @throws FirestoreError
     * @return {FirestoreModelManager}
     */
    subscribe({model, subscriptionKey,  filter = [], resource=null, orderBy=null, orderDirection="asc", limit=null, startAfter=null, extraProps={}, ignoreDeletes = false})
    {
        const _resource = resource ? resource : model.collection();

        if(typeof this.subscriptions[_resource] === "undefined")
            this.subscriptions[_resource] = [];

        let reference = this.connection.collection(_resource);

        if(!!orderBy && Array.isArray(orderBy)) {
            orderBy.forEach( (entry) => {
                reference = reference.orderBy(entry.orderBy, entry.orderDirection);
            });
        } else if (!!orderBy) {
            reference = reference.orderBy(orderBy, orderDirection);
        }

        if(!!startAfter) {
            reference = reference.startAfter(startAfter);
        }

        if(!!filter) {
            filter.forEach(function (where) {
                reference = reference.where(where[0], where[1], where[2]);
            });
        }

        if(!!limit) {
            reference = reference.limit(limit);
        }

        if(!!model) {
            reference = reference.withConverter(model.converter);
        }

        this.subscriptions[_resource]
            .push(
                reference
                    .onSnapshot(querySnapshot => {
                        const changes = querySnapshot.docChanges();

                        changes.forEach(({type, doc}) => {
                                const modelInstance = doc.data();

                                switch (type) {
                                    case "added":
                                        this.dispatch('add', {model, modelInstance, extra: extraProps});
                                        break;

                                    case "modified":
                                        this.dispatch('update', {model, modelInstance, extra: extraProps});
                                        break;

                                    case "removed":
                                        if (!ignoreDeletes) {
                                            this.dispatch('delete', {model, modelInstance, extra: extraProps});
                                        }
                                        break;
                                }
                        });
                    },
                    error => {
                        const errorObject = new FirestoreError(
                            model,
                            null,
                            _resource,
                            "model_manager/subscribe-failed",
                            error.message
                        )
                        throw(errorObject);
                    })
        );


        this.dispatch('subscribe', {model, resource: !!subscriptionKey ? subscriptionKey :  _resource});

        return this;
    }

    /**
     * listen to realtime changes
     * @param model
     * @param id
     * @param documentPath
     * @param extraProps
     * @param withCallback
     * @param onlyInitialCallback
     * @param stopEvents
     * @throws FirestoreError
     * @return {FirestoreModelManager}
     */
    subscribeDoc( {model,id,  documentPath=null, extraProps={}, withCallback, onlyInitialCallback = true, stopEvents = false})
    {

        const _resource = documentPath ? documentPath : (model.collection() + '/' + id);

        if(typeof this.subscriptions[_resource] === "undefined")
            this.subscriptions[_resource] = [];

        let reference = this.connection
            .doc(_resource);

        if(!!model) {
            reference = reference.withConverter(model.converter);
        }

        let initialCallback = false;

        this.subscriptions[_resource]
            .push(
                reference
                    .onSnapshot(documentSnapshot => {
                        if(!stopEvents) {
                            this.dispatch('update', {model, modelInstance: documentSnapshot.data(), extra: extraProps});
                        }

                        if (withCallback && (!initialCallback || !onlyInitialCallback)) {
                            initialCallback = true;
                            withCallback (documentSnapshot.data());
                        }
                    },
                error => {
                        const errorObject = new FirestoreError(
                            model,
                            null,
                            _resource,
                            "model_manager/subscribeDoc-failed",
                            error.message
                        )
                        throw(errorObject);
                    })
            );

        this.dispatch('subscribe', {model, resource: _resource});
        return this;
    }


    /**
     * unsubscribe
     * @param model
     * @param subscriptionKey
     * @return {FirestoreModelManager}
     */
    unsubscribe({model=null, subscriptionKey}) {

        let resources = this.subscriptions;

        if(model) {
            const _resource = subscriptionKey ? subscriptionKey : model.collection();
            resources = {_resource: resources[_resource]};
        }

        Object.keys(resources).forEach(resource => {
            const detachers = resources[resource];
            if(!!detachers) {
                detachers.forEach(detach => detach());
            }
        });

        return this;
    }

}


export default FirestoreModelManager;