import React from "react";
import {useTranslation} from "react-i18next";
import UserListOverview from "../UserListOverview";

/**
 * Page that displays a list of participants of an event.
 *
 * @param {Array<User>} users
 * @param {String} eventTitle
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <EventParticipantsShow users={users} eventTitle={"Testevent 1"}/>
 */
const EventParticipantsShow = ({users, eventTitle}) => {

    const [t] = useTranslation();

    return (
        <UserListOverview
            userList={users}
            title={t('event.participantsOf', {event: eventTitle})}
        />
    );
}


export default EventParticipantsShow;
