import * as httpClient from "../utils/httpclient/HttpClient";
import parameters from '../config/parameters.json';
import AUTHSTORAGE from "../utils/auth/AuthStorage";
import {FireAuth} from "./FirebaseService";

class ApiClient {

    static createRequest= async function(path,body) {
        let httpClientInstance = new httpClient.default(parameters.apiUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        body['idToken'] = await FireAuth.currentUser.getIdToken();
        body['clientId'] = AUTHSTORAGE.getClientId();
        return httpClientInstance.post(path, JSON.stringify(body));
    }

}

export default ApiClient;