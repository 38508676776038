import {id, model, prop} from "../manager/decorators";
import {MessagingType} from "../enums/MessagingType";
import {SERVER_TIMESTAMP} from "../manager/decorators/sentinels";
import * as ROUTES from "../../Routes";
import {getEventAvatarPath, getUserAvatarPath} from "../helper/ImageHelper";

@model("/client/:client/user/:user/notification")
class Messaging {

    @id()
    id;

    @prop()
    cUid;

    @prop({type: "date", sentinel: SERVER_TIMESTAMP, readOnly: true})
    cAt;

    @prop()
    read;

    @prop()
    type;

    @prop()
    title;

    @prop()
    aId;

    @prop()
    cName;

    @prop()
    oName;

    @prop()
    dId;

    @prop()
    tId;

    @prop()
    disName;

    @prop()
    oldChatName;

    @prop()
    chatName;

    @prop()
    messageText;

    @prop()
    fullName;

    @prop()
    uId;

    @prop()
    eId;

    @prop()
    cId;

    /*
   * @param t translation
   * */
    getDescription(t) {
        switch (this.type) {
            case MessagingType.ARTICLEPUBLISHED:
                return t('messaging.publishedArticle', {userName: this.cName});
            case MessagingType.COMMENTATARTICLE:
                return t('messaging.commentAtArticle',{userName: this.cName});
            case MessagingType.ANSWERATCOMMENT:
                return t('messaging.answerAtComment',{userName: this.cName});
            case MessagingType.COMMENTATCOMMENTEDARTICLE:
                return t('messaging.commentAtCommentedArticle',{userName: this.cName});
            case MessagingType.MENTIONEDINCOMMENT:
                return t('messaging.mentionedAtComment',{userName: this.cName});
            case MessagingType.HEARTATARTICLE:
                return t('messaging.newHeartAtArticle',{userName: this.cName});
            case MessagingType.CHATNAMECHANGED:
                return t("messaging.chatNameChangedDescription",{senderName: this.cName,oldChatName: this.oldChatName, chatName: this.chatName });
            case MessagingType.ADDEDTODISTRIBUTOR:
                return t('messaging.addedToDistributorDescription', {distributor: this.disName});
            case MessagingType.REMOVEDFROMDISTRIBUTOR:
                return t('messaging.removedFromDistributorDescription', {distributor: this.disName});
            case MessagingType.DISTRIBUTORREMOVED:
                return t("messaging.groupWasRemoved",{distributor: this.disName });
            case MessagingType.LEFTDISTRIBUTOR:
                return t("messaging.someoneLeftDistributorDescription",{userName: this.cName, distributor: this.disName });
            case MessagingType.REQUESTDISTRIBUTOR:
                return t("messaging.someoneRequestedDistributorDescription",{userName: this.cName, distributor: this.disName });
            case MessagingType.REMOVEDREQUESTDISTRIBUTOR:
                return t("messaging.requestDistributorRemovedDescription",{userName: this.cName, distributor: this.disName });
            case MessagingType.REQUESTREFUSEDDISTRIBUTOR:
                return t("messaging.requestDistributorRejectedDescription",{userName: this.cName, distributor: this.disName });
            case MessagingType.TASKASSIGNED:
                return t("messaging.taskAssigned",{userName: this.cName, taskTitle: this.title });
            case MessagingType.TASKASSIGNEDTOOTHER:
                return t("messaging.taskAssignedToOther",{userName: this.cName, assigneeName: this.oName, taskTitle: this.title });
            case MessagingType.TASKFINISHED:
                return t("messaging.taskFinished",{userName: this.cName, taskTitle: this.title});
            case MessagingType.DATAEXPORTDONE:
                return t("messaging.dataExportDoneDescription");
            case MessagingType.MESSAGENEW:
                return this.cName + ": " + (this.messageText && this.messageText.length > 200) ? this.messageText.substring(0,200) + '...' : this.messageText;
            case MessagingType.NEWEVENT:
                return t("messaging.newEvent",{fullName: this.fullName, group: this.disName});
            case MessagingType.EVENTADDEDASCONTACT:
                return t("messaging.eventAddedAsContact",{fullName: this.fullName, event: this.title});
            case MessagingType.EVENTDATECHANGED:
                return t("messaging.eventDateChanged",{fullName: this.fullName, event: this.title});
            case MessagingType.EVENTPLACECHANGED:
                return t("messaging.eventPlaceChanged",{fullName: this.fullName, event: this.title});
            case MessagingType.EVENTINFORMATIONCHANGED:
                return t("messaging.eventInformationChanged",{fullName: this.fullName, event: this.title});
            default:
                break;
        }
    }

    getAvatar() {
        switch (this.type) {
            case MessagingType.ARTICLEPUBLISHED:
            case MessagingType.HEARTATARTICLE:
            case MessagingType.MESSAGENEW:
            case MessagingType.CHATNAMECHANGED:
            case MessagingType.ADDEDTODISTRIBUTOR:
            case MessagingType.REMOVEDFROMDISTRIBUTOR:
            case MessagingType.DISTRIBUTORREMOVED:
            case MessagingType.LEFTDISTRIBUTOR:
            case MessagingType.REQUESTDISTRIBUTOR:
            case MessagingType.REMOVEDREQUESTDISTRIBUTOR:
            case MessagingType.REQUESTREFUSEDDISTRIBUTOR:
            case MessagingType.TASKASSIGNED:
            case MessagingType.TASKASSIGNEDTOOTHER:
            case MessagingType.TASKFINISHED:
                return getUserAvatarPath(this.cUid);
            case MessagingType.NEWEVENT:
            case MessagingType.EVENTADDEDASCONTACT:
            case MessagingType.EVENTINFORMATIONCHANGED:
            case MessagingType.EVENTPLACECHANGED:
            case MessagingType.EVENTDATECHANGED:
                return getEventAvatarPath(this.uId, this.eId);
            default:
                break;
        }
    }

    getFallback() {
        switch (this.type) {
            case MessagingType.NEWEVENT:
            case MessagingType.EVENTINFORMATIONCHANGED:
            case MessagingType.EVENTPLACECHANGED:
            case MessagingType.EVENTDATECHANGED:
                return this.title;
            case MessagingType.ARTICLEPUBLISHED:
            case MessagingType.HEARTATARTICLE:
            case MessagingType.MESSAGENEW:
            case MessagingType.CHATNAMECHANGED:
            case MessagingType.ADDEDTODISTRIBUTOR:
            case MessagingType.REMOVEDFROMDISTRIBUTOR:
            case MessagingType.DISTRIBUTORREMOVED:
            case MessagingType.LEFTDISTRIBUTOR:
            case MessagingType.REQUESTDISTRIBUTOR:
            case MessagingType.REMOVEDREQUESTDISTRIBUTOR:
            case MessagingType.REQUESTREFUSEDDISTRIBUTOR:
            case MessagingType.TASKASSIGNED:
            case MessagingType.TASKASSIGNEDTOOTHER:
            case MessagingType.TASKFINISHED:
            default:
                return this.disName ?? this.fullName ?? this.cName;
        }
    }

    getLinkPath() {
        switch (this.type) {
            case MessagingType.ARTICLEPUBLISHED:
            case MessagingType.COMMENTATARTICLE:
            case MessagingType.COMMENTATCOMMENTEDARTICLE:
            case MessagingType.MENTIONEDINCOMMENT:
            case MessagingType.HEARTATARTICLE:
                return ROUTES.articleShow.replace(":id", this.aId);
                break;
            case MessagingType.ANSWERATCOMMENT:
                return ROUTES.commentShow.replace(':id', this.cId);
            case MessagingType.ADDEDTODISTRIBUTOR:
            case MessagingType.LEFTDISTRIBUTOR:
            case MessagingType.REQUESTREFUSEDDISTRIBUTOR:
            case MessagingType.REMOVEDFROMDISTRIBUTOR:
                return ROUTES.groupShow.replace(":id", this.dId);
                break;
            case MessagingType.REQUESTDISTRIBUTOR:
            case MessagingType.REMOVEDREQUESTDISTRIBUTOR:
                return ROUTES.groupRequests.replace(":id", this.dId);
                break;
            case MessagingType.MESSAGENEW:
                return ROUTES.messagesShow.replace(":id", this.cId);
                break;
            case MessagingType.TASKASSIGNED:
            case MessagingType.TASKASSIGNEDTOOTHER:
            case MessagingType.TASKFINISHED:
                return ROUTES.tasksShow.replace(":id", this.tId);
                break;
            case MessagingType.NEWEVENT:
            case MessagingType.EVENTADDEDASCONTACT:
            case MessagingType.EVENTINFORMATIONCHANGED:
            case MessagingType.EVENTPLACECHANGED:
            case MessagingType.EVENTDATECHANGED:
                return ROUTES.eventShow.replace(":id", this.eId);
                break;
            case MessagingType.CHATNAMECHANGED:
            case MessagingType.DISTRIBUTORREMOVED:
            default:
                break;
        }
    }



}

export default Messaging;
