import {getModelInstance} from "../../utils/store/selectors";
import {connect} from "react-redux";
import {MentionType} from "../../utils/enums/MentionType";
import User from "../../utils/models/User";
import React from "react";
import {Link} from "react-router-dom";
import MentionHelper from "../../utils/helper/MentionHelper";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../helper/ColorHelper";

const useStyles = makeStyles(() => ({
    root: {
        color: Colors.BRANDSECONDARY,
        textTransform: "none",
        textDecoration: "none",

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    },
}));

/**
 * @param {Mention} mention
 * @param {string} name
 * @returns {JSX.Element}
 * @constructor
 */
const RenderedMention = ({mention, name}) => {
    const classes = useStyles();

    // If name is set, link mention to entity, if name is undefined, show fallback name without link
    if(!!name) {
        return <span className={classes.root}> <Link to={MentionHelper.getPageForMention(mention)}>{name}</Link></span>;
    } else {
        return <span> {mention.fallbackName}</span>;
    }
};

const mapState = (state, ownProps) => {
    let mapObject = {};

    switch (ownProps.mention.entityType) {
        case MentionType.USER:
            mapObject['name'] = getModelInstance(state, User, ownProps.mention.entityId)?.fullName;
            break;
        default:
            break;
    }

    return mapObject;
};

export default connect(mapState)(RenderedMention);