import Dexie from 'dexie';
import User from "../models/User";
import Event from "../models/Event";
import {updateModelInstance, updateModelInstances} from "../store/actions";
import AUTHSTORAGE from "../auth/AuthStorage";
import Chat from "../models/Chat";
import Message from "../models/Message";
import MinimalUser from "../models/MinimalUser";
import {DateTime} from "luxon";
import Task from "../models/Task";
import {firebase} from "../../api/FirebaseService";
import TaskRepetition from "../models/TaskRepetition";
import CustomRepetitionInterval from "../models/CustomRepetitionInterval";

const version = 4;
const localCacheDb = new Dexie("cacheDb_" + version);
const loadTimestampDb = new Dexie("loadDb_" + version);

// Everything that is defined in the schema is indexed, we don't need to index the data
// Documentation: https://dexie.org/docs/API-Reference
// If you want to add new tables, increase the version number
//
// The id is combined of the id of either the client or the user + id of cached object
// For example:
// - Distributors are the same for the same client, therefore:  id = clientId + distributorId
// - Chats are the same only for the same user, therefore:      id = userId + chatId
localCacheDb.version(7).stores({user: 'id,clientId', chatKeys: 'id', events: 'id,clientId', distributor: 'id,clientId', chats: 'id,userId', tasks: 'id,clientId', birthdays: 'id'});
loadTimestampDb.version(1).stores({timestamp: 'id'});

export const loadCachedUsers = async () => {
    let users = [];
    try {
        if (!!localCacheDb.user.toArray) {

            (await localCacheDb.user.where('clientId').equals(AUTHSTORAGE.getClientId()).toArray()).forEach(userObject => {
                let user = Object.assign(new User(), userObject.data);
                if (user && user.id !== AUTHSTORAGE.getUserId() && !user.deleted && user.enabled) {
                    users.push(user);
                }
            });
            updateModelInstances(User, users);
        }
    } catch (ex) {
        console.log("Cache Helper - loadCachedUsers failed:");
        console.log(ex);
    }
    return users;
};

export const loadCachedEvents = async () => {
    try {
        if(!!localCacheDb.events.toArray) {
            let events = [];

            (await localCacheDb.events.where('clientId').equals(AUTHSTORAGE.getClientId()).toArray()).forEach(eventObject => {
                let event = Object.assign(new Event(), eventObject.data);
                if (!!event.geolocation) {
                    // After converting to json the location is saved as Object { u_: 40.7579747, h_: -73.9855426 }
                    event.geolocation = new  firebase.firestore.GeoPoint(event.geolocation.u_, event.geolocation.h_)
                }
                events.push(event);
            });
            updateModelInstances(Event, events);
        }
    } catch (ex) {
        console.log("Cache Helper - loadCachedEvents failed:");
        console.log(ex);
    }
};

export const loadCachedDistributors = async () => {
    // if(!!localCacheDb.distributor.toArray) {
    //     (await localCacheDb.distributor.where('clientId').equals(AUTHSTORAGE.getClientId()).toArray()).forEach(distributorObject => {
    //         let distributor = Object.assign(new Distributor(), distributorObject.data);
    //         updateModelInstance(Distributor, distributor);
    //     });
    // }
};

export const loadCachedTask = async () => {
    try {
        if(!!localCacheDb.tasks.toArray) {
            let tasks = [];

            (await localCacheDb.tasks.where('clientId').equals(AUTHSTORAGE.getClientId()).toArray()).forEach(taskObject => {
                let task = Object.assign(new Task(), taskObject.data);

                if(!!taskObject.data.repetition) {
                    task.repetition = Object.assign(new TaskRepetition(), taskObject.data.repetition);

                    if(!!taskObject.data.repetition.customInterval) {
                        task.repetition.customInterval = Object.assign(new CustomRepetitionInterval(), taskObject.data.repetition.customInterval);
                    }
                }

                tasks.push(task);
            });
            updateModelInstances(Task, tasks);
        }
    } catch (ex) {
        console.log("Cache Helper - loadCachedTask failed:");
        console.log(ex);
    }
};

export const loadCachedChats = async () => {
    try {
        if(!!localCacheDb.chats.toArray) {
            (await localCacheDb.chats.where('userId').equals(AUTHSTORAGE.getUserId()).toArray()).forEach(chatObject => {
                let chat = Object.assign(new Chat(), chatObject.data);
                chat.sample = chatObject.data.sample.map(minimalUserData => MinimalUser.from(minimalUserData));
                chat.lastMessage = null;
                if(!!chatObject.data.lastMessage) {
                    chat.lastMessage = Object.assign(new Message(), chatObject.data.lastMessage);
                    // console.log(chat.lastMessage);
                }
                updateModelInstance(Chat, chat);
            });
        }
    } catch (ex) {
        console.log("Cache Helper - loadCachedChats failed:");
        console.log(ex);
    }
};


export const setEncryptionKeyForChat = (chatId, key) => {
    try {
        localCacheDb.chatKeys.put({id: AUTHSTORAGE.getUserId() + chatId, key: key});
    } catch (ex) {
        console.log("Cache Helper - setEncryptionKeyForChat failed:");
        console.log(ex);
    }
};

export const checkIfChatIsHidden = async (chatId) => {
    try {
        let chat = (await localCacheDb.chats.get({id: AUTHSTORAGE.getUserId() + chatId}));
        return chat && chat.data && chat.data.hidden;
    } catch (ex) {
        console.log("Cache Helper - checkIfChatIsHidden failed:");
        console.log(ex);
        return false;
    }
};

export const getEncryptionKeyForChatId = async (chatId) => {
    try {
        return (await localCacheDb.chatKeys.get({id: AUTHSTORAGE.getUserId() + chatId}))?.key;
    } catch (ex) {
        console.log("Cache Helper - getEncryptionKeyForChatId failed:");
        console.log(ex);
    }
};

export const getLastUserUpdatedAt = async () => {
    try {
        return (await loadTimestampDb.timestamp.get(AUTHSTORAGE.getClientId() + 'lastUserUpdatedAt'))?.timestamp;
    } catch (ex) {
        console.log("Cache Helper - getLastUserUpdatedAt failed:");
        console.log(ex);
    }
};

export const getLastTaskUpdatedAt = async () => {
    try {
        return (await loadTimestampDb.timestamp.get(AUTHSTORAGE.getClientId() + 'lastTaskUpdatedAt'))?.timestamp;
    } catch (ex) {
        console.log("Cache Helper - getLastTaskUpdatedAt failed:");
        console.log(ex);
    }
};

export const getLastEventUpdatedAt = async () => {
    try {
        return (await loadTimestampDb.timestamp.get(AUTHSTORAGE.getClientId() + 'lastEventUpdatedAt'))?.timestamp;
    } catch (ex) {
        console.log("Cache Helper - getLastEventUpdatedAt failed:");
        console.log(ex);
    }
};

export const getLastDistributorUpdatedAt = async () => {
    try {
        return (await loadTimestampDb.timestamp.get(AUTHSTORAGE.getClientId() + 'lastDistributorUpdatedAt'))?.timestamp;
    } catch (ex) {
        console.log("Cache Helper - getLastDistributorUpdatedAt failed:");
        console.log(ex);
    }
};

export const setCachedUser = async (user, withoutUAt = false) => {
    try {
        localCacheDb.user.put({id: user.id, data: user, clientId: AUTHSTORAGE.getClientId()});
    } catch (ex) {
        console.log("Cache Helper - setCachedUser failed:");
        console.log(ex);
    }
};

export const removeCachedUser = async (user) => {
    try {
        localCacheDb.user.delete(user.id);
    } catch (ex) {
        console.log("Cache Helper - removeCachedUser failed:");
        console.log(ex);
    }
};

export const setCachedTask = async (task) => {
    try {
        localCacheDb.tasks.put({id: AUTHSTORAGE.getClientId() + task.id, data: task, clientId: AUTHSTORAGE.getClientId()});

        let lastUpdatedAt = (await loadTimestampDb.timestamp.get({id: AUTHSTORAGE.getClientId() + 'lastTaskUpdatedAt'}))?.timestamp;

        if (!lastUpdatedAt || (task.uAt && lastUpdatedAt < task.uAt.getTime())) {
            loadTimestampDb.timestamp.put({id: AUTHSTORAGE.getClientId() + 'lastTaskUpdatedAt', timestamp: task.uAt.getTime()});
        }
    } catch (ex) {
        console.log("Cache Helper - setCachedTask failed:");
        console.log(ex);
    }
};


export const removeCachedTask = async (task) => {
    try {
        localCacheDb.tasks.delete(AUTHSTORAGE.getClientId() + task.id);
    } catch (ex) {
        console.log("Cache Helper - removeCachedTask failed:");
        console.log(ex);
    }
};


export const setCachedEvent = async (event) => {
    try {
        localCacheDb.events.put({id: AUTHSTORAGE.getClientId() + event.id, data: event, clientId: AUTHSTORAGE.getClientId()});

        let lastUpdatedAt = (await loadTimestampDb.timestamp.get({id: AUTHSTORAGE.getClientId() + 'lastEventUpdatedAt'}))?.timestamp;

        if (!lastUpdatedAt || (event.uAt && lastUpdatedAt < event.uAt.getTime())) {
            loadTimestampDb.timestamp.put({id: AUTHSTORAGE.getClientId() + 'lastEventUpdatedAt', timestamp: event.uAt.getTime()});
        }
    } catch (ex) {
        console.log("Cache Helper - setCachedEvent failed:");
        console.log(ex);
    }
};

export const setCachedDistributor = async (distributor) => {
    try {
        localCacheDb.distributor.put({id: AUTHSTORAGE.getClientId() + distributor.id, data: distributor, clientId: AUTHSTORAGE.getClientId()});

        let lastUpdatedAt = (await loadTimestampDb.timestamp.get({id: AUTHSTORAGE.getClientId() + 'lastDistributorUpdatedAt'}))?.timestamp;

        if (!lastUpdatedAt || (distributor.uAt && lastUpdatedAt < distributor.uAt.getTime())) {
            loadTimestampDb.timestamp.put({id: AUTHSTORAGE.getClientId() + 'lastDistributorUpdatedAt', timestamp: distributor.uAt.getTime()});
        }
    } catch (ex) {
        console.log("Cache Helper - setCachedDistributor failed:");
        console.log(ex);
    }
};

export const setCachedChat = async (chat) => {
    try {
        localCacheDb.chats.put({id: AUTHSTORAGE.getUserId() + chat.id, data: chat, userId: AUTHSTORAGE.getUserId()});
    } catch (ex) {
        console.log("Cache Helper - setCachedChat failed:");
        console.log(ex);
    }
};

export const checkHiddenBirthday = async (uid) => {
    let lastHiddenBirthday = (await loadTimestampDb.timestamp.get(AUTHSTORAGE.getUserId() + 'lastBirthdayHidden'))?.timestamp;
    if (!!lastHiddenBirthday) {
        if (DateTime.fromMillis(lastHiddenBirthday).startOf('day') < DateTime.local().startOf('day')) {
            localCacheDb.birthdays.clear(); //clear all birthdays if last time a birthday was hidden was yesterday or longer agp
        }
    }
    return (!!await localCacheDb.birthdays.get(uid));
};

export const setHiddenBirthdays = async (uid) => {
    try {
        loadTimestampDb.timestamp.put({id: AUTHSTORAGE.getUserId() + 'lastBirthdayHidden', timestamp: DateTime.local().toMillis()});
        localCacheDb.birthdays.put({id: uid});
    } catch (ex) {
        console.log("Cache Helper - setHiddenBirthdays failed:");
        console.log(ex);
    }
};

export const canCache = async () => {
    try {
        await Dexie.exists("cacheDb_" + version)
        await loadTimestampDb.timestamp.put({id: AUTHSTORAGE.getClientId() + 'lastCheckCache', timestamp: DateTime.local().toMillis()});
        return true;
    } catch (ex) {
        console.log("Cache Helper - canCache failed:");
        console.log(ex);
        return false;
    }
};