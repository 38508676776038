import React, {useState} from "react";
import {SurveyType} from "../../utils/enums/SurveyType";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import MoinButton from "../base/MoinButton";
import {Colors} from "../helper/ColorHelper";
import MoinTextField from "../base/MoinTextField";
import {Privacy} from "../../utils/enums/Privacy";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {Link} from "react-router-dom";
import * as ROUTES from "../../Routes";
import {getFormattedTimeLeftUntil} from "../helper/TextHelper";

const useStyles = makeStyles((theme) => ({
    surveyWrapper: {
        width: "100%",
        paddingRight: "16px",
        paddingLeft: "16px",
    },
    surveyOption: {
        backgroundColor: Colors.WHITE,
        borderRadius: "8px",
        border: "1px solid " + Colors.BLUELIGHT,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    surveyOptionSelectable: {
        backgroundColor: Colors.WHITE,
        borderRadius: "8px",
        border: "1px solid " + Colors.BLUELIGHT,
        cursor: "pointer",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },

    optionSelector: {
        backgroundColor: Colors.BRANDSECONDARY,
        width: "8px",
        height: "100%",
        position: "absolute",
        borderRadius: "8px 0px 0px 8px"
    },
    optionWrapper: {
        position: "relative"
    },
    voteButton: {
        marginTop: theme.spacing(1.5)
    },
    surveyQuestion: {
        marginBottom: theme.spacing(0.5)
    },
    surveyVoteInfo: {
        marginTop: theme.spacing(1.5)
    },
    surveyVoteInfoRow: {
        display: "flex",
        alignItems: "center"
    },
    surveyInfoDot: {
        height: "4px",
        width: "4px",
        backgroundColor: Colors.BLACKLIGHT,
        borderRadius: "100%",
        margin: "2px 4px 0"
    },
    link: {
        color: Colors.BRANDSECONDARY,
        textTransform: "none",
        textDecoration: "none",

        '& :link': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :visited': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :active': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        },
        '& :hover': {
            textDecoration: "none",
            color: Colors.BRANDSECONDARY,
        }
    }
}));

/**
 * @param {Survey} survey
 * @param {Article} article
 * @param {Function} voteOnSurvey
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleSurvey = ({survey, article, voteOnSurvey}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [answer, setAnswer] = useState("");
    const [t, i18n] = useTranslation();
    const classes = useStyles();

    const selectOption = (optionKey) => {
        const parsedOptionKey = parseInt(optionKey);

        if(survey.type === SurveyType.SINGLECHOICE) {
            setSelectedOptions([parsedOptionKey]);
        } else {
            if(selectedOptions.includes(parsedOptionKey)) {
                setSelectedOptions(selectedOptions.filter(option => option !== parsedOptionKey));
            } else {
                setSelectedOptions([...selectedOptions, parsedOptionKey]);
            }
        }
    }

    const getEndDateText = () => {
        if(survey.endDate < new Date()) {
            return t('article.survey.ended');
        }

        return getFormattedTimeLeftUntil(t, survey.endDate);
    };

    const getAnsweredText = () => {
        let votesString;

        if(survey.total === 0) {
            votesString = t('article.survey.noVotes');
        } else if(survey.total === 1) {
            votesString = t('article.survey.oneVote');
        } else {
            votesString = t('article.survey.multipleVotes', {votes: survey.total});
        }

        return (
            <div className={classes.surveyVoteInfo}>
                {
                    (!!survey.authUserAnswer || (!!survey.authUserVotes && survey.authUserVotes.length > 0)) &&
                        <Typography variant="body1">{t('article.survey.thanksForVoting')}</Typography>
                }
                <div className={classes.surveyVoteInfoRow}>
                    <Typography variant="caption">{votesString}</Typography>
                    {
                        !!survey.endDate &&
                            <div className={classes.surveyInfoDot}/>
                    }
                    {
                        !!survey.endDate &&
                            <Typography variant="caption">{ getEndDateText() }</Typography>
                    }
                </div>
            </div>
        );
    };

    const getSurveyOptions = () => {
        return (
            <>
                {
                    Object.keys(survey.options).map(optionKey => {
                        const option = survey.options[optionKey];
                        let isSelected = !!survey.authUserVotes && survey.authUserVotes.includes(parseInt(optionKey));

                        if (!survey.authUserVotes) {
                            isSelected = selectedOptions.includes(parseInt(optionKey));
                        }

                        let total = survey.total ?? 0;

                        if(survey.type === SurveyType.MULTIPLECHOICE) {
                            total = 0;
                            Object.values(survey.options).forEach(option => total += option.count);
                        }

                        let percentage = total === 0 ? total : (option.count / total * 100);
                        if(percentage < 0) {
                            percentage = 0;
                        }

                        let voteStyle = {
                            background: `linear-gradient(to right, ${Colors.BLUELIGHT} ${percentage}%, ${Colors.BLUELIGHT} ${percentage}%, ${Colors.WHITE} ${percentage}%, ${Colors.WHITE})`
                        };

                        return(
                            <div className={classes.optionWrapper} key={"surveyVoted" + survey.id + "option" + optionKey}>
                                {
                                    isSelected &&
                                    <div className={classes.optionSelector}/>
                                }
                                <div className={classes.surveyOption} onClick={() => selectOption(optionKey)} style={voteStyle}>
                                    <Typography variant="body1">{ option.text }</Typography>
                                    { (showResults || (article.cUid === AUTHSTORAGE.getUserId()) ) &&<Typography variant="body1" style={{fontWeight: "bold", minWidth: "50px", textAlign: "end"}}>{ Math.round(percentage) + "%" }</Typography> }
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    };

    const getVoteButton = () => {
        if(!showResults && !survey.authUserAnswer) {
            return (
                <MoinButton disabled={ (!selectedOptions || selectedOptions.length === 0) && !answer} className={classes.voteButton} onClick={() => voteOnSurvey(selectedOptions, answer)} size="small">
                    { t('article.survey.vote') }
                </MoinButton>
            )
        }
    };

    const handleAnswerChange = (event) => {
        setAnswer(event.target.value);
    };

    const getSurveyFreeAnswerField = () => {
        if(!!survey.authUserAnswer) {
            return (
                <>
                    <Typography variant="body1">{ t('article.survey.yourAnswer') }: { survey.authUserAnswer }</Typography>
                    { getShowSurveyAnswersLink() }
                </>
            )
        } else {
            return (
                <>
                    {
                        (article.cUid !== AUTHSTORAGE.getUserId()) &&
                        <MoinTextField rows="2" name="answer" placeholder={ t('article.survey.answerSurvey') } onChange={handleAnswerChange} value={answer}/>
                    }
                    { getShowSurveyAnswersLink() }
                </>
            )
        }
    };

    const getShowSurveyAnswersLink = () => {
        if(survey.total > 0 && (survey.aP === Privacy.PUBLIC || article.cUid === AUTHSTORAGE.getUserId())) {
            let answerLink = ROUTES.surveyAnswerShow.replace(':aid', article.id).replace(':sid', survey.id);

            if(survey.type !== SurveyType.FREEANSWER) {
                answerLink = ROUTES.surveyVotesShow.replace(':aid', article.id).replace(':sid', survey.id);
            }

            return <Typography variant="body2" className={classes.link}><Link to={answerLink}>{ t('article.survey.showAllAnswers') }</Link></Typography>;
        }
    }

    if(!survey) {
        return <div/>;
    }

    const showResults = (!!survey.authUserVotes && survey.authUserVotes.length > 0) || (!!survey.endDate && survey.endDate < new Date());

    return (
        <div className={classes.surveyWrapper}>
            <Typography variant="h2" className={classes.surveyQuestion}>{survey.question}</Typography>
            {
                survey.type === SurveyType.SINGLECHOICE && !showResults &&
                     <Typography variant="caption">{t("article.survey.singleChoiceHint")}</Typography>
            }
            {
                survey.type === SurveyType.MULTIPLECHOICE && !showResults &&
                     <Typography variant="caption">{t("article.survey.multipleChoiceHint")}</Typography>
            }

            {
                survey.type === SurveyType.FREEANSWER
                    ? getSurveyFreeAnswerField()
                    : <>
                        { getSurveyOptions() }
                        { getShowSurveyAnswersLink() }
                    </>
            }
            {
                getVoteButton()
            }
            {
                getAnsweredText()
            }
        </div>
    );
};

export default ArticleSurvey;
