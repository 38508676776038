import AbstractType from "./AbstractType";
import {firebase} from "../../../api/FirebaseService";

export default class GeoPointType extends AbstractType
{

    toFirestore = (value) => value && value instanceof firebase.firestore.GeoPoint? value : null;
    fromFirestore = (value) => value && value instanceof firebase.firestore.GeoPoint ? value: null;

    static type = "geopoint";

    test = (value) => value instanceof firebase.firestore.GeoPoint;
};