import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {TranslationType} from "../../utils/enums/TranslationType";
import {ClientType} from "../../utils/enums/ClientType";
import {SmsLanguage} from "../../utils/enums/SmsLanguage";

export const Translations = {};

function clientIsAssociation() {
    return AUTHSTORAGE.getClient()?.type === ClientType.ASSOCIATION;
}

export function getTranslationForType(type) {
    let customTranslation = Translations[type];
    if(customTranslation){
        return customTranslation[AUTHSTORAGE.getLanguage()];
    }
}
export function getTranslationForSMSLanguage(t,type) {
    switch (type) {
        case SmsLanguage.ENGLISH:
            return t('language.english');
        case SmsLanguage.RUSSIAN:
            return t('language.russian');
        case SmsLanguage.UKRAINIAN:
            return t('language.ukrainian');
    }
    return t('language.german');
}

export function department(t) {
    let departmentTranslation = getTranslationForType(TranslationType.DEPARTMENT);

    if(!departmentTranslation) {
        if(clientIsAssociation()) {
            return t('user.branch');
        } else {
            return t('user.department');
        }
    }

    return departmentTranslation;
}

export function unknownDepartment(t) {
    if(clientIsAssociation()) {
        return t('user.unknownBranch', {branchTranslation: department(t)});
    }

    return t('user.unknownDepartment', {departmentTranslation: department(t)});
}

export function noDepartmentSelected(t) {
    if(clientIsAssociation()) {
        return t('user.noBranchSelected', {branchTranslation: department(t)});
    }

    return t('user.noDepartmentSelected', {departmentTranslation: department(t)});
}
