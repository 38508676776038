import AbstractType from "./AbstractType";
import {FireStore} from "../../../api/FirebaseService";

export default class ReferenceType extends AbstractType
{
    target;

    constructor({target}) {
        super();
        this.target = target;
    }

    toFirestore = (value) => FireStore.collection(this.target.resource).doc(value);

    fromFirestore = (value) => value ? value.id : null;

    static type = "reference";

};